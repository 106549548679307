import {
    LOAD_PROJECT_TEMPLATES,
    LOAD_PROJECT_TEMPLATES_SUCCESS,
    LOAD_PROJECT_TEMPLATES_FAILURE,
} from "./project-templates.actions";

const initialState = {
    loading: false,
    error: null,
    projectTemplates: null,
};

const projectTemplatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PROJECT_TEMPLATES: {
            return {
                ...initialState,
                loading: true,
            };
        }

        case LOAD_PROJECT_TEMPLATES_SUCCESS: {
            return {
                ...state,
                projectTemplates: action.projectTemplates,
            };
        }

        case LOAD_PROJECT_TEMPLATES_FAILURE: {
            return {
                ...state,
                projectTemplates: action.projectTemplates,
            };
        }

        default: {
            return state;
        }
    }
};

export default projectTemplatesReducer;
