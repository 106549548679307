import { getProjectForms, getFormDeleted, getFormDirty, getProjectCollections, getProject, getFormQuestionState, getProjectRole, getFormId, getFormAttributes, getFormAttributesWithSections } from '../../store/selectors';

export default class ProjectFormController {

    static $inject = ['$ngRedux', '$stateParams', '$element', '$q', 'ProjectService', 'ProjectFormActions', 'toastr', '$scope', '$state', '$timeout', '$mdDialog', '$transitions'];
    constructor($ngRedux, $stateParams, $element, $q, ProjectService, ProjectFormActions, toastr, $scope, $state, $timeout, $mdDialog, $transitions) {

        this.$timeout = $timeout;
        this.$stateParams = $stateParams;
        this.$ngRedux = $ngRedux;
        this.$transitions = $transitions;
        const validTitleAttributeTypes = ['text', 'select', 'multiselect', 'media', 'float', 'number', 'integer', 'date', 'datetime', 'email', 'url'];

        $scope.$on('$destroy', $ngRedux.connect((state) => {
            const { form: { ready, saving, errors, settingsView, name, title, thankyou, color, allowMemberUpdate, allowOwnRecordDelete, selectedAttributeId, titleAttributeId, secondaryTitleAttributeId, style, visible, private: formPrivate, deleted } } = state;
            const attributes = getFormAttributes(state);
            const selected = selectedAttributeId === null ? null : attributes.find(a => a.id === selectedAttributeId);
            const hasLocation = typeof attributes.find(a => a.questionType === 'geometry') !== 'undefined';

            if (saving) {
                this.buildSections(attributes);
            }

            return {
                project: getProject(state),
                selected,
                ready,
                saving,
                errors,
                settingsView,
                formName: name,
                formTitle: title,
                // formColor: color,
                formStyle: angular.copy(style),
                formThankyou: thankyou,
                formTitleAttributeId: titleAttributeId,
                formDescriptionAttributeId: secondaryTitleAttributeId,
                formAllowMemberUpdate: allowMemberUpdate,
                formAllowOwnRecordDelete: allowOwnRecordDelete,
                formId: getFormId(state),
                formVisibility: visible,
                formPrivate,
                // formTitleAttribute,
                attributes,
                attributesWithSections: getFormAttributesWithSections(state),
                role: getProjectRole(state),
                deleted,
                empty: (attributes.length) === 0,
                selectedAttributeId,
                titleAttributes: angular.copy(attributes.filter(a => a.id > 0 && validTitleAttributeTypes.indexOf(a.type) !== -1 && a.id !== secondaryTitleAttributeId)),
                descriptionAttributes: angular.copy(attributes.filter(a => a.id > 0 && validTitleAttributeTypes.indexOf(a.type) !== -1 && a.id !== titleAttributeId)),
                forms: getProjectForms(state),
                surveys: getProjectForms(state),
                collections: getProjectCollections(state),
                dirty: getFormDirty(state),
                questionState: getFormQuestionState(state),
                hasLocation
            };
        }, ProjectFormActions)(this));

        this.buildSections();
        this.associatedForms = [];
        this.ProjectService = ProjectService;
        this.toastr = toastr;
        this.$timeout = $timeout;
        this.$mdDialog = $mdDialog;
        this.$element = $element;
        this.$q = $q;

        if (!this.ready) {
            // Redirect to forms list if no form found
            $state.go('project.forms', { id: this.project.slug });
            return;
        }

        // Form becomes readonly if user isn't an admin
        this.readonly = this.role !== 'admin';

        // Duplicating committed attributes to created staged attributes
        this.attributeSet = [];

        this.calculatedQuestionTypes = ['rgeolocation', 'geometryquery', 'coordinatetransform'];


        var vm = this;
        vm.isReceiving = false;
        vm.isDragging = false;

        $timeout(function () {
            $('#form_builder').sortable({
                axis: 'y',
                // containment: 'parent',
                containment: '#form_builder',
                placeholder: "formbuilder-drag-placeholder",
                handle: ".form-handle",
                tolerance: "pointer",
                receive: function (event, ui) {
                    const { q } = angular.element(ui.item[0]).scope();
                    const index = ui.helper.index();
                    let sectionIdx = 0;
                    if (index > 0) {
                        sectionIdx = vm.attributesWithSections[index - 1].sectionIdx;
                    }
                    ui.helper.remove();

                    vm.isReceiving = true;
                    $timeout(() => vm.isReceiving = false, 200);
                    const type = angular.isArray(q.type) ? q.type[0] : q.type;

                    $scope.$evalAsync(() => {
                        vm.addAttribute(type, q.questionType, index - sectionIdx, sectionIdx, vm.formId);
                        vm.buildSections();
                    });
                },
                over: function (event) {
                    vm.dragging = true;
                    $scope.$digest();
                },
                out: function (event) {
                    vm.dragging = false;
                    $scope.$digest();
                },
                update: function (event, ui) {
                    if (vm.isReceiving) {
                        return;
                    }

                    const el = $('#form_builder');

                    let order = [];
                    let sectionIdx = 0;
                    let thisSectionCount = 0;


                    el.children().each(function () {
                        var attribute = angular.element(this).scope().a;

                        if (attribute.type === 'divider') {
                            if (thisSectionCount > 0) {
                                sectionIdx++;
                            }
                            thisSectionCount = 0;
                        } else {
                            thisSectionCount++;
                            order.push({
                                id: attribute.id,
                                sectionIdx
                            });
                        }
                    });

                    $scope.$evalAsync(() => {
                        vm.setAttributeOrder(order);
                        vm.buildSections();
                    });
                }

            });

            $('.form-question-type').draggable({
                // revert: true,
                helper: 'clone',
                connectToSortable: '#form_builder'
            });
        });

        // Catch routing state changes and prompt user to save first, if appropriate
        var skipCheck = false;
        $scope.$on('$destroy', this.$transitions.onStart({}, (trans) => {
            var $state = trans.router.stateService;

            if (skipCheck) {
                // Check has already occurred
                skipCheck = false;
                return;
            }

            if (this.dirty) {
                // Unsaved changes; block state change and prompt user to take action
                // event.preventDefault();
                if (this.saving) {
                    // Save already in progress
                    return false;
                }

                return this.checkUnsaved().then((action) => {
                    if (!action) {
                        return false;
                    }
                    const actionPromise = action === 'save' ? this.saveForm() : Promise.resolve();
                    return actionPromise.then(() => {
                        skipCheck = true;
                        return $state.target(trans.to().name);
                    });
                }, angular.noop);
            }
        }));

        // Catch browser-level navigation and prompt user when there are unsaved changes.
        window.onbeforeunload = () => {
            if (this.dirty) {
                return "The current page has unsaved changes. Are you sure you wish to leave?";
            }
        };

        // Remove route change handlers when page is left
        $scope.$on('$destroy', () => window.onbeforeunload = null);
    }

    onSaveClick() {
        const deletedCount = this.deleted.length;

        if (deletedCount) {
            this.warnDeleted().then(() => {
                this.saveForm();
            })
        } else {
            this.saveForm();
        }
    }

    save() {
        this.saveForm().then(() => this.buildSections(), (err) => {
            console.warn(err);
            // Still rebuild sections as attributes may have changed
            this.buildSections();
        });
    }

    buildSections(attributes = this.attributes) {
        const blocksContainer = document.getElementById('form_builder');
        const scrollTop = blocksContainer ? blocksContainer.scrollTop : null;
        this.attributesWithSections = getFormAttributesWithSections(this.$ngRedux.getState());

        this.$timeout(function () {
            if (blocksContainer) {
                blocksContainer.scrollTop = scrollTop;
            }
        });
    }


    handleSortableStop() {
        $(this).sortable('cancel');
    };

    addBlock(block) {
        this.blocks.push(block);
    }

    /**
     * Delete a block
     * @param {number} id ID of the attribute to delete
     */
    removeBlock(id) {
        this.deleteAttribute(id);
        this.buildSections();
    }

    getId(attribute) {
        return attribute.id;
    }

    addSection(id) {
        this.addSectionDivider(id);
        this.buildSections();
    }

    removeSection(id) {
        this.removeSectionDivider(id);
        this.buildSections();
    }

    displayBeforeLocationDivider(attribute, index) {
        return (attribute.questionType === 'geometry') && (index > 0) && (this.attributesWithSections[index - 1].type !== 'divider')
    }

    displayAfterLocationDivider(attribute, index) {
        return (attribute.questionType === 'geometry')
            && (index < this.attributesWithSections.length - 1)
            && (this.attributesWithSections[index + 1].type !== 'divider');
    }

    warnDeleted() {
        return new Promise((resolve, reject) => {
            this.$mdDialog.show({
                backdrop: 'static',
                template: require('!raw-loader!./deleted-warning.modal.html').default,
                resolve: {
                    deletedCount: () => this.deleted.length
                },
                controllerAs: 'ctrl',
                controller: ['$mdDialog', 'deletedCount', function ($mdDialog, deletedCount) {
                    this.deletedCount = deletedCount;
                    this.ok = () => {
                        $mdDialog.hide();
                        resolve();
                    };
                    this.cancel = () => {
                        $mdDialog.cancel();
                        reject();
                    };
                }]
            }).catch(angular.noop);
        });
    }

    /**
     * View deleted questions
     */

    viewDeleted() {
        var vm = this;
        this.$mdDialog.show({
            backdrop: 'static',
            template: require('!raw-loader!./deleted.modal.html').default,
            resolve: {
                readonly: () => this.readonly
            },
            controllerAs: 'ctrl',
            controller: ['$scope', '$mdDialog', '$ngRedux', 'ProjectFormActions', 'readonly', function ($scope, $mdDialog, $ngRedux, ProjectFormActions, readonly) {
                $scope.$on('$destroy', $ngRedux.connect((state) => {
                    return {
                        deleted: getFormDeleted(state),
                        forms: getProjectForms(state),
                    }
                }, ProjectFormActions)(this));

                this.readonly = readonly;

                this.restore = (id) => {
                    this.restoreAttribute(id);
                    vm.buildSections();
                };

                this.ok = () => {
                    $mdDialog.hide();
                };
            }]
        });
    };

    /**
     * Check the form's dirty state and prompt the user to save if dirty.
     * @returns A promise that resolves once the user has saved or rolled back, and rejects if they cancel.
     */
    checkUnsaved() {

        return this.$mdDialog.show({
            parent: angular.element(document.body),
            // targetEvent: ev,
            clickOutsideToClose: true,
            template: require('!raw-loader!./unsaved-form.modal.html').default,
            controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                // $scope.actionName = actionName;
                // $scope.hideDontSave = hideDontSave;
                // $scope.saveText = saveText;
                $scope.ok = function () {
                    $mdDialog.hide('save');
                };
                $scope.dontSave = function () {
                    $mdDialog.hide('dont');
                };

                $scope.cancel = function () {
                    $mdDialog.cancel();
                };
            }]
        }).catch(angular.noop);
    }

    updateForm() {
        this.updateFormSettings(
            this.formName,
            this.formTitle,
            this.formTitleAttributeId,
            this.formThankyou,
            this.formStyle,
            this.formAllowMemberUpdate,
            this.formAllowOwnRecordDelete,
            this.formVisibility,
            this.formDescriptionAttributeId,
            this.formPrivate)
    }

    select(event, attribute) {
        // event.preventDefault();
        // event.stopPropagation();
        this.selectAttribute(attribute.id);
    }

}
