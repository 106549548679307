import './to-admin-area.scss';
export const ToAdminAreaComponent = {
    bindings: {
        token: '<'
    },
    transclude: true,
    template: require('!raw-loader!./to-admin-area.html').default,
    controllerAs: 'ctrl',
    controller: class ToAdminAreaController {
        static $inject = ['$state'];
        constructor($state) {
            this.$state = $state;
        }

        $onInit() { }

        onClick(_e) {
            this.$state.go('intermediarypage.authenticate', { token: this.token });
        }
    }

};
