export const WELCOME_MODAL = 'WELCOME_MODAL';
export const HOME_TUTORIAL = 'HOME_TUTORIAL';
export const PROJECT_DASHBOARD_TUTORIAL = 'PROJECT_DASHBOARD_TUTORIAL';

export const USER_PREFERENCES =  {
    [WELCOME_MODAL]: 'welcomeModal',
    [HOME_TUTORIAL]: 'homeTutorial',
    [PROJECT_DASHBOARD_TUTORIAL]: 'projectDashboardTutorial',
};

export const USER_PREFERENCE_VERSIONS = {
    [WELCOME_MODAL]: 1,
    [HOME_TUTORIAL]: 1,
    [PROJECT_DASHBOARD_TUTORIAL]: 1,
};
