import { getOrganisationId, getOrganisationMembership } from "../selectors";

export const LOAD_ORGANISATIONS = 'LOAD_ORGANISATIONS';
export const LOAD_ORGANISATIONS_SUCCESS = 'LOAD_ORGANISATIONS_SUCCESS';
export const LOAD_ORGANISATIONS_FAILURE = 'LOAD_ORGANISATIONS_FAILURE';
export const LOAD_ORGANISATION = 'LOAD_ORGANISATION';
export const LOAD_ORGANISATION_SUCCESS = 'LOAD_ORGANISATION_SUCCESS';
export const LOAD_ORGANISATION_FAILURE = 'LOAD_ORGANISATION_FAILURE';
export const LOAD_ORGANISATION_MEMBERSHIPS = 'LOAD_ORGANISATION_MEMBERSHIPS';
export const LOAD_ORGANISATION_MEMBERSHIPS_SUCCESS = 'LOAD_ORGANISATION_MEMBERSHIPS_SUCCESS';
export const LOAD_ORGANISATION_MEMBERSHIPS_FAILURE = 'LOAD_ORGANISATION_MEMBERSHIPS_FAILURE';
export const LOAD_ORGANISATION_MEMBERSHIP = 'LOAD_ORGANISATION_MEMBERSHIP';
export const LOAD_ORGANISATION_MEMBERSHIP_SUCCESS = 'LOAD_ORGANISATION_MEMBERSHIP_SUCCESS';
export const LOAD_ORGANISATION_MEMBERSHIP_FAILURE = 'LOAD_ORGANISATION_MEMBERSHIP_FAILURE';
export const ORGANISATION_INVITE_MEMBER = 'ORGANISATION_INVITE_MEMBER';
export const ORGANISATION_INVITE_MEMBER_SUCCESS = 'ORGANISATION_INVITE_MEMBER_SUCCESS';
export const ORGANISATION_INVITE_MEMBER_FAILURE = 'ORGANISATION_INVITE_MEMBER_FAILURE';
export const ORGANISATION_UPDATE = 'ORGANISATION_UPDATE';
export const ORGANISATION_UPDATE_SUCCESS = 'ORGANISATION_UPDATE_SUCCESS';
export const ORGANISATION_UPDATE_FAILURE = 'ORGANISATION_UPDATE_FAILURE';
export const ORGANISATION_UPDATE_MEMBERSHIP_SUCCESS = 'ORGANISATION_UPDATE_MEMBERSHIP_SUCCESS';
export const ORGANISATION_UPDATE_MEMBERSHIP_FAILURE = 'ORGANISATION_UPDATE_MEMBERSHIP_FAILURE';
export const ORGANISATION_UPDATE_MEMBERSHIPS_SUCCESS = 'ORGANISATION_UPDATE_MEMBERSHIP_SUCCESS';
export const ORGANISATION_UPDATE_MEMBERSHIPS_FAILURE = 'ORGANISATION_UPDATE_MEMBERSHIPS_FAILURE';
export const ORGANISATION_REMOVE_MEMBERSHIP_SUCCESS = 'ORGANISATION_REMOVE_MEMBERSHIP_SUCCESS';
export const ORGANISATION_REMOVE_MEMBERSHIP_FAILURE = 'ORGANISATION_REMOVE_MEMBERSHIP_FAILURE';
export const ORGANISATION_CANCEL_PENDING_INVITE = 'ORGANISATION_CANCEL_PENDING_INVITE';
export const ORGANISATION_CANCEL_PENDING_INVITE_SUCCESS = 'ORGANISATION_CANCEL_PENDING_INVITE_SUCCESS';
export const ORGANISATION_CANCEL_PENDING_INVITE_FAILURE = 'ORGANISATION_CANCEL_PENDING_INVITE_FAILURE';
export const ORGANISATION_RESEND_PENDING_INVITE = 'ORGANISATION_RESEND_PENDING_INVITE';
export const ORGANISATION_RESEND_PENDING_INVITE_SUCCESS = 'ORGANISATION_RESEND_PENDING_INVITE_SUCCESS';
export const ORGANISATION_RESEND_PENDING_INVITE_FAILURE = 'ORGANISATION_RESEND_PENDING_INVITE_FAILURE';
export const ORGANISATION_START_FREE_TRIAL_SUCCESS = 'ORGANISATION_START_FREE_TRIAL_SUCCESS';
export const ORGANISATION_START_FREE_TRIAL_FAILURE = 'ORGANISATION_START_FREE_TRIAL_FAILURE';
export const UPDATE_PENDING_ORGANISATION_USER_PROJECTS_SUCCESS = 'UPDATE_PENDING_ORGANISATION_USER_PROJECTS_SUCCESS';
export const CLEAR_ORGANISATIONS = 'CLEAR_ORGANISATIONS';

function organisationsActions(CoreoAPI) {

    const loadOrganisations = (adminOrgId) => async (dispatch) => {
        const query = `{viewer{organisationMemberships{role,
            organisation{
                id
                name
                slug
                imageUrl
                freeTrialEnd
                freeTrialExpired
                seats
                tier {
                    id
                    name
                }
            }}}
        }`;

        const adminQuery = `query CoreoAAGetOrg($id: Int!){
            organisation(id: $id){
                id
                name
                slug
                imageUrl
                freeTrialEnd
                freeTrialExpired
                seats
                tier {
                    id
                    name
                }
            }
        }`

        dispatch({
            type: LOAD_ORGANISATIONS
        });

        try {
            const result = await CoreoAPI.query(query);
            const organisations = result.viewer.organisationMemberships.map(m => ({
                role: m.role,
                ...m.organisation
            }));

            if (typeof adminOrgId !== 'undefined' && adminOrgId && typeof organisations.find(o => o.id === adminOrgId) === 'undefined') {
                const aa = await CoreoAPI.gql(adminQuery, { id: adminOrgId });
                if (aa.organisation) {
                    organisations.push({
                        ...aa.organisation,
                        role: 'admin'
                    });
                }
            }
            organisations.sort((a, b) => a.name.localeCompare(b.name));

            dispatch({
                type: LOAD_ORGANISATIONS_SUCCESS,
                organisations
            });
            return organisations;
        } catch (e) {
            dispatch({
                type: LOAD_ORGANISATIONS_FAILURE,
                err: e
            });
        }
    };

    const loadOrganisation = (id) => (dispatch) => {
        dispatch({
            type: LOAD_ORGANISATION
        });
        const query = `query getOrganisation($id: Int!){ 
            organisations(where: {id: $id}){ 
                id, 
                slug,
                name,
                seats,
                contributorSeats,
                contributorCount,
                role: viewerRole,
                imageUrl,
                projects{
                    id,
                    name,
                    imageUrl,
                    slug,
                    hasEmptySeats
                }
                freeTrialExpired,
                freeTrialEnd,
                tier {
                    name,
                    id
                }
            }
        }`;

        return CoreoAPI.query(query, { variables: { id } }).then(({ organisations }) => {
            const [organisation] = organisations;
            if (!organisation) {
                dispatch({
                    type: LOAD_ORGANISATION_FAILURE,
                    err: 'Organisation Not Found'
                });
                return null;
            }

            dispatch({
                type: LOAD_ORGANISATION_SUCCESS,
                organisation
            });
            return organisation;
        }, err => dispatch({
            type: LOAD_ORGANISATION_FAILURE,
            err
        }));
    };

    const loadOrganisationMemberships = () => (dispatch, getState) => {
        const id = getOrganisationId(getState());

        dispatch({
            type: LOAD_ORGANISATION_MEMBERSHIPS
        });

        const query = `query getOrganisationMemberships($id: Int!){ 
            organisation(id: $id){ 
                memberships {
                    pendingEmail,
                    pendingProjectMap,
                    createdAt,
                    userId,
                    user{
                        id,
                        displayName,
                        username,
                        imageUrl,
                        email,
                        deletedAt
                    },
                    role,
                    id,
                } 
            }
        }`;

        return CoreoAPI.query(query, { variables: { id } }).then(({ organisation }) => {
            const { memberships } = organisation;
            dispatch({
                type: LOAD_ORGANISATION_MEMBERSHIPS_SUCCESS,
                memberships: memberships.filter(m => !m.user || (m.user && !m.user.deletedAt))
            });
            return memberships;
        }, err => dispatch({
            type: LOAD_ORGANISATION_MEMBERSHIPS_FAILURE,
            err
        }));
    };

    const loadOrganisationMembership = (userId) => (dispatch, getState) => {
        const id = getOrganisationId(getState());

        dispatch({
            type: LOAD_ORGANISATION_MEMBERSHIP
        });

        const query = `query getOrganisationMembership{ 
            organisation(id: ${id}){ 
                memberships(where: { userId: ${userId}} ){
                    userId,
                    role,
                    user{
                        id,
                        displayName,
                        username,
                        email
                    },
                    projectMemberships{
                        role,
                        project{
                            id,
                            name
                            imageUrl
                            count: recordsAggregate(function: "COUNT", where: { userId: ${userId} })
                        }
                    }
                    role
                } 
            }
        }`;

        let aborted = false;
        CoreoAPI.query(query, {}).then(({ organisation }) => {
            const { memberships } = organisation;
            const membership = memberships[0];
            if (membership.projectMemberships === null) {
                membership.projectMemberships = [];
            }
            membership.projectMemberships.sort((a, b) => a.project.name.localeCompare(b.project.name));

            if (!aborted) {
                dispatch({
                    type: LOAD_ORGANISATION_MEMBERSHIP_SUCCESS,
                    membership
                });
            }
        }, err => !aborted && dispatch({
            type: LOAD_ORGANISATION_MEMBERSHIP_FAILURE,
            err
        }));

        return () => aborted = true;
    };

    const inviteOrganisationMember = (email, role, projects) => (dispatch, getState) => {
        const organisationId = getOrganisationId(getState());

        dispatch({
            type: ORGANISATION_INVITE_MEMBER
        });

        const query = `mutation inviteOrganisationMember($organisationId: Int!, $email: String!, $role: String!, $projects: [InviteOrganisationUserProjectRole]){
            result: inviteOrganisationUser(input: { email: $email, organisationId: $organisationId, role: $role, projects: $projects}){
                role,
                createdAt,
                pendingEmail,
                pendingProjectMap
            }
        }`;

        return CoreoAPI.mutation(query, null, {
            variables: {
                email,
                organisationId,
                role,
                projects
            }
        }).then((result) => {
            dispatch({
                type: ORGANISATION_INVITE_MEMBER_SUCCESS,
                toast: "Organisation invitation sent",
                invitation: result
            });
            return result;
        }, err => {
            console.log(err, err.message)
            dispatch({
                type: ORGANISATION_INVITE_MEMBER_FAILURE,
                toastError: err.message || "Failed to send organisation invitation",
                err
            });
            throw err;
        })
    };

    const getOrganisationInvite = (token) => dispatch => {
        const query = `query getInvite($token: String!){
            invitation: pendingOrganisationUser(token: $token){
                exists,
                user: organisationUser{
                    email: pendingEmail,
                    organisation{
                        name,
                        imageUrl
                    },
                    id,
                }
            }
        }`;
        return CoreoAPI.query(query, { variables: { token } }).then(result => {
            if (!result.invitation) {
                return null;
            }
            return {
                email: result.invitation.user.email,
                organisation: result.invitation.user.organisation,
                exists: result.invitation.exists
            };
        });
    };

    const cancelPendingOrganisationInvite = (email) => (dispatch, getState) => {
        const organisationId = getOrganisationId(getState());
        const query = `mutation cancelPendingOrganisationInvite($email: String!, $organisationId: Int!){
            result: dismissPendingOrganisationUser(input:{email: $email, organisationId: $organisationId})
        }`;

        return CoreoAPI.mutation(query, null, { variables: { email, organisationId } }).then(result => {
            dispatch({
                type: ORGANISATION_CANCEL_PENDING_INVITE_SUCCESS,
                toast: 'Pending invitation cancelled',
                email
            });
        });
    }

    const resendPendingOrganisationInvite = (email) => (dispatch, getState) => {
        const organisationId = getOrganisationId(getState());
        const query = `mutation resendPendingOrganisationUserInvite($email: String!, $organisationId: Int!){
            result: resendPendingOrganisationUserInvite(input:{email: $email, organisationId: $organisationId})
        }`;
        return CoreoAPI.mutation(query, null, { variables: { email, organisationId } }).then(result => {
            dispatch({
                type: ORGANISATION_RESEND_PENDING_INVITE_SUCCESS,
                toast: 'Pending invitation resent',
                email
            });
        });
    }

    const updateOrganisation = (name, image) => (dispatch, getState) => {
        const organisationId = getOrganisationId(getState());

        dispatch({
            type: ORGANISATION_UPDATE
        });

        const query = `mutation updateOrganisation($organisationId: Int!, $name: String!){
            result: updateOrganisation(input: { id: $organisationId, name: $name}){
                id,
                imageUrl
            }
        }`;

        const files = image ? { imageUrl: image } : {};

        return CoreoAPI.mutation(query, files, {
            variables: {
                organisationId,
                name
            }
        }).then((result) => {
            dispatch({
                type: ORGANISATION_UPDATE_SUCCESS,
                update: {
                    name,
                    imageUrl: result.imageUrl
                },
                toast: 'Organisation updated'
            });
        }, err => dispatch({
            type: ORGANISATION_UPDATE_FAILURE,
            err
        }))
    };

    const updateOrganisationMembership = (userId, role) => (dispatch, getState) => {
        const organisationId = getOrganisationId(getState());

        const query = `mutation updateOrganisationUser($organisationId: Int!, $userId: Int!, $role: String!){
            updateOrganisationUser(input: { organisationId: $organisationId, userId: $userId, role: $role}){
                role
            }
        }`;
        return CoreoAPI.mutation(query, null, {
            variables: {
                organisationId,
                userId,
                role
            }
        }).then(() => {
            dispatch({
                type: ORGANISATION_UPDATE_MEMBERSHIP_SUCCESS,
                userId,
                role,
                toast: 'Organisation Membership updated'
            });
            return getOrganisationMembership(getState());
        }, err => dispatch({
            type: ORGANISATION_UPDATE_MEMBERSHIP_FAILURE,
            toastError: 'Failed to update membership',
            err
        }));
    };

    const addPendingOrganisationUserToProject = (pendingOrganisationUserId, projectId, role) => (dispatch) => {
        const mutation = `
      mutation addProjectToPendingOrganisationUser($input: addProjectToPendingOrganisationUserInputType!) {
         result: addProjectToPendingOrganisationUser(input: $input) {
            id,
            pendingProjectMap,
        }
      }
    `;

        return CoreoAPI.mutation(mutation, null, { variables: { input: { pendingOrganisationUserId, projectId, role } } }).then(result => dispatch({ type: UPDATE_PENDING_ORGANISATION_USER_PROJECTS_SUCCESS, id: result.id, pendingProjectMap: result.pendingProjectMap }), err => dispatch({ type: ORGANISATION_UPDATE_MEMBERSHIP_FAILURE, toastError: 'Failed to add project to pending user', err }));
    }

    const removePendingOrganisationUserProjectMembership = (pendingOrganisationUserId, projectId) => (dispatch) => {
        const mutation = `
      mutation deleteProjectFromPendingOrganisationUser($input: deleteProjectFromPendingOrganisationUserInputType!) {
        result: deleteProjectFromPendingOrganisationUser(input: $input) {
            id,
            pendingProjectMap,
        }
      }
    `;

        return CoreoAPI.mutation(mutation, null, { variables: { input: { pendingOrganisationUserId, projectId } } }).then(result => dispatch({ type: UPDATE_PENDING_ORGANISATION_USER_PROJECTS_SUCCESS, id: result.id, pendingMembershipProjects: result.pendingProjectMap }), err => dispatch({ type: ORGANISATION_UPDATE_MEMBERSHIP_FAILURE, toastError: 'Failed to remove project from user', err }));
    }

    const updatePendingOrganisationUserProjectMembership = (pendingOrganisationUserId, projectId, role) => (dispatch) => {
        const mutation = `
          mutation updateProjectFromPendingOrganisationUser($input: updateProjectFromPendingOrganisationUserInputType!) {
             result: updateProjectFromPendingOrganisationUser(input: $input) {
                id,
                pendingProjectMap,
            }
          }
        `;

        return CoreoAPI.mutation(mutation, null, { variables: { input: { pendingOrganisationUserId, projectId, role } } }).then(result => dispatch({ type: UPDATE_PENDING_ORGANISATION_USER_PROJECTS_SUCCESS, id: result.id, pendingProjectMap: result.pendingProjectMap }), err => dispatch({ type: ORGANISATION_UPDATE_MEMBERSHIP_FAILURE, toastError: 'Failed to add project to user', err }));
    }

    const addOrganisationUserToProject = (userId, projectId, role) => (dispatch, getState) => {
        const organisationId = getOrganisationId(getState());

        const mutation = `
      mutation addOrganisationUserToProject($input: addOrganisationUserToProjectInput!) {
        addOrganisationUserToProject(input: $input) {
          project {
            id,
            name,
          }
        }
      }
    `;

        return CoreoAPI.mutation(mutation, null, { variables: { input: { userId, projectId, role, organisationId } } }).then(() => dispatch(loadOrganisationMembership(userId)), err => dispatch({ type: ORGANISATION_UPDATE_MEMBERSHIP_FAILURE, toastError: 'Failed to add project to user', err }));
    }

    const removeOrganisationProjectMembership = (userId, projectId) => (dispatch) => {
        const mutation = `
      mutation deleteProjectMembership($input: projectMembershipDeleteInput!) {
        deleteProjectMembership(input: $input)
      }
    `;

        return CoreoAPI.mutation(mutation, null, { variables: { input: { userId, projectId } } }).then(() => dispatch(loadOrganisationMembership(userId)), err => dispatch({ type: ORGANISATION_UPDATE_MEMBERSHIP_FAILURE, toastError: 'Failed to remove project from user', err }));
    }

    const updateOrganisationUserProjectMembership = (userId, projectId, role) => (dispatch) => {
        const mutation = `
          mutation updateProjectMembership($input: updateProjectMembershipInput!) {
             updateProjectMembership(input: $input) {
              project {
                id,
                name,
              }
            }
          }
        `;

        return CoreoAPI.mutation(mutation, null, { variables: { input: { userId, projectId, role } } }).then(() => dispatch(loadOrganisationMembership(userId)), err => dispatch({ type: ORGANISATION_UPDATE_MEMBERSHIP_FAILURE, toastError: 'Failed to add project to user', err }));
    }

    const updateOrganisationUserProjectMemberships = (userId, memberships) => (dispatch, getState) => {

        const organisationId = getOrganisationId(getState());
        const inputMemberships = memberships.map(m => ({
            role: m.role,
            remove: !m.role,
            projectId: m.projectId
        }))
        const mutation = `
          mutation updateProjectMemberships($input: updateProjectMembershipsInput!) {
             updateProjectMemberships(input: $input) {
              project {
                id,
                name,
              }
            }
          }
        `;
        return CoreoAPI.mutation(mutation, null, { variables: { input: { userId, organisationId, memberships: inputMemberships } } })
            .then(() => {
                dispatch(loadOrganisationMembership(userId));
                dispatch({ type: ORGANISATION_UPDATE_MEMBERSHIPS_SUCCESS, toast: "Project roles updated" })
            }, err => dispatch({ type: ORGANISATION_UPDATE_MEMBERSHIPS_FAILURE, toastError: 'Failed to update user\'s project roles', err }));
    }

    const removeOrganisationMembership = (userId) => (dispatch, getState) => {
        const organisationId = getOrganisationId(getState());

        const query = `mutation removeOrganisationUser($organisationId: Int!, $userId: Int!){
            result: removeOrganisationUser(input: { organisationId: $organisationId, userId: $userId})
        }`;
        return CoreoAPI.mutation(query, null, {
            variables: {
                organisationId,
                userId
            }
        }).then(() => {
            dispatch({
                type: ORGANISATION_REMOVE_MEMBERSHIP_SUCCESS,
                userId,
                toast: 'Organisation Membership removed'
            });
        }, err => dispatch({
            type: ORGANISATION_REMOVE_MEMBERSHIP_FAILURE,
            toastError: 'Failed to remove membership',
            err
        }));
    }

    const startFreeTrial = organisationName => dispatch => {
        const query = `mutation startFreeTrial($organisationName: String!){
            result: createOrganisation(input: { name: $organisationName, freeTrial: true}){
                id,
                name,
                slug,
                imageUrl,
                freeTrialEnd,
                freeTrialExpired,
                tier {
                    name,
                    id
                }
            }
        }`;
        return CoreoAPI.mutation(query, null, {
            variables: {
                organisationName
            }
        }).then(organisation => {
            dispatch({
                type: ORGANISATION_START_FREE_TRIAL_SUCCESS,
                organisation
            })
            return Promise.resolve(organisation);
        }, err => {
            dispatch({
                type: ORGANISATION_START_FREE_TRIAL_FAILURE,
                toastError: 'Failed to start free trial',
                err
            })
        })
    }

    const clearOrganisations = () => dispatch => {
        dispatch({
            type: CLEAR_ORGANISATIONS,
        });
    }

    return {
        clearOrganisations,
        loadOrganisations,
        loadOrganisation,
        loadOrganisationMemberships,
        loadOrganisationMembership,
        inviteOrganisationMember,
        getOrganisationInvite,
        cancelPendingOrganisationInvite,
        resendPendingOrganisationInvite,
        updateOrganisation,
        updateOrganisationMembership,
        removeOrganisationMembership,
        startFreeTrial,
        addOrganisationUserToProject,
        removeOrganisationProjectMembership,
        updateOrganisationUserProjectMembership,
        addPendingOrganisationUserToProject,
        removePendingOrganisationUserProjectMembership,
        updatePendingOrganisationUserProjectMembership,
        updateOrganisationUserProjectMemberships,
    }
};
organisationsActions.$inject = ['CoreoAPI'];
export default organisationsActions;