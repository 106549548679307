@if(requestLog){
<div class=" flex w-full gap-4 px-4 py-8">
    <p-panel class="p-0 w-full" [header]="'Request ' + requestLog.id">
        <ul class="p-4">
            <li>
                <label>ID</label>
                <span>{{requestLog.id}}</span>
            </li>
            <li>
                <label>Path</label>
                <span>{{requestLog.path}}</span>
            </li>
            <li>
                <label>Method</label>
                <span>{{requestLog.method}}</span>
            </li>
            <li>
                <label>Origin</label>
                <span>{{requestLog.origin}}</span>
            </li>
            <li>
                <label>Browser</label>
                <span>{{requestLog.browser}}</span>
            </li>
            <li>
                <label>Browser Version</label>
                <span>{{requestLog.browserVersion}}</span>
            </li>
            <li>
                <label>OS</label>
                <span>{{requestLog.os}}</span>
            </li>
            <li>
                <label>Device Type</label>
                <span>{{requestLog.deviceType}}</span>
            </li>
            <li>
                <label>User ID</label>
                <span>{{requestLog.user?.id}}</span>
            </li>
            <li>
                <label>User Name</label>
                <span>{{requestLog.user?.displayName}}</span>
            </li>
            <li>
                <label>App Build</label>
                <span>{{requestLog.appBuild}}</span>
            </li>
            <li>
                <label>App Version Name</label>
                <span>{{requestLog.appVersion}}</span>
            </li>
            <li>
                <label>App Version Name</label>
                <span>{{requestLog.responseCode}}</span>
            </li>
            <li>
                <label>App Version Name</label>
                <span>{{requestLog.responseStatus}}</span>
            </li>
            <li>
                <label>Time Taken</label>
                <span>{{requestLog.timeTaken}}</span>
            </li>
            <li>
                <label>Query</label>
                <span>{{requestLog.query | json}}</span>
            </li>
            <li>
                <label>Body</label>
            </li>
        </ul>
        <pre class="p-4 font-mono text-sm whitespace-pre-wrap bg-grey-80 text-white">{{requestLog.body | json}}</pre>
    </p-panel>
</div>
}