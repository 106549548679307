import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { ApiService } from 'src/app/core';
import { AttributeQuestionType, AttributeType } from '../../record-form/form.types';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImageModalComponent, ImageModalProps } from '../../../../shared/components/image-modal/image-modal.component';
import { RecordAttachment } from '../../records.service';
import { TwicPicPipe } from 'src/app/shared/pipes/twicpic.pipe';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

interface RecordDataSummaryResponse {
    id: number;
    data: { [key: string]: any; };
    attachments: {
        id: number;
        url: string;
        mimeType: string;
        attributeId: number;
    }[];
    associates: {
        association: {
            id: number;
            path: string;
            label: string;
            survey: {
                title: string;
            }
        };
        record: {
            id: number;
            title: string;
        }
    }[];
    survey: {
        attributes: {
            id: number;
            label: string;
            path: string;
            order: number;
            type: AttributeType;
            questionType: AttributeQuestionType;
            collection: {
                items: {
                    key: string;
                    value: string;
                }[];
            };
        }[];
    };
}

interface RecordDataSummaryItem {
    attribute: {
        label: string;
        type: string;
        questionType: string;
        path: string;
    };
    value: any;
    highlight?: string;
}

type OtherAssociates = {
    label: string;
    associates: {
        title: string;
        id: number;
    }[]
}

@Component({
    selector: 'app-record-summary',
    templateUrl: 'record-summary.component.html',
    standalone: true,
    imports: [
        CommonModule,
        AsyncPipe,
        DatePipe,
        DynamicDialogModule,
        ImageModalComponent,
        TwicPicPipe,
        ProgressSpinnerModule
    ],
    providers: [
        DialogService
    ],
})

export class RecordSummaryComponent implements OnInit {

    @Input()
    set recordId(value: number) {
        this._recordId = value;
        this.getRecordData();
    }
    get recordId(): number {
        return this._recordId;
    }
    @Input() readonly: boolean = true;
    /** For highlighting and replacing diff in review changes */
    @Input() diff: any[];
    @Input() highlight: boolean = false;
    @Input() replace: boolean = false;

    @Output() record: EventEmitter<number> = new EventEmitter();

    private _recordId: number;
    public recordData$: Observable<RecordDataSummaryItem[]>;
    otherAssociates = signal<OtherAssociates[]>([]);
    private dialogRef: DynamicDialogRef | undefined;

    constructor(
        private apiService: ApiService,
        private datePipe: DatePipe,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.getRecordData();
    }

    private getRecordData() {
        const query = `query getRecordData($id: Int){
            record(id: $id){
                id
                data
                attachments{
                    id
                    url
                    mimeType
                    attributeId
                }
                associates{
                    association{
                        id
                        path
                        label
                        survey{
                            title
                        }
                    }
                    record{
                        id
                        title
                    }
                }
                survey{
                    attributes{
                        id
                        label
                        path
                        order
                        type
                        questionType
                        collection{
                            items{
                                key
                                value
                            }
                        }
                    }
                }
            }
        }`;

        this.recordData$ = this.apiService.graphql<{ record: RecordDataSummaryResponse }>(query, { id: this.recordId }).pipe(
            map(res => this.formatData(res.record))
        );
    }

    private formatData(record: RecordDataSummaryResponse): RecordDataSummaryItem[] {
        // console.log('RecordDataSummaryResponse', record);
        const { associates, attachments, data, survey } = record;
        const { attributes } = survey;

        /** Return associated records that aren't part of the survey attributes ie reverse lookup direction */
        const otherAssociates = associates.filter(a => {
            const idx = attributes.findIndex(attr => attr.path === a.association.path);
            return idx < 0;
        })

        const sortedObj = otherAssociates.reduce((acc, item) => {
            return {
                ...acc,
                [item.association.survey.title]: [
                    ...(acc[item.association.survey.title] || []),
                    item.record
                ]
            }
        }, {});

        const result = Object.keys(sortedObj).map((key) => {
            const item = sortedObj[key];
            return {
                label: key,
                associates: item
            }
        });

        this.otherAssociates.set(result);

        return attributes
            .filter(a => a.questionType !== 'geometry')
            .filter(a => {
                /** If highlighting or replace remove attachments and media as they don't show up in diff */
                if (this.highlight || this.replace) {
                    return !(a.type === 'attachment' || a.type === 'media');
                } else {
                    return true;
                }
            })
            .sort((a, b) => a.order - b.order)
            .map(attribute => {
                let att = { label: attribute.label, type: attribute.type, questionType: attribute.questionType, path: attribute.path };
                let value = data[attribute.path];
                let highlight = null;

                if (attribute.type === 'boolean') {
                    if (value === undefined || value === null) {
                        value = null;
                    } else {
                        value = value ? 'Yes' : 'No';
                    }
                }
                if (!!value && attribute.type === 'datetime') {
                    value = this.datePipe.transform(data[attribute.path], "d MMMM y 'at' hh:mm a");
                }
                if (!!value && attribute.type === 'date') {
                    value = this.datePipe.transform(data[attribute.path], "d MMMM y");
                }
                if (attribute.type === 'media' || attribute.type === 'attachment') {
                    const media = attachments.filter(attachment => attachment.attributeId === attribute.id);
                    if (media.length > 0) {
                        value = media;
                    } else {
                        value = null;
                    }
                }
                if (!!value && attribute.type === 'select') {
                    const item = attribute.collection.items.find(a => a.key === data[attribute.path]);
                    value = item.value;
                }
                if (!!value && attribute.type === 'multiselect') {
                    const items = data[attribute.path].map(item => {
                        return attribute.collection.items.find(a => a.key === item).value;
                    });
                    value = items;
                }
                if (!!value && attribute.type === 'geometryquery') {
                    const item = attribute.collection.items.find(a => a.key === data[attribute.path]);
                    value = item.value;
                }
                const hasAssociate: boolean = !!associates.find(a => a.association.path === attribute.path);
                if (hasAssociate) {
                    const records = associates.filter(a => a.association.path === attribute.path).map(a => a.record);
                    value = records;
                }

                /** If highlighting or replacing add highlight colour and replace value as necessary */
                if (this.highlight || this.replace) {
                    const diff: any = this.diff.find(d => {
                        const splitPath = d.path.split('/');
                        const diffPath = splitPath.pop();
                        return diffPath === attribute.path;
                    });

                    if (!!diff) {
                        highlight = this.highlight ? '#009E1933' : '#CD564233';

                        if (this.replace) {
                            value = diff.value;
                        }
                    }
                }

                return { attribute: att, value, highlight };
            })
            .filter(data => {
                /** If highlighting or replacing show all values, else remove null, undefined or empty values from summary */
                if (this.highlight || this.replace) {
                    return true;
                } else {
                    return !(data.value === undefined || data.value === null || data.value.length < 1);
                }
            });
    }

    public clickRecord(id: number) {
        if (this.readonly) {
            return;
        }
        this.record.emit(id);
    }

    public clickImage(label: string, images: RecordAttachment[], selectedImage: RecordAttachment) {
        if (this.readonly) {
            return;
        }

        const imageProps: ImageModalProps = {
            images: images.map(image => image.url),
            index: images.findIndex(image => image.id === selectedImage.id)
        };

        this.dialogRef = this.dialogService.open(ImageModalComponent, {
            data: imageProps,
            header: `${label}`,
            width: '75vw',
            height: '90vh',
            modal: true,
            breakpoints: {
                '960px': '75vw',
                '640px': '90vw'
            },
            contentStyle: { 'padding': '0px' }
        });

        this.dialogRef.onClose.subscribe((data: { deleted: boolean }) => {
            if (data && data.deleted) {
                this.getRecordData();
            }
        });
    }

    public refresh() {
        this.getRecordData();
    }
}