export default class ProjectFormsBlockQuestionSummaryDirective {
  constructor() {
    this.restrict = 'E';
    this.scope = {
      block: '=',
      attribute: '=',
      forms: '='
    };
    this.template = '{{summary}}';
  }

  link(scope) {
    var formLookup = _.keyBy(scope.forms, 'id');

    scope.summary = summarise();
    scope.$watch('block', update, true);
    scope.$watch('attribute', update, true);
    scope.$watch('forms', update, true);

    function update() {
      formLookup = _.keyBy(scope.forms, 'id');
      scope.summary = summarise();
    }

    function summarise() {

      if (scope.block.type === 'geometry') {
        return scope.block.config && scope.block.config.type || 'Location';
      }
      if (scope.block.type === 'child') {
        if (scope.block.formId && formLookup[scope.block.formId]) {
          return formLookup[scope.block.formId].name;
        }
        return null;
      }

      if (!scope.attribute) {
        // New question
        return null;
      }
      switch (scope.attribute.type) {
        case 'integer':
        case 'float':
          var numType = 'Any ' + (scope.attribute.type === 'integer' ? 'whole number' : 'number');
          if (scope.attribute.meta) {
            var hasMin = typeof scope.attribute.meta.min !== undefined;
            var hasMax = typeof scope.attribute.meta.max !== undefined;
            if (hasMin && hasMax) {
              return numType + ' between ' + scope.attribute.meta.min + ' and ' + scope.attribute.meta.max;
            }
            if (hasMin) {
              return numType + ' above ' + scope.attribute.meta.min;
            }
            if (hasMax) {
              return numType + ' below ' + scope.attribute.meta.max;
            }
          }
          return numType;
        case 'multiselect':
        case 'select':
          var collection = scope.attribute.collection;
          if (!collection) {
            return null;
          }
          if (collection.items.length > 4) {
            return _.map(collection.items.slice(0, 3), 'value').join(', ') + '... (' + (collection.items.length - 3) + ' more)';
          }
          return _.map(collection.items, 'value').join(', ');
        case 'media':
          if (scope.attribute.meta) {
            switch (scope.attribute.meta.type) {
              case 'image': return '';
              case 'video': return 'Video files only';
              case 'text': return 'Text files only';
            }
          }
          return 'Any file';
        case 'date':
        case 'datetime':
          return (scope.block.config && scope.block.config.auto) ? 'Autocomplete' : '';
      }
      return '';
    }
  }
}