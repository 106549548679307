export default class OrganisationFreeTrialExpiredController {

    static $inject = ['$state', '$mdDialog'];
    constructor($state, $mdDialog) {
        this.$state = $state;
        this.$mdDialog = $mdDialog;
    }

    goHome() {
        this.$state.go('home');
    }
}