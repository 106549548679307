<!-- not using panel here so that we can have a sticky header -->
<div class="static w-full h-full rounded-lg bg-white shadow-panel">
    <div class="sticky top-0 z-[1] h-16 px-4 flex items-center justify-between rounded-t-lg bg-white border-b border-grey-15">
        <div class="flex items-center gap-3">
            <img src="/assets/icons/clipboard-list-light.svg" alt="" class="h-5">
            @if (editData) {
                <h3 class="text-lg font-semibold leading-none">Edit Data</h3>
            }
            @else {
                <h3 class="text-lg font-semibold leading-none">Form Data</h3>
            }
        </div>
        @if (canEdit) {
            @if (editData) {
                <div class="flex items-center justify-end gap-3">
                    <p-button [disabled]="submitting()" label="Cancel" [outlined]="true" (click)="cancelEditData()" styleClass="w-32"></p-button>
                    <p-button [disabled]="submitting()" [loading]="submitting()" label="Save" (click)="finishEditData($event)" styleClass="w-32"></p-button>
                </div>
            }
            @else {
                <p-button [disabled]="submitting()" label="Edit data" (click)="startEditData()" styleClass="w-32">
                    <img alt="" src="/assets/icons/edit-white.svg" class="mr-1" />
                </p-button>
            }
        }
    </div>
    <!-- content -->
    @if (editData) {
        <!-- edit form -->
        <div class="p-10">
            <app-record-form #recordForm [projectId]="recordData.projectId" [surveyId]="recordData.surveyId" [isEdit]="true" [recordId]="recordData.id"></app-record-form>
        </div>
    }
    @else {
        <!-- readonly form data -->
        <div class="p-7">
            <app-record-summary #recordSummary [readonly]="false" [recordId]="recordData.id" (record)="record.emit($event)"></app-record-summary>
        </div>
    }
</div>