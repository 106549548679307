import { getProjectCollections, getPageState, getProjectCollection, getProjectAttributesForCollection } from '../../store/selectors';

export default class ProjectPageCollectionController {

    static $inject = ['$scope', '$ngRedux', '$window', 'ProjectPageActions', 'previewUrl'];
    constructor($scope, $ngRedux, $window, ProjectPageActions, previewUrl) {

        this.$ngRedux = $ngRedux;

        $window.addEventListener('message', e => {
            if (e.data === 'COREO_PAGE_PREVIEW_READY') {
                this.updatePreview();
            }
        });

        this.previewUrl = previewUrl;

        this.collections = getProjectCollections($ngRedux.getState());

        $scope.$on('$destroy', $ngRedux.connect(state => {
            const page = angular.copy(getPageState(state));
            this.updatePreview(page);
            return {
                page,

            };
        }, ProjectPageActions)(this));

        if (!(this.page.config && this.page.config.defaultLayout)) {
            this.page.config.defaultLayout = 'list';
        }

        this.getPageCollection();
        this.updatePreview();
    }

    getPageCollection() {
        const { collectionId, attributes } = this.page.config;

        if (!collectionId) {
            return null;
        }
        const state = this.$ngRedux.getState();
        this.collection = getProjectCollection(collectionId)(state);
        this.collectionAttributes = getProjectAttributesForCollection(collectionId)(state).reduce((acc, attribute) => {
            acc.push({
                attribute,
                selected: (attributes || []).indexOf(attribute.id) !== -1
            });
            return acc;
        }, []);
    }

    updateCollection() {
        this.getPageCollection();
        this.page.config.attributes = [];
        this.update();
    }

    updateCollectionAttributes() {
        this.page.config.attributes = this.collectionAttributes.filter(a => a.selected).map(a => a.attribute.id);
        this.updatePageConfig(this.page.config);
    }

    update() {
        this.updatePageConfig(this.page.config);
    }

    sendToPreview(type, data) {
        const payload = {
            type,
            ...data
        };
        document.getElementById('preview').contentWindow.postMessage(payload, '*');
    }

    updatePreview(content = this.page) {
        if (!this.collection) {
            return;
        }
        this.sendToPreview('COREO_PAGE_PREVIEW_UPDATE', {
            page: JSON.stringify(content),
            collection: JSON.stringify(this.collection),
            collectionAttributes: JSON.stringify(this.collectionAttributes.map(a => a.attribute))
        });
    }
}
ProjectPageCollectionController.$inject = ['$scope', '$ngRedux', '$window', 'ProjectPageActions', 'previewUrl'];