
import ProjectTemplatesActions from "../store/project-templates/project-templates.actions";
import { getAuthIsAdmin, getOrganisation } from '../store/selectors';
import { OrganisationFreeTrialBannerComponent } from './free-trial-banner/free-trial-banner.component';
import OrganisationFreeTrialExpiredController from './free-trial-expired/free-trial-expired.controller.js';
import OrganisationInvitationController from './invitation/organisation-invitation.controller';
import OrganisationMembersController from './members/organisation-members.controller';
import OrganisationController from './organisation.controller.js';
import OrganisationSettingsController from './settings/organisation-settings.controller';

import { ORGANISATION_ADMIN, ORGANISATION_OWNER } from '../permissions/permissions.constants';

export default angular
    .module('app.organisations', ['ngSanitize', 'ui.router'])
    .service('ProjectTemplatesActions', ProjectTemplatesActions)
    .controller('OrganisationController', OrganisationController)
    .controller('OrganisationFreeTrialExpiredController', OrganisationFreeTrialExpiredController)
    .controller('OrganisationSettingsController', OrganisationSettingsController)
    .controller('OrganisationMembersController', OrganisationMembersController)
    .controller('OrganisationInvitationController', OrganisationInvitationController)
    .component('organisationFreeTrialBanner', OrganisationFreeTrialBannerComponent)
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('org', {
                abstract: true,
                url: '/{orgSlug:[^\/]+}',
                resolvePolicy: {
                    when: 'EAGER',
                    async: 'WAIT'
                },
                data: {
                    breadcrumb: {
                        label: '',
                        state: 'organisation.dashboard',
                        icon: 'fas fa-building'
                    }
                },
                resolve: {
                    organisation: ['$ngRedux', function ($ngRedux) {
                        return getOrganisation($ngRedux.getState());
                    }]
                },
                onEnter: ['$state', 'toastr', 'organisation', '$ngRedux', function ($state, toastr, organisation, $ngRedux) {
                    if (organisation) {
                        const isAdmin = getAuthIsAdmin($ngRedux.getState());
                        const state = $state.get('org');
                        state.data.breadcrumb.label = organisation.name;
                        if (organisation.freeTrialExpired && !isAdmin) {
                            $state.go('free-trial-expired', { orgSlug: organisation.slug });
                        }
                    } else {
                        $state.go('home');
                        toastr.error('Organisation not found');
                    }
                }],
                onRetain: ['$state', 'organisation', '$ngRedux', function ($state, organisation, $ngRedux) {
                    const isAdmin = getAuthIsAdmin($ngRedux.getState());
                    if (organisation && organisation.freeTrialExpired && !isAdmin) {
                        $state.go('free-trial-expired', { orgSlug: organisation.slug });
                    }
                }]
            })
            .state('free-trial-expired', {
                url: '/free-trial-expired',
                parent: 'organisation',
                template: require('!raw-loader!./free-trial-expired/free-trial-expired.html').default,
                controller: 'OrganisationFreeTrialExpiredController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Free Trial Expired',
                        parent: 'org'
                    }
                },
            })
            .state('organisation-admin', {
                abstract: true,
                template: require('!raw-loader!../layouts/app.layout.html').default,
                controller: 'AppLayoutController as ctrl',
                resolve: {
                    users: ['$ngRedux', 'OrganisationsActions', function ($ngRedux, OrganisationsActions) {
                        return $ngRedux.dispatch(OrganisationsActions.loadOrganisationMemberships());
                    }],
                    organisation: ['$ngRedux', function ($ngRedux) {
                        return getOrganisation($ngRedux.getState());
                    }]
                }
            })
            .state('organisation-admin.members', {
                url: '/organisation-members?name&username&role&email&state',
                template: require('!raw-loader!./members/organisation-members.html').default,
                controller: 'OrganisationMembersController as ctrl',
                params: {
                    email: {
                        value: null,
                        dynamic: true
                    },
                    name: {
                        value: null,
                        dynamic: true
                    },
                    username: {
                        value: null,
                        dynamic: true
                    },
                    role: {
                        value: null,
                        dynamic: true
                    },
                    state: {
                        value: null,
                        dynamic: true
                    }
                },
                resolve: {
                    projects: ['CoreoAPI', 'organisation', (CoreoAPI, organisation) => {
                        const query = `query( $orgId: Int! ) {
                            organisation(id: $orgId) {
                                projects{
                                    id
                                    name
                                    hasEmptySeats
                                }
                            }
                        }`
                        return CoreoAPI.gql(query, { orgId: organisation.id }).then(result => {
                            return result.organisation.projects;
                        });
                    }]
                },
                data: {
                    breadcrumb: {
                        label: 'Organisation Members',
                        parent: 'organisation-admin'
                    },
                    permissions: [
                        ORGANISATION_ADMIN
                    ]
                }
            })
            .state('organisation-admin.settings', {
                url: '/organisation-settings',
                template: require('!raw-loader!./settings/organisation-settings.html').default,
                controller: 'OrganisationSettingsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Organisation Settings',
                        parent: 'organisation-admin'
                    },
                    permissions: [
                        ORGANISATION_OWNER
                    ]
                }
            })
            .state('organisation-invitation', {
                url: '/invitations/org/:token',
                parent: 'unauthenticated',
                template: require('!raw-loader!./invitation/organisation-invitation.html').default,
                controller: 'OrganisationInvitationController as ctrl',
            });
    }]);