// import './project-forms-block-editor.scss';

export const ProjectFormsSectionDividerComponent = {
    bindings: {
        onRemove: '&?',
        message: '@'
    },
    template: require('!raw-loader!./project-forms-section-divider.component.html').default,
    controllerAs: 'ctrl',
    controller: class ProjectFormsSectionDividerComponent {
        constructor() {
        }

        $onInit() {
            this.showRemove = !!this.onRemove;
        }
    }
}