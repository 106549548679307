import { passwordStrength, defaultOptions } from 'check-password-strength';
const allowedSymbols = "!\"#\$%&'\(\)\*\+,-\./:;<=>\?@\[\\\\\\]\^_`\{|\}~£";
export default class PasswordCheckDirective {
    constructor($q) {
        this.restrict = 'A';
        this.require = 'ngModel';
        this.$q = $q;
    }
    link(scope, element, attrs, ngModel) {
        const checks = ['length', 'lowercase', 'uppercase', 'number', 'symbol'];

        ngModel.$validators.password = (modelValue, viewValue) => {
            let passedChecks = [];
            if (viewValue) {
                const test = passwordStrength(viewValue, defaultOptions, allowedSymbols);
                console.log(test)
                passedChecks = test.contains;
            }

            for (const c of checks) {
                ngModel.$setValidity(c, passedChecks.includes(c));
            }
            const lengthInvalid = !viewValue || viewValue.length < 8;
            ngModel.$setValidity('length', !lengthInvalid);
            return true;
        };
    }
}
