import { createSelector } from 'reselect'

import * as projectSelectors from './project/project.selectors';
import * as projectsSelectors from './projects/projects.selectors';
import * as projectTemplatesSelectors from './project-templates/project-templates.selectors';
import * as formSelectors from './form/form.selectors';
import * as authSelectors from './auth/auth.selectors';
import * as recordsSelectors from './records/records.selectors';
import * as recordSelectors from './record/record.selectors';
import * as pageSelectors from './page/page.selectors';
import * as organisationsSelectors from './organisations/organisations.selectors';
import { ALL_PERMISSIONS, ORGANISATION_OWNER, ORGANISATION_ADMIN, ORGANISATION_MEMBER, PROJECT_ADMIN, PROJECT_MODERATOR, PROJECT_MEMBER } from '../permissions/permissions.constants';

/**
 *
 */
export const getAuthState = (state) => state.auth;
export const getAuthUser = createSelector(getAuthState, authSelectors.getAuthUser);
export const getAuthUserImage = createSelector(getAuthState, authSelectors.getAuthUserImage);
export const getAuthUserPreferences = createSelector(getAuthState, authSelectors.getAuthUserPreferences);
export const getAuthUserUsedFreeTrial = createSelector(getAuthState, authSelectors.getAuthUserUsedFreeTrial);
export const getAuthIsAdmin = createSelector(getAuthState, authSelectors.getAuthIsAdmin);
export const getAuthIsImpersonating = createSelector(getAuthState, authSelectors.getAuthIsImpersonating);
export const getAuthIsLoggedIn = createSelector(getAuthState, authSelectors.getAuthIsLoggedIn);
export const getAuthToken = createSelector(getAuthState, authSelectors.getAuthToken);
export const getAuthOAuthError = createSelector(getAuthState, authSelectors.getAuthOAuthError);
export const getAuthOAuthErrorDescription = createSelector(getAuthState, authSelectors.getAuthOAuthErrorDescription);
export const getAuthAccountVerificationToken = createSelector(getAuthState, authSelectors.getAuthAccountVerificationToken);
export const getAuthPasswordResetToken = createSelector(getAuthState, authSelectors.getAuthPasswordResetToken);
export const getAuthProjectPermissions = createSelector(getAuthState, authSelectors.getAuthProjectPermissions);
export const getAuthOrganisationPermissions = createSelector(getAuthState, authSelectors.getAuthOrganisationPermissions);
export const getAuthIsLoggingIn = createSelector(getAuthState, authSelectors.getAuthIsLoggingIn);
export const getAuthLoginError = createSelector(getAuthState, authSelectors.getAuthLoginError);
export const getAuthProvider = createSelector(getAuthState, authSelectors.getAuthProvider);
export const getAuthOrganisationId = createSelector(getAuthState, authSelectors.getAuthOrganisationId);
export const getAuthUserId = createSelector(getAuthState, authSelectors.getAuthUserId);

export const getAuthRoleForProject = id => createSelector(getAuthState, authSelectors.getAuthRoleForProject(id));
export const getAuthRoleForOrganisation = id => createSelector(getAuthState, authSelectors.getAuthRoleForOrganisation(id));

/**
 * Project Selectors
 */
export const getProjectState = (state) => state.project;
export const getProject = createSelector(getProjectState, projectSelectors.getProject);
export const getProjectError = createSelector(getProjectState, projectSelectors.getProjectError);
export const getProjectLoading = createSelector(getProjectState, projectSelectors.getProjectLoading);
export const getProjectId = createSelector(getProjectState, projectSelectors.getProjectId);
export const getProjectOrganisationId = createSelector(getProjectState, projectSelectors.getProjectOrganisationId);
export const getProjectVerification = createSelector(getProjectState, projectSelectors.getProjectVerification);
export const getProjectAccessMode = createSelector(getProjectState, projectSelectors.getProjectAccessMode);
export const getProjectSlug = createSelector(getProjectState, projectSelectors.getProjectSlug);
export const getProjectOrg = createSelector(getProjectState, projectSelectors.getProjectOrg);
export const getProjectOrgSlug = createSelector(getProjectState, projectSelectors.getProjectOrgSlug);
export const getProjectOrgFeatures = createSelector(getProjectState, projectSelectors.getProjectOrgFeatures);
export const getProjectName = createSelector(getProjectState, projectSelectors.getProjectName);
export const getProjectShareableLink = createSelector(getProjectState, projectSelectors.getProjectShareableLink);
export const getProjectAllowContributors = createSelector(getProjectState, projectSelectors.getProjectAllowContributors);
export const getProjectForms = createSelector(getProjectState, projectSelectors.getProjectForms);
export const getProjectFormsContainingGeometry = createSelector(getProjectState, projectSelectors.getProjectFormsContainingGeometry);
export const getProjectCollections = createSelector(getProjectState, projectSelectors.getProjectCollections);
export const getProjectMaps = createSelector(getProjectState, projectSelectors.getProjectMaps);
export const getProjectMapLayers = createSelector(getProjectState, projectSelectors.getProjectMapLayers);
export const getProjectMapLayersState = createSelector(getProjectState, projectSelectors.getProjectMapLayersState);
export const getProjectMapLayer = id => createSelector(getProjectState, projectSelectors.getProjectMapLayer(id));
export const getProjectMap = id => createSelector(getProjectState, projectSelectors.getProjectMap(id));
export const getProjectCredentials = createSelector(getProjectState, projectSelectors.getProjectCredentials);
export const getProjectAttributes = createSelector(getProjectState, projectSelectors.getProjectAttributes);
export const getProjectGeometry = createSelector(getProjectState, projectSelectors.getProjectGeometry);
export const getProjectBoundingBox = createSelector(getProjectState, projectSelectors.getProjectBoundingBox);
export const getProjectApiKeys = createSelector(getProjectState, projectSelectors.getProjectApiKeys);
export const getProjectStats = createSelector(getProjectState, projectSelectors.getProjectStats);
export const getProjectStates = createSelector(getProjectState, projectSelectors.getProjectStates);
export const getProjectBespoke = createSelector(getProjectState, projectSelectors.getProjectBespoke);
export const getProjectLocked = createSelector(getProjectState, projectSelectors.getProjectLocked);
export const getProjectParentProjectId = createSelector(getProjectState, projectSelectors.getProjectParentProjectId);
export const getProjectIsChild = createSelector(getProjectState, projectSelectors.getProjectIsChild);
export const getProjectHooks = createSelector(getProjectState, projectSelectors.getProjectHooks);
export const getProjectCollection = id => createSelector(getProjectState, projectSelectors.getProjectCollection(id));
export const getProjectForm = id => createSelector(getProjectState, projectSelectors.getProjectForm(id));
export const getProjectAttribute = id => createSelector(getProjectState, projectSelectors.getProjectAttribute(id));
export const getProjectAttributesForForm = id => createSelector(getProjectState, projectSelectors.getProjectAttributesForForm(id));
export const getProjectAttributesForCollection = (id) => createSelector(getProjectState, projectSelectors.getProjectAttributesForCollection(id));
export const getProjectColorsInitialised = createSelector(getProjectState, projectSelectors.getProjectColorsInitialised);
export const getProjectHasEmptySeats = createSelector(getProjectState, projectSelectors.getProjectHasEmptySeats);
export const getProjectUsersLimit = createSelector(getProjectState, projectSelectors.getProjectUsersLimit);
export const getProjectHasSurveysUnavailableForShapefileExport = createSelector(getProjectState, projectSelectors.getProjectHasSurveysUnavailableForShapefileExport);
export const getProjectParent = createSelector(getProjectState, projectSelectors.getProjectParent);
export const getProjectParentForms = createSelector(getProjectState, projectSelectors.getProjectParentForms);
/**
 * 
 * Organisation Selectors
 */
export const getOrganisationsState = state => state.organisations;
export const getOrganisationsLoading = createSelector(getOrganisationsState, organisationsSelectors.getOrganisationsLoading);
export const getOrganisationsError = createSelector(getOrganisationsState, organisationsSelectors.getOrganisationsError);
export const getOrganisationsList = createSelector(getOrganisationsState, organisationsSelectors.getOrganisationsList);
// export const getOrganisation = createSelector(getOrganisationsState, organisationsSelectors.getOrganisation);
// export const getOrganisationId = createSelector(getOrganisationsState, organisationsSelectors.getOrganisationId);
// export const getOrganisationSlug = createSelector(getOrganisationsState, organisationsSelectors.getOrganisationSlug);
// export const getOrganisationImage = createSelector(getOrganisationsState, organisationsSelectors.getOrganisationImage);
// export const getOrganisationFreeTrialEnd = createSelector(getOrganisationsState, organisationsSelectors.getOrganisationFreeTrialEnd);
// export const getOrganisationFreeTrialExpired = createSelector(getOrganisationsState, organisationsSelectors.getOrganisationFreeTrialExpired);
export const getOrganisationMemberships = createSelector(getOrganisationsState, organisationsSelectors.getOrganisationMemberships);
export const getOrganisationMembership = createSelector(getOrganisationsState, organisationsSelectors.getOrganisationMembership);
// export const getOrganisationRole = createSelector(getOrganisationsState, organisationsSelectors.getOrganisationRole);

export const getOrganisation = createSelector(getAuthOrganisationId, getOrganisationsList, (id, organisations) => {
    if (!organisations) {
        return null;
    }
    return organisations.find(o => o.id === id);
});
export const getOrganisationId = createSelector(getOrganisation, organisation => organisation && organisation.id);
export const getOrganisationSlug = createSelector(getOrganisation, organisation => organisation && organisation.slug);
export const getOrganisationImage = createSelector(getOrganisation, organisation => organisation && organisation.imageUrl);
export const getOrganisationRole = createSelector(getOrganisation, organisation => organisation && organisation.role);
export const getOrganisationFreeTrialEnd = createSelector(getOrganisation, organisation => organisation && organisation.freeTrialEnd);
export const getOrganisationFreeTrialExpired = createSelector(getOrganisation, organisation => organisation && organisation.freeTrialExpired);

/**
 * 
 * Page Selectors
 */

export const getPageState = state => state.page;
export const getPageId = createSelector(getPageState, pageSelectors.getPageId);
export const getPageType = createSelector(getPageState, pageSelectors.getPageType);
export const getPageMediaItems = createSelector(getPageState, pageSelectors.getPageMediaItems);
export const getPageConfig = createSelector(getPageState, pageSelectors.getPageConfig);
export const getPageErrors = createSelector(getPageState, pageSelectors.getPageErrors);
export const getPageForUpdate = createSelector(getPageState, pageSelectors.getPageForUpdate);

/**
 * Projects Selectors
*/
export const getProjectsState = (state) => state.projects;
export const getProjects = createSelector(getProjectsState, projectsSelectors.getProjects);

/**
 * Project Templates Selectors
*/
export const getProjectTemplatesState = (state) => state.projectTemplates;
export const getProjectTemplatesLoading = createSelector(getProjectTemplatesState, projectTemplatesSelectors.getProjectTemplatesLoading);
export const getProjectTemplatesError = createSelector(getProjectTemplatesState, projectTemplatesSelectors.getProjectTemplatesError);
export const getProjectTemplatesList = createSelector(getProjectTemplatesState, projectTemplatesSelectors.getProjectTemplatesList);

/**
 * Records Selectors
 */
export const getRecordsState = (state) => state.records;
export const getRecordsFilter = createSelector(getRecordsState, recordsSelectors.getRecordsFilter);
export const getRecordsView = createSelector(getRecordsState, recordsSelectors.getRecordsView);
export const getRecordsFilterOpen = createSelector(getRecordsState, recordsSelectors.getRecordsFilterOpen);
export const getRecordsRecordOpen = createSelector(getRecordsState, recordsSelectors.getRecordsRecordOpen);
export const getRecordsPagination = createSelector(getRecordsState, recordsSelectors.getRecordsPagination);
export const getRecordsPaginationSize = createSelector(getRecordsState, recordsSelectors.getRecordsPaginationSize);
export const getRecordsPaginationPage = createSelector(getRecordsState, recordsSelectors.getRecordsPaginationPage);
export const getRecordsShowDeleted = createSelector(getRecordsState, recordsSelectors.getRecordsShowDeleted);
export const getRecordsTotal = createSelector(getRecordsState, recordsSelectors.getRecordsTotal);
export const getRecordsData = createSelector(getRecordsState, recordsSelectors.getRecordsData);
export const getRecordsOrder = createSelector(getRecordsState, recordsSelectors.getRecordsOrder);
export const getRecordsSelected = createSelector(getRecordsState, recordsSelectors.getRecordsSelected);
export const getRecordsSelectedId = createSelector(getRecordsState, recordsSelectors.getRecordsSelectedId);
export const getRecordsSelectedIds = createSelector(getRecordsState, recordsSelectors.getRecordsSelectedIds);
export const getRecordsSelectedCount = createSelector(getRecordsState, recordsSelectors.getRecordsSelectedCount);
export const getRecordsSelectionSource = createSelector(getRecordsState, recordsSelectors.getRecordsSelectionSource);
export const getRecordsFilterBoundary = createSelector(getRecordsState, recordsSelectors.getRecordsFilterBoundary);
export const getRecordsFilterCustomBoundary = createSelector(getRecordsState, recordsSelectors.getRecordsFilterCustomBoundary);
export const getRecordsFilterCustom = createSelector(getRecordsState, recordsSelectors.getRecordsFilterCustom);
export const getRecordsFilterAttributes = createSelector(getRecordsState, recordsSelectors.getRecordsFilterAttributes);
export const getRecordsFilterCustomOperator = createSelector(getRecordsState, recordsSelectors.getRecordsFilterCustomOperator);
export const getRecordsMapIsSatellite = createSelector(getRecordsState, recordsSelectors.getRecordsMapIsSatellite);
export const getRecordsMapIsDrawing = createSelector(getRecordsState, recordsSelectors.getRecordsMapIsDrawing);
export const getRecordsReloadRequired = createSelector(getRecordsState, recordsSelectors.getRecordsReloadRequired);
export const getRecordsFilterDirty = createSelector(getRecordsState, recordsSelectors.getRecordsFilterDirty);
export const getRecordsFormId = createSelector(getRecordsState, recordsSelectors.getRecordsFormId);
export const getRecordsLayers = createSelector(getRecordsState, recordsSelectors.getRecordsLayers);

/*
 * Record Selectors
 */
export const getRecordState = state => state.record;
export const getRecord = createSelector(getRecordState, recordSelectors.getRecord);
export const getRecordId = createSelector(getRecordState, recordSelectors.getRecordId);
export const getRecordDraft = createSelector(getRecordState, recordSelectors.getRecordDraft);
export const getRecordIsEditing = createSelector(getRecordState, recordSelectors.getRecordIsEditing);
/*
 * Form Selectors
 */
export const getFormState = (state) => state.form;
export const getFormAttributes = createSelector(getFormState, formSelectors.getFormAttributes);
export const getFormDeleted = createSelector(getFormState, formSelectors.getFormDeleted);
export const getFormDirty = createSelector(getFormState, formSelectors.getFormDirty);
export const getFormSelectedAttributeId = createSelector(getFormState, formSelectors.getFormSelectedAttributeId);
export const getFormSelectedAttribute = createSelector(getFormState, formSelectors.getFormSelectedAttribute);
export const getFormQuestionState = createSelector(getFormState, formSelectors.getFormQuestionState);
export const getFormErrors = createSelector(getFormState, formSelectors.getFormErrors);
export const getFormSelectedAttributeErrors = createSelector(getFormState, formSelectors.getFormSelectedAttributeErrors);
export const getFormId = createSelector(getFormState, formSelectors.getFormId);
export const getFormAttributesWithSections = createSelector(getFormState, formSelectors.getFormAttributesWithSections);

export const getFormSelectedAttributeCollection = createSelector(getFormSelectedAttribute, getProjectCollections, (attribute, collections) => {
    return attribute && attribute.collectionId && collections && collections.find(c => c.id === attribute.collectionId);
});

export const getFormSelectedAttributeAssociationForm = createSelector(getFormSelectedAttribute, getProjectForms, (attribute, forms) => {
    return attribute && attribute.associatedSurveyId && forms && forms.find(f => f.id === attribute.associatedSurveyId);
});

export const getProjectCollectionItemMap = createSelector(getProjectCollections, (collections) => {
    const map = {};
    for (const collection of collections) {
        map[collection.id] = {};
        for (const item of collection.items) {
            map[collection.id][item.key] = item.value;
        }
    }
    return map;
});

export const getRecordsForm = createSelector(getRecordsFormId, getProjectForms, (recordsFormId, forms) => forms.find(f => f.id === recordsFormId));

export const getRecordsFormAttributes = createSelector(getRecordsFormId, getProjectAttributes, (recordsFormId, attributes) => {
    const invalidQuestionTypes = ['text', 'child', 'geometry', 'association'];
    return attributes.filter(a => (a.surveyId === recordsFormId) && a.path && (!invalidQuestionTypes.includes(a.questionType)));
});

export const getRecordsFormHasGeometry = createSelector(getRecordsFormId, getProjectAttributes, (formId, attributes) => {
    const atts = formId === null ? attributes : attributes.filter(a => a.surveyId === formId);
    return atts.some(a => a.questionType === 'geometry');
});

export const getRecordsFormHasMedia = createSelector(getRecordsFormId, getProjectAttributes, (formId, attributes) => {
    const atts = formId === null ? attributes : attributes.filter(a => a.surveyId === formId);
    return atts.some(a => { return (a.questionType === 'mediaGallery') || (a.questionType === 'photo') });
});

export const getRecordsSelectedHasMedia = createSelector(getRecordsFormId, getProjectAttributes, getRecordsSelectedIds, getRecordsData, (formId, attributes, selectedIds, recordsData) => {
    const selectedSurveyIds = recordsData.filter(r => selectedIds.includes(r.id)).map(record => record.surveyId);
    let atts;
    if (selectedSurveyIds.length) {
        atts = attributes.filter(attribute => selectedSurveyIds.includes(attribute.surveyId));
    } else if (formId !== null) {
        atts = attributes.filter(a => a.surveyId === formId);
    } else {
        atts = attributes;
    }
    return atts.some(a => { return (a.questionType === 'mediaGallery') || (a.questionType === 'photo') })
});

export const getProjectAttributesHasGeometry = createSelector(getProjectAttributes, attributes => attributes.some(a => a.questionType === 'geometry'));
export const getProjectAttributesHasMedia = createSelector(getProjectAttributes, attributes => attributes.some(a => { return (a.questionType === 'mediaGallery') || (a.questionType === 'photo') }));

export const getAttributesForForm = id => createSelector(getProjectAttributes, attributes => attributes.filter(a => a.surveyId === id));
export const getAttributesForCollection = id => createSelector(getProjectAttributes,
    attributes => attributes
        .filter(a => a.parentCollectionId === id && a.label)
        .sort((a, b) => a.order - b.order)
);

export const getOrganisationsForRoles = roles => createSelector(getAuthOrganisationPermissions, getOrganisationsList, (permissions, organisations) => {
    const orgs = [];
    for (const [id, role] of Object.entries(permissions)) {
        if (roles.includes(role)) {
            const org = (organisations || []).find(o => o.id === +id);
            if (typeof org !== 'undefined') {
                orgs.push(org);
            }
        }
    }
    return orgs;
});

export const getProjectRole = createSelector(
    getProjectId,
    getAuthIsAdmin,
    getAuthProjectPermissions,
    (projectId, isAdmin, permissions) => {
        if (isAdmin) {
            return 'admin';
        };
        return permissions[projectId] || 'unauthorized';
    });

export const getProjectRoleMatches = (roles) => createSelector(getProjectRole, role => roles.includes(role));

export const getProjectRoleById = id => createSelector(getAuthIsAdmin, getAuthProjectPermissions, (isAdmin, permissions) => {
    if (isAdmin) {
        return 'admin'
    }
    return permissions[id] || 'unauthorized';
});

export const getRecordsAttributeFilter = createSelector(getRecordsFilter, getRecordsFilterCustomOperator, (filter, operator) => {
    let result = { [operator]: [] };
    for (const attribute of Object.values(filter.attributes)) {

        let { operatorId, value, attributeType, attributePath, formId } = attribute;

        if (!(operatorId && attributePath && attributeType) || formId !== filter.formId) {
            continue;
        }


        // Special handling for multiselect
        if (attributeType === 'multiselect') {
            const multiselect = { or: [] };
            if (angular.isArray(value)) {
                for (const val of value) {
                    multiselect.or.push({
                        data: {
                            [attributePath]: {
                                like: '%"' + val + '"%'
                            }
                        }
                    });
                }
            }
            if (multiselect.or.length > 0) {
                result[operator].push(multiselect);
            }
        } else {
            let att;

            if (operatorId.indexOf(':') !== -1) {
                const [op, v] = operatorId.split(':');
                value = v === 'true' ? true : (v === 'false' ? false : (v === 'null' ? null : v));
                att = { [op]: value };
            } else if (operatorId === 'substring') {
                if (value) {
                    att = { iLike: `%${value}%` };
                }
            } else {
                if (value && !(Array.isArray(value) && value.length === 0)) {
                    att = { [operatorId]: value };
                }
            }

            if (att) {
                result[operator].push({
                    data: {
                        [attributePath]: att
                    }
                });
            }
        }
    }

    if (result[operator].length === 0) {
        return {};
    }

    return result;
});

const buildFilterQuery = (projectId, filter, attributes, selectedIds = [], filterOnSelf, authUser, source) => {

    const where = {
        projectId,
        ...attributes
    };

    if (filter.formId) {
        where.surveyId = filter.formId;
    }

    // User ID
    if (filterOnSelf) {
        where.userId = authUser.id;
    } else if (filter.userId) {
        where.userId = filter.userId;
    }

    // From and To
    if (filter.to && filter.from) {
        where.createdAt = {
            between: [new Date(filter.from).toISOString(), new Date(filter.to).toISOString()]
        };
    } else if (filter.from) {
        where.createdAt = {
            gte: new Date(filter.from).toISOString()
        };
    } else if (filter.to) {
        where.createdAt = {
            lte: new Date(filter.to).toISOString()
        };
    }

    // Boundary
    if (filter.boundary) {
        where.boundaryId = filter.boundary.boundaryId;
    }

    if (filter.recordId) {
        where.id = filter.recordId;
    }

    // States
    if (filter.states.length) {
        where.state = { in: filter.states }
    }

    if (filter.customBoundary) {
        where.geometry = {
            intersects: filter.customBoundary
        }
    }

    if (source === 'map' && selectedIds && selectedIds.length > 0) {
        where.id = { in: selectedIds };
    }

    return where;
};

export const getRecordsShouldFilterOnSelf = createSelector(getRecordsFilter, getProjectForms, getProjectRole, ({ formId }, forms, role) => {
    const form = forms.find(f => f.id === formId);
    const isPrivate = form && form.private;
    const isLimitedToSelf = role === 'member' || role === 'contributor';
    return isPrivate && isLimitedToSelf;
});

export const getRecordsFilterQuery = createSelector(getProjectId, getRecordsFilter, getRecordsAttributeFilter, getRecordsSelectedIds, getRecordsShouldFilterOnSelf, getAuthUser, getRecordsSelectionSource, buildFilterQuery);
export const getRecordsFilterQueryString = createSelector(getRecordsFilterQuery, where => JSON.stringify(where).replace(/\"([^(\")"]+)\":/g, "$1:"));

export const getRecordsLookupAttributeIds = createSelector(getRecordsFormId, getProjectAttributes, (recordsFormId, attributes) => {
    return attributes
        .filter(attribute => (attribute.surveyId === recordsFormId && attribute.questionType === 'association') ||
            (attribute.questionType === 'child' && attribute.associatedSurveyId === recordsFormId)).map(a => a.id);
});

export const getRecordsFilterAssociatesQuery = createSelector(getRecordsLookupAttributeIds, (ids) => {
    return {
        attributeId: {
            in: ids
        }
    }
});

export const getRecordsFilterCount = createSelector(getRecordsFilter, getRecordsAttributeFilter, getRecordsSelectionSource, getRecordsSelectedCount, (filter, attributeFilter, selectionSource, selectedCount) => {
    let count = 0;
    if (filter.from || filter.to) {
        count++;
    }
    if (filter.user) {
        count++;
    }
    if (filter.boundary) {
        count++;
    }
    if (filter.states.length > 0) {
        count++;
    }
    if (filter.customBoundary) {
        count++;
    }
    if (filter.recordId) {
        count++;
    }
    if (attributeFilter && attributeFilter.and) {
        count += attributeFilter.and.length;
    }
    if (selectionSource === 'map' && selectedCount > 0) {
        count++;
    }

    return count;
});

export const getPermissions = createSelector(getAuthIsAdmin, getOrganisationRole, getProjectRole, (isAdmin, orgRole, projectRole) => {
    if (isAdmin) {
        return Object.fromEntries(ALL_PERMISSIONS.map(a => [a, true]));
    }

    return {
        [ORGANISATION_OWNER]: orgRole === 'owner',
        [ORGANISATION_ADMIN]: orgRole === 'admin' || orgRole === 'owner',
        [ORGANISATION_MEMBER]: orgRole === 'member',
        [PROJECT_ADMIN]: projectRole === 'admin' || orgRole === 'admin' || orgRole === 'owner',
        [PROJECT_MODERATOR]: projectRole === 'moderator',
        [PROJECT_MEMBER]: projectRole === 'member',
    };
});

export const getFormAssociationAttributes = surveyId => createSelector(getProjectAttributes, getProject, (attributes, project) => {
    return attributes.filter(a => a.surveyId === surveyId && (a.questionType === 'association'));
});

export const getRecordsForms = createSelector(getRecordsFormId, getProjectForms, (formId, forms) => {
    return formId ? forms.filter(f => f.id === formId) : forms;
});

export const getRecordsColumns = createSelector(getProjectAttributes, getProjectVerification, getRecordsFormId, (attributes, verification, formId) => {

    const defaultColumns = [
        ["id", "ID", true],
        ["userId", "User", true],
        ["createdAt", "Created", true],
        ["state", "State", true]
    ];

    const basicColumns = verification ? defaultColumns : defaultColumns.slice(0, defaultColumns.length - 1);
    const labelledColumns = attributes.filter(a => a.surveyId && a.label);

    // Put associate columns at the start
    const associationColumns = labelledColumns.filter(a => a.questionType === 'child');
    const attributeColumns = labelledColumns.filter(a => a.questionType !== 'child');

    const defs = [
        ...associationColumns,
        ...attributeColumns
    ].map(a => {
        let visible = a.surveyId === formId;
        if (a.questionType === 'child') {
            if (a.surveyId !== formId) {
                visible = a.associatedSurveyId === formId;
            } else {
                visible = false;
            }
        }
        return [a.id, visible, a.surveyId, a.path];
    });

    const columns = [
        ...basicColumns,
        ...defs
    ];
    return columns;
});

export const getAuthCurrentOrganisation = createSelector(getOrganisationsList, getAuthOrganisationId, (organisations, organisationId) => {
    return organisations && organisations.find(o => o.id === organisationId);
});