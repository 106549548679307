
export const AppTutorialCoachmarkComponent = {
    controllerAs: 'ctrl',
    template: require('!raw-loader!./tutorial-coachmark.component.html').default,
    require: {
        tutorialCtrl: '^tutorial'
    },
    bindings: {
        target: '@'
    },
    transclude: {
        'icon': '?coachmarkIcon',
        'title': '?coachmarkTitle',
        'content': '?coachmarkContent'
    },
    controller: class AppTutorialCoachmarkComponent {
        static $inject = ['$element'];
        constructor($element) {
            this.$element = $element;
            this.content = this.content || '';
        }

        $onInit() {
            this.tutorialCtrl.addCoachmark(this);
        }

        activate() {
            this.$element.addClass('active');
        }

        deactivate() {
            this.$element.removeClass('active');
        }
    }
};
