import {
    generateColor,
    updateCssText,
    convertCssToColors,
    initColors,
    COLOR_VARS,
    VARIANTS,
    THEMES
} from './color';

export const ColorSelectorComponent = {
    bindings: {
        css: '=',
    },
    template: require('!raw-loader!./color-selector.component.html').default,
    controllerAs: 'ctrl',
    controller: class ColorSelectorComponent {
        constructor() {
            this.COLOR_VARS = COLOR_VARS;
            this.THEMES = THEMES;
            this.colors = [];
            this.themeIdx = 0;
            this.colorPickerOptions = {
                inputClass: 'form-control',
                format: 'hexString',
                case: 'lower',
                allowEmpty: false,
                alpha: false,
                dynamicHue: false,
                required: true,
                pos: 'top left'
            };
        }

        getThemeIdx() {
            const colors = this.colors.reduce((p, c) => {
                p[c.name] = c.value;
                return p;
            }, {})
            return THEMES.findIndex(t => angular.equals(t.colors, colors));
        }

        setTheme() {
            if (this.themeIdx === -1) { //custom theme
                return;
            }
            let css = this.css;
            COLOR_VARS.map(c => {
                let color;
                color = generateColor(c.name, c.property, THEMES[this.themeIdx].colors[c.name]);
                css = updateCssText(c.property, css, color.value);

                VARIANTS.map(v => {
                    css = updateCssText(c.property + v.cssKey, css, color[v.key]);
                })
            });
            this.colors = convertCssToColors(css, this.themeIdx);
            this.colors.map(c => {
                c.eventApi = this.createColorPickerEventApi(c.property);
            })
            this.css = css;
            this.updateColors();
        }

        updateColors() {
            this.colors = convertCssToColors(this.css, this.themeIdx);
            this.colors.map(c => {
                c.eventApi = this.createColorPickerEventApi(c.property);
            })
        }

        createColorPickerEventApi(property) {
            return {
                onChange: (_api, color, _$event) => {
                    const colorIdx = this.colors.findIndex(c => c.property === property);
                    const newColor = generateColor(this.colors[colorIdx].name, property, color);
                    this.css = updateCssText(property, this.css, newColor.value);
                    this.colors[colorIdx] = Object.assign(this.colors[colorIdx], newColor);
                    VARIANTS.map(v => {
                        this.css = updateCssText(property + v.cssKey, this.css, newColor[v.key]);
                    })
                    this.themeIdx = this.getThemeIdx();
                }
            }
        }

        toggleDropdown(colorType) {
            colorType.displayVariants = !colorType.displayVariants;
        }

        $onInit() {
            this.css = initColors(this.css);
            this.updateColors();
            this.themeIdx = this.getThemeIdx();
        }
    }
}