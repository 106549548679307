import { z } from 'zod';

export const RequestLogSchema = z.object({
    id: z.string(),
    appBuild: z.string().nullable(),
    appVersion: z.string().nullable(),
    body: z.any(),
    browser: z.string(),
    browserVersion: z.string(),
    deviceType: z.string(),
    method: z.string(),
    origin: z.string().nullable(),
    os: z.string(),
    path: z.string(),
    project: z.object({
        id: z.string(),
        name: z.string(),
    }).nullable(),
    query: z.any(),
    requestedAt: z.string(),
    responseCode: z.number(),
    responseStatus: z.string(),
    timeTaken: z.number(),
    user: z.object({
        id: z.number(),
        displayName: z.string(),
    }).nullable(),
});

export type RequestLog = z.infer<typeof RequestLogSchema>;

