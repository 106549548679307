<div class="w-full">
    @if (isSlider) {
        <div class="px-3 py-1">
            <p-slider [ngModel]="value" (onChange)="onChange($event)" [step]="numberStep" [min]="numberMin" [max]="numberMax" [id]="question.path"></p-slider>
        </div>
    }
    @else {
        @switch (question.type) {
            @case ('float') {
                <p-inputNumber [ngModel]="value" (onInput)="onChange($event)" [minFractionDigits]="0" [maxFractionDigits]="10" [min]="numberMin" [max]="numberMax" [inputId]="question.path" styleClass="w-full" class="w-full"></p-inputNumber>
            }
            @case ('integer') {
                <p-inputNumber [ngModel]="value" (onInput)="onChange($event)" [min]="numberMin" [max]="numberMax" [inputId]="question.path" styleClass="w-full" class="w-full"></p-inputNumber>
            }
        }
    }
</div>