// import noIcon from '../../../assets/icons/no-icon.png';

export const ProjectSidebarComponent = {
    template: require('!raw-loader!./project-sidebar.component.html').default,
    controllerAs: 'ctrl',
    bindings: {
        projects: '<',
        project: '<',
        projectRole: '<',
        isAdmin: '<',
        organisationRole: '<'
    },
    controller: class ProjectSidebarComponent {
        static $inject = ['$element', '$state'];
        constructor($element, $state) {
            this.$element = $element;
            this.$state = $state;
            this.noIcon = '../../../assets/icons/no-icon.png';

            this.configStates = [
                'project.config',
                'project.styling',
                'project.maps-config',
                'project.map-config',
                'project.settings',
                'project.pages',
                'project.page',
                'project.forms',
                'project.form',
                'project.collections',
                'project.collection',
                'project.collection-item',
                'project.verificationsettings',
                'project.hooks',
                'project.credentials',
                'project.apikeys'
            ];
        }

        toggle() {
            this.$element[0].classList.toggle('open');
        };

    }

}


