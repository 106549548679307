<p-dialog header="{{'Confirm ' + type() + ' Deletion'}}" [(visible)]="isVisibleRemoveDialog" [modal]="true" (onHide)="onHideDialog()">
  <div class="w-96 flex flex-col gap-1 mb-4">
    <p class="text-xs">Are you sure you want to delete <b>{{deletionItem()?.name}}</b> {{type().toLocaleLowerCase()}}?</p>
    @if(type() === 'Project') {
      <p class="text-xs">This cannot be undone. If you wish to proceed enter <b>{{deletionItem()['slug']}}</b> below to confirm.</p>
      <form [formGroup]="removeProjectForm">
        <input class="w-full" pInputText formControlName="slug" />
      </form>
    } @else {
      <p class="text-xs">Note this will not delete projects or folders within this folder, they will be moved to the top level.</p>
    }
  </div>
  <ng-template pTemplate="footer">
    <p-button [text]="true" size="large" (click)="onHideDialog()">Cancel</p-button>
    <p-button type="submit" [disabled]="removeProjectForm && !removeProjectForm.valid" size="large" severity="danger" (click)="onHideDialog(true)">Delete {{type()}}</p-button>
  </ng-template>
</p-dialog>