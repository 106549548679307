const initialState = {
    record: null,
    draft: null
};

const recordReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'RECORD_DETAILS_LOAD_SUCCESS': {
            return {
                ...state,
                record: action.record
            };
        }

        case 'RECORD_EDIT': {
            return {
                ...state,
                draft: angular.copy(action.record || state.record)
            };
        }

        case 'RECORD_EDIT_DISCARD': {
            return {
                ...state,
                draft: null
            };
        }

        case 'RECORD_EDIT_SAVE_SUCCESS': {
            return {
                ...state,
                record: action.record,
                draft: null
            };
        }

        case 'RECORD_UPDATE_DRAFT_DATA': {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    data: action.data
                }
            };
        }

        case 'RECORD_ADD_DRAFT_ATTACHMENT': {
            const { attribute, file, url } = action;
            return {
                ...state,
                draft: {
                    ...state.draft,
                    attachments: [
                        ...state.draft.attachments,
                        {
                            id: new Date().valueOf(),
                            file,
                            mimeType: file.type,
                            url,
                            attribute,
                            attributeId: attribute.id
                        }
                    ]
                }
            };
        }

        case 'RECORD_DELETE_DRAFT_ATTACHMENT': {
            const { attribute, attachment } = action;
            let idx = -1;
            if (attribute.type !== 'attachment') {
                idx = state.draft.attachments.findIndex(a => a.attributeId === attribute.id);
            } else {
                idx = state.draft.attachments.findIndex(a => a.attributeId === attribute.id && a.id === attachment.id);
            }
            if (idx === -1) {
                console.warn('COULD NOT FIND ATTACHMENT TO DELETE');
                return state;
            }

            const updatedData = { ...state.draft.data };
            delete updatedData[attribute.path];

            return {
                ...state,
                draft: {
                    ...state.draft,
                    attachments: [
                        ...state.draft.attachments.slice(0, idx),
                        ...state.draft.attachments.slice(idx + 1)
                    ],
                    data: updatedData
                }
            };
        }

        case 'RECORD_UPDATE_DRAFT_ASSOCIATION': {
            const { attribute, record } = action;
            const idx = state.draft.associates.findIndex(a => a.association.id === attribute.id);
            if (idx === -1) {
                // New association to create
                return {
                    ...state,
                    draft: {
                        ...state.draft,
                        associates: [
                            ...state.draft.associates,
                            {
                            association: {
                                id: attribute.id,
                                label: attribute.label,
                                questionType: attribute.questionType
                            },
                            record: {
                                id: record.id,
                                title: record.title
                            }
                        }]
                    }
                };
            } else if (!record) {
                return {
                    ...state,
                    draft: {
                        ...state.draft,
                        associates: [
                            ...state.draft.associates.slice(0, idx),
                            ...state.draft.associates.slice(idx + 1)
                        ]
                    }
                };
            } else {
                // Update existing
                return {
                    ...state,
                    draft: {
                        ...state.draft,
                        associates: [
                            ...state.draft.associates.slice(0, idx),
                            {
                                ...state.draft.associates[idx],
                                record: {
                                    id: record.id,
                                    title: record.title
                                }
                            },
                            ...state.draft.associates.slice(idx + 1)
                        ]
                    }
                }
            };
        }

        default: {
            return state;
        }
    }
}

export default recordReducer;