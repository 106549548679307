export default class IntermediaryPageAddedToOrganisationController {
    static $inject = ['$location', 'IntermediaryPageService'];
    constructor($location, IntermediaryPageService) {
        const search = $location.search();
        this.role = search.projectrole; //project role
        this.organisationName = search.orgname;
        this.projectName = search.projectname;
        this.hostUrl = IntermediaryPageService.getHostUrl();
        this.displayHostUrl = IntermediaryPageService.getDisplayHostUrl();
    }

    $onInit() { }
}
