export class GetInTouchModalController {
    static $inject = ['$mdDialog'];
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }
    ok() {
        this.$mdDialog.hide();
    };
    cancel() {
        this.$mdDialog.cancel();
    };
}