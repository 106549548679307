import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecordLog } from './record-activity.component';
import { VerificationStateMap } from '../record-detail.component';
import { CommonModule, DatePipe } from '@angular/common';
import { DateToNowPipe } from 'src/app/shared/pipes/date-to-now.pipe';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';

@Component({
    selector: 'app-record-log',
    templateUrl: 'record-log.component.html',
    standalone: true,
    imports: [
        CommonModule,
        DateToNowPipe,
        AvatarModule,
        ButtonModule
    ]
})

export class RecordLogComponent implements OnInit {

    @Input() log: RecordLog;
    @Input() verificationStateMap: VerificationStateMap;

    @Output() reviewChanges: EventEmitter<RecordLog> = new EventEmitter();

    public operationString: string;
    public dateString: string = null;
    public stateString: string = null;
    public badgeColor: string = null;
    public badgeBackgroundColor: string = null;

    constructor(
        private datePipe: DatePipe
    ) { }

    ngOnInit() {
        this.setOperationString();
    }

    private setOperationString() {
        switch (this.log.operation) {
            case 'create': {
                this.operationString = 'created this record on';
                this.setDateString();
                break;
            }
            case 'update': {
                this.operationString = `updated this record on`;
                this.setDateString();
                break;
            }
            case 'verification': {
                this.operationString = `changed the status from`;
                const state = this.verificationStateMap[this.log.diff[0].value];
                this.stateString = `${state.name}`;
                this.badgeColor = state.color;
                this.badgeBackgroundColor = `${state.color}1A`; // Add hex code alpha channel
                break;
            }
            default:
                break;
        }
    }

    private setDateString() {
        this.dateString = this.datePipe.transform(this.log.createdAt, "d MMMM y 'at' hh:mm a");
    }
}