<div class="table-item__inner">
    <div class="w-1/2 flex items-center justify-between">
        <p class="table-item__primary-text w-1/2 truncate">{{ item().name }}</p>
    </div>
    <div class="w-1/2 flex items-center justify-between">
        <p>{{ item().createdAt | date:'dd/MM/yyyy' }}</p>
        <div class="table-item__buttons">
            <button class="table-item__button" [ngClass]="hovering() ? 'inline-block' : 'hidden'"
                (click)="deleteItem($event)">
                <i class="fa-solid fa-trash-can"></i>
            </button>
            <button class="table-item__button" [ngClass]="hovering() || selected ? 'inline-block' : 'hidden'"
                (click)="selectItem($event)">
                <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="30" height="30" rx="3" fill="#F2F8FE" />
                    <rect x="1" y="1" width="30" height="30" rx="3" stroke-width="2"
                        [attr.stroke]="selected ? '#0069DF' : '#878F97'" />
                    @if(selected){
                    <path
                        d="M13.0602 22.5672L7.10185 16.6089C6.74388 16.2509 6.74388 15.6705 7.10185 15.3125L8.39818 14.0161C8.75615 13.6581 9.33658 13.6581 9.69455 14.0161L13.7084 18.0299L22.3055 9.43278C22.6635 9.07482 23.2439 9.07482 23.6019 9.43278L24.8982 10.7291C25.2562 11.0871 25.2562 11.6675 24.8982 12.0255L14.3566 22.5672C13.9986 22.9252 13.4182 22.9252 13.0602 22.5672Z"
                        fill="#0069DF" />
                    }
                </svg>
            </button>
        </div>
    </div>
</div>