import { getAuthProvider } from '../../store/selectors';

export default class OAuthTransferController {
    static $inject = ['$scope', '$state', '$ngRedux', 'AuthActions'];
    constructor($scope, $state, $ngRedux, AuthActions) {
        this.$state = $state;
        this.$ngRedux = $ngRedux;
        this.AuthActions = AuthActions;

        this.transferComplete = false;
        this.passwordType = 'password';
        this.password = null;
        $scope.$on('$destroy', $ngRedux.connect(state => ({
            authProvider: getAuthProvider(state),
        }), AuthActions)(this));
    }

    togglePasswordType() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }

    doOAuthTransfer() {
        this.oauthTransfer(this.password)
            .then(() => {
                this.transferComplete = true;
            })
    }

    goHome() {
        this.$state.go('home');
    }
}