<div class="flex justify-center items-center">
    <p-toast position="bottom-center"></p-toast>
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="w-full">
        <p-panel class="flex flex-col items-start max-w-4xl mx-auto" styleClass="w-full">
            <ng-template pTemplate="header">
                <div class="flex items-center gap-3">
                    <i class="fas fa-user"></i>
                    <span class="p-panel-title">Edit Profile</span>
                </div>
                <div>
                    <p-button size="large" label="Save changes" type="submit"
                        [disabled]="!profileForm.touched || !profileForm.valid">
                    </p-button>
                </div>
            </ng-template>

            <div class="flex justify-between p-2 max-w-4xl mx-auto">
                <div class="m-5 sm:m-10 w-full sm:w-72 h-48 sm:h-72" *ngIf="profile$ | async; let profile">
                    <p-avatar styleClass="ml-14 w-44 h-44 items-center bg-[#DFEEFF] rounded-full">
                        <img *ngIf="imageUrl" [src]="imageUrl" class="w-44 h-44 rounded-full object-cover" />
                    </p-avatar>
                    <div class="flex justify-center items-center mt-6">
                        <input type="file" #fileInput class="hidden" (change)="onFileSelected($event)"
                            accept="image/*" />
                        <p-button type="button" (click)="showDialog()" [outlined]="true" label="Change picture">
                        </p-button>

                        <p-dialog header="Upload a profile image" [visible]="visible" [draggable]="false"
                            [resizable]="false" (click)="closeDialog()" styleClass="sm:w-[560px] sm:h-[608px]">
                            <div [ngClass]="{'': imageSelected, 'border border-grey-50 border-dotted': !imageSelected}"
                                class="w-full h-full flex justify-center items-center">
                                <div pDroppable (onDrop)="onDrop($event)">
                                    <div *ngIf="!imageSelected">
                                        <div class="flex justify-center items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="114" height="114"
                                                viewBox="0 0 114 114" fill="none">
                                                <path
                                                    d="M89.3 52.6359C89.9086 51.0477 90.25 49.3109 90.25 47.5C90.25 39.6328 83.8672 33.25 76 33.25C73.0758 33.25 70.3445 34.1406 68.0883 35.6547C63.9766 28.5297 56.3023 23.75 47.5 23.75C34.3781 23.75 23.75 34.3781 23.75 47.5C23.75 47.9008 23.7648 48.3016 23.7797 48.7023C15.4672 51.6266 9.5 59.5531 9.5 68.875C9.5 80.6758 19.0742 90.25 30.875 90.25H85.5C95.9945 90.25 104.5 81.7445 104.5 71.25C104.5 62.0617 97.9688 54.3875 89.3 52.6359ZM67.8953 61.75H58.1875V78.375C58.1875 79.6813 57.1188 80.75 55.8125 80.75H48.6875C47.3813 80.75 46.3125 79.6813 46.3125 78.375V61.75H36.6047C34.482 61.75 33.4281 59.1969 34.9273 57.6977L50.5727 42.0523C51.493 41.132 53.007 41.132 53.9273 42.0523L69.5727 57.6977C71.0719 59.1969 70.0031 61.75 67.8953 61.75Z"
                                                    fill="#0069DF" />
                                            </svg>
                                        </div>
                                        <div class="flex justify-center items-center mt-2">
                                            <h2 class="text-base text-[#000] font-semibold leading-tight">
                                                Drag & drop an image <br>
                                                <div class="flex justify-center items-center mt-2">
                                                    or
                                                </div>
                                            </h2>
                                        </div>
                                        <div class="flex justify-center items-center mt-2">
                                            <p-button type="button" size="large" (click)="fileInput.click()"
                                                label="Browse File">
                                            </p-button>
                                        </div>
                                    </div>
                                    <div *ngIf="imageSelected">
                                        <div class="w-full h-full flex justify-center items-center">
                                            <img [src]="imageUrl" class="mt-0 sm:w-[450px] sm:h-[450px]" />
                                        </div>
                                        <div class="flex items-center mt-2 justify-end">
                                            <p-button label="Upload image"
                                                (click)="$event.preventDefault(); selectImage()">
                                                <div class="flex items-center mr-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                        viewBox="0 0 20 20" fill="none">
                                                        <path
                                                            d="M15.6666 9.23446C15.7734 8.95581 15.8333 8.65112 15.8333 8.33342C15.8333 6.95321 14.7135 5.83341 13.3333 5.83341C12.8203 5.83341 12.3411 5.98966 11.9453 6.25529C11.2239 5.00529 9.87756 4.16675 8.33329 4.16675C6.03121 4.16675 4.16663 6.03133 4.16663 8.33342C4.16663 8.40373 4.16923 8.47404 4.17183 8.54435C2.7135 9.05737 1.66663 10.448 1.66663 12.0834C1.66663 14.1537 3.34631 15.8334 5.41663 15.8334H15C16.8411 15.8334 18.3333 14.3412 18.3333 12.5001C18.3333 10.8881 17.1875 9.54175 15.6666 9.23446ZM11.9114 10.8334H10.2083V13.7501C10.2083 13.9792 10.0208 14.1667 9.79163 14.1667H8.54163C8.31246 14.1667 8.12496 13.9792 8.12496 13.7501V10.8334H6.42183C6.04944 10.8334 5.86454 10.3855 6.12756 10.1225L8.87236 7.37769C9.03381 7.21623 9.29944 7.21623 9.4609 7.37769L12.2057 10.1225C12.4687 10.3855 12.2812 10.8334 11.9114 10.8334Z"
                                                            fill="white" />
                                                    </svg>
                                                </div>
                                            </p-button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </p-dialog>
                    </div>

                    <div class="flex justify-center items-center mt-5">
                        <h2 class="text-s font-normal leading-5 truncate">
                            {{profile.email}}
                        </h2>
                    </div>
                    <div class="flex justify-center items-center mt-3">
                        <div class="bg-[#15CB64] rounded-md px-2 py-1">
                            <h2 class="text-xs text-white font-normal leading-5 uppercase">
                                {{profile.role}}
                            </h2>
                        </div>
                    </div>
                </div>

                <!-- <div class="mt-5 sm:mt-20 mr-5 sm:mr-10 w-full sm:w-[460px] h-auto sm:h-[206px]"> -->
                <div class="flex items-center justify-center">

                    <div class="space-y-5 w-full">
                        <div class="flex w-full flex-col gap-2">
                            <label for="name" class="text-sm text-grey-120 font-normal"> Name</label>
                            <input type="text" pInputText formControlName="displayName" class="w-full">
                        </div>

                        <div class="flex w-full flex-col gap-2">
                            <label for="username" class="text-sm text-grey-120 font-normal">
                                Username</label>
                            <input type="text" pInputText formControlName="username" class="w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </p-panel>
    </form>
</div>