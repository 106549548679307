@if (form && questions) {
    <form [formGroup]="form" class="flex flex-col gap-7">
        @for (question of questions; track question) {
            @if (!isHidden(question)) {
                <app-form-question
                    [form]="form"
                    [question]="question"
                    [isEdit]="isEdit"
                ></app-form-question>
            }
        }
    </form>
} 
@else {
    <div class="h-60 w-full pt-4">
        <div class="flex items-center justify-center">
            <p-progressSpinner styleClass="h-8 w-8" class="h-8 w-8"></p-progressSpinner>
        </div>
    </div>
}