import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { AutoCompleteCompleteEvent, AutoCompleteModule } from "primeng/autocomplete";
import { DatePipe, TitleCasePipe } from "@angular/common";
import { FilterMetadata } from "primeng/api";
import { InputTextModule } from "primeng/inputtext";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { ApiService } from "src/app/core";
@Component({
    selector: 'app-admin-requests',
    standalone: true,
    templateUrl: './admin-requests.component.html',
    imports: [
        TableModule,
        DropdownModule,
        FormsModule,
        ButtonModule,
        DatePipe,
        TitleCasePipe,
        AutoCompleteModule,
        InputTextModule,
        InputGroupModule,
        InputGroupAddonModule
    ],
    styles: [
        `:host{ @apply block h-full w-full overflow-hidden p-8;}`
    ]
})
export class AdminRequestsComponent {
    @ViewChild('adminRequestsTable') table: Table;
    @Input() offset: string = "0";
    @Input() limit: string = "10";
    @Input() order: string;
    @Input() where: { [key: string]: any };

    @Output() request: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();

    first: number;
    rows: number;
    filters = {};
    sortField: string;
    sortOrder: number;
    loading: boolean = false;

    methods = ['POST', 'PUT', 'GET', 'DELETE'];

    requests: any[] = [];
    users: any[] = [];
    projects: any[] = [];

    private readonly query: string = `query AAGetRequests($offset: Int!, $limit: Int!, $order: String!, $where: SequelizeJSON){
        requestLogs(offset: $offset, limit: $limit, order: $order, where: $where) {
           logs{
            id
            path
            method
            requestedAt
            timeTaken
            responseStatus
            user {
                id
                displayName
            }
            project{
                id
                name
            }
           }
        }
    }`;

    constructor(private api: ApiService) { }

    ngOnInit() {
        this.rows = parseInt(this.limit);
        this.first = parseInt(this.offset);

        if (this.order) {
            this.sortField = this.order.replace('reverse:', '');
            this.sortOrder = this.order.startsWith('reverse:') ? -1 : 1;
        }

        if (this.where) {
            // const filters = JSON.parse(this.where);
            for (const k in this.where) {
                this.filters[k] = {
                    value: this.where[k],
                    matchMode: k === 'id' ? 'startsWith' : 'equals'
                };
            }
        }
    }

    globalSearch(event: any) {
        this.table.filterGlobal(event.target.value, 'contains');
    }

    rowSelect(event: any) {
        this.request.emit(event);
    }

    searchUsers(event: AutoCompleteCompleteEvent) {
        const userQuery = `query AAGetRequestUsers($limit: Int!, $where: SequelizeJSON){
            users(limit: $limit, where: $where) {
                id
                displayName
                username
            }
        }`;

        const input = {
            limit: 50,
            where: {
                displayName: { iLike: `%${event.query}%` }
            }
        }
        this.api.graphql<{ users: any[] }>(userQuery, input).subscribe((res) => {
            this.users = res.users;
        });
    }

    searchProjects(event: AutoCompleteCompleteEvent) {
        const projectQuery = `query AAGetRequestProjects($limit: Int!, $where: SequelizeJSON){
            projects(limit: $limit, where: $where) {
                id
                name
            }
        }`;
        const input = {
            limit: 50,
            where: {
                name: { iLike: `%${event.query}%` }

            }
        };
        this.api.graphql<{ projects: any[] }>(projectQuery, input).subscribe((res) => {
            this.projects = res.projects;
        });
    }

    loadRequests(event: TableLazyLoadEvent) {
        this.loading = true;
        const order = `${event.sortOrder === -1 ? 'reverse:' : ''}${event.sortField}`;
        let where = { and: [] };

        if (event.filters['id'] && event.filters['id']['value']) {
            where.and.push({ id: event.filters['id']['value'] });
        }

        if (event.filters['path'] && event.filters['path']['value']) {
            where.and.push({ path: { like: `/${event.filters['path']['value']}%` } });
        }

        if (event.filters['method'] && event.filters['method']['value']) {
            where.and.push({ method: event.filters['method']['value'] });
        }
        if (event.filters['user'] && event.filters['user']['value']) {
            where.and.push({ userId: event.filters['user']['value'].id });
        }
        if (event.filters['project'] && event.filters['project']['value']) {
            where.and.push({ projectId: event.filters['project']['value'].id });
        }

        const input = {
            offset: event.first,
            limit: event.rows,
            order,
            where
        }

        const compactFilters = Object.keys(event.filters).reduce((acc, key) => {
            if ((event.filters[key] as FilterMetadata).value) {
                acc[key] = (event.filters[key] as FilterMetadata).value;
            }
            return acc;
        }, {});

        this.change.emit({
            offset: event.first,
            limit: event.rows,
            order,
            q: Object.keys(compactFilters).length > 0 ? JSON.stringify(compactFilters) : ''
        });

        this.api.graphql<{ requestLogs: { logs: [] } }>(this.query, input).subscribe((res) => {
            this.requests = res.requestLogs.logs;
            this.loading = false;
        });
    }
}