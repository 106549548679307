<div>
    <form [formGroup]="attributeForm" (ngSubmit)="onSubmit()">
        <div class="mb-7">
            <label for="label" class="block mb-2">Attribute name/label*</label>
            <input type="text" pInputText id="label" type="text" placeholder="Add a name/label" formControlName="label" class="w-full" />
            <p class="text-xs text-danger leading-none mt-2">
                @if (label.invalid && (label.dirty || label.touched) && label.errors?.['required']) {
                    Name/label is required
                }
            </p>
        </div>

        <div class="mb-7">
            <label for="type" class="block mb-2">Attribute type</label>
            <p-selectButton [options]="typeOptions" [multiple]="false" [unselectable]="true" optionLabel="label" optionValue="value" formControlName="type" styleClass="w-full"></p-selectButton>
        </div>

        <div class="flex items-center justify-between mb-7">
            <label for="visible">Visible in the app</label>
            <p-inputSwitch inputId="visible" formControlName="visible" />
        </div>

        <div class="flex items-center {{ isEdit ? 'justify-between' : 'justify-end'}}">
            @if(isEdit) {
                <p-button type="button" [loading]="loading" label="Delete" [text]="true" severity="danger" (click)="delete()">
                    <i class="fa-solid fa-trash-can mr-1"></i>
                </p-button>
            }

            <p-button type="submit" [disabled]="!attributeForm.valid" [loading]="loading">{{ isEdit ? 'Save' : 'Create attribute' }}</p-button>
        </div>
    </form>
</div>