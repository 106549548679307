import { z } from 'zod';
export const ROOT_FOLDER = 'root';
export interface OrganisationFolder {
    id: string;
    name: string;
    parentId: string | null;
    updatedAt: string;
    isFavourite: boolean;
    type: 'ORGANISATION_FOLDER';
}

export interface HomeProject {
    id: number;
    name: string;
    description: string;
    organisationFolderId: string;
    imageUrl: string;
    slug: string;
    updatedAt: string;
    role: string;
    isFavourite: boolean;
    type: 'PROJECT';
}

export interface OrganisationFolderTreeNode extends OrganisationFolder {
    projects: HomeProject[];
    children: OrganisationFolderTreeNode[];
}

export interface OrganisationTreeFindResult {
    node: OrganisationFolderTreeNode;
    path: OrganisationFolderTreeNode[];
}

export type ProjectMembership = {
    role: string;
    userId: number;
}

export interface HomeProjectSetupResult {
    name: string;
    description: string;
    memberships: ProjectMembership[];
};

export interface FavouriteEntity {
    id: number | string,
    type: 'ORGANISATION_FOLDER' | 'PROJECT'
};

export const HomeProjectTemplateSchema = z.object({
    id: z.number(),
    projectId: z.number(),
    name: z.string(),
    description: z.string(),
    category: z.string().nullable(),
    icon: z.string()
});

export type HomeProjectTemplate = z.infer<typeof HomeProjectTemplateSchema>;

export type OrganisationRole = 'admin' | 'member' | 'owner';