import { getOrganisationFreeTrialEnd, getOrganisationFreeTrialExpired } from '../../store/selectors';
import { openGetInTouchModal } from '../helpers';

export const OrganisationFreeTrialBannerComponent = {
    bindings: {
        isExpired: '=',
        freeTrialEnd: '='
    },
    template: require('!raw-loader!./free-trial-banner.component.html').default,
    controllerAs: 'ctrl',
    controller: class OrganisationFreeTrialBannerController {

        static $inject = ['$ngRedux', '$scope', '$state', '$mdDialog'];
        constructor($ngRedux, $scope, $state, $mdDialog) {
            this.$ngRedux = $ngRedux;
            this.$scope = $scope;
            this.$state = $state;
            this.$mdDialog = $mdDialog;
            this.onFreeTrial = false;
            this.message = null;
            this.$scope.$on('$destroy', this.$ngRedux.connect((state) => {
                const freeTrialEnd = getOrganisationFreeTrialEnd(state);

                const onFreeTrial = !!freeTrialEnd && this.isOrgPage();
                let message = null;

                if (freeTrialEnd) {
                    const expired = getOrganisationFreeTrialExpired(state);
                    message = expired ? 'Expired' : this.toDaysLeftMessage(freeTrialEnd);
                }

                return {
                    message,
                    onFreeTrial
                }
            }, null)(this));
        }

        openEmail(ev) {
            openGetInTouchModal(this.$mdDialog, ev);
        }

        toDaysLeftMessage(datestring) {
            const date = new Date(datestring);
            const now = new Date();
            const DAY_IN_MS = 1000 * 3600 * 24;
            const days = Math.floor((date - now) / DAY_IN_MS);
            return `Expires In ${days} Days`
        }

        isOrgPage() {
            // const ORG_STATE_NAME = 'home';
            // console.log('TESTING', this.$state);
            return this.$state.includes('home') || this.$state.includes('project') || this.$state.includes('organisation-admin');
        }
    }
}