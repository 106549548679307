export const ORGANISATION_OWNER = 'ORGANISATION_OWNER';
export const ORGANISATION_ADMIN = 'ORGANISATION_ADMIN';
export const ORGANISATION_MEMBER = 'ORGANISATION_MEMBER';

export const PROJECT_ADMIN = 'PROJECT_ADMIN';
export const PROJECT_MODERATOR = 'PROJECT_MODERATOR';
export const PROJECT_MEMBER = 'PROJECT_MEMBER';

export const COREO_ADMIN = 'COREO_ADMIN';

export const ALL_PERMISSIONS = [
    ORGANISATION_OWNER,
    ORGANISATION_ADMIN,
    ORGANISATION_MEMBER,
    PROJECT_ADMIN,
    PROJECT_MODERATOR,
    PROJECT_MEMBER,
    COREO_ADMIN
];