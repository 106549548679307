export const SearchableListComponent = {
    bindings: {
        items: '<',
        placeholder: '@'
    },
    template: require('!raw-loader!./searchable-list.component.html').default,
    controllerAs: 'ctrl',
    controller: class SearchableListComponent {
        static $inject = ['$element'];
        constructor($element) {
            this.selection = [];
            this.searchTerm = '';
            this.placeholder = 'Search...';
            this.$element = $element;
        }

        $onInit() {
            this.$element.find('input').on('keydown', function (ev) {
                ev.stopPropagation();
            });
        }
        clearSearchTerm() {
            this.searchTerm = '';
        }

        // $onChanges(changes) {

        // }
    }
};
