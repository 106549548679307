import { questionTypes } from '../../store/form/form.reducer';

const questionTypeIndex = questionTypes.reduce((acc, typeObj) => {
    const { type, questionType } = typeObj;
    const types = angular.isArray(type) ? type : [type];

    for (let i of types) {

        acc = {
            ...acc,
            [i]: {
                ...acc[i] || {},
                [questionType]: typeObj
            }
        };
    }

    return acc;
}, {});


export default function () {

    return function (attribute, field) {
        let item = {
            name: "Unknown",
            icon: "question"
        };

        if (attribute) {
            let { type, questionType } = attribute;
            if (angular.isArray(type)) {
                type = type[0];
            }

            if (questionType === 'expression') {
                item = questionTypeIndex.null.expression;
            }

            if (questionTypeIndex.hasOwnProperty(type) && questionTypeIndex[type].hasOwnProperty(questionType)) {
                item = questionTypeIndex[type][questionType];
            }
        }

        if (item[field] !== undefined) {
            return item[field];
        }
        return null;
    };
}