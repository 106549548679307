<p-toast position="bottom-center" [life]="1000"></p-toast>
<p-confirmDialog></p-confirmDialog>

@if(user){
<div class=" flex w-full gap-4 px-4 pt-8">

    <p-panel class="w-1/4 ">

        <ng-template pTemplate="header">
            <div class="flex items-center gap-2">
                <p-avatar [image]="user.imageUrl" size="large" shape="circle" />
                <div class="font-bold flex flex-col">
                    <span>{{user.displayName}}</span>
                    <a [href]="'mailto:' + user.email" class="text-primary underline">{{user.email}}</a>
                </div>
            </div>
        </ng-template>

        <ul class="space-y-4">
            <li class="flex">
                <label class="font-normal">User ID</label>
                <span class="ml-auto font-semibold">{{user.id}}</span>
            </li>
            <li class="flex">
                <label class="font-normal">Name</label>
                <span class="ml-auto font-semibold">{{user.displayName}}</span>
            </li>
            <li class="flex">
                <label class="font-normal">Username</label>
                <span class="ml-auto font-semibold">{{user.username}}</span>
            </li>
            <li class="flex">
                <label class="font-normal">Account Type</label>
                <span class="ml-auto font-semibold">{{user.provider | titlecase}}</span>
            </li>
            <li class="flex">
                <label class="font-normal">Signup Date</label>
                <span class="ml-auto font-semibold">{{user.createdAt | date}}</span>
            </li>
            <li class="flex">
                <label class="font-normal">Verified</label>
                <span class="ml-auto font-semibold">{{user.verified | yesNo}}</span>
            </li>
            <li class="flex">
                <label class="font-normal">Last Active</label>
                <span class="ml-auto font-semibold">{{user.lastActive | date}}</span>
            </li>
            <li>
                <div class="w-full space-y-4">
                    <p-button severity="primary" [outlined]="true" class="block w-full"
                        (click)="impersonate.emit()">Impersonate User</p-button>
                    @if(!user.verified){
                    <p-button severity="primary" [outlined]="true" class="block w-full" (click)="verifyUser()">Verify
                        User</p-button>
                    }
                </div>
            </li>
        </ul>

    </p-panel>

    <!-- General -->
    <div class="w-3/4 space-y-4">
        <p-panel class="block">
            <ng-template pTemplate="header">
                <div class="flex items-center justify-between">
                    <span class="p-panel-title">General</span>
                    <p-button [disabled]="!userForm.dirty" [loading]="saving" type="submit" (click)="saveDetails()"
                        class="order-2">Save</p-button>
                </div>
            </ng-template>
            <form [formGroup]="userForm" class="space-y-4">
                <div class="flex flex-col gap-2">
                    <label for="name">Name</label>
                    <input pInputText formControlName="displayName" data-lpignore="true" autocomplete="off" />
                </div>
                <div class="flex flex-col gap-2">
                    <label for="name">Email</label>
                    <input pInputText type="email" formControlName="email" data-lpignore="true" autocomplete="off" />
                </div>
                <div class="flex flex-col gap-2">
                    <label for="name">Username</label>
                    <input pInputText formControlName="username" data-lpignore="true" autocomplete="off" />
                </div>
            </form>
        </p-panel>

        <!-- Password -->
        <p-panel class="block">
            <ng-template pTemplate="header">
                <div class="flex items-center justify-between">
                    <span class="p-panel-title">Password</span>
                    <p-button class="order-2" *ngIf="user.provider === 'local'"
                        [disabled]="!passwordForm.dirty || !passwordForm.valid" [loading]="saving" type="submit"
                        (click)="savePassword()">Update Password</p-button>
                </div>
            </ng-template>

            @if(user.provider === 'local'){
            <form [formGroup]="passwordForm" class="space-y-4">
                <div class="flex flex-col gap-2">
                    <label for="name">Update Password</label>
                    <input type="password" pInputText formControlName="password" data-lpignore="true"
                        autocomplete="off" />
                </div>
            </form>
            } @else {
            <div>This is not a local account</div>
            }
        </p-panel>

        <!-- Projects -->
        <p-panel header="Projects" class="block p-0">
            <p-table [value]="user.memberships">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Project</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Joined</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-puser>
                    <tr>
                        <td>
                            <a class="flex items-center gap-2"
                                [href]="puser.project.organisation.slug+ '/'+puser.project.slug">
                                <p-avatar [image]="puser.project.imageUrl" shape="circle"></p-avatar>
                                {{puser.project.name}}
                            </a>
                        </td>
                        <td>{{puser.role | titlecase}}</td>
                        <td>{{puser.status}}</td>
                        <td>{{puser.createdAt | date}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-panel>

        <p-panel header="Organisations" class="block p-0">
            <p-table [value]="user.organisationMemberships">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Organisation</th>
                        <th>Role</th>
                        <th>Joined</th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-org>
                    <tr>
                        <td>
                            <a class="flex items-center gap-2" [href]="'/' + org.organisation.slug">
                                <p-avatar [image]="org.organisation.imageUrl"
                                    shape="circle"></p-avatar>{{org.organisation.name}}
                            </a>
                        </td>
                        <td>{{org.role | titlecase}}</td>
                        <td>{{org.createdAt | date}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-panel>
        <p-panel header="Danger Zone" class="block">
            <p-button severity="danger" size="large" (click)="deleteUser($event)">Delete User</p-button>
        </p-panel>
    </div>
</div>
}