import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, input, signal } from '@angular/core';
import { Collection } from './project-collections.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-collections-list-item',
    templateUrl: 'collections-list-item.component.html',
    standalone: true,
    imports: [
        CommonModule
    ]
})

export class CollectionsListItemComponent implements OnInit {

    item = input.required<Collection>();

    @Output() open = new EventEmitter<void>();
    @Output() select = new EventEmitter<boolean>();
    @Output() delete = new EventEmitter<void>();

    @Input() @HostBinding('class.selected') selected = false;
    
    @HostListener('click') onClick() {
        this.open.emit();
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.hovering.set(true);
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.hovering.set(false);
    }

    hovering = signal<boolean>(false);

    constructor() { }

    ngOnInit() { }

    selectItem(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.select.emit();
    }

    deleteItem(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.delete.emit();
    }
}