import angular from 'angular';

export const MapboxPropEditorComponent = {
    template: require('!raw-loader!./mapbox-prop-editor.component.html').default,
    controllerAs: 'ctrl',
    bindings: {
        layer: '<',
        prop: '<',
        value: '<',
        images: '<',
        onChange: '&',
        onReset: '&'
    },
    controller: class MapboxPropEditorComponent {
        static $inject = ['ProjectService'];
        constructor(ProjectService) {
            this.ProjectService = ProjectService;
            this.editor = null;
            this.valid = false;
            this.view = 'value';
            this.attributes = [];
        }
        switchView(view) {
            this.view = view;
        };

        changed() {
            this.onChange({ result: this.value });
        };

        onJsonChange(result) {
            this.onChange({ result: result });
        };

        $onInit() {
            var filter = this.layer.sourceType === 'records' ? { surveyId: this.layer.sourceId } : { parentCollectionId: this.layer.sourceId };
            this.attributes = _.filter(this.ProjectService.getAttributes(), filter);
        }

        $onChanges(changes) {
            if (changes.prop) {
                if (angular.isArray(this.value)) {
                    this.view = 'json';
                } else {
                    this.view = 'value';
                }
            }
        };

        reset() {
            this.onReset();
            this.view = 'value';
        };
    }
}