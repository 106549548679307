export default function () {
    return function (input) {
        switch (input) {
            case 'google': return 'Google';
            case 'facebook': return 'Facebook';
            case 'local': return 'Email';
            case 'apple': return 'Apple';
            default: return 'Unknown Provider'
        }
    };
}