import { Component, effect, forwardRef, inject, signal, viewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RecordFormComponent } from 'src/app/features/records/record-form/record-form.component';
import { NewChildRecord, RecordUpdateData } from '../../records.service';
import { ConfigGeometryTypes, RecordMapComponent } from 'src/app/features/records/record-detail-page/record-geometry/record-map.component';
import { ApiService } from 'src/app/core';
import { catchError, of, take } from 'rxjs';
import { Geometry } from 'geojson';
import { TooltipModule } from 'primeng/tooltip';

interface GeometryQuestionResponse {
    text: string;
    description: string;
    help: string;
    required: boolean;
    visible: boolean;
    config: any;
}

@Component({
    selector: 'app-child-form-modal',
    templateUrl: 'child-form-modal.component.html',
    standalone: true,
    imports: [
        forwardRef(() => RecordFormComponent),
        ButtonModule,
        RecordMapComponent,
        TooltipModule
    ]
})

export class ChildFormModalComponent {

    private dialogConfig = inject(DynamicDialogConfig);
    private dialogRef = inject(DynamicDialogRef);
    private apiService = inject(ApiService);

    public projectId: number;
    public surveyId: number;
    public attributeId: number;
    public isEdit: boolean = false;

    recordForm = viewChild<RecordFormComponent>('recordForm');
    recordMap = viewChild<RecordMapComponent>('recordMap');
    showHasErrors = signal(false);

    hasGeometryQuestion = signal(false);
    geometryLabel = signal<string | null>(null);
    geometryRequired = signal(false);
    geometryVisible = signal(false);
    geometryDescription = signal<string | null>(null);
    geometryHelp = signal<string | null>(null);
    geometryTypes = signal<ConfigGeometryTypes[]>([]);
    enforceBounds = signal(false);
    bounds = signal<Geometry | null>(null);

    constructor() { 
        this.projectId = this.dialogConfig.data['projectId'];
        this.surveyId = this.dialogConfig.data['surveyId'];
        this.attributeId = this.dialogConfig.data['attributeId'];
        this.getGeometryData();

        /** Using an effect here to check whether the map is present before continuing with editing, bounds etc */
        effect(() => {
            const map = this.recordMap();
            const hasGeometryQuestion = this.hasGeometryQuestion();
            const geometryVisible = this.geometryVisible();
            const enforceBounds = this.enforceBounds();
    
            if (!!map && hasGeometryQuestion && geometryVisible) {
                if (enforceBounds) {
                    this.getBounds();
                } else {
                    this.startEditGeometry();
                }
            }
        });
    }

    private getGeometryData() {
        const attributesFragment = `fragment attributeFields on Attribute{
            text
            description
            help
            required
            visible
            config
        }`;

        const query = `query getGeometryQuestionData{
            project(id: ${this.projectId}){
                surveys(where: {id: ${this.surveyId}}){
                    attributes(where: { questionType: "geometry" }){ ...attributeFields }
                }
                parent{
                    surveys(where: {id: ${this.surveyId}}){
                        attributes(where: { questionType: "geometry" }){ ...attributeFields }
                    }
                }
            }
        }${attributesFragment}`;

        this.apiService.graphql<{ project: { surveys: { attributes: GeometryQuestionResponse[] }[]; parent: { surveys: { attributes: GeometryQuestionResponse[] }[] } } }>(query).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null)
            })
        ).subscribe(res => {
            if (!!res) {
                const survey = res.project.surveys?.[0] ?? res.project.parent?.surveys?.[0];
                const { attributes } = survey;

                if (attributes.length < 1) {
                    /* No geometry question **/
                    return;
                }
                this.hasGeometryQuestion.set(true);
                
                const data = attributes[0];
                const { config } = data;
                
                if (!data.visible) {
                    /* geometry question is hidden **/
                    return;
                }
                    
                this.geometryVisible.set(data.visible);
                this.geometryLabel.set(data.text);
                this.geometryRequired.set(data.required);
                this.geometryDescription.set(data.description);
                this.geometryHelp.set(data.help);
                this.geometryTypes.set(config.types);
                if (config.enforceBounds) {
                    this.enforceBounds.set(config.enforceBounds);
                }
            }
        })
    }

    private getBounds() {
        const query = `query getProjectBounds{
            project(id: ${this.projectId}){
                geometry{
                    type
                    coordinates
                }
            }
        }`;

        this.apiService.graphql<{ project: { geometry: Geometry } }>(query).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null)
            })
        ).subscribe(res => {
            if (!!res) {
                this.bounds.set(res.project.geometry);  
                this.startEditGeometry(); 
            }
        });
    }

    private startEditGeometry() {
        /** Add small delay to ensure the map is ready to edit - fixes mapboxGIS error */
        setTimeout(() => {
            this.recordMap().startEdit(this.geometryTypes(), this.enforceBounds(), this.bounds());
        }, 500);
    }

    public geometryValid(): boolean {
        if (this.geometryRequired()) {
            const geometryIsValid: boolean = !!this.recordMap().updatedGeometry;
            return geometryIsValid;
        } else {
            return true;
        }
    }

    private formValid(): boolean {
        const formIsValid: boolean = this.recordForm().form.valid;
        return formIsValid;
    }

    private isValid(): boolean {
        if (this.hasGeometryQuestion()) {
            return this.geometryValid() && this.formValid();
        } else {
            return this.formValid();
        }
    }
    
    public saveCheck() {
        Object.keys(this.recordForm().form.controls).forEach(key => {
            // force highlight errors if controls haven't been touched
            this.recordForm().form.controls[key].markAsDirty();
        });

        if (this.isValid()) {
            this.save();
        } else {
            this.showHasErrors.set(true);
        }
    }
                    
    private save() {
        const recordUpdateData: RecordUpdateData = this.recordForm().formRecordUpdateData;
        let geometry: Geometry = null;
        if (this.hasGeometryQuestion()) {
            geometry = this.recordMap().updatedGeometry;
        }
        const data: NewChildRecord = {
            projectId: this.projectId,
            surveyId: this.surveyId,
            geometry,
            data: recordUpdateData.data,
            associates: recordUpdateData.newAssociates,
            childRecords: recordUpdateData.childRecords,
            attachments: recordUpdateData.newAttachments,
            attributeId: this.attributeId
        };

        this.dialogRef.close(data);
    }

    public cancel() {
        this.dialogRef.close();
    }
}