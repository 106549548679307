import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProjectCreateOrganisation } from '../../../core/models/project.model';
import { ApiService } from "src/app/core";

@Component({
  templateUrl: './create-project.component.html',
  selector: 'app-create-project',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ToastModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    AutoCompleteModule
  ],
  providers: [MessageService]
})
export class CreateProjectComponent {

  @Input() isVisibleDialog: boolean = false;
  @Output() visibleChange = new EventEmitter<{ isVisibleDialog: boolean; projectId: number; }>();

  createProjectForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(5)]),
    description: new FormControl(''),
    organisation: new FormControl<any>(null, [Validators.required]),
  });

  organisations: ProjectCreateOrganisation[] | [] = [];

  private readonly createProjectQuery: string = `mutation CreateProject($input: ProjectInput!) {
    createProject(input: $input) { id }
  }`;

  constructor(
    private apiService: ApiService,
    private messageService: MessageService
  ) { }

  ngOnInit() { }

  getOrganisation(event: {originalEvent: Event; query: string;}) {
    const query = `query CoreoAASearchOrganisations {
      organisations(
        where: { name: { iLike: "%${event.query}%" } },
        limit: 300
      ) { id, name }
    }`;
    return this.apiService.graphql<{ organisations: ProjectCreateOrganisation[] }>(query).subscribe(res => this.organisations = res.organisations);
  }

  onSubmit() {
    const { name, description, organisation } = this.createProjectForm.value;
    this.apiService.graphql<{createProject: { id: number }}>(this.createProjectQuery, { input: { name, description, organisationId: organisation.id } })
    .subscribe((response: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Project Created' });
      this.onHideDialog(response.createProject.id);
    },
    (error) => {
      console.error(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not create project' });
      this.onHideDialog();
    });
  }

  onHideDialog(projectId: number = null) {
    this.isVisibleDialog = false;
    this.visibleChange.emit({ isVisibleDialog: this.isVisibleDialog, projectId });
  }
}
