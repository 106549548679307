import { Component, ElementRef, OnInit, signal, viewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import { environment } from 'src/environments/environment';
import mapboxgl, { LngLatLike, Map as MapboxMap } from "mapbox-gl";
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { CommonModule } from '@angular/common';
import { Layer, RECORDS_SOURCE, addTileLayers } from '../../records.map.utils';
import { RecordSummaryComponent } from '../../record-detail-page/record-data/record-summary.component';
import { InputTextModule } from 'primeng/inputtext';
import { RecordMapComponent } from '../../record-detail-page/record-geometry/record-map.component';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';

const tabs = [
    'map',
    'list'
] as const;
type Tab = typeof tabs[number];

type Record = {
    id: number;
    title?: string;
}

@Component({
    selector: 'app-record-lookup-modal',
    templateUrl: 'record-lookup-modal.component.html',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        DataViewModule,
        RecordSummaryComponent,
        RecordMapComponent,
        InputTextModule,
        TabViewModule,
        CardModule,
        TableModule
    ]
})

export class RecordLookupModalComponent implements OnInit {

    private surveyId: number;
    private projectId: number;

    mapDiv = viewChild<ElementRef>('lookupMap');

    private map: MapboxMap;
    private mapUrl: string;

    activeTab = signal<Tab>('map');
    activeRecord = signal<Record | null>(null);
    records = signal<Record[]>([]);
    itemCount = signal(0);
    loading = signal(false);
    first = signal(0);
    rows = signal(10);

    constructor(
        private dialogRef: DynamicDialogRef,
        private dialogConfig: DynamicDialogConfig,
        private apiService: ApiService
    ) { }

    async ngOnInit() {
        this.surveyId = this.dialogConfig.data['surveyId'];
        this.projectId = this.dialogConfig.data['projectId'];
        this.loadRecords();
        this.getMapTiles().then(res => {
            this.mapUrl = res;
            this.initMap();
        });
    }

    public loadRecords($event: TableLazyLoadEvent = null) {
        this.loading.set(true);

        const query = `query recordLookupGetRecords($projectId: Int!, $surveyId: Int!, $where: SequelizeJSON, $offset: Int!, $limit: Int!){
            records(order:"reverse:createdAt", where: $where, offset: $offset, limit: $limit){
                id
                title
            }
            project(id: $projectId){
                surveys(where: { id: $surveyId }){
                    recordCount
                }
            }
        }`;

        if ($event) {
            this.first.set($event.first);
            this.rows.set($event.rows);
        }

        const where = {
            surveyId: this.surveyId,
        }

        const input = {
            projectId: this.projectId,
            surveyId: this.surveyId,
            where,
            offset: this.first(),
            limit: this.rows(),
        }

        this.apiService.graphql<{ records: Record[], project: { surveys: { recordCount: number }[] } }>(query, input).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                const { records, project } = res;
                this.records.set(records);
                this.itemCount.set(project.surveys[0].recordCount);
            }
            this.loading.set(false);
        });
    }

    private async getMapTiles(): Promise<string> {
        const query = {
            surveyId: this.surveyId
        };

        const body = {
            projectId: this.projectId,
            query
        };

        const res = await fetch(`${environment.mapsUrl}/tiles`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        const data = await res.json();
        const { mapId } = data;

        return `${environment.mapsUrl}/tiles/${mapId}/{z}/{x}/{y}.mvt`;
    }

    private initMap() {
        const mapOptions: mapboxgl.MapboxOptions = {
            accessToken: environment.mapboxApiKey,
            container: this.mapDiv().nativeElement,
            style: 'mapbox://styles/mapbox/streets-v12',
            projection: { name: 'mercator' },
            center: [-2.9, 54.4],
            zoom: 4,
        };

        this.map = new MapboxMap(mapOptions);

        this.map.on('load', async () => {
            this.map.resize();

            addTileLayers(this.map, this.mapUrl);

            this.map.on('mouseenter', Layer.CLUSTER, () => (this.map.getCanvas().style.cursor = 'pointer'));
            this.map.on('mouseleave', Layer.CLUSTER, () => (this.map.getCanvas().style.cursor = ''));

            this.map.on('mouseenter', Layer.POLYGON, () => (this.map.getCanvas().style.cursor = 'pointer'));
            this.map.on('mouseleave', Layer.POLYGON, () => (this.map.getCanvas().style.cursor = ''));

            this.map.on('mouseenter', Layer.LINE, () => (this.map.getCanvas().style.cursor = 'pointer'));
            this.map.on('mouseleave', Layer.LINE, () => (this.map.getCanvas().style.cursor = ''));

            this.map.on('mouseenter', Layer.POINT, () => (this.map.getCanvas().style.cursor = 'pointer'));
            this.map.on('mouseleave', Layer.POINT, () => (this.map.getCanvas().style.cursor = ''));

            this.map.on('click', (e) => {
                if (!!this.activeRecord()) {
                    this.map.removeFeatureState({
                        source: RECORDS_SOURCE,
                        'sourceLayer': RECORDS_SOURCE,
                        id: this.activeRecord().id
                    });
                }

                this.activeRecord.set(null);

                const features: any[] = this.map.queryRenderedFeatures(e.point, {
                    layers: [
                        Layer.CLUSTER,
                        Layer.POINT,
                        Layer.LINE,
                        Layer.POLYGON,
                    ],
                    validate: false
                });

                if (features.length === 0) {
                    return;
                }

                const feature = features[0];

                if (feature.layer.id === Layer.CLUSTER) {
                    this.map.easeTo({
                        center: feature.geometry.coordinates as LngLatLike,
                        zoom: 10
                    });
                    return;
                }

                this.activeRecord.set(feature.properties);
                console.log(feature);
                console.log(this.activeRecord());


                this.map.setFeatureState(
                    {
                        source: RECORDS_SOURCE,
                        'sourceLayer': RECORDS_SOURCE,
                        id: this.activeRecord().id
                    },
                    {
                        active: true
                    }
                );
            });

        });
    }

    public selectRecord() {
        if (!this.activeRecord()) {
            return;
        }

        this.dialogRef.close(this.activeRecord().id);
    }

    public isSelected(item: Record): boolean {
        if (this.activeRecord()) {
            return this.activeRecord().id === item.id;
        } else {
            return false;
        }
    }

    public onTabChange($event: TabViewChangeEvent) {
        this.activeTab.set(tabs[$event.index]);
        this.activeRecord.set(null);
    }
}