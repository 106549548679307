import angular, { IController } from 'angular';
import { getProjectMaps } from "../../store/selectors";
import ngRedux from 'ng-redux';
import ModalService from '../../main/modal.service';

export default class ProjectMapsConfigController implements IController {

    createProjectMap: (map: any) => any;
    deleteProjectMap: (id: number) => any;

    static $inject = ['$scope', '$ngRedux', '$state', 'project', '$mdDialog', 'ModalService', 'ProjectActions'];
    constructor($scope, $ngRedux: ngRedux.INgRedux, private $state, private project, private $mdDialog: angular.material.IDialogService, private ModalService: ModalService, ProjectActions) {
        $scope.$on('$destroy', $ngRedux.connect(this.mapStateToThis, ProjectActions)(this));
    }

    mapStateToThis(state: any) {
        return {
            maps: getProjectMaps(state)
        };
    }

    create(ev: MouseEvent) {
        var project = this.project;

        const modal = this.$mdDialog.show({
            parent: document.body,
            targetEvent: ev,
            clickOutsideToClose: true,
            templateUrl: 'create-map-modal.html',
            controllerAs: 'ctrl',
            controller: class CreateMapOverlayController implements IController {

                name: string;
                description: string;

                static $inject = ['$mdDialog'];
                constructor(private $mdDialog) {

                }

                ok() {
                    this.$mdDialog.hide(({
                        name: this.name,
                        description: this.description
                    }));
                }

                cancel() {
                    console.log('Cancelling');
                    this.$mdDialog.cancel();
                }
            }
        });

        modal.then(async result => {
            const map = await this.createProjectMap(result);
            this.$state.go('project.map-config', { map_id: map.id });
        });
    };

    async delete(ev: MouseEvent, map: any) {
        const result = await this.ModalService.confirm({
            title: 'Delete Map Overlay?',
            text: 'Are you sure you want to delete this map overlay?',
            ev
        });

        if (result) {
            this.deleteProjectMap(map.id);
        }
    }
}