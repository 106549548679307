import { Component, Inject, OnInit } from '@angular/core';
import { JobsService } from './core/services/jobs.service';
import { NG_REDUX } from './core/core.module';
import { UserService } from './core/services/user.service';

@Component({
    selector: 'app-root',
    template: '',
})
export class AppComponent implements OnInit {

    constructor(
        private userService: UserService,
        private jobsService: JobsService
    ) {
    }

    ngOnInit() {
        this.userService.populate();
        this.jobsService.init();
    }
}