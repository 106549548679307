import angular from 'angular';
// Services
import ProjectsService from './projects.service';
import ProjectService from './project.service';
import ProjectMapsService from './maps/project-maps.service';
import ProjectPagesService from './pages/project-pages.service';
import ProjectAttributesService from './project-attributes.service';
import ProjectMediaService from './media/project-media.service';
import ItemValueService from './collections/item-value/item-value.service';

import ProjectDependencyErrorService from './dependency-error/project-dependency-error.service';

// Controllers
import ProjectController from './project.controller';
import ProjectDashboardController from './dashboard/project-dashboard.controller';
import ProjectRecordsController from './records/project-records.controller';
import ProjectConfigController from './config/project-config.controller';
import ProjectRecordStatusSettingsController from './settings/project-record-status-settings.controller';
import ProjectFormsController from './forms/project-forms.controller';
import ProjectFormController from './forms/project-form.controller';
import ProjectUsersController from './users/project-users.controller';
import ProjectUserController from './users/project-user.controller';
import ProjectMapsController from './maps/project-maps.controller';
import ProjectMapsConfigController from './maps/project-maps-config.controller';
import ProjectMapsLayersController from './maps/project-maps-layers.controller';
import ProjectMapsLayerController from './maps/project-maps-layer.controller';
import ProjectMapEditorController from './maps/project-map-editor.controller';
import ProjectPageController from './pages/project-page.controller';
import ProjectPageWysiwygController from './pages/project-page-wysiwyg.controller';
import ProjectPageCollectionController from './pages/project-page-collection.controller';
import ProjectMapConfigController from './maps/project-map-config.controller';
import ProjectStylingController from './styling/project-styling.controller';
import ProjectCredentialsController from './credentials/project-credentials.controller';
import ProjectFormCreateController from './forms/create/project-form-create.controller';
import ProjectRecordsImportController from './records/project-records-import/project-records-import.controller';
import ProjectRecordsInvalidShapefileExportController from './records/project-records-invalid-shapefile-export/project-records-invalid-shapefile-export.controller';

// Components
import { ProjectSidebarComponent } from './ui/project-sidebar/project-sidebar.component';
import { ProjectRecordsChipsComponent } from './records/project-records-chips/project-records-chips.component';
import { ProjectRecordsTableComponent } from './records/project-records-table/project-records-table.component';
import { ProjectRecordsToolbarComponent } from './records/project-records-toolbar/project-records-toolbar.component';
import { ProjectRecordsMapComponent } from './records/project-records-map/project-records-map.component';
import { ProjectMapComponent } from './maps/project-map.component';
import { ProjectFormsBlockTextComponent } from './forms/block/text/project-forms-block-text.component';
import { ProjectFormsBlockEditorComponent } from './forms/block/editor/project-forms-block-editor.component';
import { ProjectFormsBlockQuestionComponent } from './forms/block/question/project-forms-block-question.component';
import { ProjectFormsBlockDeletedComponent } from './forms/block/deleted/project-forms-block-deleted.component';
import { ProjectFormsBlockComponent } from './forms/block/project-forms-block.component';
import { ProjectFormsSectionDividerComponent } from './forms/block/project-forms-section-divider.component';
import { ProjectFormsRulesSummaryComponent } from './forms/rules/project-forms-rules-summary.component';
import { ProjectMediaItemComponent } from './project-media-item.component';
import { ProjectCollectionSelectComponent } from './ui/project-collection-select/project-collection-select.component';
import { ProjectMapBaseSelectorComponent } from './maps/base-selector/project-map-base-selector.component';
import { MapboxJsonEditorComponent } from './maps/mapbox-style-editor/mapbox-json-editor.component';
import { MapboxPropEditorComponent } from './maps/mapbox-style-editor/mapbox-prop-editor.component';
import { MapboxStyleEditorComponent } from './maps/mapbox-style-editor/mapbox-style-editor.component';
import { HeatmapStyleEditorComponent } from './maps/heatmap-style-editor/heatmap-style-editor.component';
import { ProjectRecordStateComponent } from './record/record-state/project-record-state.component';
import { MediaManagerComponent } from './media/media-manager.component';
import { MediaManagerItemComponent } from './media/media-manager-item.component';
import { ColorSelectorComponent } from './styling/color-selector/color-selector.component';
import { ProjectRecordsAttributeFilterComponent } from './records/project-records-attribute-filter/project-records-attribute-filter.component';
import { ItemValueComponent } from './collections/item-value/item-value.component';
import { MapDataLayerEditorComponent } from './maps/map-data-layer-editor/map-data-layer-editor.component';
// Directives
import ProjectFormsBlockQuestionSummaryDirective from './forms/block/question/summary/project-forms-block-question-summary.directive';
import ProjectFormsRulesComponent from './forms/rules/project-forms-rules.component';
import ProjectFormsCollectionDirective from './forms/collection/project-forms-collection.directive';

// Filters
import ProjectPagesBlockInfoFilter from './pages/project-pages-block-info.filter';
import ProjectFormsQuestionInfoFilter from './forms/project-forms-question-info.filter';
import MapboxExpressionSummaryFilter from './maps/mapbox-style-editor/mapbox-expression-summary.filter';

// Constants
import { PROJECT_ADMIN } from '../permissions/permissions.constants';

import { getProjectCollection, getAuthToken, getProjectId, getProjectForm, getProjectLocked, getOrganisation, getOrganisationId, getOrganisationRole, getProjectSlug, getAuthIsAdmin, getProjectRole, getProject, getProjectBoundingBox, getProjectCollections, getProjectParentProjectId, getProjectForms, getProjectParent } from '../store/selectors';
import { template } from 'lodash';

export default angular
    .module('app.projects', ['ngSanitize', 'ui.router'])
    .service('ProjectService', ProjectService)
    .service('ProjectsService', ProjectsService)
    .service('ProjectMapsService', ProjectMapsService)
    .service('ProjectPagesService', ProjectPagesService)
    .service('ProjectAttributesService', ProjectAttributesService)
    .service('ProjectMediaService', ProjectMediaService)
    .service('ProjectDependencyErrorService', ProjectDependencyErrorService)
    .service('ItemValueService', ItemValueService)
    .controller('ProjectController', ProjectController)
    .controller('ProjectDashboardController', ProjectDashboardController)
    .controller('ProjectRecordsController', ProjectRecordsController)
    .controller('ProjectConfigController', ProjectConfigController)
    .controller('ProjectRecordStatusSettingsController', ProjectRecordStatusSettingsController)
    .controller('ProjectFormsController', ProjectFormsController)
    .controller('ProjectFormController', ProjectFormController)
    .controller('ProjectUsersController', ProjectUsersController)
    .controller('ProjectUserController', ProjectUserController)
    .controller('ProjectMapsController', ProjectMapsController)
    .controller('ProjectMapsConfigController', ProjectMapsConfigController)
    .controller('ProjectMapsLayersController', ProjectMapsLayersController)
    .controller('ProjectMapsLayerController', ProjectMapsLayerController)
    .controller('ProjectMapEditorController', ProjectMapEditorController)
    .controller('ProjectPageController', ProjectPageController)
    .controller('ProjectPageWysiwygController', ProjectPageWysiwygController)
    .controller('ProjectPageCollectionController', ProjectPageCollectionController)
    .controller('ProjectStylingController', ProjectStylingController)
    .controller('ProjectMapConfigController', ProjectMapConfigController)
    .controller('ProjectCredentialsController', ProjectCredentialsController)
    .controller('ProjectFormCreateController', ProjectFormCreateController)
    .controller('ProjectRecordsImportController', ProjectRecordsImportController)
    .controller('ProjectRecordsInvalidShapefileExportController', ProjectRecordsInvalidShapefileExportController)
    .component('projectSidebar', ProjectSidebarComponent)
    .component('projectRecordsChips', ProjectRecordsChipsComponent)
    .component('projectRecordsTable', ProjectRecordsTableComponent)
    .component('projectRecordsToolbar', ProjectRecordsToolbarComponent)
    .component('projectRecordsMap', ProjectRecordsMapComponent)
    .component('projectMap', ProjectMapComponent)
    .component('projectFormsBlock', ProjectFormsBlockComponent)
    .component('projectFormsSectionDivider', ProjectFormsSectionDividerComponent)
    .component('projectFormsRulesSummary', ProjectFormsRulesSummaryComponent)
    .component('projectFormsBlockText', ProjectFormsBlockTextComponent)
    .component('projectFormsBlockEditor', ProjectFormsBlockEditorComponent)
    .component('projectFormsBlockQuestion', ProjectFormsBlockQuestionComponent)
    .component('projectFormsBlockDeleted', ProjectFormsBlockDeletedComponent)
    .component('projectFormsRules', ProjectFormsRulesComponent)
    .component('projectMediaItem', ProjectMediaItemComponent)
    .component('projectCollectionSelect', ProjectCollectionSelectComponent)
    .component('projectMapBaseSelector', ProjectMapBaseSelectorComponent)
    .component('mapboxStyleEditor', MapboxStyleEditorComponent)
    .component('heatmapStyleEditor', HeatmapStyleEditorComponent)
    .component('mapboxPropEditor', MapboxPropEditorComponent)
    .component('mapboxJsonEditor', MapboxJsonEditorComponent)
    .component('mapDataLayerEditor', MapDataLayerEditorComponent)
    .component('projectRecordState', ProjectRecordStateComponent)
    .component('mediaManager', MediaManagerComponent)
    .component('mediaManagerItem', MediaManagerItemComponent)
    .component('colorSelector', ColorSelectorComponent)
    .component('projectRecordsAttributeFilter', ProjectRecordsAttributeFilterComponent)
    .component('itemValue', ItemValueComponent)
    .directive('projectFormsBlockQuestionSummary', () => new ProjectFormsBlockQuestionSummaryDirective())
    .directive('projectFormsCollection', ['$timeout', 'toastr', ($timeout, toastr) => new ProjectFormsCollectionDirective($timeout, toastr)])
    .filter('blockInfo', ProjectPagesBlockInfoFilter)
    .filter('questionInfo', ProjectFormsQuestionInfoFilter)
    .filter('mapboxExpression', MapboxExpressionSummaryFilter)
    .constant('PROJECT_RECORD_VIEWS',
        [
            { label: 'Map & Table', id: 'map-table', icon: 'fa-columns' },
            { label: 'Map', id: 'map', icon: 'fa-map' },
            { label: 'Table', id: 'table', icon: 'fa-table' }
        ]
    )
    .run(['$transitions', '$ngRedux', function ($transitions, $ngRedux) {
        $transitions.onEnter({}, function (trans) {
            const state = $ngRedux.getState();
            const toState = trans.to();
            const isLocked = getProjectLocked(state);

            if (getAuthIsAdmin(state) || !isLocked || !(toState.data && toState.data.lockable)) {
                return true;
            }

            const fromState = trans.from();

            // If we are already in settings, do nothing
            if (fromState.name === 'project.settings') {
                return false;
            }

            const orgSlug = getOrganisation(state).slug;
            const id = getProjectSlug(state);
            return trans.router.stateService.target('project.settings', {
                id,
                orgSlug
            });
        });
    }])
    .config(['$stateProvider', '$qProvider', function ($stateProvider, $qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
        $stateProvider
            .state('project', {
                abstract: true,
                // parent: 'org',
                // url: '/{orgSlug:[^\/]+}',
                url: '/{orgSlug:[^\/]+}/{id:[^\/]+}',
                template: require('!raw-loader!../layouts/project.layout.html').default,
                controller: 'ProjectController as ctrl',
                resolvePolicy: {
                    when: 'EAGER',
                    async: 'WAIT'
                },
                resolve: {
                    project: ['$stateParams', '$ngRedux', 'ProjectActions', function ($stateParams, $ngRedux, ProjectActions) {
                        return $ngRedux.dispatch(ProjectActions.loadProject($stateParams.id))
                    }]
                },
                onEnter: ['$transition$', '$state', '$ngRedux', 'project', function ($transition$, $state, $ngRedux, project) {
                    if (project === null) {
                        return $transition$.router.stateService.go('home', {
                        });
                    } else {
                        const project = getProject($ngRedux.getState());
                        let projectImageUrl = '../assets/icons/no-icon.png';
                        if (project && project.imageUrl) {
                            const url = new URL(project.imageUrl);
                            projectImageUrl = `https://coreo.twic.pics${url.pathname}?twic=v1/resize=256x256`;
                        }

                        $state.get('project').data = {
                            breadcrumb: {
                                label: project.name,
                                state: 'project.dashboard',
                                project: projectImageUrl
                            }
                        };
                        const orgState = $state.get('org');
                        orgState.data.breadcrumb.label = project.organisation.name;
                    }
                }]
            })
            .state('project.dashboard', {
                url: '',
                template: require('!raw-loader!./dashboard/project-dashboard.html').default,
                controller: 'ProjectDashboardController as ctrl',
                data: {
                    breadcrumb: {
                        parent: 'project',
                        label: null,
                        skip: true
                    },
                    hasTutorial: true
                }
            })
            .state('project.map-layers', {
                url: '/map-layers',
                template: require('!raw-loader!./maps/project-maps-layers.html').default,
                controller: 'ProjectMapsLayersController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Map Layers',
                        parent: 'project.config'
                    }
                }
            })
            .state('project.map-layer', {
                url: '/map-layers/:layer_id',
                template: require('!raw-loader!./maps/project-maps-layer.html').default,
                controller: 'ProjectMapsLayerController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Map Layer',
                        parent: 'project.config'
                    }
                }
            })
            .state('project.maps-config', {
                url: '/maps-config',
                template: require('!raw-loader!./maps/project-map-editor.html').default,
                controller: 'ProjectMapEditorController as ctrl',
                unscopedParent: 'project.maps-config',
                // resolve: {
                //     map: function ($stateParams, project, ProjectMapsService) {
                //         return ProjectMapsService.getMap(project.id, $stateParams.map_id);
                //     }
                // },
                data: {
                    breadcrumb: {
                        label: 'Map Builder',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.maps', {
                url: '/maps?z&c&m',
                reloadOnSearch: false,
                template: require('!raw-loader!./maps/project-maps.html').default,
                controller: 'ProjectMapsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Maps',
                        parent: 'project'
                    }
                },
                resolve: {
                    map: ['project', 'ProjectMapsService', function (project, ProjectMapsService) {
                        return ProjectMapsService.getDefaultMap(project.id);
                    }]
                }
            })
            .state('project.config', {
                url: '/config',
                template: require('!raw-loader!./config/project-config.html').default,
                controller: 'ProjectConfigController as ctrl',

                data: {
                    breadcrumb: {
                        label: 'Configuration',
                        parent: 'project'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.pages', {
                url: '/pages/',
                template: `<app-project-pages
                [role]="ctrl.role"
                [parent]="ctrl.parent"
                [project]="ctrl.project"
                (page)="ctrl.page($event)"
                ></app-project-pages>`,
                controllerAs: 'ctrl',
                controller: class ProjectPagesController {
                    static $inject = ['$state', '$stateParams', '$scope', '$ngRedux', 'ProjectActions'];
                    constructor(public $state, public $stateParams, public $scope, public $ngRedux, public ProjectActions) {
                        $scope.$on('$destroy', $ngRedux.connect(state => {
                            return {
                                role: getProjectRole(state),
                                project: getProject(state),
                                parent: getProjectParent(state)
                            }
                        })(this));
                    }

                    async page(event: { pageId: number, isNewPage: boolean }) {
                        if (event.isNewPage) {
                            await this.$ngRedux.dispatch(this.ProjectActions.loadProject(this.$stateParams.id));
                        }
                        this.$state.go('project.page', { page_id: event.pageId });
                    }
                },
                data: {
                    breadcrumb: {
                        label: 'Pages',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.page', {
                url: '/pages/:page_id',
                views: {
                    '': {
                        template: require('!raw-loader!./pages/project-page.html').default,
                        controller: 'ProjectPageController as ctrl'
                    },
                    'page@project.page': {
                        templateProvider: ['page', function (page) {
                            switch (page.type) {
                                case 'map':
                                case 'wysiwyg':
                                case 'collection': {
                                    return require(`!raw-loader!./pages/project-page-${page.type}.html`).default;
                                }
                                default: {
                                    return 'Unknown page type';
                                }
                            }
                        }],
                        controllerProvider: ['page', function (page) {
                            switch (page.type) {
                                case 'wysiwyg': {
                                    return 'ProjectPageWysiwygController'
                                }
                                case 'collection': {
                                    return 'ProjectPageCollectionController'
                                }
                            }
                        }],
                        controllerAs: 'viewCtrl'
                    }
                },
                unscopedParent: 'project.pages',
                resolvePolicy: {
                    when: 'EAGER'
                },
                resolve: {
                    pageData: ['$stateParams', 'ProjectService', 'project', ($stateParams, projectService, project) => {
                        return projectService.getPage(project.id, $stateParams.page_id);

                    }],
                    page: ['$ngRedux', '$stateParams', 'project', 'ProjectPageActions', 'pageData', ($ngRedux, $stateParams, project, ProjectPageActions, pageData) => {
                        console.log('Page Data', pageData);
                        return $ngRedux.dispatch(ProjectPageActions.initPage(pageData));
                    }],
                    pages: ['ProjectService', 'project', (ProjectService, project) => {
                        return ProjectService.getPages(project.id);
                    }],
                    previewUrl: ['$ngRedux', '$sce', 'apiHostname', ($ngRedux, $sce, apiHostname) => {
                        const state = $ngRedux.getState();
                        const token = getAuthToken(state);
                        const appId = getProjectId(state);
                        const env = apiHostname.indexOf('dev.coreo') === -1 ? 'prod' : 'dev';

                        if (window.location.hostname === 'localhost') {
                            return $sce.trustAsResourceUrl(`http://localhost:3333/preview?appId=${appId}&token=${token}&env=${env}`);
                        } else {
                            return $sce.trustAsResourceUrl(`https://coreo-app-preview.netlify.app/preview?appId=${appId}&token=${token}&env=${env}`);
                        }
                    }]
                },
                data: {
                    breadcrumb: {
                        label: '',
                        parent: 'project.pages'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                },
                onEnter: ['$state', 'page', function ($state, page) {
                    $state.get('project.page').data.breadcrumb.label = page.title;
                }]
            })
            .state('project.styling', {
                url: '/styling',
                template: require('!raw-loader!./styling/project-styling.html').default,
                controller: 'ProjectStylingController as ctrl',
                resolve: {
                    styleView: () => 'app'
                },
                data: {
                    breadcrumb: {
                        label: 'Styling',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.styling-pwa', {
                url: '/styling-pwa',
                template: require('!raw-loader!./styling/project-styling.html').default,
                controller: 'ProjectStylingController as ctrl',
                resolve: {
                    styleView: () => 'pwa'
                }
            })
            .state('project.forms', {
                url: '/forms',
                template: require('!raw-loader!./forms/project-forms.html').default,
                controller: 'ProjectFormsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Forms',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.form', {
                url: '/forms/:form_id',
                template: require('!raw-loader!./forms/project-form.html').default,
                controller: 'ProjectFormController as ctrl',
                unscopedParent: 'project.forms',
                resolvePolicy: {
                    when: 'EAGER'
                },
                resolve: {
                    init: ['$ngRedux', '$stateParams', 'project', 'ProjectFormActions', '$q', 'ProjectActions', ($ngRedux, $stateParams, project, ProjectFormActions, $q, ProjectActions) => {
                        return $q.all([
                            $ngRedux.dispatch(ProjectFormActions.initForm($stateParams.form_id)),
                            $ngRedux.dispatch(ProjectActions.loadCollections())
                        ]);
                        // return $ngRedux.dispatch(ProjectFormActions.initForm($stateParams.form_id));
                    }]
                },
                data: {
                    breadcrumb: {
                        label: '',
                        parent: 'project.forms'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                },
                onEnter: ['$state', '$ngRedux', '$stateParams', function ($state, $ngRedux, $stateParams) {
                    const form = getProjectForm(+$stateParams.form_id)($ngRedux.getState());
                    $state.get('project.form').data.breadcrumb.label = form && form.name;
                }]
            })
            .state('project.users', {
                url: '/users',
                template: require('!raw-loader!./users/project-users.html').default,
                controller: 'ProjectUsersController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Members',
                        parent: 'project'
                    },
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.user', {
                url: '/users/:projectUserId',
                template: require('!raw-loader!./users/project-user.html').default,
                controller: 'ProjectUserController as ctrl',
                resolve: {
                    projectUser: ['CoreoAPI', 'project', '$stateParams', (CoreoAPI, project, $stateParams) => {
                        const query = `query AAGetProjectUser($projectId: Int!){
              project(id: $projectId){
                projectUsers(where:{userId: ${$stateParams.projectUserId}}){
                  result{
                    userId,
                    user{
                      username,
                      imageUrl,
                      displayName,
                      email,
                      verified,
                      provider
                    },
                    data
                  }
                }
              }
            }`;
                        return CoreoAPI.query(query, {
                            variables: {
                                projectId: project.id,
                                userId: +$stateParams.projectUserId
                            }
                        }).then(result => {
                            return result && result.project && result.project.projectUsers && result.project.projectUsers.result && result.project.projectUsers.result[0];
                        });
                    }]
                },
                onEnter: ['$state', 'projectUser', function ($state, projectUser) {
                    const label = projectUser && projectUser.user && projectUser.user.displayName;
                    if (label) {
                        $state.get('project.user').data.breadcrumb.label = label;
                    }
                }],
                data: {
                    breadcrumb: {
                        label: 'User',
                        parent: 'project.users'
                    },
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.apikeys', {
                url: '/apikeys',
                template: `<app-project-api-keys
                    [role]="ctrl.role"
                    [project-id]="ctrl.projectId"></app-project-api-keys>`,
                controllerAs: 'ctrl',
                controller: class ProjectApiKeysController {
                    static $inject = ['$scope', '$ngRedux', 'ProjectActions'];
                    constructor($scope, public $ngRedux, public ProjectActions) {
                        $scope.$on('$destroy', $ngRedux.connect(state => {
                            const project = getProject(state)
                            return {
                                projectId: project.id,
                                role: getProjectRole(state)
                            }
                        })(this));
                    }
                },
                data: {
                    breadcrumb: {
                        label: 'API Keys',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.credentials', {
                url: '/credentials',
                template: require('!raw-loader!./credentials/project-credentials.html').default,
                controller: 'ProjectCredentialsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'OAuth Credentials',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.hooks', {
                url: '/hooks',
                template: `<app-project-hooks
                [project]="ctrl.project"
                [surveys]="ctrl.surveys"
                [role]="ctrl.role"></app-project-hooks>`,
                controllerAs: 'ctrl',
                controller: class ProjectHooksController {
                    static $inject = ['$scope', '$ngRedux', 'ProjectActions'];
                    constructor($scope, public $ngRedux, public ProjectActions) {
                        $scope.$on('$destroy', $ngRedux.connect(state => {
                            return {
                                role: getProjectRole(state),
                                project: getProject(state),
                                surveys: getProjectForms(state)
                            }
                        })(this));
                    }
                },
                data: {
                    breadcrumb: {
                        label: 'Hooks',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.settings', {
                url: '/settings',
                template: `<app-project-settings
                [project]="ctrl.project"
                [org-role]="ctrl.orgRole"
                [role]="ctrl.role"
                [parent-project-id]="ctrl.parentProjectId"
                [bounds]="ctrl.bounds"
                [is-admin]="ctrl.isAdmin"
                [organisation-id]="ctrl.organisationId"
                [mapbox]="ctrl.mapbox"
                (update)="ctrl.update($event)"
                (removed)="ctrl.removed()">
                </app-project-settings>`,
                controllerAs: 'ctrl',
                controller: class ProjectSettingsController {
                    static $inject = ['$state', '$scope', '$ngRedux', 'ProjectActions', 'Mapbox'];
                    constructor(public $state, public $scope, public $ngRedux, public ProjectActions, public Mapbox) {
                        $scope.$on('$destroy', $ngRedux.connect(state => {
                            return {
                                project: getProject(state),
                                orgRole: getOrganisationRole(state),
                                role: getProjectRole(state),
                                bounds: getProjectBoundingBox(state),
                                isAdmin: getAuthIsAdmin(state),
                                organisationId: getOrganisationId(state),
                                parentProjectId: getProjectParentProjectId(state),
                                mapbox: Mapbox
                            }
                        })(this));
                    }

                    update(project) {
                        this.$ngRedux.dispatch({
                            type: 'UPDATE_PROJECT_SUCCESS',
                            project
                        });
                    }

                    removed() {
                        this.$state.go('home');
                    }
                },
                data: {
                    breadcrumb: {
                        label: 'Project Settings',
                        parent: 'project.config'
                    },
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.verificationsettings', {
                url: '/settings/record-status',
                template: require('!raw-loader!./settings/project-record-status-settings.html').default,
                controller: 'ProjectRecordStatusSettingsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Record Status Settings',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.collections', {
                url: '/collections/',
                template: `<app-project-collections
                [parent]="ctrl.parent"
                [project-id]="ctrl.projectId"
                (collection)="ctrl.collection($event)"
                />`,
                controllerAs: 'ctrl',
                controller: class ProjectCollectionsController {
                    static $inject = ['$state', '$stateParams', '$scope', '$ngRedux', 'ProjectActions'];
                    constructor(public $state, public $stateParams, public $scope, public $ngRedux, public ProjectActions) {
                        $scope.$on('$destroy', $ngRedux.connect(state => {
                            return {
                                projectId: getProjectId(state),
                                parent: getProjectParent(state)
                            }
                        })(this));
                    }

                    async collection(event: { collectionId: number, isNewCollection: boolean }) {
                        if (event.isNewCollection) {
                            await this.$ngRedux.dispatch(this.ProjectActions.loadProject(this.$stateParams.id));
                        }
                        this.$state.go('project.collection', { collection_id: event.collectionId });
                    }
                },
                data: {
                    breadcrumb: {
                        label: 'Collections',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.collection', {
                url: '/collections/:collection_id',
                template: `<app-collection-detail 
                [project-id]="ctrl.projectId" 
                [collection-id]="ctrl.collectionId"
                (item)="ctrl.item($event)"
                (delete)="ctrl.delete()">
                </app-collection-detail>`,
                controller: class ProjectCollectionController {
                    static $inject = ['$rootScope', '$scope', '$state', '$stateParams', '$ngRedux', 'ProjectActions'];
                    projectId;
                    collectionId;
                    state;
                    rootScope;
                    ngRedux;
                    projectActions;
                    constructor($rootScope, $scope, $state, $stateParams, $ngRedux, ProjectActions) {
                        $scope.$on('$destroy', $ngRedux.connect(state => {
                            return {
                                projectId: getProjectId(state),
                                collectionId: $stateParams.collection_id,
                                state: $state,
                                rootScope: $rootScope,
                                ngRedux: $ngRedux,
                                projectActions: ProjectActions
                            }
                        })(this));
                    }

                    item(collectionItemId) {
                        this.state.go('project.collection-item', {
                            collection_id: this.collectionId,
                            item_id: collectionItemId
                        });
                    }

                    delete() {
                        this.ngRedux.dispatch(this.projectActions.deleteCollection(+this.collectionId))
                            .then((result) => {
                                if (result) {
                                    this.state.go('project.collections');
                                }
                            });
                    }
                },
                controllerAs: 'ctrl',
                unscopedParent: 'project.collections',
                data: {
                    breadcrumb: {
                        label: '',
                        parent: 'project.collections'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                },
                onEnter: ['$state', '$ngRedux', '$stateParams', function ($state, $ngRedux, $stateParams) {
                    const collection = getProjectCollection($stateParams.collection_id)($ngRedux.getState());
                    if (collection) {
                        $state.get('project.collection').data.breadcrumb.label = collection.name;
                    }
                }]
            })
            .state('project.collection-item', {
                url: '/collections/:collection_id/items/:item_id',
                template: `<app-collection-item-detail
                [item-id]="ctrl.itemId"
                [collection-id]="ctrl.collectionId"
                [project-id]="ctrl.projectId"
                (back)="ctrl.back()"">
                </app-collection-item-detail>`,
                controller: class ProjectCollectionItemController {
                    static $inject = ['$rootScope', '$scope', '$state', '$stateParams', '$ngRedux', 'ProjectActions'];
                    projectId;
                    collectionId;
                    itemId;
                    state;
                    rootScope;
                    ngRedux;
                    constructor($rootScope, $scope, $state, $stateParams, $ngRedux) {
                        $scope.$on('$destroy', $ngRedux.connect(state => {
                            return {
                                projectId: getProjectId(state),
                                collectionId: $stateParams.collection_id,
                                itemId: $stateParams.item_id,
                                state: $state,
                                rootScope: $rootScope,
                                ngRedux: $ngRedux,
                            }
                        })(this));
                    }

                    back() {
                        // go back to collection
                        this.state.go('project.collection', { collection_id: this.collectionId });
                    }
                },
                controllerAs: 'ctrl',
                unscopedParent: 'project.collections',
                data: {
                    permissions: [
                        PROJECT_ADMIN
                    ],
                    lockable: true,
                    breadcrumb: {
                        label: '',
                        parent: 'project.collection'
                    }
                },
                resolve: {
                    // TODO: we don't want to get the whole collection here for the breadcrumb
                    item: ['$stateParams', '$ngRedux', 'ProjectActions', ($stateParams, $ngRedux, ProjectActions) => {
                        return $ngRedux.dispatch(ProjectActions.loadCollectionItem($stateParams.collection_id, $stateParams.item_id))
                    }],
                    collection: ['$stateParams', '$ngRedux', 'ProjectActions', ($stateParams, $ngRedux) => {
                        return getProjectCollection(+$stateParams.collection_id)($ngRedux.getState());
                    }]
                },
                onEnter: ['$state', '$stateParams', 'item', 'collection', function ($state, $stateParams, item, collection) {
                    if (collection) {
                        const bc = $state.get('project.collection').data.breadcrumb;
                        bc.label = collection.name;
                        bc.stateParams = { collection_id: +$stateParams.collection_id };
                    }
                    if (item) {
                        $state.get('project.collection-item').data.breadcrumb.label = item.value;
                    }
                }]
            })
            .state('project.records', {
                url: '/records',
                template: require('!raw-loader!./records/project-records.html').default,
                controller: 'ProjectRecordsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Records',
                        parent: 'project'
                    }
                },
                onEnter: ['$ngRedux', 'RecordsActions', 'search', ($ngRedux, RecordsActions, search) => {
                    $ngRedux.dispatch(RecordsActions.recordsInit(search));
                }],
                resolve: {
                    search: ['$location', '$q', 'Boundaries', 'project', 'CoreoAPI', function ($location, $q, Boundaries, project, CoreoAPI) {
                        const search = $location.search();
                        const promises = [];

                        if (search && search.b) {
                            promises.push(Boundaries.getBoundary(search.b).then(b => search.b = b));
                        }
                        if (search && search.u) {
                            const query = `{project(id:${project.id}){projectUsers(where:{ userId: ${search.u} }){result{user{displayName,id}}}}}`;
                            promises.push(CoreoAPI.query(query).then(result => {
                                search.u = result.project.projectUsers.result[0].user;
                            }));
                        }

                        return $q.all(promises).then(() => search);
                    }]
                }
            })
            .state('project.record', {
                url: '/records/:record_id',
                // Angular 16 Version
                template: `<app-record-detail 
                    [record-id]="ctrl.recordId"
                    (record)="ctrl.record($event)"
                    (records)="ctrl.records()"
                ></app-record-detail>`,
                controller: class RecordDetailController {
                    static $inject = ['$state', '$stateParams'];
                    recordId;
                    state;
                    constructor($state, $stateParams) {
                        this.recordId = $stateParams.record_id;
                        this.state = $state;
                    }

                    record(recordId) {
                        this.state.go('project.record', { record_id: recordId });
                    }

                    records() {
                        this.state.go('project.records');
                    }
                },
                controllerAs: 'ctrl',
            })
            .state('project.profile', {
                url: '/profile',
                templateUrl: 'auth/profile/profile.html',
                controller: 'ProfileController as ctrl',
                resolve: {
                    profile: ['$http', function ($http) {
                        return $http.get('/auth/profile').then(function (res) {
                            return res.data;
                        });
                    }]
                }
            })
            .state('project.terms', {
                url: '/terms',
                templateUrl: 'legal/terms/terms.html',
                controller: 'LegalTermsController as ctrl'
            })
            .state('project.privacy', {
                url: '/privacy',
                templateUrl: 'legal/privacy/privacy.html',
                controller: 'LegalPrivacyController as ctrl'
            });

    }]);
