import { getProjectStates, getRecordsView, getProjectForms, getRecordsSelectedCount, getRecordsSelectedHasMedia, getProjectRole, getRecordsTotal, getRecordsFormId, getProjectId, getRecordsFilterDirty, getRecordsFormHasGeometry, getProjectAttributesHasGeometry, getRecordsFormHasMedia, getProjectAttributesHasMedia, getOrganisationFreeTrialEnd } from '../../../store/selectors';

export const ProjectRecordsToolbarComponent = {
    template: require('!raw-loader!./project-records-toolbar.component.html').default,
    controllerAs: 'ctrl',
    bindings: {
        onImport: '&',
        onExport: '&',
        onUpdate: '&',
        onDelete: '&'
    },
    controller: class ProjectRecordsToolbarComponent {
        static $inject = ['$ngRedux', '$scope', '$timeout', 'PROJECT_RECORD_VIEWS', '$mdDialog', 'ProjectsService', '$rootScope', 'RecordsActions', 'ProjectService', 'ProjectActions'];
        constructor($ngRedux, $scope, $timeout, PROJECT_RECORD_VIEWS, $mdDialog, ProjectsService, $rootScope, RecordsActions, ProjectService, ProjectActions) {
            this.views = PROJECT_RECORD_VIEWS;
            this.$mdDialog = $mdDialog;
            this.ProjectService = ProjectService;
            this.ProjectsService = ProjectsService;
            this.$rootScope = $rootScope;
            this.onFreeTrial = false;
            this.isIncludingMedia = false;

            const state = $ngRedux.getState();
            this.surveys = getProjectForms(state).slice(0);
            this.surveys.sort((a, b) => a.sort - b.sort);

            this.states = getProjectStates(state);
            this.surveyMap = this.surveys.reduce((acc, survey) => {
                acc[survey.id] = survey.name;
                return acc;
            }, {});

            // If form no longer exists, change to the first available form
            const formId = getRecordsFormId(state);

            if (formId !== null && typeof this.surveys.find(s => s.id === formId) === 'undefined') {
                $ngRedux.dispatch(RecordsActions.updateForm(null))
            }

            $scope.$on('$destroy', $ngRedux.connect((state) => {
                const viewId = getRecordsView(state);
                const total = getRecordsTotal(state);
                const selectedCount = getRecordsSelectedCount(state);
                const view = PROJECT_RECORD_VIEWS.find(v => v.id === viewId);
                const formId = getRecordsFormId(state);
                const form = this.surveys.find(s => s.id === formId);
                const onFreeTrial = !!getOrganisationFreeTrialEnd(state);

                return {
                    view,
                    total,
                    selectedCount,
                    totalRecords: total,
                    role: getProjectRole(state),
                    filtering: getRecordsFilterDirty(state),
                    formId,
                    form,
                    formHasGeometry: getRecordsFormHasGeometry(state),
                    formHasMedia: getRecordsFormHasMedia(state),
                    projectHasGeometry: getProjectAttributesHasGeometry(state),
                    projectHasMedia: getProjectAttributesHasMedia(state),
                    selectedRecordsHasMedia: getRecordsSelectedHasMedia(state),
                    onFreeTrial
                };
            }, RecordsActions)(this));

            this.recordsCount = 0;

            this.exportFormats = [{
                label: 'CSV',
                key: 'csv',
                hasDialog: false
            }, {
                label: 'GeoJSON',
                key: 'geojson',
                hasDialog: false,
                disabled: !this.formHasGeometry,
            }, {
                label: 'Shapefile',
                key: 'shapefile',
                hasDialog: false,
                disabled: !this.formHasGeometry,
            }, {
                label: 'Images',
                key: 'images',
                hasDialog: false,
                disabled: !this.formHasMedia,
                selectedRecordsHasMedia: this.selectedRecordsHasMedia
            }];

            $scope.$on('$records:refresh', () => {
                // Update export formats disabled property when records reload
                this.exportFormats = this.exportFormats.map((format) => {
                    let disabled;
                    switch (format.key) {
                        case 'images':
                            disabled = !this.formHasMedia;
                            break;
                        case 'geojson':
                        case 'shapefile':
                            disabled = !this.formHasGeometry;
                            break;
                    }

                    return {
                        ...format,
                        disabled,
                    };
                });
            });

            this.exportDialogControllers = {
                logs: this.logsDialogController
            };

            this.exportDialogTemplates = {
                logs: require('!raw-loader!../project-records-export/dialog-templates/logs.tmpl.html').default
            };
        }

        openExportDataDialog($mdMenu, ev) {
            if (!this.onFreeTrial) {
                ev.preventDefault();
                ev.stopPropagation();
                $mdMenu.open();
            } else {
                this.openUpgradeToExportModal(ev);
            }
        }

        openUpgradeToExportModal(ev) {
            this.$mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                template: require('!raw-loader!./upgrade-to-export.modal.html').default,
                controllerAs: 'ctrl',
                controller: class UpgradeToExportController {
                    static $inject = ['$mdDialog'];
                    constructor($mdDialog) {
                        this.$mdDialog = $mdDialog;
                    }

                    close() {
                        this.$mdDialog.cancel();
                    }
                }
            }).then(angular.noop, angular.noop);
        }

        openMenu($mdMenu, ev) {
            ev.preventDefault();
            ev.stopPropagation();

            $mdMenu.open(ev);
        }

        changeView(event, view) {
            event.preventDefault();
            event.stopPropagation();
            this.updateView(view.id);
        }

        setForm(menu, id) {
            menu.close();
            this.updateForm(id);
        }

        openLogsExportDialog(ev) {
            this.$mdDialog.show({
                controller: class ExportRecordLogsController {
                    static $inject = ['$scope', '$mdDialog', 'surveys', 'projectId'];
                    constructor($scope, $mdDialog, surveys, projectId) {
                        $scope.surveys = angular.copy(surveys).map(s => Object.assign(s, { selected: false }));
                        const operations = ['update', 'delete', 'revert', 'restore']
                        $scope.operations = operations.map(o => ({ name: o, selected: true }));

                        $scope.answer = {
                            operations: null,
                            surveys: null,
                            projectId
                        }

                        $scope.isExportValid = function () {
                            if ($scope.surveys.filter(s => s.selected).length === 0 || $scope.surveys.filter(s => s.selected).length === 0) {
                                return false;
                            }
                            return true;
                        };

                        $scope.ok = function () {
                            $scope.answer.operations = $scope.operations.filter(o => o.selected).map(o => o.name);
                            $scope.answer.surveys = $scope.surveys.filter(s => s.selected).map(s => s.id);
                            $mdDialog.hide($scope.answer);
                        };

                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        };
                    }
                },
                template: require('!raw-loader!../project-records-export/dialog-templates/logs.tmpl.html').default,
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                resolve: {
                    surveys: () => {
                        return this.surveys
                    },
                    projectId: () => {
                        return this.ProjectService.getProjectId();
                    }
                }
            })
                .then((answer) => {
                    const { surveys, operations, projectId } = answer;
                    this.ProjectsService.recordLogsExport(projectId, surveys, operations);

                }, function () {
                    //cancelled
                });
        }

        logsDialogController($scope, $mdDialog, surveys, projectId) {
            $scope.surveys = angular.copy(surveys).map(s => Object.assign(s, { selected: false }));
            const operations = ['update', 'delete', 'revert', 'restore']
            $scope.operations = operations.map(o => ({ name: o, selected: true }));

            $scope.answer = {
                operations: null,
                surveys: null,
                projectId
            }

            $scope.isExportValid = function () {
                if ($scope.surveys.filter(s => s.selected).length === 0 || $scope.surveys.filter(s => s.selected).length === 0) {
                    return false;
                }
                return true;
            };


            $scope.ok = function () {
                $scope.answer.operations = $scope.operations.filter(o => o.selected).map(o => o.name);
                $scope.answer.surveys = $scope.surveys.filter(s => s.selected).map(s => s.id);
                $mdDialog.hide($scope.answer);
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };
        }
    }
}