import angular from 'angular';

import LegalTermsController from './terms/terms.controller';
import LegalPrivacyController from './privacy/privacy.controller';

export default angular
    .module('app.legal', ['ui.router', 'LocalStorageModule'])
    .controller('LegalTermsController', LegalTermsController)
    .controller('LegalPrivacyController', LegalPrivacyController)
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('legal', {
                abstract: true,
                template: require('!raw-loader!./legal.html').default,
                url: '/legal'
            })
            .state('legal.terms', {
                url: '/terms',
                template: require('!raw-loader!./terms/terms.html').default,
                controller: 'LegalTermsController as ctrl'
            })
            .state('legal.privacy', {
                url: '/privacy',
                template: require('!raw-loader!./privacy/privacy.html').default,
                controller: 'LegalPrivacyController as ctrl'
            });
    }]);
