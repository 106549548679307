import _ from 'lodash';

import './constants';
import main from './main/main.module';
import auth from './auth/auth.module';
import admin from './admin/admin.module';
import projects from './projects/projects.module';
import records from './records/records.module';
import jobs from './jobs/jobs.module';
import legal from './legal/legal.module';
import home from './home/home.module';
import organisations from './organisations/organisations.module';
import permissions from './permissions/permissions.module';
import intermediarypage from './intermediarypage/intermediarypage.module';

window._ = _;

angular.module('app', [
    'app.constants',
    main.name,
    auth.name,
    permissions.name,
    admin.name,
    home.name,
    organisations.name,
    projects.name,
    records.name,
    jobs.name,
    legal.name,
    intermediarypage.name,
    'ngApp'
]);

export default 'app';