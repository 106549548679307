import { urlToImageProxy } from "src/helpers/utils";

export const LOAD_PROJECT_TEMPLATES = 'LOAD_PROJECT_TEMPLATES';
export const LOAD_PROJECT_TEMPLATES_SUCCESS = 'LOAD_PROJECT_TEMPLATES_SUCESS';
export const LOAD_PROJECT_TEMPLATES_FAILURE = 'LOAD_PROJECT_TEMPLATES_FAILURE';

export const CREATE_PROJECT_TEMPLATE = 'CREATE_PROJECT_TEMPLATE';
export const CREATE_PROJECT_TEMPLATE_SUCCESS = 'CREATE_PROJECT_TEMPLATE_SUCCESS'
export const CREATE_PROJECT_TEMPLATE_FAILURE = 'CREATE_PROJECT_TEMPLATE_FAILURE';

function projectTemplatesActions(CoreoAPI) {

    const loadProjectTemplates = () => (dispatch) => {
        dispatch({
            type: LOAD_PROJECT_TEMPLATES
        });

        const query = `{projectTemplates{id,projectId,name,description,category,icon}}`;

        return CoreoAPI.query(query).then(result => {
            const projectTemplates = result && result.projectTemplates || [];
            for (const projectTemplate of projectTemplates) {
                if (projectTemplate.icon) {
                    projectTemplate.icon = urlToImageProxy(projectTemplate.icon, { w: 512 })
                } else {
                  projectTemplate.icon = '../assets/icons/no-icon.png';
                }
            }
            dispatch({
                type: LOAD_PROJECT_TEMPLATES_SUCCESS,
                projectTemplates
            })
            return projectTemplates;
        });
    }

    // const createProjectTemplate = projectTemplate => dispatch => {
    //     dispatch({
    //         type: CREATE_PROJECT_TEMPLATE,
    //         projectTemplate
    //     });

    //     return ProjectService.createProjectTemplate(projectTemplate).then(result => {
    //         dispatch({
    //             type: CREATE_PROJECT_TEMPLATE_SUCCESS,
    //             projectTemplate: result,
    //             toast: 'Project template created'
    //         });
    //         return result;
    //     }, err => dispatch({
    //         type: CREATE_PROJECT_TEMPLATE_FAILURE,
    //         err,
    //         toast: {
    //             type: 'error',
    //             message: 'Error creating project template'
    //         }
    //     }));
    // }

    return {
        loadProjectTemplates,
    };
}
projectTemplatesActions.$inject = ['CoreoAPI'];
export default projectTemplatesActions;