import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CollectionItemService, MediaItem } from './collection-item.service';
import { ConfirmationService } from 'primeng/api';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-collection-item-media-edit',
    templateUrl: 'collection-item-media-edit.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InputTextareaModule,
        ButtonModule,
        DatePipe
    ]
})

export class CollectionItemMediaEditComponent implements OnInit {

    dialogConfig = inject(DynamicDialogConfig);
    dialogRef = inject(DynamicDialogRef);
    confirmationService = inject(ConfirmationService);
    collectionItemService = inject(CollectionItemService);

    item = signal<MediaItem | null>(null);
    loading = signal(false);

    mediaType = computed(() => {
        const item = this.item();
        const type = item.type.split('/')[0];
        return type; // 'image' || 'audio'
    });

    src = computed(() => {
        const item = this.item();
        return item.url;
    });

    form = signal<FormGroup | null>(null);
    caption = signal<string | null>(null);
    captionCharacterCount = computed(() => {
        const caption = this.caption();
        return caption ? caption.length : 0;
    });

    constructor() { }

    ngOnInit() {
        this.item.set(this.dialogConfig.data['item']);
        this.form.set(new FormGroup({
            caption: new FormControl(this.item().caption)
        }));
        this.form().controls['caption'].valueChanges.subscribe(c => this.caption.set(c));
    }

    deleteMediaItemCheck($event) {
        this.confirmationService.confirm({
            defaultFocus: 'none',
            message: 'Are you sure you want to delete this media item?<br>This cannot be undone.',
            target: $event.target,
            header: 'Delete media item',
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Delete item',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
            accept: () => {
                this.deleteMediaItem();
            }
        });
    }

    private deleteMediaItem() {
        this.collectionItemService.deleteMediaItem(this.item().id);
        this.dialogRef.close();
    }

    save() {
        this.loading.set(true);

        const caption = this.form().controls['caption'].value ?? null;
        const update = {
            ...this.item(),
            caption
        }
        this.collectionItemService.updateMediaItem(update).subscribe(res => {
            if (!!res) {
                const item = res;
                // update media items
                this.collectionItemService.mediaItems.update(items => {
                    const index = items.findIndex(i => i.id === item.id);
                    items.splice(index, 1, item);
                    return items;
                });
                // close modal
                this.dialogRef.close();
            }
            this.loading.set(false);
        });
    }
}