
export const MapboxStyleEditorComponent = {
    template: require('!raw-loader!./mapbox-style-editor.component.html').default,
    controllerAs: 'ctrl',
    bindings: {
        layer: '<',
        type: '<',
        images: '<',
        onUpdate: '&'
    },
    controller: class MapboxStyleEditorComponent {
        static $inject = ['MAPBOX_STYLE_SPEC'];
        constructor(MAPBOX_STYLE_SPEC) {
            this.spec = MAPBOX_STYLE_SPEC;
            this.props = [];
            this.heatmapColors = [];
            this.selected = null;
        }

        loadDefaults(group, layerType) {
            var props = this.spec[layerType];

            return _.reduce(_.filter(props, { group: group }), function (acc, prop) {
                if (prop.hasOwnProperty('default')) {
                    acc[prop.key] = prop.default;
                }
                return acc;
            }, {});
        }


        initLayer() {
            var type = this.type;
            console.log('HERE?!', this.layer);
            var layoutDefaults = this.loadDefaults('layout', type);
            var paintDefaults = this.loadDefaults('paint', type);

            this.layer.layout = _.pick(_.assign({}, layoutDefaults, this.layer.layout || {}), _.keys(layoutDefaults))
            this.layer.paint = _.pick(_.assign({}, paintDefaults, this.layer.paint || {}), _.keys(paintDefaults));
            this.props = this.spec[type];

            if (this.props) {
                this.selected = this.props[0];
            }
            if (this.type === 'heatmap') {
                const heatmapPaintProp = this.layer.paint['heatmap-color'];
                this.heatmapColors = [
                    heatmapPaintProp[4],
                    heatmapPaintProp[6],
                    heatmapPaintProp[8],
                    heatmapPaintProp[10],
                    heatmapPaintProp[12],
                    heatmapPaintProp[14]
                ];
            }
        };

        $onChanges() {
            if (this.layer) {
                this.initLayer();
            }
        };

        selectProp(prop) {
            if (this.selected === prop) {
                return;
            }
            this.selected = prop;
        };

        changed(prop) {
            this.onUpdate();
        };

        propChanged(result) {
            this.layer[this.selected.group][this.selected.key] = result;
            this.onUpdate();
        };

        propReset() {
            var prop = _.find(this.spec[this.layer.type], { key: this.selected.key });
            this.layer[this.selected.group][this.selected.key] = prop.default;
            this.onUpdate();
        };

        heatmapChanged(prop) {
            console.log('HERE?!', prop, this.heatmapColors);
        }

    }
}