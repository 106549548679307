import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { UserService } from 'src/app/core/services/user.service';
import { ApiService } from "src/app/core";
import { UsersResponse } from '../../../core/models/user.model';

@Component({
  templateUrl: './transfer-organisation.component.html',
  selector: 'app-transfer-organisation',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ToastModule,
    ButtonModule,
    DialogModule,
    AutoCompleteModule
  ],
  providers: [MessageService]
})
export class TransferOrganisationComponent {

  @Input() isVisibleDialog: boolean = false;
  @Input() organisationId: number;

  @Output() visibleChange: EventEmitter<{ isVisibleDialog: boolean; isTransfered: boolean }> = new EventEmitter<{ isVisibleDialog: boolean; isTransfered: boolean }>();

  transferOrganisationForm = new FormGroup({
    owner: new FormControl<UsersResponse>(null, [Validators.required])
  });

  users: UsersResponse[] | [] = [];
  selectedUser: UsersResponse;

  private readonly transferOrganisationQuery: string = `mutation TransferOrganisationOwnership($organisationId: Int!, $userId: Int!) {
    result: transferOrganisationOwnership(input: {
      userId: $userId,
      organisationId: $organisationId
    }) { role }
  }`;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private messageService: MessageService
  ) { }

  ngOnInit() { }

  getUsers(event: {originalEvent: Event; query: string;}) {
    this.userService.searchUsers(event.query).subscribe(res => this.users = res.users);
  }

  onSubmit() {
    const { owner } = this.transferOrganisationForm.value;
    this.apiService.graphql(this.transferOrganisationQuery, { userId:owner.id, organisationId:this.organisationId })
    .subscribe((response: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Organisation ownership transferred' });
      this.onHideDialog(true);
    },
    (error) => {
      console.error(error);
      this.onHideDialog();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Organisation failed to transfer' });
    });
  }

  onHideDialog(isTransfered: boolean = false) {
    this.isVisibleDialog = false;
    this.visibleChange.emit({ isVisibleDialog: this.isVisibleDialog, isTransfered});
  }
}
