
export const ProjectFormsBlockDeletedComponent = {
    template: require('!raw-loader!./project-forms-block-deleted.component.html').default,
    bindings: {
        block: '<',
        attribute: '<',
        forms: '<'
    },
    controllerAs: 'ctrl',
    controller: class ProjectFormsBlockDeletedComponent {
        $onInit() {
            this.variant = this.block;
        }
    }
}