<div class="relative h-full w-full flex flex-col overflow-hidden">
    <div class="grow overflow-y-auto p-5">
        @if (hasGeometryQuestion() && geometryVisible()) {
            <div class="flex items-center justify-between mb-2">
                <label class="relative text-text-primary font-normal leading-none">
                    {{ geometryLabel() }}{{ geometryRequired() ? ' *' : null }}
                    @if (geometryHelp()) {
                        <i class="fa-solid fa-circle-question absolute -right-5 inset-y-0 text-text-secondary hover:cursor-pointer" [pTooltip]="geometryHelp()" tooltipPosition="top"></i>
                    }
                </label>
            </div>
            @if (!!geometryDescription()) {
                <div class="mb-2 leading-none">
                    <p class="text-xs text-text-secondary">{{ geometryDescription() }}</p>
                </div>
            }
            <div class="relative w-full aspect-[6/4] mb-5">
                <app-record-map #recordMap></app-record-map>
            </div>
            @if (geometryRequired() && !geometryValid()) {
                <div class="-mt-3 mb-5">
                    <p class="text-xs text-danger leading-none mt-2">Geometry required</p>
                </div>
            }
        }
        <app-record-form #recordForm [projectId]="projectId" [surveyId]="surveyId" [isEdit]="isEdit"></app-record-form>
    </div>
    <div class="flex-none flex items-center justify-end gap-4 p-4">
        <p-button size="large" label="Cancel" [outlined]="true" (click)="cancel()"></p-button>
        <p-button size="large" label="Save" (click)="saveCheck()"></p-button>
    </div>
    @if (showHasErrors()) {
        <div class="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-50">
            <div class="bg-white p-10">
                <p class="text-lg font-semibold text-center mb-2">Complete All Required Fields</p>
                <p class="text-base text-center mb-4">Please fill in all mandatory fields to save your data.<br>Look for red text below fields to identify missing information.</p>
                <div class="flex items-center justify-center gap-4">
                    <p-button size="large" (click)="showHasErrors.set(false)" label="Ok"></p-button>
                </div>
            </div>
        </div>
    }
</div>