export default class DateOnlyDirective {
    constructor() {
        this.restrict = "A";
        this.require = "ngModel";
    }

    link(scope, element, attributes, ngModel) {
        // Remove all the inbuild formatters and parsers
        // that will try to coerce to/from a date
        ngModel.$formatters = [];
        ngModel.$parsers = [];

        ngModel.$formatters.push(function (val) {
            if (val instanceof Date) {
                return val.toISOString().substring(0, 10);
            }
            return val.substring(0, 10);
        });

        ngModel.$parsers.push(function (val) {
            return val;
        });
    }
}
