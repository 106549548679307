import { DatePipe, NgFor, TitleCasePipe } from '@angular/common';
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { ApiService } from '../../../core/';
import { User } from '../../../core/models/user.model';
import { FilterMetadata } from 'primeng/api';
interface AdminUserResponse extends User {
    id: number;
    displayName: string;
    role: string;
    email: string;
    imageUrl: string;
}
@Component({
    selector: 'app-admin-users',
    templateUrl: './admin-users.component.html',
    imports: [
        NgFor,
        TitleCasePipe,
        TableModule,
        InputTextModule,
        ButtonModule,
        DatePipe,
        TitleCasePipe,
        DropdownModule,
        FormsModule,
        InputNumberModule,
        InputTextModule
    ],
    standalone: true,
    styles: [
        `:host{ @apply block h-full w-full overflow-hidden p-8;}`
    ]
})
export class AdminUsersComponent implements OnInit {

    @ViewChild('adminUsersTable') table: Table;
    @Input() offset: string = "0";
    @Input() limit: string = "10";
    @Input() search: string = '';
    @Input() order: string;
    @Input() where: { [key: string]: any };

    @Output() user: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();

    first: number;
    rows: number;
    filters = {};
    sortField: string;
    sortOrder: number;

    users: AdminUserResponse[] = [];
    totalUsers: number;
    loading: boolean = false;

    roles = [{ label: 'Admin', value: 'admin' }, { label: 'User', value: 'user' }];

    private readonly query: string = `query AAGetUsers($offset: Int!, $limit: Int!, $order: String!, $where: SequelizeJSON){
        users(offset: $offset, limit: $limit, order: $order, where: $where) {
            id,
            displayName,
            username,
            role,
            email,
            imageUrl,
            createdAt,
            lastActive,
            lastLoggedIn
        }
        count: usersCount(where: $where)
    }`;

    constructor(private api: ApiService) { }

    ngOnInit() {
        this.rows = parseInt(this.limit);
        this.first = parseInt(this.offset);

        if (this.search) {
            this.filters['global'] = { value: this.search, matcgMode: 'contains' };
        } else {
            this.search = this.search ?? '';
        }

        if (this.order) {
            this.sortField = this.order.replace('reverse:', '');
            this.sortOrder = this.order.startsWith('reverse:') ? -1 : 1;
        }

        if (this.where) {
            // const filters = JSON.parse(this.where);
            for (const k in this.where) {
                this.filters[k] = {
                    value: this.where[k],
                    matchMode: k === 'id' ? 'startsWith' : 'equals'
                };
            }
        }
    }

    globalSearch(event: any) {
        this.table.filterGlobal(event.target.value, 'contains');
    }

    loadUsers(event: TableLazyLoadEvent) {
        this.loading = true;
        const order = `${event.sortOrder === -1 ? 'reverse:' : ''}${event.sortField}`;
        let globalFilter = '';
        let where = { and: [] };

        if (event.filters?.['global']) {
            // where['$or'] = [{ displayName: { iLike: `%${event.filters['global']['value']}%` } }, { email: { iLike: `%${event.filters['global']['value']}%` } }];
            globalFilter = event.filters['global']['value'];
            where.and.push({
                or: [
                    { displayName: { iLike: `%${globalFilter}%` } },
                    { email: { iLike: `%${globalFilter}%` } },
                    { username: { iLike: `%${globalFilter}%` } }
                ]
            });
        }

        if (event.filters['id'] && event.filters['id']['value']) {
            where.and.push({ id: event.filters['id']['value'] });
        }

        if (event.filters['displayName'] && event.filters['displayName']['value']) {
            where.and.push({ displayName: { iLike: `%${event.filters['displayName']['value']}%` } });
        }

        if (event.filters['username'] && event.filters['username']['value']) {
            where.and.push({ username: { iLike: `%${event.filters['username']['value']}%` } });
        }

        if (event.filters['email'] && event.filters['email']['value']) {
            where.and.push({ email: { iLike: `%${event.filters['email']['value']}%` } });
        }

        if (event.filters['role'] && event.filters['role']['value']) {
            where.and.push({ role: event.filters['role']['value'] });
        }

        const input = {
            offset: event.first,
            limit: event.rows,
            order,
            where
        }

        const compactFilters = Object.keys(event.filters).reduce((acc, key) => {
            if ((event.filters[key] as FilterMetadata).value) {
                acc[key] = (event.filters[key] as FilterMetadata).value;
            }
            return acc;
        }, {});

        this.change.emit({
            offset: event.first,
            limit: event.rows,
            s: globalFilter,
            order,
            q: Object.keys(compactFilters).length > 0 ? JSON.stringify(compactFilters) : ''
        });

        this.api.graphql<{ users: AdminUserResponse[]; count: number }>(this.query, input).subscribe((res) => {
            this.users = res.users;
            this.totalUsers = res.count;
            this.loading = false;
        });
    }

    rowSelect(event: any) {
        this.user.emit(event);
    }
}