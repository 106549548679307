<div class="home-item-container">
    <div class="w-10 h-10 rounded-full relative overflow-hidden bg-[#DFEBFF] flex-shrink-0">
        @if(project().imageUrl){
        <img [ngSrc]="project().imageUrl" width="80" height="80" class="w-full h-full object-cover" alt="Project Image" />
        }
        @else{
        <svg-icon src="/assets/icons/coreo-logo-square.svg" class="w-full h-full flex items-center justify-center"></svg-icon>
        }
    </div>
    <div class="space-y-1 w-1/2">
        <span class="text-[#0069DF] text-sm font-semibold flex items-center gap-2.5">
            {{project().name}}
            @if(project().isFavourite){
            <i class="fas fa-star text-grey-50 fa-md mr-2.5"></i>
            }
        </span>
        @if(project().description){<p class="text-xs text-[#444] line-clamp-1 text-ellipsis">{{project().description}}
        </p>}
    </div>

    <div class="ml-auto flex items-center gap-4">
        <span class="text-[#444] text-sm ml-auto">{{project().updatedAt | date:'mediumDate'}}</span>

        <div class="home-item-buttons-container">
            <div class="home-item-buttons" [class.can-select]="selectionCount > 0">
                <p-menu #menu [model]="menuItems()" [popup]="true"></p-menu>

                <button (click)="handleEvent($event,favourite)"
                    [pTooltip]="project().isFavourite ? 'Remove from favourites' : 'Add to favourites'"
                    [tooltipOptions]="tooltipOptions">
                    <i class="{{project().isFavourite ? 'fas' : 'far'}} fa-star fa-regular text-grey-50 fa-lg"></i>
                </button>

                @if(isOrgAdmin()){
                <button (click)="handleEvent($event,move)" pTooltip="Move Project" [tooltipOptions]="tooltipOptions">
                    <svg-icon src="/assets/icons/move.svg"></svg-icon>
                </button>

                <button (click)="handleEvent($event,clone)" pTooltip="Clone Project" [tooltipOptions]="tooltipOptions">
                    <svg-icon src="/assets/icons/copy.svg"></svg-icon>
                </button>
                }

                <button (click)="openMenu($event, menu)">
                    <i class="fas fa-ellipsis-h text-2xl"></i>
                </button>

                <button (click)="selectProject($event,true)" class="home-item-select">
                    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="30" height="30" rx="3" fill="white" />
                        <rect x="1" y="1" width="30" height="30" rx="3" stroke-width="2"
                            [attr.stroke]="selected ? '#0069DF' : '#878F97'" />
                        @if(selected){
                        <path
                            d="M13.0602 22.5672L7.10185 16.6089C6.74388 16.2509 6.74388 15.6705 7.10185 15.3125L8.39818 14.0161C8.75615 13.6581 9.33658 13.6581 9.69455 14.0161L13.7084 18.0299L22.3055 9.43278C22.6635 9.07482 23.2439 9.07482 23.6019 9.43278L24.8982 10.7291C25.2562 11.0871 25.2562 11.6675 24.8982 12.0255L14.3566 22.5672C13.9986 22.9252 13.4182 22.9252 13.0602 22.5672Z"
                            fill="#0069DF" />
                        }
                    </svg>

                </button>
            </div>
        </div>
    </div>
</div>