import { Component, computed, effect, ElementRef, EventEmitter, inject, Input, model, Output, signal, viewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MultiPolygon } from "geojson";
import ngredux from 'ng-redux';
import { ButtonModule } from "primeng/button";
import { InputSwitchModule } from "primeng/inputswitch";
import { fromEvent, Subject, takeUntil } from "rxjs";
import { NG_REDUX } from "src/app/core/core.module";
import { baseStyles, MAP_MENU_LAYERS_CHANGED_EVENT, MAP_MENU_STYLE_CHANGED_EVENT, MapBaseStyle, MapBaseStyleId, MapProjectBoundsHandle, MapsService } from "src/app/core/services/maps.service";
import { getProjectGeometry, getProjectMapLayersState, getProjectOrgFeatures } from "src/store";
import { MapLayerState } from "src/store/records/records.reducer";
import { MapDataService, MapLayer } from "src/app/core/services/mapData.service";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmationService } from "primeng/api";

type ActiveMapLayer = MapLayerState & {
    impl: MapLayer
};

type MapBaseStyleState = MapBaseStyle & {
    locked: boolean;
}
@Component({
    selector: 'app-map-menu-control',
    templateUrl: './map-menu-control.component.html',
    styleUrls: ['./map-menu-control.component.scss'],
    standalone: true,
    imports: [
        ButtonModule,
        InputSwitchModule,
        FormsModule,
        ConfirmDialogModule
    ],
    providers: [
        ConfirmationService
    ],
    host: {
        'class': 'mapboxgl-ctrl relative'
    }
})
export class MapMenuControlComponent {

    mapsService = inject(MapsService);
    mapData = inject(MapDataService);
    confirmationService = inject(ConfirmationService);

    @Input() map: mapboxgl.Map;
    @Input() showProjectBoundsToggle: boolean = true;
    @Input() showLayers: boolean = true;

    @Output() layersChange: EventEmitter<MapLayerState[]> = new EventEmitter();

    private redux: ngredux.INgRedux = inject<ngredux.INgRedux>(NG_REDUX);

    visible = signal(false);
    activeBaseStyleId = signal<MapBaseStyleId>('streets');
    projectBounds = signal<MultiPolygon | null>(null);
    showProjectBounds = model<boolean>(false);
    boundsHandle: MapProjectBoundsHandle;

    controlContainer = viewChild<ElementRef>('controlContainer');
    overlayPanel = viewChild<ElementRef>('op');

    destroy$ = new Subject<void>();

    // baseStyles = signal<MapBaseStyle[]>([...baseStyles]);
    layers = signal<MapLayerState[]>([]);
    bingAvailable = signal(false);

    baseStyles = computed<MapBaseStyleState[]>(() => {
        const bingAvailable = this.bingAvailable();
        return [...baseStyles].map(s => ({
            ...s,
            locked: s.id === 'bing' && !bingAvailable
        }));
    });

    activeLayers: ActiveMapLayer[] = [];

    constructor() {
        effect(() => {
            if (this.showProjectBounds() && this.projectBounds()) {
                this.boundsHandle = this.mapsService.addProjectBoundaryLayer(this.map, this.projectBounds())
            } else if (this.boundsHandle) {
                this.boundsHandle.remove();
                this.boundsHandle = null;
            }
        });

        effect(async () => {
            const layers = this.layers();
            await this.renderLayers(layers);
            this.layersChange.emit(layers);
            this.map.fire(MAP_MENU_LAYERS_CHANGED_EVENT, { layers });
        });
    }

    async renderLayers(layers: MapLayerState[]) {
        for (const layer of layers) {
            const existing = this.activeLayers.find(r => r.id === layer.id && r.layerType === layer.layerType);
            if (layer.visible) {
                if (typeof existing !== 'undefined') {
                    existing.impl.show();
                } else {
                    const impl = this.mapData.fromState(layer);
                    if (impl) {
                        this.activeLayers.push({
                            ...layer,
                            impl
                        });
                        await impl.addTo(this.map);
                    }
                }
            } else {
                if (typeof existing !== 'undefined') {
                    existing.impl.hide();
                }
            }
        }
    }

    ngOnInit() {
        const state = this.redux.getState();
        const features = getProjectOrgFeatures(state);
        const bounds = getProjectGeometry(state);
        const layers = getProjectMapLayersState(state);
        this.layers.set(layers);
        this.bingAvailable.set(features.bingMaps);
        this.projectBounds.set(bounds ?? null);

        fromEvent(this.map, 'click').pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.visible.set(false);
        });

        fromEvent(this.map, 'style.load').pipe(takeUntil(this.destroy$)).subscribe(async () => {
            this.getActiveStyle();
            await this.renderLayers(this.layers());
            this.map.fire(MAP_MENU_STYLE_CHANGED_EVENT);
        });

        if (this.map.isStyleLoaded()) {
            this.getActiveStyle();
        } else {
            this.map.once('style.load', () => {
                this.getActiveStyle();
            });
        }
    }

    getControlElement() {
        return this.controlContainer().nativeElement;
    }

    toggle(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.visible.update(v => !v);
    }

    getActiveStyle() {
        const style = this.map.getStyle();
        const baseStyle = baseStyles.find(s => s.mapboxName === style.name);
        this.activeBaseStyleId.set(baseStyle!.id);
    }

    async setBaseStyle(style: MapBaseStyle) {
        if (this.activeBaseStyleId() === style.id) {
            return;
        }
        else if (style.id === 'bing') {
            if (!this.bingAvailable()) {
                return this.showBingModal();
            }
            const bingStyle = await this.mapsService.loadBingMapStyle();
            this.map.setStyle(bingStyle);
        } else {
            this.map.setStyle(style.url);
        }
        this.activeLayers = [];
    }

    showBingModal() {
        this.confirmationService.confirm({
            header: 'Bing maps is locked',
            message: 'To use the high resolution Bing maps you will need to upgrade your Coreo plan.',
            rejectVisible: false,
            acceptLabel: 'OK'
        });
    }

    toggleLayerVisible(event: Event, layer: MapLayerState) {
        event.preventDefault();
        event.stopPropagation();

        const idx = this.layers().findIndex(l => l.id === layer.id);
        if (idx === -1) {
            return;
        }
        this.layers.set([
            ...this.layers().slice(0, idx),
            { ...layer, visible: !layer.visible },
            ...this.layers().slice(idx + 1)
        ]);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}