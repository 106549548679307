import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class JwtService {
    readonly tokenKey = 'ls.auth_token';

    getToken() {
        const token = window.localStorage[this.tokenKey];
        return token && JSON.parse(token);
    }

    saveToken(token: string) {
        window.localStorage[this.tokenKey] = token;
    }

    destroyToken() {
        window.localStorage.removeItem(this.tokenKey);
    }
}
