import { Component, computed, inject, input, signal } from '@angular/core';
import { RecordMessage } from './record-messages.component';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { ApiService } from 'src/app/core';
import { catchError, of, take } from 'rxjs';

@Component({
    selector: 'app-record-message',
    templateUrl: 'record-message.component.html',
    standalone: true,
    imports: [
        CommonModule,
        AvatarModule
    ]
})

export class RecordMessageComponent {

    private apiService = inject(ApiService);

    message = input.required<RecordMessage>();

    markedAsRead = signal(false);

    isRead = computed<boolean>(() => {
        const message = this.message();
        const marked = this.markedAsRead();

        return message.read || marked;
    });

    markAsRead() {
        if (this.isRead()) {
            return;
        }
        
        this.apiService.post('/messages/mark/' + this.message().id).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null)
            })
        ).subscribe(res => {
            if (!!res) {
                this.markedAsRead.set(true);
            }
        });
    }
}