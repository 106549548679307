<button (click)="toggle($event)">
    <i class="fas fa-layer-group"></i>
</button>

<div [class.visible]="visible()" class="container">
    <div class="container-inner">
        <div class="flex">
            @for(baseStyle of baseStyles(); track baseStyle.id){
            <div class="base-style" [class.active]="activeBaseStyleId() === baseStyle.id"
                (click)="setBaseStyle(baseStyle)" [class.locked]="baseStyle.locked">
                <button [style.backgroundImage]="baseStyle.preview"></button>
                <label>{{baseStyle.name}}</label>
                <i class="fas fa-lock"></i>
            </div>
            }
        </div>

        <div class="px-4">
            <!-- Layers -->
            @if(showLayers && layers().length > 0){
            <ol class="list-none my-2">
                @for(layer of layers(); track layer.id){
                <li class="custom-layer flex gap-2 items-center">
                    <span>{{layer.name}}</span>
                    <i class="fas ml-auto" [class.fa-eye]="layer.visible" [class.fa-eye-slash]="!layer.visible"
                        (click)="toggleLayerVisible($event, layer)"></i>
                </li>
                }
            </ol>
            }

            @if(projectBounds() && showProjectBoundsToggle){
            <div class="flex items-center justify-between my-2">
                <label for="show-bounds">Show Project Bounds</label>
                <p-inputSwitch [(ngModel)]="showProjectBounds" />
            </div>
            }
        </div>
    </div>

    <span class="arrow"></span>
</div>


<p-confirmDialog appendTo="body">
    <ng-template pTemplate="message" let-message>
        <div class="flex flex-col items-center gap-8 max-w-80 text-center mx-auto">
            <i class="fas fa-lock text-6xl text-primary"></i>
            <p>{{ message.message }}</p>
        </div>
    </ng-template>
</p-confirmDialog>