export const MinScreenSizeBlockComponent = {
    bindings: {
        isExpired: '=',
        freeTrialEnd: '='
    },
    template: require('!raw-loader!./min-screen-size-block.component.html').default,
    controllerAs: 'ctrl',
    controller: class MinScreenSizeBlockController {
        static $inject = ['IntermediaryPageService'];
        constructor(IntermediaryPageService) {
            this.hostUrl = IntermediaryPageService.getHostUrl();
            this.displayHostUrl = IntermediaryPageService.getDisplayHostUrl();
        }
    }
}