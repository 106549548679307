import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal, ViewChild } from '@angular/core';
import { ConfigGeometryTypes, RecordMapComponent } from './record-map.component';
import { ApiService } from 'src/app/core';
import { ConfirmationService } from 'primeng/api';
import { RecordsService } from '../../records.service';
import { Subscription, catchError, of, take } from 'rxjs';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { Geometry } from 'geojson';

interface GeometryQuestionData {
    description: string;
    help: string;
    required: boolean;
    visible: boolean;
    config: any;
}

@Component({
    selector: 'app-record-geometry',
    templateUrl: 'record-geometry.component.html',
    standalone: true,
    imports: [
        PanelModule,
        ButtonModule,
        RecordMapComponent,
        TooltipModule
    ]
})

export class RecordGeometryComponent implements OnInit, OnDestroy {

    @Input() recordId!: number;
    @Input() canEdit: boolean = false;

    @Output() geometryUpdated: EventEmitter<void> = new EventEmitter();

    @ViewChild('recordMap', { static: false }) recordMap: RecordMapComponent;

    public questionData: GeometryQuestionData;
    public geometryTypes: ConfigGeometryTypes[] = [];
    public enforceBounds: boolean = false;
    public bounds: Geometry = null;
    public editGeometry: boolean = false;

    private dataSub: Subscription;
    private boundsSub: Subscription;

    submitting = signal(false);

    constructor(
        private apiService: ApiService,
        private confirmationService: ConfirmationService,
        private recordsService: RecordsService,
    ) { }

    ngOnInit() {}

    ngOnDestroy(): void {
        if (this.dataSub) {
            this.dataSub.unsubscribe();
        }
        if (this.boundsSub) {
            this.boundsSub.unsubscribe();
        }
    }

    private getQuestionData() {
        const query = `query getGeometryQuestionData($id: Int!){
            record(id: $id){
                projectId
                survey{
                    attributes(where: { questionType: "geometry" }){
                        description
                        help
                        required
                        visible
                        config
                    }
                }
            }
        }`;

        this.dataSub = this.apiService.graphql<{ record: { projectId: number, survey: { attributes: GeometryQuestionData[] } } }>(query, { id: this.recordId }).pipe(
            take(1)
        ).subscribe(res => {
            const data = res.record.survey.attributes[0];
            this.questionData = data;
            this.geometryTypes = data.config?.types ?? ['point'];
            if (data.config?.enforceBounds) {
                this.enforceBounds = data.config.enforceBounds;
                this.getProjectBounds(res.record.projectId);
            } else {
                this.startEdit();
            }
        });
    }

    private getProjectBounds(projectId: number) {
        const query = `query getProjectBounds{
            project(id: ${projectId}){
                geometry{
                    type
                    coordinates
                }
            }
        }`;

        this.boundsSub = this.apiService.graphql<{ project: { geometry: Geometry } }>(query).pipe(
            take(1),
        ).subscribe(res => {
            console.log(res);
            this.bounds = res.project.geometry;
            this.startEdit();
        });
    }

    public startEditGeometry() {
        this.getQuestionData();
    }
    
    private startEdit() {
        this.editGeometry = true;
        this.recordMap.startEdit(this.geometryTypes, this.enforceBounds, this.bounds);
    }

    public cancelEditGeometry() {
        this.editGeometry = false;
        this.recordMap.cancelEdit();
    }

    public finishEditGeometry($event) {
        /** Check if required and no geometry */
        if (this.questionData.required && this.recordMap.touched && !this.recordMap.updatedGeometry) {
            this.confirmationService.confirm({
                target: $event.target as EventTarget,
                message: 'Please add geometry and save',
                header: 'This form requires geometry',
                acceptIcon: 'none',
                acceptLabel: 'Ok',
                accept: () => {
                },
                rejectVisible: false
            });             
        } else {
            this.updateGeometry();
        }
    }
    
    private updateGeometry() {
        this.submitting.set(true);
        const geometry = this.recordMap.updatedGeometry;
        this.recordsService.updateRecordGeometry(this.recordId, geometry).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {    
            if (!!res) {
                this.submitting.set(false);
                this.editGeometry = false;
                this.recordMap.getGeometry(true);
                this.geometryUpdated.emit();
            }
        });       

    }
}