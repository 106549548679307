import mapboxgl, { GeoJSONSource, VectorSourceImpl } from "mapbox-gl";
import { Feature } from "geojson";
import { difference, polygon } from '@turf/turf';
import { feature as featureHelper, MultiPolygon, Polygon } from '@turf/helpers';
import { safelyAddLayer, safelyAddSource, safelyMoveLayer, safelyRemoveLayer, safelyRemoveSource } from "src/app/core/services/maps.service";

export const RECORDS_SOURCE = 'records';

export enum Layer {
    CLUSTER = 'cluster',
    CLUSTER_COUNT = 'cluster-count',
    POLYGON = 'polygon',
    POLYGON_OUTLINE = 'polygon-outline',
    LINE = 'line',
    POINT = 'point'
}

const addLayers = (map: mapboxgl.Map, vector: boolean = false) => {

    const pointLayer: mapboxgl.CircleLayer = {
        id: Layer.POINT,
        source: RECORDS_SOURCE,
        type: 'circle',
        filter: [
            'all',
            ['!has', 'pc'],
            ['==', '$type', 'Point']
        ],
        paint: {
            'circle-color': '#C521E0',
            'circle-radius': 10,
            'circle-opacity': 0.5,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#FFFFFF'
        }
    };

    const lineLayer: mapboxgl.LineLayer = {
        id: Layer.LINE,
        source: RECORDS_SOURCE,
        type: 'line',
        paint: {
            'line-color': '#F02F1D',
            'line-width': 3
        },
        'filter': ['==', '$type', 'LineString']
    };

    const polygonOutlineLayer: mapboxgl.LineLayer = {
        id: Layer.POLYGON_OUTLINE,
        source: RECORDS_SOURCE,
        type: 'line',
        paint: {
            'line-color': '#F02F1D',
            'line-width': 3
        },
        'filter': ['==', '$type', 'Polygon']
    };

    const polygonFillLayer: mapboxgl.FillLayer = {
        id: Layer.POLYGON,
        source: RECORDS_SOURCE,
        type: 'fill',
        paint: {
            'fill-color': '#F02F1D',
            'fill-opacity': 0.2
        },
        'filter': ['==', '$type', 'Polygon']
    };

    const clusterCountLayer: mapboxgl.SymbolLayer = {
        id: Layer.CLUSTER_COUNT,
        source: RECORDS_SOURCE,
        type: 'symbol',
        filter: ["has", "pc"],
        layout: {
            "text-field": "{pca}",
            'text-size': 14,
        },
        paint: {
            'text-color': "#000000",
            'text-opacity': 1
        }
    };

    const clusterCircleLayer: mapboxgl.CircleLayer = {
        id: Layer.CLUSTER,
        source: RECORDS_SOURCE,
        type: 'circle',
        filter: ["has", "pc"],
        paint: {
            "circle-color": "#FFFFFF",
            'circle-radius': [
                'interpolate', ['linear'], ['get', 'pc'],
                10, 10,
                100, 15,
                1000, 20,
                10000, 24,
                50000, 35
            ],
            'circle-stroke-width': 1,
            'circle-stroke-color': "#000000",
        },
    };

    if (vector) {
        clusterCircleLayer['source-layer'] = RECORDS_SOURCE;
        clusterCountLayer['source-layer'] = RECORDS_SOURCE;
        polygonFillLayer['source-layer'] = RECORDS_SOURCE;
        polygonOutlineLayer['source-layer'] = RECORDS_SOURCE;
        lineLayer['source-layer'] = RECORDS_SOURCE;
        pointLayer['source-layer'] = RECORDS_SOURCE;
    }

    safelyAddLayer(map, clusterCircleLayer);
    safelyAddLayer(map, clusterCountLayer);
    safelyAddLayer(map, polygonFillLayer);
    safelyAddLayer(map, polygonOutlineLayer);
    safelyAddLayer(map, lineLayer);
    safelyAddLayer(map, pointLayer);
}

export const addTileLayers = (map: mapboxgl.Map, url: string) => {
    const source = map.getSource(RECORDS_SOURCE) as VectorSourceImpl;
    if (typeof source !== 'undefined') {
        return source.setTiles([url]);
    }

    safelyAddSource(map, RECORDS_SOURCE, {
        type: "vector",
        tiles: [url],
        promoteId: "id",
    });

    addLayers(map, true);
}

// TODO: pass in id rather than use RECORDS_SOURCE?
export const addFeatureLayers = (map: mapboxgl.Map, features: Feature[]) => {
    safelyAddSource(map, RECORDS_SOURCE, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features
        }
    });

    addLayers(map);
}

export const updateFeatures = (map: mapboxgl.Map, features: Feature[]) => {
    (map.getSource(RECORDS_SOURCE) as GeoJSONSource).setData({
        type: 'FeatureCollection',
        features
    });
}

export const removeRecordsLayers = (map: mapboxgl.Map) => {
    safelyRemoveLayer(map, Layer.CLUSTER);
    safelyRemoveLayer(map, Layer.CLUSTER_COUNT);
    safelyRemoveLayer(map, Layer.POLYGON);
    safelyRemoveLayer(map, Layer.POLYGON_OUTLINE);
    safelyRemoveLayer(map, Layer.LINE);
    safelyRemoveLayer(map, Layer.POINT);
    safelyRemoveSource(map, RECORDS_SOURCE);
}

export const moveRecordsLayer = (map: mapboxgl.Map) => {
    safelyMoveLayer(map, Layer.CLUSTER);
    safelyMoveLayer(map, Layer.CLUSTER_COUNT);
    safelyMoveLayer(map, Layer.POLYGON);
    safelyMoveLayer(map, Layer.POLYGON_OUTLINE);
    safelyMoveLayer(map, Layer.LINE);
    safelyMoveLayer(map, Layer.POINT);
}

// Bounds layer
const globalLayer = polygon([[
    [-180, -90],
    [-180, 90],
    [180, 90],
    [180, -90],
    [-180, -90]
]]);

const getGlobalMaskLayer = (bounds: MultiPolygon | Polygon) => {
    const appLayer = featureHelper(bounds);
    return difference(globalLayer, appLayer);
}

export const addBoundsLayer = (map: mapboxgl.Map, bounds: MultiPolygon | Polygon) => {
    const data = getGlobalMaskLayer(bounds);
    const color = '#000';

    safelyAddSource(map, 'boundsFillSource', {
        type: 'geojson',
        data
    });

    safelyAddSource(map, 'boundsLineSource', {
        type: 'geojson',
        data: {
            type: 'Feature',
            properties: {},
            geometry: bounds
        }
    });

    safelyAddLayer(map, {
        id: 'boundsFill',
        type: 'fill',
        source: 'boundsFillSource',
        paint: {
            'fill-opacity': 0.3,
            'fill-color': color
        }
    });

    safelyAddLayer(map, {
        id: 'boundsLine',
        type: 'line',
        source: 'boundsLineSource',
        paint: {
            'line-color': color
        }
    });
}

export const removeBoundsLayer = (map: mapboxgl.Map) => {
    safelyRemoveLayer(map, 'boundsFill');
    safelyRemoveLayer(map, 'boundsLine');
    safelyRemoveSource(map, 'boundsFillSource');
    safelyRemoveSource(map, 'boundsLineSource');
}