import angular from 'angular';

// Services
import Auth from './auth.service';
// Controllers
import LoginController from './login/login.controller';
import AccountVerificationController from './accountverification/accountverification.controller';
import ProfileController from './profile/profile.controller';
import PasswordResetController from './passwordreset/passwordreset.controller';
import PasswordUpdateController from './passwordupdate/passwordupdate.controller';
import SignupController from './signup/signup.controller';
import OAuthController from './oauth/oauth.controller';
import OAuthTransferController from './oauth-transfer/oauth-transfer.controller';
import * as _ from 'lodash';

import { getAuthIsLoggedIn, getAuthToken } from '../store/selectors';

export default angular
    .module('app.auth', ['ui.router', 'LocalStorageModule'])
    .service('Auth', Auth)
    .controller('LoginController', LoginController)
    .controller('AccountVerificationController', AccountVerificationController)
    .controller('ProfileController', ProfileController)
    .controller('PasswordResetController', PasswordResetController)
    .controller('PasswordUpdateController', PasswordUpdateController)
    .controller('SignupController', SignupController)
    .controller('OAuthController', OAuthController)
    .controller('OAuthTransferController', OAuthTransferController)
    .config(['$stateProvider', '$httpProvider', function ($stateProvider, $httpProvider) {
        $stateProvider
            .state('login', {
                url: '/login',
                parent: 'unauthenticated',
                template: require('!raw-loader!./login/login.html').default,
                controller: 'LoginController as ctrl',
                params: {
                    redirect: null
                }
            })
            .state('signup', {
                url: '/signup',
                parent: 'unauthenticated',
                template: require('!raw-loader!./signup/signup.html').default,
                controller: 'SignupController as ctrl'
            })
            .state('oauth', {
                url: '/oauth',
                parent: 'unauthenticated',
                template: require('!raw-loader!./oauth/oauth.html').default,
                controller: 'OAuthController as ctrl',
                params: {
                    oauth_transfer_redirect: null
                },
            })
            .state('oauthdenied', {
                url: '/oauthdenied',
                parent: 'unauthenticated',
                template: require('!raw-loader!./oauth/oauthdenied.html').default,
                controller: 'OAuthController as ctrl'
            })
            .state('passwordreset', {
                url: '/passwordreset?project_id',
                parent: 'unauthenticated',
                template: require('!raw-loader!./passwordreset/passwordreset.html').default,
                controller: 'PasswordResetController as ctrl'
            })
            .state('passwordupdate', {
                url: '/passwordupdate?project_id',
                parent: 'unauthenticated',
                template: require('!raw-loader!./passwordupdate/passwordupdate.html').default,
                controller: 'PasswordUpdateController as ctrl',
                resolve: {
                    project: ['$stateParams', 'ProjectsService', function ($stateParams, ProjectsService) {
                        return typeof $stateParams.project_id === 'undefined' ? Promise.resolve() : ProjectsService.getById($stateParams.project_id);
                    }]
                },
                onEnter: ['$ngRedux', 'AuthActions', function ($ngRedux, AuthActions) {
                    AuthActions.logout($ngRedux.dispatch);
                }]
            })
            .state('accountverification', {
                url: '/accountverification?project_id',
                parent: 'unauthenticated',
                template: require('!raw-loader!./accountverification/accountverification.html').default,
                controller: 'AccountVerificationController as ctrl',
                resolve: {
                    project: ['$stateParams', 'ProjectsService', function ($stateParams, ProjectsService) {
                        return typeof $stateParams.project_id === 'undefined' ? Promise.resolve() : ProjectsService.getById($stateParams.project_id);
                    }]
                }
            })
            .state('loginCb', {
                url: '/login_cb',
                onEnter: ['$state', '$ngRedux', 'AuthActions', function ($state, $ngRedux, AuthActions) {
                    AuthActions.init($ngRedux.dispatch);
                    const isLoggedIn = getAuthIsLoggedIn($ngRedux.getState());
                    $state.transitionTo(isLoggedIn ? 'home' : 'login');
                }]
            })
            .state('profile', {
                url: '/profile',
                parent: 'app',
                // template: require('!raw-loader!./profile/profile.html').default,
                // controller: 'ProfileController as ctrl',
                // controllerAs: 'ctrl',

                // Angular 16 Version
                template: '<app-profile></app-profile>',
                // controllerAs: 'ctrl',
                // controller: class ProfileController {
                //     static $inject = ['profile'];
                //     constructor(public profile) {
                //     }
                // },
                // resolve: {
                //     profile: ['Auth', function (Auth) {
                //         return Auth.getUser();
                //     }]
                // }
            })
            .state('oauthTransfer', {
                url: '/oauth-transfer',
                parent: 'app',
                template: require('!raw-loader!./oauth-transfer/oauth-transfer.html').default,
                controller: 'OAuthTransferController as ctrl'
            });

        $httpProvider.interceptors.push(['$q', '$ngRedux', 'apiHostname', 'apiProtocol', 'apiPort', 'localStorageService', function ($q, $ngRedux, apiHostname, apiProtocol, apiPort, localStorageService) {

            const authState: any = {};
            $ngRedux.connect((state) => ({
                token: getAuthToken(state)
            }), null)(authState);

            return {
                request: function (config) {
                    if (_.startsWith(config.url, 'https://coreo.s3') || _.startsWith(config.url, 'https://s3')) {
                        return config;
                    }

                    if (!_.startsWith(config.url, 'http') && !_.endsWith(config.url, 'html')) {
                        config.url = [apiProtocol, '://', apiHostname, (apiPort === 80 || apiPort === 443) ? '' : ':' + apiPort, _.startsWith(config.url, '/') ? '' : '/', config.url].join('');
                    }

                    const localToken = localStorageService.get('auth_token');
                    if (config.token_override || authState.token || localToken) {
                        config.headers.Authorization = 'JWT ' + (config.token_override || authState.token || localToken);
                    }
                    return config;
                },
                responseError: function (rejection) {
                    if (rejection.status !== 401) {
                        return $q.reject(rejection);
                    }

                    var allowed401Urls = ['/auth/login', '/auth/forgot/reset', '/auth/signup'];
                    for (var i = 0; i < allowed401Urls.length; i++) {
                        if (rejection.config.url.indexOf(allowed401Urls[i]) !== -1) {
                            return $q.reject(rejection);
                        }
                    }
                    // $injector.get('$state').go('login');
                }
            };
        }]);
    }])
    .run(['$ngRedux', '$transitions', 'AuthActions', function ($ngRedux, $transitions, AuthActions) {

        const authState: any = {};
        $ngRedux.connect((state) => ({
            isLoggedIn: getAuthIsLoggedIn(state)
        }), AuthActions)(authState);

        // List of states that unauthorized users are able to access
        const unauthorizedStates = [
            'login',
            'oauth',
            'oauthdenied',
            'organisation-invitation',
            'signup',
            'passwordreset',
            'passwordupdate',
            'accountverification',
            'legal.privacy',
            'legal.terms',
            'intermediarypage.verify',
            'intermediarypage.authenticate',
            'intermediarypage.added-to-project',
            'intermediarypage.added-to-organisation',
            'intermediarypage.reset-password',
            'intermediarypage.join-project'
        ];

        // List of states that if acccessed when logged in, should redirect to home
        const authRedirectStates = [
            'login',
            'oauth',
            'oauthdenied',
            'signup'
        ];

        const redirectLogin = (trans, redirectState = null) => {
            const redirectParams = redirectState ? {
                redirect: {
                    state: redirectState,
                    params: {}
                }
            } : null;
            return trans.router.stateService.target('login', redirectParams, {
                notify: true,
                inherit: false,
                reload: true
            });
        }
        const redirectHome = (trans) => trans.router.stateService.target('home', null, {
            notify: true,
            inherit: false,
            reload: true
        });

        const redirectOAuth = (trans, oauth_transfer_redirect = undefined) => {
            return trans.router.stateService.target('oauth', {
                oauth_transfer_redirect: oauth_transfer_redirect
            }, {
                notify: true,
                inherit: false,
                reload: true
            });
        }


        const authInitTrans = $transitions.onBefore({}, function () {
            return AuthActions.init($ngRedux.dispatch).then(() => {
                authInitTrans();
                return true;
            });
        });

        $transitions.onBefore({}, function (trans) {
            const toState = trans.to();
            // If we are not logged in, and we aren't going to one of the states listed above, then redirect to home
            if (!authState.isLoggedIn && unauthorizedStates.indexOf(toState.name) === -1) {
                // Redirect to the OAuth transfer page if requested pre login
                if (toState.name === 'oauthTransfer') {
                    return redirectOAuth(trans, true);
                }
                return redirectLogin(trans);
            } else if (authState.isLoggedIn && authRedirectStates.indexOf(toState.name) !== -1) {
                return redirectHome(trans);
            }
            return true;
        });
    }]);
