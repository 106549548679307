
const initialState = {
    loading: false,
    error: null,
    projects: null,
};

const projectsReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'LOAD_PROJECTS': {
            return {
                ...initialState,
                loading: true
            };
        }

        case 'LOAD_PROJECTS_FAILURE': {
            const { error } = action;
            return {
                ...initialState,
                error
            };
        }

        case 'LOAD_PROJECTS_SUCCESS': {
            const { projects } = action;
            return {
                ...initialState,
                projects
            };
        }

        case 'CREATE_PROJECT_SUCCESS': {
            const { project } = action;
            if (!state.projects) {
                return state;
            }

            return {
                ...state,
                projects: [
                    ...state.projects,
                    project
                ]
            };
        }

        case 'DELETE_PROJECT_SUCCESS': {
            const { projectId } = action;
            const idx = state.projects.findIndex(p => p.id === projectId);
            return {
                ...state,
                projects: [
                    ...state.projects.slice(0, idx),
                    ...state.projects.slice(idx + 1)
                ]
            };
        }

        case 'LOGOUT': {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export default projectsReducer;