
import { createPopper } from '@popperjs/core';
import { USER_PREFERENCE_VERSIONS, USER_PREFERENCES } from '../../helpers/user-preferences';

export const AppTutorialComponent = {
    bindings: {
        active: '<',
        completeText: '@',
        onBeforeChange: '&',
        onAfterChange: '&',
        tutorialKey: '<',
    },
    template: require('!raw-loader!./tutorial.component.html').default,
    transclude: true,
    controllerAs: 'ctrl',
    controller: class AppTutorialComponent {
        static $inject = ['$element', '$timeout', '$scope', '$window', '$ngRedux', 'AuthActions'];
        constructor($element, $timeout, $scope, $window, $ngRedux, AuthActions) {
            this.$timeout = $timeout;
            this.$element = $element;
            this.$scope = $scope;
            this.$ngRedux = $ngRedux;
            this.AuthActions = AuthActions;

            this.initialised = false;
            this.coachmarks = [];
            this.index = 0;
            this.currentTarget = null;
            this.popper = null;
            this.showSkip = false;

            angular.element($window).bind('resize', () => {
                if (this.currentTarget && this.currentTarget[0]) {
                    this.updateHighlightPosition();
                    $scope.$digest();
                }
            });
        }

        addCoachmark(coachmark) {
            this.coachmarks.push(coachmark);
        }

        $onInit() {
            this.completeText = this.completeText || 'Done';

            if (this.active) {
                this.$timeout(() => this.initialise(), 200);
            }

            this.$scope.$on('$tutorial:restart', (_, args) => {
                if (!this.initialised) {
                    this.initialise();
                }

                this.showSkip = args && args.fromGuide;

                this.index = 0;
                this.update();
                this.$element.addClass('active');
                this.highlighter.show();
            });
        }

        initialise() {

            this.coachmarkContainer = this.$element.find('.tutorial--coachmark');

            this.highlighter = angular.element('<div class="tutorial--highlighter"></div>');
            const body = angular.element(document.body);
            body.append(this.highlighter);

            this.$element.detach();
            body.append(this.$element);

            this.popper = createPopper(this.highlighter[0], this.coachmarkContainer[0], {
                placement: 'auto',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [12, 12]
                        }
                    },
                    {
                        name: 'computeStyles',
                        options: {
                            adaptive: false,
                        },
                    },
                ]
            });
            // this.update();
            this.$element.addClass('active');
            this.initialised = true;
        }

        next() {
            this.index++;
            if (this.index < this.coachmarks.length) {
                this.update();
            } else {
                this.end();
            }
        }

        end() {
            if (this.currentTarget) {
                this.currentTarget.removeClass('tutorial--coachmark-focus');
            }
            // Deactivate all our coachmarks
            for (const c of this.coachmarks) {
                c.deactivate();
            }
            this.$element.removeClass('active');
            this.$ngRedux.dispatch(this.AuthActions.updateUserPreferences({ [USER_PREFERENCES[this.tutorialKey]]: USER_PREFERENCE_VERSIONS[this.tutorialKey] }));
            this.highlighter.hide();
        }

        update() {
            if (this.index > 0) {
                this.coachmarks[this.index - 1].deactivate();
            }

            const coachmark = this.coachmarks[this.index];
            const target = angular.element(coachmark.target);

            if (this.currentTarget) {
                this.currentTarget.removeClass('tutorial--coachmark-focus');
            }

            if (!target) {
                return;
            }
            this.onBeforeChange({ coachmark });


            coachmark.activate();
            this.currentTarget = target;
            target.addClass('tutorial--coachmark-focus');

            // this.coachmarkContainer.addClass('transition-transform');
            this.coachmarkContainer.removeClass('hidden');
            this.updateHighlightPosition();

            this.onAfterChange({ coachmark });

        }

        updateHighlightPosition() {
            const bounding = this.currentTarget[0].getBoundingClientRect();
            this.highlighter.css('top', `${bounding.y}px`);
            this.highlighter.css('left', `${bounding.x}px`);
            this.highlighter.css('width', `${bounding.width}px`);
            this.highlighter.css('height', `${bounding.height}px`);

            // Update popper to the new position
            this.popper.update();
        }
    }
};
