<p-table #adminOrganisationsTable [value]="organisations" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="10"
  [rowsPerPageOptions]="[10,50,100]" [rowHover]="true" [lazy]="true" (onLazyLoad)="loadOrganisations($event)"
  [totalRecords]="totalOrganisations" [loading]="loading" sortField="id" [sortOrder]="-1" [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} organisations" [scrollable]="true"
  scrollHeight="flex" (onRowSelect)="rowSelect($event)" class="text-sm" [first]="first" [rows]="rows"
  [filters]="filters" [sortField]="sortField" [sortOrder]="sortOrder">

  <ng-template pTemplate="caption">
    <div class="flex items-center justify-between w-full">
      <h3 class="text-2xl mr-4">Manage Organisations</h3>
      <p-button size="large" (click)="isVisibleDialog = true">Create Organisation</p-button>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="id" style="width: 10%">ID<p-sortIcon field="id"></p-sortIcon></th>
      <th pSortableColumn="name" style="width: 15%">Name<p-sortIcon field="name"></p-sortIcon></th>
      <th pSortableColumn="slug" style="width: 15%">Slug<p-sortIcon field="slug"></p-sortIcon></th>
      <th pSortableColumn="tier.name" style="width: 10%">Tier<p-sortIcon field="tier.name"></p-sortIcon></th>
      <th pSortableColumn="createdAt" style="width: 18%">Created At<p-sortIcon field="createdAt"></p-sortIcon></th>
      <th pSortableColumn="freeTrialEnd" style="width: 18%">Free Trial End Time<p-sortIcon field="freeTrialEnd"></p-sortIcon></th>
      <th pSortableColumn="freeTrialExpired" style="width: 14%">Free Trial Expired<p-sortIcon field="freeTrialExpired"></p-sortIcon></th>
    </tr>
    <tr>
      <th></th>
      <th><p-columnFilter field="name" type="text" matchMode="equals" [showMenu]="false"></p-columnFilter></th>
      <th><p-columnFilter field="slug" type="text" matchMode="equals" [showMenu]="false"></p-columnFilter></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-organisation>
    <tr class="cursor-pointer" (click)="rowSelect(organisation)">
      <td>{{ organisation.id }}</td>
      <td>{{ organisation.name }}</td>
      <td>{{ organisation.slug }}</td>
      <td>{{ organisation.tier.name }}</td>
      <td>{{ organisation.createdAt| date:'medium' }}</td>
      <td>{{ organisation.freeTrialEnd | date:'medium'}}</td>
      <td>{{ organisation.freeTrialExpired | yesNo }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">No organisations found.</td>
    </tr>
  </ng-template>
</p-table>

<app-create-organisation [isVisibleDialog]="isVisibleDialog" (visibleChange)="onVisibleChange($event)"></app-create-organisation>
