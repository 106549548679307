import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit, SecurityContext, signal } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import * as marked from 'marked';
import { z } from 'zod';

const MediaItemSchema = z.object({
    id: z.number(),
    url: z.string(),
    name: z.string(),
    type: z.string(),
    caption: z.string().nullable(),
    sort: z.number(),
});

const CollectionItemSchema = z.object({
    id: z.number(),
    key: z.string(),
    value: z.string(),
    data: z.any(),
    mediaItems: MediaItemSchema.array()
});

const CollectionAttributeSchema = z.object({
    label: z.string(),
    path: z.string().nullable(),
    visible: z.boolean()
});

const CollectionSchema = z.object({
    attributes: CollectionAttributeSchema.array(),
    items: CollectionItemSchema.array()
});

const CollectionItemPreviewSchema = z.object({
    title: z.string(),
    data: z.object({
        label: z.string(),
        value: z.any().nullable()
    }).array(),
    mediaItems: MediaItemSchema.array()
});

type Collection = z.infer<typeof CollectionSchema>;
export type CollectionItemPreview = z.infer<typeof CollectionItemPreviewSchema>;

@Component({
    selector: 'app-collection-info-modal',
    templateUrl: 'collection-info-modal.component.html',
    standalone: true,
    imports: [
        CommonModule
    ]
})

export class CollectionInfoModalComponent implements OnInit {

    apiService = inject(ApiService);
    dialogConfig = inject(DynamicDialogConfig);
    sanitizer = inject(DomSanitizer);

    private projectId: number;
    private collectionId: number;
    private itemId: string;

    loading = signal(false);
    collectionItem = signal<CollectionItemPreview | null>(null);
    
    images = computed(() => {
        const item = this.collectionItem();
        return item.mediaItems.filter(item => {
            const type = item.type.split('/')[0];
            return type === 'image';
        }).sort((a, b) => a.sort - b.sort);
    });
    currentImageIndex = signal(0);
    currentImage = computed(() => {
        const images = this.images();
        const index = this.currentImageIndex();
        return images[index];
    });

    audio = computed(() => {
        const item = this.collectionItem();
       
        return item.mediaItems.filter(item => {
            const type = item.type.split('/')[0];
            return type === 'audio';
        });
    });

    data = computed(() => {
        const item = this.collectionItem();
        const markedData = item.data.filter(d => !!d.value).map(d => {
            return {
                ...d,
                value: this.sanitizer.sanitize(SecurityContext.HTML, marked.parse(d.value))
            }
        });
        return markedData;
    });

    constructor() { }

    ngOnInit() {
        this.projectId = this.dialogConfig.data['projectId'];
        this.collectionId = this.dialogConfig.data['collectionId'];
        this.itemId = this.dialogConfig.data['itemId'];

        this.getCollectionItem();
    }

    private getCollectionItem() {
        this.loading.set(true);

        const collectionsFragment = `fragment collectionFields on Collection{
            items(where: { id: $itemId }){
                id
                key
                value
                data
                mediaItems{
                    id
                    url
                    name
                    caption
                    type
                    sort
                }
            }
            attributes{
                path
                label
                visible
            }
        }`;

        const query = `query getCollectionItem($projectId: Int!, $collectionId: Int!, $itemId: Int!){
            project(id: $projectId){
                collections(where: { id: $collectionId }){ ...collectionFields }
                parent{
                    collections(where: { id: $collectionId }){ ...collectionFields }
                }
            }
        }${collectionsFragment}`;

        const input = {
            projectId: this.projectId,
            collectionId: this.collectionId,
            itemId: this.itemId
        }

        this.apiService.graphql<{ project: { collections: Collection[]; parent: { collections: Collection[] } } }>(query, input).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
            ).subscribe(res => {
                if (!!res) {
                    try {
                        const collectionToParse = res.project.collections?.[0] ?? res.project.parent?.collections?.[0];
                        const collection = CollectionSchema.parse(collectionToParse);
                        const item = collection.items[0];
                        const attributes = collection.attributes;

                        const data = attributes.filter(a => a.visible).map(a => {
                            return {
                                label: a.label,
                                value: item.data[a.path]
                            }
                        });

                        this.collectionItem.set({
                            title: item.value,
                            data,
                            mediaItems: item.mediaItems
                        })
                        
                    } catch (error) {
                        if (error instanceof z.ZodError) {
                            console.warn(error.issues);
                        }
                    }
                }
                this.loading.set(false);
            });
    }
}