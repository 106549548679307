<p-confirmDialog styleClass="hide-close"></p-confirmDialog>

<p-table 
    [value]="collection"
    [tableStyle]="{'min-width': '714px'}"
    [lazy]="true"
    (onLazyLoad)="loadCollection($event)"
    (onRowReorder)="reorderRows($event)" 
    [paginator]="true" 
    paginatorDropdownAppendTo="body"
    [rows]="rows"
    [rowsPerPageOptions]="[10,50,100]"
    [totalRecords]="itemCount"
    [loading]="loading"
    [scrollable]="true"
    scrollHeight="flex"
    styleClass="bg-white p-0"
>
    <ng-template pTemplate="caption">
        <div class="coreo-list-header p-4 border-b border-grey-30">
            <h2 class="whitespace-nowrap truncate">{{ title }}</h2>
            <div class="flex items-center justify-end gap-4">
                <span class="p-input-icon-left">
                    <i class="fa-solid fa-magnifying-glass text-primary text-xs ml-1"></i>
                    <input pInputText #searchInput type="text" (input)="debouncedSearch()" class="w-full max-w-60 h-11 pr-10" autocomplete="off"
                    placeholder="Search for an item" [(ngModel)]="search" />
                    @if (search.length > 0) {
                        <button class="absolute right-2 top-1/2 -translate-y-1/2 h-5 w-5 rounded-md cursor-pointer hover:bg-grey-10" (click)="clearSearch($event)">
                            <i class="fa-solid fa-xmark text-text-secondary text-sm absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"></i>
                        </button>
                    }
                </span>
                <p-button size="large" (click)="newItem()">
                    <i class="fas fa-plus"></i>New item
                </p-button>
            </div>
        </div>
        <div class="coreo-list-header p-2 gap-2">
            <p-button [text]="true" (click)="reverse()" [disabled]="collection.length < 1">
                <span class="text-sm text-black font-semibold">Reverse</span>
                <i [ngClass]="{'fa-arrow-up-wide-short': !sortReverse, 'fa-arrow-down-wide-short': sortReverse}" class="fa-solid fa-arrow-up-wide-short ml-1 text-black"></i>
            </p-button>
            <div class="flex items-center justify-end">
                <span class="text-sm text-black font-normal mr-2">Sort</span>
                <p-selectButton [options]="sortOptions" [(ngModel)]="sortMode" [multiple]="false" [unselectable]="true" optionLabel="label" optionValue="value" [disabled]="collection.length < 1" optionDisabled="disabled" (onChange)="updateSort()"></p-selectButton>
            </div>
            @if (sortMode === 'attribute') {
                <p-dropdown [options]="attributeDropdownOptions" [(ngModel)]="sortAttributeId" optionLabel="label" optionValue="id" placeholder="Select attribute" (onChange)="updateSort()" appendTo="body" styleClass="h-8"></p-dropdown>
            }
        </div>
    </ng-template>

    <ng-template pTemplate="body" let-item let-index="rowIndex">
        <tr [pReorderableRow]="index" (click)="rowSelect(item)" class="table-item table-item--thin">
            <div class="table-item__inner justify-between">
                <div class="flex items-center gap-[10px]">
                    @if (hasImages) {
                        <p-avatar [image]="item.avatar" shape="circle" styleClass="h-9 w-9" class="h-9 w-9"></p-avatar>
                    }
                    <p class="table-item__primary-text max-w-72 truncate">{{ item.value }}</p>
                </div>
                <div class="flex items-center justify-end gap-[10px]">
                    <p class="block text-xs text-grey-120 max-w-64 truncate">{{ item.key }}</p>
                    <div class="table-item__buttons">
                        <button class="table-item__button">
                            <i (click)="deleteCollectionItemCheck($event, item)" class="fa-solid fa-trash-can"></i>
                        </button>
                        @if (sortMode === 'manual') {
                            <i class="fa-solid fa-grip-vertical text-grey-30" pReorderableRowHandle></i>
                        }
                    </div>
                </div>
            </div>
        </tr>
    </ng-template>
    
    <ng-template pTemplate="emptymessage">
        <div class="absolute h-full w-full flex flex-col items-center justify-center">
            <i class="fa-solid {{ search.length > 0 ? 'fa-magnifying-glass' : 'fa-layer-group' }} text-5xl text-primary-100 mb-5"></i>
            <p class="text-base font-semibold">
                @if (search.length > 0) {
                    Nothing found
                }
                @else {
                    You don't have any collection items yet
                }
            </p>
            @if (search.length > 0) {
                <p class="max-w-80 text-sm text-text-secondary text-center mt-6">No items fit that search description. Try again with a different search term.</p>
            }
            @else {
                <p-button size="large" (click)="newItem()" class="mt-10">
                    <i class="fas fa-plus mr-1"></i>New item
                </p-button>
            }
        </div>
    </ng-template>
</p-table>