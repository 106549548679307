import bbox from '@turf/bbox';
import { feature, polygon } from '@turf/helpers';
import difference from '@turf/difference';

export default class MapUtils {

    static $inject = ['$http', 'mapsUrl'];
    constructor($http, mapsUrl) {
        this.$http = $http;
        this.mapTilesUrl = `${mapsUrl}/tiles`;
    }

    geometryToBounds(geometry) {
        return bbox(geometry);
    }

    addProjectBoundaryLayer(map, bounds, color = '#000') {

        safelyAddSource(map, 'projectBoundsLineSource', {
            type: 'geojson',
            data: bounds
        }, true);

        const globalLayer = polygon([[
            [-180, -90],
            [-180, 90],
            [180, 90],
            [180, -90],
            [-180, -90]
        ]]);
        const appLayer = feature(bounds);
        const data = difference(globalLayer, appLayer);

        map.addSource('projectBoundsFillSource', {
            type: 'geojson',
            data
        });

        map.addLayer({
            id: 'projectBoundsLine',
            type: 'line',
            source: 'projectBoundsLineSource',
            paint: {
                'line-width': 2,
                'line-color': color
            }
        });

        map.addLayer({
            id: 'projectBoundsFill',
            type: 'fill',
            source: 'projectBoundsFillSource',
            paint: {
                'fill-opacity': 0.2,
                'fill-color': color
            }
        });

        return {
            setColor: (color) => {
                map.setPaintProperty('projectBoundsFill', 'fill-color', color);
                map.setPaintProperty('projectBoundsLine', 'line-color', color);
            },
            remove: () => {
                safelyRemoveLayer(map, 'projectBoundsFill');
                safelyRemoveLayer(map, 'projectBoundsLine');
                safelyRemoveSource(map, 'projectBoundsFillSource');
                safelyRemoveSource(map, 'projectBoundsLineSource');
            }
        }
    }

    getMapUrl(query) {
        return this.$http.post(`${this.mapTilesUrl}`, query).then(result => {
            const mapId = result.data.mapId;
            return `${this.mapTilesUrl}/${mapId}/{z}/{x}/{y}.mvt`;
        });
    }
}