import * as Sentry from '@sentry/browser';
import { getProject, getProjectForms, getProjectAttributes, getProjectRole, getProjectParent } from '../../store/selectors';

export default class ProjectFormsController {

    static $inject = ['$scope', '$ngRedux', 'ProjectsService', 'CoreoAPI', 'ProjectActions', 'toastr', '$state', '$mdDialog'];
    constructor($scope, $ngRedux, ProjectsService, CoreoAPI, ProjectActions, toastr, $state, $mdDialog) {
        $scope.$on('$destroy', $ngRedux.connect(this.mapStateToThis, ProjectActions)(this));

        this.ProjectsService = ProjectsService;
        this.toastr = toastr;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.readonly = this.role !== 'admin';
        this.CoreoAPI = CoreoAPI;

        this.builderSortableConfig = {
            axis: 'y',
            containment: 'parent',
            tolerance: 'pointer'
        };

        this.formsReordered = this.formsReordered.bind(this);
    }

    formsReordered() {
        const newOrder = this.forms.map((form, i) => {
            return { ...form, sort: i }
        })

        this.updateForms(newOrder);
    };

    mapStateToThis(state) {
        const attributes = getProjectAttributes(state);
        const getForms = getProjectForms(state);
        const sortExists = !getForms.every(form => (form.hasOwnProperty('sort') && form.sort === 0));
        const forms = getForms.filter(f => !f.readonly).map((form, idx) => {
            return {
                ...form,
                questionCount: attributes.filter((a) => a.surveyId === form.id).length,
                visibleQuestionCount: attributes.filter((a) => ((a.surveyId === form.id) && a.visible)).length,
                sort: sortExists ? form.sort : idx
            }
        });

        return {
            project: getProject(state),
            role: getProjectRole(state),
            forms: _.sortBy(forms, ['sort']),
            parent: getProjectParent(state)
        };
    }

    createFormModal() {
        this.$mdDialog.show({
            template: require('!raw-loader!./create/create-form.modal.html').default,
            controller: 'ProjectFormCreateController',
            controllerAs: 'ctrl'
        }).then((form) => {
            return this.createForm(form.name, form.title, this.forms.length).then((form) => {
                this.$state.go('project.form', { 'form_id': form.id });
            }, angular.noop);
        }, angular.noop);
    }

    removeForm(form) {
        var vm = this;

        this.$mdDialog.show({
            template: require('!raw-loader!./delete-data.modal.html').default,
            resolve: {
                recordCount: () => {
                    const query = `{project(id: ${vm.project.id}){surveys(where:{id: ${form.id}}){recordCount}}}`;
                    return vm.CoreoAPI.query(query).then(result => {
                        return result && result.project && result.project.surveys && result.project.surveys[0] && result.project.surveys[0].recordCount || 0;
                    });
                },
                associateAttributes: () => {
                    const query = `{project(id: ${vm.project.id}){attributes(where:{associatedSurveyId: ${form.id}}){id, label, survey { title }}}}`;
                    return vm.CoreoAPI.query(query).then(result => {
                        return result && result.project && result.project.attributes || [];
                    });
                }
            },
            controller: ['$scope', '$mdDialog', 'recordCount', 'associateAttributes', function ($scope, $mdDialog, recordCount, associateAttributes) {
                $scope.recordCount = recordCount;
                $scope.associateAttributes = associateAttributes;
                $scope.associateAttributeCount = associateAttributes.length;
                $scope.delete = function () {
                    $mdDialog.hide();
                };

                $scope.cancel = function () {
                    $mdDialog.cancel();
                };
            }]
        }).then(() => {
            vm.deleteForm(form.id)
                .then(
                    () => vm.toastr.success('Form and records deleted'),
                    (err) => {
                        console.log(err);
                        vm.toastr.error('There was an error deleting the form');
                        Sentry.captureException(err);
                    }
                );
        }, angular.noop);
    }
}
