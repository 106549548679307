<form class="w-96" [formGroup]="createPageForm" (ngSubmit)="onSubmit()">
    <div class="p-5">
        <div class="flex flex-col gap-1 mb-4">
            <label for="name">Title</label>
            <input pInputText [autofocus]="true" id="name" formControlName="name" placeholder="Name" />
        </div>
        <div class="flex flex-col gap-1 mb-4">
            <label for="name">Template</label>
            <div class="flex gap-4">
                <div *ngFor="let type of templateTypes" (click)="setTemplate(type)"
                    class="rounded-md w-full cursor-pointer border hover:shadow-xl p-8 {{createPageForm.value.template.title === type.title ? 'border-primary' : 'border-white'}}">
                    <div class="flex flex-col items-center justify-between">
                        <i class="fas text-3xl m-8 {{ type.icon }}"></i>
                        <p class="font-bold capitalize">{{ type.title }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-1 mb-4">
            <p>{{ createPageForm.value.template.description }}</p>
        </div>
    </div>
    <div class="p-dialog-footer">
        <p-button [text]="true" size="large" (click)="close()">Cancel</p-button>
        <button type="submit" form="createPageForm" pButton class="p-button-lg" [disabled]="!createPageForm.valid"
            (click)="onSubmit()">Create</button>
    </div>
</form>