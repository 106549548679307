<div class="relative h-full w-full flex flex-col">
    <div class="grow p-4">
        <div class="relative h-full w-full overflow-hidden flex items-center justify-center">
            @if (currentImage(); as image) {
            <img [src]="image | twicPic:'resize=1080'" alt="" class="absolute h-full object-contain">
            }
        </div>
    </div>
    @if (multiple()) {
    <div class="flex-none p-5 flex items-center justify-between border-t border-grey-border">
        <div>
            <p-button (click)="previous()" [text]="true" [disabled]="first()">
                <i class="fa-solid fa-chevron-left"></i>
                <span class="ml-1">Previous</span>
            </p-button>
            <p-button (click)="next()" [text]="true" [disabled]="last()">
                <span class="mr-1">Next</span>
                <i class="fa-solid fa-chevron-right"></i>
            </p-button>
        </div>
    </div>
    }
</div>