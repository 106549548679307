export default class ProjectMediaService {
    static $inject = ['CoreoAPI'];
    constructor(CoreoAPI) {
        this.CoreoAPI = CoreoAPI;
    }

    getProjectMediaItems(projectId) {
        var query = '{data: project(id: ' + projectId + '){mediaItems{id,name,url,size,type,caption,createdAt}}}';
        return this.CoreoAPI.query(query).then(function (data) {
            return _.sortBy(data.data.mediaItems, function (item) {
                return item.name && item.name.toLowerCase();
            });
        });
    }

    createMediaItem(mediaItem, parentId, projectId, file, parentIdKey) {
        const newMediaItem = _.pick(mediaItem, ['caption', 'sort'])
        newMediaItem[parentIdKey] = parentId;
        newMediaItem.projectId = projectId;
        var mutation = `mutation{result: createMediaItem(input:${this.CoreoAPI.gqlStringify(newMediaItem)}){id,url,name,size,caption,type,createdAt,sort}}`;
        return this.CoreoAPI.mutation(mutation, { file: file });
    }

    createItemMediaItem(mediaItem, itemId, projectId, file) {
        return this.createMediaItem(mediaItem, itemId, projectId, file, 'itemId')
    }

    createPageBlockMediaItem(mediaItem, pageBlockId, projectId, file) {
        return this.createMediaItem(mediaItem, pageBlockId, projectId, file, 'pageBlockId')
    }

    createPageMediaItem(mediaItem, projectPageId, projectId, file) {
        return this.createMediaItem(mediaItem, projectPageId, projectId, file, 'projectPageId')
    }

    deleteMediaItems(ids) {
        var deletes = _.map(ids, function (id) {
            return 'delete_' + id + ': deleteMediaItem(input:{id: ' + id + '})';
        }).join('\n');
        var mutation = 'mutation{' + deletes + '}';
        return this.CoreoAPI.mutation(mutation);
    }


    updateMediaItem(mediaItem) {
        mediaItem = {
            id: mediaItem.id,
            sort: mediaItem.sort,
            caption: mediaItem.caption
        };
        var query = 'mutation {updateMediaItem(input:' + this.CoreoAPI.gqlStringify(mediaItem) + '){id,sort} }';
        return this.CoreoAPI.mutation(query);
    }

    updateMediaItems(mediaItems) {
        mediaItems = mediaItems.map(item => ({
            id: item.id,
            sort: item.sort,
            caption: item.caption
        }));
        const items = [];
        for (var i = 0; i < mediaItems.length; i++) {
            var name = (i === 0 ? 'data' : 'data_' + i);
            items.push(name + ': updateMediaItem(input:' + this.CoreoAPI.gqlStringify(mediaItems[i]) + '){id,sort}');
        }
        var query = 'mutation {' + items.join(',') + '}';
        return this.CoreoAPI.mutation(query);
    }
}
ProjectMediaService.$inject = ['CoreoAPI'];