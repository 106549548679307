import { IComponentController, IOnChangesObject } from "angular";
import { HeatmapPaint } from "mapbox-gl";
import { CoreoDataLayerStyle } from "../../../types";
// import "./map-data-layer-editor.component.scss";

const hexToRgbA = (hex: string): string => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0)';
    }
    throw new Error('Bad Hex');
}

export const HeatmapStyleEditorComponent = {
    bindings: {
        paint: '<',
        onUpdate: '&'
    },
    template: require('!raw-loader!./heatmap-style-editor.component.html').default,
    controllerAs: 'ctrl',
    controller: class HeatmapStyleEditorComponentController implements IComponentController {
        paint: HeatmapPaint;
        colors: string[] = [];
        onUpdate: (event: { paint: HeatmapPaint }) => void;

        $onInit() {
            // this.buildPreview();
            const heatmapPaintProp = this.paint['heatmap-color'];
            this.colors = [
                // heatmapPaintProp[4],
                heatmapPaintProp[6],
                heatmapPaintProp[8],
                heatmapPaintProp[10],
                heatmapPaintProp[12],
                heatmapPaintProp[14]
            ];
            console.log('HERE', this.colors);
            if (!this.colors.every(a => a.startsWith('#'))) {
                this.colors = [
                    // "#ff0000",
                    "#4169e1",
                    "#00FFFF",
                    "#bfff00",
                    "#ffff00",
                    "#ff0000"
                ];
                this.colorChanged();
            }
        }

        $onChanges(onChangesObj: IOnChangesObject): void {
            // this.buildPreview();
        }

        changed() {
            this.onUpdate({ paint: this.paint });
        }

        colorChanged() {
            this.paint = {
                ...this.paint,
                'heatmap-color': [
                    'interpolate',
                    ['linear'],
                    ['heatmap-density'],
                    0,
                    // hexToRgbA(this.colors[0]),
                    'rgba(0,0,0,0)',
                    0.1,
                    this.colors[0],
                    0.3,
                    this.colors[1],
                    0.5,
                    this.colors[2],
                    0.7,
                    this.colors[3],
                    1,
                    this.colors[4]
                ]
            };
            this.onUpdate({ paint: this.paint });
        }
    }
};
