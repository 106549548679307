<div class="shadow rounded p-2.5 bg-white text-sm w-96 jobs-list">
    <div class="flex gap-3 items-center py-2 mb-2">
        <i class="fa-solid fa-bell text-[#878F97]"></i>
        <h2 class="text-[#444]">Notifications</h2>
        <button (click)="toggle()" class="ml-auto pr-2"><i class="fa-solid fa-chevron-down text-[#878F97]"></i></button>
    </div>

    <div class="space-y-2">
        @for(job of jobs(); track job.id){
        <app-job [job]="job"></app-job>
        }
    </div>
</div>

<button class="rounded-full bg-primary text-white w-10 h-10 cursor-pointer relative" (click)="toggle()">
    <i class="fa-solid fa-bell"></i>
    <span
        class="absolute -top-1 -right-1 bg-white rounded-full w-4 h-4 text-primary tabular-nums text-xs flex items-center justify-center border border-primary p-2 font-semibold">{{count()}}</span>
</button>