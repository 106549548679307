import thunk from 'redux-thunk';
import freeze from 'redux-freeze';
import logger from 'redux-logger';

export const enhancers = [];
export const middleware = [thunk, freeze, 'toastMiddleware', 'recordsMiddleware', 'recordsRefreshMiddleware', logger];
// export const middleware = [thunk, freeze, 'toastMiddleware', logger];

// if (process.env.NODE_ENV === 'development') {
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
}
// }
