import { Injectable, signal } from '@angular/core';

interface FormCollection {
    id: number;
    items: { key: string; value: string; data: any }[];
}

@Injectable()
export class FormCollectionsService {
    
    private collectionsSignal = signal<FormCollection[]>([]);

    readonly collections = this.collectionsSignal.asReadonly();

    setCollections(collections: FormCollection[]) {
        this.collectionsSignal.set(collections);
    }
}