
export const UsernameFeedbackComponent = {
    bindings: {
        username: '<'
    },
    template: `
    <div class="my-2 h-16 overflow-hidden">
        <ul class="grid grid-cols-1" ng-show="ctrl.username.$error.format || ctrl.username.$error.length">
            <li ng-repeat="check in ctrl.checks"
                class="text-xs text-grey-70 h-6"
                ng-class="{'opacity-50': !ctrl.username.$error[check.key]}">
                <i class="fas mr-4 w-1 h-1 text-xs fa-circle input-feedback-dot"
                    ></i>{{check.message}}
            </li>
        </ul>
        <div ng-show="ctrl.username.$error.alreadyTaken"class="bg-danger-lighter text-danger rounded-md flex items-center justify-center w-full h-12 mt-2" >Username is already taken</div>
        <div ng-show="ctrl.username.$valid" class="text-success bg-success-lighter rounded-md flex items-center justify-center w-full h-12 mt-2">Username is available and you're all set</div>
    </div>`,
    controllerAs: 'ctrl',
    controller: class UsernameFeedbackComponent {
        constructor() {
            this.checks = [
                { message: 'Lowercase letters, numbers & hyphens only', key: 'format' },
                { message: '5 characters minimum', key: 'length' }
            ];
        }
    }
};
