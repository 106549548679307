export default class UsernameCheckDirective {
    constructor($q, CoreoAPI) {
        this.restrict = 'A';
        this.require = 'ngModel';
        this.CoreoAPI = CoreoAPI;
        this.$q = $q;
    }
    link(scope, element, attrs, ngModel) {

        const query = `query checkUserName($username: String!){
            usernameExists(username: $username)
          }`;
        const regex = /^[a-zA-Z\d-]{5,}$/;

        ngModel.$validators.testing = (modelValue, viewValue) => {
            const lengthInvalid = !viewValue || viewValue.length < 5;

            ngModel.$setValidity('length', !lengthInvalid);
            ngModel.$setValidity('format', !!viewValue && regex.test(viewValue));
            return true;
        };

        ngModel.$asyncValidators.alreadyTaken = (modelValue, viewValue) => {
            console.log('Checking username');
            return this.CoreoAPI.query(query, { variables: { username: viewValue } }).then(result => {
                if (result.usernameExists) {
                    console.warn('Already taken');
                    return this.$q.reject();
                }
                return true;
            });
        };
    }
}
