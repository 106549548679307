import angular from 'angular';
import JobsService from './jobs.service';

export default angular.module('app.jobs', [])
    .service('JobsService', JobsService)
    .config(['toastrConfig', function(toastrConfig) {
        angular.extend(toastrConfig, {
            autoDismiss: false,
            containerId: 'toast-container',
            maxOpened: 0,
            newestOnTop: true,
            positionClass: 'toast-bottom-center',
            preventDuplicates: false,
            preventOpenDuplicates: false,
            tapToDismiss: false,
            target: 'body'
        });
    }]);