<p-toast position="bottom-center" [life]="2000"></p-toast>
<p-dialog header="Clone Project" [(visible)]="isVisibleCloneDialog" #createDialog [modal]="true" (onHide)="onHideDialog()">
  <form class="w-96 flex flex-col gap-2" [formGroup]="selectModeForm">
    <div class="flex items-center">
      <p-radioButton inputId="clone" value="clone" formControlName="mode"></p-radioButton>
      <label for="clone" class="ml-2">Create a new project</label>
    </div>
    <div class="flex items-center">
      <p-radioButton inputId="merge" value="merge" formControlName="mode"></p-radioButton>
      <label for="merge" class="ml-2">Copy into an existing project</label>
    </div>
  </form>

  <form class="mt-4 space-y-4 p-fluid" [formGroup]="mergeProjectForm" *ngIf="selectModeForm.value.mode === 'merge'">
    <div class="flex flex-col gap-1" *ngIf="isAdmin">
      <label for="merge-organisation">Organisation</label>
      <p-autoComplete appendTo="body" inputId="merge-organisation" formControlName="organisation"
        field="name" placeholder="Search organisation user by name..." [showClear]="true" [forceSelection]="true"
        [suggestions]="organisations" [showEmptyMessage]="true" (completeMethod)="getOrganisation($event)">
        <ng-template let-organisation pTemplate="item">
          <div class="flex flex-col gap-2">
            <span class="block">{{organisation.name}}</span>
            <span class="block text-sm text-grey-80 italic flex space-between">ID: {{organisation.id}}</span>
          </div>
        </ng-template>
      </p-autoComplete>
    </div>
    <div class="flex flex-col gap-1">
      <label for="merge-project">Project</label>
      <p-autoComplete appendTo="body" inputId="merge-project" formControlName="project"
        field="name" placeholder="Search project by name..." [forceSelection]="true" [showClear]="true"
        [suggestions]="projects" [showEmptyMessage]="true" (completeMethod)="getProjects($event)">
        <ng-template let-project pTemplate="item">
          <span class="block">{{project.name}}</span>
        </ng-template>
      </p-autoComplete>
    </div>
  </form>

  <form class="mt-4 space-y-4 p-fluid" [formGroup]="cloneProjectForm" *ngIf="selectModeForm.value.mode === 'clone'">
    <div class="flex flex-col gap-1" *ngIf="isAdmin">
      <label for="clone-organisation">Organisation</label>
      <p-autoComplete appendTo="body" inputId="clone-organisation" formControlName="organisation"
        field="name" placeholder="Search organisation user by name..." [showClear]="true" [forceSelection]="true"
        [suggestions]="organisations" [showEmptyMessage]="true" (completeMethod)="getOrganisation($event)">
        <ng-template let-organisation pTemplate="item">
          <div class="flex flex-col gap-2">
            <span class="block">{{organisation.name}}</span>
            <span class="block text-sm text-grey-80 italic flex space-between">ID: {{organisation.id}}</span>
          </div>
        </ng-template>
      </p-autoComplete>
    </div>
    <div class="flex flex-col gap-1" *ngIf="isAdmin">
      <label for="clone-user">Organisation user (will own cloned project)</label>
      <p-autoComplete appendTo="body" inputId="clone-user" formControlName="user"
        field="user.displayName" placeholder="Search organisation user by name..." [forceSelection]="true" [showClear]="true"
        [suggestions]="users" [showEmptyMessage]="true" (completeMethod)="getUsers($event)">
        <ng-template let-user pTemplate="item">
          <div class="flex flex-col gap-2">
            <span class="block">{{user.user.displayName}}</span>
            <span class="block text-sm text-grey-80 italic flex space-between">
              {{user.user.username}} - ID: {{user.user.id}}</span>
          </div>
        </ng-template>
      </p-autoComplete>
    </div>
    <div class="flex flex-col gap-1">
      <label for="clone-name">Cloned Project Name</label>
      <input pInputText type="text" inputId="clone-name" formControlName="name" />
    </div>
    <div class="flex flex-col gap-1" *ngIf="isAdmin">
      <label for="clone-locked">Lock Cloned Project?</label>
      <p-checkbox formControlName="locked" [binary]="true" inputId="clone-locked"></p-checkbox>
    </div>
    <div class="flex flex-col gap-1" *ngIf="isAdmin">
      <label for="clone-limit">Users Limit</label>
      <input pInputText type="number" inputId="clone-limit" formControlName="usersLimit" />
    </div>
  </form>

  <ng-template pTemplate="footer">
    <p-button [text]="true" size="large" *ngIf="selectModeForm.value.mode" (click)="onHideDialog()">Cancel</p-button>
    <p-button type="submit" size="large" *ngIf="selectModeForm.value.mode === 'merge'" [disabled]="!mergeProjectForm.valid" (click)="onSubmitMerge()">Copy Project</p-button>
    <p-button type="submit" size="large" *ngIf="selectModeForm.value.mode === 'clone'" [disabled]="!cloneProjectForm.valid" (click)="onSubmitClone()">Clone Project</p-button>
  </ng-template>
</p-dialog>