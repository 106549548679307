import passwordStrength from 'check-password-strength';

export default class SignupController {
    static $inject = ['$scope', '$ngRedux', '$state', '$interval', 'toastr', 'AuthActions', 'CoreoAPI'];
    constructor($scope, $ngRedux, $state, $interval, toastr, AuthActions, CoreoAPI) {
        this.$scope = $scope;
        this.$state = $state;
        this.$interval = $interval;
        this.toastr = toastr;
        this.coreoIcon = '../../assets/icons/coreo.svg';
        this.CoreoAPI = CoreoAPI;
        this.signupComplete = false;
        this.signingUp = false;
        this.verificationPoll = null;

        this.user = {
            email: '',
            password: '',
            displayName: '',
            username: '',
            marketingConsent: false
        };
        this.emailError = false;


        this.passwordChecks = {
            length: false,
            lowercase: false,
            uppercase: false,
            symbol: false,
            number: false
        };

        this.passwordConfig = [
            { message: '8 or more characters', key: 'length' },
            { message: '1 or more uppercase letter', key: 'uppercase' },
            { message: '1 or more lowercase letter', key: 'lowercase' },
            { message: '1 or more number', key: 'number' },
            { message: '1 or more special character', key: 'symbol' },
        ];

        this.passwordType = 'password';

        $scope.$on('$destroy', $ngRedux.connect(null, AuthActions)(this));
    }

    togglePasswordType() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }

    passwordCheck() {
        let passedChecks = [];
        if (this.user.password) {
            const test = passwordStrength(this.user.password);
            passedChecks = test.contains.map(c => c.message);
        }

        for (const k in this.passwordChecks) {
            this.passwordChecks[k] = passedChecks.includes(k);
        }
        this.passwordChecks.length = this.user.password && this.user.password.length >= 8;
    }

    startVerificationPoll() {
        this.verificationPoll = this.$interval(() => {
            // Keep trying to login until we succeed
            this.login('local', {
                email: this.user.email,
                password: this.user.password
            }).then(() => {
                this.$state.go('intermediarypage.verify', {
                    freeTrialVerified: true
                });
            }, angular.noop);
        }, 4000);

        this.$scope.$on('$destroy', () => this.stopVerificationPoll());
    }

    stopVerificationPoll() {
        if (this.verificationPoll) {
            this.$interval.cancel(this.verificationPoll);
            this.verificationPoll = undefined;
        }
    }

    doSignup() {
        this.emailError = false;
        this.user.username = this.user.username.toLowerCase();
        this.signingUp = true;
        this.signup(this.user).then(() => {
            this.signupComplete = true;
            this.startVerificationPoll();
        }, (err) => {
            if (err.message === 'Email already taken') {
                this.emailError = true;
            }
            if (err.message === 'Email delivery failed') {
              this.toastr.error(err.message);
            }
            this.signingUp = false;
        });
    };

}

