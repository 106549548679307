<section class="flex flex-col gap-4 mb-4">
    <h2 class="text-2xl font-bold">Buttons</h2>
    <div class="flex gap-2 items-center">
        <label class="w-20">Default</label>
        <p-button>Button</p-button>
        <p-button [outlined]="true">Button</p-button>
        <p-button [text]="true">Button</p-button>
    </div>
    <div class="flex gap-2 items-center">
        <label class="w-20">Large</label>
        <p-button size="large" label="Button"></p-button>
        <p-button size="large" [outlined]="true">Button</p-button>
        <p-button size="large" [text]="true">Button</p-button>
    </div>
    <div class="flex gap-2 items-center">
        <label class="w-20">Small</label>
        <p-button size="small">Button</p-button>
        <p-button size="small" [outlined]="true">Button</p-button>
        <p-button size="small" [text]="true">Button</p-button>
    </div>
    <div class="flex gap-2 items-center">
        <label class="w-20">Loading</label>
        <p-button [loading]="true">Button</p-button>
        <p-button [loading]="true" [outlined]="true">Button</p-button>
        <p-button [loading]="true" [text]="true">Button</p-button>
    </div>
    <div class="flex gap-2 items-center">
        <label class="w-20">Disabled</label>
        <p-button [disabled]="true">Button</p-button>
        <p-button [disabled]="true" [outlined]="true">Button</p-button>
        <p-button [disabled]="true" [text]="true">Button</p-button>
    </div>
</section>

<section class="flex flex-col gap-4 mb-4">
    <h2 class="text-2xl font-bold">Inputs</h2>
    <input type="text" pInputText placeholder="Some placeholder text" />
    <input pInputText class="ng-invalid ng-dirty" placeholder="Some placeholder text" />
    <textarea rows="5" cols="30" pInputTextarea>Some placeholder text</textarea>
</section>

<section class="flex flex-col gap-4 mb-4">
    <h2 class="text-2xl font-bold">Dropdowns</h2>
    <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name" [showClear]="true"
        placeholder="Select a City"></p-dropdown>
</section>

<section class="flex flex-col gap-4 mb-4">
    <h2 class="text-2xl font-bold">Select button</h2>
    <p-selectButton [options]="selectButtonOptions" [(ngModel)]="selectMode" [multiple]="false" [unselectable]="true" optionLabel="label" optionValue="value" styleClass=""></p-selectButton>
    <p-selectButton [options]="selectButtonOptions" [(ngModel)]="selectMode" [multiple]="false" [unselectable]="true" optionLabel="label" optionValue="value" styleClass="w-full"></p-selectButton>
</section>

<section class="flex flex-col gap-4 mb-4">
    <h2 class="text-2xl font-bold">Cards</h2>
    <p-card>
        <h3 class="text-[42px] font-semibold">1,521</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.</p>
    </p-card>
</section>

<section class="flex flex-col gap-4 mb-4">
    <h2 class="text-2xl font-bold">Panels</h2>
    <p-panel>
        <ng-template pTemplate="header">
            <div class="flex items-center justify-between">
                <div class="flex items-center gap-3">
                    <i class="fas fa-clipboard-check"></i>
                    <span class="p-panel-title">Forms</span>
                </div>
                <p-button>Button</p-button>
            </div>
        </ng-template>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
    </p-panel>
    <label>No padding on content</label>
    <p-panel class="p-0">
        <ng-template pTemplate="header">
            <div class="flex items-center gap-3">
                <span class="p-panel-title">Example</span>
            </div>
        </ng-template>
        <p class="p-4 border-b solid border-grey-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <p class="p-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua.</p>
    </p-panel>
    <label>Tab View panel</label>
    <p-tabView>
        <p-tabPanel header="Header I">
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
        </p-tabPanel>
        <p-tabPanel header="Header II">
            <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
                ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
            </p>
        </p-tabPanel>
    </p-tabView>
</section>

<section class="flex flex-col gap-4 mb-4">
    <h2 class="text-2xl font-bold">Avatars</h2>
    <div class="flex items-center gap-4">
        <p-avatar label="C" shape="circle"></p-avatar>
        <p-avatar image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" shape="circle"></p-avatar>
    </div>
</section>