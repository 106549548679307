export default function () {
    return function (input) {
        switch (input) {
            case 'google': return 'fab fa-google';
            case 'facebook': return 'fab fa-facebook';
            case 'local': return 'fas fa-at';
            case 'apple': return 'fab fa-apple';
            default: return ''
        }
    };
}