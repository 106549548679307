import { Component, Input, OnInit } from '@angular/core';
import { Question } from '../form.types';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AutocompleteModalComponent } from './autocomplete-modal.component';

@Component({
    selector: 'app-text-answer',
    templateUrl: 'text-answer.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InputTextModule,
        InputTextareaModule,
    ]
})

export class TextAnswerComponent implements OnInit {

    @Input() question: Question;
    @Input() form: FormGroup;

    private dialogRef: DynamicDialogRef | undefined;

    constructor(
        private dialogService: DialogService
    ) { }

    ngOnInit() { }

    public openAutocomplete() {
        this.dialogRef = this.dialogService.open(AutocompleteModalComponent, {
            data: {
                surveyId: this.question.surveyId,
                path: this.question.path
            },
            header: this.question.label,
            width: '75vw',
            height: '75vh',
            modal: true,
            breakpoints: {
                '960px': '75vw',
                '640px': '90vw'
            },
        });

        this.dialogRef.onClose.subscribe((data: string) => {
            if (data === undefined) {
                return;
            }
            if (!!data) {
                this.form.controls[this.question.path].setValue(data);
            }
            
        });
    }
}