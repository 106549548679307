import { Component, Input, OnInit } from '@angular/core';
import { Question } from '../form.types';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';
import { CommonModule } from '@angular/common';
import { addIcons } from "ionicons";

@Component({
    selector: 'app-numeric-answer',
    templateUrl: 'numeric-answer.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InputNumberModule,
        SliderModule,
    ]
})

export class NumericAnswerComponent implements OnInit {

    @Input() question: Question;
    @Input() form: FormGroup;

    public numberMin: number;
    public numberMax: number;
    public numberStep: number;
    public numberDefault: number;
    public isSlider: boolean = false;

    constructor() {}

    ngOnInit() {
        this.isSlider = this.question.questionType === 'slider';
        this.numberMin = parseFloat(this.question?.config?.min) || null;
        this.numberMax = parseFloat(this.question?.config?.max) || null;

        if (this.isSlider) {
            const configStep = parseFloat(this.question?.config?.step) || 1;
            const step = configStep > 0 ? configStep : 1;
            
            // allow decimal steps or not?
            if (this.question.type === 'float') {
                this.numberStep = step;
            } else {
                this.numberStep = Math.max(step, 1);
            }
        }
    }

    get isValid() {
        return this.form.controls[this.question.path].valid;
    }

    get value() {
        return this.form.controls[this.question.path].value;
    }

    onChange($event: any) {
        this.form.controls[this.question.path].setValue($event.value);
    }
}