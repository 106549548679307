import ErrorMessages from '../errorMessages';
import { APP_URL } from '../intermediarypage.constants';

export default class IntermediaryPageAccountVerificationController {
    static $inject = ['$location', 'IntermediaryPageService', '$stateParams'];
    constructor($location, IntermediaryPageService, $stateParams) {
        this.$location = $location;
        this.IntermediaryPageService = IntermediaryPageService;
        this.hostUrl = this.IntermediaryPageService.getHostUrl();
        this.displayHostUrl = this.IntermediaryPageService.getDisplayHostUrl();
        this.freeTrialVerified = $stateParams.freeTrialVerified; //go straight to success via this route
        if (this.freeTrialVerified) {
            this.loading = false;
            this.user = null;
            this.isAdminAreaUser = true;
            this.authToken = null;
            this.error = null;
        } else {
            this.verified = false;
            this.loading = true;
            this.user = null;
            this.error = null;
        }

    }

    $onInit() {
        // already verified, go straight to success
        if (this.freeTrialVerified) {
            return;
        }
        const search = this.$location.search();
        const token = search.account_verification_token;
        if (!token) {
            this.error = ErrorMessages.InvalidURL;
            this.loading = false;
            return;
        }

        return this.IntermediaryPageService.verifyAccount(token).then(user => {
            this.user = user;
            this.verified = true;
            this.isAdminAreaUser = this.user.isAdminAreaUser;
            this.loading = false;
            this.authToken = this.user.token;
        }, (e) => {
            this.error = e.message;
            this.loading = false;
        }
        )
    }
}
