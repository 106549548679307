<p-toast position="bottom-center" [life]="2000"></p-toast>
<p-panel class="w-full">
    <ng-template pTemplate="header">
        <div class="flex items-center">
            <div class="flex items-center">
                <i class="fas fa-mobile-alt fa-fw text-grey-20 mr-4"></i>
                <span class="font-bold text-lg text-grey-100">Project - {{project?.name}}
                    <p class="font-normal text-sm underline cursor-pointer" (click)="goToOrganisation()">
                        {{projectOrganisation?.name}}</p>
                </span>
            </div>
            <p-button type="button" [outlined]="true" size="large" (click)="goToDashboard()">Go to project</p-button>
        </div>
    </ng-template>

    <form class="flex flex-col space-y-4" [formGroup]="projectForm">
        <div class="flex flex-col gap-1">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" formControlName="name" />
        </div>
        <div class="flex flex-col gap-1">
            <label for="slug">Slug</label>
            <input type="text" pInputText id="slug" formControlName="slug" />
        </div>
        <div class="flex flex-col gap-1 p-fluid">
            <label for="version">UKHab Version</label>
            <p-dropdown id="version" [options]="projectVersions" optionLabel="label" formControlName="version"
                [showClear]="true" placeholder="Select UKHab Version"></p-dropdown>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <div class="flex justify-end gap-2">
            <p-button type="button" size="large" (click)="onSubmit()"
                [disabled]="!projectForm.valid || !projectForm.dirty"><i class="fa fa-save ng-scope mr-2"></i> Update
                Project</p-button>
        </div>
    </ng-template>

</p-panel>