<p-confirmDialog styleClass="hide-close"></p-confirmDialog>
<p-toast position="bottom-center" [life]="2000"></p-toast>

@if (item()) {
<div class="h-full w-full max-w-7xl flex flex-col gap-8 p-10 mx-auto">
    <div class="flex-none w-full flex items-center justify-between gap-5">
        <div class="min-w-32 flex items-center gap-2">
            <button (click)="back.emit()">
                <i class="fa-solid fa-chevron-left text-xl text-text-secondary"></i>
            </button>
            <h2 class="text-3xl font-semibold text-nowrap truncate">{{ item().value }}</h2>
        </div>
        <div class="flex items-center justify-end gap-4">
            <p-button [disabled]="loading()" (click)="deleteItemCheck($event)" size="large" [text]="true"
                severity="danger" label="Delete item">
                <i class="fa-solid fa-trash-can mr-2"></i>
            </p-button>
            <p-button [disabled]="loading() || !!valueError()" (click)="showPreview()" size="large" [outlined]="true"
                label="Preview card">
                <i class="fa-solid fa-eye mr-2"></i>
            </p-button>

            <p-button [disabled]="!canSave()" (click)="saveItem()" size="large" label="Save changes">
                <i class="fa-solid fa-floppy-disk mr-2"></i>
            </p-button>
        </div>
    </div>

    <p-panel class="min-w-[720px] p-0">
        <ng-template pTemplate="header">
            <div class="flex items-center">
                <p class="p-panel-title">Detail of collection item</p>
            </div>
            <p class="font-semibold">Key: {{ item().key }}</p>
        </ng-template>
        @if (valueForm()) {
        <form [formGroup]="valueForm()" class="p-5">
            <div class="flex items-start gap-4">
                <label for="value" class="text-sm font-semibold w-1/5 min-w-44 pt-3">Name (Value) *</label>
                <div class="w-full">
                    <input pInputText type="text" formControlName="value" id="value" class="w-full" />
                    @if (valueError()) {
                    <p class="text-xs text-danger leading-none pt-4">{{ valueError() }}</p>
                    }
                </div>
            </div>
        </form>
        }
        @if (attributeForm()) {
        <div class="h-10 px-4 flex items-center bg-primary-100 border-t border-b border-primary-300">
            <p class="text-xs text-text-primary font-semibold">Attributes</p>
        </div>
        <form [formGroup]="attributeForm()" class="p-5 space-y-5">
            @for (question of attributeQuestions(); track question) {
            <div class="flex items-start gap-4">
                <label [for]="question.path" class="text-sm font-semibold w-1/5 min-w-44 pt-2">{{ question.label
                    }}</label>
                @if (question.type === 'text') {
                <!-- <input pInputText type="text" [formControlName]="question.path" [id]="question.path" class="w-full" /> -->
                <textarea [autoResize]="true" pInputTextarea [formControlName]="question.path" [id]="question.path"
                    class="w-full">
                                </textarea>
                }
                @else if (question.type === 'float') {
                <p-inputNumber [minFractionDigits]="0" [formControlName]="question.path" [inputId]="question.path"
                    styleClass="w-full" class="w-full"></p-inputNumber>
                }
            </div>
            }
        </form>
        }
    </p-panel>

    @if (geometry()) {
    <p-panel class="p-0 min-w-[720px]">
        <ng-template pTemplate="header">
            <div class="flex items-center">
                <p class="p-panel-title">Geometry</p>
            </div>
        </ng-template>
        <div #collectionItemMap class="w-full h-80"></div>
    </p-panel>
    }

    <p-panel class="min-w-[720px]">
        <ng-template pTemplate="header">
            <div class="flex items-center">
                <p class="p-panel-title">Media</p>
            </div>
            <p-button size="large" [outlined]="true" label="Upload media file" (click)="addMedia($event)"
                [disabled]="mediaLoading()">
                <i class="fa-solid fa-plus mr-2"></i>
            </p-button>
            <input #mediaInput type="file" accept="capture=image/jpeg, image/png, image/jpg, audio/*"
                (change)="selectMediaHandler()" class="sr-only opacity-0" />
        </ng-template>
        @if (mediaItems().length) {
        <p class="text-center text-xs font-semibold">The first image will be shown with item value as a choice in the
            Coreo app. Click and drag to reorder.</p>

        <app-collection-item-media-list [items]="mediaItems()"></app-collection-item-media-list>
        }
        @else {
        <p class="text-center text-xs font-semibold">No media items.</p>
        }
    </p-panel>
</div>
}
<!-- TODO: loading -->