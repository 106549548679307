import angular from 'angular';
import { environment } from './environments/environment';

export default angular.module('app.constants', [])
    .constant('hostname', environment.adminHostname)
    .constant('port', environment.adminPort)
    .constant('protocol', environment.adminProtocol)
    .constant('version', 1)
    .constant('apiHostname', environment.apiHostname)
    .constant('apiProtocol', environment.apiProtocol)
    .constant('apiPort', environment.apiPort || 443)
    .constant('publicHostname', environment.adminHostname)
    .constant('publicProtocol', environment.adminProtocol)
    .constant('publicPort', environment.adminPort)
    .constant('appDomain', '')
    .constant('googleAnalyticsId', environment.googleAnalyticsId)
    .constant('mapboxAccessToken', environment.mapboxApiKey)
    .constant('bingMapsKey', environment.bingMapsKey)
    .constant('mapsUrl', environment.mapsUrl)
    .constant('docsUrl', environment.docsUrl)
    .constant('androidUrl', environment.androidUrl)
    .constant('iosUrl', environment.iosUrl)
    .constant('isProd', environment.production)
    ;