import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, computed, signal } from '@angular/core';
import { ApiService } from 'src/app/core';
import { catchError, of, take } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AvatarModule } from 'primeng/avatar';
import { MenuModule } from 'primeng/menu';
import { FormsModule } from '@angular/forms';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DateToNowPipe } from 'src/app/shared/pipes/date-to-now.pipe';
import { RecordsService } from '../records.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RecordGeometryComponent } from './record-geometry/record-geometry.component';
import { RecordCommentsComponent } from './comments/record-comments.component';
import { RecordActivityComponent } from './activity/record-activity.component';
import { RecordDataComponent } from './record-data/record-data.component';
import { Geometry } from 'geojson';
import { RecordBreadcrumbComponent } from './breadcrumb/record-breadcrumb.component';
import { RecordMessagesComponent } from './messages/record-messages.component';

interface ProjectVerificationState {
    projectId: number;
    stateId: number;
    sort: number;
    color: string;
    name: string;
}
export interface VerificationStateMap {
    [key: number]: ProjectVerificationState;
}
export interface RecordDetailViewer {
    id: number;
    role: string;
    memberships: {
        role: 'member' | 'moderator' | 'admin' | 'contributor'; 
        projectId: number; 
    }[];
    organisationMemberships: {
        role: string;
        organisationId: number;
    }[];
    displayName: string;
    imageUrl: string;
}
export interface RecordDetailUser {
    displayName: string;
    username: string;
    imageUrl: string;
    id: number;
}
interface RecordDetailSurvey {
    name: string;
    allowOwnRecordDelete: boolean;
    allowMemberUpdate: boolean;
}
interface RecordDetailResponse {
    id: number;
    geometry: Geometry;
    surveyId: number;
    projectId: number;
    createdAt: string;
    updatedAt: string;
    title: string;
    state: number;
    user: RecordDetailUser;
    survey: RecordDetailSurvey;
    project: { 
        name: string;
        organisationId: number;
        verification: boolean;
        states: ProjectVerificationState[];
        bespoke: boolean;
    };
}
export interface RecordDetailData { 
    id: number;
    createdAt: string;
    title: string;
    surveyId: number;
    surveyName: string;
    projectId: number;
    projectName: string;
    organisationId: number;
    state: number;
    verification: boolean;
    user: RecordDetailUser;
}

type Tab = 'activity' | 'comments' | 'messages';

@Component({
    selector: 'app-record-detail',
    templateUrl: './record-detail.component.html',
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        DropdownModule,
        ProgressSpinnerModule,
        AvatarModule,
        MenuModule,
        AsyncPipe,
        DatePipe,
        DateToNowPipe,
        ConfirmDialogModule,
        RecordGeometryComponent,
        RecordCommentsComponent,
        RecordActivityComponent,
        RecordMessagesComponent,
        RecordDataComponent,
        RecordBreadcrumbComponent
    ],
    providers: [
        ConfirmationService
    ],
    standalone: true,
})

export class RecordDetailComponent implements OnInit {

    @Input() recordId: number;

    @Output() record: EventEmitter<any> = new EventEmitter();
    @Output() records: EventEmitter<any> = new EventEmitter();

    @ViewChild('activityTab', { static: false }) activityTab: RecordActivityComponent;
    @ViewChild('recordData', { static: false }) recordDataComponent: RecordDataComponent;
    
    settingsMenuItems = signal<MenuItem[] | null>(null);

    verificationStateMap = signal<VerificationStateMap | null>(null);
    stateLoading = signal(false);
    verificationStates = signal<ProjectVerificationState[]>([]);
    verificationState = signal<ProjectVerificationState | null>(null);

    recordData = signal<RecordDetailData | null>(null);
    updatedAt = signal<string | null>(null);

    hasGeometry = signal(false);
    viewer = signal<RecordDetailViewer | null>(null);
    allowOwnRecordDelete = signal<boolean>(false);
    allowMemberUpdate = signal<boolean>(false);

    projectMembership = computed(() => {
        const viewer = this.viewer();
        const record = this.recordData();
        const projectId = record.projectId;
        const memberships = viewer.memberships ?? [];
        return memberships.find(m => m.projectId === projectId);
    });
    isProjectAdmin = computed<boolean>(() => {
        const projectMembership = this.projectMembership();
        if (!!projectMembership) {
            return projectMembership.role === 'admin';
        } else {
            return false;
        }
    });
    isProjectModerator = computed<boolean>(() => {
        const projectMembership = this.projectMembership();
        if (!!projectMembership) {
            return projectMembership.role === 'moderator';
        } else {
            return false;
        }
    });
    isProjectMember = computed<boolean>(() => {
        const projectMembership = this.projectMembership();
        if (!!projectMembership) {
            return projectMembership.role === 'member';
        } else {
            return false;
        }
    });
    organisationMembership = computed(() => {
        const viewer = this.viewer();
        const record = this.recordData();
        const organisationId = record.organisationId;
        const orgMemberships = viewer.organisationMemberships ?? [];
        return orgMemberships.find(m => m.organisationId === organisationId);
    });
    isOrganisationAdminOrOwner = computed<boolean>(() => {
        const organisationMembership = this.organisationMembership();
        if (!!organisationMembership) {
            return organisationMembership.role === 'admin' || organisationMembership.role === 'owner';
        } else {
            return false;
        }
    });
    isSuperAdmin = computed<boolean>(() => {
        const viewer = this.viewer();
        return viewer.role === 'admin';
    });
    allowAccess = computed<boolean>(() => {
        const isSuperAdmin = this.isSuperAdmin();
        const isOrganisationAdminOrOwner = this.isOrganisationAdminOrOwner();
        const isProjectAdmin = this.isProjectAdmin();
        const isProjectModerator = this.isProjectModerator();
        return (
            isSuperAdmin || 
            isOrganisationAdminOrOwner || 
            isProjectAdmin || 
            isProjectModerator
        );
    });
    canDelete = computed<boolean>(() => {
        /** project moderators, project admins, org admins/owners & super admins only */
        /** handle survey allowOwnRecordDelete */
        const allowAccess = this.allowAccess();
        const allowOwnRecordDelete = this.allowOwnRecordDelete();
        const viewer = this.viewer();
        const record = this.recordData();
        const isMember = this.isProjectMember();
        
        if (allowAccess) {
            return true;
        } else if (allowOwnRecordDelete) {
            return viewer.id === record.user.id && isMember;
        } else {
            return false;
        }
    });
    canEdit = computed<boolean>(() => {
        /** project moderators, project admins, org admins/owners & super admins only */
        /** handle survey allowMemberUpdate */
        const allowAccess = this.allowAccess();
        const isMember = this.isProjectMember();
        const allowMemberUpdate = this.allowMemberUpdate();

        if (allowAccess) {
            return true;
        } else if (allowMemberUpdate) {
            return isMember;
        } else {
            return false;
        }
    });
    showActivityTabs = computed<boolean>(() => {
        /** project moderators, project admins, org admins/owners & super admins only */
        const allowAccess = this.allowAccess();
        return allowAccess;
    });

    bespokeProject = signal(false);

    tabs = computed<Tab[]>(() => {
        const bespoke = this.bespokeProject();
        let tabs: Tab[] = ['activity', 'comments'];
        if (bespoke) {
            tabs = [...tabs, 'messages'];
        }
        return tabs;
    });
    activeTab = signal<Tab>('activity');

    constructor(
        private apiService: ApiService,
        private confirmationService: ConfirmationService,
        private recordsService: RecordsService,
    ) {}
    
    ngOnInit() {
        this.settingsMenuItems.set([{
            label: 'Delete record',
            command: ($event) => {
                this.checkDeleteRecord($event);
            }
        }]);
    
        this.loadRecord();
    }
    
    public loadRecord() {
        this.getRecordData(this.recordId);
    }

    private getRecordUpdatedAt() {
        const query = `query getRecordUpdatedAt{
            record(id: ${this.recordId}){
                updatedAt
            }
        }`;

        this.apiService.graphql<{ record: { updatedAt: string } }>(query).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.updatedAt.set(res.record.updatedAt);
            }
        });
    }
    
    private getRecordData(id: number) {
        const query = `query getCoreoRecordData($id: Int){
            record(id: $id){
                id
                geometry {
                    type
                }
                surveyId
                projectId
                createdAt
                updatedAt
                title
                state
                user {
                    id
                    displayName
                    username
                    imageUrl
                }
                survey{
                    name
                    allowOwnRecordDelete
                    allowMemberUpdate
                }
                project{
                    name
                    organisationId
                    verification
                    states{
                        projectId
                        stateId
                        sort
                        color
                        name
                    }
                    bespoke
                }
            }
            viewer{
                id
                role
                memberships{
                    role
                    projectId
                }
                organisationMemberships{
                    role
                    organisationId
                }
                displayName
                imageUrl
            }
        }`;
        
        return this.apiService.graphql<{ record: RecordDetailResponse; viewer: RecordDetailViewer }>(query, { id }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {
            if(!!res) {
                const { record, viewer } = res;
                const { createdAt, project, projectId, state, survey, surveyId, title, user, geometry } = record;
                
                this.viewer.set(viewer);
                this.allowMemberUpdate.set(survey.allowMemberUpdate);
                this.allowOwnRecordDelete.set(survey.allowOwnRecordDelete);
                this.updatedAt.set(record.updatedAt);
                if (!!geometry) {
                    this.hasGeometry.set(true);
                }
                const verificationStateMap = this.getVerificationStateMap(project.states);
                this.verificationStateMap.set(verificationStateMap);
                this.verificationState.set(verificationStateMap[record.state]);
                this.verificationStates.set(project.states);
                this.bespokeProject.set(project.bespoke);

                const recordData: RecordDetailData = { 
                    id: record.id,
                    createdAt,
                    title,
                    surveyId,
                    surveyName: survey.name,
                    organisationId: project.organisationId,
                    state,
                    verification: project.verification,
                    user,
                    projectId,
                    projectName: project.name,
                };
                this.recordData.set(recordData);
            }
        })
    }

    private getVerificationStateMap(states: ProjectVerificationState[]): VerificationStateMap {
        return states.reduce((acc, state) => {
            return {
                ...acc,
                [state.stateId]: state
            }
        }, {});
    }

    public geometryUpdated() {
        /** Record geometry has been updated reload components that need to be or rely on that data */
        this.activityTab.refresh();
        this.recordDataComponent.refresh();
        this.getRecordUpdatedAt();
    }

    public recordUpdated() {
        this.activityTab.refresh();
        this.getRecordUpdatedAt();
    }

    public gotToRecord(id: number) {
        this.record.emit(id);
    }

    public gotToRecords() {        
        this.records.emit();
    }

    public verificationSateChanged($event) {
        this.stateLoading.set(true);
        const previousState = this.verificationState();
        const map = this.verificationStateMap();

        this.recordsService.updateRecordState(this.recordId, $event.value.stateId).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                this.verificationState.set({...previousState});
                
                return of(null);
            })
        ).subscribe(state => {
                if (state) {
                    this.verificationState.set({...map[state]})
                } 
                this.stateLoading.set(false);
                /** Update the activity tab to show change */
                this.activityTab.refresh();
                this.getRecordUpdatedAt();
            }
        );
    }

    private checkDeleteRecord($event) {
        // Dynamic dialog instead of alert with loading spinner while submitting?
        this.confirmationService.confirm({
            target: $event.target as EventTarget,
            message: 'Are you sure you want to delete this record?',
            header: 'Delete record',
            acceptIcon: 'none',
            acceptLabel: 'Delete',
            acceptButtonStyleClass: 'p-button-danger',
            rejectIcon: 'none',
            rejectLabel: 'Cancel',
            rejectButtonStyleClass: 'p-button-text',
            accept: () => {
                this.deleteRecord();
            },
            reject: () => {
                console.log('Changes not saved, continue editing');
            }
        });
    }

    private deleteRecord() {
        this.recordsService.deleteRecord(this.recordId).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {    
            if (!!res) {
                // Confirmation toast/message?
                /** Go back to records page */
                this.records.emit();
            }
        });
    }
}