import { Component, Input } from "@angular/core";
import { ApiService } from "src/app/core";
import { RequestLog, RequestLogSchema } from "src/app/core/models/requestlog.model";
import { PanelModule } from "primeng/panel";
import { JsonPipe } from "@angular/common";
@Component({
    selector: 'app-admin-request',
    standalone: true,
    templateUrl: './admin-request.component.html',
    imports: [
        PanelModule,
        JsonPipe
    ],
    styles: [`
    li{
        @apply flex gap-2;
    }
    label{
        @apply w-32;
    }
    `]
})
export class AdminRequestComponent {

    @Input() id: string;

    requestLog: RequestLog;

    constructor(private apiService: ApiService) { }

    ngOnInit() {
        const query = `query AAGetRequestLog($id: String!){
            requestLog(id: $id) {
                id
                appBuild
                appVersion
                body
                browser
                browserVersion
                deviceType
                method
                origin
                os
                path
                project{
                    id
                    name
                }
                query
                requestedAt
                responseCode
                responseStatus
                timeTaken
                user{
                    id
                    displayName
                }
            }
        }`;

        this.apiService.graphql<{ requestLog: RequestLog }>(query, { id: this.id }).subscribe(res => {
            this.requestLog = RequestLogSchema.parse(res.requestLog);
        });

    }
}