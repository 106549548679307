import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import { CollectionItem } from '../form.types';
import { CollectionInfoModalComponent } from '../collection-info-modal/collection-info-modal.component';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { debounce } from 'lodash';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { AvatarModule } from 'primeng/avatar';
import { InputIconModule } from 'primeng/inputicon';

type CollectionItemsResponse = {
    items: {
        id: number;
        key: string;
        value: string;
        mediaItems: {
            type: string;
            url: string;
            sort: number;
        }[];
        data: { [key: string]: string };
    }[],
    itemCount: number;
}

type CollectionsResponse = {
    project: {
        collections: CollectionItemsResponse[];
        parent: {
            collections: CollectionItemsResponse[];
        }
    };
}

type CollectionTableItem = {
    id: number;
    key: string;
    value: string;
    avatar: string;
    hasInfo: boolean;
}

const collectionsFragment = `fragment collectionFields on Collection{
    items(offset: $offset, limit: $limit, where: $where){
        id
        key
        value
        mediaItems{
            type
            url
            sort
        }
            data
    }
    itemCount(where: $where)
}`;

const query = `query collectionReadonly($projectId: Int!, $collectionId: Int!, $offset: Int!, $limit: Int!, $where: SequelizeJSON){
    project(id: $projectId){
        collections(where: {id: $collectionId }){ ...collectionFields }
        parent{
            collections(where: {id: $collectionId }){ ...collectionFields }
        }
    }
}${collectionsFragment}`;

@Component({
    selector: 'app-searchable-list-modal',
    templateUrl: 'searchable-list-modal.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        TableModule,
        AvatarModule,
        InputIconModule,
        InputTextModule
    ],
    providers: [
        DialogService
    ],
})

export class SearchableListModalComponent implements OnInit {

    private projectId: number;
    private collectionId: number;
    
    layout = signal<'list' | 'card'>('list');
    multiselect = signal<boolean>(false);
    showTooltip = signal<boolean>(false);
    
    selection = signal<string[]>([]);
    collection = signal<CollectionTableItem[]>([]);
    itemCount = signal(0);
    hasImages = signal<boolean>(false);
    
    loading = signal(false);
    firstLoad = signal(true);
    first = signal(0);
    rows = signal(10);
    searchTerm = signal<string>('');

    constructor(
        private dialogRef: DynamicDialogRef,
        private dialogConfig: DynamicDialogConfig,
        private dialogService: DialogService,
        private apiService: ApiService
    ) { }

    ngOnInit() {
        this.projectId = this.dialogConfig.data['projectId'];
        this.collectionId = this.dialogConfig.data['collectionId'];
        this.selection.set(this.dialogConfig.data['selection']);
        if (this.dialogConfig.data['multiselect']) {
            this.multiselect.set(this.dialogConfig.data['multiselect']); 
        }
        if (this.dialogConfig.data['showTooltip']) {
            this.showTooltip.set(this.dialogConfig.data['showTooltip']);    
        }
        
        this.loadCollection();
    }

    public loadCollection($event: TableLazyLoadEvent = null) {
        this.loading.set(true);

        if ($event) {   
            this.first.set($event.first); 
            this.rows.set($event.rows);
        }

        const where = {and: [
            { value:  { iLike: `%${this.searchTerm()}%` }}
        ]};

        const input = { 
            projectId: this.projectId, 
            collectionId: this.collectionId, 
            offset: this.first(), 
            limit: this.rows(), 
            where 
        };

        this.apiService.graphql<CollectionsResponse>(query, input).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {      
                const collection = res.project.collections?.[0] ?? res.project.parent?.collections?.[0];
                const { items, itemCount } = collection;
                const tableItems: CollectionTableItem[] = items.map(i => {
                    const images = i.mediaItems.filter(mi => mi.type.startsWith('image')).sort((a, b) => a.sort - b.sort);
                    const dataKeys = Object.keys(i.data || {});
                    return {
                        id: i.id,
                        key: i.key,
                        value: i.value,
                        avatar: images.length ? images[0].url : null,
                        hasInfo: dataKeys.length > 0 || i.mediaItems.length > 0
                    }
                });
                this.collection.set(tableItems);
                this.itemCount.set(itemCount);
    
                if (this.firstLoad()) {
                    this.firstLoad.set(false);
                    const hasImages: boolean = items.reduce((acc, item) => {
                        const images = item.mediaItems.filter(mi => mi.type.startsWith('image'));
                        if (images.length > 0) {
                            acc.push(images[0]);
                        }
                        return acc;
                    }, []).length > 0;
                    this.hasImages.set(hasImages);
                }
            }
            this.loading.set(false);
        });
    }


    public rowSelect(item: CollectionTableItem) {
        if (this.multiselect()) {
            const values = this.selection();
            const isSelected = !!values.find(i => i === item.key);
            let newValues: string[];
            if (isSelected) {
                newValues = values.filter(i => i !== item.key);
            } else {
                newValues = [...values, item.key];
            }
            this.selection.set(newValues);
        } else {
            this.selection.set([item.key]);
            this.closeWithSelection();
        }
    }

    public clearSearch($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.searchTerm.set('');
        this.handleSearch();
    }

    public debouncedSearch = debounce(this.handleSearch, 500);

    private handleSearch() {
        this.first.set(0);
        this.rows.set(10);

        this.loadCollection();
    }

    public toggleLayout() {
        this.layout() === 'list' ? this.layout.set('card') : this.layout.set('list');
    }

    public openCollectionInfoModal($event: Event, item: CollectionTableItem) {
        $event.preventDefault();
        $event.stopPropagation();
        this.dialogService.open(CollectionInfoModalComponent, {
            data: {
                projectId: this.projectId,
                collectionId: this.collectionId,
                itemId: item.id
            },
            header: 'Info',
            width: '420px',
            modal: true,
        });
    }

    public isSelected(item: CollectionItem): boolean {
        return !!this.selection().find(i => i === item.key);
    }

    public closeWithSelection() {
        const selection = this.multiselect() ? this.selection() : this.selection()[0];
        this.dialogRef.close(selection);
    }
}