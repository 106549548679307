import { Component, OnInit, Input, Output, EventEmitter, inject, signal, computed } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { TableModule, TableRowReorderEvent } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { ApiService } from "../../../core/services/api.service";
import { ProjectRemovePageComponent } from "./project-remove-page.component";
import { ProjectCreatePageComponent } from "./project-create-page.component";
import { ProjectPageSummary, ProjectPageSummarySchema } from './project-pages.model';
import { DialogService } from 'primeng/dynamicdialog';
import { catchError, filter, throwError } from 'rxjs';
const _ = require('lodash');

@Component({
    selector: 'app-project-pages',
    templateUrl: './project-pages.component.html',
    imports: [
        PanelModule,
        TableModule,
        ButtonModule,
        TooltipModule,
        ToastModule,
        ProjectRemovePageComponent,
        ProjectCreatePageComponent
    ],
    standalone: true,
    styles: [
        `:host{ @apply h-full w-full flex flex-col; }`
    ],
    providers: [
        MessageService,
        DialogService
    ]
})
export class ProjectPagesComponent implements OnInit {

    apiService = inject(ApiService);
    messageService = inject(MessageService);
    dialogService = inject(DialogService)

    pages = signal<ProjectPageSummary[]>([]);
    pagesLoaded = signal(false);

    pagesCount = computed(() => this.pages().length);
    showEmpty = computed(() => this.pagesCount() === 0 && this.pagesLoaded());

    @Input() project: any;
    @Input() role: any;
    @Input() parent: any;

    @Output() page: EventEmitter<{ pageId: number; isNewPage: boolean; }> = new EventEmitter();

    typeMap = { wysiwyg: 'Custom', collection: 'Collection' };

    pageId: number;

    loadPages() {
        const query = `query CoreoAALoadPages($projectId: Int!){
            project(id: $projectId){
                pages{
                    id
                    title
                    type
                    order
                    icon
                    auth
                    published
                }
            }
        }`;
        this.apiService.graphql<{ project: { pages: any } }>(query, { projectId: this.project.id }).subscribe((response) => {
            const pages = ProjectPageSummarySchema.array().parse(response.project.pages);
            pages.sort((pageA, pageB) => (pageA.order ?? 0) - (pageB.order ?? 0))
            this.pages.set(pages);
            this.pagesLoaded.set(true);
        });
    }

    ngOnInit() {
        try {
            this.loadPages();
        } catch (error) {
            console.error(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error has occurred' });
        }
    }

    pagesReordered(event: TableRowReorderEvent) {
        if (event.dragIndex === event.dropIndex) {
            return;
        }

        const pages = this.pages();
        for (let i = 0; i < pages.length; i++) {
            pages[i].order = i;
        }

        this.pages.set(pages);
        this.reorderProjectPages();
    }

    reorderProjectPages() {
        const pages = this.pages();
        const items = pages.map((_page, i) => {
            const name = i === 0 ? 'data' : `data_${i}`;
            return `${name}: updateProjectPage(input: $input${i}) {id, order}`;
        });

        const variables = {};
        pages.forEach((page, i) => variables[`input${i}`] = { order: page.order, id: page.id });

        const query = `mutation(${pages.map((_, i) => `$input${i}: ProjectPageUpdateInput!`).join(', ')}) { ${items.join(', ')} }`;

        this.apiService.graphql(query, variables).pipe(
            catchError(error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update order' });
                return throwError(() => new Error(error.error));
            })
        ).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Order Updated' });
        });
    }

    rowSelect(pageId: number) {
        this.page.emit({ pageId, isNewPage: false });
    }

    createPageModal(event) {
        event.stopPropagation();
        this.dialogService.open(ProjectCreatePageComponent, {
            header: 'Create Page',
            modal: true,
            contentStyle: {
                'padding': '0px',
            }
        }).onClose.pipe(filter(data => !!data)).subscribe((data) => {
            console.log(data);
            // this.page.emit({ pageId, isNewPage: true });
            this.createPage(data);
        });
    }

    createPage(data) {
        const query = `mutation CreateProjectPage($input: ProjectPageInput!) {
            createProjectPage(input: $input) { 
                id
                title
                type
                order
                icon
                auth
                published
             }
          }`;
        const { name, template: { type } } = data;
        const order = this.pagesCount();
        const input = {
            type,
            title: name,
            projectId: this.project.id,
            slug: _.kebabCase(name),
            order: order,
            published: false,
            auth: 'all',
            listed: true,
            icon: 'help',
            config: {}
        };
        this.apiService.graphql<{ createProjectPage: ProjectPageSummary }>(query, { input }).pipe(
            catchError(error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not create page' });
                return throwError(() => new Error(error.error));
            })
        ).subscribe((result) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Page Created' });
            const page = ProjectPageSummarySchema.parse(result.createProjectPage);
            this.pages.update(pages => [...pages, page]);
        });
    }

    deletePageModal(event, pageId: number) {
        event.stopPropagation();
        this.dialogService.open(ProjectRemovePageComponent, {
            header: 'Delete Page',
            modal: true,
            contentStyle: {
                'padding': '0px',
            }
        }).onClose.pipe(filter(removed => !!removed)).subscribe((isRemoved) => {
            this.deletePage(pageId);
        });
    }

    deletePage(id: number) {
        const query = `mutation CoreoAADeletePage($id: Int!){
            deleteProjectPage(input:{id: $id})
        }`;
        this.apiService.graphql(query, { id }).pipe(
            catchError(error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not remove page' });
                return throwError(() => new Error(error.error));
            })
        ).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Page Removed' });
            this.pages.update(pages => pages.filter(page => page.id !== id));
        });
    }
}