import * as Sentry from '@sentry/browser';
export const toastMiddleware = ($injector) => {
    return _store => next => action => {
        if (action.toast && typeof action.toast === 'string') {
            $injector.get('toastr').success(action.toast);
        } else if (action.toastError && typeof action.toastError === 'string') {
            $injector.get('toastr').error(action.toastError);
        } else if (action.toast) {
            const toastType = action.toast.type || 'success';
            const toastMsg = action.toast.message;
            const toastTitle = action.toast.title;
            $injector.get('toastr')[toastType](toastMsg, toastTitle);
            const actionError = action.err || action.error;
            if (actionError) {
                Sentry.captureException(actionError);
            }
        }
        return next(action);
    }
}
toastMiddleware.$inject = ['$injector'];

export const errorMiddleware = () => {
    return _store => next => action => {
        if (action.err) {
            console.warn('An Error Occured');
        }
        return next(action);
    }
}

export default toastMiddleware;