import { Pipe, PipeTransform } from "@angular/core";
import { formatDistanceToNow } from "date-fns/esm";

@Pipe({
    name: "timeAgo",
    standalone: true
})
export class TimeAgoPipe implements PipeTransform {
    transform(value: string, prefix = 'ago'): string {
        return `${formatDistanceToNow(new Date(value))} ${prefix}`;
    }
}