import { getProjectMapLayer, getProjectId, getProjectFormsContainingGeometry } from "../../store/selectors";

export default class ProjectMapLayerController {

    static $inject = ['$scope', '$ngRedux', '$stateParams', 'Mapbox', 'MapUtils', 'MapboxStyle'];
    constructor($scope, $ngRedux, $stateParams, Mapbox, MapUtils, MapboxStyle) {
        this.Mapbox = Mapbox;
        this.MapUtils = MapUtils;
        this.MapboxStyle = MapboxStyle;
        this.$scope = $scope;

        const state = $ngRedux.getState();
        this.projectId = getProjectId(state);
        this.forms = getProjectFormsContainingGeometry(state);
        this.layer = angular.copy(getProjectMapLayer($stateParams.layer_id)(state));

        this.layerId = 'layer-id';
        this.sourceId = 'source-id';

        this.layerTypes = MapboxStyle.getLayerTypes();

        this.sourceTypes = [{
            id: 'records',
            label: 'Coreo Records',
            styleable: true,
            fn: async () => {
                const url = await this.MapUtils.getMapUrl({
                    projectId: this.projectId,
                    query: {
                        surveyId: this.layer.sourceId
                    }
                });

                this.addLayer({
                    type: this.layer.type,
                    paint: this.layer.paint,
                    layout: this.layer.layout,
                    'source-layer': 'records',
                    source: {
                        type: 'vector',
                        tiles: [url],
                        tileSize: 512,
                        promoteId: {
                            records: 'id'
                        }
                    }
                })
            }
        }, {
            id: 'collections',
            label: 'Coreo Collections'
        },
        {
            id: 'image',
            label: 'Image',
            hasUrl: true,
            example: () => {
                this.layer.source = 'https://docs.mapbox.com/mapbox-gl-js/assets/radar.gif';
                const exampleImageBounds = [
                    [-80.425, 46.437],
                    [-71.516, 46.437],
                    [-71.516, 37.936],
                    [-80.425, 37.936]
                ];
                this.layer.layout = {
                    coordinates: exampleImageBounds
                }

                this.map.fitBounds([
                    exampleImageBounds[0],
                    exampleImageBounds[2]
                ]);
            },
            fn: () => {
                this.addLayer({
                    type: 'raster',
                    source: {
                        type: 'image',
                        url: this.layer.source,
                        coordinates: this.layer.layout.coordinates
                    }
                });
            }
        }, {
            id: 'geojson',
            label: 'GeoJSON',
            hasUrl: true,
            styleable: true,
            fn: () => {
                this.addLayer({
                    type: this.layer.type,
                    source: {
                        type: 'geojson',
                        data: this.layer.source
                    },
                    layout: this.layer.layout,
                    paint: this.layer.paint
                });
            },
            example: () => {
                this.layer.source = 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson';
            }
        }, {
            id: 'rasterTileset',
            label: 'Raster Tileset',
            hasUrl: true,
            example: () => {
                // this.layer.source = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
                this.layer.source = 'http://c.tile.stamen.com/watercolor/{z}/{x}/{y}.jpg';
            },
            fn: () => {
                this.addLayer({
                    type: 'raster',
                    source: {
                        type: 'raster',
                        tiles: [
                            this.layer.source
                        ],
                        tileSize: 256
                    }
                })
            }
        }, {
            id: 'vector',
            label: 'Vector Tileset'
        }, {
            id: 'mbtiles',
            label: 'MBTiles (offline)'
        }];

        this.sourceType = this.sourceTypes.find(o => o.id === this.layer.sourceType);
    }

    $onInit() {
        this.Mapbox.get().then(mapboxgl => {
            this.map = new mapboxgl.Map({
                container: 'project-maps-layer-map',
                style: 'mapbox://styles/mapbox/streets-v12',
                renderWorldCopies: false
            });
            this.map.on('load', () => {
                this.$scope.$watch('ctrl.form.$valid', () => {
                    if (this.form.$valid) {
                        // console.log('Updating layer?');
                        this.replaceLayer();
                    }
                });
            });
        });

    }

    getLayer() {
        return this.map.getLayer(this.layerId);
    }

    getSource() {
        return this.map.getSource(this.sourceId);
    }

    hasLayer() {
        return this.map && typeof this.getLayer() !== 'undefined';
    }

    hasSource() {
        return this.map && typeof this.getSource() !== 'undefined';
    }

    removeSource() {
        if (this.hasSource()) {
            this.map.removeSource(this.sourceId);
        }
    }

    removeLayer() {
        if (this.hasLayer()) {
            this.map.removeLayer(this.layerId);
        }
    }

    replaceLayer() {
        this.removeLayer();
        this.removeSource();

        if (this.sourceType.fn) {
            this.sourceType.fn();
        } else {
            console.log('Not implemented', this.sourceType, this.layer);
        }
    }

    updateLayer() {
        for (const l in this.layer.layout) {
            this.map.setLayoutProperty(this.layerId, l, this.layer.layout[l]);
        }
        for (const p in this.layer.paint) {
            this.map.setPaintProperty(this.layerId, p, this.layer.paint[p]);
        }
    }

    addLayer(layer) {
        console.log('Adding', layer);

        this.map.addSource(this.sourceId, layer.source);
        const { source, ...layerRest } = layer;
        this.map.addLayer({
            id: this.layerId,
            source: this.sourceId,
            ...layerRest
        });
    }

    sourceTypeChanged() {
        this.sourceType = this.sourceTypes.find(o => o.id === this.layer.sourceType);
        this.layer.source = null;
        if (this.sourceType.styleable) {
            this.layer.type = null;
            this.layer.layout = {};
            this.layer.paint = {};
        }
        // this.layer.paint = this.MapboxStyle.getDefaults('paint',this.)
        // this.layer.layout = {};
        this.removeLayer();
    }

    layerTypeChanged() {
        this.layer.paint = this.MapboxStyle.getDefaults('paint', this.layer.type);
        this.layer.layout = this.MapboxStyle.getDefaults('layout', this.layer.type);
        this.replaceLayer();
    }

    dataSourceChanged() {
        this.replaceLayer();
    }

    loadExample() {
        if (this.sourceType && this.sourceType.example) {
            this.sourceType.example();
        }
    }
}