import { getProjectCollection } from '../../../store/selectors';

export const ProjectCollectionSelectComponent = {
    template: require('!raw-loader!./project-collection-select.component.html').default,
    controllerAs: 'ctrl',
    require: {
        ngModelCtrl: 'ngModel'
    },
    bindings: {
        collectionId: '<',
        ngModel: '<',
        multiple: '<'
    },
    controller: class ProjectCollectionSelectCompoonent {
        static $inject = ['$ngRedux'];
        constructor($ngRedux) {
            this.$ngRedux = $ngRedux;
        }

        $onInit() {
            this.collection = getProjectCollection(this.collectionId)(this.$ngRedux.getState());
            console.log(this.collection)
        }

        $onChanges(changes) {

            if (changes.collectionId && changes.collectionId.currentValue) {
                this.collection = getProjectCollection(changes.collectionId.currentValue)(this.$ngRedux.getState());
            }
        };

        ngModelChange() {
            this.ngModelCtrl.$setViewValue(this.ngModel);
        };
    }
};