import { createSelector } from "reselect";

export const getFormId = (state) => state.id;
export const getFormAttributes = (state) => state.attributes;
export const getFormDeleted = (state) => state.deleted;
export const getFormDirty = (state) => state.dirty;
export const getFormSelectedAttributeId = (state) => state.selectedAttributeId;
export const getFormQuestionState = (state) => state.questionState;
export const getFormErrors = (state) => state.errors;

export const getFormSelectedAttribute = createSelector(
    getFormAttributes,
    getFormSelectedAttributeId,
    (attributes, id) => attributes.find(a => a.id === id)
);

export const getFormSelectedAttributeErrors = createSelector(getFormErrors, getFormSelectedAttributeId, (errors, attributeId) => errors[attributeId] || {});

export const getFormAttributesWithSections = createSelector(getFormAttributes, (attributes) => {
    let sectionIdx = 0;
    const result = attributes.reduce((acc, attribute, idx) => {

        if (attribute.sectionIdx !== sectionIdx) {
            acc.push({
                type: 'divider',
                id: ['section', idx].join('_'),
                sectionIdx: ++sectionIdx
            });
        }
        acc.push({
            ...attribute,
            sectionIdx
        });
        return acc;
    }, []);
    return result;
});