import { Injectable } from "@angular/core";
import { JwtService } from "../services";
import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class HttpTokenInterceptor implements HttpInterceptor {
    constructor(private jwtService: JwtService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        const token = this.jwtService.getToken();
        let headersConfig = {};
        if (token) {
            headersConfig['Authorization'] = `JWT ${token}`;
        }

        if (req.body instanceof FormData) {
            const formDataRequest = req.clone({ setHeaders: headersConfig });
            return next.handle(formDataRequest);
        }

        headersConfig['Content-Type'] = 'application/json';
        headersConfig['Accept'] = 'application/json';

        const request = req.clone({ setHeaders: headersConfig });
        return next.handle(request);
    }
}