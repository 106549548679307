@if(stage() === 1){
<form id="projectForm" class="h-full w-full flex flex-col flex-grow overflow-hidden" [formGroup]="form">
    <div class="space-y-4 p-5">
        <div class="flex gap-5">
            @if(template()?.icon; as icon){
            <img class="h-24 w-24 rounded-full" width="96" height="96" [ngSrc]="icon" alt="template-icon">
            }
            <div class="w-full">
                <h2 class="font-semibold text-base mb-5">{{ template()?.name || '' }} Project Details</h2>
                <div class="flex gap-2 flex-col">
                    <label>Project Name</label>
                    <input pInputText pAutoFocus type="text" formControlName="name"
                        placeholder="Give your project a name" [autofocus]="true" />
                </div>
            </div>
        </div>

        <div class="flex gap-2 flex-col">
            <label>Project Description</label>
            <textarea rows="5" pInputTextarea formControlName="description"></textarea>
        </div>
    </div>
</form>
}

@if(stage() === 2){
<div class="w-full h-full overflow-hidden flex flex-col">
    <div class="p-5 flex items-start flex-grow-0">
        <button (click)="back()"><i class="fas fa-chevron-left text-[#878F97] text-2xl pr-3"></i></button>
        <div>
            <h2 class="font-semibold text-base">Choose members</h2>
            <p class="text-xs font-normal text-[#878F97]">The project will only be visible to aded members. The
                creator
                of the project will be automatically be
                assigned as admin, however this can be changed. There can be multiple admins.</p>
        </div>
    </div>

    <div class="px-4">
        <div class="border-b border-[#D3DBE3] gap-4 pt-1 pb-3 flex items-center">
            <div class="relative">
                <p-checkbox inputId="selectAll" [ngModel]="isSelectAll()" [binary]="true"
                    (ngModelChange)="selectAll()" />
                @if(isSelectAll() === null){
                <label for="selectAll"
                    class="absolute block h-0.5 w-2.5 bg-primary rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-event-none"></label>
                }
            </div>
            <span class="flex-grow flex items-center cursor-pointer"
                (click)="sortByName(sortType() === 'asc' ? 'desc' : 'asc')">Name
                <i class="pi ml-3 pi-sort-amount-{{sortType() === 'desc' ? 'up-alt' : 'down'}}"></i>
            </span>
            <span class="block min-w-36">Project Role</span>
        </div>
    </div>
    <ul class="overflow-y-scroll px-4 flex-grow pt-[10px]">
        @for(member of memberships(); track member.userId;){
        <li class="flex items-center gap-4 py-1">
            <p-checkbox [ngModel]="member.selected" [binary]="true" (ngModelChange)="selectMember(member)" />
            <input [value]="member.name" readonly class="flex-grow" />
            @if(member.selected){
            <p-dropdown [options]="roles" appendTo="body" optionLabel="name" optionValue="value"
                class="min-w-36 ml-auto" styleClass="w-full" [ngModel]="member.role"
                (ngModelChange)="updateMember(member,$event)" />
            }
        </li>
        }
    </ul>
</div>
}

<div class="p-dialog-footer mt-auto flex gap-3 justify-end flex-grow-0">
    @if(!hasAdmin() && memberships().length && stage() === 2){
    <div>
        <p class="text-left text-[10px] text-[#CC5642] px-2.5 py-2 rounded-lg bg-[#CC5642] bg-opacity-10">There are no
            admins assigned
            to this
            project. Only Organisation Admins will have editing privileges. To
            assign an admin, please select Admin next to a member's name.</p>
        <!-- <i class="fas fa-exclamation-triangle mr-1 text-[#CC5642]"></i> -->
    </div>
    }
    <button pButton class="p-button-lg flex-shrink-0 h-full" type="submit" form="projectForm"
        [disabled]="!form.valid || (!hasAdmin() && stage() === 2)" (click)="createProject($event)">{{stage() === 1 ?
        'Next' : 'Create Project'}}</button>
</div>