<div class="job-container" [ngClass]="status()">
    <div class="flex items-center gap-2">
        <span class="w-2 h-2 rounded-full job-status"></span>
        <h3 class="text-[#444] text-sm">{{title()}}</h3>
    </div>
    <small>{{job().createdAt | timeAgo}}</small>
    @if(job().message){
    <p class="job-message">
        <i class="fas fa-exclamation-triangle job-message-warning"></i>
        {{jobMessage()}}
    </p>
    }
    @if(jobStatus()){
    <p class="job-status">{{jobStatus()}}<i class="fas fa-spinner fa-spin"></i></p>
    }
    @if(urlAction() && !isJobErrorMessage()){
    <button (click)="action()"
        class="bg-primary text-white rounded p-2 mt-2 text-xs font-semibold">{{urlAction()}}</button>
    }
    @if(showClear()){
    <button class="absolute right-2 top-2" (click)="clear()">
        <i class="fas fa-close text-[#878F97]"></i>
    </button>
    }
</div>