import { Component, EventEmitter, Output, computed, inject, input } from '@angular/core';
import { TwicPicPipe } from 'src/app/shared/pipes/twicpic.pipe';

@Component({
    selector: 'app-form-image',
    templateUrl: 'form-image.component.html',
    standalone: true,
    providers: [
        TwicPicPipe
    ]
})

export class FormImageComponent {

    twicPicPipe = inject(TwicPicPipe);

    imageSrc = input.required<string>();

    @Output() remove: EventEmitter<void> = new EventEmitter();

    src = computed(() => {
        const imageSrc = this.imageSrc();
        if (imageSrc.startsWith('https://coreo.s3')) {
            return this.twicPicPipe.transform(imageSrc, 'resize=320');
        } else {
            return imageSrc;
        }
    });
}