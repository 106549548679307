<p-dialog [modal]="true" [header]="'Create Hook ' + (hookTypeName() ? ' - ' + hookTypeName() : '')" [(visible)]="visible" [draggable]="false" [resizable]="false" [style]="{minWidth: '25vw', height: 'auto'}" (onHide)="reset()">
    <div class="flex flex-col gap-2 mb-3">
    @if (step() === 1) {
        <h2 class="font-bold text-lg pb-4">Select Type</h2>
        <div class="flex flex-row justify-center items-center gap-4">
            <div *ngFor="let type of hookTypes" 
            class="w-40 h-52 p-4 flex flex-col items-center justify-between bg-white shadow 
            rounded cursor-pointer transform transition duration-300 hover:scale-105 hover:bg-blue-100" (click)="selectType(type)">
                <div class="w-full min-h-32 flex items-center justify-center">
                    <img [src]="type.icon" alt="{{ type.name }}" class="max-w-full max-h-full object-contain" />
                </div>
                <div class="w-full text-center py-4">
                    <h4 class="text-lg truncate font-bold">{{ type.name }}</h4>
                </div>
            </div>
        </div>
    } @else if (step() === 2) {
        <form [formGroup]="hookForm">
            <div class="flex flex-col gap-2 mb-3">
                <label for="name">Action</label>
                <p-dropdown [options]="hookOptions()" optionLabel="actionLabel" formControlName="action" optionValue="action" styleClass="w-full"></p-dropdown>
            </div>
            <div class="flex flex-col gap-2 mb-3">
                <label for="url">Target URL</label>
                <input pInputText formControlName="url" />
            </div>
            <div class="flex flex-col gap-2 mb-3">
                <label for="surveys">Surveys</label>
                <div *ngFor="let survey of surveys;" class="items-center justify-between">
                    <p-checkbox [(ngModel)]="survey.selected" [binary]="true" inputId="survey_{{survey.id}}" [ngModelOptions]="{standalone: true}" class="mr-2"></p-checkbox>
                    <label class="!text-base" for="survey_{{survey.id}}">{{ survey.name }}</label>
                </div>
            </div>
            <div class="flex flex-col gap-2 mb-3">
                <label for="events">Events</label>
                <div *ngFor="let event of events;" class="items-center justify-between">
                    <p-checkbox [(ngModel)]="event.selected" [binary]="true"inputId="event_{{event.event}}" class="mr-2" [ngModelOptions]="{standalone: true}"></p-checkbox>
                    <label class="!text-base" for="event_{{event.event}}">{{ event.eventLabel }}</label>
                </div>
            </div>
            <div class="flex flex-col gap-2 mb-3">
                <label for="settings">Settings</label>
                <div class="flex items-center">
                    <p-checkbox formControlName="allowSubmitOnFail" class="mr-2" [binary]="true" inputId="allowSubmitOnFail"></p-checkbox>
                    <label class="!text-base" for="allowSubmitOnFail">Allow submit on hook failure</label>
                </div>
            </div>
        </form>
    }
    </div>
    <ng-template pTemplate="footer">
        @if (hook()?.id) {
            <p-button label="Update" (click)="submit()" [disabled]="!hookForm.valid"></p-button>
        } @else {
            <p-button label="Create" (click)="submit()" [disabled]="!hookForm.valid"></p-button>
        }
    </ng-template>
</p-dialog>
<p-confirmDialog></p-confirmDialog>
<div class="py-10 px-4 sm:px-10 max-w-screen-xl mx-auto">
    <p-toast position="bottom-center"></p-toast>
    <p-panel class="block">
        <ng-template pTemplate="header">
            <div class="flex items-center justify-between">
                <span class="p-panel-title">Hooks</span>
                @if (role === 'admin') {
                    <p-button label="Create Hook" class="order-2" (click)="open()"></p-button>
                }
            </div>
        </ng-template>
        <ng-template pTemplate="body">
            @if (hooks.length <= 0) {
                <div class="flex justify-center items-center pb-4">
                    <span class="text-center text-base">No hooks found</span>
                </div>
            } @else {
                <div *ngFor="let hook of hooks;" class="flex mb-4 p-2 items-center justify-between items-end">
                    <div class="px-4">
                        <img class="flex" [src]="getIcon(hook.type)" alt="hookIcon" width="128" height="128" />
                    </div>
                    <div class="flex flex-col gap-2 mb-3 w-full">
                        <span class="capitalize text-lg">{{ hook.type }} - {{hook.action }}</span>
                        <span class="text-xs">{{ hook.config.url }}</span>
                    </div>
                    @if(role === 'admin') {
                        <div class="flex flex-col items-end justify-between gap-2">
                            <p-button label="Edit" severity="primary" (click)="editHook($event, hook)" styleClass="w-28"></p-button>
                            <p-button label="Delete" severity="danger" (click)="deleteHook($event, hook)" styleClass="w-28"></p-button>
                        </div>
                    }
                </div>
            }
        </ng-template>
    </p-panel>
</div>