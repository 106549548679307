import { getProjectAttributes, getProjectForms, getProjectId, getProjectRole } from '../../../store/selectors';
import { jobFieldsFragment } from '../../../app/core/services/jobs.service';
import { filter, tap } from 'rxjs';
export default class ProjectRecordsImportController {
    static $inject = ['$rootScope', '$scope', '$interval', '$mdDialog', '$timeout', '$ngRedux', 'CoreoAPI', 'GeoJSON', '$http', 'jobsService'];
    constructor($rootScope, $scope, $interval, $mdDialog, $timeout, $ngRedux, CoreoAPI, GeoJSON, $http, jobsService) {
        this.$rootScope = $rootScope;
        this.$http = $http;
        this.$mdDialog = $mdDialog;
        this.$timeout = $timeout;
        this.$interval = $interval;
        this.$scope = $scope;
        this.CoreoAPI = CoreoAPI;
        this.GeoJSON = GeoJSON;
        this.jobsService = jobsService;


        this.ImportGeometryType = {
            NONE: 'none',
            LATLNG: 'latlng',
            WKT: 'wkt'
        }
        this.ImportEPSGCode = {
            4326: 4326,
            27700: 27700,
            3857: 3857
        }
        this.ImportGeometryFileFormat = {
            CSV: 'CSV',
            GeoJSON: 'GeoJSON',
            Shapefile: 'Shapefile',
        }

        $scope.$on('$destroy', $ngRedux.connect((state) => {
            return {
                surveys: getProjectForms(state),
                projectId: getProjectId(state),
                attributes: angular.copy(getProjectAttributes(state)),
                role: getProjectRole(state)
            }
        }, null)(this));

        this.format = this.ImportGeometryFileFormat.CSV;
        this.step = 1;
        this.stepReady = false;
        this.readFile = this.readFile.bind(this);
        this.fileError = null;
        this.map = {}; // data structure for import request submissions , { col : path }
        this.geometryType = this.ImportGeometryType.NONE;
        this.epsgCode = null;
        this.pairs = []; // data structure for UI {attribute, col}[]

    }

    readFile(e) {
        this.$timeout(async () => {
            const file = (e.srcElement || e.target).files[0];
            if (file) {
                try {
                    await this.parseFile(file);
                    this.fileError = null;
                } catch (e) {
                    this.file = null;
                    this.columns = null;
                    this.fileError = e;
                }
            } else {
                this.columns = null;
            }
            this.updateReady();
            this.$scope.$apply();
        }, 0);
    }

    async parseFile(file) {
        this.file = file;
        switch (this.format) {
            case this.ImportGeometryFileFormat.CSV: {
                const readCSVColumns = async file => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = (_e) => {
                            try {
                                const text = reader.result;
                                const firstLine = text.replace('\r', '').split('\n').shift();
                                return resolve(firstLine.split(',').map(s => s.trim()));
                            } catch (e) {
                                return reject(new Error('Unable to read columns'))
                            }

                        };
                        reader.readAsText(file, 'UTF-8');
                    })
                }
                this.columns = await readCSVColumns(file);
                break;
            }
            // Shapefile ZIP
            case this.ImportGeometryFileFormat.Shapefile: {
                const formData = new FormData();
                formData.append('file', file);
                return this.$http({
                    method: 'POST',
                    url: '/gis/shapefile-info',
                    headers: {
                        'Content-Type': undefined
                    },
                    data: formData
                }).then(response => {
                    const shapefiles = Object.keys(response.data);
                    if (shapefiles.length === 0) {
                        throw new Error('No shapefiles found in provided zip')
                    }
                    if (shapefiles.length > 1) {
                        throw new Error('More than one shapefile found in provided zip')
                    }
                    this.columns = response.data[shapefiles[0]].fields.map(a => a.name);
                });
            }
            case this.ImportGeometryFileFormat.GeoJSON: {
                try {
                    await this.GeoJSON.readGeoJSONFile(file).then((result) => {
                        this.columns = result.geoJsonPaths;
                    });
                } catch (_e) {
                    throw new Error('Unable to read GeoJSON File');
                }
                break;
            }
            default: {
                console.warn('Unknown file type!', file);
            }
        }
    }
    import() {
        const input = {
            surveyId: this.survey.id,
            projectId: this.projectId,
            geometryWKTColumn: this.geometryWKTColumn,
            geometryLatColumn: this.geometryLatColumn,
            geometryLngColumn: this.geometryLngColumn,
            map: this.map,
            format: this.format,
            epsgCode: this.epsgCode
        };

        var mutation = `mutation($input: RecordsImportInput!){
            result: importRecords(input: $input){
                ...jobFields
            }
        }${jobFieldsFragment}`;
        this.stepReady = false;
        this.step++;

        return this.CoreoAPI.mutation(mutation, {
            file: this.file
        }, {
            variables: {
                input
            }
        }).then((job) => {
            this.jobsService.addJob(job).pipe(
                filter(job => job.status === 'complete'),
                tap(job => {
                    this.step = 5;
                })).subscribe();
            this.step = 4;
        }).catch(e => {
            this.error = e;
            console.log('import error', e);
            this.step = -1;
        })
    }

    parseValidationErrorsMessage(msg) {
        try {
            const VALIDATION_ERROR_SEPERATOR = ',\n';
            const VALIDATION_ERRORS_HEADER = 'Validation errors:\n';
            const [, errorString] = msg.split(VALIDATION_ERRORS_HEADER);
            return errorString.split(VALIDATION_ERROR_SEPERATOR).map(e => e.trim());
        } catch (_e) {
            return [msg];
        }
    }

    next() {
        if (this.step === 3) {
            this.import();
        } else {
            if (this.step === 1) {


                const attributesCopy = this.attributes.slice(0, this.attributes.length); //shallow copy

                if (this.role === 'admin') {
                    // pseudo attribute to allow mapping of userId column
                    attributesCopy.push({
                        id: 0,
                        path: 'userId',
                        label: 'Coreo User ID',
                        required: false,
                        questionType: null,
                        surveyId: this.survey.id
                    })
                }
                this.surveyAttributes = attributesCopy.filter(a => a.surveyId === this.survey.id);
                this.mappableAttributes = this.surveyAttributes.filter(a => !!a.path);

                this.pairs = this.mappableAttributes.filter(a => a.required).map(attribute => ({ attribute, column: null }));
                this.pairs.map(p => this.removeMappableAttribute(p.attribute.id));

                // Try to auto-match
                for (let i = 0; i < this.columns.length; i++) {
                    const matchedAttribute = this.mappableAttributes.find(a => a.path.toLowerCase() === this.columns[i].toLowerCase());
                    if (typeof matchedAttribute !== 'undefined') {
                        const pair = this.pairs.find(p => p.attribute.id === matchedAttribute.id);
                        if (!pair) {
                            this.handleNewPair(matchedAttribute);
                        }
                        const pairIdx = this.pairs.findIndex(p => p.attribute.id === matchedAttribute.id);
                        this.selectPairColumn(pairIdx, this.columns[i]);
                    }
                }

                this.geometryAttribute = this.surveyAttributes.find(a => a.questionType === 'geometry');
                this.surveyHasGeometry = !!this.geometryAttribute || this.survey.bespoke;
                this.surveyHasPointGeometry = this.surveyHasGeometry ?
                    this.geometryAttribute.config && this.geometryAttribute.config.types && this.geometryAttribute.config.types.includes('point') || this.survey.bespoke : false

                if (this.surveyHasGeometry && this.geometryAttribute.required) {
                    this.geometryType = this.surveyHasPointGeometry ? this.ImportGeometryType.LATLNG : this.ImportGeometryType.WKT;
                }
                if (!this.surveyHasGeometry || this.format !== this.ImportGeometryFileFormat.CSV) {
                    this.step++; //skip geometry column mapping step if not CSV
                }
            }
            this.step++;
            this.updateReady();
        }
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    done() {
        this.$mdDialog.hide(this.survey.id);
    }

    searchAttributesText(searchText) {
        if (!searchText) {
            return this.mappableAttributes;
        }
        return this.mappableAttributes.filter(a => a.label.toLowerCase().includes(searchText.toLowerCase()));
    }

    handleNewPair(attribute) {
        if (!attribute) {
            return;
        }
        this.pairs.push({
            attribute,
            column: null
        });
        this.removeMappableAttribute(attribute.id);
        this.newPairSelect = undefined;
        this.updateReady();
    }

    removeMappableAttribute(id) {
        const idx = this.mappableAttributes.findIndex(a => a.id === id);
        if (idx === -1) {
            throw new Error(`Tried to remove non-existant mappable attribute with id ${id}`)
        }
        this.mappableAttributes = [
            ...this.mappableAttributes.slice(0, idx),
            ...this.mappableAttributes.slice(idx + 1)
        ]
    }

    searchColumnsText(searchText) {
        if (!searchText) {
            return this.columns;
        }
        return this.columns.filter(c => c.toLowerCase().includes(searchText.toLowerCase()));
    }

    selectPairColumn(idx, column) {
        this.pairs[idx].column = column;
        this.updateReady();
    }

    removePair(idx) {
        if ((idx > this.pairs.length - 1) || (idx < 0)) {
            throw new Error('Tried to remove pair with invalid index');
        }
        const attribute = this.pairs[idx].attribute;
        this.pairs = [
            ...this.pairs.slice(0, idx),
            ...this.pairs.slice(idx + 1)
        ];
        this.mappableAttributes.push(attribute);
        this.updateReady();
    }

    resetGeometryColumns() {
        this.geometryLatColumn = undefined;
        this.geometryLngColumn = undefined;
        this.geometryWKTColumn = undefined;
        this.epsgCode = null;
        this.updateReady();
    }

    updateReady() {
        switch (this.step) {
            case 1: {
                this.stepReady = this.file && this.survey && this.columns && !this.fileError;
                break;
            }
            case 2: {
                this.stepReady = (this.geometryType === this.ImportGeometryType.NONE) || (this.geometryType && (this.geometryWKTColumn || (this.geometryLatColumn && this.geometryLngColumn)));
                break;
            }
            case 3: {
                this.stepReady = !this.pairs.some(p => !p.column);
                this.map = this.pairs.reduce((acc, pair) => {
                    acc[pair.attribute.path] = pair.column;
                    return acc;
                }, {});
                break;
            }
        }
    }
}