<p-table
    [value]="attributes"
    [tableStyle]="{'min-width': '380px'}"
    (onRowReorder)="reorderRows($event)" 
    [loading]="loading"
    [scrollable]="true"
    scrollHeight="flex"
    styleClass="bg-white"
>
    <ng-template pTemplate="caption">
        <div class="coreo-list-header gap-4">
            <span class="flex items-center">
                <h2 class="whitespace-nowrap">Attributes</h2>
                <i 
                    class="fa-solid fa-circle-info text-primary ml-2 cursor-pointer"
                    pTooltip="This is a description that can be added to each of the collection items. If set to visible these descriptions will be shown as part of the collection item’s description or tool tip in the app. Numerical attributes can also be used to sort items in your collection."
                    tooltipPosition="right"
                ></i>
            </span>
            <p-button size="large" (click)="newAttribute()" [outlined]="true">
                <i class="fas fa-plus"></i>New attribute
            </p-button>
        </div>
    </ng-template>

    <ng-template pTemplate="body" let-attribute let-index="rowIndex">
        <tr [pReorderableRow]="index" class="table-item table-item--thin">
            <div class="table-item__inner justify-between">
                <p>{{ attribute.label }}</p>
                <div class="table-item__buttons">
                    <button class="table-item__button" (click)="toggleVisibility(attribute)">
                        <i class="fa-solid fa-eye-slash" [ngClass]="{'text-grey-50': !attribute.visible, 'text-grey-30 hover:text-grey-40': attribute.visible}"></i>
                    </button>
                    <button class="table-item__button" (click)="editAttribute(attribute)">
                        <i class="fa-solid fa-pen text-primary"></i>
                    </button>
                    <i class="fa-solid fa-grip-vertical" pReorderableRowHandle></i>
                </div>
            </div>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <div class="absolute h-full w-full flex flex-col items-center justify-center">
            <i class="fa-solid fa-layer-group text-5xl text-primary-100 mb-5"></i>
            <p class="text-base font-semibold">You don't have attributes yet</p>
            <p-button size="large" (click)="newAttribute()" [outlined]="true" class="mt-10">
                <i class="fas fa-plus mr-1"></i>New attribute
            </p-button>
        </div>
    </ng-template>
</p-table>