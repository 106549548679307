function blockInfoFilter(BLOCK_TYPES) {
    return function (type, field) {
        var item = {
            name: "Unknown",
            icon: "question"
        };
        if (BLOCK_TYPES[type]) {
            item = BLOCK_TYPES[type];
        }

        if (item[field] !== undefined) {
            return item[field];
        }
        return null;
    };
}
blockInfoFilter.$inject = ['BLOCK_TYPES'];

export default blockInfoFilter;