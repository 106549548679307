<div [formGroup]="form">
    @if (question.questionType === 'long') {
        <textarea pInputTextarea [autoResize]="true" rows="4" spellcheck autoCapitalize="on" [formControlName]="question.path" [id]="question.path" class="w-full"></textarea>
    }
    @else {
        @if (question.config?.autocomplete) {
            <span class="p-input-icon-right w-full">
                <i (click)="openAutocomplete()" class="fa-solid fa-magnifying-glass hover:cursor-pointer"></i>
                <input pInputText type="text" [formControlName]="question.path" [id]="question.path" class="w-full" />
            </span>
        }
        @else {
            <input pInputText type="text" [formControlName]="question.path" [id]="question.path" class="w-full" />
        }
    }
</div>