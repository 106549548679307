import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-code',
    template: '<ngx-monaco-editor style="height: 100%" [options]="editorOptions" [(ngModel)]="code" (onInit)="onInit($event)"></ngx-monaco-editor>'
})
export class CodeComponent {

    @Input() code: string;
    @Output() editor = new EventEmitter<any>();

    editorOptions = {
        theme: 'vs-dark',
        language: 'typescript',
        minimap: { enabled: false },
        wordWrap: "on",
        renderLineHighlight: 'none',
        automaticLayout: true
    };

    onInit(editor) {
        this.editor.emit(editor);
    }
}