<li class="flex items-start gap-3 py-3">
    @if (log.user.imageUrl) {
        <p-avatar [image]="log.user.imageUrl" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
    }
    @else {
        <p-avatar [label]="log.user.displayName.charAt(0).toUpperCase()" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
    }
    <div>
        <p class="text-sm text-text-primary mb-2"><span class="font-semibold">{{ log.user.displayName }}</span> {{ operationString }} <span class="font-semibold">{{ dateString }}</span></p>
        <!-- Hide review changes for now -->
        <!-- @if (log.operation === 'update' && log.diff.length > 0) {
            <p-button (click)="reviewChanges.emit()" label="Review changes" [outlined]="true" size="small"></p-button>
        } -->
        @if (log.operation === 'verification') {
            <div class="h-6 w-max px-2 flex items-center rounded-md border text-[10px] font-semibold uppercase" [style.color]="badgeColor" [style.background-color]="badgeBackgroundColor" [style.border-color]="badgeColor">
                <p>{{ stateString }}</p>
            </div>
        }
        <p class="text-[11px] font-normal text-text-secondary mt-2">{{ log.createdAt | dateToNow }}</p>
    </div>
</li>