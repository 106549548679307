import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastModule } from "primeng/toast";
import { PanelModule } from 'primeng/panel';
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ApiService } from "../../../core/services/api.service";
import { z } from 'zod';

export const ProjectAdminSchema = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    androidAppUrl: z.string().nullable(),
    organisation: z.object({
        id: z.number(),
        slug: z.string(),
        name: z.string()
    })
});

interface ProjectAdminParsed extends z.infer<typeof ProjectAdminSchema> { }

@Component({
    selector: 'app-admin-project',
    templateUrl: './admin-project.component.html',
    imports: [
        ReactiveFormsModule,
        ToastModule,
        PanelModule,
        ButtonModule,
        InputTextModule,
        DropdownModule
    ],
    standalone: true,
    styles: [
        `:host{ @apply block h-full w-full overflow-scroll p-8;}`
    ],
    providers: [MessageService]
})
export class AdminProjectComponent implements OnInit {

    @Input() projectId: number;

    @Output() dashboard: EventEmitter<{ orgSlug: string; projectSlug: string; }> = new EventEmitter();
    @Output() organisation: EventEmitter<number> = new EventEmitter();

    projectForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        slug: new FormControl('', [Validators.required]),
        version: new FormControl<{ label: string; value: string; } | null>(null),
    });

    project: ProjectAdminParsed | null = null;
    projectOrganisation: ProjectAdminParsed['organisation'] | null = null;
    projectVersions: { label: string; value: string; }[] = [
        { label: 'UKHab v3.1', value: 'ukhab3.1' },
        { label: 'UKHab Statuatory', value: 'ukhab4.0' },
    ];

    private readonly getProjectQuery: string = `query getAdminProject($id: Int!) {
        project(id: $id) { id, name, slug, androidAppUrl, organisation { id, name, slug } }
    }`;

    private readonly updateProjectQuery: string = `mutation AAUpdateProject($input: ProjectUpdateInput!) {
        updateProject(input: $input) { id }
    }`;

    constructor(
        private apiService: ApiService,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        this.initProjectData();
    }


    initProjectData() {
        this.apiService.graphql<{ project: ProjectAdminParsed }>(this.getProjectQuery, { id: this.projectId }).subscribe(response => {
            const parsedResponse = ProjectAdminSchema.parse(response.project);
            const { name, slug, androidAppUrl, organisation } = this.project = parsedResponse;
            const version = this.projectVersions.find(version => androidAppUrl === version.value) || null;
            this.projectForm.setValue({ name, slug, version });
            this.projectOrganisation = organisation;
        });
    }

    onSubmit() {
        const { name, slug, version } = this.projectForm.value;
        this.apiService.graphql(this.updateProjectQuery, { input: { id: this.project!.id, androidAppUrl: version?.value ?? null, name, slug } }).subscribe(() => {
            this.initProjectData();
            this.projectForm.markAsPristine();
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Project updated' });
        },
            (error) => {
                console.error(error);
                this.projectForm.markAsPristine();
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Project failed to update' });
            });
    }

    goToDashboard() {
        this.dashboard.emit({
            orgSlug: this.project!.organisation.slug,
            projectSlug: this.project!.slug,
        });
    }

    goToOrganisation() {
        this.organisation.emit(this.project!.organisation!.id);
    }
}