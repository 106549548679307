<div (click)="markAsRead()">
    @if (!isRead()) {
        <div class="w-max px-3 py-1 bg-primary-10 rounded-full mb-3">
            <p class="block text-text-primary text-[10px] font-semibold"><i class="fa fa-bell h-3"></i> New message</p>
        </div>
    }

    <div class="flex items-start gap-3 mb-4">
        @if (message().user.imageUrl) {
            <p-avatar [image]="message().user.imageUrl" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
        }
        @else {
            <p-avatar [label]="message().user.displayName.charAt(0).toUpperCase()" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
        }
        <div>
            <p class="text-sm text-text-primary font-semibold mb-2">{{ message().user.displayName }}</p>
            <p class="text-xs font-normal text-text-secondary">{{ message().createdAt | date:'short' }}</p>
        </div>
    </div>
    <p class="text-sm text-text-primary">{{ message().content }}</p>
    @for (attachment of message().attachments; track attachment; let index = $index) {
        <a class="" target="_blank" [href]="attachment">
            <i class="fa fa-paperclip text-sm text-text-secondary"></i> Attachment {{index + 1}}
        </a>
    }
</div>