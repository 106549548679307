import { Component, inject } from "@angular/core";
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
    templateUrl: './project-remove-page.component.html',
    selector: 'app-project-remove-page',
    standalone: true,
    imports: [
        ButtonModule,
    ],
    providers: [MessageService]
})
export class ProjectRemovePageComponent {

    private ref = inject(DynamicDialogRef);
    deletePage() {
        this.close(true);
    }

    close(remove = false) {
        this.ref.close(remove);
    }
}
