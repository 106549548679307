import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule, downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';

import { CoreModule } from './core/core.module';

import { AppComponent } from './app.component';
import { ProfileComponent } from './features/profile/profile.component';
import { ProjectApiKeysComponent } from './features/projects/project-api-keys/project-api-keys.component';
import { ProjectCollectionsComponent } from './features/projects/project-collections/project-collections.component';
import { ProjectPagesComponent } from './features/projects/project-pages/project-pages.component';
import { ProjectSettingsComponent } from './features/projects/project-settings/project-settings.component';
import { CodeComponent } from './shared/components/code/code.component';

import { FormsModule } from '@angular/forms';

import { DatePipe, IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { MessageService } from 'primeng/api';
import angularjsModule from '../index';
import { JobsService } from './core/services/jobs.service';
import { MapDataService } from './core/services/mapData.service';
import { MapsService } from './core/services/maps.service';
import { AdminDesignComponent } from './features/admin/design/design.component';
import { AdminOrganisationComponent } from './features/admin/organisations/admin-organisation.component';
import { AdminOrganisationsComponent } from './features/admin/organisations/admin-organisations.component';
import { AdminProjectComponent } from './features/admin/projects/admin-project.component';
import { AdminProjectsComponent } from './features/admin/projects/admin-projects.component';
import { AdminRequestComponent } from './features/admin/requests/admin-request.component';
import { AdminRequestsComponent } from './features/admin/requests/admin-requests.component';
import { AdminUserComponent } from './features/admin/users/admin-user.component';
import { AdminUsersComponent } from './features/admin/users/admin-users.component';
import { CollectionItemDetailComponent } from './features/collections/collection-item/collection-item-detail.component';
import { CollectionDetailComponent } from './features/collections/collection/collection-detail.component';
import { HomeComponent } from './features/home/home.component';
import { ProjectHooksComponent } from './features/projects/project-hooks/project-hooks.component';
import { RecordDetailComponent } from './features/records/record-detail-page/record-detail.component';
import { OrganisationMenuComponent } from './features/ui/organisation-menu/organisation-menu.component';
import { ImageModalViewerComponent } from './shared/components/image-modal-viewer/image-modal-viewer.component';
import { JobsComponent } from './shared/components/jobs/jobs.component';
import { MapMenuControlComponent } from './shared/components/map-menu-control/map-menu-control.component';

angular.module('ngApp', [])
    .directive('appComponent', downgradeComponent({ component: AppComponent }))
    .directive('appProfile', downgradeComponent({ component: ProfileComponent }))
    .directive('appAdminUsers', downgradeComponent({ component: AdminUsersComponent }))
    .directive('appAdminUser', downgradeComponent({ component: AdminUserComponent }))
    .directive('appProjectApiKeys', downgradeComponent({ component: ProjectApiKeysComponent }))
    .directive('appAdminRequests', downgradeComponent({ component: AdminRequestsComponent }))
    .directive('appAdminRequest', downgradeComponent({ component: AdminRequestComponent }))
    .directive('appProjectSettings', downgradeComponent({ component: ProjectSettingsComponent }))
    .directive('appProjectPages', downgradeComponent({ component: ProjectPagesComponent }))
    .directive('appProjectHooks', downgradeComponent({ component: ProjectHooksComponent }))
    .directive('appProjectCollections', downgradeComponent({ component: ProjectCollectionsComponent }))
    .directive('appCode', downgradeComponent({ component: CodeComponent }))
    .directive('appAdminDesign', downgradeComponent({ component: AdminDesignComponent }))
    .directive('appAdminOrganisations', downgradeComponent({ component: AdminOrganisationsComponent }))
    .directive('appAdminOrganisation', downgradeComponent({ component: AdminOrganisationComponent }))
    .directive('appAdminProjects', downgradeComponent({ component: AdminProjectsComponent }))
    .directive('appAdminProject', downgradeComponent({ component: AdminProjectComponent }))
    .directive('appHome', downgradeComponent({ component: HomeComponent }))
    .directive('appOrganisationMenu', downgradeComponent({ component: OrganisationMenuComponent }))
    .directive('appCollectionDetail', downgradeComponent({ component: CollectionDetailComponent }))
    .directive('appCollectionItemDetail', downgradeComponent({ component: CollectionItemDetailComponent }))
    .directive('appRecordDetail', downgradeComponent({ component: RecordDetailComponent }))
    .directive('appJobs', downgradeComponent({ component: JobsComponent }))
    .directive('appMapMenuControl', downgradeComponent({ component: MapMenuControlComponent }))
    .directive('appImageModalViewer', downgradeComponent({ component: ImageModalViewerComponent }))
    .factory('jobsService', downgradeInjectable(JobsService) as any)
    .factory('mapsService', downgradeInjectable(MapsService) as any)
    .factory('mapData', downgradeInjectable(MapDataService) as any)
    ;

@NgModule({
    declarations: [
        AppComponent,
        CodeComponent
    ],
    imports: [
        BrowserModule,
        UpgradeModule,
        CoreModule,
        FormsModule,
        BrowserAnimationsModule,
        MonacoEditorModule.forRoot({
            onMonacoLoad: () => {
                const monaco = (window as any).monaco;

                monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
                    noLib: true,
                    allowNonTsExtensions: true
                });

                //Ignore error regarding top level return
                monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
                    diagnosticCodesToIgnore: [/*top-level return*/ 1108]
                });
            }
        })
    ],
    providers: [{
        provide: IMAGE_LOADER,
        useValue: (config: ImageLoaderConfig) => {
            const url = new URL(config.src);
            const resize = config.width ? `?twic=v1/resize=${config.width}` : ``;
            return `https://coreo.twic.pics${url.pathname}${resize}`;
        }
    },
    provideAngularSvgIcon(),
        MessageService,
        DatePipe
    ]
})
export default class AppModule implements DoBootstrap {
    constructor(private upgrade: UpgradeModule) { }

    ngDoBootstrap(app: ApplicationRef) {
        this.upgrade.bootstrap(document.body, [angularjsModule], { strictDi: true });
        // Now bootstrap the Angular application
        app.bootstrap(AppComponent);
    }
}