import { getProjectCollection, getProjectAttribute, getProjectForm } from '../../../store/selectors';
import { getOperators } from '../../../store/records/records.reducer';

export const ProjectRecordsAttributeFilterComponent = {
    bindings: {
        attribute: '<',
        state: '<',
        onUpdate: '&'
    },
    template: require('!raw-loader!./project-records-attribute-filter.component.html').default,
    controllerAs: 'ctrl',
    controller: class ProjectRecordsAttributeFilterComponent {
        static $inject = ['$ngRedux', '$scope', 'CoreoAPI', 'ProjectActions'];
        constructor($ngRedux, $scope, CoreoAPI, ProjectActions) {
            this.$ngRedux = $ngRedux;
            this.$scope = $scope;
            this.CoreoAPI = CoreoAPI;
            this.ProjectActions = ProjectActions;
            this.autocomplete = {
                selectedItem: null,
                searchText: null
            };
            this.items = [];
            this.hasItems = false;
            this.value = '';
            this.searchText = '';
        }

        $onInit() {

            const state = this.$ngRedux.getState();

            if (this.attribute.collectionId) {
                this.value = this.state && this.state.value || [];
                const collection = getProjectCollection(this.attribute.collectionId)(state);

                if (collection) {
                    this.items = angular.copy(collection.items);
                    this.hasItems = typeof collection.items !== 'undefined';

                    if (this.state.value) {
                        this.loadCollection();
                    }
                }

            } else if (this.attribute.questionType === 'association') {
                this.value = this.state && this.state.value || [];
                const associatedForm = getProjectForm(this.attribute.associatedSurveyId)(state);
                if (associatedForm && associatedForm.titleAttributeId) {
                    this.associationAttribute = getProjectAttribute(associatedForm.titleAttributeId)(state);
                }
            }

            this.operators = getOperators(this.attribute.type, this.attribute.questionType);

            // Do we have an operator?
            const operatorLessTypes = ['boolean', 'media'];
            this.hasOperator = !operatorLessTypes.includes(this.attribute.type);
            // console.log('HERE', this.attribute.label, this.hasOperator, this.operators, this.state);
        }

        $onChanges(changes) {
            if (changes.state) {
                if (typeof changes.state.currentValue === 'undefined') {
                    this.value = '';
                    this.operator = {};
                } else {
                    this.value = changes.state.currentValue.value;
                    this.operator = angular.copy(changes.state.currentValue.operator);
                }
            }
        }

        loadCollection() {
            return this.$ngRedux.dispatch(this.ProjectActions.loadCollection(this.attribute.collectionId));
        }

        openCollection() {
            if (!this.hasItems) {
                return this.loadCollection();
            }
        }

        addItem(item) {
            if (item && !this.value.includes(item)) {
                // console.log('Pushing', item);
                this.value.push(item);
            }
            this.autocomplete.searchText = null;
            this.autocomplete.selectedItem = null;
        }

        removeItem(item) {
            const idx = this.value.indexOf(item);
            this.value = [
                ...this.value.slice(0, idx),
                ...this.value.slice(idx + 1)
            ];
        }

        openMenu($mdMenu, ev) {
            $mdMenu.open(ev);
        }

        clear() {
            if (!this.hasOperator) {
                this.operator = null;
                this.updateOperator(null);
            } else {
                this.value = this.state.operator.multiple ? [] : null;
                this.updateValue();
            }
        }


        getItems() {
            const { searchText } = this.autocomplete;
            const unselectedItems = this.items.filter(i => !this.value.includes(i));
            if (!searchText) {
                return unselectedItems;
            } else {
                const search = searchText.toLowerCase();
                return unselectedItems.filter(i => i.value.toLowerCase().indexOf(search) !== -1);
            }
        }

        updateValue() {
            this.onUpdate({
                attribute: this.attribute,
                value: this.value,
                operator: this.state.operator
            });
        }

        updateOperator(operator) {
            this.onUpdate({
                attribute: this.attribute,
                value: this.value,
                operator: typeof operator === 'undefined' ? this.operator : operator
            });
        }

        querySearch(text) {

            const query = `query lookupSearch($data: SequelizeJSON, $surveyId: Int!){
                records(where: {surveyId: $surveyId, data: $data}, limit: 10){
                    title
                }
            }`;

            return this.CoreoAPI.query(query, {
                variables: {
                    surveyId: this.attribute.associatedSurveyId,
                    data: {}
                }
            }).then(result => {
                return result.records;
            });
        }
    }
};
