import { v4 as uuid } from 'uuid';
import { getProject } from '../../store/selectors';

const ACCEPT_TYPES = ['image', 'video', 'audio'];

export const MediaManagerComponent = {
    bindings: {
        mediaItems: '=',
        onUpload: '&',
        onUpdate: '&',
        onDelete: '&',
        onSort: '&',
        single: '<',
        context: '@',
        onlyAccept: '@',
    },
    template: require('!raw-loader!./media-manager.component.html').default,
    controllerAs: 'ctrl',
    controller: class MediaManagerComponent {
        static $inject = ['$timeout', '$document', '$scope', '$mdDialog', 'toastr', 'ProjectMediaService', '$ngRedux', '$attrs'];
        constructor($timeout, $document, $scope, $mdDialog, toastr, ProjectMediaService, $ngRedux, $attrs) {
            $scope.$on('$destroy', $ngRedux.connect((state) => {
                return {
                    project: getProject(state)
                };
            }, null)(this));
            this.$document = $document;
            this.$mdDialog = $mdDialog;
            this.$timeout = $timeout;
            this.$attrs = $attrs;
            this.ProjectMediaService = ProjectMediaService;
            this.search = '';
            this.toastr = toastr;
            this.uploadingCount = 0;
            this.sortableOptions = {
                //                containment: 'parent',
                stop: () => {
                    this.updateItemOrder();
                }
            };
            this.id = uuid();

            var vm = this;
            $scope.readFile = function (files) {
                let uploadingCount = 0;
                let count = 0;
                vm.uploading = true;
                const items = [];
                for (const file of files) {
                    uploadingCount++;
                    var reader = new FileReader();
                    reader.onloadend = function (event) {
                        console.log('q', (vm.mediaItems || []).length + count)
                        items.push({
                            id: -Date.now() + Math.floor(Math.random() * 100000),
                            file,
                            name: file.name,
                            createdAt: (new Date()).toISOString(),
                            size: file.size,
                            caption: '',
                            type: file.type,
                            sort: (vm.mediaItems || []).length + count++,
                            url: event.target.result
                        });
                        uploadingCount--;
                        if (!uploadingCount) {
                            if (vm.$attrs.onUpload) {
                                const itemUploadPromises = vm.onUpload()(items);
                                for (const uploadPromise of (itemUploadPromises || [])) {
                                    vm.uploadingCount++;

                                    const uploadFinishedFn = () => {
                                        vm.uploadingCount--;
                                        if (vm.uploadingCount === 0) {
                                            vm.uploading = false;
                                        }
                                    }

                                    uploadPromise.then(
                                        uploadFinishedFn,
                                        uploadFinishedFn
                                    )
                                }
                            }
                        }
                        $scope.$digest();
                    };
                    reader.readAsDataURL(file);
                }
                // $timeout(() => {
                //     vm.uploading = true;
                //     var promises = _.map(files, function (file) { 
                //         return ProjectMediaService.createMediaItem(vm.project.id, file);
                //     }); 
                //     Promise.all(promises).then(items => {
                //         for (const item of items) {
                //             item.unlinked = true;
                //             vm.mediaItems.push(item);
                //             if (typeof vm.onUpload === 'function') {
                //                 vm.onUpload(item);
                //             }
                //         }
                //         vm.uploading = false;
                //         toastr.success('Media successfully uploaded');
                //     }, function (_err) {
                //         toastr.error('Media failed to upload');
                //     });
                // }, 0);
            };
        }

        updateItemOrder() {
            const itemSortMap = {}
            this.mediaItems.forEach((item, idx) => {
                itemSortMap[item.id] = idx;
            })
            if (this.$attrs.onSort) {
                this.onSort()(itemSortMap);
            }
        };

        deleteModal() {
            const vm = this;
            var modal = this.$mdDialog.show({
                templateUrl: 'media-manager-delete-items-modal.html',
                controllerAs: '$ctrl',
                controller: ['$mdDialog', function ($mdDialog) {
                    if (vm.context) {
                        this.message = `Are you sure you want to remove this media from this ${vm.context}?`;
                    } else {
                        this.message = `Are you sure you want to delete this media?`;
                    }


                    this.ok = function () {
                        $mdDialog.hide();
                    };
                    this.cancel = function () {
                        $mdDialog.cancel();
                    };
                }]
            });
            return modal;
        }

        startUpload(event) {
            if (event.target.tagName === 'INPUT') {
                return;
            }
            event.preventDefault();
            event.stopPropagation();

            this.$timeout(() => {
                this.$document[0].getElementById(this.id).click();
            }, 0);
        };

        deleteItem(event, item) {
            event.preventDefault();
            event.stopPropagation();
            return this.deleteModal().then(() => {
                const mediaItemIdx = this.mediaItems.findIndex(mediaItem => item.id === mediaItem.id);
                this.mediaItems.splice(mediaItemIdx, 1);
                if (this.$attrs.onDelete) {
                    this.onDelete()(item);
                }
                return Promise.resolve();
            }, angular.noop);
        };

        $onInit() {
            this.sortableOptions.disabled = !!this.single;
            if (!this.single) {
                this.$timeout(() => {
                    this.$document[0].getElementById(this.id).setAttribute('multiple', 'true');
                })
            }
            if (this.onlyAccept && ACCEPT_TYPES.includes(this.onlyAccept)) {
                this.accept = this.onlyAccept + '/*';
                this.mediaName = this.onlyAccept.charAt(0).toUpperCase() + this.onlyAccept.slice(1)
            } else {
                this.accept = ACCEPT_TYPES.map(t => t + '/*').join();
                this.mediaName = 'Media';
            }
        }

        editItem(event, item) {
            var vm = this;

            event.preventDefault();
            event.stopPropagation();

            return this.$mdDialog.show({
                templateUrl: 'media-manager-edit-item-modal.html',
                controllerAs: '$ctrl',
                windowClass: 'modal-fullscreen',
                controller: ['$mdDialog', function ($mdDialog) {
                    this.item = item;
                    this.date = new Date(item.createdAt);
                    this.ok = function () {
                        this.item.updated = true;
                        if (vm.$attrs.onUpdate) {
                            vm.onUpdate()(this.item);
                        }
                        $mdDialog.hide();
                    };
                    this.cancel = function () {
                        $mdDialog.cancel();
                    };
                    this.delete = (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        return vm.deleteItem(event, item).then(() => {
                            $mdDialog.hide();
                        });
                    };
                }]
            });
        };
    }
};