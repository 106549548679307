import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject, computed } from "@angular/core";
import { SvgIconComponent } from "angular-svg-icon";
import { HomeService } from "./home.service";
import { NgClass } from "@angular/common";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HomeProject, OrganisationFolder, FavouriteEntity } from "./home.models";

@Component({
    selector: 'app-home-toolbar',
    standalone: true,
    templateUrl: './home-toolbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SvgIconComponent,
        ReactiveFormsModule,
        InputTextModule,
        NgClass
    ]
})
export class HomeToolbarComponent {

    homeService = inject(HomeService);
    isOrgAdmin = this.homeService.isOrgAdmin;

    @Output() move = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() favourite = new EventEmitter<(HomeProject | OrganisationFolder)[]>();

    selectionCount = this.homeService.selectionCount;
    selectedItems = computed(() => [...this.homeService.selectedProjects(), ...this.homeService.selectedFolders()]);
    isSameFavouriteStatus = computed(() => new Set(this.selectedItems().map(item => item.isFavourite)).size === 1);
    searchForm = new FormGroup({
        search: new FormControl('')
    });

    constructor() {
        this.searchForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
            this.homeService.updateSearch(value.search);
        });
    }

    clearSelection() {
        this.homeService.clearSelection();
    }
}