import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from "@angular/forms";
import { ValidateEqual } from 'src/app/core/validators/validator';
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ApiService } from "src/app/core";

@Component({
  templateUrl: './project-remove.component.html',
  selector: 'app-project-remove',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ToastModule,
    ButtonModule,
    DialogModule,
    InputTextModule
  ],
  providers: [MessageService]
})
export class ProjectRemoveComponent {

  @Input() projectId: number;
  @Input() projectSlug!: string;
  @Input() isVisibleRemoveDialog: boolean = false;
  @Output() visibleRemoveChange = new EventEmitter<{ isVisibleRemoveDialog: boolean; isRemoved: boolean }>();

  removeProjectForm:FormGroup;

  constructor(
    private apiService: ApiService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.removeProjectForm = new FormGroup({
      slug: new FormControl<string>('', [Validators.required, ValidateEqual(this.projectSlug)]),
    });
  }

  onSubmit() {
    var query = `mutation{data: deleteProject(input:{id: ${this.projectId}})}`;
    this.apiService.graphql(query)
    .subscribe((response) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Project Removed' });
      this.onHideDialog(true);
    },
    (error) => {
      console.error(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not remove project' });
      this.onHideDialog();
    });
  }

  onHideDialog(isRemoved: boolean = false) {
    this.isVisibleRemoveDialog = false;
    this.visibleRemoveChange.emit({ isVisibleRemoveDialog: this.isVisibleRemoveDialog, isRemoved});
    this.removeProjectForm.reset();
  }
}
