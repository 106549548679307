import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    protected apiUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }

    private formatErrors(error: any) {
        return throwError(() => new Error(error.error));
    };

    get(path: string, params: HttpParams = new HttpParams()) {
        return this.http.get(`${this.apiUrl}${path}`, { params }).pipe(
            catchError(this.formatErrors)
        );
    }

    post<T = any>(path: string, body: Object = {}) {
        return this.http.post<T>(`${this.apiUrl}${path}`, body).pipe(
            catchError(this.formatErrors)
        );
    }

    graphql<T extends any>(
        query: string,
        variables: any = {},
        files?: { [key: string]: File }
    ): Observable<T> {

        if (files && Object.keys(files).length > 0) {

            let formData = new FormData();
            formData.append('query', query);
            formData.append('variables', JSON.stringify(variables));

            Object.keys(files).forEach(key => {
                formData.append(key, files[key]);
            });

            return this.http.post<{ data: T; errors: any[] }>(`${this.apiUrl}/graphql`, formData)
                .pipe(
                    map((res) => {
                        if (res.errors) {
                            throw new Error(res.errors[0].message);
                        }
                        return res.data;
                    }),
                    catchError(this.formatErrors)
                );
        }
        return this.http
            .post<{ data: T; errors: any[] }>(`${this.apiUrl}/graphql`, { query, variables })
            .pipe(
                map((res) => {
                    if (res.errors) {
                        throw new Error(res.errors[0].message);
                    }
                    return res.data;
                }),
                catchError(this.formatErrors)
            );
    }
}