const coreoGraphQLQuery = function ($http, eventEmitter) {

    var CoreoFilter = function (cfg) {
        angular.extend(this, {
            op: 'eq',
            looseMatch: true,
            value: undefined
        }, cfg || {});
    };

    function CoreoGraphQLQuery(query, config) {
        this.query = query;
        this.tmpl = _.template(query);
        this.config = angular.extend({}, {
            limit: 10,
            offset: 0,
            order: 'id',
            key: ''
        }, config || {});
        this.builder = this.config.builder;
        delete this.config.builder;

        this.fixedWhere = this.config.fixedWhere;
        delete this.config.fixedWhere;

        this.filters = {};
        this.data = null;
    }
    eventEmitter.inject(CoreoGraphQLQuery.prototype);

    CoreoGraphQLQuery.prototype._buildWhere = function () {
        var where = _.reduce(this.filters, function (acc, cfgArray, key) {
            _.each(cfgArray, function (cfg) {
                var value = cfg.value;
                var op = cfg.op;
                var target = acc;

                if (cfg.obj) {
                    target = acc[cfg.obj] = acc[cfg.obj] || {};
                    // target = acc[cfg.obj];
                }

                if (op === 'eq') {
                    target[key] = value;
                } else if (op === 'iLike') {
                    target[key] = _.assign({}, target[key], { iLike: '%' + value + '%' });
                } else if (op === 'like') {
                    target[key] = _.assign({}, target[key], { like: '%' + value + '%' });
                }
            });

            return acc;
        }, {});

        where = _.assign({}, this.fixedWhere || {}, where);
        return JSON.stringify(where).replace(/\"([^(\")"]+)\":/g, "$1:");
    };

    CoreoGraphQLQuery.prototype.run = function () {
        var self = this;

        function build(data) {
            if (angular.isFunction(self.builder)) {
                return new self.builder(data);
            } else {
                return data;
            }
        }

        var queryParams = angular.extend({}, this.config, {
            where: this._buildWhere()
        });

        return $http.post('/graphql', {
            query: this.tmpl(queryParams)
        }).then(function (response) {
            var responseData = response.data.data;
            self.errors = response.data.errors;

            if (angular.isArray(responseData.data)) {
                self.data = _.map(responseData.data, build);
            } else {
                self.data = build(responseData.data);
            }

            if (responseData.count) {
                self.count = responseData.count;
            }

            self.emit('change', self);
            return self;
        });
    };

    CoreoGraphQLQuery.prototype.filter = function (column, cfg) {
        if (typeof cfg === 'undefined') {
            delete this.filters[column];
        } else {
            var filters;
            if (angular.isArray(cfg)) {
                filters = cfg.map(function (c) {
                    return new CoreoFilter(c);
                });
            } else {
                if (angular.isObject(cfg)) {
                    filters = [new CoreoFilter(cfg)];
                } else {
                    filters = [new CoreoFilter({
                        value: cfg
                    })];
                }
            }
            this.filters[column] = filters;
        }
        this.start(0);
        this.emit('filter', column, this.filters);
        return this.filters;
    };

    CoreoGraphQLQuery.prototype.clearFilter = function () {
        this.filters = {};
    };

    CoreoGraphQLQuery.prototype.limit = function (limit) {
        if (limit === null || typeof limit === 'undefined') {
            delete this.config.limit;
        } else {
            this.config.limit = limit;
        }
        return this.config.limit;
    };

    CoreoGraphQLQuery.prototype.getLimit = function () {
        return this.config.limit;
    };

    CoreoGraphQLQuery.prototype.setLimit = function (limit) {
        if (limit === null || typeof limit === 'undefined') {
            delete this.config.limit;
        }
    };

    CoreoGraphQLQuery.prototype._setOrder = function () {
        this.config.order = (this.config.sortDir === 'desc' ? 'reverse:' : '') + this.config.sort;
    };

    CoreoGraphQLQuery.prototype.sort = function (sort) {
        if (typeof sort !== 'undefined') {
            if (sort.indexOf('-') === 0) {
                this.config.sort = sort.substr(1);
                this.config.sortDir = 'asc';
            } else {
                this.config.sort = sort;
                this.config.sortDir = 'desc';
            }
        }
        this._setOrder();
        return this.config.sort;
    };


    CoreoGraphQLQuery.prototype.sortDir = function (sortDir) {
        // console.log('SortDir', sortDir);

        if (typeof sortDir !== 'undefined') {
            this.config.sortDir = sortDir;
        }
        this._setOrder();
        return this.config.sortDir;
    };

    CoreoGraphQLQuery.prototype.clearSort = function () {
        this.config.sort = 'id';
        this.config.sortDir = '';
    };

    CoreoGraphQLQuery.prototype.start = function (start) {
        if (typeof start !== 'undefined') {
            this.config.offset = start;
        }
        return this.config.offset;
    };

    CoreoGraphQLQuery.prototype.clone = function () {
        var clone = new CoreoGraphQLQuery(this.query, angular.extend({}, this.config, {
            builder: this.builder
        }));
        angular.extend(clone.filters, this.filters);
        return clone;
    };

    return CoreoGraphQLQuery;
};

coreoGraphQLQuery.$inject = ['$http', 'eventEmitter'];
export default coreoGraphQLQuery;