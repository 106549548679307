import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';

export const NG_REDUX = new InjectionToken('NgRedux');
@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: HttpTokenInterceptor,
        multi: true
    }, {
        provide: NG_REDUX,
        useFactory: (i: any) => i.get('$ngRedux'),
        deps: ['$injector']
    }]
})
export class CoreModule { }