<div class="w-full h-full flex flex-col">
    <div class="bg-white p-5 flex flex-col lg:flex-row">
        <div class="flex flex-col flex-1">
            <span class="text-sm">Create your project using one of the pre-made, topic specific templates</span>
            <p class="text-xs text-[#878F97]">You are free to edit the template to suit the specific needs of your own
                project</p>
        </div>
        <!-- <span class="p-input-icon-left w-full flex-1">
            <i class="pi pi-search"></i>
            <input pInputText type="text" placeholder="Search for templates" class="w-full h-10" />
        </span> -->
    </div>

    <div class="border-t border-[#D3DBE3] p-5 bg-[#f4f7fc] overflow-y-auto w-full h-full">
        <div class="grid gap-5" style="grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));">
            @for(template of templates(); track template){
            <div class="bg-white rounded-xl border border-[#D3DBE3] overflow-hidden p-5 flex flex-col">
                <div class="h-48 w-full relative rounded-lg overflow-hidden mb-6">
                    <img [ngSrc]="template.icon" priority fill class=" object-cover" disableOptimizedSrcset />
                    <!-- <img [src]="template.icon | twicPic:'resize=192'" priority fill class="object-cover" /> -->
                </div>
                <h2 class="font-semibold mb-4">{{template.name}}</h2>
                <p class="text-xs text-[#878F97] mb-6">{{template.description}}</p>
                <p-button size="large" class="block mt-auto" (click)="selectTemplate(template)">Use template</p-button>
            </div>
            }
        </div>
    </div>
</div>