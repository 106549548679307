import { Injectable } from "@angular/core";
import { DownZip, DownZipFile } from "downzip";

interface Files {
    name: number;
    projectId: number;
    size: string;
    surveyId: number;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    private downZip: DownZip;

    constructor() {
        // this.init();
    }

    async init(): Promise<DownZip> {
        if (this.downZip) {
            return this.downZip;
        }
        const DownZip: any = await import('downzip');
        const downZip: DownZip = new DownZip.default();
        await downZip.register();
        return downZip;
    }

    async exportFiles(link: string): Promise<string> {
        const response = await fetch(link);
        const { files, date } = await response.json();
        return this.getExportLink(files, date);
    }

    async getExportLink(files: Files[], date: number): Promise<string> {
        const exportFiles = [];

        for (const file of files) {
            const { name, size, url } = file;
            exportFiles.push({ name, size, downloadUrl: url });
        }

        return this.createDownload(`export-${date}`, `export-${date}`, exportFiles);
    }

    async createDownload(downloadId: string, zipFileName: string, files: DownZipFile[]): Promise<string> {
        const downZip = await this.init();
        return downZip.downzip(downloadId, zipFileName, files);
    }
}