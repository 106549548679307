import { Component, OnDestroy, OnInit, computed, inject, input, signal } from '@angular/core';
import { SearchableListModalComponent } from './searchable-list-modal.component';
import { Question } from '../form.types';
import { FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonModule } from '@angular/common';
import { ModalButtonComponent } from '../buttons/modal-button.component';
import { FormCollectionsService } from '../form-collections.service';

@Component({
    selector: 'app-searchable-list',
    templateUrl: 'searchable-list.component.html',
    standalone: true,
    imports: [
        CommonModule,
        ModalButtonComponent
    ]
})

export class SearchableListComponent implements OnInit, OnDestroy {

    private formCollectionsService = inject(FormCollectionsService);
    private dialogService = inject(DialogService);

    question = input.required<Question>();
    form = input.required<FormGroup>();

    projectId = computed(() => {
        return this.question().projectId;
    });
    collectionId = computed(() => {
        return this.question().collectionId;
    });
    formControl = computed(() => {
        return this.form().controls[this.question().path];
    });

    collections = this.formCollectionsService.collections;

    collectionItems = computed(() => {
        const collections = this.collections();
        return collections.find(c => c.id === this.collectionId()).items;
    });

    values = computed(() => {
        const keys = this.keys();
        const items = this.collectionItems();

        return keys.map(k => {
            const item = items.find(i => i.key === k);
            if (item) {
                return item.value;
            }
        });
    });

    /** not using a computed signal for the key as it wasn't updating when changed after the modal closed so explicitly setting it on init and after modal close */
    keys = signal<string[]>([]);

    private dialogRef: DynamicDialogRef | undefined;

    ngOnInit() {
        this.setKeys();
    }

    ngOnDestroy() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    private setKeys() {
        if (this.question().type === 'multiselect') {
            this.keys.set(this.formControl().value);
        } else {
            /** transform to array if single value */
            this.keys.set([this.formControl().value]);
        }
    }

    public openSearchableList() {
        this.dialogRef = this.dialogService.open(SearchableListModalComponent, {
            data: {
                projectId: this.question().projectId,
                collectionId: this.question().collectionId,
                multiselect: this.question().type === 'multiselect',
                defaultLayout: this.question().config.defaultLayout ?? 'list',
                showTooltip: this.question().config.collectionTooltip,
                selection: this.keys()
            },
            header: this.question().label,
            width: '588px',
            height: '75vh',
            modal: true,
            contentStyle: { 'padding': '0px' }
        });

        this.dialogRef.onClose.subscribe((data: string) => {
            if (data === undefined) {
                return;
            } else {
                if (!!data) {
                    this.formControl().setValue(data);
                } else if (data === null) {
                    this.formControl().setValue(null);
                }
                this.setKeys();
            }
        });
    }
}