@if (card) {
    <div (click)="handleClick($event)" class="relative w-full rounded-lg shadow-panel overflow-hidden hover:cursor-pointer">
        @if (hasImage) {
            <img [src]="imageSrc" alt="" class="w-full h-64 object-cover">
        }
        <div class="h-12 px-4 flex items-center justify-between">
            <p>{{ item.value }}</p>
            @if (showTooltip) {
                <i (click)="handleTooltip($event)" class="fa-solid fa-circle-info text-text-secondary hover:cursor-pointer"></i>
            }
        </div>
        <div [ngClass]="{'absolute top-3 right-3 h-6 w-6 flex items-center justify-center rounded-full border': true , 'border-grey-15 bg-grey-10': !selected, 'border-primary bg-primary': selected}">
            @if (selected) {
                <i class="fa-solid fa-check text-white text-lg"></i>
            }
        </div>
    </div>
}
@else {
    <div (click)="handleClick($event)" class="py-1 border-b border-white flex items-center justify-between hover:cursor-pointer hover:border-grey-15">
        <div class="flex items-center gap-2">
            @if (hasImage) {
                <p-avatar [image]="imageSrc" styleClass="overflow-hidden" class="h-8"></p-avatar>
            }
            <p>{{ item.value }}</p>
        </div>
        <div class="flex-none flex items-center gap-2">
            @if (showTooltip) {
                <i (click)="handleTooltip($event)" class="fa-solid fa-circle-info text-text-secondary hover:cursor-pointer"></i>
            }
            <div [ngClass]="{'h-6 w-6 flex items-center justify-center rounded-full border': true , 'border-grey-15 bg-grey-10': !selected, 'border-primary bg-primary': selected}">
                @if (selected) {
                    <i class="fa-solid fa-check text-white text-lg"></i>
                }
            </div>
        </div>
    </div>
}