import { Component, ElementRef, inject, input, OnInit, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordDetailUser, RecordDetailViewer } from '../record-detail.component';
import { ApiService } from 'src/app/core';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RecordMessageComponent } from './record-message.component';
import { z } from 'zod';
import { catchError, of, take } from 'rxjs';
import { AvatarModule } from 'primeng/avatar';

const RecordMessageSchema = z.object({
    id: z.number(),
    subject: z.string(),
    content: z.string(),
    direction: z.union([z.literal('inbound'), z.literal('outbound')]),
    attachments: z.string().array().nullable(),
    read: z.boolean(),
    createdAt: z.string(),
    user: z.object({
        id: z.number(),
        displayName: z.string(),
        username: z.string(),
        imageUrl: z.string().nullable()
    })
});
export type RecordMessage = z.infer<typeof RecordMessageSchema>;

const RecordMessagesSchema =  RecordMessageSchema.array();
type RecordMessages = z.infer<typeof RecordMessagesSchema>;

@Component({
    selector: 'app-record-messages',
    templateUrl: 'record-messages.component.html',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        InputTextareaModule,
        RecordMessageComponent,
        AvatarModule
    ]
})

export class RecordMessagesComponent implements OnInit {

    private apiService = inject(ApiService);

    recordId = input.required<number>();
    projectId = input.required<number>();
    viewer = input.required<RecordDetailViewer>();

    textArea = viewChild<ElementRef>('textArea');

    user = signal<RecordDetailUser | null>(null);
    messages = signal<RecordMessages>([]);
    messaging = signal(false);
    sending = signal(false);

    constructor() { }

    ngOnInit() {
        this.getMessages();
    }

    getMessages() {
        const query = `query CoreoAAGetRecordMessages($id: Int!){
            record(id: $id){
                messages(order:"createdAt"){
                    id
                    subject
                    content
                    direction
                    attachments
                    read
                    createdAt
                    user{
                        id
                        displayName
                        username
                        imageUrl
                    }
                }
            }
        }`;

        this.apiService.graphql<{ record: { messages: RecordMessages } }>(query, { id: this.recordId() }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null)
            })
        ).subscribe(res => {
            if (!!res) {
                console.log(res);
                try {
                    const messages = RecordMessagesSchema.parse(res.record.messages);
                    this.messages.set(messages);
                    console.log(this.messages());
                    
                } catch (error) {
                    if (error instanceof z.ZodError) {
                        console.warn(error.issues);
                    }
                }
            }
        });
    }

    addMessage() {
        this.messaging.set(true);
        setTimeout(() => {
            this.textArea().nativeElement.focus();
        }, 500);
    }

    cancelMessage() {
        this.messaging.set(false);
    }

    postMessage() {
        this.sending.set(true);
        const message: string = this.textArea().nativeElement.value;
        
        this.apiService.post('/messages', {
            recordId: this.recordId(),
            projectId: this.projectId(),
            subject: 'Query Regarding your Recording',
            content: message
        }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null)
            })
        ).subscribe(res => {
            if (!!res) {
                this.cancelMessage();
                this.getMessages();
            }
            this.sending.set(false);
        });
    }
}