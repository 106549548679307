import { ChangeDetectionStrategy, Component, computed, inject, signal } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from "primeng/button";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from 'primeng/inputtextarea';
import { HomeMembershipsComponent } from "./home-memberships.component";
import { HomeService } from "./home.service";
import { HomeProject, HomeProjectSetupResult, HomeProjectTemplate, ProjectMembership } from "./home.models";
import { ApiService } from "src/app/core";
import { z } from 'zod';
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { UserService } from "src/app/core/services/user.service";
import { NgOptimizedImage } from "@angular/common";

const ProjectMember = z.object({
    role: z.string(),
    user: z.object({
        id: z.number(),
        displayName: z.string(),
        username: z.string().nullable()
    })
});

const ProjectMembers = z.array(ProjectMember);

const OrganisationMember = z.object({
    user: z.object({
        id: z.number(),
        displayName: z.string(),
        username: z.string().nullable()
    }).nullable()
});
const OrganisationMembers = z.array(OrganisationMember);

const ProjectQueryResult = z.object({
    memberships: ProjectMembers
});

const OrganisationQueryResult = z.object({
    memberships: OrganisationMembers
});

const QueryResult = z.object({
    project: ProjectQueryResult,
    organisation: OrganisationQueryResult
});

const EmptyQueryResult = z.object({
    organisation: OrganisationQueryResult
});

type ProjectMembershipItem = ProjectMembership & {
    selected: boolean;
    name: string;
}

@Component({
    standalone: true,
    selector: 'app-home-project-setup',
    imports: [
        ReactiveFormsModule,
        InputTextModule,
        InputTextareaModule,
        AutoFocusModule,
        ButtonModule,
        HomeMembershipsComponent,
        CheckboxModule,
        FormsModule,
        DropdownModule,
        NgOptimizedImage
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './home-project-setup.component.html',
    styles: [`
        :host{@apply flex flex-col overflow-hidden h-full;}
    `]
})
export class HomeProjectSetupComponent {

    private ref = inject(DynamicDialogRef);
    private homeService = inject(HomeService);
    private config = inject(DynamicDialogConfig);
    private apiService = inject(ApiService);
    private userService = inject(UserService);

    roles = [
        { name: 'Admin', value: 'admin' },
        { name: 'Moderator', value: 'moderator' },
        { name: 'Member', value: 'member' }
    ];

    stage = signal<1 | 2>(1);
    memberships = signal<ProjectMembershipItem[]>([]);
    hasAdmin = computed(() => this.memberships().some(a => a.selected && a.role === 'admin'));

    organisationId = this.homeService.currentOrganisationId;

    template = signal<HomeProjectTemplate | null>(this.config.data.template ?? null);
    project = signal<HomeProject | null>(this.config.data.project ?? null);
    userId = signal<number | null>(this.config.data.userId ?? null);

    form: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.minLength(3)]),
        description: new FormControl('')
    });

    isSelectAll = computed<boolean | null>(() => {
        const sameStatus = new Set(this.memberships().map(member => member.selected));
        return sameStatus.size === 1 ? sameStatus.values().next().value : null;
    });
    sortType = signal<'asc' | 'desc'>('asc');

    selectAll() {
        this.memberships.set(this.memberships().map(member => ({ ...member, selected: !this.isSelectAll() })));
    }

    sortByName(sortType: 'asc' | 'desc') {
        this.sortType.set(sortType);
        this.memberships.set(this.memberships().sort((a, b) => sortType === 'asc'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        ));
    }

    loadCurrentMemberships() {
        const query = `query AAGetCurrentProjectMemberships($projectId: Int!, $organisationId: Int!) {
            project(id: $projectId) {
                memberships{
                    role,
                    user{
                        id,
                        displayName,
                        username
                    }
                }
            }
            organisation(id: $organisationId){
                memberships{
                    user{
                        id,
                        displayName
                        username
                    }
                }
            }
        }`;
        this.apiService.graphql<z.infer<typeof QueryResult>>(query, {
            projectId: this.project().id,
            organisationId: this.organisationId()
        }).subscribe(t => {
            const result = QueryResult.parse(t);
            const memberships: ProjectMembershipItem[] = [];
            const userId = this.userId();

            for (const member of result.organisation.memberships.filter(a => a.user !== null)) {
                const pm = result.project.memberships.find(a => a.user.id === member.user?.id);
                memberships.push({
                    selected: pm?.user?.id === userId || typeof pm !== 'undefined',
                    role: pm?.user?.id === userId ? 'admin' : (pm?.role ?? 'member'),
                    name: member.user?.displayName ?? '',
                    userId: member.user?.id ?? 0
                });
            }
            memberships.sort((a, b) => a.name.localeCompare(b.name));
            this.memberships.set(memberships);
        });
    }

    loadEmptyMemberships() {
        const query = `query AAGetOrganisationrojectMemberships($organisationId: Int!) {
            organisation(id: $organisationId){
                memberships{
                    user{
                        id,
                        displayName
                        username
                    }
                }
            }
        }`;
        this.apiService.graphql<z.infer<typeof EmptyQueryResult>>(query, {
            organisationId: this.organisationId()
        }).subscribe(t => {
            const result = EmptyQueryResult.parse(t);
            const memberships: ProjectMembershipItem[] = [];
            const userId = this.userId();
            for (const member of result.organisation.memberships.filter(a => a.user !== null)) {
                memberships.push({
                    selected: member.user?.id === userId,
                    role: member.user?.id === userId ? 'admin' : 'member',
                    name: member.user?.displayName ?? '',
                    userId: member.user?.id ?? 0
                });
            }
            memberships.sort((a, b) => a.name.localeCompare(b.name));
            this.memberships.set(memberships);
        });
    }

    ngOnInit() {
        if (this.project() && this.organisationId()) {
            this.loadCurrentMemberships();
        } else {
            this.loadEmptyMemberships();
        }
    }

    selectMember(member: ProjectMembershipItem) {
        const index = this.memberships().findIndex(a => a.userId === member.userId);
        const memberships = [...this.memberships()];
        memberships[index] = { ...member, selected: !member.selected };
        this.memberships.set(memberships);
    }

    updateMember(member: ProjectMembershipItem, role: string) {
        const index = this.memberships().findIndex(a => a.userId === member.userId);
        const memberships = [...this.memberships()];
        memberships[index] = { ...member, role };
        this.memberships.set(memberships);
    }

    back() {
        this.stage.set(1);
    }

    createProject(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.stage() === 1) {
            return this.stage.set(2);
        }

        const result: HomeProjectSetupResult = {
            ...this.form.value,
            memberships: this.memberships().filter(user => user.selected)
        };
        this.ref.close(result);
    }
}
