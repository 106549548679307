export default class RecordsService {
    static $inject = ['$http', 'Record', 'CoreoAPI', 'CoreoQuery', 'CoreoGraphQLQuery'];
    constructor($http, Record, CoreoAPI, CoreoQuery, CoreoGraphQLQuery) {
        this.$http = $http;
        this.Record = Record;
        this.CoreoAPI = CoreoAPI;
        this.CoreoQuery = CoreoQuery;
        this.CoreoGraphQLQuery = CoreoGraphQLQuery;
        this.query = null;

        import(/* webpackChunkName: "coreo-conditions" */'@natural-apptitude/coreo-conditions').then(({ RuleEngine }) => {
            this.RuleEngine = RuleEngine;
        });
    }

    /**
     * Gets all associated records of a record, and returns in a consistent format for legacy and new
     * @param {Number} id
     */
    getRecordAssociates(id) {
        var query = `
{
  record(id: ${id}, showDeleted: true){
    children{
      id,
        surveyId,
        title,
        createdAt,
        user{
        displayName
      }
    },
    parent{
      id,
        title,
        surveyId,
        createdAt,
        user{
        displayName
      }
    },
    associates{
      association{
        label
      },
      record{
        id,
          title,
          surveyId,
          createdAt,
          user{
          displayName
        }
      }
    }
  }
} `;
        return this.CoreoAPI.query(query).then(({ record }) => {
            const result = record.associates.reduce((acc, { association, record }) => {
                acc.push({
                    id: record.id,
                    name: record.title,
                    surveyId: record.surveyId,
                    association: association.label,
                    user: record.user.displayName,
                    createdAt: record.createdAt
                });

                return acc;
            }, []);

            for (let child of record.children) {
                result.push({
                    id: child.id,
                    name: child.title,
                    surveyId: child.surveyId,
                    association: "Child Record",
                    user: child.user.displayName,
                    createdAt: child.createdAt
                });
            }

            if (record.parent) {
                result.push({
                    id: record.parent.id,
                    name: record.parent.title,
                    surveyId: record.parent.surveyId,
                    association: "Parent Record",
                    user: record.parent.user.displayName,
                    createdAt: record.parent.createdAt
                });
            }
            return result;
        });
    }


    getChildrenWithSurvey(recordId, surveyId) {
        var query = '{record(id: ' + recordId + '){children(order: "createdAt", where:{surveyId: ' + surveyId + '}){id,createdAt,data,lat,lng}}}';
        return this.CoreoAPI.query(query).then(function (data) {
            return data.record.children;
        });
    }

    getComments(id) {
        var query = '{record(id: ' + id + '){comments(order: "createdAt"){id,recordId,text,createdAt,user{id,displayName,imageUrl}}}}';
        return this.CoreoAPI.query(query).then(function (data) {
            return data.record && data.record.comments;
        });
    }

    getMessages(id) {
        var query = '{record(id: ' + id + '){messages(order: "createdAt"){id,recordId,content,read,subject,messageId,createdAt,user{id,displayName,imageUrl}}}}';
        return this.CoreoAPI.query(query).then(function (data) {
            return data.record && data.record.comments;
        });
    }

    update(id, data) {
        return this.$http.put('/records/' + id, data).then(function (res) {
            return res.data;
        });
    }

    updateFile(id, path, file) {
        var data = new FormData();
        data.append('data[' + path + ']', file);
        return this.$http.put('/records/' + id, data, {
            headers: {
                'Content-Type': undefined
            }
        }).then(function (res) {
            return res.data;
        }, function (res) {
            throw res.data;
        });
    }

    deleteRecord(id) {
      var query = 'mutation{result: deleteRecord(input:{id:' + id + '})}';
      return this.CoreoAPI.mutation(query);
    }

    undeleteRecord(id) {
        return this.CoreoAPI.mutation(`mutation{ result: restoreRecord(input: { id: ${id}}) }`).then(function (res) {
            return res;
        });
    }

    revertRecord(id, logId) {
        return this.CoreoAPI.mutation(`mutation{ result: revertRecord(input:{id:${id}, recordLogId:${logId}})}`).then(function (res) {
            return res;
        })
    }


    evaluateAttributeConditions(attribute, attributes, data) {
        const { conditions } = attribute || {};
        if (!(conditions && conditions.rules)) {
            return true;
        }
        for (const rule of conditions.rules) {
            const targetAttribute = attributes.find(a => a.path === rule.path)
            if (!targetAttribute) {
                continue
            }
            const result = this.RuleEngine.evaluateRule(rule, targetAttribute.type, data[rule.path]);
            if (result === null) {
                // Rule is invalid, ignore
                continue;
            }

            if (!result && !conditions.any) {
                // Conditions require al l rules to hold, and this one failed
                return false;
            }
            if (result && conditions.any) {
                // Conditions require any rule to hold, and this one passed
                return true;
            }
        }

        // If we reach here, either:
        // - *all* rules are required and none failed: success
        // - *any* rule is required and none passed: failure
        return !conditions.any;
    };

}
