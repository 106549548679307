import { v4 as uuid } from 'uuid';

export const questionTypes = [
    { type: 'text', questionType: 'short', name: 'Short answer', help: 'Single line text field. No character limit', icon: 'fas fa-align-left' },
    { type: 'text', questionType: 'long', name: 'Long answer', help: 'Multi-line text field. No character limit', icon: 'fas fa-align-justify' },
    { type: 'boolean', questionType: 'boolean', name: 'Yes/No', help: 'Yes/No select buttons', icon: 'fa-coreo-yes-no' },
    { type: 'boolean', questionType: 'confirm', name: 'Checkbox', help: 'Single checkbox', icon: 'fas fa-clipboard-check' },
    { type: ['select', 'multiselect'], questionType: 'multi', name: 'Multiple choice', help: 'Select list which presents radio buttons. Suitable for short lists', icon: 'fas fa-list-ul' },
    { type: ['select', 'multiselect'], questionType: 'modal', name: 'Searchable list', help: 'Select list which features a dropdown with a search option. Suitable for long lists', icon: 'fas fa-search', initConfig: { defaultLayout: 'list' } },
    { type: ['select', 'multiselect'], questionType: 'geometryselect', name: 'Geometry picker', help: 'Select list but enables you to choose an item with a geometry from the map interface', icon: 'fas fa-draw-polygon' },
    { type: ['float', 'integer'], questionType: 'slider', name: 'Slider', help: 'Input a number using a slider interface. Accepts min/max values, a step value and default value', icon: 'fas fa-sliders-h' },
    { type: ['float', 'integer'], questionType: 'numeric', name: 'Numeric answer', help: 'Input a number by directly typing into the field. Accepts min/max values', icon: 'fas fa-hashtag' },
    { type: 'attachment', questionType: 'mediaGallery', name: 'Multi-photo', help: 'Upload multiple photos from the camera or device’s gallery', icon: 'fas fa-image' },
    { type: 'media', questionType: 'photo', name: 'Single photo', help: 'Upload a single photo from the camera or device’s gallery', icon: 'fas fa-image' },
    { type: 'media', questionType: 'video', name: 'Video upload', help: 'Video capture field, using either the users gallery or camera (if available)', icon: 'fas fa-video', hidden: true },
    { type: 'media', questionType: 'audio', name: 'Audio upload', help: 'Audio capture field, using either the users gallery or microphone (if available)', icon: 'fas fa-file-audio', hidden: true },
    { type: 'media', questionType: 'signature', name: 'Signature', help: 'Freehand signature', icon: 'fas fa-signature', hidden: true },
    { type: 'media', questionType: 'file', name: 'File upload', help: 'A generic file upload field for any file type', icon: 'fas fa-cloud-upload-alt', hidden: true },
    { type: 'date', questionType: 'date', name: 'Date picker', help: 'Date format of Day/Month/Year', icon: 'far fa-calendar-alt', icon: 'far fa-calendar-alt', initConfig: { auto: true } },
    { type: 'datetime', questionType: 'datetime', name: 'Date/Time picker', help: 'Date/time format of Day/Month/Year and time (24hr)', icon: 'far fa-clock', initConfig: { auto: true } },
    { type: null, questionType: 'geometry', name: 'Location / Geometry', max: 1, help: 'Add a location (point, line, polygon) to the record', icon: 'fas fa-map-marker-alt', initConfig: { types: ['point'], baseStyle: 'streets-v12' } },
    { type: 'coordinatetransform', questionType: null, name: 'Coordinate projection', requires: { type: null, questionType: 'geometry' }, help: 'Automatically transform and store additional coordinate references for the lat/lng recorded (e.g. GB National Grid Ref)', icon: 'fas fa-map-marked-alt' },
    { type: 'rgeolocation', questionType: null, name: 'Reverse geolocation', requires: { type: null, questionType: 'geometry' }, help: 'Uses Bing’s Reverse Geolocation API to store the nearest address, town, postcode etc for the geometry', icon: 'fas fa-atlas', initConfig: { localisation: 'en-GB' } },
    { type: 'geometryquery', questionType: null, name: 'Area lookup', requires: { type: null, questionType: 'geometry' }, help: 'In a project containing reference polygon data (e.g. county or country boundaries) the Area Lookup question returns the name of the polygon your record is created in', icon: 'fas fa-globe-europe', initConfig: { operation: 'within' } },
    { type: null, questionType: 'child', name: 'Sub-form', help: 'The Sub-form question is used to add a form into your current form. Typically this is used to add multiple records to a parent survey', icon: 'far fa-clipboard' },
    { type: null, questionType: 'association', name: 'Record lookup', help: 'Record lookup enables you to associate your survey data with an existing record in your project. It is used in e.g. fixed point monitoring surveys', icon: 'fas fa-link', initConfig: { min: 0, max: 1 } },
    { type: 'attachment', questionType: 'signature', name: 'Signature', help: 'Freehand signature', icon: 'fas fa-signature' },
    { type: null, questionType: 'text', name: 'Text', help: 'Provide text within the survey form itself to provide e.g. clear instructions or section titles', icon: 'fas fa-font' },
    { type: null, questionType: 'expression', name: 'Calculated field', help: 'Add code to perform calculations within your survey. Uses JavaScript and, as such, requires developer level knowledge', icon: 'fas fa-calculator', },
];

const computeQuestionState = (attributes) => {
    return questionTypes.reduce((acc, { type, questionType, help, max, requires, hidden }) => {
        if (hidden) {
            return acc;
        }
        let enabled = true;
        if (max) {
            const existing = (attributes || []).filter(a => a.type === type && a.questionType === questionType);
            if (existing.length >= max) {
                enabled = false;
            }
        }
        if (enabled && requires) {
            const { type: requiresType, questionType: requiresQuestionType } = requires;
            const existing = (attributes || []).find(a => a.type === requiresType && a.questionType === requiresQuestionType);
            if (typeof existing === 'undefined') {
                enabled = false;
            }
        }

        return [...acc, {
            type,
            questionType,
            help,
            enabled
        }];
    }, []);

}

const initialState = {
    ready: false,
    dirty: false,
    selectedAttributeId: null,
    settingsView: 'build',
    questionState: computeQuestionState(),
    deleted: [],
    saving: false,
    errors: {}
};

const formReducer = (state = initialState, action) => {

    // Return the index in the attributes array of the currently selected question
    const currentAttributeIdx = () => state.attributes.findIndex(a => a.id === state.selectedAttributeId);

    const processAttributeRules = (updatedAttribute, attribute) => {
        if (!(attribute.conditions && attribute.conditions.rules && attribute.conditions.rules.length > 0)) {
            return attribute;
        }
        const { path, type } = updatedAttribute;

        return {
            ...attribute,
            conditions: {
                ...attribute.conditions,
                rules: attribute.conditions.rules.map(r => {
                    if (r.path !== path) {
                        return r;
                    }

                    // Handle the case where a question has changed from being select <=> multiselect
                    let selectCondition = r.selectCondition;
                    if (type === 'select' && ['contains', 'notcontains'].includes(selectCondition)) {
                        selectCondition = (selectCondition === 'contains') ? 'is' : 'not';
                    } else if (type === 'multiselect' && ['is', 'not'].includes(selectCondition)) {
                        selectCondition = (selectCondition === 'is') ? 'contains' : 'notcontains';
                    }

                    return {
                        ...r,
                        selectCondition
                    };
                })
            }
        };
    };

    const updateSelectedAttribute = (update) => {
        const idx = currentAttributeIdx();
        const newAttribute = {
            ...state.attributes[idx],
            ...update,
            dirty: true
        };

        return {
            ...state,
            dirty: true,
            attributes: [
                ...state.attributes.slice(0, idx).map(a => processAttributeRules(newAttribute, a)),
                newAttribute,
                ...state.attributes.slice(idx + 1).map(a => processAttributeRules(newAttribute, a))
            ]
        }
    };

    const getMaxSectionIdx = () => {
        if (state.attributes.length === 0) {
            return 0;
        }
        return Math.max(...state.attributes.map(a => a.sectionIdx));
    };

    const getAttributesForSection = (sectionIdx) => state.attributes.filter(a => a.sectionIdx === sectionIdx);

    switch (action.type) {

        case 'FORM_INIT': {
            const { form, attributes: formAttributes } = action;
            const attributes = formAttributes.map(a => ({
                ...a,
                conditions: a.conditions || {
                    rules: [],
                    any: false
                },
                config: a.config || {}
            }));

            const firstAttribute = attributes.length ? attributes[0] : undefined;
            const selectedAttributeId = typeof firstAttribute === 'undefined' ? null : firstAttribute.id;
            return {
                ...initialState,
                ...form,
                attributes,
                questionState: computeQuestionState(attributes),
                deleted: [],
                errors: {},
                selectedAttributeId,
                settingsView: 'build',
                ready: true,
                dirty: false
            };
        }

        case 'FORM_SWITCH_VIEW': {
            return {
                ...state,
                settingsView: action.view
            };
        }

        case 'FORM_SELECT_ATTRIBUTE': {
            const { id } = action;
            return {
                ...state,
                selectedAttributeId: id
            };
        }

        case 'FORM_SET_ATTRIBUTE_ORDER': {
            const { order } = action;
            const idsOrder = order.map(o => o.id);
            const sectionIdxs = order.reduce((acc, o) => {
                acc[o.id] = o.sectionIdx;
                return acc;
            }, {});

            const newAttributes = [...state.attributes].sort((a, b) => idsOrder.indexOf(a.id) - idsOrder.indexOf(b.id)).map((a, order) => ({
                ...a,
                order,
                sectionIdx: sectionIdxs[a.id],
                dirty: a.dirty || (a.order !== order) || (a.sectionIdx !== sectionIdxs[a.id])
            }));

            return {
                ...state,
                dirty: true,
                attributes: newAttributes
            };
        }

        case 'FORM_DELETE_ATTRIBUTE': {
            const { id } = action;
            const idx = state.attributes.findIndex(a => a.id === id);
            // If the index is less than 0, just get rid of it
            const deleted = id < 0 ? state.deleted : [...state.deleted, {
                ...state.attributes[idx],
                dirty: true,
                sectionIdx: null
            }];

            // If removing this question causes an entire section to be removed, we need to fix the sectionIdx of all following questions
            const isLastQuestionInSection = getAttributesForSection(state.attributes[idx].sectionIdx).length === 1;

            const attributes = [
                ...state.attributes.slice(0, idx),
                ...state.attributes.slice(idx + 1).map(attribute => ({
                    ...attribute,
                    sectionIdx: isLastQuestionInSection ? attribute.sectionIdx - 1 : attribute.sectionIdx,
                    order: attribute.order - 1,
                    dirty: true
                }))
            ];

            return {
                ...state,
                dirty: true,
                questionState: computeQuestionState(attributes),
                attributes,
                deleted
            };
        }

        case 'FORM_ADD_ATTRIBUTE': {
            const { aType, questionType, index, sectionIdx, config, surveyId } = action;

            const order = index === state.attributes.length ? state.attributes.length : state.attributes[index].order;
            const id = new Date().valueOf() * -1;
            const newAttribute = {
                id,
                uuid: uuid(),
                required: false,
                visible: true,
                config,
                meta: {},
                conditions: { rules: [], any: false },
                type: aType,
                questionType,
                surveyId,
                order,
                sectionIdx
            };
            const attributes = [
                ...state.attributes.slice(0, index),
                newAttribute,
                ...state.attributes.slice(index).map(a => ({
                    ...a,
                    order: a.order + 1,
                    dirty: true
                }))
            ];

            return {
                ...state,
                dirty: true,
                selectedAttributeId: id,
                attributes,
                questionState: computeQuestionState(attributes)
            };
        }

        case 'FORM_RESTORE_ATTRIBUTE': {
            const { id } = action;
            const deletedIdx = state.deleted.findIndex(a => a.id === id);
            const sectionIdx = getMaxSectionIdx();

            return {
                ...state,
                dirty: true,
                attributes: [
                    ...state.attributes,
                    {
                        ...state.deleted[deletedIdx],
                        order: state.attributes.length,
                        dirty: true,
                        sectionIdx
                    }
                ],
                deleted: [
                    ...state.deleted.slice(0, deletedIdx),
                    ...state.deleted.slice(deletedIdx + 1)
                ]
            };
        }

        case 'FORM_DELETE_ATTRIBUTE_SUCCESS': {
            const { id } = action;
            const idx = state.deleted.findIndex(a => a.id === id);

            return {
                ...state,
                deleted: [
                    ...state.deleted.slice(0, idx),
                    ...state.deleted.slice(idx + 1)
                ]
            };
        }

        case 'FORM_ADD_SECTION_DIVIDER': {
            const { id } = action;
            const idx = state.attributes.findIndex(a => a.id === id);

            return {
                ...state,
                dirty: true,
                attributes: [
                    ...state.attributes.slice(0, idx + 1),
                    ...state.attributes.slice(idx + 1).map(a => ({
                        ...a,
                        sectionIdx: a.sectionIdx + 1,
                        dirty: true
                    }))
                ]
            };
        }

        case 'FORM_REMOVE_SECTION_DIVIDER': {
            const { id } = action;
            const idx = state.attributes.findIndex(a => a.id === id);

            return {
                ...state,
                dirty: true,
                attributes: [
                    ...state.attributes.slice(0, idx + 1),
                    ...state.attributes.slice(idx + 1).map(a => ({
                        ...a,
                        sectionIdx: a.sectionIdx - 1,
                        dirty: true
                    }))
                ]
            };
        }

        case 'FORM_DELETE_ATTRIBUTE': {
            // TODO!!

            // const { id } = action;
            // const idx = state.attributes.findIndex(a => a.id === id);

            // return {
            //     ...state,
            //     attributes: [
            //         ...state.attributes.slice(0, idx),
            //         ...state.attributes.slice(idx + 1)
            //     ]
            // };
            console.warn('NOT IMPLEMENTED');
            return state;
        }

        case 'FORM_ADD_CONDITION_RULE': {
            const idx = state.attributes.findIndex(a => a.id === state.selectedAttributeId);
            if (idx === -1) {
                return state;
            }
            return updateSelectedAttribute({
                conditions: {
                    ...state.attributes[idx].conditions,
                    rules: [
                        ...(state.attributes[idx].conditions.rules || []),
                        {
                            path: state.attributes.length > 0 ? state.attributes[0].path : ''
                        }
                    ]
                }
            });
        }

        case 'FORM_UPDATE_CONDITION_RULES': {
            return updateSelectedAttribute({
                conditions: action.conditions
            });
        }

        case 'FORM_REMOVE_CONDITION_RULE': {
            const idx = currentAttributeIdx();
            const conditions = state.attributes[idx].conditions;
            const newRules = [
                ...conditions.rules.slice(0, action.index),
                ...conditions.rules.slice(action.index + 1)
            ];

            return updateSelectedAttribute({
                conditions: {
                    ...state.attributes[idx].conditions,
                    rules: newRules
                }
            });
        }

        case 'FORM_UPDATED_SELECTED_ATTRIBUTE': {
            return updateSelectedAttribute(action.update);
        }

        case 'FORM_SAVE': {
            return {
                ...state,
                saving: true
            };
        }

        case 'FORM_SAVE_ERROR': {
            return {
                ...state,
                saving: false
            };
        }

        case 'FORM_SAVE_ATTRIBUTE_SUCCESS': {
            const { prevId, id, result } = action;
            if (id === prevId) {
                return {
                    ...state,
                    attributes: state.attributes.map(a => ({
                        ...a,
                        dirty: a.id === prevId ? false : a.dirty
                    }))
                };
            }
            return {
                ...state,
                attributes: state.attributes.map(a => ({
                    ...a,
                    id: a.id === prevId ? id : a.id,
                    path: a.id === prevId ? result.path : a.path,
                    exportPath: a.id === prevId ? result.exportPath : a.exportPath,
                    dirty: a.id === prevId ? false : a.dirty
                })),
                selectedAttributeId: state.selectedAttributeId === prevId ? id : state.selectedAttributeId
            };
        }

        case 'FORM_SAVE_ATTRIBUTES_SUCCESS': { //Update only
            const { ids } = action;
            return {
                ...state,
                attributes: state.attributes.map(a => ({
                    ...a,
                    dirty: ids.includes(a.id) ? false : a.dirty
                }))
            };
        }

        case 'FORM_SAVE_SUCCESS': {

            return {
                ...state,
                saving: false,
                dirty: false,
                deleted: [],
                errors: {}
            };
        }

        case 'FORM_SAVE_VALIDATION_ERROR': {
            const { errors } = action;

            const errorAttributeIds = Object.keys(errors).filter(i => i !== 'settings').map(parseInt);
            const errorAttribute = state.attributes.find(a => errorAttributeIds.indexOf(a.id) !== -1);

            return {
                ...state,
                saving: false,
                // Switch to the first attribute with errors
                selectedAttributeId: (errorAttribute && errorAttribute.id) || state.selectedAttributeId,
                // If there was an error in the form settings, switch the view
                settingsView: errors.hasOwnProperty('settings') ? 'settings' : state.settingsView,
                errors
            };
        }

        case 'FORM_UPDATE_SETTINGS': {
            const { name, title, titleAttributeId, secondaryTitleAttributeId, thankyou, style, allowMemberUpdate, allowOwnRecordDelete, visible, private: formPrivate } = action;

            return {
                ...state,
                dirty: true,
                name,
                title,
                titleAttributeId,
                secondaryTitleAttributeId,
                thankyou,
                style,
                allowMemberUpdate,
                allowOwnRecordDelete,
                visible,
                private: formPrivate
            };
        }

        // is only updating errors for the selected attribute
        case 'FORM_VALIDATE_SELECTED_ATTRIBUTE': {
            const { errors } = action;

            return {
                ...state,
                errors
            };
        }

        default: {
            return state;
        }
    }
}

export default formReducer;
