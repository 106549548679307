import { Component, Input, input, Output, EventEmitter, computed, OnChanges, SimpleChanges } from "@angular/core";
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from "@angular/forms";
import { ValidateEqual } from 'src/app/core/validators/validator';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { HomeProject, OrganisationFolder } from "./home.models";

@Component({
  templateUrl: './home-item-remove.component.html',
  selector: 'app-home-item-remove',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    InputTextModule
  ]
})
export class HomeItemRemoveComponent implements OnChanges {

  deletionItem = input.required<HomeProject | OrganisationFolder | null>();
  @Input() isVisibleRemoveDialog: boolean = false;
  @Output() handlingDeletionChange = new EventEmitter<{ item: HomeProject | OrganisationFolder; isRemoved: boolean }>();

  removeProjectForm:FormGroup;
  type = computed<'Project' | 'Folder'>(() => this.deletionItem()?.type === 'PROJECT' ? 'Project' : 'Folder');

  ngOnChanges() {
    this.removeProjectForm = new FormGroup(
      this.type() === 'Project' && {slug: new FormControl<string>('', [Validators.required, ValidateEqual(this.deletionItem()['slug'])])}
    );
  }

  onHideDialog(isRemoved: boolean = false) {
    this.isVisibleRemoveDialog = false;
    this.handlingDeletionChange.emit({item: this.deletionItem(), isRemoved});
  }
}
