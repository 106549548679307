import { Component, Input, Output, EventEmitter, inject, signal, computed } from '@angular/core';
import { NgFor } from '@angular/common';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from "@angular/forms";
import { ApiKey } from "../../../core/models";
import { ApiService } from '../../../core/services/api.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmationService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { ClipboardDirective } from '../../../shared/directives/clipboard.directive'
@Component({
    selector: "app-project-api-keys",
    templateUrl: "./project-api-keys.component.html",
    providers: [ConfirmationService],
    imports: [
        ButtonModule,
        DialogModule,
        ReactiveFormsModule,
        NgFor,
        InputTextModule,
        PanelModule,
        ConfirmDialogModule,
        ClipboardDirective
    ],
    standalone: true,
})
export class ProjectApiKeysComponent {

    confirmationService = inject(ConfirmationService);
    apiService = inject(ApiService);

    readonly apikeys = signal<ApiKey[]>([]);
    readonly apikeysLoaded = signal<boolean>(false);
    readonly showEmpty = computed(() => this.apikeys().length === 0 && this.apikeysLoaded());

    // @Input() apikeys: ApiKey[];
    @Input() projectId: number;
    @Input() role: string;
    visible: boolean = false;
    apiKeyForm = new FormGroup({
        name: new FormControl('', Validators.required)
    });

    ngOnInit() {
        this.loadApiKeys();
    }

    loadApiKeys() {
        const query = `query CoreoAAGetProjectAPIKeys($projectId: Int!){
            project(id: $projectId){
                apiKeys{
                    id
                    key
                    projectIds
                    token
                    userId
                    name
                }
            }
        }`;

        this.apiService.graphql<{ project: any }>(query, { projectId: this.projectId }).subscribe((response) => {
            this.apikeys.set(response.project.apiKeys);
            this.apikeysLoaded.set(true);
        });
    }

    open() {
        this.visible = true;
    }

    reset() {
        this.apiKeyForm.reset();
        this.apiKeyForm.markAsPristine();
        this.visible = false;
    }

    createToken() {
        if (!this.apiKeyForm.valid) {
            return;
        }

        var query = `mutation CoreoAACreateProjectAPIKey($input: createApiKeyDataInput!){
            createProjectApiKey(input: $input){ 
                id
                key
                projectIds
                token
                userId
                name
            }
        }`;

        const input = {
            projectId: this.projectId,
            name: this.apiKeyForm.value.name
        };

        this.apiService.graphql<{ createProjectApiKey: ApiKey }>(query, { input }).subscribe((response) => {
            this.apikeys.set([...this.apikeys(), response.createProjectApiKey]);
            this.reset();
        });
    }

    revokeToken(event: Event, apiKey: ApiKey) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to revoke this key?',
            target: event.target,
            header: 'Revoke Key',
            acceptIcon: 'pi pi-check mr-2',
            rejectIcon: 'pi pi-times mr-2',
            rejectButtonStyleClass: 'p-button',
            acceptButtonStyleClass: 'p-button-outlined p-button',
            accept: () => {
                const query = `mutation CoreoAADeleteProjectAPIKey($input: projectApiKeyDeleteInput!){
                    deleteProjectApiKey(input: $input)
                }`;
                const input = {
                    projectId: this.projectId,
                    id: apiKey.id
                };

                this.apiService.graphql<{ revokeProjectApiKey: number }>(query, { input }).subscribe(() => {
                    this.apikeys.update((keys) => keys.filter(k => k.id !== apiKey.id));
                });
            }
        })
    }
}
