<p-panel class="block">
    <ng-template pTemplate="header">
        <div class="flex items-center justify-between">
            <h2 class="font-bold text-lg text-grey-100">Project Settings</h2>
            <p-button *ngIf="canClone" type="button" [outlined]="true" size="large"
                (click)="isVisibleCloneDialog = true">Clone Project <i class="pi pi-copy ml-2"></i>
            </p-button>
        </div>
    </ng-template>

    <form class="flex flex-col space-y-4" [formGroup]="projectSettingsForm">
        <div class="flex flex-col gap-1">
            <label for="name">Project Name</label>
            <input type="text" pInputText [readonly]="role !== 'admin'" id="name" formControlName="name"
                placeholder="Project Name" />
        </div>
        <div class="flex flex-col gap-1">
            <label for="description">Project Description</label>
            <input type="text" pInputText [readonly]="role !== 'admin'" id="description" formControlName="description"
                placeholder="Description" />
        </div>

        <ng-container *ngIf="currentProject.bespoke">
            <div class="flex flex-col gap-1">
                <label for="fromAddress">From Address</label>
                <input type="email" pInputText [readonly]="role !== 'admin'" id="fromAddress"
                    formControlName="fromAddress" placeholder="From Email Address" />
            </div>
            <div class="flex flex-col gap-1">
                <label for="accountVerificationUrl">Account Verification URL</label>
                <input type="url" pInputText [readonly]="role !== 'admin'" id="accountVerificationUrl"
                    formControlName="accountVerificationUrl" placeholder="Account Verification URL" />
            </div>
            <div class="flex flex-col gap-1">
                <label for="passwordResetUrl">Password Reset URL</label>
                <input type="url" pInputText [readonly]="role !== 'admin'" id="passwordResetUrl"
                    formControlName="passwordResetUrl" placeholder="Password Reset URL" />
            </div>
            <div class="flex flex-col gap-1">
                <label for="androidAppUrl">Google Play Store URL</label>
                <input type="url" pInputText [readonly]="role !== 'admin'" id="androidAppUrl"
                    formControlName="androidAppUrl" placeholder="Google Play Store URL" />
            </div>
            <div class="flex flex-col gap-1">
                <label for="iosAppUrl">App Store URL</label>
                <input type="url" pInputText [readonly]="role !== 'admin'" id="iosAppUrl" formControlName="iosAppUrl"
                    placeholder="App Store URL" />
            </div>
        </ng-container>

        <ng-container *ngIf="isAdmin">
            <div class="flex flex-col gap-1">
                <label for="slug">Project Slug</label>
                <input type="text" pInputText id="slug" formControlName="slug" />
            </div>
            <div class="flex flex-col gap-1">
                <label for="usersLimit">Users limit (0 is unlimited)</label>
                <input type="number" pInputText id="usersLimit" formControlName="usersLimit" min="0" />
            </div>
            <div class="flex flex-col gap-1">
                <label for="locked">Lock Project</label>
                <p-checkbox formControlName="locked" [binary]="true" inputId="locked"></p-checkbox>
            </div>
            <div class="flex flex-col gap-1">
                <label for="allowContributors">Allow Contributors</label>
                <p-checkbox formControlName="allowContributors" [binary]="true"
                    inputId="allowContributors"></p-checkbox>
            </div>
        </ng-container>
    </form>

    <ng-template pTemplate="footer">
        <div class="flex justify-end">
            <p-button type="button" size="large" (click)="onSubmitUpdate()"
                [disabled]="!projectSettingsForm.valid || !projectSettingsForm.dirty">Update Settings</p-button>
        </div>
    </ng-template>
</p-panel>

<p-panel class="p-0 block">
    <ng-template pTemplate="header">
        <div class="flex justify-between">
            <h2 class="font-bold text-lg text-grey-100">Project Bounds</h2>
            <p-button type="button" [outlined]="true" (click)="startBoundsUpload()">Upload GeoJSON or
                Shapefile</p-button>
            <input type="file"
                accept=".geojson, zip, application/octet-stream, application/zip, application/x-zip, application/x-zip-compressed"
                (change)="handleBoundsFileChange($event)" id="project-settings-bounds-upload" class="hidden" />
        </div>
    </ng-template>

    <div class="w-full h-96 relative map-wrapper">
        <div class="absolute inset-0" #mapContainer></div>
    </div>

    <ng-template pTemplate="footer">
        <div class="flex justify-end">
            <p-button type="button" size="large" (click)="saveProjectBounds()" [disabled]="!boundsDirty">Save
                Bounds</p-button>
        </div>
    </ng-template>
</p-panel>

<p-panel class="block">
    <ng-template pTemplate="header">
        <h2 class="font-bold text-lg text-danger">Danger Zone</h2>
    </ng-template>

    <!-- <ng-template pTemplate="footer"> -->
    <div class="flex items-cemter justify-between">
        <div>
            <p class="text-danger font-bold">Delete Project</p>
            <span>Deleting your project is irreversible</span>
        </div>
        <p-button type="button" size="large" severity="danger" (click)="isVisibleRemoveDialog = true"><i
                class="pi pi-trash mr-2"></i> Delete Project</p-button>
    </div>
    <!-- </ng-template> -->
</p-panel>

<p-toast position="bottom-center" [life]="2000"></p-toast>

<app-project-clone #cloneDialog [isVisibleCloneDialog]="isVisibleCloneDialog" [isAdmin]="this.isAdmin"
    [organisationId]="organisationId" [projectId]="currentProject.id"
    (visibleCloneChange)="onVisibleCloneChange($event)"></app-project-clone>
<app-project-remove [isVisibleRemoveDialog]="isVisibleRemoveDialog" [projectId]="currentProject.id"
    [projectSlug]="currentProject.slug" (visibleRemoveChange)="onVisibleRemoveChange($event)"></app-project-remove>