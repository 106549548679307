import { Component, EventEmitter, Input, OnInit, Output, ViewChild, inject, signal } from '@angular/core';
import { CollectionTableComponent } from './collection-table/collection-table.component';
import { CollectionAttributeTableComponent } from './attribute-table/attribute-table.component';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CollectionImportType, ImportCollectionModalComponent } from './import-collection-modal/import-collection-modal.component';
import { ApiService } from '../../../core/services/api.service';
import { CoreoJob, JobsService, jobFieldsFragment } from '../../../core/services/jobs.service';
import { catchError, filter, of, take, tap } from 'rxjs';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
    selector: 'app-collection-detail',
    templateUrl: 'collection-detail.component.html',
    imports: [
        ButtonModule,
        DynamicDialogModule,
        CollectionTableComponent,
        CollectionAttributeTableComponent,
        ReactiveFormsModule,
        InputTextModule,
        MenuModule,
        ConfirmDialogModule,
        ToastModule,
        ProgressSpinnerModule
    ],
    providers: [
        DialogService,
        ConfirmationService,
        MessageService
    ],
    standalone: true,
    styles: [`:host { @apply block h-full w-full relative;}`]
})

export class CollectionDetailComponent implements OnInit {

    jobService = inject(JobsService);

    @Input() projectId: number;
    @Input() collectionId: number;

    @Output() item: EventEmitter<number> = new EventEmitter();
    @Output() delete: EventEmitter<void> = new EventEmitter();

    @ViewChild('itemsTable', { static: false }) itemsTable: CollectionTableComponent;
    @ViewChild('attributeTable', { static: false }) attributeTable: CollectionAttributeTableComponent;

    private dialogRef: DynamicDialogRef | undefined;

    public collectionName: string;
    public editName: boolean = false;
    public nameForm = new FormGroup({
        name: new FormControl('', Validators.required),
    });
    public nameLoading: boolean = false;
    public settingsItems: MenuItem[] = [
        {
            label: 'Delete collection',
            icon: 'fa-solid fa-trash-can',
            class: 'text-danger',
            command: ($event) => this.deleteCollectionCheck($event)
        }
    ];
    public importItems: MenuItem[] = [
        { label: 'From CSV', command: () => this.importCollection('csv') },
        { label: 'GeoJSON', command: () => this.importCollection('geojson') },
        { label: 'Zipped Shapefile', command: () => this.importCollection('shapefile') }
    ];

    processing = signal(false);

    constructor(
        private dialogService: DialogService,
        private apiService: ApiService,
        private confirmationService: ConfirmationService,
    ) { }

    ngOnInit() {
        this.loadCollection();
    }

    private loadCollection() {
        const query = `query loadCollection($projectId: Int!, $collectionId: Int!){
            project(id: $projectId){
                collections(where: { id: $collectionId }){
                    name
                }
            }
        }`;

        this.apiService.graphql<{ project: { collections: { name: string; }[] } }>(query, { projectId: this.projectId, collectionId: this.collectionId, }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                const collection = res.project.collections[0];
                this.collectionName = collection.name;
            }
        });
    }

    public deleteCollectionCheck($event) {
        this.confirmationService.confirm({
            defaultFocus: 'none',
            message: 'Are you sure you want to delete this collection, and all items within?<br>This cannot be undone.',
            target: $event.target,
            header: 'Delete collection',
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Delete collection',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
            accept: () => {
                this.deleteCollection();
            }
        });
    }

    private deleteCollection() {
        this.delete.emit();
    }

    get name() {
        return this.nameForm.get('name');
    }

    public updateName() {
        this.nameLoading = true;

        const query = `mutation updateCollectionName($input: CollectionUpdateInput!){
            updateCollection(input: $input){
                name
            }
        }`;

        const input = {
            id: this.collectionId,
            name: this.name.value
        };

        this.apiService.graphql<{ updateCollection: { name: string } }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.collectionName = res.updateCollection.name;
                this.editName = false
            }
            this.nameLoading = false;
        });
    }

    public importCollection(importType: CollectionImportType) {
        const headerMap = {
            csv: 'CSV',
            geojson: 'geoJSON',
            shapefile: 'shapefile'
        }
        const header = `Import from ${headerMap[importType]}`;

        this.dialogRef = this.dialogService.open(ImportCollectionModalComponent, {
            header,
            data: {
                collectionId: this.collectionId,
                importType
            },
            width: '420px'
        });

        this.dialogRef.onClose.subscribe((job: CoreoJob) => {
            if (job) {
                this.jobService.addJob(job).subscribe({
                    complete: () => {
                        this.refreshAll();
                    }
                });
            }
        });
    }

    public exportCollection() {
        const mutation = `mutation CoreoAAExportCollection($input: exportCollectionToCSVInput!){
            exportCollectionToCSV(input: $input){
                ...jobFields
            }
        }
        ${jobFieldsFragment}`;
        const input = {
            id: this.collectionId
        };
        this.apiService.graphql<{ exportCollectionToCSV: CoreoJob }>(mutation, { input }).subscribe((response) => {
            this.jobService.addJob(response.exportCollectionToCSV);
        });
    }

    public refreshItems() {
        this.itemsTable.refresh(true);
    }

    public refreshAttributes() {
        this.attributeTable.refresh()
    }

    public refreshAll() {
        this.refreshItems()
        this.refreshAttributes();
    }
}