
const initialState = {
    ready: false,
    dirty: false,
    settingsView: 'build',
    config: {},
    mediaItems: [],
    saving: false,
    errors: []
};

export const pageReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'PAGE_INIT': {
            const { page } = action;
            return {
                ...state,
                ...page,
                errors: [],
                settingsView: page.type === 'custom' ? 'build' : 'settings',
                ready: true,
                dirty: false
            };
        }

        case 'PAGE_SAVE': {
            return {
                ...state,
                saving: true
            }
        }

        case 'PAGE_SAVE_SUCCESS': {
            return {
                ...state,
                saving: false,
                dirty: false,
                errors: []
            };
        }


        case 'PAGE_SAVE_ERROR': {
            const { errors } = action;
            return {
                ...state,
                saving: false,
                errors
            };
        }

        case 'PAGE_SWITCH_VIEW': {
            const { view } = action;
            return {
                ...state,
                settingsView: view,
            };
        }

        case 'PAGE_UPDATE_SETTINGS': {
            const { title, route, icon, permissions, published, listed, config } = action;
            return {
                ...state,
                title,
                route,
                icon,
                permissions,
                published,
                listed,
                config,
                dirty: true,
            }
        }

        case 'PAGE_UPDATE_CONFIG': {
            const { config } = action;
            return {
                ...state,
                config,
                dirty: true
            };
        }

        case 'PAGE_PUBLISH_SUCCESS': {
            return {
                ...state,
                published: true
            }
        }

        case 'PAGE_UNPUBLISH_SUCCESS': {
            return {
                ...state,
                published: false
            }
        }

        case 'PAGE_CREATE_MEDIA': {
            const { mediaItem } = action;
            return {
                ...state,
                mediaItems: [
                    ...state.mediaItems,
                    mediaItem
                ],
                dirty: true
            }
        }

        case 'PAGE_CREATE_MEDIA_SUCCESS': {
            const { mediaItemId, prevId, url } = action;

            const idx = state.mediaItems.findIndex(m => m.id === prevId);
            const blockIdx = state.config.blocks.findIndex(b => b.type === 'media' && b.data && b.data.file && b.data.file.id === prevId);

            const { data: { file: { fileId, ...restOfBlockFile } } } = state.config.blocks[blockIdx];

            return {
                ...state,
                mediaItems: [
                    ...state.mediaItems.slice(0, idx),
                    {
                        ...state.mediaItems[idx],
                        id: mediaItemId,
                        url
                    },
                    ...state.mediaItems.slice(idx + 1)
                ],
                config: {
                    ...state.config,
                    blocks: [
                        ...state.config.blocks.slice(0, blockIdx),
                        {
                            ...state.config.blocks[blockIdx],
                            data: {
                                ...state.config.blocks[blockIdx].data,
                                file: {
                                    ...restOfBlockFile,
                                    url,
                                    id: mediaItemId
                                }
                            }
                        },
                        ...state.config.blocks.slice(blockIdx + 1)
                    ]
                }
            };
        }

        case 'PAGE_DELETE_MEDIA_SUCCESS': {
            const { ids } = action;
            return {
                ...state,
                mediaItems: state.mediaItems.filter(m => !ids.includes(m.id))
            };
        }

        default: {
            return state;
        }
    }
}

export default pageReducer;