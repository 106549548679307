import { NgFor } from '@angular/common';
import { Component, Input, signal, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ConfirmationService, MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { ApiService } from 'src/app/core';
import { Hook } from 'src/app/core/models/hook.model';

interface HookType {
    key: string;
    name: string;
    icon: string;
    action: string;
    actionLabel: string;
}

interface EventType {
    event: string;
    eventLabel: string;
    selected: boolean;
}

@Component({
    selector: "app-project-hooks",
    templateUrl: "./project-hooks.component.html",
    providers: [ConfirmationService, MessageService],
    imports: [
        PanelModule,
        DialogModule,
        ConfirmDialogModule,
        ToastModule,
        NgFor,
        ReactiveFormsModule,
        InputTextModule,
        DropdownModule,
        CheckboxModule,
        FormsModule
    ],
    standalone: true,
})
export class ProjectHooksComponent implements OnInit {
    @Input() role: string;
    // Need to convert to model types
    @Input() project: any;
    @Input() surveys: any[];

    visible: boolean = false;
    hookForm: FormGroup;

    hookTypes: HookType[] = [
        { 
            key: 'slack', 
            name: 'Slack', 
            icon: '../../assets/icons/slack.png',
            action: 'post',
            actionLabel: 'Post Message'
        },
        { 
            key: 'webhook',
            name: 'Webhook',
            icon: '../../assets/icons/webhook.png',
            action: 'post',
            actionLabel: 'Post Data'
        }
    ];

    events: EventType[] = [
        {
            event: 'records.beforeCreate',
            eventLabel: 'Before Record Create',
            selected: false
        },
        {
            event: 'records.beforeUpdate',
            eventLabel: 'Before Record Update',
            selected: false
        },
        {
            event: 'records.create',
            eventLabel: 'Record Created',
            selected: false
        },
        {
            event: 'records.unsafeImage',
            eventLabel: 'Record Unsafe Image Detected',
            selected: false
        },
        {
            event: 'records.update',
            eventLabel: 'Record Updated',
            selected: false
        },
        {
            event: 'projectUsers.create',
            eventLabel: 'User Joined Project',
            selected: false
        }
    ];

    hooks: Hook[] = [];
    hook = signal<Hook>(null);
    hookTypeName = signal<string>('');
    hookOptions = signal<HookType[]>([]);
    step = signal<1 | 2>(1);

    private readonly getProjectHooksQuery: string = `query AAGetProjectHook($projectId: Int!) {
        hooks(where: {projectId: $projectId}) {
            id,
            action,
            type,
            config,
            active,
            events,
            allowSubmitOnFail
        }
    }`;

    private readonly createProjectHookMutation: string = `mutation AACreateProjectHook($input: createHookDataInput!){
        createProjectHook(input: $input) {
            id,
            type,
            config,
            events,
            action,
            active,
            allowSubmitOnFail
        }
    }`;

    private readonly updateProjectHookMutation: string = `mutation AAUpdateProjectHook($input: projectHookUpdateInput!){
        updateProjectHook(input: $input) {
            id,
            type,
            config,
            events,
            action,
            active,
            allowSubmitOnFail
        }
    }`;

    private readonly deleteProjectHookMutation: string = `mutation AADeleteProjectHook($input: projectHookDeleteInput!){
        deleteProjectHook(input: $input)
    }`;

    constructor(
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private apiService: ApiService
    ) {
        this.hookForm = new FormGroup({
            type: new FormControl('', Validators.required),
            action: new FormControl('', Validators.required),
            url: new FormControl('', Validators.required),
            allowSubmitOnFail: new FormControl(false)
        });
    }

    ngOnInit() {
        this.initHooks();
        this.initSurveys();
        this.initEvents();
    }

    initHooks() {
        this.apiService.graphql<{ hooks: Hook[] }>(this.getProjectHooksQuery, { projectId: this.project.id }).subscribe(res => {
            this.hooks = res.hooks
        });
    }

    initSurveys() {
        this.surveys = this.surveys.map(survey => ({
            ...survey,
            selected: false
        }));
    }

    initEvents() {
        this.events = this.events.map(event => ({
            ...event,
            selected: false
        }));
    }

    open() {
        this.visible = true;
    }

    submit() {
        const surveys = this.surveys.filter(survey => survey.selected).map(survey => survey.id);
        const events = this.events.filter(event => event.selected).map(event => event.event);
        const type = this.hookForm.get('type').value;
        const action = this.hookForm.get('action').value;
        const url = this.hookForm.get('url').value;
        const allowSubmitOnFail = this.hookForm.get('allowSubmitOnFail').value;

        let data = {
            active: true,
            action,
            config: {
                surveys,
                url,
            },
            events,
            type,
            allowSubmitOnFail
        };

        if (this.hook().id) {
            this.updateHook({...data, id: this.hook().id }).subscribe((res) => {
                const updatedHook = res.updateProjectHook;
                const idx = this.hooks.findIndex((h) => h.id === updatedHook.id);
                this.hooks = [
                    ...this.hooks.slice(0, idx),
                    updatedHook,
                    ...this.hooks.slice(idx + 1)
                ]
                this.showMessage('Hook updated');
                this.reset();
            });
        } else {
            this.createHook({...data, projectId: this.project.id }).subscribe((res) => {
                const createdHook = res.createProjectHook;
                this.hooks = [
                    ...this.hooks,
                    createdHook,
                ]
                this.showMessage('Hook created');
                this.reset();
            });
        }
    }

    updateHook(input: any) {
        return this.apiService.graphql<{ updateProjectHook: Hook }>(this.updateProjectHookMutation, {
            input: {
                ...input
            }
        });
    }

    createHook(input: any) {
        return this.apiService.graphql<{ createProjectHook: Hook }>(this.createProjectHookMutation, {
            input: {
                ...input
            }
        });
    }

    showMessage(message: string, severity: string = 'success') {
        if (message) {
            this.messageService.add({ severity, detail: message})
        }
    }

    selectType(type: HookType) {
        if (!type) {
            return;
        }
        this.hookTypeName.set(type.name);
        this.hook.set({ type: type.key, action: type.action });
        this.hookOptions.set([type]);
        this.hookForm.patchValue({
            type: type.key
        });
        this.step.set(2);
    }

    reset() {
        setTimeout(() => this.step.set(1), 300);
        this.hookForm.reset();
        this.hookForm.markAsPristine();
        this.visible = false;
        this.hook.set(null);
        this.hookOptions.set(null);
        this.hookTypeName.set('');
        this.initSurveys();
        this.initEvents();
    }

    getIcon(key: string) {
        const type = this.hookTypes.find(t => t.key === key);
        return type ? type.icon : '';
    }

    deleteHook(event: Event, hook: Hook) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this hook?',
            target: event.target,
            header: 'Delete Hook',
            acceptIcon: 'pi pi-check mr-2',
            rejectIcon: 'pi pi-times mr-2',
            rejectButtonStyleClass: 'p-button',
            acceptButtonStyleClass: 'p-button-outlined p-button',
            accept: () => {
                const idx = this.hooks.findIndex((h) => h.id === hook.id);
                this.apiService.graphql<{ deleteProjectHook: number}>(this.deleteProjectHookMutation, {
                    input: {
                        id: hook.id
                    }
                }).subscribe((res) => {
                    const { deleteProjectHook } = res;
                    if (deleteProjectHook) {
                        this.hooks = [
                            ...this.hooks.slice(0, idx),
                            ...this.hooks.slice(idx + 1)
                        ];
                        this.showMessage('Hook deleted');
                    }
                });
            }
        })
    }

    editHook(event: Event, hook: Hook) {
        this.hookForm.patchValue({
            type: hook.type,
            action: hook.action,
            url: hook.config.url,
            allowSubmitOnFail: hook.allowSubmitOnFail,
        });

        this.hook.set({ 
            id: hook.id, 
            type: hook.type, 
            action: hook.action,
            allowSubmitOnFail: hook.allowSubmitOnFail,
        });

        this.surveys.forEach(survey => {
            survey.selected = hook.config.surveys.includes(survey.id);
        });

        this.events.forEach(event => {
            event.selected = hook.events.includes(event.event);
        });

        const selectedHookType = this.hookTypes.find(type => type.key === hook.type);
        this.hookOptions.set([selectedHookType]);
        this.step.set(2);
        this.visible = true;
    }
}
