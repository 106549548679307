import { isArray, cloneDeep } from 'lodash';
// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import es6Def from '../../../../es6';

// these do not need to be user submitted as they come from the current user
const TEST_FIELDS_BLACKLIST = ['$username', '$displayName'];

const attributeAndCollectionTypeTypeMappings = {
    text: 'string',
    date: 'string',
    datetime: 'string',
    email: 'string',
    float: 'number',
    integer: 'number',
    url: 'string',
    boolean: 'boolean',
    select: 'Item',
    multiselect: 'Item[]',
};

export default class ProjectFormsBlockEditorGeometryStylingController {
    static $inject = ['$scope', '$mdDialog', '$element', '$timeout', 'block', 'attributes', 'collections', 'user', 'ProjectService'];
    constructor($scope, $mdDialog, $element, $timeout, block, attributes, collections, user, ProjectService) {
        this.attributes = attributes;
        this.collections = angular.copy(collections);
        this.collectionsMap = this.collections.reduce((m, c) => {
            m[c.id] = c;
            return m;
        }, {});
        this.user = user;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.$element = $element;
        this.$timeout = $timeout;
        this.ProjectService = ProjectService;
        this.block = block;
        this.editor = null;
        this.es5Expression = null;
        this.runtimeError = null;
        this.validationError = null;
        this.testFields = [];
        this.loading = true;
        this.itemDataLoading = false;
        this.libSource = '';
        this.babel = null;
        this.projectId = this.ProjectService.getProjectId();
        import(/* webpackChunkName: "@babel/standalone" */'@babel/standalone').then(pkg => this.babel = pkg);
        $scope.$on("$destroy", () => monaco.editor.getModels().forEach(model => model.dispose()));
    }

    $onInit() {
        import(/* webpackChunkName: "coreo-expressions" */'@natural-apptitude/coreo-expressions/dist/esm').then(pkg => {
            const ExpressionEvaluator = pkg.CoreoExpressionEvaluator;
            const itemResolver = (collectionId, key) => {
                const collection = this.collections.find(c => c.id === collectionId);
                if (typeof collection === 'undefined') {
                    return null;
                }
                return collection.items.find(a => a.key === key);
            }

            this.expressionEvaluator = new ExpressionEvaluator(this.attributes.map(attr => ({ ...attr })), itemResolver, this.user);
            this.fieldNamesMap = this.expressionEvaluator.getRecordFieldNamesMap();
            if (this.block && this.block.config && this.block.config.expression) {
                this.block.config.expression = this.expressionEvaluator.deserialiseExpression(this.block.config.expression);
            }
            // Initialise any exisitng field variables
            this.buildCustomLibAndFieldNames();
            this.setTestFieldValues(this.block.config.expression);
        });
    }

    buildCustomLibAndFieldNames() {
        const fieldNames = this.expressionEvaluator.getAvailableFieldNames();
        let libSource = `interface Item {
            key: string;
            value: string;
            data: any;
        }
        `;
        this.fields = fieldNames.map(name => {
            const originalUuid = this.fieldNamesMap[name];

            // Filter out this block
            if (originalUuid === this.block.uuid) return;

            const attribute = this.attributes.find(({ uuid }) => uuid === originalUuid);

            if (!attribute) {
                libSource += `declare const ${name}: string; `;
                return { name, type: 'string' }
            }

            let type = attributeAndCollectionTypeTypeMappings[attribute.type];
            if (!type && attribute.questionType === 'expression') {
                const configType = attribute.config.type;
                if (configType === 'text' || configType === 'datetime') type = 'string';
                if (configType === 'float') type = 'number';
                if (configType === 'boolean') type = 'boolean';
            }

            libSource += `declare const ${name}: ${type}; `;

            return {
                name,
                type,
                collectionId: attribute.collectionId
            };
        }).filter(Boolean);
        this.libSource = libSource;
    }

    save() {
        this.es5Expression = this.transpileExpression(this.editor.getValue());
        const serialisedExpression = this.expressionEvaluator.serialiseExpression(this.editor.getValue());
        this.$mdDialog.hide({ es5Expression: this.es5Expression, expression: serialisedExpression });
    }

    transpileExpression(expression, ast = false) {
        expression = `(()=>{${expression}})()`;
        expression = this.expressionEvaluator.serialiseExpression(expression);

        return this.babel.transform(expression, {
            presets: ['env'],
            ast
        }).code;
    }

    evaluateExpression() {
        this.output = null;
        this.runtimeError = null;
        let recordData = {};
        this.testFields.forEach(({ name, value }) => recordData[this.attributes.find(a => a.uuid === this.fieldNamesMap[name]).path] = value);
        this.expressionEvaluator.setRecord({ data: recordData });
        this.expressionEvaluator.setExpressionAttribute({ ...this.block, config: { ...this.block.config, es5Expression: this.es5Expression } });
        this.expressionEvaluator.evaluateExpression().then((output) => this.output = output).catch(e => this.runtimeError = e.message);
    }

    execute() {
        console.log('Executng');
        this.es5Expression = this.transpileExpression(this.editor.getValue());
        this.evaluateExpression();
    }

    cancel() {
        this.$mdDialog.hide();
    }

    setTestFieldValues(text) {
        if (!text) return;

        this.fields
            .filter(field => !TEST_FIELDS_BLACKLIST.includes(field.name))
            .forEach((field) => {
                if (text.includes(field.name) && !this.testFields.some(({ name }) => name === field.name)) {
                    // If text includes the field and the field hasn't already been added
                    // added it
                    this.testFields.push({ name: field.name, value: null, type: field.type, collectionId: field.collectionId });
                } else if (!text.includes(field.name) && this.testFields.some(({ name }) => name === field.name)) {
                    // If text doesn't include the field and the field has already been added
                    // remove it
                    this.testFields = this.testFields.filter(({ name }) => name !== field.name);
                }
            });
    }

    async updateTestFieldValue(field) {
        const ogIndex = this.testFields.findIndex(({ name }) => field.name === name);

        this.testFields[ogIndex] = {
            ...field,
            value: field.type === 'number' ? parseFloat(field.value, 10) : field.value,
        };

        const testField = this.testFields[ogIndex];
        const val = testField.value;
        if (testField.collectionId && val) {
            this.itemDataLoading = true;
            const items = Array.isArray(val) ?
                await this.ProjectService.getCollectionItemsByKeys(this.projectId, testField.collectionId, val) :
                [await this.ProjectService.getCollectionItemByKey(this.projectId, testField.collectionId, val)]

            const collectionIdx = this.collections.findIndex(c => c.id === testField.collectionId);
            const collection = angular.copy(this.collections[collectionIdx]);
            for (const item of items) {
                const oldItem = collection.items.find(i => i.id === item.id);
                oldItem.data = item.data;
            }
            this.collections = [
                ...this.collections.slice(0, collectionIdx),
                collection,
                ...this.collections.slice(collectionIdx + 1)
            ]
            // this.expressionEvaluator.setCollections(this.collections);
            this.itemDataLoading = false;
        }
    }

    onEditor(editor) {
        this.editor = editor;
        console.log('EDITOR!', editor);
        const monaco = window.monaco;


        // Define custom es2015 lib
        monaco.languages.typescript.typescriptDefaults.addExtraLib(es6Def, 'es6.d.ts');

        // Define new lib with custom variables and types
        const libUri = 'ts:filename/fields.d.ts';
        monaco.languages.typescript.typescriptDefaults.addExtraLib(this.libSource, libUri);
        monaco.editor.createModel(this.libSource, 'typescript', monaco.Uri.parse(libUri));

        // Setup watcher to track errors
        monaco.editor.onDidChangeMarkers(() => {
            const modelMarkers = monaco.editor.getModelMarkers();
            const errors = modelMarkers.filter(marker => marker.severity === 8);
            const oldError = this.validationError;
            this.validationError = errors.length > 0 ? errors[0].message : null;
            if (oldError !== this.validationError) this.$scope.$apply();
        });



        // Setup watcher to track editor content change and update field variables
        this.editor.onDidChangeModelContent(() => {
            this.setTestFieldValues(this.editor.getValue())
            this.$scope.$apply();
        });

        this.loading = false;
    }
}
