import { ApplicationRef, Component, ComponentFactoryResolver, computed, effect, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, viewChild, ViewContainerRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { CollectionItemMediaListComponent } from './collection-item-media-list.component';
import { CollectionItemService } from './collection-item.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CollectionItemPreviewComponent } from './collection-item-preview.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { environment } from 'src/environments/environment';
import { Feature } from 'geojson';
import mapboxgl, { LngLatBoundsLike, LngLatLike, Map as MapboxMap } from 'mapbox-gl';
import bbox from '@turf/bbox';
import { catchError, of, take } from 'rxjs';
import { ToastModule } from 'primeng/toast';
import { MapMenuControlComponent } from 'src/app/shared/components/map-menu-control/map-menu-control.component';
import { MapsService } from 'src/app/core/services/maps.service';

@Component({
    selector: 'app-collection-item-detail',
    templateUrl: 'collection-item-detail.component.html',
    standalone: true,
    imports: [
        ButtonModule,
        PanelModule,
        ReactiveFormsModule,
        InputTextModule,
        InputNumberModule,
        CollectionItemMediaListComponent,
        ConfirmDialogModule,
        InputTextareaModule,
        ToastModule,
        MapMenuControlComponent
    ],
    providers: [
        CollectionItemService,
        ConfirmationService,
        DialogService,
        MessageService
    ]
})

export class CollectionItemDetailComponent implements OnInit, OnDestroy {

    collectionItemService = inject(CollectionItemService);
    confirmationService = inject(ConfirmationService);
    dialogService = inject(DialogService);
    messageService = inject(MessageService);
    viewContainerRef = inject(ViewContainerRef);
    mapsService = inject(MapsService);

    /** input signal errors - is not a function - so using old style */
    @Input() itemId: number;
    @Input() collectionId: number;
    @Input() projectId: number;

    @Output() back = new EventEmitter();

    mediaInput = viewChild<ElementRef>('mediaInput');
    collectionItemMap = viewChild<ElementRef>('collectionItemMap');

    loading = this.collectionItemService.loading;
    mediaLoading = this.collectionItemService.mediaLoading;
    collectionName = this.collectionItemService.collectionName;
    item = this.collectionItemService.item;
    geometry = this.collectionItemService.geometry;
    mediaItems = this.collectionItemService.mediaItems;

    attributeQuestions = this.collectionItemService.attributeQuestions;

    valueForm = this.collectionItemService.valueForm;
    valueFormDirty = this.collectionItemService.valueFormDirty;
    valueError = this.collectionItemService.valueError;
    attributeForm = this.collectionItemService.attributeForm;
    attributeFormDirty = this.collectionItemService.attributeFormDirty;

    canSave = computed(() => {
        const valueDirty = this.valueFormDirty();
        const attributeForm = this.attributeForm();
        const attributesDirty = this.attributeFormDirty();
        const loading = this.loading();
        const valueError = this.valueError();
        if (!!attributeForm) {
            return (
                !valueError &&
                !loading &&
                (valueDirty || attributesDirty)
            );
        } else {
            return (
                !valueError &&
                !loading &&
                valueDirty
            );
        }
    });

    private map: MapboxMap;
    private resizeObserver: ResizeObserver;
    private dialogRef: DynamicDialogRef;

    constructor() {
        effect(() => {
            const geometry = this.geometry();
            const map = this.collectionItemMap();
            if (!!geometry && !!map) {
                this.initMap();
            }
        });
    }

    ngOnInit() {
        this.collectionItemService.projectId.set(this.projectId);
        this.collectionItemService.collectionId.set(this.collectionId);
        this.collectionItemService.itemId.set(this.itemId);
        this.collectionItemService.loadItem();
    }

    ngOnDestroy() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    showPreview() {
        const preview = this.collectionItemService.preview();

        this.dialogRef = this.dialogService.open(CollectionItemPreviewComponent, {
            data: {
                preview
            },
            header: 'In app card preview',
            width: '402px',
            height: '75vh',
            modal: true,
            contentStyle: { 'padding': '0px' }
        });
    }

    saveItem() {
        this.collectionItemService.updateItem();
    }

    deleteItemCheck($event) {
        this.confirmationService.confirm({
            defaultFocus: 'none',
            message: 'Are you sure you want to delete this collection item?<br>This cannot be undone.',
            target: $event.target,
            header: 'Delete collection item',
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Delete item',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
            accept: () => {
                this.deleteItem();
            }
        });
    }

    private deleteItem() {
        this.loading.set(true);
        this.collectionItemService.deleteItem(this.itemId).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                /** The graphql error message isn't making it through */
                this.messageService.add({ severity: 'error', summary: 'Error', detail: "Couldn't delete the item" });
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.back.emit();
            }
            this.loading.set(false);
        });
    }

    addMedia($event: Event) {
        $event.preventDefault();
        this.mediaInput().nativeElement.click();
    }

    selectMediaHandler() {
        const files = this.mediaInput().nativeElement.files;
        this.handleFiles(files!);
    }

    private handleFiles(fileList: FileList) {
        if (fileList.length > 0) {
            // TODO: add check for file size? + error message https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
            const file: File = fileList[0];
            this.collectionItemService.createMediaItem(file);
        }
    }

    private initMap() {
        const mapOptions: mapboxgl.MapboxOptions = {
            accessToken: environment.mapboxApiKey,
            container: this.collectionItemMap().nativeElement,
            style: 'mapbox://styles/mapbox/streets-v12',
            projection: { name: 'mercator' },
        };

        const feature: Feature = {
            type: 'Feature',
            geometry: this.geometry(),
            properties: {}
        };

        if (feature.geometry.type === "Point") {
            mapOptions.center = feature.geometry.coordinates as LngLatLike;
            mapOptions.zoom = 16;
        } else {
            const bounds = bbox(feature.geometry);
            mapOptions.bounds = bounds as LngLatBoundsLike;
            mapOptions.fitBoundsOptions = { padding: 20 };
        }

        this.map = new MapboxMap(mapOptions);

        this.map.on('style.load', () => {
            this.map.addSource('item', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [feature]
                }
            });

            if (feature.geometry.type === 'Point') {
                this.map.addLayer({
                    id: 'point',
                    source: 'item',
                    type: 'circle',
                    filter: [
                        'all',
                        ['!has', 'pc'],
                        ['==', '$type', 'Point']
                    ],
                    paint: {
                        'circle-color': '#C521E0',
                        'circle-radius': 10,
                        'circle-opacity': 0.5,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#FFFFFF'
                    }
                });
            } else if (feature.geometry.type === 'LineString') {
                this.map.addLayer({
                    id: 'line',
                    source: 'item',
                    type: 'line',
                    paint: {
                        'line-color': '#F02F1D',
                        'line-width': 3
                    },
                    'filter': ['==', '$type', 'LineString']
                });
            } else if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
                this.map.addLayer({
                    id: 'polygon-outline',
                    source: 'item',
                    type: 'line',
                    paint: {
                        'line-color': '#F02F1D',
                        'line-width': 3
                    },
                    'filter': ['==', '$type', 'Polygon']
                });
                this.map.addLayer({
                    id: 'polygon-fill',
                    source: 'item',
                    type: 'fill',
                    paint: {
                        'fill-color': '#F02F1D',
                        'fill-opacity': 0.2
                    },
                    'filter': ['==', '$type', 'Polygon']
                });
            }
        });

        this.map.scrollZoom.disable();
        this.map.addControl(new mapboxgl.NavigationControl());

        // Add our map layers control
        this.mapsService.attachMapMenuControl(this.map, this.viewContainerRef);

        this.resizeObserver = new ResizeObserver(() => this.map.resize());
        this.resizeObserver.observe(this.collectionItemMap().nativeElement);
    }
}