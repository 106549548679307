<p-toast position="bottom-center" [life]="2000"></p-toast>
<p-dialog header="Confirm Project Deletion" [(visible)]="isVisibleRemoveDialog" [modal]="true" (onHide)="onHideDialog()">
  <form class="w-96" [formGroup]="removeProjectForm">
    <div class="flex flex-col gap-1 mb-4">
      <p class="text-xs">You are about to delete your project. This cannot be undone. If you wish to proceed enter
        <span class="font-bold">{{projectSlug}}</span> below to confirm.
      </p>
      <input pInputText formControlName="slug" />
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button [text]="true" size="large" (click)="onHideDialog()">Cancel</p-button>
    <p-button type="submit" [disabled]="!removeProjectForm.valid" size="large" severity="danger" (click)="onSubmit()">Delete Project</p-button>
  </ng-template>
</p-dialog>