import { DatePipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, Output, OnChanges, SimpleChanges, signal, input, computed } from "@angular/core";
import { SvgIconComponent } from "angular-svg-icon";
import { MenuItem, MenuItemCommandEvent, TooltipOptions } from "primeng/api";
import { MenuModule } from "primeng/menu";
import { HomeProject, OrganisationFolder } from "./home.models";
import { HomeService } from "./home.service";
import { TooltipModule } from "primeng/tooltip";

@Component({
    selector: 'app-home-folder',
    standalone: true,
    templateUrl: './home-folder.component.html',
    styles: [`
        :host {
            @apply flex items-center p-2 bg-white;
            height: 80px;
        }
    `],
    styleUrls: ['./home.styles.scss'],
    imports: [
        NgClass,
        MenuModule,
        SvgIconComponent,
        DatePipe,
        TooltipModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.dropping]': 'dropping()'
    }
})
export class HomeFolderComponent implements OnChanges {
    @HostBinding('attr.draggable') draggable = true;

    @HostListener('dragenter', ['$event']) onDragEnter(event: DragEvent) {
        if (!this.dragging()) {
            this.dropping.set(true);
            event.preventDefault();
        }
    }

    @HostListener('dragstart', ['$event']) onDragStart(event: DragEvent) {
        event.dataTransfer!.effectAllowed = 'move';
        event.dataTransfer!.dropEffect = 'none';
        event.dataTransfer!.setData('text/plain', JSON.stringify({
            type: 'folder',
            ...this.folder()
        }));
        this.dragging.set(true);
    }

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
        this.dropping.set(true);

        if (!this.dragging()) {
            event.preventDefault();
        }
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
        this.dropping.set(false);
    }

    @HostListener('dragend', ['$event']) onDragEnd() {
        this.dragging.set(false);
    }

    @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
        const data = event.dataTransfer!.getData("text/plain");
        const { type, ...dropData } = JSON.parse(data);

        if (type === 'PROJECT') {
            this.moveProjectToFolder.emit(dropData);
        } else {
            this.moveFolderToFolder.emit(dropData);
        }

        this.dropping.set(false);
    }

    @HostListener('dblclick') onDoubleClick() {
        this.open.emit();
    }

    @HostBinding('class.selected') @Input() selected = false;

    @HostListener('mouseenter') onMouseEnter() {
        this.hovering.set(true);
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.hovering.set(false);
    }

    folder = input.required<OrganisationFolder>();
    selectionCount = input.required<number>();
    isOrgAdmin = input.required<boolean>();

    @Output() open: EventEmitter<void> = new EventEmitter();
    @Output() delete: EventEmitter<void> = new EventEmitter();
    @Output() move: EventEmitter<void> = new EventEmitter();
    @Output() favourite: EventEmitter<void> = new EventEmitter();
    @Output() rename: EventEmitter<void> = new EventEmitter();
    @Output() select: EventEmitter<boolean> = new EventEmitter();
    @Output() moveProjectToFolder: EventEmitter<HomeProject> = new EventEmitter();
    @Output() moveFolderToFolder: EventEmitter<OrganisationFolder> = new EventEmitter();

    dragging = signal<boolean>(false);
    dropping = signal<boolean>(false);
    hovering = signal<boolean>(false);

    menuItems = computed<MenuItem[]>(() => {
        const isOrgAdmin = this.isOrgAdmin();
        return [{
            label: 'Open',
            command: (e) => this.handleMenuEvent(e, this.open)
        }, {
            label: 'Rename folder',
            command: (e) => this.handleMenuEvent(e, this.rename),
            visible: isOrgAdmin
        }, {
            label: 'Favourite',
            command: (e) => this.handleMenuEvent(e, this.favourite),
        }, {
            label: 'Move',
            command: (e) => this.handleMenuEvent(e, this.move),
            visible: isOrgAdmin
        }, {
            label: 'Delete',
            command: (e) => this.handleMenuEvent(e, this.delete),
            visible: isOrgAdmin
        }]
    });

    tooltipOptions: TooltipOptions = {
        showDelay: 500,
        tooltipPosition: 'bottom'
    };

    handleMenuEvent(event: MenuItemCommandEvent, emitter: EventEmitter<void>) {
        event.originalEvent?.preventDefault();
        event.originalEvent?.stopPropagation();
        emitter.emit();
    }

    handleEvent(event: Event, emitter: EventEmitter<any>) {
        event.preventDefault();
        event.stopPropagation();
        emitter.emit();
    }

    openMenu(event: Event, menu: any) {
        event.preventDefault();
        event.stopPropagation();
        menu.toggle(event);
    }

    selectFolder(event: Event, multi: boolean = false) {
        event.preventDefault();
        event.stopPropagation();
        this.select.emit(multi);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('selectionCount' in changes) {
            this.draggable = changes['selectionCount'].currentValue <= 1;
        }
    }
}
