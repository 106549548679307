import { getProject, getProjectRole } from '../../store/selectors';

export default class ProjectRecordStatusSettingsController {
    static $inject = ['$mdDialog', '$ngRedux', '$scope', 'ProjectsService', 'toastr', 'ProjectActions'];
    constructor($mdDialog, $ngRedux, $scope, ProjectsService, toastr, ProjectActions) {

        this.$mdDialog = $mdDialog;
        this.ProjectsService = ProjectsService;
        this.toastr = toastr;

        $scope.$on('$destroy', $ngRedux.connect((state) => {
            const project = angular.copy(getProject(state));

            const compareState = (a, b) => {
                if (a.sort < b.sort) {
                    return -1;
                } else if (a.sort < b.sort) {
                    return 1;
                } else {
                    return 0;
                }
            }

            project.states = project.states.sort(compareState);
            return {
                project,
                role: getProjectRole(state),
                stateListConfig: {
                    axis: 'y',
                    containment: '.status-list',
                    tolerance: 'pointer',
                    disabled: !project.verification
                }
            };
        }, ProjectActions)(this));

        this.readonly = this.role !== 'admin';
        this.selectedStateId = null;

        this.classMap = {
            null: 'label-clear',
            0: 'label-default',
            1: 'label-danger',
            2: 'label-warning',
            3: 'label-info',
            4: 'label-primary',
            5: 'label-success'
        };
    }

    updateOrder() {
        this.updateProjectRecordStatusOrder(this.project.states);
    };

    createState() {
        this.$mdDialog.show({
            template: require('!raw-loader!./create-record-status.modal.html').default,
            controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                $scope.state = {
                    verified: false,
                    name: "",
                    color: '#ffbbcc'
                };

                $scope.colorPickerOptions = {
                    inputClass: 'form-control',
                    format: 'hexString',
                    allowEmpty: false,
                    alpha: false,
                    dynamicHue: false,
                    required: true
                };

                $scope.ok = function () {
                    $mdDialog.hide($scope.state);
                };

                $scope.cancel = function () {
                    $mdDialog.cancel()
                };
            }]
        }).then((state) => {
            var stateId = Math.max.apply(null, _.map(this.project.states, function (state) {
                return state.stateId;
            }));

            state.stateId = stateId + 1;
            state.projectId = this.project.id;
            state.sort = this.project.states.length;
            this.createProjectRecordStatus(state);
        }, angular.noop);
    };

    toggleRecordStatuses() {
        this.updateProject({
            id: this.project.id,
            verification: this.project.verification
        });
    }

    editState(stateId) {
        this.selectedStateId = stateId;
        var state = _.find(this.project.states, { stateId: +stateId });
        var vm = this;

        var editedState = {
            stateId: stateId,
            verified: state.verified,
            name: state.name,
            default: state.default,
            projectId: this.project.id,
            color: state.color
        };

        this.$mdDialog.show({
            template: require('!raw-loader!./edit-record-status.modal.html').default,
            controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                $scope.state = editedState;

                $scope.colorPickerOptions = {
                    inputClass: 'form-control',
                    format: 'hexString',
                    allowEmpty: false,
                    alpha: false,
                    dynamicHue: false,
                    required: true
                };

                $scope.ok = function () {
                    $mdDialog.hide();
                };

                $scope.cancel = function () {
                    $mdDialog.cancel();
                };

                $scope.delete = function () {
                    $mdDialog.hide('delete');
                };

                $scope.default = function () {
                    $mdDialog.hide('default');
                };
            }]
        }).then((result) => {

            if (result === 'delete') {
                this.$mdDialog.show({
                    template: require('!raw-loader!./delete-record-status.modal.html').default,
                    controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                        $scope.ok = function () {
                            vm.deleteProjectRecordStatus(vm.selectedStateId).then(() => {
                                $mdDialog.hide();
                            });
                        };

                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        };
                    }]
                }).then(() => {
                    this.project.states = _.filter(this.project.states, (state) => {
                        return state.stateId !== this.selectedStateId;
                    });
                }, angular.noop);
                return;
            }

            state.name = editedState.name;
            state.verified = editedState.verified;
            state.color = editedState.color;

            if (result === 'default') {
                state.default = true;
                editedState.default = true;
            }

            this.updateProjectRecordStatus(editedState);
        });
    };

}
