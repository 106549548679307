import { environment } from "src/environments/environment";

export const MEDIA_ITEM_ID_ATTRIBUTE = 'media-item-id';

export const slugify = (text) => {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    const to = "aaaaaeeeeeiiiiooooouuuunc------"

    const newText = text.split('').map(
        (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

    return newText
        .toString()                     // Cast to string
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                         // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/&/g, '-y-')           // Replace & with 'and'
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}

const constructUrl = (protocol, hostname, port) => {
    if (!protocol || !hostname) {
        return;
    }
    return [protocol, '://', hostname, (+port === 80 || +port === 443) ? '' : ':' + port].join('');
};


const validOutputs = new Set([
    "auto", "av1", "avif", "blank",
    "blurhash", "h264", "h265", "heif",
    "image", "jpeg", "jxl", "main",
    "mean", "png", "preview", "sketch", "vp9", "webp"
]);

const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

const transformProperties = (params) => {
    const transformed = [];
    if (params.w && isNumeric(params.w)) {
        transformed.push(`resize=${params.w}` + (params.h && isNumeric(params.h) ? `x${params.h}` : ''));
    } else if (params.output && validOutputs.has(params.output)) {
        transformed.push(`output=${params.output}`);
    }
    return transformed.join('/');
}

export const urlToImageProxy = (string, params = {}) => {
    try {
        const s3Url = new URL(string);

        const requestUrl = environment.imagesProxyEnabled
            ? constructUrl(
                environment.apiProtocol,
                environment.apiHostname,
                environment.apiPort
            ) + "/images"
            : environment.imagesCdnUrl;

        if (!s3Url.hostname.startsWith('coreo') || !requestUrl) {
            return string;
        }

        const url = new URL(`${requestUrl}${s3Url.pathname}`);
        let qry;
        if (!environment.imagesProxyEnabled) {
            const transformedProperties = transformProperties(params);
            qry = `twic=v1${transformedProperties ? '/' + transformedProperties : ''}`;
        } else {
            qry = new URLSearchParams(params).toString();
        }
        url.search = qry;
        return url.toString();
    } catch (e) {
        console.warn(`Failed to translate image URL: "${string}"`);
        return '';
    }
}

export const urlBase64Decode = (str) => {
    var output = str.replace('-', '+').replace('_', '/');
    switch (output.length % 4) {
        case 0:
            break;
        case 2:
            output += '==';
            break;
        case 3:
            output += '=';
            break;
        default:
            throw 'Illegal base64url string!';
    }
    return window.atob(output);
}
