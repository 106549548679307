<p-table #adminUsersTable [value]="users" [tableStyle]="{ 'min-width': '50rem', 'table-layout': 'fixed' }"
    [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,50,100]" [rowHover]="true" [lazy]="true"
    (onLazyLoad)="loadUsers($event)" [totalRecords]="totalUsers" [loading]="loading" sortField="id" [sortOrder]="-1"
    [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
    [scrollable]="true" scrollHeight="flex" (onRowSelect)="rowSelect($event)" class="text-sm" [first]="first"
    [rows]="rows" [filters]="filters" [sortField]="sortField" [sortOrder]="sortOrder">

    <ng-template pTemplate="caption">
        <div class="flex items-center justify-between w-full">
            <h3 class="text-2xl mr-4">Users</h3>
            <span class="p-input-icon-left w-96">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="globalSearch($event)" class="w-full" autocomplete="off"
                    placeholder="Search by name, username or email" data-lpignore="true" [value]="search" />
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="id" style="width: 10%">ID<p-sortIcon field="id"></p-sortIcon></th>
            <th pSortableColumn="displayName" style="width: 15%">Name<p-sortIcon field="displayName"></p-sortIcon></th>
            <th pSortableColumn="username" style="width: 15%">Username<p-sortIcon field="username"></p-sortIcon></th>
            <th pSortableColumn="email" style="width: 20%">Email<p-sortIcon field="email"></p-sortIcon></th>
            <th pSortableColumn="role" style="width: 10%">System Role<p-sortIcon field="role"></p-sortIcon></th>
            <th pSortableColumn="createdAt" style="width: 10%">Signup Date<p-sortIcon field="createdAt"></p-sortIcon>
            </th>
            <th pSortableColumn="lastLoggedIn" style="width: 10%">Last Logged In<p-sortIcon
                    field="lastLoggedIn"></p-sortIcon></th>
            <th pSortableColumn="lastActive" style="width: 10%">Last Active<p-sortIcon field="lastActive"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th><p-columnFilter type="text" field="id" [showButtons]="false" [showOperator]="false"
                    placeholder="Search ID" [showMenu]="false"></p-columnFilter></th>
            <th><p-columnFilter field="displayName" type="text" matchMode="equals" [showMenu]="false"
                    placeholder="Search Name"></p-columnFilter>
            </th>
            <th><p-columnFilter field="username" type="text" matchMode="equals" [showMenu]="false"
                    placeholder="Search Username"></p-columnFilter>
            </th>
            <th><p-columnFilter field="email" type="text" matchMode="equals" [showMenu]="false"
                    placeholder="Search Email"></p-columnFilter></th>
            <th><p-columnFilter field="role" matchMode="equals" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-dropdown [ngModel]="value" [options]="roles" (onChange)="filter($event.value)"
                            placeholder="Any" [showClear]="true">
                            <ng-template let-option pTemplate="item">
                                {{option.label}}
                            </ng-template>
                        </p-dropdown>
                    </ng-template>
                </p-columnFilter></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
        <tr (click)="rowSelect(user)" class="cursor-pointer">
            <td class="break-words">{{ user.id }}</td>
            <td class="break-words">{{ user.displayName }}</td>
            <td class="break-words">{{ user.username }}</td>
            <td class="break-words">{{ user.email }}</td>
            <td class="break-words">{{ user.role | titlecase }}</td>
            <td class="break-words">{{ user.createdAt| date:'d/M/yy, h:mm a' }}</td>
            <td class="break-words">{{ user.lastLoggedIn | date:'d/M/yy, h:mm a'}}</td>
            <td class="break-words">{{ user.lastActive | date:'d/M/yy, h:mm a' }}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8">No users found.</td>
        </tr>
    </ng-template>
</p-table>