export default class FileChangeDirective {

    constructor() {
        this.restrict = 'A';
    }

    link(scope, element, attrs) {
        const fileChangeHandler = scope.$eval(attrs.fileChange);
        element.on('change', e => {
            const files = (e.srcElement || e.target).files
            fileChangeHandler(e, files)
        });
        element.on('$destroy', function () {
            element.off();
        });
    }
}
