import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "twicPic",
    standalone: true
})
export class TwicPicPipe implements PipeTransform {
    transform(value: string, transformation: string): string {
        const url = new URL(value);
        return `https://coreo.twic.pics${url.pathname}?twic=v1/${transformation}`;
    }
}