import { Polygon } from "geojson";
import { CoreoAttributeQuestionType, CoreoAttributeType } from "../../types";
interface RecordsAttributeFilterOperator {
    id: string;
    label: string;
    multiple?: boolean;
    unary?: boolean;
    input?: 'text' | 'number' | 'date';
}

interface RecordsAttributeFilter {
    attributePath: string;
    attributeQuestionType: string;
    attributeType: string;
    formId: number;
    operator: RecordsAttributeFilterOperator;
    operatorId: string;
    value: string | string[];
}

export interface RecordsFilter {
    dirty: boolean;
    user: any;
    states: any[];
    formId: number;
    recordId: number;
    custom: any;
    customOperator: string;
    from: string;
    to: string;
    boundary: any;
    attributes: { [key: number]: RecordsAttributeFilter };
    customBoundary: Polygon;
}

interface RecordsPagination {
    size: number;
    page: number;
}

export interface MapLayerState {
    id: number;
    name: string;
    layerType: 'records' | 'collection' | 'custom';
    sort: number;
    visible: boolean;
}

export interface RecordsState {
    view: 'map' | 'table' | 'map-table';
    filterOpen: boolean;
    data: any[];
    recordOpen: boolean;
    filter: RecordsFilter;
    showDeleted: boolean;
    pagination: RecordsPagination;
    order: string;
    total: number;
    selected: any;
    selectedIds: number[];
    selectionSource: any;
    selectionBoundary: any;
    drawingBoundary: boolean;
    reloadRequired: boolean;
    layers: MapLayerState[];
}

const initialState: RecordsState = {
    view: 'map-table',
    filterOpen: true,
    data: [],
    recordOpen: false,
    filter: {
        dirty: false,
        recordId: null,
        user: null,
        states: [],
        formId: null,
        custom: {},
        customOperator: 'and',
        customBoundary: null,
        from: null,
        to: null,
        boundary: null,
        attributes: {}
    },
    showDeleted: false,
    pagination: {
        size: 10,
        page: 1
    },
    order: 'reverse:createdAt',
    total: 0,

    selected: null,
    selectedIds: [],
    selectionSource: null,
    selectionBoundary: null,

    drawingBoundary: false,
    reloadRequired: false,
    layers: null
};

const isFilterDirty = (filter: RecordsFilter) => {
    const { from, to, boundary, states, user, attributes, customBoundary, recordId } = filter;
    if (from || to || boundary || user || customBoundary || recordId || states.length > 0) {
        return true;
    }
    for (const [, attribute] of Object.entries(attributes)) {
        if ((attribute.operator && attribute.operator.unary) || attribute.value) {
            return true;
        }
    }
    return false;
};

const generateAttributeFilter = (attribute) => {
    const defaultOperator = getDefaultOperator(attribute.type, attribute.questionType);
    return {
        attributePath: attribute.path,
        attributeType: attribute.type,
        attributeQuestionType: attribute.questionType,
        formId: attribute.surveyId,
        operator: defaultOperator,
        operatorId: defaultOperator && defaultOperator.id,
        value: null
    };
}

export const getOperators = (type: CoreoAttributeType, questionType: CoreoAttributeQuestionType): RecordsAttributeFilterOperator[] => {
    switch (type) {
        case 'number': {
            return [];
        }
        case 'geometryquery':
        case 'multiselect':
        case 'select': {
            return [
                { id: 'in', label: 'One of', multiple: true },
                { id: 'eq', label: 'Is' },
                { id: 'ne', label: 'Is not' },
                { id: 'notIn', label: 'Not one of', multiple: true },
                { id: 'is:null', label: 'Is blank', unary: true },
                { id: 'not:null', label: 'Is not blank', unary: true }
            ];
        }
        case 'rgeolocation':
        case 'coordinatetransform':
        case 'text': {
            return [
                { id: 'substring', label: 'Contains', input: 'text' },
                { id: 'eq', label: 'Is', input: 'text' },
                { id: 'is:null', label: 'Is blank', unary: true },
                { id: 'not:null', label: 'Is not blank', unary: true },
                { id: 'startsWith', label: 'Starts with', input: 'text' },
                { id: 'endsWith', label: 'Ends with', input: 'text' },
                { id: 'ne', label: 'Does not equal', input: 'text' }
            ];
        }
        case 'integer':
        case 'float': {
            return [
                { id: 'eq', label: 'Equals', input: 'number' },
                { id: 'gt', label: 'Greater than', input: 'number' },
                { id: 'gte', label: 'Greater than or equal to', input: 'number' },
                { id: 'lt', label: 'Less than', input: 'number' },
                { id: 'lte', label: 'Less than or equal to', input: 'number' },
                { id: 'is:null', label: 'Is blank', unary: true },
                { id: 'not:null', label: 'Is not blank', unary: true }
            ];
        }
        case 'media': {
            return [
                { id: 'not:null', label: 'Is not blank', unary: true },
                { id: 'is:null', label: 'Is blank', unary: true }
            ];
        }
        case 'boolean': {
            return [
                { id: 'is:true', label: 'True' },
                { id: 'is:false', label: 'False' },
                { id: 'is:null', label: 'Is blank', unary: true },
                { id: 'not:null', label: 'Is not blank', unary: true }
            ];
        }
        case 'date':
        case 'datetime': {
            return [
                { id: 'lt', label: 'Before', input: 'date' },
                { id: 'gt', label: 'After', input: 'date' },
                { id: 'is:null', label: 'Is blank', unary: true },
                { id: 'not:null', label: 'Is not blank', unary: true }
            ];
        }
        case null: {
            switch (questionType) {
                case 'geometry':
                case 'text':
                case 'child':
                case 'association': {
                    return [];
                }
            }
        }

        default: {
            console.warn('Unknown type when building operators', type, questionType);
            return [];
        }
    }
}

const getDefaultOperator = (type, questionType) => {
    if (['boolean', 'media'].includes(type)) {
        return null;
    }

    const operators = getOperators(type, questionType);
    return operators && operators[0];
}


const recordsReducer = (state = initialState, action): RecordsState => {
    switch (action.type) {
        case 'RECORDS_INIT': {
            const { project: { attributes }, init } = action;
            // handling for newly created attributes
            const formId = init && init.filter && init.filter.formId;
            const attributesFilter = (init && init.filter && init.filter.attributes) || {};

            for (const attribute of attributes.filter(a => !!a.surveyId)) {
                if (attributesFilter[attribute.id]) {
                    continue;
                }
                attributesFilter[attribute.id] = generateAttributeFilter(attribute);
            }

            return {
                ...state,
                ...init,
                filter: {
                    ...((init && init.filter) || {}),
                    attributes: attributesFilter
                },
                layers: init.layers ?? null
            };
        }

        case 'RECORDS_UPDATE_LAYERS': {
            return {
                ...state,
                layers: action.layers
            };
        }

        case 'LOAD_PROJECT': {
            return initialState;
        }

        case 'LOAD_PROJECT_SUCCESS': {
            const { project: { forms, attributes } } = action;

            const formId = forms && forms[0] && forms[0].id;
            const attributesFilter = {};

            for (const attribute of attributes.filter(a => !!a.surveyId)) {
                attributesFilter[attribute.id] = generateAttributeFilter(attribute);
            }

            return {
                ...state,
                filter: {
                    ...state.filter,
                    attributes: attributesFilter,
                    formId
                }
            };
        }

        case 'RECORDS_LOAD': {
            const { filter = {}, pagination = {}, showDeleted = state.showDeleted, order = state.order } = action;
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...filter
                },
                pagination: {
                    ...state.pagination,
                    ...pagination
                },
                order,
                showDeleted,
                selectedIds: state.selectionSource === 'table' ? [] : state.selectedIds
            };
        }

        case 'RECORDS_LOAD_SUCCESS': {
            const { total, data } = action;
            return {
                ...state,
                total,
                data,
                // selectedIds: [],
                reloadRequired: false
            };
        }

        case 'RECORDS_UPDATE_VIEW': {
            return {
                ...state,
                view: action.view
            };
        }

        case 'RECORDS_TOGGLE_FILTER': {
            return {
                ...state,
                filterOpen: !state.filterOpen
            };
        }

        case 'RECORDS_UPDATE_FORM': {
            const { formId } = action;
            return {
                ...state,
                filter: {
                    ...state.filter,
                    formId
                },
                reloadRequired: true,
                selectedIds: [],
                selectionSource: null,
                order: initialState.order
            };
        }

        case 'RECORDS_UPDATE_FILTER': {
            const newFilter = {
                ...state.filter,
                ...action.update
            };
            const dirty = isFilterDirty(newFilter);

            // If we're adding a custom boundary, deselect manually selected records
            const selectedIds = action.update.customBoundary ? [] : state.selectedIds;
            const selectionSource = action.update.customBoundary ? null : state.selectionSource;

            return {
                ...state,
                filter: {
                    ...newFilter,
                    dirty
                },
                reloadRequired: true,
                selectedIds,
                selectionSource
            };
        }

        case 'RECORDS_UPDATE_SHOW_DELETED': {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    dirty: true
                },
                showDeleted: action.showDeleted,
                reloadRequired: true
            };
        }

        case 'RECORD_LOAD_SUCCESS': {
            return {
                ...state,
                selectedIds: [action.id],
                recordOpen: true
            };
        }

        case 'RECORD_DETAILS_LOAD_SUCCESS': {
            return {
                ...state,
                selected: action.record
            };
        }

        case 'RECORDS_UPDATE_SELECTED_STATE_SUCCESS': {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    state: action.state
                }
            };
        }

        case 'RECORD_SELECT': {
            const { ids, source, append } = action;

            return {
                ...state,
                selectedIds: append ? [...state.selectedIds, ...ids] : ids,
                selectionSource: source,
                filter: {
                    ...state.filter,
                    customBoundary: null
                }
            };
        }

        case 'RECORDS_CLEAR_SELECTION': {
            return {
                ...state,
                selectedIds: [],
                selectionSource: null,
                reloadRequired: true
            };
        }

        case 'RECORDS_RESET_FILTER': {

            const resetAttributeFilter = {};

            for (const [attributeId, attributeCfg] of Object.entries(state.filter.attributes)) {
                const operator = getDefaultOperator(attributeCfg.attributeType, attributeCfg.attributeQuestionType);
                resetAttributeFilter[attributeId] = {
                    ...attributeCfg,
                    operator,
                    operatorId: operator && operator.id,
                    value: null
                };
            }


            return {
                ...state,
                filter: {
                    ...initialState.filter,
                    attributes: resetAttributeFilter,
                    formId: state.filter.formId,
                    customOperator: 'and',
                    customBoundary: null,
                    custom: {},
                    dirty: false
                },
                selectedIds: [],
                selectionSource: null,
                showDeleted: false,
                reloadRequired: true
            };
        }

        case 'RECORDS_UPDATE_ATTRIBUTE_FILTER': {
            let { attribute, value, operator } = action;

            if (operator) {
                if (operator.unary) {
                    value = null;
                } else if (operator.multiple && value) {
                    if (!Array.isArray(value)) {
                        value = [value]
                    } else if (Array.isArray(value) && value.length === 0) {
                        value = [];
                    }
                } else if (!operator.multiple && Array.isArray(value) && value.length > 0) {
                    value = value[0];
                } else if (value === "") {
                    value = null;
                }
            }

            const newFilter = {
                ...state.filter,
                attributes: {
                    ...state.filter.attributes,
                    [attribute.id]: {
                        ...state.filter.attributes[attribute.id],
                        value,
                        operator,
                        operatorId: operator && operator.id
                    }
                }
            };

            const dirty = isFilterDirty(newFilter);

            return {
                ...state,
                filter: {
                    ...newFilter,
                    dirty
                },
                reloadRequired: true
            };
        }

        default: {
            return state;
        }
    }
}

export default recordsReducer;