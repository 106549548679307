import { Pipe, PipeTransform } from "@angular/core";
import { formatDistanceToNow } from "date-fns";

@Pipe({
    name: "dateToNow",
    standalone: true
})
export class DateToNowPipe implements PipeTransform {
    // Return the distance between the given date and now in words.
    transform(value: string): string {
        return formatDistanceToNow(new Date(value), { addSuffix: true });
    }
}