export default class IntermediaryPageAddedToProjectController {
    static $inject = ['$location', 'IntermediaryPageService'];
    constructor($location, IntermediaryPageService) {
        const search = $location.search();
        this.projectUrl = search.projectUrl;
        this.role = search.role;
        this.projectName = search.name;
        this.hostUrl = IntermediaryPageService.getHostUrl();
        this.displayHostUrl = IntermediaryPageService.getDisplayHostUrl();
    }

    $onInit() { }
}
