<p-table #adminProjectsTable [value]="projects" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true" [rows]="10"
  [rowsPerPageOptions]="[10,50,100]" [rowHover]="true" [lazy]="true" (onLazyLoad)="loadProjects($event)"
  [totalRecords]="1000" [loading]="loading" sortField="id" [sortOrder]="-1" [scrollable]="true"
  scrollHeight="flex" (onRowSelect)="rowSelect($event)" class="text-sm" [first]="first" [rows]="rows"
  [filters]="filters" [sortField]="sortField" [sortOrder]="sortOrder">

  <ng-template pTemplate="caption">
    <div class="flex items-center justify-between w-full">
      <h3 class="text-2xl mr-4">Manage Projects</h3>
      <p-button size="large" (click)="isVisibleDialog = true">Create Project</p-button>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="id" style="width: 10%">ID<p-sortIcon field="id"></p-sortIcon></th>
      <th pSortableColumn="name" style="width: 25%">Name<p-sortIcon field="name"></p-sortIcon></th>
      <th pSortableColumn="slug" style="width: 25%">Slug<p-sortIcon field="slug"></p-sortIcon></th>
      <th pSortableColumn="createdAt" style="width: 25%">Created At<p-sortIcon field="createdAt"></p-sortIcon></th>
      <th pSortableColumn="visible" style="width: 15%">Visible<p-sortIcon field="visible"></p-sortIcon></th>
    </tr>
    <tr>
      <th></th>
      <th><p-columnFilter field="name" type="text" matchMode="equals" [showMenu]="false"></p-columnFilter></th>
      <th><p-columnFilter field="slug" type="text" matchMode="equals" [showMenu]="false"></p-columnFilter></th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-project>
    <tr class="cursor-pointer" (click)="rowSelect(project)">
      <td>{{ project.id }}</td>
      <td>{{ project.name }}</td>
      <td>{{ project.slug }}</td>
      <td>{{ project.createdAt| date:'medium' }}</td>
      <td>{{ project.visible | yesNo }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">No projects found.</td>
    </tr>
  </ng-template>
</p-table>

<app-create-project [isVisibleDialog]="isVisibleDialog" (visibleChange)="onVisibleChange($event)"></app-create-project>
