export default function () {
    return function (input) {
        var results = [];
        for (var i = 0; i < input.length; i++) {
            if (input[i].collectionId) {
                results.push(input[i]);
            }
        }
        return results;
    };
}