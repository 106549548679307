import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { PanelModule } from "primeng/panel";
import { AvatarModule } from 'primeng/avatar';
import { TabViewModule } from 'primeng/tabview';
import { SelectButtonModule } from "primeng/selectbutton";

@Component({
    selector: 'app-admin-design',
    templateUrl: './design.component.html',
    standalone: true,
    imports: [
        ButtonModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        SelectButtonModule,
        DropdownModule,
        FormsModule,
        CardModule,
        PanelModule,
        AvatarModule,
        TabViewModule
    ],
    styles: [`:host { @apply block p-8;}`]
})
export class AdminDesignComponent {
    cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];
    selectedCity: any;

    selectButtonOptions = [
        { label: 'First', value: 'first' },
        { label: 'Second', value: 'second' },
        { label: 'Third', value: 'third' }
    ];
    selectMode = 'first';
}
