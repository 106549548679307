import { createSelector } from "reselect";

export const getPageId = page => page.id;
export const getPageType = page => page.type;
export const getPageConfig = page => page.config;
export const getPageMediaItems = page => page.mediaItems;
export const getPageErrors = page => page.errors;

export const getPageForUpdate = ({ id, config, type, title, icon, order, auth, listed, published }) => ({
    id,
    config,
    type,
    title,
    icon,
    order,
    auth,
    listed,
    published
});