export default function () {
    return function (input) {
        if (!angular.isArray(input)) {
            return input;
        }
        // Check if it's one of the ones we pre-built
        var type = input[0];

        // Check we have e.g.
        // [
        //     "string",
        //     [
        //         "to-string",
        //         [
        //             "get",
        //             "species"
        //         ]
        //     ],
        //     ""
        // ]
        if (angular.isArray(input[1]) && (input[1][0] === 'to-' + type) && angular.isArray(input[1][1]) && input[1][1][0] === 'get') {
            return 'From ' + input[1][1][1];
        } else {
            return 'Custom Formula';
        }
    };
}