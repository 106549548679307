import { Component, ViewChild, EventEmitter, Input, OnInit, Output, OnDestroy, computed, signal, ChangeDetectionStrategy } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { SvgIconComponent } from "angular-svg-icon";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { OrganisationFolder, OrganisationFolderTreeNode } from "./home.models";
import { HomeService } from "./home.service";
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from "primeng/dynamicdialog";
import { NgClass } from "@angular/common";
import { HomeNewFolderComponent } from "./home-new-folder.component";
import { HomeMoveLocationComponent } from "./home-move-location.component";
import { BreadcrumbItemClickEvent, BreadcrumbModule } from "primeng/breadcrumb";
import { filter, switchMap } from "rxjs/operators";

@Component({
    selector: 'app-home-move',
    standalone: true,
    templateUrl: './home-move.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SvgIconComponent,
        ButtonModule,
        InputTextModule,
        ReactiveFormsModule,
        HomeMoveLocationComponent,
        NgClass,
        BreadcrumbModule
    ],
    providers: [
        HomeService
    ],
    styles: [`
        :host{
            @apply h-full flex flex-col;
        }

        li{
            @apply hover:bg-[#F2F8FE];
        }

        li.selected{
            @apply bg-[#DFEBFF] hover:bg-[#DFEBFF];
        }

        li.disabled{
            @apply opacity-50 pointer-events-none;;
        }
    `]
})
export class HomeMoveComponent implements OnInit {

    @Output() move = new EventEmitter<OrganisationFolderTreeNode>();
    @Output() duplicate = new EventEmitter<OrganisationFolderTreeNode>();

    @ViewChild(HomeMoveLocationComponent) homeMoveLocationComponent!: HomeMoveLocationComponent;

    // The current location of the item(s)
    currentLocation = signal<OrganisationFolderTreeNode>(this.config.data!.currentLocation);

    isUpdate = signal<boolean>(false);
    selection = signal<string | null>(null);
    searchedFolders = signal<{ search: string, folders: OrganisationFolder[] }>({ search: '', folders: [] });

    canMove = computed(() => {
        const selection = this.selection();
        return typeof selection !== 'undefined' && selection !== this.currentLocation().id;
    });
    cta = this.config.data!.cta;
    folderIds = this.config.data!.folderIds;
    organisationId = this.config.data!.organisationId;

    unstructuredFolders = this.homeService.folders();

    selectedFolderName = computed(() => {
        const selection = this.selection();
        return this.homeService.current().node.children.find(folder => folder.id === selection)?.name || '';
    });

    searchForm = new FormGroup({
        search: new FormControl('')
    });

    constructor(public homeService: HomeService, private dialogService: DialogService, private dialogRef: DynamicDialogRef, private config: DynamicDialogConfig<{
        rootNode: OrganisationFolderTreeNode;
        currentLocation: OrganisationFolderTreeNode;
        cta: string;
        folderIds: string[];
        organisationId: number;
    }>) { }

    ngOnInit() {
        const { rootNode } = this.config.data!;
        this.homeService.setRoot(rootNode);
        this.homeService.loadOrganisation(this.organisationId);
        this.homeService.folderId.set(this.currentLocation().id);

        this.searchForm.valueChanges.subscribe(value => {
            const { search } = value;
            this.searchedFolders.set({
                search,
                folders: !search ? [] :
                    this.homeService.folders()
                        .filter(p => !search || p.name.toLowerCase().includes(search.toLowerCase()))
                        .sort((a, b) => a.name.localeCompare(b.name))
            })
        });
    }

    selectFolder(event: string | null) {
        this.selection.set(event);
    }

    moveItems() {
        this.dialogRef.close({ id: this.selection(), isUpdate: this.isUpdate() });
    }

    breadcrumbClick(event: BreadcrumbItemClickEvent) {
        this.homeService.folderId.set(event.item.id);
        this.homeMoveLocationComponent.selection.set(this.homeService.current().node.id);
    }

    up() {
        const path = this.homeService.current().path;
        if (path.length > 0) {
            const parent = path[path.length - 1];
            this.homeService.folderId.set(parent.id);
        }
        this.homeMoveLocationComponent.selection.set(this.homeService.current().node.id);
    }

    private openCreateFolderModel() {
        const ref = this.dialogService.open(HomeNewFolderComponent, {
            width: '30vw',
            contentStyle: {
                'padding': '0px',
            },
            header: 'New Folder',
            data: {
                cta: 'Create'
            }
        });

        return ref.onClose.pipe(
            filter(t => !!t)
        );
    }

    openNewFolderModal() {
        this.openCreateFolderModel().pipe(
            switchMap((r: { name: string; }) => {
                return this.homeService.createFolder(r.name, this.homeService.currentFolderId());
            })).subscribe(() => {
                this.isUpdate.set(true);
            });
    }
}