<p-table 
    [value]="collection()"
    [tableStyle]="{'min-width': '424px'}"
    [lazy]="true"
    (onLazyLoad)="loadCollection($event)"
    [paginator]="true" 
    paginatorDropdownAppendTo="body"
    [rows]="rows()"
    [rowsPerPageOptions]="[10,50,100]"
    [totalRecords]="itemCount()"
    [loading]="loading()"
    [scrollable]="true"
    scrollHeight="flex"
    styleClass="bg-white p-0"
>
<ng-template pTemplate="caption">
    <div class="coreo-list-header p-4">
        <div class="w-full flex items-center">
            <span class="p-input-icon-left w-full">
                <i class="fa-solid fa-magnifying-glass text-primary text-xs ml-1"></i>
                <input pInputText type="text" (input)="debouncedSearch()" class="w-full h-11 pr-10" autocomplete="off"
                placeholder="Search for an item" [(ngModel)]="searchTerm" />
                @if (searchTerm().length > 0) {
                    <button class="absolute right-2 top-1/2 -translate-y-1/2 h-5 w-5 rounded-md cursor-pointer hover:bg-grey-10" (click)="clearSearch($event)">
                        <i class="fa-solid fa-xmark text-text-secondary text-sm absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"></i>
                    </button>
                }
            </span>
        </div>
    </div>
</ng-template>

<ng-template pTemplate="body" let-item>
    <tr (click)="rowSelect(item)" class="table-item table-item--thin" [ngClass]="{'selected': isSelected(item)}">
        <div class="table-item__inner justify-between">
            <div class="flex items-center gap-[10px]">
                @if (hasImages()) {
                    <p-avatar [image]="item.avatar" shape="circle" styleClass="h-9 w-9" class="h-9 w-9"></p-avatar>
                }
                <p class="table-item__primary-text max-w-56 truncate">{{ item.value }}</p>
            </div>
            <div class="flex items-center justify-end gap-[10px]">
                <div class="table-item__buttons">
                    @if (item.hasInfo) {
                        <button (click)="openCollectionInfoModal($event, item)" class="table-item__button">
                            <i class="fa-solid fa-circle-info text-grey-50"></i>
                        </button>
                    }
                    @if (multiselect()) {
                        <button class="table-item__button">
                            <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="30" height="30" rx="3" fill="white" />
                                <rect x="1" y="1" width="30" height="30" rx="3" stroke-width="2"
                                [attr.stroke]="isSelected(item) ? '#0069DF' : '#878F97'" />
                                @if(isSelected(item)){
                                    <path
                                    d="M13.0602 22.5672L7.10185 16.6089C6.74388 16.2509 6.74388 15.6705 7.10185 15.3125L8.39818 14.0161C8.75615 13.6581 9.33658 13.6581 9.69455 14.0161L13.7084 18.0299L22.3055 9.43278C22.6635 9.07482 23.2439 9.07482 23.6019 9.43278L24.8982 10.7291C25.2562 11.0871 25.2562 11.6675 24.8982 12.0255L14.3566 22.5672C13.9986 22.9252 13.4182 22.9252 13.0602 22.5672Z"
                                    fill="#0069DF" />
                                }
                            </svg>
                        </button>
                    }
                </div>
            </div>
        </div>
    </tr>
</ng-template>

<ng-template pTemplate="emptymessage">
    <div class="absolute h-full w-full flex flex-col items-center justify-center p-5">
        <i class="fa-solid {{ searchTerm().length > 0 ? 'fa-magnifying-glass' : 'fa-layer-group' }} text-5xl text-primary-100 mb-5"></i>
        <p class="text-base font-semibold">
            @if (searchTerm().length > 0) {
                Nothing found
            }
            @else {
                No items
            }
        </p>
        @if (searchTerm().length > 0) {
            <p class="max-w-80 text-sm text-text-secondary text-center mt-6">No items fit that search description. Try again with a different search term.</p>
        }
    </div>
</ng-template>

@if (multiselect()) {
<ng-template pTemplate="summary">
    <div class="flex items-center justify-end">
        <p-button label="Confirm" size="large" (click)="closeWithSelection()"></p-button>
    </div>
</ng-template>
}
</p-table>