import { getPermissions } from '../store/selectors';

export default class PermissionsService {

    static $inject = ['$ngRedux'];
    constructor($ngRedux) {
        this.permissions = {};
        $ngRedux.connect(getPermissions, null)(this.permissions);
    }

    checkAccess(permissions) {
        return permissions.some(p => this.permissions[p]);
    }
}
PermissionsService.$inject = ['$ngRedux'];