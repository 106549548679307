<div class="h-full w-full flex flex-col justify-normal">
    <div class="grow flex gap-5 p-5 bg-background overflow-hidden">
        <div class="h-full w-1/2 min-w-80">
            <p-card styleClass="h-full w-full p-0 overflow-hidden">
                <p-table 
                    [value]="collection()"
                    [tableStyle]="{'min-width': '310px'}"
                    [lazy]="true"
                    (onLazyLoad)="loadCollection($event)"
                    [paginator]="true" 
                    [showPageLinks]="false"
                    paginatorDropdownAppendTo="body"
                    [rows]="rows()"
                    [rowsPerPageOptions]="[10,50,100]"
                    [totalRecords]="itemCount()"
                    [loading]="loading()"
                    [scrollable]="true"
                    scrollHeight="flex"
                    styleClass="bg-white p-0"
                >
                    <ng-template pTemplate="caption">
                        <div class="p-4">
                            <span class="p-input-icon-left w-full">
                                <i class="fa-solid fa-magnifying-glass text-primary text-xs ml-1"></i>
                                <input pInputText type="text" (input)="debouncedSearch()" class="w-full h-11 pr-10" autocomplete="off"
                                placeholder="Search" [(ngModel)]="searchTerm" />
                                @if (searchTerm().length > 0) {
                                    <button class="absolute right-2 top-1/2 -translate-y-1/2 h-5 w-5 rounded-md cursor-pointer hover:bg-grey-10" (click)="clearSearch($event)">
                                        <i class="fa-solid fa-xmark text-text-secondary text-sm absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"></i>
                                    </button>
                                }
                            </span>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="body" let-item>
                        <tr (click)="currentFeature.set(item); setFeature()" class="table-item table-item--thin" [ngClass]="{'selected': isSelected(item)}">
                            <div class="table-item__inner justify-between">
                                <p class="table-item__primary-text max-w-56 truncate">{{ item.value }}</p>
                                <i class="fa-solid fa-chevron-right text-text-secondary"></i>
                            </div>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <div class="absolute h-full w-full flex flex-col items-center justify-center p-4">
                            <i class="fa-solid {{ searchTerm().length > 0 ? 'fa-magnifying-glass' : 'fa-layer-group' }} text-5xl text-primary-100 mb-5"></i>
                            <p class="text-base font-semibold">
                                @if (searchTerm().length > 0) {
                                    Nothing found
                                }
                                @else {
                                    No items
                                }
                            </p>
                            @if (searchTerm().length > 0) {
                                <p class="max-w-80 text-sm text-text-secondary text-center mt-6">No items fit that search description. Try again with a different search term.</p>
                            }
                        </div>
                    </ng-template>
                </p-table>                
            </p-card>
        </div>
        <div class="h-full w-1/2 min-w-80">
            <p-card styleClass="h-full w-full p-0 overflow-hidden">
                <div class="relative h-full w-full">
                    <div #geometryPickerMap class="absolute inset-0"></div>
                </div>
            </p-card>
        </div>
    </div>
    <div class="flex-none flex items-center justify-end gap-4 p-5 bg-white border-t border-grey-border">
        <p-button size="large" [disabled]="!currentFeature" (click)="clearSelection()" severity="danger">Clear selection</p-button>
        <p-button size="large" [disabled]="!currentFeature" (click)="saveSelection()">Save selection</p-button>
    </div>
</div>