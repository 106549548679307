export default class ProjectPagesService {
    static $inject = ['$http', 'CoreoAPI', 'CoreoGraphQLQuery', 'Auth'];
    constructor($http, CoreoAPI, CoreoGraphQLQuery, Auth) {
        this.$http = $http;
        this.CoreoAPI = CoreoAPI;
        this.CoreoGraphQLQuery = CoreoGraphQLQuery;
        this.Auth = Auth;
    }

    // Serialize object to gql
    gqlStringify(obj) {
        // JSON stringify, and strip quotes on field names, and 'pages' auth field as it is an enum
        return JSON.stringify(obj).replace(/\"([^(\")"]+)\":/g, "$1:").replace(/auth\s*:\s*"(.*?)"/g, "auth: $1");
    }

    createPage(page) {
        const mutation = 'mutation {result: createProjectPage(input:' + this.gqlStringify(page) + '){id}}';
        return this.CoreoAPI.mutation(mutation).then(result => result.id);
    }

    updatePage(page) {
        const mutation = 'mutation {result: updateProjectPage(input:' + this.gqlStringify(page) + '){id}}';
        return this.CoreoAPI.mutation(mutation);
    }

    updatePages(pages) {
        const items = [];
        for (var i = 0; i < pages.length; i++) {
            const name = (i === 0 ? 'data' : 'data_' + i);
            items.push(name + ': updateProjectPage(input:' + this.gqlStringify(pages[i]) + '){id,order}');
        }
        const mutation = 'mutation {' + items.join(',') + '}';
        return this.CoreoAPI.mutation(mutation);
    }

    deletePage(id) {
        const mutation = 'mutation {result: deleteProjectPage(input: {id:' + id + '})}';
        return this.CoreoAPI.mutation(mutation);
    }
}
ProjectPagesService.$inject = ['$http', 'CoreoAPI', 'CoreoGraphQLQuery', 'Auth'];