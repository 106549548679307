export default class CompareToDirective {
  constructor() {
    this.restrict = 'A';
    this.require = 'ngModel';
    this.scope = {
      otherModelValue: '=compareTo'
    };
  }
  link(scope, element, attributes, ngModel) {
    ngModel.$validators.compareTo = function (modelValue) {
      return modelValue === scope.otherModelValue;
    };

    scope.$watch('otherModelValue', function () {
      ngModel.$validate();
    });
  }
}
