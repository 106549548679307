import { Component, computed, inject, signal } from "@angular/core";
import { CoreoJob, JobsService } from '../../../core/services/jobs.service';
import { NgClass } from "@angular/common";
import { TimeAgoPipe } from "../../pipes/timeago.pipe";
import { JobComponent } from "./job.component";

@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        JobComponent
    ],
    styles: [`:host {
        @apply z-[999];
    }`],
    host: {
        '[class.empty]': 'empty()',
        '[class.open]': 'open()'
    }
})
export class JobsComponent {

    jobsService = inject(JobsService);

    jobs = this.jobsService.jobs;
    empty = computed(() => this.jobsService.jobs().length === 0);
    count = computed(() => this.jobsService.jobs().length);
    open = signal(true);

    clearJob(job: CoreoJob) {
        this.jobsService.clear(job);
    }

    toggle() {
        this.open.update(o => !o);
    }

}