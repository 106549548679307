import { createSelector } from "reselect";

export const getAuthUser = (state) => state.user;
export const getAuthUserUsedFreeTrial = (state) => state.user.usedFreeTrial;
export const getAuthUserPreferences = state => state.user && state.user.preferences;
export const getAuthIsAdmin = (state) => state.isAdmin;
export const getAuthIsImpersonating = (state) => state.isImpersonating;
export const getAuthIsLoggedIn = (state) => state.isLoggedIn;
export const getAuthToken = (state) => state.token;
export const getAuthProjectPermissions = (state) => state.projectPermissions;
export const getAuthOrganisationPermissions = (state) => state.organisationPermissions;
export const getAuthOAuthError = (state) => state.oauthError;
export const getAuthOAuthErrorDescription = (state) => state.oauthErrorDescription;
export const getAuthPasswordResetToken = (state) => state.passwordResetToken;
export const getAuthAccountVerificationToken = (state) => state.accountVerificationToken;
export const getAuthIsLoggingIn = (state) => state.isLoggingIn;
export const getAuthLoginError = (state) => state.loginError;
export const getAuthOrganisationId = (state) => state.organisationId;

export const getAuthProvider = createSelector(getAuthUser, user => user && user.provider);
export const getAuthUserImage = createSelector(getAuthUser, user => user && user.imageUrl);
export const getAuthUserId = createSelector(getAuthUser, user => user && user.id);

export const getAuthRoleForProject = id => createSelector(getAuthProjectPermissions, getAuthIsAdmin, (permissions, isAdmin) => {
    return isAdmin ? 'admin' : (permissions[id] || 'unauthorized');
});

export const getAuthRoleForOrganisation = id => createSelector(getAuthOrganisationPermissions, getAuthIsAdmin, (permissions, isAdmin) => {
    return isAdmin ? 'admin' : (permissions[id] || 'unauthorized');
});