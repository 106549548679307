import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { UserService } from 'src/app/core/services/user.service';
import { ApiService } from "src/app/core";
import { UsersResponse } from '../../../core/models/user.model';

@Component({
  templateUrl: './create-organisation.component.html',
  selector: 'app-create-organisation',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ToastModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    AutoCompleteModule
  ],
  providers: [MessageService]
})
export class CreateOrganisationComponent {

  @Input() isVisibleDialog: boolean = false;
  @Output() visibleChange = new EventEmitter<{ isVisibleDialog: boolean; organisationId: number | null }>();

  createOrganisationForm = new FormGroup({
    organisationName: new FormControl('', [Validators.required]),
    owner: new FormControl<UsersResponse>(null, [Validators.required])
  });

  users: UsersResponse[] | [] = [];
  selectedUser: UsersResponse;

  private readonly createOrganisationQuery: string = `mutation CreateOrganisation($input: OrganisationInput!) {
    createOrganisation(input: $input) { id, name }
  }`;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private messageService: MessageService
  ) { }

  ngOnInit() { }

  getUsers(event: {originalEvent: Event; query: string;}) {
    this.userService.searchUsers(event.query).subscribe(res => this.users = res.users);
  }

  onSubmit() {
    const { organisationName, owner } = this.createOrganisationForm.value;
    this.apiService.graphql<{createOrganisation: { id: number }}>(this.createOrganisationQuery, { input: { ownerId: owner.id, name: organisationName } })
    .subscribe((response: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Organisation Created' });
      const { createOrganisation: { id } } = response;
      this.onHideDialog(id);
    },
    (error) => {
      console.error(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not create organisation' });
      this.onHideDialog();
    });
  }

  onHideDialog(organisationId: number | null = null) {
    this.isVisibleDialog = false;
    this.visibleChange.emit({ isVisibleDialog: this.isVisibleDialog, organisationId});
  }
}
