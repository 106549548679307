export default class IFrameOnLoadDirective {
    constructor() {
        this.restrict = 'A';
        this.scope = {
            callBack: '&iframeOnload'
        };
    }
    link(scope, element) {
        element.on('load', () => scope.callBack());
    }
}
