import { getProjectMapLayers } from "../../store/selectors";

export default class ProjectMapLayersController {

    static $inject = ['$scope', '$ngRedux'];
    constructor($scope, $ngRedux) {
        $scope.$on('$destroy', $ngRedux.connect((state) => ({
            layers: getProjectMapLayers(state)
        }), null)(this));
    }
}