import { Component, computed, inject, OnInit, SecurityContext, signal } from '@angular/core';
import { CollectionItemPreview } from './collection-item.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import * as marked from 'marked';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-collection-item-preview',
    templateUrl: 'collection-item-preview.component.html',
    standalone: true,
    imports: [
        CommonModule
    ]
})

export class CollectionItemPreviewComponent implements OnInit {

    dialogConfig = inject(DynamicDialogConfig);
    sanitizer = inject(DomSanitizer);

    preview = signal<CollectionItemPreview | null>(null);

    images = computed(() => {
        const preview = this.preview();
        return preview.mediaItems.filter(item => {
            const type = item.type.split('/')[0];
            return type === 'image';
        }).sort((a, b) => a.sort - b.sort);
    });
    currentImageIndex = signal(0);
    currentImage = computed(() => {
        const images = this.images();
        const index = this.currentImageIndex();
        return images[index];
    });

    audio = computed(() => {
        const preview = this.preview();
       
        return preview.mediaItems.filter(item => {
            const type = item.type.split('/')[0];
            return type === 'audio';
        });
    });

    data = computed(() => {
        const preview = this.preview();
        const markedData = preview.data.filter(d => !!d.value).map(d => {
            return {
                ...d,
                value: this.sanitizer.sanitize(SecurityContext.HTML, marked.parse(d.value))
            }
        });
        return markedData;
    });

    // TODO: style carousel

    ngOnInit() {
        this.preview.set(this.dialogConfig.data['preview']);

        console.log('CollectionItemPreviewComponent', this.preview());
        
    }
}