import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, effect, signal } from "@angular/core";
import { HomeService } from "./home.service";
import { OrganisationFolder, OrganisationFolderTreeNode } from "./home.models";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { SvgIconComponent } from "angular-svg-icon";

@Component({
    selector: 'app-home-move-location',
    standalone: true,
    templateUrl: './home-move-location.component.html',
    imports: [
        NgClass,
        NgOptimizedImage,
        SvgIconComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
    li{
        padding: 5px 5px 5px 0;
    }
    
    li > div {
        @apply hover:bg-[#F2F8FE];
    }

    li.selected > div{
        @apply bg-[#DFEBFF] hover:bg-[#DFEBFF];
    }

    li.selected button{
        @apply hover:bg-[#F2F8FE];
    }

    li.disabled{
        @apply opacity-50 pointer-events-none;
    }
`]
})
export class HomeMoveLocationComponent {

    @Input() currentLocationId: string | null;
    @Input() excludedFolderIds: string[] = [];
    @Input() searchedFolders: { search: string, folders: OrganisationFolder[] };

    @Output() select = new EventEmitter<string | null>();

    current = this.homeService.current;
    selection = signal<string | null | undefined>(null);

    constructor(private homeService: HomeService) {
        effect(() => this.select.emit(this.selection()));
        this.selection.set(this.currentLocationId);
    }

    selectFolder(f: OrganisationFolderTreeNode | OrganisationFolder) {
        if (this.selection() === f.id) {
            this.selection.set(this.homeService.current().node.id);
        } else {
            this.selection.set(f.id);
        }
    }

    openFolder(f: OrganisationFolderTreeNode) {
        this.homeService.openFolder(f);
        this.selection.set(f.id);
    }

}