<div class="relative h-full w-full overflow-hidden">
    <div class="sticky flex-none flex items-center gap-5 p-5 border-b border-grey-border bg-white text-lg font-semibold">
        <div class="w-full flex items-center">
            <h2>Previous version</h2>
        </div>
        <div class="w-full flex items-center justify-between">
            <h2>Current version</h2>
            <p-button (click)="revertRecord()" severity="danger" label="Revert to previous"></p-button>
        </div>
    </div>
    <div class="h-full w-full grid grid-cols-2 gap-5 p-5 pb-24 overflow-y-auto coreo-scrollbar bg-background">
        <div class="w-full bg-white rounded-xl p-7">
            @if (hasGeometry()) {
                <div class="w-full p-3 mb-4" [style.background-color]="hasGeometryDiff() ? '#CD564233' : null">
                    <div class="relative w-full h-44">
                        <app-record-map [recordId]="recordId" [showFullscreen]="false" [replace]="geometryDiff()"></app-record-map>
                    </div>
                </div>
            }
            <app-record-summary [recordId]="recordId" [readonly]="true" [replace]="true" [diff]="log.diff"></app-record-summary>
        </div>
        <div class="w-full bg-white rounded-xl p-7">
            @if (hasGeometry()) {
                <div class="w-full p-3 mb-4" [style.background-color]="hasGeometryDiff() ? '#009E1933' : null">
                    <div class="relative w-full h-44">
                        <app-record-map [recordId]="recordId" [showFullscreen]="false"></app-record-map>
                    </div>
                </div>
            }
            <app-record-summary [recordId]="recordId" [readonly]="true" [highlight]="true" [diff]="log.diff"></app-record-summary>
        </div>
    </div>
</div>