<p-toast position="bottom-center" [life]="2000"></p-toast>
<p-dialog header="Transfer Organisation Ownership" [(visible)]="isVisibleDialog" [modal]="true" #transferDialog (onHide)="onHideDialog()">
  <form class="w-96" [formGroup]="transferOrganisationForm">
    <div class="flex flex-col gap-1">
      <label for="owner">Transfer to user</label>
      <span class="p-fluid">
        <p-autoComplete [forceSelection]="true" [appendTo]="transferDialog" [showClear]="true"
          [suggestions]="users" [showEmptyMessage]="true" (completeMethod)="getUsers($event)"
          formControlName="owner" field="displayName" placeholder="Search user..." id="owner">
          <ng-template let-user pTemplate="item">
            <div class="flex flex-col gap-2">
              <span class="block">{{user.displayName}}</span>
              <span class="block text-sm text-grey-80 italic flex space-between">
                {{user.username}} - ID: {{user.id}}</span>
            </div>
          </ng-template>
        </p-autoComplete>
      </span>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button [text]="true" size="large" (click)="onHideDialog()">Cancel</p-button>
    <p-button type="submit" [disabled]="!transferOrganisationForm.valid" size="large" (click)="onSubmit()">Transfer</p-button>
  </ng-template>
</p-dialog>