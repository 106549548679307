export default class StringToNumberDirective {
    constructor() {
        this.restrict = "A";
        this.require = "ngModel";
    }
    link(scope, element, attributes, ngModel) {
        ngModel.$parsers.push(function (val) {
            // console.log("Parser");
            return parseFloat(val);
        });
        ngModel.$formatters.push(function (val) {
            // console.log("Formatter");
            return parseFloat(val);
        });
    }
}
