import { getProjectCollection, getProjectId } from "../../store/selectors";

export const CollectionSelectComponent = {
    bindings: {
        ngModel: '<',
        collectionId: '<',
        key: '<',
        multiple: '<',
        disabled: '<',
        showClear: '<',
        itemData: '<',
        label: '@'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    template: require('!raw-loader!./collection-select.component.html').default,
    controllerAs: 'ctrl',
    controller: class CollectionSelectComponent {
        static $inject = ['$scope', '$timeout', '$ngRedux', '$element', '$q', 'ProjectActions', 'ProjectService'];
        constructor($scope, $timeout, $ngRedux, $element, $q, ProjectActions, ProjectService) {
            this.$ngRedux = $ngRedux;
            this.ProjectActions = ProjectActions;
            this.ProjectService = ProjectService;
            this.$scope = $scope;
            this.$timeout = $timeout;
            this.$element = $element;
            this.$q = $q;

            const state = $ngRedux.getState();
            this.projectId = getProjectId(state);

            this.$scope.topIndex = 0;
            this.chips = [];
            this.multipleItems = [];
            this.itemMap = new Map();
            this.loading = false;
            this.previousValue = null;

            this.output = '';
            this.search = '';
            this.style = {
                height: 0
            };
            this.pages = {};
            this.PAGE_SIZE = 100;
            this.showSelectOptions = false;

            $scope.$on('$mdMenuClose', () => {
                this.$timeout(() => this.resetSearch(), 500);
            });
        }

        $onInit() {
            const collection = getProjectCollection(this.collectionId)(this.$ngRedux.getState());
            if (!collection) {
                this.itemCount = 0;
                this.disabled = true;
                console.warn('Failed to find collection', this.collectionId);
                return;
            }

            this.itemCount = collection.itemCount;
            // this.style.height = this.itemCount * 48 + 'px';
            this.style.height = '280px';

            this.showClear = typeof this.showClear === 'undefined' ? true : this.showClear;

            const vm = this;
            const model = this.ngModelCtrl;

            const original$isEmpty = model.$isEmpty;
            model.$isEmpty = function () {
                return angular.isArray(model.$viewValue) ? model.$viewValue.length === 0 : original$isEmpty(model.$viewValue);
            }

            const updateView = () => {
                if (model.$viewValue === this.previousValue) { // don't rerender/fetch same value in case of invalid key
                    return;
                }
                this.previousValue = model.viewValue;
                if (vm.multiple) {
                    // vm.chips = vm.chips.filter(chip => model.$viewValue.includes(chip.key));
                    if (!model.$viewValue) {
                        vm.chips = [];
                    } else {
                        if (model.$viewValue.some(s => !vm.itemMap.has(s))) {
                            this.previousValue = model.$viewValue
                            return this.loadItemsByKeys(model.$viewValue).then(() => {
                                vm.chips = model.$viewValue.map(key => vm.itemMap.get(key));
                            });
                        }
                        vm.chips = model.$viewValue.map(key => vm.itemMap.get(key));
                    }
                } else {
                    if (model.$isEmpty()) {
                        vm.search = '';
                    } else {
                        let item = vm.itemMap.get(model.$viewValue);
                        if (!item) {
                            this.loadItemsByKeys([model.$viewValue]).then(() => {
                                item = vm.itemMap.get(model.$viewValue);
                                vm.search = item && item.value;
                            });
                        } else {
                            vm.search = item && item.value;
                        }
                    }
                }
            }

            model.$render = function () {

                if (!model.$isEmpty(model.$viewValue)) {
                    updateView();
                }
                if (vm.multiple) {
                    vm.search = '';
                }
            };
        }

        loadItemsByKeys(keys) {
            return this.ProjectService.getCollectionItemsByKeys(this.projectId, this.collectionId, keys).then((results) => {
                for (const item of results) {
                    this.itemMap.set(item.key, item);
                }
            });
        }

        getItemAtIndex(index) {
            const pageNumber = Math.floor(index / this.PAGE_SIZE);
            const page = this.pages[pageNumber];

            if (page) {
                return page[index % this.PAGE_SIZE];
            } else if (page !== null) {
                this.fetchPage(pageNumber);
            }
        }

        getLength() {
            return this.itemCount;
        }

        fetchPage(pageNumber) {


            // If this is set we are in mid-flight
            if (this.canceler) {
                // this.canceler.resolve();
            }

            this.pages[pageNumber] = null;
            const offset = pageNumber * this.PAGE_SIZE;

            this.canceler = this.$q.defer();

            this.ProjectService.searchCollectionItems(this.projectId, this.collectionId, this.search, offset, this.PAGE_SIZE, this.canceler).then(result => {
                const { count, items } = result;

                this.pages[pageNumber] = items;
                this.canceler = null;
                for (const item of items) {
                    this.itemMap.set(item.key, item);
                }
                this.itemCount = count;
                return items;
            });
        }

        refresh() {
            return this.$timeout(() => {
                this.$scope.$broadcast("$md-resize");
                window.dispatchEvent(new Event('resize'));
            }, 100);
        }

        resetSearch() {
            this.search = '';
            this.items = this.allItems;
        }

        setViewValue(value) {
            this.ngModelCtrl.$setViewValue(value);
        }


        updateValue() {
            const value = this.multiple ? this.multipleItems.map(i => i.key) : this.ngModel;
            this.setViewValue(value);
        }

        update($event) {
            const { key, value } = $event.target.dataset;
            let viewValue = key;
            if (this.multiple) {
                if (!this.ngModel) {
                    viewValue = [key];
                } else {
                    viewValue = this.ngModel.includes(key) ? this.ngModel : this.ngModel.concat(key);
                }
            }
            this.setViewValue(viewValue);

            this.ngModelCtrl.$render();
            this.search = value;

            if (this.multiple) {
                this.resetSearch();
                this.hideOptions();
                this.$scope.topIndex = 0;
            } else {
                this.hideOptions();
            }
        }

        updateSearch() {
            this.pages = {};
        }

        removeChip($chip) {
            this.setViewValue(this.ngModel.filter(k => k !== $chip.key));
        }

        showOptions() {
            this.showSelectOptions = true;
        }

        hideOptions(event) {
            if (event && event.relatedTarget && event.relatedTarget.tagName === "OPTION") {
                return;
            }
            this.ngModelCtrl.$render();
            this.showSelectOptions = false;
        }

        clear() {
            this.pages = {};
            this.setViewValue(this.multiple ? [] : null);
            this.ngModelCtrl.$render();
            this.$scope.topIndex = 0;
        }

        openMenu($mdMenu, ev) {
            $mdMenu.open(ev);
        }
    }
};
