import { urlBase64Decode } from "../helpers/utils";

export default class Auth {

    static $inject = ['$rootScope', '$injector', '$location', 'localStorageService', 'hostname', 'protocol', 'port', 'apiHostname', 'apiProtocol', 'apiPort'];
    constructor($rootScope, $injector, $location, localStorageService, hostname, protocol, port, apiHostname, apiProtocol, apiPort) {
        this.$rootScope = $rootScope;
        this.$injector = $injector;
        this.$location = $location;
        this.localStorageService = localStorageService;
        this.hostname = hostname;
        this.protocol = protocol;
        this.port = port;
        this.apiHostname = apiHostname;
        this.apiProtocol = apiProtocol;
        this.apiPort = apiPort;

        this.user = false;
        this.token = false;
        this.permissions = {};
    }

    getClaimsFromToken(token) {
        var user = {};
        if (typeof token !== 'undefined') {
            var encoded = token.split('.')[1];
            user = JSON.parse(urlBase64Decode(encoded));
        }
        return user;
    }

    processTokenResponse(token) {
        if (token) {
            this.setToken(token);
            this.user = this.getClaimsFromToken(token);
        }
    }

    setToken(auth_token) {
        this.localStorageService.set('auth_token', auth_token);
        this.token = auth_token;
    }

    getPermissions() {
        var $http = this.$injector.get('$http');
        return $http.get('/permissions').then((permissionsResponse) => {
            this.permissions = _.reduce(permissionsResponse.data, function (acc, p) {
                acc[p.projectId] = p.role;
                return acc;
            }, {});
            return this.permissions;
        });
    }

    updatePermissions() {
        return this.getPermissions();
    }

    login(type, credentials) {
        if (type === 'local') {
            var mutation = 'mutation{result: login(input:{email: "' + credentials.email + '", password: "' + credentials.password + '"}){token}}';
            var api = this.$injector.get('CoreoAPI');
            return api.mutation(mutation).then((response) => {
                this.processTokenResponse(response.token);
                return this.getPermissions().then(() => {
                    this.$rootScope.$broadcast('jobs:refresh');
                    return this.user;
                });
            });
        } else {
            var cbPort = '';
            const { port, apiPort, apiProtocol, apiHostname, hostname, protocol } = this;
            if (port && port !== '80' && port !== '443') {
                cbPort = ':' + port;
            }

            var cbUrl = [protocol, '://', hostname, cbPort, '/%23/login_cb'].join('');
            var targetUrl = [apiProtocol, '://', apiHostname, (+apiPort === 80 || +apiPort === 443) ? '' : ':' + apiPort, '/auth/', type, '?return_url=', cbUrl].join('');
            window.location = targetUrl;
        }
    }

    signup(data) {

        var api = this.$injector.get('CoreoAPI');
        data.legacy = false;
        var input = api.gqlStringify(data);
        var mutation = 'mutation{result: signup(input:' + input + ' ){token}}';
        return api.mutation(mutation).then((res) => {
            this.processTokenResponse(res.token);
            return this.user;
        });
    }

    passwordReset(email) {
        var api = this.$injector.get('CoreoAPI');
        var input = api.gqlStringify({ email: email, legacy: false });
        var mutation = 'mutation{result: passwordForgot(input: ' + input + ')}';
        return api.mutation(mutation);
    }

    passwordUpdate(newPassword) {
        var api = this.$injector.get('CoreoAPI');
        var input = api.gqlStringify({ token: this.password_reset_token, password: newPassword });
        var mutation = 'mutation{result: passwordReset(input: ' + input + '){token}}';
        return api.mutation(mutation).then((res) => {
            this.processTokenResponse(res.token);
            return this.user;
        });
    }

    verifyAccount() {
        var api = this.$injector.get('CoreoAPI');
        var input = api.gqlStringify({ token: this.account_verification_token });
        var mutation = 'mutation{result: verifyAccount(input: ' + input + '){token}}';
        return api.mutation(mutation).then((res) => {
            this.processTokenResponse(res.token);
            return this.user;
        });
    }

    getUser() {
        var api = this.$injector.get('CoreoAPI');
        var query = `
      query getUser {
        viewer {
            id,
            email,
            role,
            imageUrl,
            displayName,
            username,
            provider,
            preferences,
            apiKey {
              token
            }
        }
      }
    `
        return api.query(query).then((res) => {
            return res.viewer;
        });
    }

    createOrRefreshApiKey() {
        var api = this.$injector.get('CoreoAPI');
        var mutation = `
        mutation{
            result: createOrRefreshUserApiKey{
                token
            }
        }
    `;
        return api.mutation(mutation).then((res) => {
            return res.token;
        });
    }

    deleteApiKey() {
        var api = this.$injector.get('CoreoAPI');
        var mutation = `
        mutation{
            result: deleteUserApiKey
        }
    `;
        return api.mutation(mutation);
    }
}
Auth.$inject = ['$rootScope', '$injector', '$location', 'localStorageService', 'hostname', 'protocol', 'port', 'apiHostname', 'apiProtocol', 'apiPort']

