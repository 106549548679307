import { NgClass } from "@angular/common";
import { Component, computed, inject, input } from "@angular/core";
import { JobsService, CoreoJob } from "src/app/core/services/jobs.service";
import { TimeAgoPipe } from "../../pipes/timeago.pipe";
import { ApiService } from "src/app/core/services/api.service";

const JOB_ERROR_MESSAGE_CODES = {
    noRecordsMediaToExport: 'No matching media!',
    noMatchingRecordsFoundToExport: 'No matching records!',
};

@Component({
    selector: 'app-job',
    standalone: true,
    templateUrl: './job.component.html',
    styleUrls: ['./job.component.scss'],
    imports: [
        TimeAgoPipe,
        NgClass
    ]
})
export class JobComponent {

    jobService = inject(JobsService);

    job = input.required<CoreoJob>();
    status = computed(() => this.job().status);
    isJobErrorMessage = computed<boolean>(() => !!JOB_ERROR_MESSAGE_CODES[this.job().message]);
    jobMessage = computed<string>(() => this.isJobErrorMessage() ? JOB_ERROR_MESSAGE_CODES[this.job().message] : this.job().message);
    urlTarget = computed(() => {
        const url = this.job().url;
        return url.startsWith('http') ? '_blank' : '_self';
    });

    urlAction = computed(() => {
        const job = this.job();
        if (job.status !== 'complete') {
            return '';
        }
        return this.jobService.jobAction(job);
    });

    title = computed<string>(() => this.jobService.jobTitle(this.job()));

    showClear = computed<boolean>(() => {
        const status = this.job().status;
        return status === 'complete' || status === 'failed';
    });

    jobStatus = computed(() => {
        const status = this.job().status;
        if (status === 'pending') {
            return 'Queued';
        }
        if (status === 'processing') {
            return 'Processing';
        }
    });

    jobsService = inject(JobsService);
    apiService = inject(ApiService);

    action() {
        return this.jobService.actionJob(this.job());
    }

    openProject(id: number) {
        const query = `query CoreoAAGetProject($id: Int!){
            project(id: $id){
                slug
                organisation{
                slug}
            }
    }`;
        this.apiService.graphql<any>(query, { id }).subscribe((res: any) => {
            const url = `/${res.project.organisation.slug}/${res.project.slug}`;
            window.open(url, '_self');
        });
    }

    clear() {
        this.jobsService.clear(this.job());
    }
}