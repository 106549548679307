import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { Observable, map, tap } from 'rxjs';
import { ApiService } from 'src/app/core';

@Component({
    selector: 'app-autocomplete-modal',
    templateUrl: 'autocomplete-modal.component.html',
    standalone: true,
    imports: [
        CommonModule,
        InputTextModule
    ]
})

export class AutocompleteModalComponent implements OnInit {

    private surveyId: number;
    private path: string;

    public suggestions$: Observable<string[]>;

    constructor(
        private dialogRef: DynamicDialogRef,
        private dialogConfig: DynamicDialogConfig,
        private apiService: ApiService
    ) { }

    ngOnInit() {
        this.surveyId = this.dialogConfig.data['surveyId'];
        this.path = this.dialogConfig.data['path'];

        this.getSuggestions();
    }

    // add pagination/ virtual scroll?
    private getSuggestions(search: string = null) {
        const query = `query autocompleteSuggestions($where: SequelizeJSON, $path: String){
            records(where: $where){
                data(path: $path)
            }
        }`;

        const where = {
            surveyId: this.surveyId,
        }

        if (search) {
            where['and'] = [{ data: { [this.path]: { iLike: `%${search}%` } } }];
        }

        this.suggestions$ = this.apiService.graphql<{ records: { data: any }[] }>(query, {
            where,
            path: this.path
        }).pipe(
            tap(res => console.log(res)),
            map(res => res.records.map(r => r.data).filter(r => !!r && r.length > 0))
        );
    }

    public handleSearch($event: Event) {
        const searchValue = ($event.target as HTMLInputElement).value;
        this.getSuggestions(searchValue);
    }

    public selectSuggestion($event: string) {
        this.dialogRef.close($event);
    }
}