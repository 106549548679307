import './to-app.scss';
import { APP_URL } from '../../intermediarypage.constants';

export const ToAppComponent = {
    bindings: {
        url: '<' // optional override
    },
    transclude: true,
    template: require('!raw-loader!./to-app.html').default,
    controllerAs: 'ctrl',
    controller: class ToAppController {
        constructor() {
            this.APP_URL = APP_URL;
        }

        $onInit() {

        }
    }

};
