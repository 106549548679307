export default class Mapbox {

    static $inject = ['mapboxAccessToken'];
    constructor(mapboxAccessToken) {
        this.mapbox = null;
        this.token = mapboxAccessToken;
    }

    get() {
        if (this.mapbox) {
            return this.mapbox;
        }

        return this.mapbox = import(/* webpackChunkName: "mapbox" */'mapbox-gl').then(({ default: mapboxgl }) => {
            mapboxgl.accessToken = this.token;
            return mapboxgl;
        });
    }
}