<p-dialog [modal]="true" header="Create API Key" [(visible)]="visible" [draggable]="false" [resizable]="false"
    [style]="{width: '20vw'}" (onHide)="reset()">
    <form [formGroup]="apiKeyForm">
        <div class="flex flex-col gap-2 mb-3">
            <label for="name">Name</label>
            <input pInputText formControlName="name" />
            @if (apiKeyForm.get('name').touched && apiKeyForm.get('name').hasError('required')) {
            <span>Name is required</span>
            }
        </div>
    </form>
    <ng-template pTemplate="footer">
        <p-button label="Create" type="submit" (click)="createToken()" [disabled]="!apiKeyForm.dirty"></p-button>
    </ng-template>
</p-dialog>
<p-confirmDialog></p-confirmDialog>
<div class="py-10 px-4 sm:px-10 max-w-screen-xl mx-auto">
    <p-panel class="block">
        <ng-template pTemplate="header">
            <div class="flex items-center justify-between">
                <span class="p-panel-title">API Keys</span>
                @if (role === 'admin') {
                <p-button label="Create API Key" class="order-2" (click)="open()"></p-button>
                }
            </div>
        </ng-template>
        <ng-template pTemplate="body">
            @if (showEmpty()) {
            <div class="flex justify-center items-center pb-4">
                <span class="text-center text-base">No API Keys found</span>
            </div>
            } @else {
            @for(apikey of apikeys(); track apikey.id){
            <div class="mb-4 p-2">
                <div class="flex flex-col gap-2 mb-3">
                    <label for="name">Name</label>
                    <input pInputText value="{{ apikey.name }}" disabled />
                </div>
                <div class="flex flex-col gap-2 mb-3">
                    <label for="name">Key</label>
                    <input pInputText value="{{ apikey.token }}" disabled />
                </div>
                <div class="flex gap-2">
                    <p-button label="Copy" app-clipboard [clipboard]="apikey.token"></p-button>
                    @if(role === 'admin'){
                    <p-button label="Revoke" severity="danger" (click)="revokeToken($event, apikey)"></p-button>
                    }
                </div>
            </div>
            }
            }
        </ng-template>
    </p-panel>
</div>