import { Component } from '@angular/core';

@Component({
    selector: 'app-modal-button',
    templateUrl: 'modal-button.component.html',
    standalone: true
})

export class ModalButtonComponent {
    constructor() { }
}