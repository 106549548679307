import './get-app.scss';

export const GetAppComponent = {
    bindings: {},
    transclude: true,
    template: require('!raw-loader!./get-app.html').default,
    controllerAs: 'ctrl',
    controller: class GetAppController {
        static $inject = ['androidUrl', 'iosUrl'];
        constructor(androidUrl, iosUrl) {
            this.androidUrl = androidUrl;
            this.iosUrl = iosUrl;
        }

        $onInit() {

        }
    }

};
