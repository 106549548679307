import { Component, EventEmitter, OnInit, Output, inject, input, signal } from '@angular/core';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';

type BreadcrumbData = {
    title: string;
    id: number;
}

type ResponseData = {
    survey: {
        attributes: {
            id: number;
            questionType: string;
        }[];
    } 
    associates: {
        association: {
            id: number;
            questionType: string;
        };
        record: {
            id: number;
            title: string;
        }
    }[]
}

@Component({
    selector: 'app-record-breadcrumb',
    templateUrl: 'record-breadcrumb.component.html',
    standalone: true
})

export class RecordBreadcrumbComponent implements OnInit {

    private apiService = inject(ApiService);

    recordId = input.required<number>();
    recordTitle = input.required<string>();

    @Output() record: EventEmitter<any> = new EventEmitter();

    breadcrumb = signal<BreadcrumbData[]>([]);

    constructor() {}
    
    ngOnInit() {
        this.breadcrumb.set([{title: this.recordTitle(), id: this.recordId()}]);
        this.getParent(this.recordId());
    }

    private getParent(id: number) {
        const query = `query getRecordParent{
            record(id: ${id}){
                id
                associates{
                    association{
                        id
                        questionType
                    }
                    record{
                        id
                        title
                    }
                }
                survey{
                    attributes{
                        id
                        questionType
                    }
                }
            }
        }`;

        this.apiService.graphql<{ record: ResponseData }>(query).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                const childAttrs = res.record.survey.attributes.filter(a => a.questionType === 'child');

                const parentAssociates = res.record.associates.filter(r => {
                    const childAssociation: boolean = r.association.questionType === 'child';
                    const isParent: boolean = !childAttrs.find(a => a.id === r.association.id);
                    return childAssociation && isParent;
                });

                if (parentAssociates.length > 0) {
                    const parent = parentAssociates[0];
                    const data = { title: parent.record.title, id: parent.record.id };
                    this.breadcrumb.update(b => [data, ...b]);
                    this.getParent(parent.record.id);                    
                }
            }
        })
    }
}