export default class ProjectFormCreateController {
    static $inject = ['$mdDialog'];
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    ok() {
        this.$mdDialog.hide({
            name: this.name,
            title: this.title
        });
    }

    cancel() {
        this.$mdDialog.cancel();
    }
}