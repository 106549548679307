import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[app-clipboard]',
  standalone: true
})
export class ClipboardDirective {
  @Input('clipboard') value: string;
  constructor() { }

  @HostListener('click')
  onClick(): void {
    if (navigator.clipboard && this.value) {
      navigator.clipboard.writeText(this.value);
    }
  }
}
