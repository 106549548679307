import { Point } from '@turf/helpers';
import { LineString, Polygon } from 'geojson';

type CollectionGeometry = Point | LineString | Polygon;

export interface CollectionMediaItem {
    id?: number;
    url: string;
    size?: number
    name?: string;
    caption?: string;
    type?: string;
    sort?: number;
}

export interface CollectionItem {
    id: number;
    collectionId: number;
    key: string;
    value: string;
    data: any;
    sort: number;
    geometry?: CollectionGeometry;
    mediaItems?: CollectionMediaItem[]
};

  
export interface AttributeConditionRule {
    path: string;
  
    /**
     * For `integer` and `float` fields.
     *
     * Compares the answer against `numberComparand`.
     * - `lt`: Less Than
     * - `lte`: Less Than or Equal
     * - `eq`: Equals
     * - `ne`: Not Equals
     * - `gt`: Greater Than
     * - `gte`: Greater Than or Equal
     */
    numberCondition?: 'lt' | 'lte' | 'eq' | 'ne' | 'gt' | 'gte' | 'answered' | 'unanswered';
    /** A number to compare against the answer */
    numberComparand?: number;
  
    /**
     * For `boolean` fields.
     *
     * Evaluates to `answer == isTrue`.
     */
    isTrue?: boolean;
  
    /**
     * For `text` fields.
     *
     * Compares the answer against `textComparand`.
     * `is` and `not` both perform a simple equality check.
     * `contains` and `notcontains` look for the presence of a substring (or lack of).
     * `matches` and `notmatches` test against a regular expression.
     */
    textCondition?: 'is' | 'not' | 'contains' | 'notcontains' | 'matches' | 'notmatches' | 'answered' | 'unanswered';
    /** A text or regex string to compare against the answer */
    textComparand?: string;
  
    /**
     * For `date` fields.
     *
     * Compares the answer against `dateComparand`.
     */
    dateCondition?: 'before' | 'after' | 'is' | 'not' | 'answered' | 'unanswered';
    /** A date/time string to compare against the answer */
    dateComparand?: string;
  
    /**
     * For `datetime` fields.
     *
     * Compares the answer against `datetimeComparand`.
     * `beforetime` and `aftertime` compare the time of day.
     */
    datetimeCondition?: 'before' | 'after' | 'beforetime' | 'aftertime' | 'answered' | 'unanswered';
    /** A datetime or time string to compare against the answer */
    datetimeComparand?: string;
  
    /**
     * For `select` fields.
     *
     * Compares the answer against `selectComparand`.
     */
    selectCondition?: 'is' | 'not' | 'answered' | 'unanswered' | 'contains' | 'notcontains';
    /** A collection key to compare against the answer */
    selectComparand?: string;
};
  
export interface AttributeConditions {
    any: boolean;
    rules: AttributeConditionRule[];
}
  
const attributeTypes = [
    'text',
    'boolean',
    'select',
    'multiselect',
    'integer',
    'float',
    'attachment',
    'media',
    'date',
    'datetime',
    'email',
    'url',
    'coordinatetransform',
    'rgeolocation',
    'geometryquery'
    // 'geometry',
] as const;
export type AttributeType = typeof attributeTypes[number];
  
const attributeQuestionTypes = [
    'geometry',
    'short',
    'long',
    'boolean',
    'confirm',
    'multi',
    'modal',
    'geometryselect',
    'slider',
    'numeric',
    'mediaGallery',
    'signature',
    'photo',
    'date',
    'datetime',
    'email',
    'url',
    'child',
    'association',
    'expression',
    // 'thankyou',
    'text', // text block
] as const;  
export type AttributeQuestionType = typeof attributeQuestionTypes[number];
  
export interface Attribute {
    id: number;
    projectId: number;
    meta: any;
    uuid: string;
    order: number;
    path: string;
    type: AttributeType;
    label: string;
    collectionId: number;
    filterable: boolean;
    questionType: AttributeQuestionType;
    config: any;
    text: string;
    description: string;
    help: string;
    conditions: AttributeConditions;
    sectionIdx: number;
    surveyId: number;
    associatedSurveyId: number;
    required: boolean;
    visible: boolean;
    parentCollectionId: number;
}

export class Question {
    value: any;
    id: number;
    projectId: number;
    meta: any;
    uuid: string;
    order: number;
    path: string;
    type: AttributeType;
    label: string;
    collectionId: number;
    filterable: boolean;
    questionType: AttributeQuestionType;
    config: any;
    text: string;
    description: string;
    help: string;
    conditions: AttributeConditions;
    sectionIdx: number;
    surveyId: number;
    associatedSurveyId: number;
    required: boolean;
    visible: boolean;
    parentCollectionId: number;

    constructor(options: {
        value?: any;
        id?: number;
        projectId?: number;
        meta?: any;
        uuid?: string;
        order?: number;
        path?: string;
        type?: AttributeType;
        label?: string;
        collectionId?: number;
        filterable?: boolean;
        questionType?: AttributeQuestionType;
        config?: any;
        text?: string;
        description?: string;
        help?: string;
        conditions?: AttributeConditions;
        sectionIdx?: number;
        surveyId?: number;
        associatedSurveyId?: number;
        required?: boolean;
        visible?: boolean;
        parentCollectionId?: number;
    } = {}) {
        this.value = options.value;
        this.id = options.id;
        this.projectId = options.projectId;
        this.meta = options.meta;
        this.uuid = options.uuid;
        this.order = options.order;
        this.path = options.path;
        this.type = options.type;
        this.label = options.label;
        this.collectionId = options.collectionId;
        this.filterable = options.filterable;
        this.questionType = options.questionType;
        this.config = options.config;
        this.text = options.text;
        this.description = options.description;
        this.help = options.help;
        this.conditions = options.conditions;
        this.sectionIdx = options.sectionIdx;
        this.surveyId = options.surveyId;
        this.associatedSurveyId = options.associatedSurveyId;
        this.required = options.required;
        this.visible = options.visible;
        this.parentCollectionId = options.parentCollectionId;
    }
}