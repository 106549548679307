import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/core';
import { RecordLog } from './record-activity.component';
import { RecordSummaryComponent } from '../record-data/record-summary.component';
import { RecordMapComponent } from '../record-geometry/record-map.component';
import { catchError, of, take } from 'rxjs';

@Component({
    selector: 'app-review-changes-modal',
    templateUrl: 'review-changes-modal.component.html',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        RecordMapComponent,
        RecordSummaryComponent
    ]
})

export class ReviewChangesModalComponent implements OnInit {

    private dialogConfig = inject(DynamicDialogConfig);
    private dialogRef = inject(DynamicDialogRef);
    private apiService = inject(ApiService);

    public log: RecordLog;
    public recordId: number;
    hasGeometry = signal(false);
    hasGeometryDiff= signal(false);
    geometryDiff = signal<any[] | null>(null);

    constructor() {}

    ngOnInit() {
        this.log = this.dialogConfig.data['log'];
        // console.log('log:', this.log);
        this.recordId = this.dialogConfig.data['recordId'];
        this.checkRecordHasGeometry();
        
        const hasGeometryDiff = !!(this.log.diff.find(d => {
            const splitPath = d.path.split('/');
            const diffPath = splitPath[1];
            
            return diffPath === 'geometry';
        }));
        this.hasGeometryDiff.set(hasGeometryDiff);

        if (this.hasGeometryDiff()) {
            this.setGeometryDiff();
        }
    }

    private setGeometryDiff() {
        const diff = this.log.diff.filter(d => {
            const splitPath = d.path.split('/');
            const diffPath = splitPath[1];
            if (diffPath === 'geometry') {
                return true;
            } else {
                return false;
            }
        });

        this.geometryDiff.set(diff);
    }

    private checkRecordHasGeometry() {
        const query = `query checkRecordHasGeometry($id: Int){
            record(id: $id){
                geometry {
                    type
                }
            }
        }`;
        
        this.apiService.graphql<{ record: { geometry: { type: string; } } }>(query, { id: this.recordId }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null)
            })
        ).subscribe(res => {
            if (!!res) {
                if (!!res.record.geometry) {
                    this.hasGeometry.set(true);
                }
            }
        });
    }

    public revertRecord() {
        // console.log('revertRecord');
        const query = `mutation revertRecord{
            revertRecord(input: { id: ${this.recordId}, recordLogId: ${this.log.id} })
        }`;

        this.apiService.graphql<{ revertRecord: boolean }>(query).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null)
            })
        ).subscribe(res => {
            // console.log(res);
            
            if (!!res && res.revertRecord) {
                console.log('recordRevert Success');
                this.dialogRef.close({ recordChanged: true });
            }
        });
    }
}