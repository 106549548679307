import { Component } from '@angular/core';

@Component({
    selector: 'app-record-button-add',
    templateUrl: 'record-button-add.component.html',
    standalone: true
})

export class RecordButtonAddComponent {
    constructor() { }
}