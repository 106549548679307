export const AppBreadcrumbsComponent = {
    template: require('!raw-loader!./breadcrumbs.component.html').default,
    bindings: {
        project: '<'
    },
    controllerAs: 'ctrl',
    controller: class AppBreadcrumbsComponent {

        static $inject = ['$state', '$transitions', '$scope', '$rootScope'];

        constructor($state, $transitions, $scope, $rootScope) {
            this.$transitions = $transitions;
            this.$state = $state;
            this.$scope = $scope;
            this.$rootScope = $rootScope;
            this.hasTutorial = false;
        }

        $onInit() {
            this.$scope.$on('$destroy', this.$transitions.onSuccess({}, () => this.render()));
            this.render();
        }

        tutorial() {
            this.$rootScope.$broadcast('$tutorial:restart');
        }

        render() {
            this.breadcrumbs = [];

            let state = this.$state.current;
            this.hasTutorial = state && state.data && state.data.hasTutorial;

            while (state) {
                if (state.data && state.data.breadcrumb) {
                    const bc = state.data && state.data.breadcrumb || {};
                    if (!bc.skip) {
                        const stateName = bc.state;
                        this.breadcrumbs.unshift({
                            label: bc.label || 'UNKNOWN BREADCRUMB',
                            state: stateName === null ? null : (stateName || state.name),
                            stateParams: bc.stateParams || {},
                            icon: bc.icon,
                            project: bc.project
                        });
                    }
                }
                let nextState;
                if (state.data && state.data.breadcrumb && state.data.breadcrumb.parent) {
                    nextState = state.data.breadcrumb.parent;
                } else if (state.parent) {
                    nextState = state.parent;
                }
                state = nextState ? this.$state.get(nextState) : null;
            }
        }
    }
};
