import { z } from 'zod';

export const ProjectPageSummarySchema = z.object({
    id: z.number(),
    type: z.string(),
    order: z.number().optional(),
    title: z.string(),
    icon: z.string().nullable(),
    auth: z.string().nullable().optional(),
    published: z.boolean().optional()
});
export type ProjectPageSummary = z.infer<typeof ProjectPageSummarySchema>;
