import { Injectable } from '@angular/core';
import { User, UsersResponse } from '../models/user.model';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { JwtService } from './jwt.service';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private currentUserSubject = new BehaviorSubject<User>({} as User);
    public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
    public isAuthenticated = this.isAuthenticatedSubject.asObservable();

    constructor(
        private apiService: ApiService,
        private jwtService: JwtService
    ) { }

    populate() {
        if (this.jwtService.getToken()) {
            this.apiService.get('/profile').subscribe({
                // complete: () => console.log('hERE'),
                error: () => this.purgeAuth()
            });
        } else {
            this.purgeAuth();
        }
    }

    setAuth(user: User) {
        this.jwtService.saveToken(user.token);
        this.currentUserSubject.next(user);
        this.isAuthenticatedSubject.next(false);
    }

    purgeAuth() {
        this.jwtService.destroyToken();
        this.currentUserSubject.next({} as User);
        this.isAuthenticatedSubject.next(false);
    }

    login(email: string, password: string) {
        return this.apiService.post('/login', { email, password }).pipe(
            map(
                data => {
                    // this.setAuth(data.user as any);
                    return data;
                }
            )
        );
    }

    getCurrentUser(): User {
        return this.currentUserSubject.value;
    }

    searchUsers(input: string) {
        const query = `query CoreoAASearchUsers{
            users(
                where:{
                    or: [
                        {displayName: { iLike: "%${input}%"}},
                        {username: { iLike: "%${input}%"}}
                    ]
                },
                limit: 300)
            {
                displayName,
                username,
                id
            }
        }`;
        return this.apiService.graphql<{ users: UsersResponse[] }>(query);
    }
}