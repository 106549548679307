import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CollectionItem } from '../form.types';
import { AvatarModule } from 'primeng/avatar';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-multiple-choice-item',
    templateUrl: 'multiple-choice-item.component.html',
    standalone: true,
    imports: [
        CommonModule,
        AvatarModule
    ]
})

export class MultipleChoiceItemComponent implements OnInit {

    @Input() item: CollectionItem;
    @Input() hasImage: boolean = false;
    @Input() selected: boolean = false;
    @Input() showTooltip: boolean = false;
    @Input() card: boolean = false;

    @Output() select: EventEmitter<CollectionItem> = new EventEmitter();
    @Output() tooltip: EventEmitter<string> = new EventEmitter();

    public imageSrc: string = null;
    private defaultImage: string = '/assets/icons/image-regular.svg';

    constructor() { }

    ngOnInit() { 
        if (this.hasImage && this.item.mediaItems.length > 0) {
            this.imageSrc = this.item.mediaItems[0].url ?? null;
        }
    }

    public handleTooltip($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();

        this.tooltip.emit(this.item.key);
    }

    public handleClick($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();

        this.select.emit(this.item);
    }
}