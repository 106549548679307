export const RGEO_LOCALISATIONS = [
    { label: "Afrikaans", value: "af" },
    { label: "Amharic", value: "am" },
    { label: "Arabic (Saudi Arabia)", value: "ar-sa" },
    { label: "Assamese", value: "as" },
    { label: "Azerbaijani (Latin)", value: "az-Latn" },
    { label: "Belarusian", value: "be" },
    { label: "Bulgarian", value: "bg" },
    { label: "Bangla (Bangladesh)", value: "bn-BD" },
    { label: "Bangla (India)", value: "bn-IN" },
    { label: "Bosnian (Latin)", value: "bs" },
    { label: "Catalan Spanish", value: "ca" },
    { label: "Valencian", value: "ca-ES-valencia" },
    { label: "Czech", value: "cs" },
    { label: "Welsh", value: "cy" },
    { label: "Danish", value: "da" },
    { label: "German (Germany)", value: "de" },
    { label: "German (Germany)", value: "de-de" },
    { label: "Greek", value: "el" },
    { label: "English (United Kingdom)", value: "en-GB" },
    { label: "English (United States)", value: "en-US" },
    { label: "Spanish (Spain)", value: "es" },
    { label: "Spanish (Spain)", value: "es-ES" },
    { label: "Spanish (United States)", value: "es-US" },
    { label: "Spanish (Mexico)", value: "es-MX" },
    { label: "Estonian", value: "et" },
    { label: "Basque", value: "eu" },
    { label: "Persian", value: "fa" },
    { label: "Finnish", value: "fi" },
    { label: "Filipino", value: "fil-Latn" },
    { label: "French (France)", value: "fr" },
    { label: "French (France)", value: "fr-FR" },
    { label: "French (Canada)", value: "fr-CA" },
    { label: "Irish", value: "ga" },
    { label: "Scottish Gaelic", value: "gd-Latn" },
    { label: "Galician", value: "gl" },
    { label: "Gujarati", value: "gu" },
    { label: "Hausa (Latin)", value: "ha-Latn" },
    { label: "Hebrew", value: "he" },
    { label: "Hindi", value: "hi" },
    { label: "Croatian", value: "hr" },
    { label: "Hungarian", value: "hu" },
    { label: "Armenian", value: "hy" },
    { label: "Indonesian", value: "id" },
    { label: "Igbo", value: "ig-Latn" },
    { label: "Icelandic", value: "is" },
    { label: "Italian (Italy)", value: "it" },
    { label: "Italian (Italy)", value: "it-it" },
    { label: "Japanese", value: "ja" },
    { label: "Georgian", value: "ka" },
    { label: "Kazakh", value: "kk" },
    { label: "Khmer", value: "km" },
    { label: "Kannada", value: "kn" },
    { label: "Korean", value: "ko" },
    { label: "Konkani", value: "kok" },
    { label: "Central Kurdish", value: "ku-Arab" },
    { label: "Kyrgyz", value: "ky-Cyrl" },
    { label: "Luxembourgish", value: "lb" },
    { label: "Lithuanian", value: "lt" },
    { label: "Latvian", value: "lv" },
    { label: "Maori", value: "mi-Latn" },
    { label: "Macedonian", value: "mk" },
    { label: "Malayalam", value: "ml" },
    { label: "Mongolian (Cyrillic)", value: "mn-Cyrl" },
    { label: "Marathi", value: "mr" },
    { label: "Malay (Malaysia)", value: "ms" },
    { label: "Maltese", value: "mt" },
    { label: "Norwegian (Bokmål)", value: "nb" },
    { label: "Nepali (Nepal)", value: "ne" },
    { label: "Dutch (Netherlands)", value: "nl" },
    { label: "Dutch (Netherlands)", value: "nl-BE" },
    { label: "Norwegian (Nynorsk)", value: "nn" },
    { label: "Sesotho sa Leboa", value: "nso" },
    { label: "Odia", value: "or" },
    { label: "Punjabi (Gurmukhi)", value: "pa" },
    { label: "Punjabi (Arabic)", value: "pa-Arab" },
    { label: "Polish", value: "pl" },
    { label: "Dari", value: "prs-Arab" },
    { label: "Portuguese (Brazil)", value: "pt-BR" },
    { label: "Portuguese (Portugal)", value: "pt-PT" },
    { label: "K’iche’", value: "qut-Latn" },
    { label: "Quechua (Peru)", value: "quz" },
    { label: "Romanian (Romania)", value: "ro" },
    { label: "Russian", value: "ru" },
    { label: "Kinyarwanda", value: "rw" },
    { label: "Sindhi (Arabic)", value: "sd-Arab" },
    { label: "Sinhala", value: "si" },
    { label: "Slovak", value: "sk" },
    { label: "Slovenian", value: "sl" },
    { label: "Albanian", value: "sq" },
    { label: "Serbian (Cyrillic, Bosnia and Herzegovina)", value: "sr-Cyrl-BA" },
    { label: "Serbian (Cyrillic, Serbia)", value: "sr-Cyrl-RS" },
    { label: "Serbian (Latin, Serbia)", value: "sr-Latn-RS" },
    { label: "Swedish (Sweden)", value: "sv" },
    { label: "Kiswahili", value: "sw" },
    { label: "Tamil", value: "ta" },
    { label: "Telugu", value: "te" },
    { label: "Tajik (Cyrillic)", value: "tg-Cyrl" },
    { label: "Thai", value: "th" },
    { label: "Tigrinya", value: "ti" },
    { label: "Turkmen (Latin)", value: "tk-Latn" },
    { label: "Setswana", value: "tn" },
    { label: "Turkish", value: "tr" },
    { label: "Tatar (Cyrillic)", value: "tt-Cyrl" },
    { label: "Uyghur", value: "ug-Arab" },
    { label: "Ukrainian", value: "uk" },
    { label: "Urdu", value: "ur" },
    { label: "Uzbek (Latin)", value: "uz-Latn" },
    { label: "Vietnamese", value: "vi" },
    { label: "Wolof", value: "wo" },
    { label: "isiXhosa", value: "xh" },
    { label: "Yoruba", value: "yo-Latn" },
    { label: "Chinese (Simplified)", value: "zh-Hans" },
    { label: "Chinese (Traditional)", value: "zh-Hant" },
    { label: "isiZulu", value: "zu" }
];

export const RGEO_FIELDS = [
    { label: 'Formatted Address', value: 'formattedAddress' },
    { label: 'Address Line', value: 'addressLine' },
    { label: 'Country/Region', value: 'countryRegion' },
    { label: 'Locality', value: 'locality' },
    { label: 'Postal Code', value: 'postalCode' },
    { label: 'Admin District', value: 'adminDistrict' },
    { label: 'Admin District 2', value: 'adminDistrict2' }
];
