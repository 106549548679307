<p-toast position="bottom-center" />
<p-confirmDialog styleClass="hide-close"></p-confirmDialog>

<div class="h-full w-full grid grid-cols-1 grid-rows-[44px_1fr] gap-8 p-10">
    <div class="w-full flex items-center justify-between">
        @if (name) {
            @if (editName) {
                <form [formGroup]="nameForm" (ngSubmit)="updateName()" class="w-full flex items-center gap-[10px]">
                    <input [ngModel]="collectionName" type="text" pInputText id="name" type="text" placeholder="Name of collection" formControlName="name" class="w-full min-w-56 h-11" />
                    <p-button type="submit" [loading]="nameLoading" [outlined]="true" size="large" [disabled]="!nameForm.valid || name.value === collectionName" label="Update"></p-button>
                    <p-button type="button" [text]="true" size="large" label="Cancel" (click)="editName = false"></p-button>
                </form>
            }
            @else {
                <span class="flex items-center gap-1">
                    <h2 class="text-3xl font-semibold text-nowrap truncate">{{ collectionName }}</h2>
                    <p-button [text]="true" size="large" (click)="editName = true">
                        <i class="fa-solid fa-pen"></i>
                    </p-button>
                </span>
            }
        }
        <div class="flex items-center justify-end gap-[10px]">
            <p-button [text]="true" size="large" (click)="settingsMenu.toggle($event)">
                <i class="fa-solid fa-gear flex items-center justify-center"></i>
            </p-button>
            <p-menu #settingsMenu [popup]="true" [model]="settingsItems" appendTo="body">
                <ng-template pTemplate="item" let-item>
                    <div class="p-menuitem-link {{ item.class }}">
                        <i class="{{ item.icon }}"></i>
                        <span>{{ item.label }}</span>
                    </div>
                </ng-template>
            </p-menu>
            
            <p-button label="Import" [outlined]="true" size="large" (click)="importMenu.toggle($event)">
                <i class="fa-solid fa-cloud-arrow-up mr-1"></i>
            </p-button>
            <p-menu #importMenu [popup]="true" [model]="importItems" appendTo="body">
                <ng-template pTemplate="item" let-item>
                    <div class="p-menuitem-link">
                        <span>{{ item.label }}</span>
                    </div>
                </ng-template>
            </p-menu>
            
            <p-button label="Export collection" [outlined]="true" size="large" (click)="exportCollection()">
                <i class="fa-solid fa-cloud-arrow-down mr-1"></i>
            </p-button>
        </div>
    </div>
    
    <div class="h-full w-full grid grid-rows-1 grid-cols-[minmax(714px,_auto)_minmax(380px,_auto)] gap-5 min-h-0">
        <app-collection-table #itemsTable [projectId]="projectId" [collectionId]="collectionId" (item)="item.emit($event)" class="rounded-md overflow-hidden shadow-panel"></app-collection-table>
        
        <app-attribute-table #attributeTable [projectId]="projectId" [collectionId]="collectionId" (hasUpdated)="refreshItems()" class="rounded-md overflow-hidden shadow-panel"></app-attribute-table>
    </div>
</div>

@if (processing()) {
<div class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-60">
    <p-progressSpinner styleClass="w-10 h-10" />
</div>
}