import { AfterViewInit, Component, computed, ElementRef, inject, input, OnInit, viewChild } from '@angular/core';
import { CollectionItemMediaComponent } from './collection-item-media.component';
import { CollectionItemService, MediaItem } from './collection-item.service';
import Sortable, { SortableEvent } from 'sortablejs';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
    selector: 'app-collection-item-media-list',
    templateUrl: 'collection-item-media-list.component.html',
    standalone: true,
    imports: [
        CollectionItemMediaComponent,
        ProgressSpinnerModule
    ]
})

export class CollectionItemMediaListComponent implements OnInit, AfterViewInit {

    collectionItemService = inject(CollectionItemService);

    items = input<MediaItem[]>();

    list = viewChild<ElementRef>('list');

    mediaLoading = this.collectionItemService.mediaLoading;
    sortedItems = computed(() => {
        const items = this.items();
        return items.sort((a, b) => a.sort - b.sort);
    });

    sortable: Sortable;

    constructor() { }

    ngOnInit() {
        console.log('CollectionItemMediaListComponent', this.items());
        // this.sortedItems.set(this.items());
    }

    ngAfterViewInit(): void {
        this.sortable = new Sortable(this.list().nativeElement!, {
            sort: true,
            handle: '.sort-handle',
            dataIdAttr: 'data-item',
            // onStart: (e: SortableEvent) => this.handleSort(e), 
            setData: function (/** DataTransfer */dataTransfer, /** HTMLElement*/dragEl) {
                dataTransfer.setData('id', dragEl.dataset.item); // `dataTransfer` object of HTML5 DragEvent
            },
            onEnd: (e: SortableEvent) => this.handleSort(e)
            // onEnd: function (/**Event*/evt: SortableEvent) {
            //     var itemEl = evt.item;  // dragged HTMLElement
            //     evt.to;    // target list
            //     evt.from;  // previous list
            //     evt.oldIndex;  // element's old index within old parent
            //     evt.newIndex;  // element's new index within new parent
            //     evt.oldDraggableIndex; // element's old index within old parent, only counting draggable elements
            //     evt.newDraggableIndex; // element's new index within new parent, only counting draggable elements
            //     console.log('onEnd', evt, itemEl);
                
            // },
        });
    }
    
    private handleSort(e: SortableEvent) {
        // console.log('onEnd', e);
        // console.log('handleSort!', (e as any).originalEvent.dataTransfer.getData('id'));
        // const new
        console.log(this.sortedItems());
        
        const sortedCopy = [...this.sortedItems()];
        const { oldIndex, newIndex } = e;
        const movedItem = sortedCopy.splice(oldIndex, 1)[0];
        sortedCopy.splice(newIndex, 0, movedItem);
        console.log(sortedCopy);
        const newSortedItems = sortedCopy.map((item, index) => {
            console.log(index);
            
            return {
                ...item,
                sort: index
            }
        });
        console.log(newSortedItems);
        
        this.collectionItemService.updateMediaItemsSort(newSortedItems);
    }
}