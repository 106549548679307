<div class="h-full w-full flex flex-col overflow-hidden">
    <div class="w-full p-5 border-b border-grey-30">
        <div [ngClass]="{'w-full rounded-[4px] border border-grey-border transition-all': true, 'h-11': !messaging(), 'h-60': messaging()}">
            @if (messaging()) {
                <div class="h-full w-full flex flex-col p-5">
                    @if (viewer()) {
                        <div class="flex items-center gap-3">
                            @if (viewer().imageUrl) {
                                <p-avatar [image]="viewer().imageUrl" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
                            }
                            @else {
                                <p-avatar [label]="viewer().displayName.charAt(0).toUpperCase()" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
                            }
                            <p class="text-sm text-text-primary font-semibold mb-2">{{ viewer().displayName }}</p>
                        </div>
                    }
                    <div class="grow py-5">
                        <textarea #textArea pInputTextarea [autoResize]="false" rows="4" spellcheck autoCapitalize="on" class="w-full p-0 border-none outline-none ring-0 coreo-scrollbar resize-none"></textarea>
                    </div>
                    <div class="w-full flex justify-end gap-[10px]">
                        <p-button label="Cancel" [outlined]="true" (click)="cancelMessage()"></p-button>
                        <p-button label="Send" (click)="postMessage()" [loading]="sending()"></p-button>
                    </div>
                </div>
            }
            @else {
                <div (click)="addMessage()" class="h-full px-5 flex items-center text-text-secondary cursor-text">
                    <p>Add message here</p>
                </div>
            }
        </div>
    </div>
    <ul class="grow overflow-y-auto coreo-scrollbar p-5 pb-20">
        @for (message of messages(); track message) {
            <li class="py-3">
                <app-record-message [message]="message"></app-record-message>
            </li>
        }
        @empty {
            <li class="text-sm text-text-primary font-semibold">No messages</li>
        }
    </ul>
</div>
