export default function () {
    return function (input, fallback = '') {
        if (input === true || input === 'true') {
            return 'Yes'
        } else if (input === false || input === 'false') {
            return 'No';
        }
        return fallback;
    };
}
