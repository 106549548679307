export default class ProjectAttributesService {
    static $inject = ['$http', 'CoreoAPI', 'CoreoGraphQLQuery', 'ProjectsService', '$mdDialog'];
    constructor($http, CoreoAPI, CoreoGraphQLQuery, ProjectsService, $mdDialog) {
        this.$http = $http;
        this.CoreoAPI = CoreoAPI;
        this.CoreoGraphQLQuery = CoreoGraphQLQuery;
        this.$mdDialog = $mdDialog;
        this.ProjectsService = ProjectsService;
    }

    attributeController(attribute, isNew) {

        var updatedAttribute = {
            label: attribute.label,
            required: attribute.required,
            visible: attribute.visible,
            path: attribute.path,
            type: attribute.type,
            meta: attribute.meta,
            order: attribute.order || 0,
            collectionId: attribute.collectionId,
            parentCollectionId: attribute.parentCollectionId,
            filterable: attribute.filterable
        };

        var postProcessedFieldsTypes = ['rgeolocation', 'media', 'coordinatetransform', 'geometryquery'];


        return ['$scope', '$mdDialog', function ($scope, $mdDialog) {
            $scope.path = attribute.path;
            $scope.attribute = updatedAttribute;
            $scope.sourceType = attribute.parentCollectionId ? 'collection' : 'survey';

            function isPostProcessed() {
                return postProcessedFieldsTypes.indexOf($scope.attribute.type) !== -1;
            }

            $scope.attribute.isPostProcessed = isPostProcessed();
            $scope.attribute.isWebhook = !!(attribute && attribute.meta && attribute.meta.webhook);

            $scope.ok = function () {
                delete $scope.attribute.isWebhook;
                delete $scope.attribute.isPostProcessed;

                $mdDialog.hide($scope.attribute);
            };
            $scope.isNew = isNew;

            $scope.cancel = function () {
                $mdDialog.cancel('cancel');
            };

            $scope.delete = function () {
                $mdDialog.cancel('delete');
            };

            $scope.pathUpdated = function (form) {
                if (!form.label.$dirty || !attribute.label) {
                    attribute.label = _.startCase(attribute.path);
                }
            };

            $scope.handleTypeChanged = function () {
                $scope.attribute.isPostProcessed = isPostProcessed();
            };

            $scope.handleWebhookChange = function (event) {
                if ($scope.attribute.isWebhook) {
                } else {
                    delete $scope.attribute.meta.webhook;
                }
            };
        }];
    }
}