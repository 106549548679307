export const ProjectFormsBlockTextComponent = {
    bindings: {
        block: '<',
        readonly: '<',
        active: '<'
    },
    template: require('!raw-loader!./project-forms-block-text.component.html').default,
    controllerAs: 'ctrl',
    controller: class ProjectFormsBlockTextComponent {
        static $inject = ['$scope', '$element', '$document', '$ngRedux', '$sce', 'ProjectFormActions'];
        constructor($scope, $element, $document, $ngRedux, $sce, ProjectFormActions) {
            this.$scope = $scope;
            this.$document = $document;
            this.$element = $element;
            this.$sce = $sce;
            this.hovered = false;
            this.focused = false;
            this.showToolbar = false;

            this.smConfig = {
                dialogsInBody: true,
                dialogsFade: true,
                maximumImageFileSize: 2024 * 1024, //2MB, limit server handles for text right now
                toolbar: [
                    ['headline', ['style']],
                    ['fontclr', ['color']],
                    ['textsize', ['fontsize']],
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['fontface', ['fontname']],
                    ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
                    ['height', ['height']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture', 'video']],
                    ['view', ['codeview']],
                    ['help', ['help']]
                ]
            }


            $scope.$on('$destroy', $ngRedux.connect(null, ProjectFormActions)(this));
        }

        $onInit() {
            this.text = this.block.text || '';
            this.readonlyText = this.$sce.trustAsHtml(this.text);

            const clickHandler = this.handleDocumentClick.bind(this);
            this.$document.on('click', this.handleDocumentClick.bind(this))
            this.$scope.$on('$destroy', () => this.$document.off('click', clickHandler));
        }

        handleDocumentClick(e) {
            if ($(this.$element).has(e.target).length > 0 || this.$element[0] == e.target) {
                this.showToolbar = true;
            } else {
                this.showToolbar = false;
            }
        }

        focus() {
            this.showToolbar = true;
        }

        update() {
            this.readonlyText = this.$sce.trustAsHtml(this.text);
            this.updateSelectedAttribute({
                text: this.text
            });
        }

    }
}