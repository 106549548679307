import { IComponentController, IOnChangesObject } from "angular";
import { CoreoDataLayerStyle } from "../../../types";

export const MapDataLayerEditorComponent = {
    bindings: {
        config: '<',
        types: '<',
        onUpdate: '&'
    },
    template: require('!raw-loader!./map-data-layer-editor.component.html').default,
    controllerAs: 'ctrl',
    controller: class MapDataLayerEditorController implements IComponentController {
        config: CoreoDataLayerStyle;
        preview: any;
        onUpdate: () => void;
        types: string[];
        showPoint: boolean;
        showLinestring: boolean;
        showPolygon: boolean;


        $onInit() {
            this.updateTypes();
            this.buildPreview();
        }

        $onChanges(onChangesObj: IOnChangesObject): void {
            this.updateTypes();
            this.buildPreview();
        }

        updateTypes() {
            this.showPoint = this.types.includes('point');
            this.showLinestring = this.types.includes('linestring');
            this.showPolygon = this.types.includes('polygon');
        }

        update() {
            this.buildPreview();
            this.onUpdate();
        }

        buildPreview() {
            this.preview = {
                point: {
                    background: this.config.color,
                    opacity: this.config.pointOpacity,
                    // outline: `${this.layer.style.pointBorderWidth}px solid ${this.layer.style.pointBorderColor}`,
                    borderColor: this.config.pointBorderColor,
                    borderStyle: 'solid',
                    borderWidth: this.config.pointBorderWidth + 'px'
                }
            };
        }
    }
}