import { AsyncPipe, NgIf, NgClass } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ToolbarModule } from 'primeng/toolbar';
import { PanelModule } from 'primeng/panel';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DragDropModule } from 'primeng/dragdrop';

import { Observable, map, tap } from 'rxjs';
import { ApiService } from 'src/app/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

interface UserProfileUpdateInput {
    username?: string;
    displayName?: string;
    image?: string;
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    standalone: true,
    imports: [
        ButtonModule,
        AvatarGroupModule,
        AvatarModule,
        ToolbarModule,
        MenubarModule,
        ToastModule,
        PanelModule,
        DialogModule,
        DragDropModule,
        InputTextModule,
        ReactiveFormsModule,
        AsyncPipe,
        NgIf,
        NgClass
    ],
    providers: [MessageService],
    styles: [`
    :host { @apply flex w-full h-full flex items-center justify-center; }
    `]
})



export class ProfileComponent implements OnInit, OnDestroy {

    profileForm: FormGroup;
    imageUrl: string | ArrayBuffer | null = null;
    profileSubscription: Subscription;
    visible: boolean = false;
    imageSelected: boolean = false;
    originalImageUrl: string | ArrayBuffer | null = null;

    PROFILE_QUERY = `mutation updateUserInfo($input: UpdateProfileInputType!) {
            updateProfile(input: $input) {
                user {
                    username
                    displayName
                    imageUrl
                }
            }
    }`;

    profileQuery = `query AAGetProfile {
        viewer {
            displayName
            username
            email
            role
            imageUrl 
        }
    }`;

    profile$ = this.apiService.graphql<{ viewer: { displayName: string; username: string; email: string; role: string; imageUrl: string } }>(this.profileQuery).pipe(
        map(res => res.viewer)
    );

    constructor(
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private messageService: MessageService
    ) { }


    updateUserInfo(input: Partial<UserProfileUpdateInput>): Observable<{ user: { username: string, displayName: string } }> {
        return this.apiService.graphql<{ updateProfile: { user: { username: string, displayName: string } } }>(
            this.PROFILE_QUERY,
            { input }
        ).pipe(
            map(response => {
                // console.log('Response from updateProfile mutation:', response.updateProfile.user);
                return { user: response.updateProfile.user };
            }),
            tap({
                next: (user) => {
                    console.log('Update successful for user:', user.user.username ?? 'No Username Update', user.user.displayName ?? 'No DisplayName Update');
                },
                error: (error) => {
                    console.error('Error updating user info:', error);
                }
            })
        );
    };

    showSuccess() {
        this.messageService.add({ severity: 'success', detail: 'Profile Updated' });
    };

    showError() {
        this.messageService.add({ severity: 'error', detail: 'Something went wrong on our end. Please try again later.' });
    }

    showDialog() {
        this.resetImage();
        this.visible = true;
    }

    closeDialog() {
        // this.imageUrl = this.originalImageUrl; 
        this.visible = false;

    }

    selectImage() {
        this.visible = false;
    }

    resetImage() {
        this.imageUrl = this.originalImageUrl;
        this.imageSelected = false;
        this.profileForm.patchValue({
            image: this.originalImageUrl
        });
    }

    onDrop(event: any) {
        event.preventDefault();

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0];
            this.onFileSelected({ target: { files: [file] } });
        }
    }

    ngOnInit() {
        this.profileForm = this.formBuilder.group({
            displayName: ['', Validators.required],
            username: ['', Validators.required],
            image: [null]
        });

        this.profileSubscription = this.profile$.subscribe(profile => {
            this.profileForm.patchValue({
                displayName: profile.displayName,
                username: profile.username
            });
            this.imageUrl = profile.imageUrl || null;
            this.originalImageUrl = this.imageUrl;
        });
    };

    onSubmit() {
        if (!this.profileForm.valid) {
            return;
        }

        const file: File = this.profileForm.get('image')?.value;
        const variables = {
            input: {
                username: this.profileForm.value.username,
                displayName: this.profileForm.value.displayName,
            }
        };

        let files: { [key: string]: File } = {};
        if (file) {
            files = { 'imageUrl': file };
        }

        this.apiService.graphql<any>(
            this.PROFILE_QUERY,
            variables,
            files
        ).subscribe({
            next: () => {
                this.profileForm.markAsPristine();
                this.profileForm.markAsUntouched();
                this.originalImageUrl = this.imageUrl;
                // console.log('User info updated successfully-1', response); 
                this.showSuccess()
            },
            error: () => {
                // console.error('Error updating profile:', error);
                this.showError();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
        }
    }

    onFileSelected(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            this.profileForm.patchValue({
                image: file
            });

            this.profileForm.get('image').markAsTouched();
            const reader = new FileReader();

            reader.onload = (e: any) => {
                this.imageUrl = e.target.result;
                this.imageSelected = true;
            };
            reader.readAsDataURL(file);
        }
    }
}





