import { getProject, getProjectError, getProjects, getProjectLoading, getAuthRoleForProject, getOrganisationRole, getAuthIsAdmin } from '../store/selectors';

export default class ProjectController {

    static $inject = ['$scope', '$ngRedux'];
    constructor($scope, $ngRedux) {

        $scope.$on('$destroy', $ngRedux.connect((state) => {
            const error = getProjectError(state);
            const loading = getProjectLoading(state);

            const project = (error || loading) ? null : getProject(state);
            // const projects = (error || loading) ? [] : getProjects(state).filter(p => p.id !== project.id);
            const projects = getProjects(state);
            const projectRole = (error || loading || !project) ? '' : getAuthRoleForProject(project.id)(state);
            const organisationRole = (error || loading || !project) ? '' : getOrganisationRole(state);
            const isAdmin = getAuthIsAdmin(state);

            return {
                project,
                projects,
                error,
                projectRole,
                organisationRole,
                loading,
                isAdmin
            };
        }, null)(this));
    }

}
ProjectController.$inject = ['$scope', '$ngRedux'];
