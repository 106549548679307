<div>
    @if (newCollection) {
        <div class="w-full pb-5">
            <label class="block font-normal mb-2" for="name">Name</label>
            <input pInputText [autofocus]="true" id="name" [(ngModel)]="name" placeholder="Name" class="w-full" />
        </div>
    }
    @if (importType !== 'empty') {
        <div class="w-full pb-5 border-b border-grey-30">
            @if (file()) {
            <div class="h-11 flex items-center justify-between">
                <p class="text-sm font-semibold truncate pr-5">{{ file().name }}</p>
                <i class="fa-solid fa-circle-xmark text-danger cursor-pointer" (click)="clearFile()"></i>
            </div>
            }
            @else {
            <p-button label="Choose {{ importTypeMap[importType].choose }} file to upload" [outlined]="true" size="large"
                (click)="chooseFile($event)" styleClass="w-full"></p-button>
            <input #fileInput type="file" [accept]="importTypeMap[importType].accept" required (change)="readFile($event)"
                class="sr-only opacity-0" />
            }
        </div>
        <div class="py-5 space-y-4">
            <!-- Hide for now needs content -->
            <!-- @switch (importType) {
            @case ('csv') {
            <p><strong class="uppercase">Please note: </strong>The CSV file must have a <strong>value</strong> column
                containing the item names and a <strong>key</strong> column, containing an permanent label for each item.
                Each attribute should have its own column.</p>
            <br>
            <p>You can <a href="" download>download a CSV template</a> to help you get started.</p>
            }
            @case ('geojson') {
                    <p><strong class="uppercase">Please note: </strong>todo</p>
                }
                @case ('shapefile') {
                    <p><strong class="uppercase">Please note: </strong>todo</p>
                }
            } -->
            <!-- CSV -->
            @if (importType === 'csv' && fileOk()) {
                <div>
                    <label class="block font-normal mb-2" for="keyProperty">Key Property Field (must be unique)</label>
                    <p-dropdown id="keyProperty" [options]="csvColumns()" [(ngModel)]="keyProperty"
                        placeholder="Select key" appendTo="body"></p-dropdown>
                </div>
                <div>
                    <label class="block font-normal mb-2" for="valueProperty">Value Property Field</label>
                    <p-dropdown id="valueProperty" [options]="csvColumns()" [(ngModel)]="valueProperty"
                        placeholder="Select property" appendTo="body"></p-dropdown>
                </div>
                <div>
                    <label class="block font-normal mb-2" for="csvGeometryType">Select Geometry Type</label>
                    <p-dropdown id="csvGeometryType" [options]="csvGeometryTypeOptions" optionLabel="label" optionValue="key" [(ngModel)]="csvGeometryType" placeholder="Select geometry type" appendTo="body" (onChange)="clearCSVColumns()"></p-dropdown>
                </div>
                @if (csvGeometryType() === 'latlng') {
                <div>
                    <label class="block font-normal mb-2" for="csvGeometryLatColumn">Select Latitude Column</label>
                    <p-dropdown id="csvGeometryLatColumn" [options]="csvColumns()" [(ngModel)]="csvGeometryLatColumn" appendTo="body"></p-dropdown>
                </div>
                <div>
                    <label class="block font-normal mb-2" for="csvGeometryLngColumn">Select Longitude Column</label>
                    <p-dropdown id="csvGeometryLngColumn" [options]="csvColumns()" [(ngModel)]="csvGeometryLngColumn" appendTo="body"></p-dropdown>
                </div>
                }
                @if (csvGeometryType() === 'wkt') {
                <div>
                    <label class="block font-normal mb-2" for="csvGeometryWKTColumn">Select Geometry Column</label>
                    <p-dropdown id="csvGeometryWKTColumn" [options]="csvColumns()" [(ngModel)]="csvGeometryWKTColumn" appendTo="body"></p-dropdown>
                </div>
                }
                @if (csvGeometryType() === 'GeoJSON') {
                <div>
                    <label class="block font-normal mb-2" for="csvGeometryGeoJSONColumn">Select Geometry Column</label>
                    <p-dropdown id="csvGeometryGeoJSONColumn" [options]="csvColumns()" [(ngModel)]="csvGeometryGeoJSONColumn" appendTo="body"></p-dropdown>
                </div>
                }
                @if (csvGeometryType() !== 'none') {
                <div>
                    <label class="block font-normal mb-2" for="epsgCode">Select EPSG Coordinate Reference System</label>
                    <p-dropdown id="epsgCode" [options]="epsgCodeOptions" optionLabel="label" optionValue="key" [(ngModel)]="epsgCode" appendTo="body"></p-dropdown>
                </div>
                } 
            }
            <!-- Geojson -->
            @if (importType === 'geojson' && fileOk() && geoJsonPaths().length > 0) {
            <div>
                <label class="block mb-2 font-normal" for="keyProperty">Key Property Field (must be unique)</label>
                <p-dropdown id="keyProperty" [options]="geoJsonPaths()" [(ngModel)]="keyProperty"
                    placeholder="Select key" appendTo="body"></p-dropdown>
            </div>
            <div>
                <label class="block mb-2 font-normal" for="valueProperty">Value Property Field</label>
                <p-dropdown id="valueProperty" [options]="geoJsonPaths()" [(ngModel)]="valueProperty"
                    placeholder="Select property" appendTo="body"></p-dropdown>
            </div>
            }
            <!-- Shapefile -->
            @if (importType === 'shapefile' && shapefileOptions()) {
            <div>
                <p class="mb-2">More than one shapefile found. Please choose one to import.</p>
                <p-dropdown [options]="shapefileOptions()" [(ngModel)]="shapefileToUse" placeholder="Select shapefile"
                    appendTo="body" (onChange)="updateShapefileInfo()"></p-dropdown>
            </div>
            }
    
            @if (importType === 'shapefile' && fileOk() && shapefileFields().length > 0) {
            <div>
                <label class="block mb-2 font-normal" for="keyProperty">Key Property Field (must be unique)</label>
                <p-dropdown id="keyProperty" [options]="shapefileFields()" [(ngModel)]="keyProperty"
                    placeholder="Select key" appendTo="body"></p-dropdown>
            </div>
            <div>
                <label class="block mb-2 font-normal" for="valueProperty">Value Property Field</label>
                <p-dropdown id="valueProperty" [options]="shapefileFields()" [(ngModel)]="valueProperty"
                    placeholder="Select property" appendTo="body"></p-dropdown>
            </div>
            }
            
            @if (processing()) {
                <div class="flex items-center justify-center">
                    <p-progressSpinner styleClass="w-5 h-5" />
                </div>
            }
            
            @if (error()) {
                <p class="text-sm text-danger">{{ error() }}</p>
            }
        </div>
    }
    <div class="flex items-center justify-end">
        <p-button label="{{ newCollection ? 'Create' : 'Import' }} {{ importTypeMap[importType].label }}" [disabled]="!canSubmit() || !!error()" [loading]="loading()" size="large" (click)="submit()"></p-button>
    </div>
</div>