import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
    selector: 'app-signature-pad',
    templateUrl: 'signature-pad.component.html',
    standalone: true
})

export class SignaturePadComponent implements OnInit, AfterViewInit, OnDestroy {

    @Output() signature = new EventEmitter<string>();

    @ViewChild('canvas', { static: false }) canvas: ElementRef;

    private signaturePad: SignaturePad;
    public touched: boolean = false;

    constructor() {}

    ngOnInit() {
    }
    
    ngAfterViewInit(): void {
        this.signaturePad = new SignaturePad(this.canvas.nativeElement as HTMLCanvasElement, {});
        this.resizeCanvas();

        this.signaturePad.addEventListener('endStroke', this.handleEndStroke);
        window.addEventListener('resize', this.resizeCanvas);
    }

    ngOnDestroy(): void {
        window.removeEventListener('resize', this.resizeCanvas);
        this.signaturePad.removeEventListener('endStroke', this.handleEndStroke);
    }

    private resizeCanvas() {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        this.canvas.nativeElement.width = this.canvas.nativeElement.offsetWidth * ratio;
        this.canvas.nativeElement.height = this.canvas.nativeElement.offsetHeight * ratio;
        this.canvas.nativeElement.getContext("2d").scale(ratio, ratio);
    }

    private handleEndStroke = async () => {
        const dataUrl = this.signaturePad.toDataURL();
        this.signature.emit(dataUrl);

        this.touched = true;
    };

    public clear() {
        this.signaturePad.clear();
        this.touched = false;
        this.signature.emit(null);
    }
}