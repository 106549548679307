<div>
    @if (images().length > 0) {
        <div class="w-full">
            <div class="w-full h-52 relative">
                <img [src]="currentImage().url" alt="" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-52">
            </div>
            <div class="w-full flex items-center justify-center gap-2 p-[14px]">
                @for (dot of images(); track dot.id; let index = $index) {
                    <button (click)="currentImageIndex.set(index)" class="h-2 w-2 rounded-full" [ngClass]="currentImageIndex() === index ? 'bg-primary' : 'bg-grey-30'"></button>
                }
            </div>
        </div>
    }
    <div class="pt-5 px-4 pb-8 text-black">
        <h3 class="text-4xl font-semibold mb-5 break-words">{{ preview().title }}</h3>
        @if (audio().length > 0) {
            <div class="my-5">
                @for (item of audio(); track item.id) {
                    <audio [controls]="true" class="w-full">
                        <source [src]="item.url" [type]="item.type">
                        Your browser does not support this audio tag
                    </audio>
                }
            </div>
        }
        @if (data().length > 0) {
            @for (item of data(); track item) {
                <h4 class="text-base font-semibold mb-3">{{ item.label }}</h4>
                <div class="text-base mb-5" [innerHTML]="item.value"></div>
            }
        }
    </div>
</div>