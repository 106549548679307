import { getAuthIsLoggedIn, getAuthUser } from '../../store/selectors';

export default class IntermediaryPageAuthenticateController {
    static $inject = ['$state', '$stateParams', '$ngRedux', '$q', 'AuthActions', 'IntermediaryPageService'];
    constructor($state, $stateParams, $ngRedux, $q, AuthActions, IntermediaryPageService) {
        this.$q = $q
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.token = $stateParams.token;
        this.$ngRedux = $ngRedux;
        this.AuthActions = AuthActions;
        this.IntermediaryPageService = IntermediaryPageService;

        const state = $ngRedux.getState();

        this.isLoggedIn = getAuthIsLoggedIn(state);
        this.currentUser = this.isLoggedIn ? getAuthUser(state) : null;
    }

    delay(ms) {
        const deferred = this.$q.defer();
        setTimeout(deferred.resolve, ms);
        return deferred.promise;
    };

    signIn() {
        return this.$ngRedux.dispatch(this.AuthActions.authenticateWithToken(this.token)).then(() => {
            return this.$state.go('home');
        })
    }

    $onInit() {
        if (!this.token) {
            return this.$state.go('login');
        }
        try {
            this.newUser = this.IntermediaryPageService.parseUserFromToken(this.token);
        } catch (e) {
            this.$state.go('login');
        }
        if (!this.isLoggedIn) {
            return this.delay(750).then(() => this.signIn())
        }
        // already logged in as user provided in token
        // newUser id is `userId` since it is extracted from token
        if (this.newUser.userId === this.currentUser.id) {
            this.$state.go('home');
        }
    }

    switchAccount() {
        this.$ngRedux.dispatch(this.AuthActions.logout());
        return this.signIn();
    }

    continueAsLoggedIn() {
        this.$state.go('home');
    }
}
