import angular from 'angular';

// Constants
import RECORD_COLUMNS from './record-columns.constant';

// Factories
import Record from './record';
// Services
import RecordsService from './records.service';


export default angular.module('app.records', [])
    .factory('Record', Record)
    .service('RecordsService', RecordsService)
    .constant('RECORD_COLUMNS', RECORD_COLUMNS)
    ;
