import { DatePipe, NgOptimizedImage } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output, SimpleChanges, computed, input, signal } from "@angular/core";
import { SvgIconComponent } from "angular-svg-icon";
import { MenuItem, MenuItemCommandEvent, TooltipOptions } from "primeng/api";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { MenuModule } from "primeng/menu";
import { TwicPicPipe } from '../../shared/pipes/twicpic.pipe';
import { HomeProject } from "./home.models";
import { TooltipModule } from "primeng/tooltip";
import { sep } from "path";

@Component({
    selector: 'app-home-project',
    standalone: true,
    templateUrl: './home-project.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AvatarModule,
        ButtonModule,
        NgOptimizedImage,
        TwicPicPipe,
        SvgIconComponent,
        DatePipe,
        MenuModule,
        TooltipModule
    ],
    styleUrls: ['./home.styles.scss'],
    styles: [`
        :host {
            @apply flex items-center p-2 bg-white;
            height: 80px;
        }

        .home-project-container{
            @apply hover:bg-[#F2F8FE];
        }

        :host(.selected) .home-project-container{
                @apply bg-[#DFEBFF] hover:bg-[#DFEBFF];
        }

        p-avatar .p-avatar-image{
            @apply w-full h-full;
        }
    `]
})
export class HomeProjectComponent implements OnChanges {
    // @Input({ required: true }) project: HomeProject;

    project = input.required<HomeProject>();
    isOrgAdmin = input.required<boolean>();

    @Output() open = new EventEmitter<void>();
    @Output() select = new EventEmitter<boolean>();
    @Output() move = new EventEmitter<void>();
    @Output() favourite = new EventEmitter<void>();
    @Output() clone = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() settings = new EventEmitter<void>();
    @Output() members = new EventEmitter<void>();

    @Input() @HostBinding('class.selected') selected = false;
    @Input() selectionCount: number;

    @HostBinding('attr.draggable') draggable = true;

    @HostListener('dragstart', ['$event']) onDragStart(event: DragEvent) {

        if (event.dataTransfer) {
            event.dataTransfer.setData('text/plain', JSON.stringify({
                type: 'project',
                ...this.project()
            }));
        }
        this.dragging.set(true);
    }

    @HostListener('dragend', ['$event']) onDragEnd(event: DragEvent) {
        this.dragging.set(false);
    }

    @HostListener('click') onClick() {
        this.open.emit();
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.hovering.set(true);
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.hovering.set(false);
    }

    hovering = signal<boolean>(false);
    dragging = signal<boolean>(false);

    isProjectAdmin = computed(() => this.project().role === 'admin');
    menuItems = computed<MenuItem[]>(() => {
        const isProjectAdmin = this.isProjectAdmin();
        const isOrgAdmin = this.isOrgAdmin();

        return [{
            label: 'Open',
            command: (e) => this.handleMenuEvent(e, this.open)
        }, {
            label: 'Favourite',
            command: (e) => this.handleMenuEvent(e, this.favourite)
        }, {
            label: 'Move',
            command: (e) => this.handleMenuEvent(e, this.move),
            visible: isOrgAdmin
        }, {
            label: 'Clone',
            command: (e) => this.handleMenuEvent(e, this.clone),
            visible: isOrgAdmin
        }, {
            label: 'Delete',
            command: (e) => this.handleMenuEvent(e, this.delete),
            visible: isOrgAdmin
        }, {
            separator: true,
            visible: isProjectAdmin
        }, {
            label: 'Settings',
            command: () => this.settings.emit(),
            visible: isProjectAdmin
        }, {
            label: 'Members',
            command: () => this.members.emit(),
            visible: isProjectAdmin
        }];

    })

    tooltipOptions: TooltipOptions = {
        showDelay: 500,
        tooltipPosition: 'bottom'
    };

    handleEvent(event: Event, emitter: EventEmitter<void>) {
        event.preventDefault();
        event.stopPropagation();
        emitter.emit();
    }

    handleMenuEvent(event: MenuItemCommandEvent, emitter: EventEmitter<void>) {
        event.originalEvent?.preventDefault();
        event.originalEvent?.stopPropagation();
        emitter.emit();
    }

    openMenu(event: Event, menu: any) {
        event.preventDefault();
        event.stopPropagation();
        menu.toggle(event);
    }

    selectProject(event: Event, multi: boolean = false) {
        event.preventDefault();
        event.stopPropagation();
        this.select.emit(multi);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('selectionCount' in changes) {
            this.draggable = changes['selectionCount'].currentValue <= 1;
        }
    }
}
