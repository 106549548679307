import { jobFieldsFragment } from "src/app/core/services/jobs.service";
import { urlToImageProxy } from '../helpers/utils';
export default class ProjectsService {
    static $inject = ['$http', 'CoreoAPI', 'CoreoGraphQLQuery', 'jobsService'];
    constructor($http, CoreoAPI, CoreoGraphQLQuery, jobsService) {
        this.$http = $http;
        this.CoreoAPI = CoreoAPI;
        this.CoreoGraphQLQuery = CoreoGraphQLQuery;
        this.jobsService = jobsService;
    }


    // Serialize object to gql
    gqlStringify(obj) {
        // JSON stringify, and strip quotes on field names
        return JSON.stringify(obj).replace(/\"([^(\")"]+)\":/g, "$1:");
    }

    query() {
        return new this.CoreoGraphQLQuery('{data: viewer{ projects(roles: [ADMIN,MODERATOR]){id, name, description, imageUrl, slug, subdomain}}}');
    }

    getProjects() {
        return this.query().run().then(q => {
            return q.data.projects.map(project => ({
                ...project,
                imageUrl: project.imageUrl && urlToImageProxy(project.imageUrl, { w: 512 }) || null
            }))
        });
    }

    getById(id) {
        return this.$http.get('/projects/' + id).then(function (res) {
            return res.data;
        });
    }

    create(data) {
        var query = 'mutation{result: createProject(input: ' + this.CoreoAPI.gqlStringify(data) + '){id,name,slug,states{name}}}';
        return this.CoreoAPI.mutation(query);
    }

    update(id, data) {
        return this.$http.put('/projects/' + id, data).then(function (res) {
            return res.data;
        });
    }

    updateProject(id, project) {
        var data = new FormData();
        Object.keys(project).map(function (k) {
            data.append(k, project[k]);
        });

        return this.$http.put('/projects/' + id, data, {
            headers: {
                'Content-Type': undefined
            }
        }).then(function (res) {
            return res.data;
        });
    }

    getProjectMemberships(id) {
        var query = '{data: project(id: ' + id + '){memberships{admin,data,marketingConsent,moderator,read,role,status,write,createdAt,user{displayName,username,id,email}}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data.memberships;
            });
    }

    createProjectUser(projectId, userId, data) {
        var query = 'mutation{data: createProjectMembership(input: {projectId: ' + projectId + ', userId:' + userId + (data ? ', ' + this.CoreoAPI.gqlStringify(data) : '') + '}){admin,data,moderator,read,role,status,write,user{displayName,id,email}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data.createProjectMembership;
            });
    }

    deleteProjectUser(projectId, userId) {
        var query = 'mutation{data: deleteProjectMembership(input: {projectId: ' + projectId + ', userId:' + userId + '})}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data.deleteProjectMembership;
            });
    }

    updateProjectUserAccess(projectId, userId, field, value) {
        var query = 'mutation{data: updateProjectMembership(input: {projectId: ' + projectId + ', userId:' + userId + ', ' + field + ':' + value.toLowerCase() + '}){admin,data,moderator,read,role,status,write,user{displayName,id,email}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data.createProjectMembership;
            });

    }

    updateProjectUserStatus(id, projectUserId, status) {
        return this.$http.put('/projects/' + id + '/memberships/' + projectUserId, {
            status: status
        }).then(function (res) {
            return res.data;
        });
    }

    // Gets all collections of a project, without items
    getCollections(id) {
        var query = '{data: project(id: ' + id + '){collections{id,name,itemCount,geometric,updatedAt,type,status, sortMode, sortAttributeId, sortReverse, attributes{filterable,collectionId,label,visible,required,order,id,type,path,meta}}}}';
        return this.CoreoAPI.query(query).then(function (data) {
            return data.data.collections;
        });
    }

    getCollectionsWithItems(id) {
        var query = '{data: project(id: ' + id + '){collections{id,name,type,geometric,attributes{filterable,collectionId,label,visible,required,order,id,type,path,meta},items{id,key,value,sort,mediaItems{id,name,url,size,type,caption,createdAt}}}}}';
        return this.CoreoAPI.query(query).then(function (data) {
            return data.data.collections;
        });
    }

    getCollectionNames(id) {
        var query = '{project(where:{id:' + id + '}){collections{id, name, type}}}';

        return this.CoreoAPI.query(query).then(function (data) {
            return data.project.collections;
        });
    }

    sendTest(id, body) {
        var headers = body instanceof FormData ? {
            'Content-Type': undefined
        } : {};
        return this.$http.post('/records', body, {
            headers: headers
        }).then(function (res) {
            return res.data;
        });
    }

    getSurveysWithCollections(id) {
        var query = '{project(id: ' + id + '){surveys{id,name,slug,anonymous,verification, allowMemberUpdate, titleAttributeId, secondaryTitleAttributeId, visible, private, primary,attributes{filterable,collectionId,label,visible,required,order,id,type,path,meta,collection{items{key,value}}},associations{id,legacy,name,subSurveyId}}}}';
        return this.CoreoAPI.query(query).then(data => data.project.surveys);
    }

    getSurveysWithCollectionAttributes(id) {
        var query = '{project(id: ' + id + '){surveys{id,name,slug,anonymous, allowMemberUpdate,attributes{filterable,collectionId,label,visible,required,order,id,type,path,meta,collection{attributes{label}}}, titleAttributeId, secondaryTitleAttributeId, visible, private, associations{id,legacy,name,subSurveyId}}}}';
        return this.CoreoAPI.query(query).then(data => data.project.surveys);
    }

    updateForm(surveyId, data) {
        data.id = surveyId;
        var query = 'mutation{data: updateSurvey(input:' + this.CoreoAPI.gqlStringify(data) + '){id,name,slug,anonymous,verification,attributes{filterable,collectionId,label,visible,required,order,id,type,path,meta,collection{items{key,value}}}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    deleteForm(surveyId) {
        var query = 'mutation{data: deleteSurvey(input:{id:' + surveyId + '})}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    addAttribute(projectId, data) {
        data.projectId = projectId;
        // data.surveyId = surveyId;
        var query = 'mutation{data: createAttribute(input:' + this.CoreoAPI.gqlStringify(data) + '){ id, label, path, required, order, filterable, meta, projectId, parentCollectionId, surveyId}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    removeAttribute(attributeId) {
        var query = 'mutation{data: deleteAttribute(input:{id:' + attributeId + '})}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    updateAttribute(attributeId, attribute) {
        attribute.id = attributeId;
        var query = 'mutation{data: updateAttribute(input:' + this.CoreoAPI.gqlStringify(attribute) + '){ id, label, path, required, order, filterable, meta, projectId, surveyId}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    importRecordsFromFile(file) {
        var fd = new FormData();
        fd.append('file', file);
        return $http.post('/records/import', fd, {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        }).then(function (response) {
            return response.data;
        });
    }

    createSubSurvey(surveyId, subsurvey) {
        var data = {};
        data.subSurveyId = subsurvey.surveyId;
        data.surveyId = surveyId;

        var query = 'mutation{data: createAssociation(input:' + this.CoreoAPI.gqlStringify(data) + '){ surveyId, subSurveyId }}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    createAssociation(sourceId, targetId, name) {
        var data = {
            surveyId: sourceId,
            subSurveyId: targetId,
            name
        };
        var query = 'mutation{data: createAssociation(input:' + this.CoreoAPI.gqlStringify(data) + '){ id, name, surveyId, subSurveyId }}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    updateAssociation(association) {
        var input = {
            id: association.id,
            name: association.name
        };
        var query = `mutation{result: updateAssociation(input: ${this.CoreoAPI.gqlStringify(input)}){id,name,surveyId,subSurveyId}}`;
        return this.CoreoAPI.mutation(query);
    }

    deleteSubSurvey(surveyId, childSurveyId) {
        var query = 'mutation{data: deleteAssociation(input:{surveyId:' + surveyId + ', subSurveyId:' + childSurveyId + '})}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    createVisualisation(projectId, surveyId, visualisation) {
        visualisation.surveyId = surveyId;
        visualisation.childSurveyId = visualisation.childSurvey;
        delete visualisation.childSurvey;
        var query = 'mutation{data: createVisualisation(input:' + this.CoreoAPI.gqlStringify(visualisation) + '){id,type,config,childSurvey{id,name,slug,attributes{label,order,id,type,path,meta}}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    updateVisualisation(projectId, surveyId, visualisationId, visualisation) {
        visualisation.id = visualisationId;
        delete visualisation.childSurvey;
        var query = 'mutation{data: updateVisualisation(input:' + this.CoreoAPI.gqlStringify(visualisation) + '){id,type,config,childSurvey{id,name,slug,attributes{label,order,id,type,path,meta}}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    deleteVisualisation(projectId, surveyId, visualisationId) {
        var query = 'mutation{data: deleteVisualisation(input: {id:' + visualisationId + '})}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    mediaExport(projectId, where) {
        const query = `mutation CoreoAAMediaExport($input: RecordsMediaExportInput!){
            createRecordsMediaExport(input: $input){
                ...jobFields
            }
        }
        ${jobFieldsFragment}
        `;

        const input = {
            projectIds: [projectId],
            where
        };
        return this.CoreoAPI.gql(query, { input }).then(result => {
            this.jobsService.addJob(result.createRecordsMediaExport);
        })
    }

    csvExport(projectId, where, showDeleted = false, exportMedia = false) {
        const query = `mutation CoreoAACSVExport($input: RecordsExportInput!){
            createRecordsExport(input: $input){
                ...jobFields
            }
        }
        ${jobFieldsFragment}
        `;

        const input = {
            projectIds: [projectId],
            where,
            showDeleted,
            exportMedia,
        };
        return this.CoreoAPI.gql(query, { input }).then(result => {
            this.jobsService.addJob(result.createRecordsExport);
        })
    }

    csvExportLegacy(projectId, where, showDeleted = false) {
        const query = `mutation CoreoAACSVExport($input: RecordsExportLegacyInput!){
            createRecordsExportLegacy(input: $input){
                ...jobFields
            }
        }
        ${jobFieldsFragment}
        `;

        const input = {
            projectIds: [projectId],
            where,
            showDeleted
        };
        return this.CoreoAPI.gql(query, { input }).then(result => {
            this.jobsService.addJob(result.createRecordsExportLegacy);
        })
    }

    recordLogsExport(projectId, surveys, operations) {
        const query = `mutation createRecordLogsExport($input: RecordsLogExportInput!){
            createRecordLogsExport(input: $input){
                ...jobFields
            }
        }
        ${jobFieldsFragment}`;
        const input = {
            projectId,
            surveys,
            operations
        };
        return this.CoreoAPI.gql(query, { input }).then(result => {
            this.jobsService.addJob(result.createRecordLogsExport);
        });
    }

    geoJSONExport(projectId, where, showDeleted = false, exportMedia = false) {
        const mutation = `mutation createGeoJSONExport($input: GeoJSONExportInput!){
            createGeoJSONExport(input: $input){
                ...jobFields
            }
        }
        ${jobFieldsFragment}`;

        const input = {
            projectIds: [projectId],
            where,
            showDeleted,
            exportMedia
        };

        return this.CoreoAPI.gql(mutation, { input }).then(result => {
            this.jobsService.addJob(result.createGeoJSONExport);
        });
    }

    geoJSONExportLegacy(projectId, where, showDeleted = false) {
        const mutation = `mutation createGeoJSONExport($input: GeoJSONExportLegacyInput!){
            createGeoJSONExportLegacy(input: $input){
                ...jobFields
            }
        }
        ${jobFieldsFragment}`;

        const input = {
          projectIds: [projectId],
            where,
            showDeleted
        };

        return this.CoreoAPI.gql(mutation, { input }).then(result => {
            this.jobsService.addJob(result.createGeoJSONExportLegacy);
        });
    }

    shapefileExport(projectId, where, showDeleted = false, exportMedia = false) {
        const mutation = `mutation createShapefileExport($input: ShapefileExportInput!){
            createShapefileExport(input: $input){
                ...jobFields
            }
         }
        ${jobFieldsFragment}`;

        const input = {
            projectIds: [projectId],
            where,
            showDeleted,
            exportMedia
        };

        return this.CoreoAPI.gql(mutation, { input }).then(result => {
            this.jobsService.addJob(result.createShapefileExport);
        });
    }

    shapefileExportLegacy(projectId, where, showDeleted = false) {
        const mutation = `mutation createShapefileExport($input: ShapefileExportLegacyInput!){
            createShapefileExportLegacy(input: $input){
                ...jobFields
            }
         }
        ${jobFieldsFragment}`;

        const input = {
            projectIds: [projectId],
            where,
            showDeleted
        };

        return this.CoreoAPI.gql(mutation, { input }).then(result => {
            this.jobsService.addJob(result.createShapefileExportLegacy);
        });
    }

    userCloneProject(projectId, name) {
        var query = 'mutation($projectId: Int!, $name: String){data: userCloneProject(input:{projectId: $projectId, name: $name}){id}}';
        return this.CoreoAPI.mutation(query, null, {
            variables: {
                projectId,
                name
            }
        }).then(function () {
            return true;
        }, function () {
            return false;
        });
    }

    cloneProjectTemplate(projectId, organisationId, name, description) {
        const data = {
            projectId,
            organisationId,
            name,
            description
        }
        var query = 'mutation{data: cloneProjectTemplate(input:' + this.CoreoAPI.gqlStringify(data) + '){id}}'
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data;
            });
    }

    mergeProject(projectId, targetId) {
        var query = 'mutation($projectId: Int!, $targetId: Int!){data: mergeProject(input:{projectId: $projectId, targetId: $targetId}){id}}';
        return this.CoreoAPI.mutation(query, null, {
            variables: {
                projectId,
                targetId
            }
        }).then(function () {
            return true;
        }, function () {
            return false;
        });
    }

    findAvailableSubdomain(subdomain) {
        // Find existing projects with subdomains matching the provided
        var query = '{data: projects(where: { subdomain: { like: "' + subdomain + '%"}}){subdomain}}';
        var graphQLQuery = new this.CoreoGraphQLQuery(query);
        return graphQLQuery.run().then(function (response) {
            // Strip project data down to a list of subdomains
            var existing = _.map(response.data, 'subdomain');
            var newSubdomain = subdomain;
            var count = 1;

            // Check if subdomain is in use; append incrementing counter if not, and repeat.
            while (true) {
                if (existing.indexOf(newSubdomain) === -1) {
                    break;
                }
                newSubdomain = subdomain + count;
                count++;
            }

            return newSubdomain;
        });
    }

    getProjectBySubdomain(subdomain) {
        var query = '{data: projects(where:{subdomain:"' + subdomain + '"}){id, name, shortName, subdomain, colors, css,font}}';
        var graphQLQuery = new this.CoreoGraphQLQuery(query);
        return graphQLQuery.run().then(function (response) {
            return (response.data.length > 0 ? response.data[0] : null);
        });
    }
}
ProjectsService.$inject = ['$http', 'CoreoAPI', 'CoreoGraphQLQuery', 'jobsService'];