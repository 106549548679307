export default class ForPermissionsDirective {
    static $inject = ['PermissionsService'];
    constructor(PermissionsService) {
        this.restrict = 'A';
        this.PermissionsService = PermissionsService;
    }
    link(_scope, element, attributes) {
        const permissions = attributes.forPermissions.split(',').map(p => p.trim());
        if (!this.PermissionsService.checkAccess(permissions)) {
            element.remove();
        }
    }
}
