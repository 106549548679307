import { Component, ElementRef, OnInit, computed, signal, viewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import { readGeoJSONFile } from 'src/app/shared/utilities/geojson';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { CoreoJob, jobFieldsFragment } from 'src/app/core/services/jobs.service';

export type CollectionImportType = 'empty' | 'csv' | 'geojson' | 'shapefile';
type CSVGeometryType = 'none' | 'wkt' | 'latlng' | 'GeoJSON';
type ESPGCode = 4326 | 27700 | 3857;

@Component({
    selector: 'app-import-collection-modal',
    templateUrl: 'import-collection-modal.component.html',
    imports: [
        ButtonModule,
        ProgressSpinnerModule,
        FormsModule,
        DropdownModule,
        InputTextModule
    ],
    providers: [
        MessageService
    ],
    standalone: true
})

export class ImportCollectionModalComponent implements OnInit {

    fileInput = viewChild<ElementRef>('fileInput');

    public newCollection: boolean = false;
    private projectId: number;
    private collectionId: number;
    public importType: CollectionImportType;

    public importTypeMap: { [type in CollectionImportType]: { label: string; choose: string; accept: string; } } = {
        'empty': { label: '', choose: '', accept: '' },
        'csv': { label: 'from CSV', choose: '.csv', accept: '.csv' },
        'geojson': { label: 'from geoJSON', choose: '.json, .geojson', accept: '.json, .geojson' },
        'shapefile': { label: 'from shapefile', choose: '.zip shapefile', accept: '.zip' }
    };

    loading = signal(false);
    processing = signal(false);

    name = signal<string | null>(null);

    file = signal<File | null>(null);
    fileOk = signal(false);
    error = signal<string | null>(null);

    keyProperty = signal<string | null>(null);
    valueProperty = signal<string | null>(null);

    csvColumns = signal([]);
    public csvGeometryTypeOptions: { key: CSVGeometryType; label: string }[] = [
        { key: 'none', label: 'Do not import geometry' },
        { key: 'latlng', label: 'Lat/Lng' },
        { key: 'wkt', label: 'Combined WKT Column' },
        { key: 'GeoJSON', label: 'GeoJSON Column' }
    ];
    csvGeometryType = signal<CSVGeometryType>('none');
    csvGeometryGeoJSONColumn = signal<string | null>(null);
    csvGeometryWKTColumn = signal<string | null>(null);
    csvGeometryLatColumn = signal<string | null>(null);
    csvGeometryLngColumn = signal<string | null>(null);
    public epsgCodeOptions: { key: ESPGCode; label: string }[] = [
        { key: 4326, label: 'EPSG:4326 - WGS 84 / Default CRS' },
        { key: 27700, label: 'EPSG:27700 - OSGB 1936 / British National Grid' },
        { key: 3857, label: 'EPSG:3857 - WGS 84 / Pseudo-Mercator' }
    ];
    epsgCode = signal<ESPGCode | null>(null);

    geoJsonProperties = signal([]);
    geoJsonPaths = signal([]);

    private shapefileInfo: any;
    shapefileOptions = signal<string[] | null>(null);
    shapefileToUse = signal<string | null>(null);
    shapefileFields = signal<string[] | null>(null);

    constructor(
        private dialogRef: DynamicDialogRef,
        private dialogConfig: DynamicDialogConfig,
        private apiService: ApiService,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        this.projectId = this.dialogConfig.data['projectId'];
        this.collectionId = this.dialogConfig.data['collectionId'];
        this.importType = this.dialogConfig.data['importType'];
        this.newCollection = this.dialogConfig.data['newCollection'];
    }

    public chooseFile($event: Event) {
        $event.preventDefault();
        this.fileInput().nativeElement.click();
    }

    public readFile($event: Event) {
        this.error.set(null);
        this.fileOk.set(false);
        this.processing.set(true);

        const files = ($event.target as HTMLInputElement).files;

        if (files.length < 1) {
            return;
        }

        this.file.set(files[0]);

        switch (this.importType) {
            case 'csv': {
                this.csvHandler();
                break;
            }
            case 'geojson': {
                this.geoJSONHandler();
                break;
            }
            case 'shapefile': {
                this.shapefileHandler();
                break;
            }
            default: {
                console.warn('Unknown template type');
                this.error.set('Unknown template type');
                this.processing.set(false);
            }
        }
    }

    public async csvHandler() {
        const result = await (async (file: File): Promise<string[]> => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    try {
                        const text: string = reader.result as string;
                        const firstLine = text.replace('\r', '').split('\n').shift();
                        this.fileOk.set(true);
                        return resolve(firstLine.split(',').map(s => s.trim()));
                    } catch (e) {
                        this.error.set('The file you have selected is either not a valid CSV or otherwise cannot be read.');
                        return reject(new Error('Unable to read columns'));
                    }
                };
                reader.readAsText(file, 'UTF-8');
            });
        })(this.file());

        this.processing.set(false);

        if (result.includes('key')) {
            this.keyProperty.set('key');
        }
        if (result.includes('value')) {
            this.valueProperty.set('value');
        }

        this.csvColumns.set(result);
    }

    public clearCSVColumns() {
        this.csvGeometryGeoJSONColumn.set(null);
        this.csvGeometryWKTColumn.set(null);
        this.csvGeometryLatColumn.set(null);
        this.csvGeometryLngColumn.set(null);
        this.epsgCode.set(null);
    }

    public geoJSONHandler() {
        readGeoJSONFile(this.file()).then((result) => {
            this.geoJsonProperties.set(result.geoJsonProperties);
            this.geoJsonPaths.set(result.geoJsonPaths);
            this.fileOk.set(true);

            if (result.geoJsonPaths.includes('key')) {
                this.keyProperty.set('key');
            }
            if (result.geoJsonPaths.includes('value')) {
                this.valueProperty.set('value');
            }

            this.processing.set(false);
        }, (err) => {
            console.warn(err);
            this.error.set('The file you have selected is either not a valid GeoJSON file or contains features without any properties, and cannot be used.');
            this.processing.set(false);
        });
    }

    public shapefileHandler() {
        const formData = new FormData();
        formData.append('file', this.file());

        this.apiService.post('/gis/shapefile-info', formData).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null)
            })
        ).subscribe(res => {
            // console.log(res);

            if (!!res) {
                const keys = Object.keys(res);
                if (keys.length > 0) {
                    this.shapefileInfo = res;
                    // console.log(keys);
                    if (keys.length === 1) {
                        this.shapefileToUse.set(keys[0]);
                        this.updateShapefileInfo();
                    } else {
                        this.shapefileOptions.set(keys);
                    }
                } else {
                    this.error.set('No shapefile found in provided zip.');
                }
            } else {
                this.error.set('There was an error checking the shapefile.');
            }
            this.processing.set(false);
        });
    }

    public updateShapefileInfo() {
        const fields = this.shapefileInfo[this.shapefileToUse()].fields.map((f: any) => f.name);
        this.shapefileFields.set(fields);

        if (fields.includes('key')) {
            this.keyProperty.set('key');
        }
        if (fields.includes('value')) {
            this.valueProperty.set('value');
        }

        this.fileOk.set(true);
    }

    public clearFile() {
        this.file.set(null);
        this.error.set(null);
        this.fileOk.set(false);
        this.keyProperty.set(null);
        this.valueProperty.set(null);
        this.csvColumns.set([]);
        this.csvGeometryType.set('none');
        this.csvGeometryGeoJSONColumn.set(null);
        this.csvGeometryWKTColumn.set(null);
        this.csvGeometryLatColumn.set(null);
        this.csvGeometryLngColumn.set(null);
        this.epsgCode.set(null);
        this.geoJsonProperties.set([]);
        this.geoJsonPaths.set(null);
        this.shapefileOptions.set(null);
        this.shapefileInfo = null;
        this.shapefileToUse.set(null);
        this.shapefileFields.set(null);
    }

    canSubmit = computed<boolean>(() => {
        return this.newCollection ? this.canCreate() : this.canImport();
    });

    canCreate = computed<boolean>(() => {
        return !!this.name() && this.name().length > 0 && this.canImport();
    });

    canImport = computed<boolean>(() => {
        const fileOk: boolean = !!this.file() && this.fileOk() && !!this.keyProperty() && !!this.valueProperty();

        switch (this.importType) {
            case 'empty': {
                return true;
            }
            case 'csv':
                {
                    let geoOk: boolean = false;
                    if (this.csvGeometryType() === 'wkt') {
                        geoOk = (!!this.csvGeometryWKTColumn() && !!this.epsgCode());
                    } else if (this.csvGeometryType() === 'latlng') {
                        geoOk = (!!this.csvGeometryLatColumn() && !!this.csvGeometryLngColumn() && !!this.epsgCode());
                    } else if (this.csvGeometryType() === 'GeoJSON') {
                        geoOk = (!!this.csvGeometryGeoJSONColumn() && !!this.epsgCode());
                    } else if (this.csvGeometryType() === 'none') {
                        geoOk = true;
                    }
                    return geoOk && fileOk;
                }
            case 'geojson':
                return fileOk;
            case 'shapefile':
                return fileOk && !!this.shapefileToUse();
            default:
                break;
        }
    });

    private getInputData() {
        let input = {};

        if (this.newCollection) {
            input['name'] = this.name();
        }

        if (this.importType !== 'empty') {
            input['keyProperty'] = this.keyProperty();
            input['valueProperty'] = this.valueProperty();

            if (this.importType === 'csv') {
                input['epsgCode'] = this.epsgCode();
                input['geometryWKTColumn'] = this.csvGeometryWKTColumn();
                input['geometryLatColumn'] = this.csvGeometryLatColumn();
                input['geometryLngColumn'] = this.csvGeometryLngColumn();
                input['geometryGeoJSONColumn'] = this.csvGeometryGeoJSONColumn();
            }

            if (this.importType === 'shapefile') {
                input['shapefileName'] = this.shapefileToUse();
            }
        }

        return input;
    }

    private getFiles() {
        if (this.importType !== 'empty') {
            let files = {};
            files[this.importType] = this.file();
            return files;
        } else {
            return null;
        }
    }

    public submit() {
        if (!this.canSubmit()) {
            return;
        }

        this.loading.set(true);

        if (this.newCollection) {
            if (this.importType === 'empty') {
                this.createEmptyCollection();
            } else {
                this.createCollectionFromFile();
            }
        } else {
            this.importCollectionItems();
        }
    }

    private importCollectionItems() {
        const query = `mutation importCollectionItems($input: CollectionImportItemsInput!){
            importCollectionItems(input: $input){
                ...jobFields
            }
        }
        ${jobFieldsFragment}
        `;

        const files = this.getFiles();
        const data = this.getInputData();

        const input = {
            id: this.collectionId,
            ...data
        };

        this.apiService.graphql<{ importCollectionItems: CoreoJob }>(query, { input }, files).pipe(
            catchError((e) => {
                console.error(e);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not import items' });
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.dialogRef.close(res.importCollectionItems);
            }
            this.loading.set(false);
        });
    }

    private createCollectionFromFile() {
        const query = `mutation CreateProjectCollectionFromFile($input: CollectionCreateFromFileInput!) {
            createCollectionFromFile(input: $input) { 
                ...jobFields
            }
        } ${jobFieldsFragment}`;

        const files = this.getFiles();
        const data = this.getInputData();

        const input = {
            projectId: this.projectId,
            ...data
        };

        this.apiService.graphql<{ createCollectionFromFile: CoreoJob }>(query, { input }, files).pipe(
            catchError((e) => {
                console.error(e);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not create collection' });
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.dialogRef.close(res.createCollectionFromFile);
            }
            this.loading.set(false);
        });
    }

    private createEmptyCollection() {
        const query = `mutation CreateProjectCollection($input: CollectionCreateInput!) {
            createCollection(input: $input) { id }
        }`;
        const input = {
            projectId: this.projectId,
            name: this.name()
        };
        this.apiService.graphql<{ createCollection: { id: number } }>(query, { input }).pipe(
            catchError((e) => {
                console.error(e);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not create collection' });
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.dialogRef.close(res.createCollection);
            }
            this.loading.set(false);
        });
    }
}