import { v4 as uuid } from 'uuid';
import { getProject, getProjectForms, getRecordsFilterAssociatesQuery, getRecordsFilterQuery, getRecordsLayers, getRecordsOrder, getRecordsPaginationPage, getRecordsPaginationSize, getRecordsShowDeleted, getProjectMapLayersState } from '../selectors';

function recordsActions(CoreoAPI, localStorageService) {

    /**
     * size
     * page
     * order
     * filter
     * showDeleted
     */
    const load = (state) => {
        const size = getRecordsPaginationSize(state);
        const page = getRecordsPaginationPage(state);
        const order = getRecordsOrder(state);
        const showDeleted = getRecordsShowDeleted(state);
        const offset = (page - 1) * size;
        const where = getRecordsFilterQuery(state);
        const associatesWhere = getRecordsFilterAssociatesQuery(state);

        const query = `query getRecords($order: String!, $showDeleted: Boolean, $offset: Int!, $limit: Int!, $where: SequelizeJSON, $associatesWhere: SequelizeJSON){
            data: records(order: $order, showDeleted: $showDeleted, offset: $offset, limit: $limit, where: $where){
                id,
                userId,
                createdAt,
                deletedAt,
                state,
                verificationState,
                surveyId,
                data,
                boundingBox{
                    type
                    coordinates
                }
                associates(where: $associatesWhere){
                    association{
                        id
                    }
                    record{
                        id
                        title
                    }
                }
                user{
                    displayName
                },
                attachments{
                    id
                    attributeId
                    size
                    mimeType
                    url
                }
            }
            total: recordsAggregate(function: "COUNT", showDeleted: $showDeleted, where: $where)
        }`
        return CoreoAPI.query(query, {
            variables: {
                showDeleted,
                offset,
                order,
                limit: size,
                where,
                associatesWhere
            }
        }).then((result) => {
            return {
                data: result.data,
                total: result.total,
                lastPage: Math.ceil(result.total / size)
            };
        });
    };

    const updateView = (view) => ({
        type: 'RECORDS_UPDATE_VIEW',
        view
    });

    const toggleFilter = () => ({
        type: 'RECORDS_TOGGLE_FILTER'
    });

    const updateShowDeleted = (showDeleted) => ({
        type: 'RECORDS_UPDATE_SHOW_DELETED',
        showDeleted
    })

    const updateFilter = (update) => (dispatch) => {

        dispatch({
            type: 'RECORDS_UPDATE_FILTER',
            update
        });
    };

    const updateLayers = (layers) => ({
        type: 'RECORDS_UPDATE_LAYERS',
        layers
    });

    const updateForm = (formId) => ({
        type: 'RECORDS_UPDATE_FORM',
        formId
    });

    const updateCustomFilter = (filter) => (dispatch) => {
        dispatch({
            type: 'RECORDS_UPDATE_CUSTOM_FILTER',
            filter
        });
    }

    const addCustomFilter = () => (dispatch, getState) => {
        const forms = getProjectForms(getState());

        dispatch({
            type: 'RECORDS_ADD_CUSTOM_FILTER',
            formId: forms.length === 1 ? forms[0].id : null,
            id: uuid()
        });
    };

    const deleteCustomFilter = (id) => ({
        type: 'RECORDS_DELETE_CUSTOM_FILTER',
        id
    });

    const updateCustomFilterOperator = (operator) => ({
        type: 'RECORDS_UPDATE_CUSTOM_FILTER_OPERATOR',
        operator
    });

    const updateAttributeFilter = (attribute, value, operator) => ({
        type: 'RECORDS_UPDATE_ATTRIBUTE_FILTER',
        attribute,
        value,
        operator
    });

    const recordsResetFilter = () => ({
        type: 'RECORDS_RESET_FILTER'
    });

    const loadRecords = ({ filter, pagination, showDeleted, order } = {}) => (dispatch, getState) => {
        dispatch({
            type: 'RECORDS_LOAD',
            filter,
            pagination,
            showDeleted,
            order
        });

        const state = getState();

        return load(state).then(result => {
            dispatch({
                type: 'RECORDS_LOAD_SUCCESS',
                ...result
            });
            return result;
        });
    };

    const selectRecord = (ids, source, append = false) => (dispatch) => {
        dispatch({
            type: 'RECORD_SELECT',
            ids,
            source,
            append
        });
    };

    const clearSelection = () => ({
        type: 'RECORDS_CLEAR_SELECTION'
    });

    const recordsInit = () => (dispatch, getState) => {

        try {
            const localState = localStorageService.get('COREO_RECORDS_STATE');
            const init = JSON.parse(localState);

            const project = getProject(getState());

            if (init !== null) {

                if (project.id === init.projectId) {
                    dispatch({
                        type: 'RECORDS_INIT',
                        init,
                        project
                    });
                }
            }

            const state = getState();
            if (!getRecordsLayers(state) || !init || init.projectId !== project.id) {
                dispatch({
                    type: 'RECORDS_UPDATE_LAYERS',
                    layers: getProjectMapLayersState(state)
                });
            }

        } catch (e) {
            console.warn(e);
        }
    }

    const recordsUpdateRecordState = (id, newState) => (dispatch) => {

        const mutation = `mutation{result: verifyRecord(input: {id: ${id}, state: ${newState}})}`;
        return CoreoAPI.mutation(mutation).then(() => {
            dispatch({
                type: 'RECORDS_UPDATE_RECORD_STATE_SUCCESS',
                state: newState,
                toast: 'Record state updated'
            });
        })
    }

    return {
        updateFilter,
        updateAttributeFilter,
        updateForm,
        loadRecords,
        toggleFilter,
        updateView,
        selectRecord,
        clearSelection,
        updateShowDeleted,
        addCustomFilter,
        updateCustomFilter,
        deleteCustomFilter,
        updateCustomFilterOperator,
        recordsInit,
        recordsResetFilter,
        recordsUpdateRecordState,
        updateLayers
    };

}
recordsActions.$inject = ['CoreoAPI', 'localStorageService'];
export default recordsActions;