import { getOrganisationMemberships } from '../../store/selectors';

export default class OrganisationSettingsController {

    static $inject = ['$scope', '$ngRedux', '$state', 'OrganisationsActions', '$timeout', 'organisation'];
    constructor($scope, $ngRedux, $state, OrganisationsActions, $timeout, organisation) {
        this.organisation = angular.copy(organisation);
        this.$scope = $scope;
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.$state = $state;
        this.OrganisationsActions = OrganisationsActions;
        this.noIcon = '../../assets/icons/no-icon.png';
        this.image = null;
        this.imageUrl = organisation.imageUrl;
        this.imageChanged = this.imageChanged.bind(this);
        this.seatsFilled = getOrganisationMemberships($ngRedux.getState()).length;
    }

    imageChanged(e) {
        const vm = this;
        const file = (e.srcElement || e.target).files[0];
        this.image = file;

        var reader = new FileReader();

        reader.onloadend = function (event) {
            vm.imageUrl = event.target.result;
            vm.$scope.$apply(() => {
                vm.organisationProfileForm.$setDirty();
            });
            // vm.$timeout()
            // vm.organisationProfileForm.$setDirty();
        };

        reader.readAsDataURL(file);
    }

    startImageChange() {
        document.getElementById('org-img-btn').click();
    }

    update() {
        this.$ngRedux.dispatch(this.OrganisationsActions.updateOrganisation(this.organisation.name, this.image));
    }
}