import { combineReducers } from 'redux';
import project from './project/project.reducer';
import projects from './projects/projects.reducer';
import projectTemplates from './project-templates/project-templates.reducer';
import form from './form/form.reducer';
import auth from './auth/auth.reducer';
import page from './page/page.reducer';
import records from './records/records.reducer';
import organisations from './organisations/organisations.reducer';
import record from './record/record.reducer';

export default combineReducers({
    form,
    projects,
    projectTemplates,
    project,
    records,
    organisations,
    auth,
    page,
    record
});