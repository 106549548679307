import { IController } from "angular";

interface ModalConfirmOptions {
    title: string;
    text: string;
    ev?: MouseEvent;
}

export default class ModalService {

    static $inject = ['$mdDialog'];
    constructor(private $mdDialog: angular.material.IDialogService) { }

    confirm(options: ModalConfirmOptions) {
        return this.$mdDialog.show({
            template: `<md-dialog class="md-dialog">
            <form ng-cloak>
                <md-toolbar>
                    <div class="md-toolbar-tools">
                        <h2>{{ctrl.title}}</h2>
                        <span flex></span>
                        <md-button class="md-icon-button" aria-label="close" ng-click="ctrl.cancel()">
                            <i class="fas fa-times"></i>
                        </md-button>
                    </div>
                </md-toolbar>
                <md-dialog-content>{{ctrl.text}}</md-dialog-content>
                <md-dialog-actions layout="row">
                    <md-button class="coreo-button clear" ng-click="ctrl.cancel()">Cancel</md-button>
                    <md-button class="coreo-button md-raised danger" ng-click="ctrl.ok()">
                        Delete
                    </md-button>
                </md-dialog-actions>
            </form>
        </md-dialog>`,
            controllerAs: 'ctrl',
            locals: options,
            targetEvent: options.ev,
            bindToController: true,
            controller: class CreateMapOverlayController implements IController {
                static $inject = ['$mdDialog'];
                constructor(private $mdDialog) { }

                ok() {
                    this.$mdDialog.hide();
                }

                cancel() {
                    this.$mdDialog.cancel();
                }
            }
        }).then(() => true, () => false);
    }
}