import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import { RecordDetailUser, VerificationStateMap } from '../record-detail.component';
import { RecordLogComponent } from './record-log.component';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReviewChangesModalComponent } from './review-changes-modal.component';

const operations = [
    'create',
    'update',
    'verification'
] as const;

type Operation = typeof operations[number];

export interface RecordLog {
    id: number;
    revertedId: number;
    operation: Operation;
    diff: any[];
    createdAt: string;
    user: RecordDetailUser;
}

interface RecordLogsResponse {
    logs: {
        logs: RecordLog[];
    }
}

@Component({
    selector: 'app-record-activity',
    templateUrl: 'record-activity.component.html',
    standalone: true,
    imports: [
        CommonModule,
        RecordLogComponent
    ],
    providers: [
        DialogService
    ]
})

export class RecordActivityComponent implements OnInit {

    @Input() recordId!: number;
    @Input() verificationStateMap!: VerificationStateMap;

    @Output() recordReverted: EventEmitter<any> = new EventEmitter();

    public logs$: Observable<RecordLog[]>;

    private dialogRef: DynamicDialogRef | undefined;

    constructor(
        private apiService: ApiService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.getLogs();
    }

    private getLogs() {
        const query = `query getRecordLogs($id: Int){
            record(id: $id){
                logs(order:"reverse:createdAt"){
                    logs{
                        id
                        revertedId
                        operation
                        diff
                        createdAt
                        user{
                            displayName
                            username
                            imageUrl
                            id
                        }
                    }
                }
            }
        }`;

        this.logs$ = this.apiService.graphql<{ record: RecordLogsResponse }>(query, { id: this.recordId }).pipe(
            take(1),
            map(res => res.record.logs.logs)
        );
    }

    public refresh() {
        this.getLogs();
    }

    public reviewChanges(log: RecordLog) {
        this.dialogRef = this.dialogService.open(ReviewChangesModalComponent, {
            data: {
                recordId: this.recordId,
                log
            },
            header: 'Record Change',
            width: '75vw',
            height: '75vh',
            modal: true,
            breakpoints: {
                '960px': '75vw',
                '640px': '90vw'
            },
            contentStyle: { 'padding': '0px' }
        });

        this.dialogRef.onClose.subscribe((data: { recordChanged: boolean }) => {
            console.log('dialogRef.onClose', data);
            // handle revert changes
            if (!!data && data.recordChanged) {
                this.recordReverted.emit();
            }
        });
    }
}