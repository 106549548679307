import { Component, OnDestroy, OnInit, computed, input, signal } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/core';
import { Question } from '../form.types';
import { FormGroup } from '@angular/forms';
import { catchError, of, take } from 'rxjs';
import { CommonModule } from '@angular/common';
import { GeometryPickerModalComponent } from './geometry-picker-modal.component';
import { ModalButtonComponent } from '../buttons/modal-button.component';

@Component({
    selector: 'app-geometry-picker',
    templateUrl: 'geometry-picker.component.html',
    standalone: true,
    imports: [
        CommonModule,
        ModalButtonComponent
    ]
})

export class GeometryPickerComponent implements OnInit, OnDestroy {

    question = input.required<Question>();
    form = input.required<FormGroup>();

    projectId = computed(() => {
        return this.question().projectId;
    });
    collectionId = computed(() => {
        return this.question().collectionId;
    });
    formControl = computed(() => {
        return this.form().controls[this.question().path];
    });

    /** note using a computed signal for the key wasn't updating when changed after the modal closed so explicitly setting it on init and after modal close */
    key = signal<string | null>(null);
    feature = signal<{ id: number; key: string; value: string } | null>(null);

    private dialogRef: DynamicDialogRef | undefined;
    // TODO: geolocate? - both show user location in the app -  has this been removed?
    // TODO: map layers? - needed?

    constructor(
        private apiService: ApiService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.key.set(this.formControl().value);

        if (this.key()) {
            this.getFeature();
        }
    }

    ngOnDestroy() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    private getFeature() {
        const collectionsFragment = `fragment collectionFields on Collection{
            items(where: { key: $key }){
                id
                key
                value
            }
        }`;

        const query = `query geometryPickerGetFeatureValue($projectId: Int!, $collectionId: Int!, $key: String!){
            project(id: $projectId){
                collections(where: { id: $collectionId }){ ...collectionFields }
                parent{
                    collections(where: { id: $collectionId }){ ...collectionFields }
                }
            }
        }${collectionsFragment}`;

        const input = {
            projectId: this.projectId(),
            collectionId: this.collectionId(),
            key: this.key()
        }

        this.apiService.graphql<{ project: { collections: { items: { id: number; key: string; value: string; }[] }[]; parent: { collections: { items: { id: number; key: string; value: string; }[] }[] } } }>(query, input).pipe(
            take(1),
            catchError(e => {
                console.warn(e);
                return of(null)
            })
        ).subscribe(res => {
            if (!!res) {
                const collection = res.project.collections?.[0] ?? res.project.parent?.collections?.[0];
                const item = collection.items[0];
                this.feature.set(item);
            }
        })
    }

    public openGeometryPicker() {
        this.dialogRef = this.dialogService.open(GeometryPickerModalComponent, {
            data: {
                projectId: this.question().projectId,
                collectionId: this.question().collectionId,
                feature: this.feature()
            },
            header: this.question().label,
            width: '720px',
            height: '75vh',
            modal: true,
            contentStyle: { 'padding': '0px' }
        });

        this.dialogRef.onClose.subscribe((data: string) => {
            if (data === undefined) {
                return;
            } else {
                if (!!data) {
                    this.formControl().setValue(data);
                } else if (data === null) {
                    this.formControl().setValue(null);
                }
                this.key.set(this.formControl().value);
                this.getFeature();
            }
        });
    }
}