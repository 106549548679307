import { getAuthUser, getAuthIsAdmin, getAuthIsImpersonating, getAuthUserImage, getOrganisationsList, getAuthOrganisationId, getAuthCurrentOrganisation } from '../../store/selectors';

export default class AppHeaderDirective {


    static $inject = ['$state', '$mdMenu', '$ngRedux', 'AuthActions', '$rootScope', '$transitions', '$location', 'docsUrl'];
    constructor($state, $mdMenu, $ngRedux, AuthActions, $rootScope, $transitions, $location, docsUrl) {
        this.restrict = 'E';
        this.$state = $state;
        this.$mdMenu = $mdMenu;
        this.$transitions = $transitions;
        this.template = require('!raw-loader!./app-header.directive.html').default;
        this.$ngRedux = $ngRedux;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.AuthActions = AuthActions;
        this.docsUrl = docsUrl;
        this.hasTutorial = false;
    }



    link($scope) {

        $scope.$on('$destroy', this.$ngRedux.connect((state) => {
            return {
                user: getAuthUser(state),
                isAdmin: getAuthIsAdmin(state),
                isImpersonating: getAuthIsImpersonating(state),
                organisations: getOrganisationsList(state),
                organisationId: getAuthOrganisationId(state),
                imageUrl: getAuthUserImage(state) || '../../assets/images/user.svg'
            };
        }, this.AuthActions)($scope));

        $scope.cancelImpersonate = $scope.isImpersonating ? () => {
            $scope.unimpersonate().then(() => this.$state.reload());
        } : null;

        $scope.openMenu = ($mdMenu, ev) => {
            $mdMenu.open(ev);
            document.getElementById('emptyButton').click();
        };

        $scope.closeMenu = ($mdMenu) => {
            $mdMenu.close();
        };

        $scope.doLogout = () => {
            $scope.logout();
            this.$location.path('/login');
        }

        $scope.loading = false;

        $scope.$on('$destroy', this.$transitions.onStart({}, (trans) => {
            $scope.loading = true;
            trans.promise.finally(() => $scope.loading = false);
        }));

        $scope.tutorial = () => {
            this.$rootScope.$broadcast('$tutorial:restart', { fromGuide: true });
        };

        const updateTutorial = () => {
            let state = this.$state.current;
            $scope.hasTutorial = state && state.data && state.data.hasTutorial;
        };

        $scope.docsUrl = this.docsUrl;
        $scope.tutorialUrl = `${this.docsUrl}/docs/tutorial/getting-started`;
        $scope.supportUrl = 'https://natapp.freshdesk.com/support/tickets/new';

        $scope.setOrg = (orgId) => {
            $scope.setOrganisation(orgId);
            this.$state.go('home');
        };

        $scope.orgSettings = () => {
            const organisation = getAuthCurrentOrganisation(this.$ngRedux.getState());
            this.$state.go('organisation-admin.settings', { orgSlug: organisation.slug });
        };

        $scope.orgMembers = () => {
            const organisation = getAuthCurrentOrganisation(this.$ngRedux.getState());
            this.$state.go('organisation-admin.members', { orgSlug: organisation.slug });
        };

        $scope.orgProjects = () => {
            this.$state.go('home');
        };

        $scope.$on('$destroy', this.$transitions.onSuccess({}, () => {
            updateTutorial();
        }));
        updateTutorial();
    }
}
AppHeaderDirective.$inject = ['$state', '$mdMenu', '$ngRedux', 'AuthActions', '$rootScope', '$transitions', '$location', 'docsUrl'];