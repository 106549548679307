@if (question.questionType === 'text' && !!question.text) {
    <app-form-text-block [question]="question"></app-form-text-block>
}
@else {
    <div [formGroup]="form">
        <div class="flex items-center justify-between mb-2">
            <!-- Question label -->
            <label [attr.for]="question.path" class="relative text-text-primary leading-none">
                {{ question.text }}{{ question.required ? ' *' : null }}
                <!-- Show help tooltip -->
                @if (question.help) {
                    <i class="fa-solid fa-circle-question absolute -right-5 inset-y-0 text-text-secondary hover:cursor-pointer" [pTooltip]="question.help" tooltipPosition="top"></i>
                }
            </label>
    
            <!-- Show slider value in line with label -->
            @if (isSlider) {
                <p>{{ value }}</p>
            }
    
            <!-- Show add image button in line with label -->
            @if (showAddImageButton()) {
                <p-button label="Add image" (click)="addImage($event)">
                    <img src="/assets/icons/file-image-white.svg" alt="" class="mr-1">
                </p-button>
                <input
                    #imageInput
                    type="file"
                    accept="capture=image/jpeg, image/png, image/jpg"
                    (change)="selectImageHandler()"
                    class="sr-only opacity-0"
                />
            }
    
            <!-- Show checkbox in line with label -->
            @if (question.type === 'boolean' && question.questionType === 'confirm') {
                <p-checkbox [binary]="true" [formControlName]="question.path" [inputId]="question.path"></p-checkbox>
            }
        </div>
    
        <!-- Show description -->
        @if (question.description) {
            <div class="mb-2 leading-none">
                <p class="text-xs text-text-secondary">{{ question.description }}</p>
            </div>
        }
    
        @if (isExpression) {
            <!-- Calculated field -->
            <div class="h-10 px-4 rounded-md bg-primary-lighten-50 flex items-center">
                @switch (question.type) {
                    @case ('float') {
                        <p>{{ value }}</p>       
                    }
                    @case ('boolean') {
                        <p>{{ value ? 'Yes' : 'No' }}</p>       
                    }
                    @case ('date') {
                        <p>{{ value | date: "d MMMM y" }}</p>       
                    }
                    @case ('datetime') {
                        <p>{{ value | date: "d MMMM y 'at' hh:mm a" }}</p>       
                    }
                    @default {
                        <p>{{ value }}</p> 
                    }
                }
            </div>
        }
        @else {
            @switch (question.type) {
                @case ('text') {
                    <app-text-answer [question]="question" [form]="form"></app-text-answer>
                }
                @case ('email') {
                    <input pInputText type="email" [formControlName]="question.path" [id]="question.path" class="w-full" />
                }
                @case ('url') {
                    <input pInputText type="url" [formControlName]="question.path" [id]="question.path" class="w-full" />
                }
                @case ('boolean') {
                    @if (question.questionType === 'boolean') {
                        <app-yes-no [question]="question" [form]="form"></app-yes-no>
                    }
                }
                @case ('float') {
                    <app-numeric-answer [question]="question" [form]="form"></app-numeric-answer>
                }
                @case ('integer') {
                    <app-numeric-answer [question]="question" [form]="form"></app-numeric-answer>
                }
                @case ('date') {
                    <app-date-picker [question]="question" [form]="form"></app-date-picker>
                }
                @case ('datetime') {
                    <app-date-picker [question]="question" [form]="form"></app-date-picker>
                }
                @case ('select') {
                    @if (question.questionType === 'multi') {
                        <app-multiple-choice [question]="question" [form]="form"></app-multiple-choice>
                    }
                    @if (question.questionType === 'modal') {
                        <app-searchable-list [question]="question" [form]="form"></app-searchable-list>
                    }
                    @if (question.questionType === 'geometryselect') {
                        <app-geometry-picker [question]="question" [form]="form"></app-geometry-picker>
                    }
                }
                @case ('multiselect') {
                    @if (question.questionType === 'multi') {
                        <app-multiple-choice [question]="question" [form]="form"></app-multiple-choice>
                    }
                    @if (question.questionType === 'modal') {
                        <app-searchable-list [question]="question" [form]="form"></app-searchable-list>
                    }
                }
                @case ('media') {
                    @if (value && value.length > 0) {
                        <div class="flex items-center justify-start gap-4 flex-wrap">
                            @for (attachment of value; track attachment) {
                                <app-form-image [imageSrc]="attachment.url" (remove)="removeAttachment(question.path, attachment)"></app-form-image>
                            }
                        </div>
                    }
                }
                @case ('attachment') {
                    @if (question.questionType === 'mediaGallery') {
                        @if (value && value.length > 0) {
                            <div class="flex items-center justify-start gap-4 flex-wrap">
                                @for (attachment of value; track attachment) {
                                    <app-form-image [imageSrc]="attachment.url" (remove)="removeAttachment(question.path, attachment)"></app-form-image>
                                }
                            </div>
                        }
                    }
                    @if (question.questionType === 'signature') {
                        <!-- Signature's can't be edited for now so only show if it is a new form -->
                        @if (isEdit) {
                            @if (value && value.length > 0) {
                                <div class="flex items-center justify-start gap-4 flex-wrap">
                                    @for (attachment of value; track attachment) {
                                        <div class="relative z-0 h-[187px] aspect-video rounded-md bg-grey-20 shadow-panel overflow-hidden">
                                            <img [src]="attachment.url" alt="" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[187px]">
                                            <p class="absolute bottom-2 left-2 text-xs italic">* Signature's can't be edited</p>
                                        </div>
                                    }
                                </div>
                            } 
                            @else {
                                <div class="relative z-0 h-[187px] aspect-video rounded-md bg-grey-20 shadow-panel overflow-hidden">
                                    <p class="absolute bottom-2 left-2 text-xs italic">* Signature's can't be edited</p>
                                </div>
                            }
                        }
                        @else {
                            <div class="relative z-0 h-[187px] aspect-video rounded-md bg-white border border-dashed shadow-panel overflow-hidden">
                                <app-signature-pad (signature)="handleSignature($event)"></app-signature-pad>
                            </div>
                        }
                    }
                }
                @default {
                    <!-- type === null (child/subform, lookup/associate, geometry) -->
                    <!-- lookup/associate -->
                    @if (question.questionType === 'association') {
                        <div class="flex flex-col gap-4">
                            @for (associate of value; track associate) {
                                <app-record-button-remove [associate]="associate" (remove)="removeAssociate(question.path, associate)"></app-record-button-remove>
                            }
                            @empty {
                                <app-record-button-add (click)="openRecordLookup()">Select record</app-record-button-add>
                            }
                        </div>
                    }
                    @else if (question.questionType === 'child') {
                        <!-- child records/subforms -->
                        <div class="flex flex-col gap-4">
                            @for (associate of value; track associate) {
                                <app-record-button-remove [associate]="associate" (remove)="removeAssociate(question.path, associate)"></app-record-button-remove>
                            }
                            <app-record-button-add (click)="addChildRecord()">Add record</app-record-button-add>
                        </div>  
                    }
                }
            }
        }
    
        <!-- error message -->
        @if (error) {
            <p class="text-xs text-danger leading-none mt-2">{{ error }}</p>
        }
    </div>
}