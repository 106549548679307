import { Component, ElementRef, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { Subscription, catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
import mapboxgl, { LngLatBoundsLike, Map as MapboxMap } from "mapbox-gl";
import { FormsModule } from '@angular/forms';
import { addFeatureLayers, updateFeatures } from '../../records.map.utils';
import bbox from '@turf/bbox';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { debounce } from 'lodash';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';

type CollectionItemsResponse = {
    items: Item[];
    itemCount: number;
}

type Item = {
    id: number;
    key: string;
    value: string;
}

@Component({
    selector: 'app-geometry-picker-modal',
    templateUrl: 'geometry-picker-modal.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        AsyncPipe,
        CardModule,
        TableModule,
        InputIconModule,
        InputTextModule
    ]
})

export class GeometryPickerModalComponent implements OnInit, OnDestroy {

    private projectId: number;
    private collectionId: number;

    mapDiv = viewChild<ElementRef>('geometryPickerMap');

    private map: MapboxMap;
    private featureSubscription: Subscription;

    itemCount = signal(0);
    loading = signal(false);
    first = signal(0);
    rows = signal(10);
    collection = signal<Item[]>([]);
    currentFeature = signal<Item | null>(null);
    searchTerm = signal<string>('');

    constructor(
        private dialogRef: DynamicDialogRef,
        private dialogConfig: DynamicDialogConfig,
        private apiService: ApiService,
    ) { }

    ngOnInit() {
        this.projectId = this.dialogConfig.data['projectId'];
        this.collectionId = this.dialogConfig.data['collectionId'];
        if (this.dialogConfig.data['feature']) {
            this.currentFeature.set(this.dialogConfig.data['feature']);
        }
        this.initMap();
        this.loadCollection();
    }

    ngOnDestroy(): void {
        if (this.featureSubscription) {
            this.featureSubscription.unsubscribe();
        }
    }

    public loadCollection($event: TableLazyLoadEvent = null) {
        this.loading.set(true);

        const collectionsFragment = `fragment collectionFields on Collection{
            items(offset: $offset, limit: $limit, where: $where){
                id
                key
                value
            }
            itemCount(where: $where)
        }`;

        const query = `query getCoreoCollection($projectId: Int!, $collectionId: Int!, $offset: Int!, $limit: Int!, $where: SequelizeJSON){
            project(id: $projectId){
                collections(where: {id: $collectionId }){ ...collectionFields }
                parent{
                    collections(where: {id: $collectionId }){ ...collectionFields }
                }
            }
        }${collectionsFragment}`;

        if ($event) {
            this.first.set($event.first);
            this.rows.set($event.rows);
        }

        const where = {
            and: [
                { value: { iLike: `%${this.searchTerm()}%` } }
            ]
        };

        const input = {
            projectId: this.projectId,
            collectionId: this.collectionId,
            offset: this.first(),
            limit: this.rows(),
            where
        };

        this.apiService.graphql<{ project: { collections: CollectionItemsResponse[]; parent: { collections: CollectionItemsResponse[] } } }>(query, input).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                const collection = res.project.collections?.[0] ?? res.project.parent?.collections?.[0];
                const { items, itemCount } = collection;
                this.collection.set(items);
                this.itemCount.set(itemCount);
            }
            this.loading.set(false);
        });
    }

    public debouncedSearch = debounce(this.handleSearch, 500);

    private handleSearch() {
        this.first.set(0);
        this.rows.set(10);
        this.loadCollection();
    }

    public clearSearch($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.searchTerm.set('');
        this.handleSearch();
    }

    private initMap() {
        const mapOptions: mapboxgl.MapboxOptions = {
            accessToken: environment.mapboxApiKey,
            container: this.mapDiv()?.nativeElement,
            style: 'mapbox://styles/mapbox/streets-v12',
            projection: { name: 'mercator' },
            center: [-2.9, 54.4],
            zoom: 4
        };

        this.map = new MapboxMap(mapOptions);

        this.map.on('load', async () => {
            this.map.resize();
            addFeatureLayers(this.map, []);
            if (this.currentFeature()) {
                this.setFeature();
            }
        });
    }

    public setFeature() {
        if (!this.currentFeature()) {
            return;
        }

        if (this.featureSubscription) {
            this.featureSubscription.unsubscribe();
        }

        const id = this.currentFeature().id;

        const collectionsFragment = `fragment collectionFields on Collection{
            items(where: { id: $itemId }){
                geometry{
                    type
                    coordinates
                }
            }
        }`;

        const query = `query getCoreoCollection($projectId: Int!, $collectionId: Int!, $itemId: Int!){
            project(id: $projectId){
                collections(where: { id:  $collectionId }){ ...collectionFields }
                parent{
                    collections(where: { id:  $collectionId }){ ...collectionFields }
                }
            }
        }${collectionsFragment}`;

        const input = {
            projectId: this.projectId,
            collectionId: this.collectionId,
            itemId: id
        }

        this.featureSubscription = this.apiService.graphql<{ project: { collections: { items: { geometry: any } }[]; parent: { collections: { items: { geometry: any } }[] } } }>(query, input).pipe(take(1)).subscribe(res => {
            const collection = res.project.collections?.[0] ?? res.project.parent?.collections?.[0];
            const geometry = collection.items[0].geometry;
            updateFeatures(this.map, [{ type: 'Feature', geometry, properties: null }]);
            const bounds = bbox(geometry);
            this.map.fitBounds(bounds as LngLatBoundsLike, { padding: 40 });
        });
    }

    public saveSelection() {
        this.dialogRef.close(this.currentFeature().key ?? null);
    }

    public clearSelection() {
        this.dialogRef.close(null);
    }

    public isSelected(item: Item): boolean {
        if (this.currentFeature()) {
            return this.currentFeature().id === item.id;
        } else {
            return false;
        }
    }
}