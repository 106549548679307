<p-panel class="p-0">
    <p-header class="flex items-center justify-between">
        <div class="flex items-center">
            <img src="/assets/icons/location-light.svg" alt="" class="h-5 mr-3">
            @if (editGeometry) {
                <h3 class="text-lg font-semibold leading-none">Edit Geometry</h3>
                @if (questionData && questionData.help) {
                    <i class="fa-solid fa-circle-question text-text-secondary ml-2 hover:cursor-pointer" [pTooltip]="questionData.help" tooltipPosition="top"></i>
                }
            }
            @else {
                <h3 class="text-lg font-semibold leading-none">Geometry Data</h3>
            }
        </div>
        @if (canEdit) {
            @if (editGeometry) {
                <div class="flex items-center justify-end gap-3">
                    <p-button [disabled]="submitting()" label="Cancel" [outlined]="true" (click)="cancelEditGeometry()" styleClass="w-32"></p-button>
                    <p-button [disabled]="submitting()" [loading]="submitting()" label="Done" (click)="finishEditGeometry($event)" styleClass="w-32"></p-button>
                </div>
            } 
            @else {
                <p-button [disabled]="submitting()" label="Edit geometry" (click)="startEditGeometry()">
                    <img alt="" src="/assets/icons/edit-white.svg" class="mr-1" />
                </p-button>
            }
        }
    </p-header>
    @if (editGeometry && questionData && questionData.description) {
        <div class="bg-white px-4 py-3">
            <p class="text-xs text-text-secondary">{{ questionData.description }}</p>
        </div>
    }
    <div class="relative h-72 w-full">
        <app-record-map #recordMap [recordId]="recordId"></app-record-map>
    </div>
</p-panel>