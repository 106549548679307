export default class IntermediaryPageJoinedOrganisationSignupController {
    static $inject = ['$stateParams', 'IntermediaryPageService'];
    constructor($stateParams, IntermediaryPageService) {
        this.token = $stateParams.token;
        this.isAdminAreaUser = $stateParams.isAdminAreaUser;
        this.organisation = $stateParams.organisation;
        this.hostUrl = IntermediaryPageService.getHostUrl();
        this.displayHostUrl = IntermediaryPageService.getDisplayHostUrl();
    }

    $onInit() { }
}
