export const COLOR_VARS = [
  {
    name: 'Primary',
    property: '--ion-color-primary'
  },
  {
    name: 'Secondary',
    property: '--ion-color-secondary'
  },
  {
    name: 'Tertiary',
    property: '--ion-color-tertiary'
  },
  {
    name: 'Success',
    property: '--ion-color-success'
  },
  {
    name: 'Warning',
    property: '--ion-color-warning'
  },
  {
    name: 'Danger',
    property: '--ion-color-danger'
  },
  {
    name: 'Dark',
    property: '--ion-color-dark'
  },
  {
    name: 'Medium',
    property: '--ion-color-medium'
  },
  {
    name: 'Light',
    property: '--ion-color-light'
  }
];

export const VARIANTS = [
  {
    key: 'shade',
    cssKey: '-shade'
  },
  {
   key: 'tint',
   cssKey: '-tint',
  },
  {
    key: 'contrast',
    cssKey: '-contrast',
  }, 
  {
    key: 'contrastRgb',
    cssKey: '-contrast-rgb'
  }, 
  {
    key: 'valueRgb',
    cssKey: '-rgb'
  }
];

export const THEMES = [
  {
    name: 'Default',
    colors: {
      Primary: '#0051ac',
      Secondary: '#3dc2ff',
      Tertiary: '#5260ff',
      Success: '#2dd36f',
      Warning:'#ffc409',
      Danger: '#eb445a',
      Dark: '#222428',
      Medium: '#92949c',
      Light: '#f4f5f8'
    }
  }
]

const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };
  
  const expandHex = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (_m, r, g, b) => {
      return r + r + g + g + b + b;
    });
  
    return `#${hex.replace('#', '')}`;
  };
  
  const hexToRGB = (hex) => {
    hex = expandHex(hex);
    hex = hex.replace('#', '');
    const intValue = parseInt(hex, 16);
  
    return {
      r: (intValue >> 16) & 255,
      g: (intValue >> 8) & 255,
      b: intValue & 255
    };
  };
  
  const hslToRGB = ({ h, s, l }) => {
    h = h / 360;
    s = s / 100;
    l = l / 100;
    if (s === 0) {
      l = Math.round(l * 255);
      return {
        r: l,
        g: l,
        b: l
      };
    }
  
    const hue2rgb = (p, q, t) => {
      if (t < 0) { t += 1; }
      if (t > 1) { t -= 1; }
      if (t < 1 / 6) { return p + (q - p) * 6 * t; }
      if (t < 1 / 2) { return q; }
      if (t < 2 / 3) { return p + (q - p) * (2 / 3 - t) * 6; }
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    const r = hue2rgb(p, q, h + (1 / 3));
    const g = hue2rgb(p, q, h);
    const b = hue2rgb(p, q, h - (1 / 3));
  
    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    };
  };
  
  const mixColors = (color, mixColor, weight = .5) => {
    const colorRGB = color.rgb;
    const mixColorRGB = mixColor.rgb;
    const mixColorWeight = 1 - weight;
  
    return {
      r: Math.round(weight * mixColorRGB.r + mixColorWeight * colorRGB.r),
      g: Math.round(weight * mixColorRGB.g + mixColorWeight * colorRGB.g),
      b: Math.round(weight * mixColorRGB.b + mixColorWeight * colorRGB.b)
    };
  };
  
  const rgbToHex = ({ r, g, b }) => {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
  };
  
  const rgbToHSL = ({ r, g, b }) => {
    r = Math.max(Math.min(r / 255, 1), 0);
    g = Math.max(Math.min(g / 255, 1), 0);
    b = Math.max(Math.min(b / 255, 1), 0);
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const l = Math.min(1, Math.max(0, (max + min) / 2));
    let d;
    let h;
    let s;
  
    if (max !== min) {
      d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      if (max === r) {
        h = (g - b) / d + (g < b ? 6 : 0);
      } else if (max === g) {
        h = (b - r) / d + 2;
      } else {
        h = (r - g) / d + 4;
      }
      h = h / 6;
    } else {
      h = s = 0;
    }
    return {
      h: Math.round(h * 360),
      s: Math.round(s * 100),
      l: Math.round(l * 100)
    };
  };
  
  const rgbToYIQ = ({ r, g, b }) => {
    return ((r * 299) + (g * 587) + (b * 114)) / 1000;
  };
  
  function exists(value){
    return (value !== null && typeof value !== 'undefined');
  }

  export class Color {
    constructor(value) {
      this.hex = null;
      this.hsl = null;
      this.rgb = null;
      this.yiq = null;
      if (typeof(value) === 'string' && /rgb\(/.test(value)) {
        const matches = /rgb\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)/.exec(value) || [];
        value = { r: parseInt(matches[0], 10), g: parseInt(matches[1], 10), b: parseInt(matches[2], 10) };
      } else if (typeof(value) === 'string' && /hsl\(/.test(value)) {
        const matches = /hsl\((\d{1,3}), ?(\d{1,3}%), ?(\d{1,3}%)\)/.exec(value) || [];
        value = { h: parseInt(matches[0], 10), s: parseInt(matches[1], 10), l: parseInt(matches[2], 10) };
      }
      if(!exists(value)) {
        value = {r:0, g:0, b:0};
      }
      if (typeof(value) === 'string') {
        value = value.replace(/\s/g, '');
        this.hex = expandHex(value);
        this.rgb = hexToRGB(this.hex);
        this.hsl = rgbToHSL(this.rgb);
      } else if (exists(value.r) && exists(value.g) && exists(value.b)) {
        this.rgb = value;
        this.hex = rgbToHex(this.rgb);
        this.hsl = rgbToHSL(this.rgb);
      } else if (exists(value.h) && exists(value.s) && exists(value.l)) {
        this.hsl = value;
        this.rgb = hslToRGB(this.hsl);
        this.hex = rgbToHex(this.rgb);
      } else {
        throw new Error('Incorrect value passed.' + JSON.stringify(value));
      }
  
      this.yiq = rgbToYIQ(this.rgb);
    }
  
    static isColor(value) {
      if (/rgb\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)/.test(value)) { return true; }
  
      return /(^#[0-9a-fA-F]+)/.test(value.trim());
    }
  
    contrast(threshold = 128) {
      return new Color((this.yiq >= threshold ? '#000' : '#fff'));
    }
  
    mix(from, amount = .5){
      const base = from instanceof Color ? from : new Color(from);
      return new Color(mixColors(this, base, amount));
    }
  
    shade(weight = .12) {
      return this.mix({ r: 0, g: 0, b: 0 }, weight);
    }
  
    tint(weight = .1) {
      return this.mix({ r: 255, g: 255, b: 255 }, weight);
    }
  
    toList() {
      const { r, g, b } = this.rgb;
      return `${r},${g},${b}`;
    }
  }

export const generateColor = (name, property, value) => {
  const color = new Color(value);
  const contrast = color.contrast();
  const tint = color.tint();
  const shade = color.shade();
  const light = color.tint(.25);
  const dark = color.shade(.25);
  return {
    name,
    property,
    value,
    valueRgb: rgbToString(color.rgb),
    contrast: contrast.hex,
    contrastRgb: rgbToString(contrast.rgb),
    tint: tint.hex,
    shade: shade.hex,
    light: light.hex,
    dark: dark.hex
  };
};

export const convertCssToColors = (cssText, themeIdx = 0) => {
  const colors = [];

  COLOR_VARS.forEach(colorVar => {
    const attrMap = {
      value: '',
      valueRgb: '-rgb',
      contrast: '-contrast',
      contrastRgb: '-contrast-rgb',
      shade: '-shade',
      tint: '-tint',
    };

    const newColor = { ...colorVar };
    const keys = Object.keys(attrMap);
    let color = generateColor(colorVar.name, colorVar.property, colorVarExists(colorVar.property, cssText) ? parseColorVar(colorVar.property, cssText) : THEMES[themeIdx].colors[colorVar.name]);
    for (const key of keys) {
      newColor[key] = color[key]; // we don't reference css for shades/variants, they are calculated.
    }
    colors.push(newColor);
  });

  return colors;
};

export const updateCssText = (colorAttr, cssText, newColorValue) => {
  if (typeof newColorValue === 'undefined') {
    return cssText;
  }

  const oldKeyValue = getCssKeyVal(colorAttr, cssText);
  const newKeyValue = `${colorAttr}: ${newColorValue}`;
  cssText = cssText.replace(oldKeyValue, newKeyValue);

  return cssText;
};

export const addCssProperty = (colorAttr, cssText, newColorValue) => {
  return cssText + '\n' + colorAttr + ': ' + newColorValue + ';';
}

export const parseColorVar = (colorAttr, cssText) => {
  const attrKeyVal = getCssKeyVal(colorAttr, cssText);
  return attrKeyVal.trim().split(':')[1].trim();
};

const getCssKeyVal = (colorAttr, cssText) => {
  const startIndex = cssText.indexOf(colorAttr);
  const valueSplt = cssText.substring(startIndex + colorAttr.length);
  const semiColonIndex = valueSplt.indexOf(';');
  const endIndex = startIndex + colorAttr.length + Math.min(cssText.length, semiColonIndex);

  return cssText.substring(startIndex, endIndex);
};

export const colorVarExists = (colorAttr, cssText) => {
  return cssText.replace(/ /g, '').includes(colorAttr + ':');
}

const rgbToString = c => {
  return `${c.r},${c.g},${c.b}`;
};

export const initColors = (css) => {
  const DEFAULT_THEME_IDX = 0;

  if(!css || !colorVarExists(COLOR_VARS[0].property, css)) {
      css = '';
  }
  COLOR_VARS.map(c => {
      let color;
      if (!colorVarExists(c.property, css)) {
          color = generateColor(c.name, c.property, THEMES[DEFAULT_THEME_IDX].colors[c.name]);
          css = addCssProperty(c.property, css, color.value);
      } else {
          color = generateColor(c.name, c.property, parseColorVar(c.property, css)); 
      }
      VARIANTS.map(v => {
          if (!colorVarExists(c.property + v.cssKey, css)) {
              css = addCssProperty(c.property + v.cssKey, css, color[v.key]);
          }
      })
  });
  return css.trim();
}