import angular from 'angular';
export default class CoreoAPIService {

    static $inject = ['$http'];
    constructor(private $http: angular.IHttpService) { }

    gqlStringify(obj) {
        // JSON stringify, and strip quotes on field names
        // return JSON.stringify(obj).replace(/\"([^(\")"]+)\":/g, "$1:").replace(/\"(\$[^(\")"]+)\"/g, "$1")
        return angular.toJson(obj).replace(/\"([^(\")"]+)\":/g, "$1:").replace(/\"(\$[^(\")"]+)\"/g, "$1");
    }

    gqlStringifyNoVariables(obj) {
        // JSON stringify, and strip quotes on field names without handling variables, as values starting with `$` may be present
        // For example in the case of attribute expressions
        return angular.toJson(obj).replace(/\"([^(\")"]+)\":/g, "$1:")
    }

    gqlStringifyAccessMode(obj) {
        // JSON stringify, and strip quotes on field names, and 'accessMode' auth field as it is an enum
        return JSON.stringify(obj).replace(/\"([^(\")"]+)\":/g, "$1:").replace(/accessMode\s*:\s*"(.*?)"/g, "accessMode: $1");
    }

    gqlGeometryStringify(obj) {
        // JSON stringify, and strip quotes on field names
        // return JSON.stringify(obj).replace(/\"([^(\")"]+)\":/g, "$1:").replace(/\"(\$[^(\")"]+)\"/g, "$1")
        return angular.toJson(obj).replace(/\"([^(\")"]+)\":/g, "$1:").replace(/\"(\$[^(\")"]+)\"/g, "$1").replace(/type:\"(Point|MultiPoint|LineString|MultiLineString|Polygon|MultiPolygon|GeometryCollection|Feature|FeatureCollection)\"/g, "type:$1");
    }

    gql<T = any>(query, variables = {}) {
        return this.$http.post<{ data: T }>('/graphql', {
            query,
            variables,
        }).then(response => response.data.data as T);
    }

    gqlWithFiles<T = any>(query: string, files: Record<string, File>, variables = {}) {
        const form = new FormData();
        form.append('query', query);
        form.append('variables', JSON.stringify(variables));
        for (const fileName in files) {
            form.append(fileName, files[fileName]);
        }
        return this.$http.post<{ data: any; errors: any }>('/graphql', form, {
            headers: {
                'Content-Type': undefined
            }
        }).then(function (response) {
            if (response.data.errors && response.data.errors.length > 0) {
                throw new Error(response.data.errors[0].message);
            }
            return response.data.data as T;
        });
    }

    query(query, options?) {
        return this.$http.post<{ data: any }>('/graphql', {
            query,
            variables: options && options.variables || {},
        }, angular.extend({}, options || {})).then(response => response.data.data);
    }

    mutation(query, files?, options: { variables?: any } = {}) {
        if (files) {
            var form = new FormData();
            form.append('query', query);

            if (options && options.variables) {
                form.append('variables', JSON.stringify(options.variables));
                delete options.variables;
            }
            Object.keys(files).forEach(function (key) {
                form.append(key, files[key]);
            });
            return this.$http.post<{ data: any; errors: any }>('/graphql', form, angular.extend({}, {
                headers: {
                    'Content-Type': undefined
                }
            }, options || {})).then(function (response) {
                if (response.data.errors && response.data.errors.length > 0) {
                    throw new Error(response.data.errors[0].message);
                }
                return response.data.data.result;
            });
        } else { //no files, don't use form data which imposes constraints like 1MB text size (from multer on Coreo API)
            return this.$http.post<{ data: any; errors: any }>('/graphql', {
                query,
                variables: options.variables
            }, angular.extend({}, {
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
            }, options || {})).then(function (response) {
                if (response.data.errors && response.data.errors.length > 0) {
                    if (response.data.errors[0].code === 'ModelDependencyError') {
                        throw response.data.errors[0];
                    } else {
                        throw new Error(response.data.errors[0].message);
                    }
                }
                return response.data.data.result;
            });;
        }
    }
}
CoreoAPIService.$inject = ['$http'];