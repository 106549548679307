<p-toast position="bottom-center" [life]="2000"></p-toast>
<p-panel class="w-full">
    <ng-template pTemplate="header">
        <div class="flex items-center">
            <div class="flex items-center">
                <i class="fas fa-building fa-fw text-grey-20 mr-4"></i>
                <span class="font-bold text-lg text-grey-100">Organisation - {{organisation?.name}}</span>
            </div>
            <p-button type="button" [outlined]="true" size="large" (click)="goToDashboard()">Go to
                organisation dashboard</p-button>
        </div>
    </ng-template>

    <form class="flex flex-col space-y-4" [formGroup]="organisationForm">
        <div class="flex flex-col gap-1">
            <label>Owner</label>
            <input pInputText [disabled]="true" value="{{organisation?.owner?.displayName || ''}}" />
        </div>
        <div class="flex flex-col gap-1">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" formControlName="name" />
        </div>
        <div class="flex flex-col gap-1">
            <label for="slug">Slug</label>
            <input type="text" pInputText id="slug" formControlName="slug" />
        </div>
        <div class="flex flex-col gap-1">
            <label for="seats">Seats</label>
            <input type="number" pInputText id="seats" formControlName="seats" />
        </div>
        <div class="flex flex-col gap-1">
            <label for="contributorSeats">Contributor Seats</label>
            <input type="number" pInputText id="contributorSeats" formControlName="contributorSeats" />
        </div>
        <div class="flex flex-col gap-1 p-fluid">
            <label for="tier">Tier</label>
            <p-dropdown id="tier" [options]="organisationTiers" optionLabel="name" optionValue="id"
                formControlName="organisationTierId" placeholder="Select Tier"></p-dropdown>
        </div>
        <div class="flex flex-col gap-1">
            <label for="freeTrialEnd">Free Trial End Date</label>
            <p-calendar id="freeTrialEnd" dateFormat="dd/mm/yy" formControlName="freeTrialEnd" [showButtonBar]="true"
                placeholder="Trial End Date"></p-calendar>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <div class="flex justify-end gap-2">
            <p-button type="button" size="large" (click)="isVisibleDialog = true"><i
                    class="fa fa-exchange-alt ng-scope mr-2"></i> Transfer Ownership</p-button>
            <p-button type="button" size="large" (click)="onSubmit()"
                [disabled]="!organisationForm.valid || !organisationForm.dirty"><i class="fa fa-save ng-scope mr-2"></i>
                Update Organisation</p-button>
        </div>
    </ng-template>

</p-panel>

<app-transfer-organisation [isVisibleDialog]="isVisibleDialog" [organisationId]="organisationId"
    (visibleChange)="onVisibleChange($event)"></app-transfer-organisation>