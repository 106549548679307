import { ChangeDetectionStrategy, Component, OnInit, computed, inject, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TwicPicPipe } from 'src/app/shared/pipes/twicpic.pipe';

export interface ImageModalProps {
    images: string[];
    index?: number;
}

@Component({
    selector: 'app-image-modal',
    templateUrl: 'image-modal.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ButtonModule,
        TwicPicPipe
    ]
})
export class ImageModalComponent implements OnInit {

    dialogConfig = inject(DynamicDialogConfig);

    public images = signal<string[]>([]);
    public index = signal<number>(0);

    public currentImage = computed<string>(() => {
        return this.images()[this.index()];
    });
    public first = computed<boolean>(() => {
        return this.index() === 0;
    });

    public last = computed<boolean>(() => {
        return this.index() === (this.images().length - 1);
    });

    public multiple = computed<boolean>(() => {
        return this.images().length > 1;
    });

    ngOnInit() {
        const data = this.dialogConfig.data as ImageModalProps;
        this.images.set(data.images ?? []);
        this.index.set(data.index ?? 0);
    }

    public next() {
        if (this.last()) {
            return;
        }
        this.index.update(i => i + 1);
    }

    public previous() {
        if (this.first()) {
            return;
        }
        this.index.update(i => i - 1);
    }
}