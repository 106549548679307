<p-confirmDialog />
<div class="p-10 h-full flex flex-col gap-y-4 overflow-hidden">
    <!-- TODO: loading -->
    @if(parent){
        <div class="flex-none w-full bg-danger text-white text-center font-semibold px-10 py-4">
            This project is derived from a master project "{{parent.name}}". Its forms, collections, pages and map layers cannot
            be
            edited. You may upload additional ones to augment the project.
        </div>
    }

    <div class="flex-none w-full flex items-center justify-between gap-4">
        <h2 class="text-3xl font-semibold">Collections</h2>

        <div class="flex items-center justify-end">
            <p-button label="New collection" size="large" (click)="createCollection('empty')">
                <i class="fa-solid fa-plus mr-1"></i>
            </p-button>
            <p-button label="Upload a collection" size="large" class="ml-4" (click)="importMenu.toggle($event)">
                <i class="fa-solid fa-cloud-arrow-up mr-1"></i>
            </p-button>
            <p-menu #importMenu [popup]="true" [model]="importCollectionMenuItems" appendTo="body">
                <ng-template pTemplate="item" let-item>
                    <div class="p-menuitem-link">
                        <span>{{ item.label }}</span>
                    </div>
                </ng-template>
            </p-menu>

            <form [formGroup]="searchForm" class="ml-4">
                <span class="p-input-icon-left" [class.p-input-icon-right]="searchForm.value.search">
                    <i class="pi pi-search text-primary"></i>
                    <input type="text" pInputText formControlName="search" placeholder="Search for a collection" class="w-80 h-11" />
                    @if(searchForm.value.search){
                        <i class="pi pi-times cursor-pointer" (click)="searchForm.reset()"></i>
                    }
                </span>
            </form>
        </div>
    </div>

    @if (loading()) {
        <div class="w-full h-full flex flex-col items-center justify-center">
            <p-progressSpinner styleClass="w-16 h-16" class="w-16 h-16"></p-progressSpinner>
        </div>
    }
    @else {
        <div class="flex-none h-14 min-w-[720px]">
            @if (selectedCount() > 0) {
                <!-- top bar -->
                <div class="h-full w-full bg-white border border-grey-30 rounded-lg flex items-center justify-start gap-12 p-5">
                    <div class="flex-none flex items-center justify-start gap-2">
                        <button (click)="clearSelected()"><i class="far fa-minus-square text-2xl text-primary"></i></button>
                        <span class="font-semibold flex-shrink-0">{{selectedCount()}} selected</span>
                    </div>
                    <button (click)="deleteSelectedCheck()">
                        <i class="fa-solid fa-trash-can text-primary mr-1"></i>
                        Delete selected
                    </button>
                </div>
            }
        </div>
    
        @if(currentCollections().length > 0){
            <div class="flex-none flex items-center min-w-[720px]">
                <div class="w-1/2">
                    <button (click)="sort('name')">
                        <span class="mr-1" [ngClass]="sortMode() === 'name' ? 'font-semibold' : 'font-normal'">Name</span>
                        @if (sortMode() === 'name') {
                            @if (sortReverse()) {
                                <i class="fas fa-sort-amount-up"></i>
                            }
                            @else {
                                <i class="fas fa-sort-amount-down"></i>
                            }
                        }
                    </button>
                </div>
                <div class="w-1/2">
                    <button (click)="sort('created')">
                        <span class="mr-1" [ngClass]="sortMode() === 'created' ? 'font-semibold' : 'font-normal'">Created</span>
                        @if (sortMode() === 'created') {
                            @if (sortReverse()) {
                                <i class="fas fa-sort-amount-up"></i>
                            }
                            @else {
                                <i class="fas fa-sort-amount-down"></i>
                            }
                        }
                    </button>
                </div>
            </div>
        }
    
        <div class="overflow-y-auto no-scrollbar pb-4 min-w-[720px]">
            <ul class="select-none rounded-lg overflow-hidden shadow-panel">
                @for (item of currentCollections(); track item) {
                    <li>
                        <app-collections-list-item class="table-item bg-white" [item]="item" [selected]="isItemSelected(item)" (open)="openCollection(item)" (select)="selectCollection(item)" (delete)="deleteCollectionCheck(item)"></app-collections-list-item>
                    </li>
                }
            </ul>
        </div>
    
        @if(currentCollections().length < 1 && !!search()){
            <div class="w-full h-full flex flex-col items-center justify-center gap-7">
                <i class="fa-solid fa-magnifying-glass text-6xl text-primary-100"></i>
                <h2 class="text-base font-semibold text-grey-120">We couldn't find any collections matching that name.</h2>
                <p class="text-sm font-normal text-grey-50">Please try again with a different collection name.</p>
            </div>
        }
        @else if (currentCollections().length < 1) {
            <div class="w-full h-full flex flex-col items-center justify-center">
                <div class="max-w-xs flex items-center justify-center flex-col text-center gap-4">
                    <svg-icon class="w-[60px] h-[60px]" src="/assets/icons/apps.svg"/>
                    <h2 class="text-base font-semibold text-grey-120">There are no collections yet.</h2>
    
                    <p-button label="New collection" size="large" (click)="createCollection('empty')">
                        <i class="fa-solid fa-plus mr-1"></i>
                    </p-button>
                    <p-menu #importMenu2 [popup]="true" [model]="importCollectionMenuItems" appendTo="body">
                        <ng-template pTemplate="item" let-item>
                            <div class="p-menuitem-link">
                                <span>{{ item.label }}</span>
                            </div>
                        </ng-template>
                    </p-menu>
                </div>
            </div>
        }
    }
</div>