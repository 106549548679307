import { DatePipe, NgIf, TitleCasePipe } from "@angular/common";
import { Component, Output, EventEmitter, Input, inject } from "@angular/core";
import { ApiService } from "src/app/core";
import { PanelModule } from 'primeng/panel';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from "primeng/button";
import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { InputTextModule } from "primeng/inputtext";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from "@angular/forms";
import { AdminApiService } from "src/app/core/services/admin-api.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { YesNoPipe } from "src/app/shared/pipes/yesno.pipe";

@Component({
    selector: 'app-admin-user',
    templateUrl: './admin-user.component.html',
    standalone: true,
    providers: [MessageService, ConfirmationService],
    imports: [
        DatePipe,
        PanelModule,
        AvatarModule,
        ButtonModule,
        TableModule,
        InputTextModule,
        ReactiveFormsModule,
        ToastModule,
        TitleCasePipe,
        ConfirmDialogModule,
        YesNoPipe,
        NgIf
    ],
    styles: [`
    p-avatar{
        line-height: 0;
    }
    `]
})
export class AdminUserComponent {

    apiService = inject(ApiService);
    adminApiService = inject(AdminApiService);
    messageService = inject(MessageService);
    confirmationService = inject(ConfirmationService);

    @Output() impersonate: EventEmitter<void> = new EventEmitter();
    @Output() userDelete: EventEmitter<void> = new EventEmitter();
    @Input() userId: number;

    user: any;
    saving: boolean = false;

    userForm = new FormGroup({
        username: new FormControl('', [Validators.required, Validators.minLength(4)]),
        displayName: new FormControl('', [Validators.required, Validators.minLength(4)]),
        email: new FormControl('', [Validators.required, Validators.email])
    });

    passwordForm = new FormGroup({
        password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });

    ngOnInit() {
        const query = `query AAAdminGetUser($id: Int!){
            user(id: $id) {
                id
                displayName
                username
                email
                role
                imageUrl
                provider
                verified
                lastActive
                lastLoggedIn
                createdAt
                apiKey{
                    key
                    token
                    createdAt
                }
                memberships{
                    role
                    status
                    createdAt
                    userId
                    projectId
                    project{
                        name
                        imageUrl
                        slug
                        organisation{
                            slug
                        }
                    }
                }
                organisationMemberships{
                    role
                    createdAt
                    organisation{
                        name
                        imageUrl
                        slug
                    }
                }
                usedFreeTrial
            }
        }`;

        this.apiService.graphql<{ user: { displayName: string; username: string; memberships: any[] } }>(query, { id: this.userId }).subscribe(response => {
            console.log(response);
            response.user.memberships = response.user.memberships.filter(m => m.project !== null);
            this.user = response.user;
            this.userForm.setValue({
                username: this.user.username,
                displayName: this.user.displayName,
                email: this.user.email
            });
        });

    }

    updateUser(input: any) {
        return this.adminApiService.graphql(`mutation AAAdminUpdateUser($input: AdminUserUpdateInput!){
            updateUser(input: $input){
                id
            }
        }`, {
            input: {
                id: this.user.id,
                ...input
            }
        });
    }

    saveDetails() {
        const user = this.userForm.value;
        this.saving = true;

        this.updateUser(user).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'User details updated' });
            this.userForm.markAsPristine();
            this.saving = false;
        });
    }

    savePassword() {
        this.saving = true;
        this.updateUser({ password: this.passwordForm.value.password }).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'User password updated' });
            this.passwordForm.reset();
            this.passwordForm.markAsPristine();
            this.saving = false;
        });
    }

    verifyUser() {
        this.updateUser({ verified: true }).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'User verified' });
            this.user.verified = true;
        });
    }

    deleteUser(event: Event) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this user? This cannot be undone.',
            target: event.target,
            header: 'Delete User',
            acceptIcon: 'pi pi-check mr-2',
            rejectIcon: 'pi pi-times mr-2',
            rejectButtonStyleClass: 'p-button',
            acceptButtonStyleClass: 'p-button-outlined p-button',
            accept: () => {
                const mutation = `mutation AAAdminDeleteUser($id: Int!){
                    deleteUser(input: { userId: $id})
                }`
                this.adminApiService.graphql(mutation, { id: this.user.id }).subscribe(() => {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'User deleted' });
                    this.userDelete.emit();
                });
            }
        });
    }

}