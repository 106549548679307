import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output, SimpleChanges, computed, effect, inject, signal } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { SvgIconComponent } from "angular-svg-icon";
import { MessageService } from "primeng/api";
import { AvatarModule } from "primeng/avatar";
import { BreadcrumbItemClickEvent, BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { DialogService } from 'primeng/dynamicdialog';
import { InputTextModule } from "primeng/inputtext";
import { ToastModule } from "primeng/toast";
import { map, filter, switchMap, catchError } from "rxjs/operators";
import { HomeEmptyComponent } from "./home-empty.component";
import { HomeFolderComponent } from "./home-folder.component";
import { HomeMoveComponent } from "./home-move.component";
import { HomeNewFolderComponent } from "./home-new-folder.component";
import { HomeProjectSetupComponent } from "./home-project-setup.component";
import { HomeProjectTemplatesComponent } from "./home-project-templates.component";
import { HomeProjectComponent } from "./home-project.component";
import { HomeToolbarComponent } from "./home-toolbar.component";
import { HomeItemRemoveComponent } from "./home-item-remove.component";
import { HomeProject, HomeProjectSetupResult, HomeProjectTemplate, OrganisationFolder, FavouriteEntity } from "./home.models";
import { HomeService } from "./home.service";
import { throwError } from "rxjs";

const modalWidth = 660;
const modalVHeight = 80;
const modalMaxHeight = 660;

const modalDimensions = {
    'width.px': modalWidth,
    'height.vh': modalVHeight,
    'maxHeight.px': modalMaxHeight
};
@Component({
    selector: 'app-home',
    standalone: true,
    templateUrl: './home.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ButtonModule,
        ReactiveFormsModule,
        InputTextModule,
        AvatarModule,
        HomeProjectComponent,
        BreadcrumbModule,
        DialogModule,
        HomeFolderComponent,
        HomeProjectTemplatesComponent,
        HomeMoveComponent,
        HomeEmptyComponent,
        HomeToolbarComponent,
        HomeItemRemoveComponent,
        NgClass,
        SvgIconComponent,
        ToastModule
    ],
    providers: [
        HomeService,
        DialogService,
        MessageService,
    ],
    styleUrls: ['./home.styles.scss'],
    styles: [`
    :host { @apply block w-full h-full overflow-hidden;}

    ul{
        box-shadow: 0px 3px 5px 0px rgba(41, 49, 52, 0.10);
    }
    `]
})
export class HomeComponent implements OnChanges {

    public homeService = inject(HomeService);
    private dialogService = inject(DialogService);
    private messageService = inject(MessageService);

    @Input() id: string;
    @Input() organisationId: number;
    @Input() userId: number;

    @Output() folder = new EventEmitter<string | null>();
    @Output() project = new EventEmitter<{
        project: HomeProject;
        state: string;
    }>();

    @HostListener('document:keydown', ['$event']) handleKeyDown(event: KeyboardEvent) {
        if (event.shiftKey) {
            this.shiftKeyDown = true;
        }
    }

    @HostListener('document:keyup', ['$event']) handleKeyUp(event: KeyboardEvent) {
        if (!event.shiftKey) {
            this.shiftKeyDown = false;
        }
    }

    folderForm = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.minLength(3)])
    });

    search = this.homeService.search;
    favourites = this.homeService.isFavouritesOnly;
    isOrgAdmin = this.homeService.isOrgAdmin;

    moveSelection = signal<(HomeProject | OrganisationFolder)[]>([]);
    selectionCount = this.homeService.selectionCount;
    shiftKeyDown = false;
    deletionItem: HomeProject | OrganisationFolder | null = null;
    isVisibleRemoveDialog: boolean = false;

    constructor() {
        effect(() => this.folder.emit(this.homeService.folderId()));
    }

    openFolder(folder: OrganisationFolder) {
        this.homeService.openFolder(folder);
    }

    openProject(project: HomeProject, state = 'dashboard') {
        this.project.emit({
            project,
            state: `project.${state}`
        });
    }

    private moveModal(cta: string, folderIds: string[] = []) {
        return this.dialogService.open(HomeMoveComponent, {
            header: `Move ${cta}`,
            style: modalDimensions,
            data: {
                rootNode: this.homeService.root(),
                currentLocation: this.homeService.current().node,
                folderIds,
                cta,
                organisationId: this.organisationId
            }
        });
    }

    moveProjectModal(project: HomeProject) {
        this.moveModal('Project').onClose.pipe(
            map((t: { id: string, isUpdate: boolean } | undefined) => {
                if (!t) return;
                if (t.isUpdate) this.homeService.loadOrganisation(this.organisationId);
                return t.id;
            }),
            filter((id: string | null) => !!id || id === null),
            switchMap((id: string) => this.homeService.moveItems(id, [project], []))
        ).subscribe(() => {
            this.toast('Project moved');
        });
    }

    moveFolderModal(folder: OrganisationFolder) {
        this.moveModal('Folder', [folder.id!]).onClose.pipe(
            map((t: { id: string, isUpdate: boolean } | undefined) => {
                if (!t) return;
                if (t.isUpdate) this.homeService.loadOrganisation(this.organisationId);
                return t.id;
            }),
            filter((id: string | null) => !!id || id === null),
            switchMap((id: string) => this.homeService.moveItems(id, [], [folder]))
        ).subscribe(() => {
            this.toast('Folder moved');
        });
    }

    moveMultipleModal() {

        // If we actually have 1 thing selected, just shortcut to the move modal
        if (this.homeService.selectionCount() === 1) {
            // Is it a project?
            const projects = this.homeService.selectedProjects();
            if (projects.length === 1) {
                return this.moveProjectModal(projects[0]);
            } else {
                return this.moveFolderModal(this.homeService.selectedFolders()[0]);
            }
        }

        const folderIds: string[] = this.homeService.selectedFolders().map(f => f.id).filter(f => f !== null) as string[];
        this.moveModal('Selected Items', folderIds).onClose.pipe(
            map((t: { id: string, isUpdate: boolean } | undefined) => {
                if (!t) return;
                if (t.isUpdate) this.homeService.loadOrganisation(this.organisationId);
                return t.id;
            }),
            filter((id: string | null) => !!id || id === null),
            switchMap((id: string) => this.homeService.moveSelectedItems(id))
        ).subscribe(() => {
            this.toast('Items moved');
        });
    }

    // deleteMultipleModal() {
    //     if (this.homeService.selectionCount() === 1) {
    //         // Is it a project?
    //         const projects = this.homeService.selectedProjects();
    //         if (projects.length === 1) {
    //             return this.deleteProject(projects[0]);
    //         } else {
    //             return this.deleteFolder(this.homeService.selectedFolders()[0]);
    //         }
    //     }

    //     const count = this.homeService.selectionCount();
    //     this.confirmationService.confirm({
    //         message: `Are you sure you want to delete ${count} items?`,
    //         accept: () => this.homeService.deleteSelectedItems().subscribe(() => {
    //             this.toast(`${count} items deleted`);
    //         })
    //     });
    // }


    selectFolder(isMulti: boolean, folder: OrganisationFolder) {
        this.homeService.selectFolder(folder, this.shiftKeyDown || isMulti);
    }

    selectProject(isMulti: boolean, project: HomeProject) {
        this.homeService.selectProject(project, this.shiftKeyDown || isMulti);
    }

    modifyFavourite(entities: (HomeProject | OrganisationFolder)[]) {
        const items = entities.map(({ id, type }) => ({ id, type }));
        const isAlreadyFavourite = entities.every(item => item.isFavourite);
        isAlreadyFavourite
            ? this.homeService.deleteFavourite(items).subscribe(() => this.toast('Removed from favourites'))
            : this.homeService.createFavourite(items).subscribe(() => this.toast('Added to favourites'));
        this.homeService.clearSelection();
    }

    deleteItem(item: HomeProject | OrganisationFolder) {
        this.deletionItem = item;
        this.isVisibleRemoveDialog = true;
    }

    onHandlingDeletionChange(event: { item: HomeProject | OrganisationFolder; isRemoved: boolean }) {
        this.isVisibleRemoveDialog = false;
        if (!event.isRemoved) return;

        const deleteObservable:Observable<any> = event.item.type === 'PROJECT'
            ? this.homeService.deleteProject(event.item.id!)
            : this.homeService.deleteFolder(event.item.id!);
    
        deleteObservable.subscribe(() => this.toast(`${event.item.type === 'PROJECT' ? 'Project' : 'Folder'} deleted`));
    }

    isProjectSelected(project: HomeProject) {
        return this.homeService.isProjectSelected(project);
    }

    isFolderSelected(folder: OrganisationFolder) {
        return this.homeService.isFolderSelected(folder);
    }

    moveProjectToFolder(project: HomeProject, folder: OrganisationFolder) {
        this.homeService.moveItems(folder.id!, [project], []).subscribe(t => {
            this.toast('Project moved');
        });
    }

    moveFolderToFolder(folder: OrganisationFolder, parent: OrganisationFolder) {
        this.homeService.moveItems(parent.id!, [], [folder]).subscribe(t => {
            this.toast('Folder moved');
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (('organisationId' in changes) && changes['organisationId'].currentValue !== null) {
            this.homeService.loadOrganisation(this.organisationId);
        }
        if ('id' in changes) {
            this.homeService.folderId.set(this.id ?? null);
        }
    }

    breadcrumbClick(event: BreadcrumbItemClickEvent) {
        if(!event.item.id) return;
        this.homeService.folderId.set(event.item.id);
    }

    openProjectTemplates() {
        this.dialogService.open(HomeProjectTemplatesComponent, {
            header: 'Project Templates',
            width: '80vw',
            height: '80vh',
            contentStyle: {
                'padding': '0px',
            }
        }).onClose.pipe(
            filter((t): t is HomeProjectTemplate => !!t),
            switchMap(r => {
                return this.projectSetupModal(r.name + ' - Setup', { template: r }).pipe(
                    switchMap((result: HomeProjectSetupResult) => {
                        return this.homeService.cloneProjectTemplate(r.projectId, result.name, result.description, result.memberships);
                    })
                )
            }),
            catchError(() => {
                this.toastError('Project Template Clone Failed', 'Please try again later');
                return throwError(() => new Error('Failed to clone project template'));
            })
        ).subscribe({
            next: () => {
                this.toast('Project Template Cloned');
            }
        });
    }

    newProject() {
        this.projectSetupModal('New Project', {}).pipe(
            switchMap((result: HomeProjectSetupResult) => {
                return this.homeService.createProject(result.name, result.description, result.memberships);
            })).subscribe(() => {
                this.toast('Project created');
            });

    }


    cloneProject(project: HomeProject) {
        this.projectSetupModal('Clone Project', { project }).pipe(
            switchMap((result: HomeProjectSetupResult) => {
                return this.homeService.cloneProject(project.id, result.name, result.description, result.memberships);
            }),
            catchError(() => {
                this.toastError('Project Clone Failed', 'Please try again later');
                return throwError(() => new Error('Failed to clone project'));
            })
        ).subscribe({
            next: () => {
                this.toast('Project Cloned');
            }
        });
    }

    private projectSetupModal(header: string, data: { project?: HomeProject, template?: HomeProjectTemplate } = {}) {
        const ref = this.dialogService.open(HomeProjectSetupComponent, {
            header,
            style: modalDimensions,
            contentStyle: {
                'padding': '0px'
            },
            data: {
                ...data,
                userId: this.userId
            }
        });

        return ref.onClose.pipe(filter((t): t is HomeProjectSetupResult => !!t));
    }

    private openCreateFolderModel(folder?: OrganisationFolder) {
        const ref = this.dialogService.open(HomeNewFolderComponent, {
            width: '30vw',
            contentStyle: {
                'padding': '0px',
            },
            header: folder ? 'Rename Folder' : 'New Folder',
            data: {
                name: folder?.name,
                cta: folder ? 'Rename' : 'Create'
            }
        });

        return ref.onClose.pipe(
            filter(t => !!t)
        );
    }

    openNewFolderModal() {
        this.openCreateFolderModel().pipe(
            switchMap((r: { name: string; }) => {
                return this.homeService.createFolder(r.name, this.homeService.currentFolderId()!);
            })).subscribe(() => {
                this.toast('Folder created');
            });
    }

    openRenameFolderModal(folder: OrganisationFolder) {
        this.openCreateFolderModel(folder).pipe(
            switchMap((r: { name: string; }) => {
                return this.homeService.renameFolder(folder.id!, r.name);
            })).subscribe(() => {
                this.toast('Folder renamed');
            });
    }

    up() {
        this.homeService.clearSelection();
        const path = this.homeService.current().path;
        if (path.length > 0) {
            const parent = path[path.length - 1];
            this.homeService.folderId.set(parent.id);
        }
    }

    private toast(detail: string) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail });
    }

    private toastError(summary: string, detail: string) {
        this.messageService.add({ severity: 'error', summary, detail });
    }
}