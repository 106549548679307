import { Component, computed, inject, input, SecurityContext } from '@angular/core';
import { Question } from '../form.types';
import * as marked from 'marked';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-form-text-block',
    templateUrl: 'form-text-block.component.html',
    standalone: true
})

export class FormTextBlockComponent {

    sanitizer = inject(DomSanitizer);

    question = input.required<Question>();

    content = computed(() => {
        const question = this.question();
        const markedContent = marked.parse(question.text);
        return this.sanitizer.sanitize(SecurityContext.HTML, markedContent);
    });
}