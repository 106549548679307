import { Component, ElementRef, input, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, catchError, map, of, take, tap } from 'rxjs';
import { ApiService } from 'src/app/core';
import { RecordDetailUser, RecordDetailViewer } from '../record-detail.component';
import { CommonModule } from '@angular/common';
import { DateToNowPipe } from 'src/app/shared/pipes/date-to-now.pipe';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { addIcons } from "ionicons";

interface RecordCommentsResponse {
    user: RecordDetailUser;
    comments: RecordComment[];
}

interface RecordComment {
    id: number;
    text: string;
    createdAt: string;
    user: RecordDetailUser;
}

@Component({
    selector: 'app-record-comments',
    templateUrl: 'record-comments.component.html',
    standalone: true,
    imports: [
        CommonModule,
        DateToNowPipe,
        AvatarModule,
        ButtonModule,
        InputTextareaModule
    ]
})

export class RecordCommentsComponent implements OnInit, OnDestroy {

    @Input() recordId!: number;
    @Input() projectId!: number;
    viewer = input.required<RecordDetailViewer>();

    @ViewChild('textArea', { static: false }) textArea: ElementRef; 

    public user: RecordDetailUser;
    public comments$: Observable<RecordComment[]>
    public commenting: boolean = false;

    private postCommentSub: Subscription;

    constructor(
        private apiService: ApiService
    ) { }

    ngOnInit() {
        this.getComments();
    }

    ngOnDestroy(): void {
        if (this.postCommentSub) {
            this.postCommentSub.unsubscribe();
        }
    }

    private getComments() {
        const query = `query getRecordComments($id: Int){
            record(id: $id){
                user {
                    id
                    displayName
                    username
                    imageUrl
                }
                comments(order:"reverse:createdAt"){
                    id
                    text
                    createdAt
                    user {
                        id
                        displayName
                        username
                        imageUrl
                    }
                }
            }
        }`;

        this.comments$ = this.apiService.graphql<{ record: RecordCommentsResponse }>(query, { id: this.recordId }).pipe(
            take(1),
            tap(res => {this.user = res.record.user; console.log(res)}),
            map(res => res.record.comments)
        );
    }

    public addComment() {
        this.commenting = true;
        setTimeout(() => {
            this.textArea.nativeElement.focus();
        }, 500);
    }

    public cancelComment() {
        this.commenting = false;
    }

    public postComment() {
        if (this.postCommentSub) {
            this.postCommentSub.unsubscribe();
        }

        const value: string = this.textArea.nativeElement.value;
        
        const query = `mutation createComment($input: CreateCommentInput!){
            createComment(input: $input){
                id
            }
        }`;

        this.postCommentSub = this.apiService.graphql<{ createComment: { id: number } }>(query, {
            input: {
                recordId: this.recordId,
                projectId: this.projectId,
                comment: value
            }
        }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.getComments();
                this.commenting = false;
            }
        });
    }
}