export default class ProjectFormsCollectionDirective {
    static $inject = ['$timeout', 'toastr'];
    constructor($timeout, toastr) {
        this.$timeout = $timeout;
        this.toastr = toastr;
        this.restrict = 'E';
        this.scope = {
            collection: '='
        };
        this.template = require('!raw-loader!./project-forms-collection.directive.html').default;
    }

    link(scope, element) {
        scope.sortableConfig = {
            axis: 'y',
            containment: 'parent',
            handle: '.form-collection-grip'
        };

        scope.updateItemOrder = function () {
            var updateItems = [];
            for (var i = 0; i < scope.collection.items.length; i++) {
                if (scope.collection.items[i].sort !== i) {
                    scope.collection.items[i].sort = i;
                    updateItems.push({
                        id: scope.collection.items[i].id,
                        sort: i
                    });
                }
            }
        };

        scope.removeItem = function (index) {
            scope.collection.items.splice(index, 1);
        };

        scope.addItem = () => {
            scope.collection.items.push({
                id: new Date().valueOf() * -1,
                value: ''
            });

            this.$timeout(() => {
                element[0].querySelector('.form-collection-item:last-of-type input').focus();
            });
        };

        scope.keyPress = function (event) {
            if (event.key === 'Enter') {
                scope.addItem();
                event.preventDefault();
            }
        };
    }
}