export default class ProjectDependencyErrorService {
    static $inject = ['$mdDialog'];
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    display(error) {
        if (error.code !== 'ModelDependencyError') {
            return;
        }
        this.$mdDialog.show({
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            template: require('!raw-loader!./project-dependency-error.modal.html').default,
            controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
                $scope.error = error;
                $scope.ok = function () {
                    $mdDialog.hide();
                };
            }]
        }).then(angular.noop);
    };
}
ProjectDependencyErrorService.$inject = ['$mdDialog'];
