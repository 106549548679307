import { getOrganisationMemberships, getOrganisationMembership } from "../../store/selectors";

export default class OrganisationMembersController {

    static $inject = ['$scope', '$ngRedux', '$timeout', '$mdDialog', '$stateParams', '$state', 'organisation', 'Tabulator', 'OrganisationsActions', 'projects'];
    constructor($scope, $ngRedux, $timeout, $mdDialog, $stateParams, $state, organisation, Tabulator, OrganisationsActions, projects) {

        this.$ngRedux = $ngRedux;
        this.$mdDialog = $mdDialog;
        this.Tabulator = Tabulator;
        this.$timeout = $timeout;
        this.OrganisationsActions = OrganisationsActions;
        this.organisation = organisation;
        this.$stateParams = $stateParams;
        this.$state = $state;

        this.activeMembership = null;
        this.activeSegment = $stateParams.state ? $stateParams.state : 'members';
        this.total = 0;
        this.activeMembers = [];
        this.memberships = { members: [], pending: [] };
        this.membershipLoad = null;

        this.projects = projects;

        $scope.$on('$destroy', $ngRedux.connect((state) => {
            const memberships = getOrganisationMemberships(state);
            const membership = getOrganisationMembership(state);
            const members = memberships.filter(m => m.user);
            const pending = memberships.filter(m => !m.user);

            return {
                total: memberships.length,
                activeMembership: this.activeSegment === 'members' && angular.copy(membership),
                hasFreeSeats: memberships.length < organisation.seats,
                memberships: {
                    members,
                    pending
                }
            };
        }, null)(this));
    }


    $onInit() {
        this.activeSegment === 'members' ? this.initMembersTable() : this.initPendingTable();
    }

    initMembersTable() {
        this.table = this.Tabulator.createTable('#organisation-members-table', {
            layout: "fitColumns",
            height: "100%",
            index: 'userId',
            paginationSize: 10,
            selectable: 1,
            data: angular.copy(this.memberships.members),
            paginationSizeSelector: true, //enable page size select element and generate list options
            columns: [
                { title: 'Name', field: 'name', headerFilter: true, headerFilterParams: { clearable: true }, mutator: (_v, data) => data.user.displayName },
                { title: 'Username', field: 'username', headerFilterParams: { clearable: true }, headerFilter: true, mutator: (_v, data) => data.user.username },
                {
                    title: 'Role',
                    field: 'role',
                    headerFilter: "list",
                    headerFilterParams: {
                        values: {
                            "member": "Member",
                            "admin": "Admin",
                            "owner": "Owner"
                        },
                        clearable: true,
                    },
                    formatter: "lookup",
                    formatterParams: {
                        "member": "Member",
                        "admin": "Admin",
                        "owner": "Owner"
                    }
                },
                {
                    title: 'Email',
                    field: 'email',
                    headerFilter: true,
                    headerFilterParams: { clearable: true },
                    mutator: (_value, data) => data.user.email
                },
            ],
            initialHeaderFilter: [
                { field: 'name', value: this.$stateParams.name },
                { field: 'username', value: this.$stateParams.username },
                { field: 'role', value: this.$stateParams.role },
                { field: 'email', value: this.$stateParams.email },
            ]
        });
        this.table.on('rowClick', (e, row) => {
            this.$timeout(() => this.selectMember(row.getData()), 0);
        });

        this.table.on('tableBuilt', () => {
            this.table.on('dataFiltered', () => {
                const params = {};
                for (const header of (['name', 'username', 'role', 'email'])) {
                    params[header] = this.table.getHeaderFilterValue(header);
                }
                this.$state.go('.', params);
            });
        });
    }

    initPendingTable() {
        this.pendingTable = this.Tabulator.createTable('#organisation-pending-table', {
            layout: "fitColumns",
            height: "100%",
            selectable: 1,
            paginationSize: 10,
            index: 'pendingEmail',
            data: angular.copy(this.memberships.pending),
            paginationSizeSelector: true, //enable page size select element and generate list options
            columns: [
                { title: 'Email', field: 'pendingEmail', headerFilter: false },
                { title: 'Role', field: 'role', headerFilter: false },
                { title: 'Inivted Date', field: 'createdAt', headerFilter: false, formatter: cell => new Date(cell.getValue()).toLocaleString() },
            ]
        });

        this.pendingTable.on('rowClick', (e, row) => {
            console.log(row);
            this.$timeout(() => this.selectPendingMember(row.getData()), 0);
        });
    }

    switchSegment(segment) {
        this.activeSegment = segment;
        this.activeMembership = null;
        this.pendingMembership = null;
        if (this.activeSegment === 'pending') {
            this.$state.go('.', { state: 'pending' });
            if (!this.pendingTable) {
                this.$timeout(() => this.initPendingTable(), 0);
            }
        }
        if (this.activeSegment === 'members') {
            this.$state.go('.', { state: null });
            if (!this.table) {
                this.$timeout(() => this.initMembersTable(), 0);
            }
        }
    }

    selectMember(membership) {
        if (membership.user && membership.user.id) {
            if (this.membershipLoad) {
                this.membershipLoad();
            }
            this.membershipLoad = this.$ngRedux.dispatch(this.OrganisationsActions.loadOrganisationMembership(membership.user.id));
            this.pendingMembership = null;
            this.pendingMembershipProjects = [];
            if (this.pendingTable) {
                this.pendingTable.deselectRow();
            }
        }
    }

    selectPendingMember(pending) {
        this.activeMembership = null;
        this.pendingMembership = pending;
        if (this.table) {
            this.table.deselectRow();
        }
        this.pendingMembershipProjects = Object.entries(pending.pendingProjectMap || {}).map(([projectId, role]) => {
            const project = this.projects.find(p => p.id === +projectId);
            return {
                role,
                project
            }
        });
    }

    resendPending() {
        this.$ngRedux.dispatch(this.OrganisationsActions.resendPendingOrganisationInvite(this.pendingMembership.pendingEmail));
    }

    manageProjects(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            template: require('!raw-loader!./organisation-manage-member-projects.modal.html').default,
            controllerAs: 'ctrl',
            hasBackdrop: true,
            resolve: {
                projects: () => this.projects,
                pendingMembershipProjects: () => this.pendingMembershipProjects,
                member: () => this.activeMembership,
                $ngRedux: () => this.$ngRedux,
                OrganisationsActions: () => this.OrganisationsActions,
                pendingMembership: () => this.pendingMembership,
            },
            controller: class OrganisationManageMemberProjectsController {
                static $inject = ['$mdDialog', 'projects', 'pendingMembershipProjects', 'member', '$ngRedux', 'OrganisationsActions', 'pendingMembership'];
                constructor($mdDialog, projects, pendingMembershipProjects, member, $ngRedux, OrganisationsActions, pendingMembership) {
                    this.$mdDialog = $mdDialog;
                    this.$ngRedux = $ngRedux;
                    this.OrganisationsActions = OrganisationsActions;
                    this.member = member;
                    this.pendingMembership = pendingMembership;
                    this.pendingMembershipProjects = pendingMembershipProjects;
                    this.projects = projects;

                    this.projectRoles = {};

                    this.projects.forEach(p => {
                        const membership = this.member ? this.member.projectMemberships.find(({ project }) => p.id === project.id) : pendingMembershipProjects.find(({ project }) => p.id === project.id);

                        if (membership) {
                            this.projectRoles[p.id] = membership.role;
                        }
                    });
                }

                async updatePendingUserProjects(role, project) {
                    const projectExistsOnPendingMember = this.pendingMembershipProjects.find(memberships => memberships.project.id === project.id);

                    if ((!role || role === '') && projectExistsOnPendingMember) {
                        // Remove the project if it exists in the users membership already
                        await this.$ngRedux.dispatch(this.OrganisationsActions.removePendingOrganisationUserProjectMembership(this.pendingMembership.id, project.id, role));
                    } else if (projectExistsOnPendingMember) {
                        // Check if the user membership exists and update if so
                        await this.$ngRedux.dispatch(this.OrganisationsActions.updatePendingOrganisationUserProjectMembership(this.pendingMembership.id, project.id, role));
                    } else {
                        // Otherwise it's a new project that needs adding
                        await this.$ngRedux.dispatch(this.OrganisationsActions.addPendingOrganisationUserToProject(this.pendingMembership.id, project.id, role));
                    }

                }

                async updateProjects(role, project) {
                    this.member ? null : this.updatePendingUserProjects(role, project);
                    //update project roles on confirmation, not during selection (as is the case for pending users)
                }

                async removeProject(id) {
                    await this.$ngRedux.dispatch(this.OrganisationsActions.removeOrganisationProjectMembership(this.member.userId, id));
                }

                confirm() {
                    const memberships = [];
                    for (const [projectId, role] of Object.entries(this.projectRoles)) {
                        memberships.push({
                            projectId,
                            role: !!role ? role : undefined // removed users have empty string as a role - clean this up
                        })
                    }
                    this.$mdDialog.hide({
                        memberships,
                        userId: this.member.userId,
                    });
                }

                cancel() {
                    this.$mdDialog.cancel();
                }
            }
        }).then(({ userId, memberships }) => {
            this.$ngRedux.dispatch(this.OrganisationsActions.updateOrganisationUserProjectMemberships(userId, memberships));
        }, angular.noop);
    }

    updateMemberRole() {
        this.$ngRedux.dispatch(this.OrganisationsActions.updateOrganisationMembership(this.activeMembership.user.id, this.activeMembership.role)).then(result => {
            this.table.updateData([result]);
        });
    }

    removeMember(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('!raw-loader!./organisation-remove-member.modal.html').default,
            controllerAs: 'ctrl',
            controller: class OrganisationRemoveMemberController {
                static $inject = ['$mdDialog'];
                constructor($mdDialog) {
                    this.$mdDialog = $mdDialog;
                }

                ok() {
                    this.$mdDialog.hide();
                }

                cancel() {
                    this.$mdDialog.cancel();
                }
            }
        }).then(() => {
            const userId = this.activeMembership.user.id;
            const action = this.OrganisationsActions.removeOrganisationMembership(userId);
            this.$ngRedux.dispatch(action).then(() => {
                this.table.deleteRow(userId);
            });
        }, angular.noop);
    }


    async removeProject(id) {
        await this.$ngRedux.dispatch(this.OrganisationsActions.removeOrganisationProjectMembership(this.member.userId, id));
    }

    addMember(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            template: require('!raw-loader!./organisation-invite-member.modal.html').default,
            controllerAs: 'ctrl',
            hasBackdrop: true,
            resolve: {
                projects: () => this.projects,
                pending: () => this.memberships.pending.map(p => p.pendingEmail)
            },
            controller: class OrganisationAddMemberController {
                static $inject = ['$mdDialog', 'projects', 'pending'];
                constructor($mdDialog, projects, pending) {
                    this.$mdDialog = $mdDialog;
                    this.userAvatar = '../../assets/images/user.svg';
                    this.title = 'Invite Member';
                    this.action = 'Next';
                    this.step = 1;
                    this.projects = projects;
                    this.availableProjects = angular.copy(projects);
                    this.member = {
                        name: null,
                        email: null,
                        role: this.projects && this.projects.length ? 'member' : 'admin',
                        projects: []
                    };
                    this.alreadyInvited = false;
                    this.pending = pending;
                    this.projectRoles = {};

                    this.project = null;
                }

                ok() {
                    if (this.step === 1) {
                        // Let's check we can move on
                        if (this.pending.indexOf(this.member.email) !== -1) {
                            this.alreadyInvited = true;
                            return;
                        }

                        this.alreadyInvited = false;
                        this.step = 2;
                        this.title = 'Select Organisation Role';
                        this.action = this.projects.length === 0 ? 'Send Invite' : 'Next';
                    } else if (this.step === 2 && this.projects.length > 0) {
                        this.step = 3;
                        this.title = 'Add to Projects?';
                        this.action = 'Send Invite';
                    } else {
                        this.member.projects = this.member.projects.map(p => ({ id: p.id, role: p.role }));
                        this.$mdDialog.hide(this.member);
                    }
                }

                addProject(role, project) {
                    if (role === '') {
                        // If there is no role selected, remove this project
                        this.member.projects = this.member.projects.filter(p => p.id !== project.id);
                    } else {
                        const idx = this.member.projects.findIndex(p => p.id === project.id);
                        if (idx === -1) {
                            this.member.projects.push({
                                id: project.id,
                                name: project.name,
                                role,
                            });
                        } else {
                            this.member.projects = [
                                ...this.member.projects.slice(0, idx),
                                {
                                    ...this.member.projects[idx],
                                    role
                                },
                                ...this.member.projects.slice(idx + 1)
                            ];
                        }
                    }
                    this.project = null;
                }

                cancel() {
                    this.$mdDialog.cancel();
                }

                back() {
                    this.step--;
                }

                setRole(role) {
                    this.member.role = role;
                }
            }
        }).then((result) => {
            this.$ngRedux.dispatch(this.OrganisationsActions.inviteOrganisationMember(result.email, result.role, result.projects)).then((pendingMember) => {
                this.switchSegment('pending');
                this.pendingTable.addData([pendingMember], true);
                this.pendingTable.selectRow(pendingMember.pendingEmail);
                this.selectPendingMember(pendingMember);
            }, err => {
                console.log('HERE?!', err);
            });
        }, angular.noop);
    }

    updateMemberRole() {
        console.log('Updating', this.activeMembership);
        this.$ngRedux.dispatch(this.OrganisationsActions.updateOrganisationMembership(this.activeMembership.user.id, this.activeMembership.role)).then(result => {
            this.table.updateData([result]);
        });
    }

    removeMember(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('!raw-loader!./organisation-remove-member.modal.html').default,
            controllerAs: 'ctrl',
            controller: class OrganisationRemoveMemberController {
                static $inject = ['$mdDialog'];
                constructor($mdDialog) {
                    this.$mdDialog = $mdDialog;
                }

                ok() {
                    this.$mdDialog.hide();
                }

                cancel() {
                    this.$mdDialog.cancel();
                }
            }
        }).then(() => {
            const userId = this.activeMembership.user.id;
            const action = this.OrganisationsActions.removeOrganisationMembership(userId);
            this.$ngRedux.dispatch(action).then(() => {
                this.table.deleteRow(userId);
            });
        }, angular.noop);
    }

    cancelPending(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('!raw-loader!./organisation-cancel-invite.modal.html').default,
            controllerAs: 'ctrl',
            controller: class OrganisationCancelInviteController {
                static $inject = ['$mdDialog'];
                constructor($mdDialog) {
                    this.$mdDialog = $mdDialog;
                }

                ok() {
                    this.$mdDialog.hide();
                }

                cancel() {
                    this.$mdDialog.cancel();
                }
            }
        }).then(() => {
            this.$ngRedux.dispatch(this.OrganisationsActions.cancelPendingOrganisationInvite(this.pendingMembership.pendingEmail)).then(() => {
                this.pendingTable.deleteRow(this.pendingMembership.pendingEmail);
                this.pendingMembership = null;
            });
        }, angular.noop);
    }

    buySeats(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('!raw-loader!./organisation-buy-seats.modal.html').default,
            controllerAs: 'ctrl',
            controller: class OrganisationBuySeatsController {
                static $inject = ['$mdDialog'];
                constructor($mdDialog) {
                    this.$mdDialog = $mdDialog;
                }

                close() {
                    this.$mdDialog.hide();
                }
            }
        }).then(angular.noop, angular.noop);
    }
}
