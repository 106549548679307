import { Component, EventEmitter, Input, OnInit, Output, signal, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { RecordUpdateData, RecordsService } from '../../records.service';
import { RecordFormComponent } from '../../record-form/record-form.component';
import { catchError, of, take } from 'rxjs';
import { RecordDetailData } from '../record-detail.component';
import { RecordSummaryComponent } from './record-summary.component';

@Component({
    selector: 'app-record-data',
    templateUrl: 'record-data.component.html',
    standalone: true,
    imports: [
        ButtonModule,
        RecordFormComponent,
        RecordSummaryComponent
    ]
})

export class RecordDataComponent implements OnInit {

    @Input() recordData!: RecordDetailData;
    @Input() canEdit: boolean = false;

    @Output() recordUpdated: EventEmitter<void> = new EventEmitter();
    @Output() record: EventEmitter<any> = new EventEmitter();

    @ViewChild('recordForm', { static: false }) recordForm: RecordFormComponent;
    @ViewChild('recordSummary', { static: false }) recordSummary: RecordSummaryComponent;

    submitting = signal(false);

    public editData: boolean = false;

    constructor(
        private confirmationService: ConfirmationService,
        private recordsService: RecordsService,
    ) { }

    ngOnInit() { }


    public startEditData() {
        this.editData = true;
    }

    public cancelEditData() {
        this.editData = false;
    }

    public finishEditData($event: Event) {
        Object.keys(this.recordForm.form.controls).forEach(key => {
            /** force highlight errors if controls haven't been touched */
            this.recordForm.form.controls[key].markAsDirty();
        });
        const isValid: boolean = this.recordForm.form.valid;
        if (isValid) {
            this.updateData();
        } else {
            this.confirmationService.confirm({
                target: $event.target as EventTarget,
                message: 'Please fill in all mandatory fields to save your data.<br>Look for red text below fields to identify missing information.',
                header: 'Complete All Required Fields',
                acceptIcon: 'none',
                acceptLabel: 'Ok',
                accept: () => {
                },
                rejectVisible: false,
                
            });  
        }
    }
    
    private updateData() {
        this.submitting.set(true);
        const recordUpdateData: RecordUpdateData = this.recordForm.formRecordUpdateData;
        this.recordsService.updateRecordData(recordUpdateData).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {    
            if (!!res) {
                this.submitting.set(false);
                this.editData = false;
                this.recordUpdated.emit();
            }
        });
    }

    public refresh() {
        /** Add check in case the summary is in edit mode */
        if (!!this.recordSummary) {
            this.recordSummary.refresh();
        }
    }
}