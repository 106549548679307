import { Component, computed, ElementRef, inject, Input, OnDestroy, OnInit, signal, ViewChild, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import mapboxgl, { FullscreenControl, LngLatBoundsLike, LngLatLike, Map as MapboxMap } from "mapbox-gl";
import bbox from '@turf/bbox';
import { addBoundsLayer, addFeatureLayers, moveRecordsLayer, removeBoundsLayer, removeRecordsLayers } from '../../records.map.utils';
import { ApiService } from 'src/app/core';
import { Feature, Geometry, MultiPolygon, Polygon, Position } from 'geojson';
import { Subscription, map, take } from 'rxjs';
import MapboxGIS from '@natural-apptitude/coreo-mapbox';
import { CoreoGeometryType } from '@natural-apptitude/coreo-mapbox/dist/types';
import { MAP_MENU_LAYERS_CHANGED_EVENT, MAP_MENU_STYLE_CHANGED_EVENT, MapsService } from 'src/app/core/services/maps.service';

export type ConfigGeometryTypes = 'polygon' | 'linestring' | 'point' | 'multipoint' | 'multilinestring' | 'multipolygon';

const geometryTypeMap = {
    polygon: 'Polygon',
    linestring: 'LineString',
    point: 'Point',
    multipoint: 'MultiPoint',
    multilinestring: 'MultiLineString',
    multipolygon: 'MultiPolygon'
}

@Component({
    selector: 'app-record-map',
    templateUrl: 'record-map.component.html',
    standalone: true
})

export class RecordMapComponent implements OnInit, OnDestroy {

    viewContainerRef = inject<ViewContainerRef>(ViewContainerRef);
    mapsService = inject(MapsService);

    @Input() recordId: number;
    @Input() showFullscreen: boolean = true;
    @Input() replace: { op: string; path: string; value: number }[];

    @ViewChild('recordDetailMap', { static: true }) mapDiv: ElementRef;

    private map: MapboxMap;
    private mapboxGIS: MapboxGIS;
    private feature: Feature;
    private geometrySubscription: Subscription;

    public touched: boolean = false; // Whether the GIS tools have been used
    public updatedGeometry: Geometry;

    private resizeObserver: ResizeObserver;

    styleLoaded = signal(false);
    // currentStyle = computed<string>(() => this.isSatellite() ? 'mapbox://styles/mapbox/satellite-v9' : 'mapbox://styles/mapbox/streets-v12');
    editing = signal(false);

    constructor(
        private apiService: ApiService
    ) { }

    ngOnInit() {
        if (this.recordId) {
            this.getGeometry();
        } else {
            this.initMap();
        }
    }

    ngOnDestroy(): void {
        this.geometrySubscription?.unsubscribe();
        this.resizeObserver?.disconnect();
    }

    private replaceCoordinates() {
        const { type } = this.feature.geometry;
        /** Have to add a check in order to access the coordinates property */
        if (
            type === 'LineString' ||
            type === 'MultiLineString' ||
            type === 'MultiPoint' ||
            type === 'MultiPolygon' ||
            type === 'Point' ||
            type === 'Polygon'
        ) {
            const coords = [...this.feature.geometry.coordinates];

            const replacementData: { indexes: number[], value: number }[] = this.replace.map(r => {
                const indexes: number[] = r.path.replaceAll('geometry', '')
                    .replaceAll('coordinates', '')
                    .replaceAll('/', '')
                    .split('')
                    .map(i => +i);

                const value = r.value;

                return {
                    indexes,
                    value
                }
            });

            function setNewValue(arr, indexes, value) {
                indexes.reduce((r, e, i, a) => {
                    const last = i === a.length - 1;

                    if (last) {
                        if (Array.isArray(r)) {
                            r[e] = value
                        }
                    }

                    return r[e]
                }, arr);
            }

            replacementData.forEach((r) => {
                setNewValue(coords, r.indexes, r.value);
            });

            this.feature = {
                ...this.feature,
                geometry: {
                    ...this.feature.geometry,
                    coordinates: coords as any
                }
            }

        }
    }

    public getGeometry(update: boolean = false) {
        if (this.geometrySubscription) {
            this.geometrySubscription.unsubscribe();
        }

        const query = `query getCoreoRecordData{
            record(id: ${this.recordId}){
                geometry{
                    type
                    coordinates
                }
            }
        }`;

        this.geometrySubscription = this.apiService.graphql<{ record: { geometry: Geometry } }>(query).pipe(
            map(res => res.record.geometry)
        ).pipe(take(1)).subscribe(geometry => {
            this.feature = { type: 'Feature', geometry, properties: {} };
            if (!!this.replace) {
                this.replaceCoordinates();
            }
            if (update) {
                this.updateFeature(this.feature);
            } else {
                this.initMap(this.feature);
            }
        });
    }

    private initMap(feature: Feature = null) {
        const mapOptions: mapboxgl.MapboxOptions = {
            accessToken: environment.mapboxApiKey,
            container: this.mapDiv.nativeElement,
            style: 'mapbox://styles/mapbox/streets-v12',
            projection: { name: 'mercator' },
        };

        if (feature) {
            if (feature.geometry.type === "Point") {
                mapOptions.center = feature.geometry.coordinates as LngLatLike;
                mapOptions.zoom = 16;
            } else {
                const bounds = bbox(feature.geometry);
                mapOptions.bounds = bounds as LngLatBoundsLike;
                mapOptions.fitBoundsOptions = { padding: 20 };
            }
        } else {
            const defaultBounds = [
                2.0153808593734936, 56.6877748258257,
                -7.0153808593762506, 47.45206245445874
            ];
            mapOptions.bounds = defaultBounds as LngLatBoundsLike;
            mapOptions.fitBoundsOptions = { padding: 20 };
        }

        this.map = new MapboxMap(mapOptions);
        this.mapsService.attachMapMenuControl(this.map, this.viewContainerRef, {
            position: 'top-left'
        });
        if (this.showFullscreen) {
            this.map.addControl(new FullscreenControl());
        }

        this.map.on(MAP_MENU_STYLE_CHANGED_EVENT, () => {
            if (this.editing()) {
                if (this.map.hasControl(this.mapboxGIS)) {
                    this.map.removeControl(this.mapboxGIS);
                }
                this.map.addControl(this.mapboxGIS);
            } else {
                addFeatureLayers(this.map, [this.feature]);
            }
            this.styleLoaded.set(true);
        });

        this.map.on(MAP_MENU_LAYERS_CHANGED_EVENT, () => {
            if (this.editing()) {
                this.mapboxGIS.moveToTop();
            } else {
                moveRecordsLayer(this.map);
            }
        });


        this.resizeObserver = new ResizeObserver(() => this.map.resize());
        this.resizeObserver.observe(this.mapDiv.nativeElement);
    }

    private updateFeature(feature: Feature) {
        this.touched = false;

        if (this.map && this.mapboxGIS) {
            this.map.removeControl(this.mapboxGIS);
        }

        addFeatureLayers(this.map, [feature]);

        if (feature.geometry.type === "Point") {
            this.map.flyTo({
                center: feature.geometry.coordinates as LngLatLike,
                zoom: 16,
            });
        } else {
            const bounds = bbox(feature.geometry);
            this.map.fitBounds(bounds as LngLatBoundsLike, {
                padding: 20
            });
        }
    }

    public startEdit(geometryTypes: ConfigGeometryTypes[], enforceBounds: boolean = false, projectBounds: any = null) {
        removeRecordsLayers(this.map);

        if (enforceBounds) {
            // draw bounds on map
            addBoundsLayer(this.map, projectBounds);
        }

        const allowedGeometryTypes = geometryTypes.map(type => geometryTypeMap[type] as CoreoGeometryType);
        let geometryType: CoreoGeometryType = 'Point';
        if (this.feature) {
            geometryType = this.feature.geometry.type as CoreoGeometryType
        }

        import('@natural-apptitude/coreo-mapbox').then(({ default: MapboxGIS }) => {
            this.mapboxGIS = new MapboxGIS({
                geometryType,
                feature: this.feature,
                bounds: (enforceBounds && !!projectBounds) ? projectBounds as (Polygon | MultiPolygon | Polygon[]) : null,
                modes: ['create', 'update', 'delete'],
                mode: 'update',
                allowedGeometryTypes,
                typeControl: true,
                toolbarControl: false,
                interface: 'desktop',
                logging: false
            });


            this.mapboxGIS.on('geometry', (e) => {
                // console.log('mapboxGIS', e);
                this.touched = true;
                this.updatedGeometry = e?.geometry ?? null;

            });

            this.map.addControl(this.mapboxGIS);
            this.editing.set(true);
        });
    }

    public cancelEdit() {
        this.touched = false;
        this.map.removeControl(this.mapboxGIS);
        removeBoundsLayer(this.map);
        addFeatureLayers(this.map, [this.feature]);
        this.editing.set(false);
    }
}