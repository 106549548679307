
import {
    LOAD_ORGANISATIONS,
    LOAD_ORGANISATIONS_SUCCESS,
    LOAD_ORGANISATION_SUCCESS,
    ORGANISATION_UPDATE_SUCCESS,
    LOAD_ORGANISATION_MEMBERSHIPS_SUCCESS,
    LOAD_ORGANISATION_MEMBERSHIP_SUCCESS,
    ORGANISATION_REMOVE_MEMBERSHIP_SUCCESS,
    ORGANISATION_UPDATE_MEMBERSHIP_SUCCESS,
    ORGANISATION_INVITE_MEMBER_SUCCESS,
    ORGANISATION_CANCEL_PENDING_INVITE_SUCCESS,
    ORGANISATION_START_FREE_TRIAL_SUCCESS,
    UPDATE_PENDING_ORGANISATION_USER_PROJECTS_SUCCESS,
    CLEAR_ORGANISATIONS,
} from './organisations.actions';

import {
    CREATE_PROJECT_SUCCESS
} from '../projects/projects.actions';

const initialState = {
    loading: false,
    error: null,
    organisations: null,
    organisation: null,
    memberships: null,
    membership: null
};

const organisationsReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOAD_ORGANISATIONS: {
            return {
                ...initialState,
                loading: true
            };
        }

        case LOAD_ORGANISATIONS_SUCCESS: {
            return {
                ...state,
                organisations: action.organisations,
                loading: false
            };
        }

        case CLEAR_ORGANISATIONS: {
            return {
                ...state,
                organisations: null
            };
        }

        case LOAD_ORGANISATION_SUCCESS: {
            return {
                ...state,
                organisation: action.organisation
            };
        }

        case LOAD_ORGANISATION_MEMBERSHIPS_SUCCESS: {
            return {
                ...state,
                memberships: action.memberships
            };
        }

        case LOAD_ORGANISATION_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                membership: action.membership
            };
        }

        case ORGANISATION_UPDATE_SUCCESS: {
            return {
                ...state,
                organisation: {
                    ...state.organisation,
                    ...action.update
                }
            }
        }

        case ORGANISATION_INVITE_MEMBER_SUCCESS: {
            return {
                ...state,
                memberships: [
                    ...state.memberships,
                    {
                        ...action.invitation,
                        userId: null,
                        user: null
                    }
                ]
            };
        }

        case ORGANISATION_UPDATE_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                membership: {
                    ...state.membership,
                    role: action.role
                }
            };
        }

        case ORGANISATION_REMOVE_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                membership: null,
                memberships: state.memberships.filter(m => m.userId !== action.userId)
            };
        }

        case ORGANISATION_CANCEL_PENDING_INVITE_SUCCESS: {
            const { email } = action;
            return {
                ...state,
                membership: null,
                memberships: state.memberships.filter(m => m.pendingEmail !== email)
            };
        }

        case CREATE_PROJECT_SUCCESS: {
            const { project } = action;
            if (!state.organisation || (project.organisationId !== state.organisation.id)) {
                return state;
            }

            const projects = [
                ...state.organisation.projects,
                project
            ];

            projects.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

            return {
                ...state,
                organisation: {
                    ...state.organisation,
                    projects
                }
            };
        }

        case ORGANISATION_START_FREE_TRIAL_SUCCESS: {
            const { organisation } = action;
            return {
                ...state,
                organisations: [
                    ...state.organisations,
                    organisation
                ]
            }
        }

        case UPDATE_PENDING_ORGANISATION_USER_PROJECTS_SUCCESS: {
            const { memberships } = state;
            const newMemberships = [...memberships];
            const updateIndex = newMemberships.findIndex(({ id }) => id === action.id);
            newMemberships[updateIndex] = {
                ...newMemberships[updateIndex],
                pendingProjectMap: action.pendingProjectMap,
            };

            return {
                ...state,
                memberships: newMemberships,
            };
        }

        case 'LOGOUT': {
            return {
                ...initialState
            };
        }

        default: {
            return state;
        }
    }
};

export default organisationsReducer;