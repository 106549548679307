import { Component, EventEmitter, OnInit, Output, inject, input, signal } from '@angular/core';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import { NewChildRecord } from '../../records.service';

type SurveyResponse = {
    titleAttributeId: number; 
    title: string; 
    attributes: {
        id: number;
        path: string;
    }[];
}

@Component({
    selector: 'app-record-button-remove',
    templateUrl: 'record-button-remove.component.html',
    standalone: true
})

export class RecordButtonRemoveComponent implements OnInit {

    private apiService = inject(ApiService);

    associate = input<number | NewChildRecord>();

    title = signal<string>('');

    @Output() remove: EventEmitter<void> = new EventEmitter();

    ngOnInit() {
        this.getTitle();
    }

    private getTitle() {
        /** We can either have an original record (id) or a new record (record) */
        if (typeof this.associate() === 'number') {
            this.getTitleFromId();
        } else {
            this.getTitleFromRecord();
        }
    }
        
    private getTitleFromId() {
        const query = `query recordButtonTitle{
            record(id: ${this.associate() as number}){
                title
            }
        }`;

        this.apiService.graphql<{ record: { title: string } }>(query).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null)
            })
        ).subscribe(res => {
            if (!!res) {
                this.title.set(res.record.title);
            }
        });
    }

    private getTitleFromRecord() {
        const { projectId, surveyId, data } = this.associate() as NewChildRecord;

        const surveysFragment = `fragment surveyFields on Survey{
            titleAttributeId
            title
            attributes{
                id
                path
            }
        }`;

        const query = `query recordButtonTitle{
            project(id: ${projectId}){
                surveys(where: {id: ${surveyId}}){ ...surveyFields }
                parent{
                    surveys(where: {id: ${surveyId}}){ ...surveyFields }
                }
            }
        }${surveysFragment}`;

        this.apiService.graphql<{ project: { surveys: SurveyResponse[]; parent: { surveys: SurveyResponse[] } } }>(query).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                const survey = res.project.surveys?.[0] ?? res.project.parent?.surveys?.[0];
                const { titleAttributeId, title, attributes } = survey;
                let t = `${title} record`;
                if (!!titleAttributeId) {
                    const path = attributes.find(a => a.id === titleAttributeId).path;
                    t = data[path];
                }
                this.title.set(t);
            }
        });
    }
}