<div class="flex items-center gap-2">
    @if (breadcrumb().length > 1) {
        @for (item of breadcrumb(); track item; let last = $last) {
            @if (!last) {
                <a (click)="record.emit(item.id)" class="text-sm font-normal text-text-primary whitespace-nowrap hover:cursor-pointer hover:text-primary-300">{{ item.title }}</a>
                <i class="fas fa-chevron-right text-grey-30"></i>
            }
            @else {
                <p class="text-sm font-semibold text-text-primary whitespace-nowrap">{{ item.title }}</p>
            }
        }
    }
</div>