import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function ValidateEqual(expectedValue: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const inputValue = control.value;

        if (inputValue === expectedValue) {
            return null;
        } else {
            return { validateEqual: true };
        }
    };
}