import { NgOptimizedImage } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { InputTextModule } from "primeng/inputtext";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/core";
import { TwicPicPipe } from "src/app/shared/pipes/twicpic.pipe";
import { z } from 'zod';
import { HomeMembershipsComponent } from "./home-memberships.component";
import { ProjectMembership, HomeProjectTemplate, HomeProjectTemplateSchema } from "./home.models";
import { HomeService } from "./home.service";

@Component({
    selector: 'app-home-project-templates',
    standalone: true,
    templateUrl: './home-project-templates.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        InputTextModule,
        NgOptimizedImage,
        ButtonModule,
        ScrollPanelModule,
        ReactiveFormsModule,
        HomeMembershipsComponent,
        TwicPicPipe
    ]
})
export class HomeProjectTemplatesComponent {

    private apiService = inject(ApiService);
    private ref = inject(DynamicDialogRef);
    private homeService = inject(HomeService);

    selected = signal<HomeProjectTemplate>(null);
    stage = signal<number>(1);
    organisationId = this.homeService.currentOrganisationId();
    memberships: ProjectMembership[] = [];

    readonly #templatesQuery = `{projectTemplates{id,projectId,name,description,category,icon}}`;
    templates = toSignal(this.apiService.graphql<{ projectTemplates: HomeProjectTemplate[] }>(this.#templatesQuery).pipe(
        map(res => z.array(HomeProjectTemplateSchema).parse(res.projectTemplates))
    ), {
        initialValue: []
    });

    selectTemplate(template: HomeProjectTemplate) {
        this.ref.close(template);
    }

}