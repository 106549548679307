export default [
    'id',
    'surveyId',
    'projectId',
    'parentId',
    'meta',
    'state',
    'survey',
    'user',
    'userId',
    'updatedAt',
    'createdAt',
    'verificationState'
];