export function readGeoJSONFile(file: File): Promise<{ geoJsonProperties: any, geoJsonPaths: string[] }> {
    const deferred = new Promise<{ geoJsonProperties: any, geoJsonPaths: string[] }>((resolve, reject) => {
        let currentSegment = 0;
        const segmentSize = 10000;  // Size of file segments to load
        let maxSegments = 100;    // Maximum number of segments to load

        // Cap segments at file size
        maxSegments = Math.min(maxSegments, Math.ceil(file.size / segmentSize));

        let loadedString = '';
        let state: any = null;
        let foundFeatures = false;

        const reader = new FileReader();
        reader.onloadend = function (event) {
            loadedString += event.target.result;
            const segmentStart = currentSegment * segmentSize;

            if (!foundFeatures) {
                // Find start of 'features' array
                const featureStart = loadedString.indexOf('"features"');
                if (featureStart !== -1) {
                    foundFeatures = true;
                    loadedString = loadedString.substring(featureStart);
                } else {
                    currentSegment++;

                    if (currentSegment < maxSegments) {
                        reader.readAsText(file.slice(segmentStart, segmentStart + segmentSize));
                    } else {
                        // Exceeded limit
                        reject();
                    }

                    return;
                }
            }

            if (!state) {
                // Haven't yet found a property block
                const start = loadedString.indexOf('"properties"');
                if (start !== -1) {
                    // Reset loaded string to start at located 'properties'
                    loadedString = loadedString.substring(start);
                } else {
                    currentSegment++;

                    if (currentSegment < maxSegments) {
                        reader.readAsText(file.slice(segmentStart, segmentStart + segmentSize));
                    } else {
                        // Exceeded limit
                        reject();
                    }

                    return;
                }
            }

            const result = findJsonBlock(loadedString, state);
            if (result.success) {
                resolve({
                    geoJsonProperties: result.block,
                    geoJsonPaths: Object.keys(result.block)
                });
            } else {
                currentSegment++;

                if (currentSegment < maxSegments) {
                    reader.readAsText(file.slice(segmentStart, segmentStart + segmentSize));
                } else {
                    reject();
                    // Exceeded limit
                }
                return;
            }
        };

        // Read subset of file to extract property list
        reader.readAsText(file.slice(0, segmentSize));
    });

    return deferred;
}

function findJsonBlock(string: string, state: any) {
    if (!state) {
        const startPos = string.indexOf("{");
        state = {
            start: startPos,
            pos: startPos + 1,
            depth: 0,
            inString: false
        };
    }

    for (let i = state.pos; i < string.length; i++) {
        if (string[i] === '}' && !state.inString) {
            // Found a closing bracket outside a string
            if (state.depth === 0) {
                return {
                    success: true,
                    block: JSON.parse(string.substring(state.start, i + 1))
                };
            } else {
                state.depth--;
            }
        }
        if (string[i] === '"' && string[i - 1] !== '\\') {
            // Found an unescaped double quote
            state.inString = !state.inString;
        }
        if (string[i] === '{' && !state.inString) {
            // Found an opening bracket outside a string
            state.depth++;
        }
        state.pos = i;
    }

    // Haven't found the end yet. Report failure and return current state.
    return {
        success: false,
        state: state
    };
}
