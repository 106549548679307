import { getAuthPasswordResetToken } from '../selectors';

export const strongPasswordRegex = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z\d$@$!%*#?&]{8,}$/);

function authActions($rootScope, $http, $location, $q, $state, CoreoAPI, localStorageService, port, apiPort, apiProtocol, apiHostname, hostname, protocol, jobsService) {

    const AUTH_TOKEN_KEY = 'auth_token';
    const IMPERSONATING_AUTH_TOKEN_KEY = 'impersonating_user_token';
    const ORGANISATION_ID_KEY = 'organisation_id';

    const init = (dispatch) => {
        const search = $location.search();

        let token = localStorageService.get(AUTH_TOKEN_KEY);
        let organisationId;

        const impToken = localStorageService.get(IMPERSONATING_AUTH_TOKEN_KEY);
        const storedOrganisationId = localStorageService.get(ORGANISATION_ID_KEY);
        if (storedOrganisationId) {
            organisationId = parseInt(storedOrganisationId);
        }


        if (search.hasOwnProperty('auth_token')) {
            token = search.auth_token;
        }
        if (search.hasOwnProperty('password_reset_token')) {
            dispatch({
                type: 'AUTH_PASSWORD_RESET_TOKEN',
                passwordResetToken: search.password_reset_token
            });
        }

        if (search.hasOwnProperty('error')) {
            let oauthErrorDescription = '';
            if (search.error_description === 'OAUTH_EMAIL_REQUIRED_ERR') {
                oauthErrorDescription = 'An email is required';
            }
            dispatch({
                type: 'AUTH_OAUTH_ERROR',
                oauthError: search.error,
                oauthErrorDescription
            })
        }

        if (token) {
            if (impToken) {
                dispatch({
                    type: 'AUTH_IMPERSONATE_SUCCESS'
                });
            }

            return getUser(token).then(user => {
                if (!user || user.id === 0) {
                    dispatch({
                        type: 'LOGIN_FAILURE',
                        loginError: 'No user found',
                    });

                    return false;
                } else {
                    return handleAuthSuccess(dispatch, token, user, organisationId).then(() => {
                        if (search.oauth_transfer_redirect) {
                            $state.go('oauthTransfer');
                        }
                    });
                }
            });

        }
        // Not authenticated, return false
        return Promise.resolve(false);
    };

    const handleAuthSuccess = (dispatch, token, user, organisationId) => {
        localStorageService.set(AUTH_TOKEN_KEY, token);
        dispatch({
            type: 'LOGIN_AUTH_SUCCESS',
            token,
            user,
            organisationId
        });


        return getUserPermissions().then(permissions => {
            dispatch({
                type: 'LOGIN_SUCCESS',
                ...permissions
            });
            jobsService.loadJobs();
            return true;
        });
    };

    const getUser = (token = null) => {
        const options = {};
        if (token) {
            options.token_override = token;
        }
        const query = `query getUser {
            viewer {
                id,
                email,
                role,
                imageUrl,
                displayName,
                username,
                provider,
                preferences
            }
        }`;

        return CoreoAPI.query(query, options).then(result => result.viewer);
    };

    const getUserPermissions = () => {
        const query = `query getUserPermissions{
            viewer{
                p: memberships{
                    r: role,
                    id: projectId
                },
                o: organisationMemberships{
                    r: role,
                    id: organisationId
                }
                usedFreeTrial
            }
        }`;
        return CoreoAPI.query(query).then(result => {
            const permissions = { projectPermissions: {}, organisationPermissions: {} };
            for (const o of result.viewer.o) {
                permissions.organisationPermissions[o.id] = o.r;
            }
            for (const p of result.viewer.p) {
                permissions.projectPermissions[p.id] = p.r;
            }
            permissions.usedFreeTrial = result.viewer.usedFreeTrial;
            return permissions;
        });
    }

    const delay = ms => {
        const deferred = $q.defer();
        setTimeout(deferred.resolve, ms);
        return deferred.promise;
    };

    const authenticateWithToken = token => dispatch => {
        dispatch({
            type: 'LOGIN_VIA_TOKEN'
        });
        return getUser(token).then(user => {
            handleAuthSuccess(dispatch, token, user);
        })
    }
    const login = (type, credentials, oauth_transfer_redirect) => dispatch => {

        const buildSearchParams = () => {
            var denySignup = true;
            var denySignupRedirectUrl = [protocol, '://', hostname, cbPort, '/oauthdenied'].join('');
            var cbUrl = [protocol, '://', hostname, cbPort, '/%23/login_cb'].join('');

            var searchParams = '?';

            if (denySignup) {
                searchParams += 'deny_signup=true&';
                searchParams += `deny_signup_redirect_url=${denySignupRedirectUrl}&`;
            }

            if (oauth_transfer_redirect) {
                searchParams += 'oauth_transfer_redirect=true&'
            }

            searchParams += `return_url=${cbUrl}`;
            return searchParams;
        }

        if (type === 'local') {

            dispatch({
                type: 'LOGIN'
            });

            const mutation = 'mutation{result: login(input:{email: "' + credentials.email + '", password: "' + credentials.password + '"}){token,user { id, email, role, imageUrl, displayName, username, provider, preferences }}}';
            // Add an artificial delay

            return delay(250).then(() => CoreoAPI.mutation(mutation).then(({ token, user }) => {
                return handleAuthSuccess(dispatch, token, user);
            }, err => {
                dispatch({
                    type: 'LOGIN_FAILURE',
                    loginError: err && err.message || 'Unknown Error'
                });
                throw err;
            }));
        } else {
            var cbPort = '';
            // const { port, apiPort, apiProtocol, apiHostname, hostname, protocol } = this;
            if (port && port !== '80' && port !== '443') {
                cbPort = ':' + port;
            }
            const searchParams = buildSearchParams();
            var targetUrl = [apiProtocol, '://', apiHostname, (+apiPort === 80 || +apiPort === 443) ? '' : ':' + apiPort, '/auth/', type, searchParams].join('');
            window.location = targetUrl;
        }
    }

    const logout = () => dispatch => {
        localStorageService.remove(AUTH_TOKEN_KEY);
        localStorageService.remove(IMPERSONATING_AUTH_TOKEN_KEY);
        localStorageService.remove(ORGANISATION_ID_KEY);
        jobsService.flush();

        dispatch({
            type: 'LOGOUT'
        });
    };

    const oauthTransfer = password => dispatch => {
        dispatch({
            type: 'OAUTH_TRANSFER'
        });

        var mutation = 'mutation{result: oauthTransfer(input:{password: "' + password + '"}){token}}';
        // Add an artificial delay

        return delay(250).then(() => CoreoAPI.mutation(mutation).then(({ token }) => {
            localStorageService.set(AUTH_TOKEN_KEY, token);
            dispatch({
                type: 'OAUTH_TRANSFER_SUCCESS',
                token
            });
            return Promise.resolve();
        }, err => {
            dispatch({
                type: 'OAUTH_TRANSFER_FAILURE',
                error: err,
                toastError: err.message
            });
            throw err;
        }));
    }

    const impersonate = userId => dispatch => {
        return $http.post('/auth/impersonate', {
            userId: userId
        }).then((res) => {
            const token = res && res.data && res.data.token;
            if (token) {
                // Backup the old token
                localStorageService.set(IMPERSONATING_AUTH_TOKEN_KEY, localStorageService.get(AUTH_TOKEN_KEY));

                return getUser(token).then(user => {
                    return handleAuthSuccess(dispatch, token, user).then(() => dispatch({
                        type: 'AUTH_IMPERSONATE_SUCCESS'
                    }));
                })
            } else {
                dispatch({
                    type: 'AUTH_IMPERSONATE_FAILURE',
                    toast: {
                        type: 'error',
                        message: 'Failed to impersonate'
                    }
                });
            }
        });
    }

    const unimpersonate = () => dispatch => {
        const token = localStorageService.get(IMPERSONATING_AUTH_TOKEN_KEY);

        return getUser(token).then(user => {
            return handleAuthSuccess(dispatch, token, user).then(() => {
                localStorageService.remove(IMPERSONATING_AUTH_TOKEN_KEY);
                dispatch({
                    type: 'AUTH_UNIMPERSONATE_SUCCESS'
                });
            });
        });
    }

    const signup = (data) => dispatch => {
        data.legacy = false;
        var input = CoreoAPI.gqlStringify(data);
        var mutation = 'mutation{result: signup(input:' + input + ' ){token}}';
        return CoreoAPI.mutation(mutation);
    };

    const passwordReset = (email) => dispatch => {
        var input = CoreoAPI.gqlStringify({ email: email, legacy: false });
        var mutation = 'mutation{result: passwordForgot(input: ' + input + ')}';
        return CoreoAPI.mutation(mutation);
    };

    const passwordUpdate = (newPassword) => (dispatch, getState) => {
        const passwordResetToken = getAuthPasswordResetToken(getState());

        var input = CoreoAPI.gqlStringify({ token: passwordResetToken, password: newPassword });
        var mutation = 'mutation{result: passwordReset(input: ' + input + '){token, user { id, email, role, imageUrl, displayName, username, provider, preferences }}}';
        return CoreoAPI.mutation(mutation).then((res) => {
            return handleAuthSuccess(dispatch, res.token, res.user);
        });
    };

    const verifyAccount = (accountVerificationToken) => (dispatch) => {
        var input = CoreoAPI.gqlStringify({ token: accountVerificationToken });
        var mutation = 'mutation{result: verifyAccount(input: ' + input + '){token user { id, email, role, imageUrl, displayName, username, provider, preferences }}}';
        return CoreoAPI.mutation(mutation).then((res) => {
            return handleAuthSuccess(dispatch, res.token, res.user);
        });
    };


    const acceptOrganisationInviteSignup = (token, password, displayName) => dispatch => {

        const query = `mutation acceptOrgInviteSignup($password: String!, $displayName: String!, $token: String!){
            result: signup(input:{ organisationInviteToken: $token, password: $password, displayName: $displayName, legacy: false}){
                token,
                user { id, email, role, imageUrl, displayName, username, provider, preferences, isAdminAreaUser },
                organisation {
                    name
                }
            }
        }`;

        return CoreoAPI.mutation(query, null, {
            variables: {
                token,
                password,
                displayName
            }
        }).then(result => {
            return handleAuthSuccess(dispatch, result.token, result.user).then(() => result);
        }, err => {
            throw Error(err.message);
        });
    }

    const acceptOrganisationInviteLogin = (token, email, password) => dispatch => {
        return login('local', {
            email,
            password
        })(dispatch).then(() => {
            return acceptOrganisationInvite(token)(dispatch);
        });
    }

    const acceptOrganisationInvite = (token) => dispatch => {
        const query = `mutation acceptOrgInviteLogin($token: String!){
            result: acceptOrganisationInvite(input: { token: $token }) {
                organisationUser {
                    role
                    organisationId
                    organisation {
                        name
                    }
                    user {
                        isAdminAreaUser
                    }
                }
                token
            }
        }`;

        return CoreoAPI.mutation(query, null, {
            variables: {
                token
            }
        }).then(result => {
            dispatch({
                type: 'ORGANISATION_INVITE_ACCEPT_SUCCESS',
                role: result.role,
                organisationId: result.organisationId,
                toast: 'Organisation Invitation Accepted'
            });
            return result;
        }, err => {
            throw Error(err.message);
        });
    }

    const updateUserPreferences = (preferences) => dispatch => {
        const mutation = `
            mutation updateUserPreferences($input: UpdatePreferences!) {
                result: updateUserPreferences(input: $input) {
                    preferences,
                }
            }
        `;

        return CoreoAPI.mutation(mutation, null, {
            variables: {
                input: {
                    preferences,
                },
            }
        }).then(result => {
            dispatch({
                type: 'UPDATE_USER_PREFERENCES_SUCCESS',
                preferences: result.preferences,
            });
        }, err => {
            throw Error(err.message);
        })
    };

    const refreshPermissions = () => dispatch => {
        return getUserPermissions().then(permissions => {
            dispatch({
                type: 'LOGIN_UPDATE_PERMISSIONS',
                ...permissions
            });
        });
    }

    const setOrganisation = (id) => dispatch => {
        localStorageService.set(ORGANISATION_ID_KEY, id);
        dispatch({
            type: 'SET_ORGANISATION',
            id
        })
    };

    return {
        init,
        login,
        logout,
        impersonate,
        unimpersonate,
        signup,
        passwordReset,
        passwordUpdate,
        verifyAccount,
        acceptOrganisationInvite,
        acceptOrganisationInviteSignup,
        acceptOrganisationInviteLogin,
        updateUserPreferences,
        authenticateWithToken,
        oauthTransfer,
        refreshPermissions,
        setOrganisation
    };
}

authActions.$inject = ['$rootScope', '$http', '$location', '$q', '$state', 'CoreoAPI', 'localStorageService', 'port', 'apiPort', 'apiProtocol', 'apiHostname', 'hostname', 'protocol', 'jobsService'];
export default authActions;