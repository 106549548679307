export const ProjectMapBaseSelectorComponent = {
    template: require('!raw-loader!./project-map-base-selector.component.html').default,
    controllerAs: 'ctrl',
    bindings: {
        base: '<',
        maps: '<',
        currentId: '<',
        onChange: '&'
    },
    require: {
        mapCtrl: '^projectMap'
    },
    controller: class ProjectMapBaseSelectorComponent {
        static $inject = ['$element', 'MAP_BASE_STYLES'];
        constructor($element, MAP_BASE_STYLES) {
            this.defaults = MAP_BASE_STYLES;
            this.$element = $element;
        }

        $onInit() {
            var mapCtrl = this.$element[0];

            function BaseSelector() {

            }

            BaseSelector.prototype.onAdd = function () {
                mapCtrl.classList.add('mapboxgl-ctrl');
                return mapCtrl;
            }


            BaseSelector.prototype.onRemove = function () {
            }

            this.mapCtrl.mapPromise.then((map) => {
                map.addControl(new BaseSelector());
            });
        }

        update() {
            this.onChange({
                map: this.currentId,
                base: this.base
            });
        }
    }


};