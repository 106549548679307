<div>
    <form [formGroup]="newItemForm" (ngSubmit)="onSubmit()">
        <div class="mb-7">
            <label for="name" class="block mb-2">Name of item (Value)*</label>
            <input type="text" pInputText id="name" type="text" placeholder="Name of collection item" formControlName="name" (input)="setKey()" class="w-full"/>
            <p class="text-xs text-danger leading-none mt-2">
                @if (name.invalid && (name.dirty || name.touched) && name.errors?.['required']) {
                    Name is required
                }
            </p>
        </div>
        
        <div class="mb-7">
            <label for="key" class="flex items-center justify-between mb-2">
                <span>Key*</span>
                <i 
                class="fa-solid fa-circle-info h-4 w-4 text-grey-50 cursor-pointer"
                pTooltip="The key is a permanent label for your collection item in the data base. It will remain constant even if the item name is changed. Once set it cannot be changed."
                tooltipPosition="left"
                ></i>
            </label>
            <input type="text" pInputText id="key" type="text" placeholder="Key value for collection item" formControlName="key" class="w-full"/>
            <p class="text-xs text-danger leading-none mt-2">
                @if (key.invalid && (key.dirty || key.touched) ) {
                    @if (key.errors?.['required']) {
                        Key is required
                    }
                    @if (key.errors?.['pattern']) {
                        Key cannot contain spaces
                    }
                }
            </p>
        </div>

        <div class="flex items-center justify-end">
            <p-button type="submit" [disabled]="!newItemForm.valid" [loading]="loading" label="Add new item" size="large"></p-button>
        </div>
    </form>
</div>