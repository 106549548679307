import angular, { IPromise } from 'angular';
import _ from 'lodash';
import CoreoAPIService from '../main/coreoApi';
import { defaultDataLayerStyle } from 'src/app/core/services/mapData.service';

export default class ProjectService {

    attributesFragment = 'attributes{id, uuid, sectionIdx, archived, text, questionType, help, description, label, path, exportPath, type, surveyId, associatedSurveyId, collectionId, visible, filterable, meta, config, conditions, order, required, parentCollectionId}';
    formsFragment = `forms: surveys{ id, projectId, titleAttributeId, secondaryTitleAttributeId, private, thankyou, allowMemberUpdate, allowOwnRecordDelete, color, slug, name, title, verification, sort, visible, shapefileExportAvailable, style, mapSort, mapVisible }`;
    itemsFragment = `items {
        id,
        key,
        value,
        sort,
        mediaItems{
            id,
            url,
            size,
            caption,
            type,
            name,
            createdAt,
            sort
        }
    }`;
    itemsFragmentWithData = `items {
        id,
        key,
        value,
        sort,
        mediaItems{
            id,
            url,
            size,
            caption,
            type,
            name,
            createdAt,
            sort
        },
        data
    }`;
    collectionsFragment = `collections{
        name,
        id,
        type,
        status,
        geometric,
        sortMode,
        sortAttributeId,
        sortReverse,
        itemCount,
        style,
        mapSort,
        mapLayer,
        mapVisible,
        projectId
    }`;

    mapLayersFragment = `mapLayers{
        id,
        projectId,
        name,
        sourceType,
        sourceId,
        source,
        sort,
        type,
        layout,
        paint,
        filter,
        visible
    }`;

    project: any = {};

    static $inject = ['$http', '$q', 'CoreoAPI'];
    constructor(private $http, private $q, private CoreoAPI: CoreoAPIService) { }

    load(slug) {
        const query = `{data: project(slug: "${slug}"){
            id,
            organisationId,
            parentProjectId,
            name,
            slug,
            description,
            accessMode,
            bespoke,
            verification,
            shareableLink,
            allowContributors,
            iosAppUrl,
            androidAppUrl,
            accountVerificationUrl,
            fromAddress,
            imageUrl,
            passwordResetUrl,
            welcomePageId,
            visible,
            geometry{
                type,
                coordinates
            },
            ${this.attributesFragment},
            shortName,
            subdomain,
            colors,
            css,
            font,
            locked,
            ${this.formsFragment},
            credentials{
                id,
                provider,
                clientID,
                clientSecret
            },
            states{
                name,
                verified,
                stateId,
                default,
                sort,
                color
            },
            ${this.collectionsFragment}
            maps{
                id,
                name,
                layers{
                    id,
                    name,
                    sourceType,
                    sourceId,
                    source,
                    sort,
                    type,
                    layout,
                    paint,
                    filter
                }
            },
            ${this.mapLayersFragment}
            organisation: organisationOverview{
                name
                slug
                features
            },
            hasEmptySeats,
            usersLimit,
            parent {
                name,
                ${this.formsFragment}
                ${this.attributesFragment}
                ${this.collectionsFragment}
                ${this.mapLayersFragment}
            }
        }}`;
        return this.CoreoAPI.query(query).then((result) => {
            this.project = result.data;
            if (!this.project) {
                throw "Failed to load project";
            }

            // Do some ordering and tidying here
            this.project.states = _.sortBy(this.project.states, 'sort');
            this.project.attributes = _.sortBy(this.project.attributes, 'order');

            if (this.project.forms.length) {
                this.project.surveys = this.project.forms;
            } else if (this.project.name === 'Rainfall Obs') { //default form created for legacy apps without a survey/form, else the AA is not capable of displaying the form-less data
                this.project.forms = [{
                    id: null,
                    titleAttributeId: null,
                    secondaryTitleAttributeId: null,
                    slug: this.project.slug,
                    name: this.project.name,
                    title: this.project.name,
                    verification: true,
                    legacy: true
                }]
                this.project.surveys = this.project.forms;
                return this.CoreoAPI.query(`{data: project(slug: "${slug}"){${this.attributesFragment}}}`).then((result) => {
                    this.project.forms[0].attributes = result.data.attributes.filter(a => !!a.surveyId); //default form should not include collection attributes
                    this.project.attributes = result.data.attributes;
                    Object.freeze(this.project);
                    return this.project;
                });
            } else {
                this.project.surveys = this.project.forms = [];
            }

            if (this.project.parent) {
                const readonlyArr = (item: any) => ({ ...item, readonly: true });
                this.project.surveys = this.project.forms = this.project.surveys.concat(this.project.parent.forms.map(readonlyArr));
                this.project.attributes = this.project.attributes.concat(this.project.parent.attributes.map(readonlyArr));
                this.project.collections = this.project.collections.concat(this.project.parent.collections.map(readonlyArr));
                this.project.mapLayers = this.project.mapLayers.concat(this.project.parent.mapLayers.map(readonlyArr));
            }
            return this.project;
        });
    }

    loadStats(projectId) {
        const query = `{data: project(id: ${projectId}){stats}}`;
        return this.CoreoAPI.query(query).then(result => result && result.data && result.data.stats);
    }

    getProjectId() {
        return this.project.id;
    }

    getAttributes() {
        return this.project.attributes;
    }

    getPage(projectId: number, id: number) {
        const query = `{data: project(id: ${projectId}){ pages(where:{id: ${id}}){
            id, title, icon, order, published, listed, config, auth, type, projectId,
            mediaItems{ id, name, url, size, type, caption, createdAt, sort }
        }}}`;
        return this.CoreoAPI.query(query).then(result => result && result.data && result.data.pages && result.data.pages[0]);
    }

    getPages(projectId: number) {
        const query = `{data: project(id: ${projectId}){ pages{
            id
            title
            type
            order
            icon
            auth
            published
        }}}`;
        return this.CoreoAPI.query(query).then(result => result && result.data && result.data.pages && result.data.pages);
    }

    getCollection(projectId, collectionId) {
        const query = `{data: project(id: ${projectId}){ collections(where:{id: ${collectionId}}){
            name,
            id,
            type,
            status,
            geometric,
            sortMode,
            sortAttributeId,
            sortReverse,
            itemCount,
            style,
            mapSort,
            mapLayer,
            mapVisible,
            ${this.attributesFragment},
            ${this.itemsFragment}
        }}}`;
        return this.CoreoAPI.query(query).then(result => result && result.data && result.data.collections && result.data.collections[0]);
    }

    getCollections(projectId) {
        const query = `{data: project(id: ${projectId}){ collections{
            name,
            id,
            type,
            status,
            geometric,
            sortMode,
            sortAttributeId,
            sortReverse,
            itemCount,
            style,
            mapSort,
            mapLayer,
            mapVisible,
            ${this.attributesFragment},
            ${this.itemsFragment}
        }}}`;
        return this.CoreoAPI.query(query).then(result => result && result.data && result.data.collections);
    }

    getSurvey(surveyId) {
        return this.project.forms.find(s => s.id === surveyId);
    }

    createForm(name, title, sort) {
        const input = {
            projectId: this.project.id,
            name,
            title,
            sort,
            style: defaultDataLayerStyle()
        };

        console.log('DATA?!', input);

        var query = `mutation CoreoAACreateForm($input: createSurveyInput!){
            result: createSurvey(input: $input){
                id
                titleAttributeId
                secondaryTitleAttributeId
                private
                thankyou
                allowMemberUpdate
                allowOwnRecordDelete
                shapefileExportAvailable
                style
                mapSort
                name
                slug
                title
                ${this.attributesFragment}
                anonymous
                verification
                sort
                visible
            }}`;
        return this.CoreoAPI.gql(query, { input }).then(data => data.result);
    }

    updateForm(surveyId, data) {
        data.id = surveyId;
        var query = 'mutation{result: updateSurvey(input:' + this.CoreoAPI.gqlStringify(data) + '){id,name,slug,anonymous,verification,attributes{filterable,collectionId,label,visible,required,order,id,type,path,meta,collection{items{key,value}}}}}';
        return this.CoreoAPI.mutation(query)
            .then(form => {
                const idx = this.project.forms.findIndex(f => f.id === surveyId);
                this.project.forms[idx] = form;
            });
    }

    deleteForm(surveyId) {
        var query = 'mutation{result: deleteSurvey(input:{id:' + surveyId + '})}';
        return this.CoreoAPI.mutation(query);
    }

    sortForms(forms) {
        return Promise.all(
            forms.map(form => {
                const query = `mutation{ result: updateSurvey( input: { id: ${form.id}, sort: ${form.sort} }) {id}}`;
                return new Promise((resolve, reject) => {
                    this.CoreoAPI.mutation(query)
                        .then(resolve)
                        .catch(err => reject(err));
                })
            })
        );
    }

    createCollection(input) {
        (input.items || []).filter(i => !i.key).forEach((item, index) => item.key = _.snakeCase(item.value) + index);
        var query = `mutation AACreateCollectionFromFormBuilder($input: CollectionCreateInput!){
            result: createCollection(input: $input){
                id,
                name,
                type,
                status,
                geometric,
                itemCount,
                sortAttributeId,
                sortMode,
                sortReverse,
                mapLayer,
                mapVisible,
                mapSort,
            ${this.itemsFragment}
        }}`;
        return this.CoreoAPI.mutation(query, {}, { variables: { input } });
    }

    updateCollection(id, input) {
        input.id = id;
        const query = `mutation{result: updateCollection(input: ${this.CoreoAPI.gqlStringify(input)}){id}}`;
        return this.CoreoAPI.mutation(query);
    }

    createCollectionItem(item) {
        const { id, ...rest } = item;
        const update = {
            ...rest,
            key: item.key || _.snakeCase(item.value)
        };

        var mutation = `mutation{
          result: createCollectionItem(input: ${this.CoreoAPI.gqlStringify(update)}){
            id,
            collectionId,
            key,
            value,
            data,
            sort,
            mediaItems{
                id,
                url,
                size,
                caption,
                type,
                name,
                createdAt,
                sort
            }
          }
        }`

        return this.CoreoAPI.mutation(mutation);

    }

    updateCollectionItem(item) {
        delete item.key;
        let mutation;

        if (item.geometry) {
            let geometryStr = this.CoreoAPI.gqlGeometryStringify(item.geometry);

            delete item.geometry;

            const itemStr = this.CoreoAPI.gqlStringify(item);
            const updateStr = itemStr.substring(0, itemStr.length - 1) + ', geometry:' + geometryStr + '}';

            mutation = `mutation{result: updateCollectionItem(input: ${updateStr}){id}}`;
        } else {
            mutation = `mutation{result: updateCollectionItem(input: ${this.CoreoAPI.gqlStringify(item)}){id}}`;
        }

        return this.CoreoAPI.mutation(mutation);
    }

    updateCollectionItems(items) {
        var update = this.CoreoAPI.gqlStringify(items);
        var mutation = `mutation{result: updateCollectionItems(input:{items: ${update}})}`;
        return this.CoreoAPI.mutation(mutation);
    }

    importCollectionItems(collectionId, update, files) {
        var u = this.CoreoAPI.gqlStringify(angular.extend({}, update, { id: collectionId }));
        var mutation = `mutation{result: importCollectionItems(input: ${u}){id,key,value}}`;
        return this.CoreoAPI.mutation(mutation, files);
    }

    deleteCollectionItem(itemId) {
        var mutation = 'mutation{result: deleteCollectionItem(input:{id: ' + itemId + '})}';
        return this.CoreoAPI.mutation(mutation);
    }

    deleteCollection(id) {
        var mutation = 'mutation{result: deleteCollection(input:{id: ' + id + '})}';
        return this.CoreoAPI.mutation(mutation);
    }

    addAttribute(data) {
        var query = 'mutation{result: createAttribute(input:' + this.CoreoAPI.gqlStringify(data) + '){ id, associatedSurveyId, description, help, label, exportPath, path, questionType, type, sectionIdx, text, visible, required, order, filterable, meta, projectId, parentCollectionId, surveyId}}';
        return this.CoreoAPI.mutation(query);
    }

    updateAttribute(attribute) {
        // var query = 'mutation{result: updateAttribute(input:' + this.CoreoAPI.gqlStringify(attribute) + '){ id, label, exportPath, path, required, order, text, type, filterable, meta, projectId, surveyId}}';
        // return this.CoreoAPI.mutation(query);
        const query = `mutation AAUpdateAttribute($input: AttributeUpdateInput!){
            result: updateAttribute(input: $input){
                id,
                label,
                exportPath,
                path,
                required, 
                order, 
                text, 
                help,
                description,
                type, 
                filterable, 
                meta, 
                projectId, 
                surveyId
            }
          }
        `;
        return this.CoreoAPI.gql(query, { input: attribute });
    }

    deleteAttribute(attributeId, surveyId) {
        var query = 'mutation{data: deleteAttribute(input:{id:' + attributeId + '})}';
        return this.CoreoAPI.mutation(query);
    }

    createAssociation(sourceId, targetId, name) {
        var data = {
            surveyId: sourceId,
            subSurveyId: targetId,
            name
        };
        var query = 'mutation{data: createAssociation(input:' + this.CoreoAPI.gqlStringify(data) + '){ id, name, surveyId, subSurveyId }}';
        return this.CoreoAPI.gql<any>(query).then(result => {
            result.data;
        });
    }

    updateAssociation(association) {
        var input = {
            id: association.id,
            name: association.name
        };
        var query = `mutation{result: updateAssociation(input: ${this.CoreoAPI.gqlStringify(input)}){id,name,surveyId,subSurveyId}}`;
        return this.CoreoAPI.mutation(query);
    }

    deleteProject(id) {
        var query = `mutation{data: deleteProject(input:{id: ${id}})}`;
        return this.CoreoAPI.mutation(query);
    }

    getCollectionItem(projectId, collectionId, id) {
        var query = '{data: project(id: ' + projectId + '){collections(where:{id: ' + collectionId + '}){items(where:{id:' + id + '}){id,key,value,data,geometry{type,coordinates},mediaItems{id,url}}}}}';
        return this.CoreoAPI.query(query).then(function (data) {
            var collection = data.data.collections.length > 0 ? data.data.collections[0] : null;
            var item = collection.items.length > 0 ? collection.items[0] : null;
            return item;
        });
    }

    getCollectionItemByKey(projectId, collectionId, key) {
        var query = '{data: project(id: ' + projectId + '){collections(where:{id: ' + collectionId + '}){items(where:{key:' + JSON.stringify(key) + '}){id,key,value,data,geometry{type,coordinates}}}}}';
        return this.CoreoAPI.query(query).then(function (data) {
            var collection = data.data.collections.length > 0 ? data.data.collections[0] : null;
            var item = collection.items.length > 0 ? collection.items[0] : null;
            return item;
        });
    }

    getCollectionItemsByKeys(projectId, collectionId, keys) {
        var query = '{data: project(id: ' + projectId + '){collections(where:{id: ' + collectionId + '}){items(where:{key:{ in: ' + JSON.stringify(keys) + '}}){id,key,value,data,geometry{type,coordinates}}}}}';
        return this.CoreoAPI.query(query).then(function (data) {
            var collection = data.data.collections.length > 0 ? data.data.collections[0] : null;
            return collection ? collection.items : [];
        });
    }

    getCollectionItems(projectId, collectionId, includeData = false) {
        var query = `query AAGetCollectionItems($projectId: Int!, $collectionId: Int!){
            data: project(id: $projectId){
                collections(where:{id: $collectionId}){
                    ${includeData ? this.itemsFragmentWithData : this.itemsFragment}
                }
            }
        }`;
        return this.CoreoAPI.query(query, {
            variables: {
                projectId,
                collectionId
            }
        }).then((data) => {
            return data && data.data && data.data.collections && data.data.collections[0] && data.data.collections[0].items;
        });
    }

    searchCollectionItems(projectId, collectionId, searchTerm, offset, limit, canceler) {
        const query = `query AASearchCollectionitems($projectId: Int!, $collectionId: Int!, $offset: Int!, $limit: Int!){
            data: project(id: $projectId){
                collections(where:{id: $collectionId}){
                    items(offset: $offset, limit: $limit, where:{value: { iLike: "%${searchTerm}%"}}){
                        id,
                        key,
                        value
                    }
                    count: itemCount(where:{value: { iLike: "%${searchTerm}%"}})
                }
            }
        }`;

        return this.CoreoAPI.query(query, {
            variables: {
                projectId,
                collectionId,
                offset,
                limit
            },
            timeout: canceler.promise
        }).then((data) => {
            return data.data.collections[0];
        });
    }

    createProject(data) {
        var query = `mutation{result: createProject(input: ${this.CoreoAPI.gqlStringify(data)}){id,name,slug,imageUrl,organisationId}}`;
        return this.CoreoAPI.mutation(query);
    }

    createProjectTemplate(template) {
        let files = null;
        if (template.icon) {
            files = {
                icon: template.icon
            }
        }
        delete template.icon;
        var query = `mutation createProjectTemplate($input: ProjectTemplateInput!){
            result: createProjectTemplate(input:$input){
                id,
                projectId,
                name,
                description,
                category,
                icon
            }
        }`
        return this.CoreoAPI.mutation(query, files, { variables: { input: template } });
    }

    updateProject(project) {
        let files = null;
        if (project.image) {
            files = {
                imageUrl: project.image
            }
        }
        delete project.image;
        var query = `mutation AAUpdateProject($input: ProjectUpdateInput!){
            result: updateProject(input:$input){
                id, 
                imageUrl,
                hasEmptySeats
            }
        }`;
        return this.CoreoAPI.mutation(query, files, { variables: { input: project } });
    }

    updateCredential(data) {
        var query = 'mutation{result: updateProjectCredential(input:' + this.CoreoAPI.gqlStringify(data) + '){id, clientID, provider, clientSecret}}';
        return this.CoreoAPI.mutation(query);
    }

    createCredential(data) {
        var query = 'mutation{result: createProjectCredential(input:' + this.CoreoAPI.gqlStringify(data) + '){id, clientID, provider, clientSecret}}';
        return this.CoreoAPI.mutation(query);
    }

    updateProjectVerificationState(state) {
        var query = 'mutation{result: updateProjectVerificationState(input:' + this.CoreoAPI.gqlStringify(state) + '){stateId,name,verified,default,sort,color}}';
        return this.CoreoAPI.mutation(query);
    }

    createProjectVerificationState(state) {
        var query = 'mutation{result: createProjectVerificationState(input:' + this.CoreoAPI.gqlStringify(state) + '){stateId,name,verified,default,sort,color}}';
        return this.CoreoAPI.mutation(query);
    }

    deleteProjectVerificationState(projectId, stateId) {
        var query = 'mutation{result: deleteProjectVerificationState(input:{projectId:' + projectId + ', stateId: ' + stateId + '})}';
        return this.CoreoAPI.mutation(query);
    }

    uploadMedia(projectId, file) {
        const query = `
            query PresignedUrl($projectId: Int!, $fileName: String!, $contentType: String!) {
                url: presignedURL(projectId: $projectId, fileName: $fileName, contentType: $contentType)
            }
        `;
        const fileName = file.name;
        const contentType = file.type;
        return this.CoreoAPI.query(query, {
            variables: { //contenttype todo
                projectId,
                fileName,
                contentType
            }
        }).then(({ url }) => {
            return this.$http.put(url, file, {
                headers: {
                    ['content-type']: contentType
                }
            }).then(() => {
                return url.split('?')[0];
            })
        })
    }

    getRecordsBoundingBox(projectId: number, where: any): IPromise<[number, number, number, number]> {
        const q = `query CoreoAARecordsBoundingBox($where: SequelizeJSON, $projectId: Int!){
            project(id: $projectId){
                bounds: recordsBoundingBox(where: $where)
            }
        }`;
        return this.CoreoAPI.gql<any>(q, {
            projectId,
            where
        }).then(result => {
            if (result.project.bounds && result.project.bounds.length > 0) {
                return result.project.bounds;
            }
            return null;
        });

    }

    getCollectionBoundingBox(projectId: number, collectionId: number): IPromise<[number, number, number, number]> {
        const q = `query CoreoAAGetCollectionBoundingBox($collectionId: Int!, $projectId: Int!){
            project(id: $projectId){
                collections(where: {id: $collectionId} ){
                    bounds: boundingBox{
                        coordinates
                    }
                }
            }
        }`;
        return this.CoreoAPI.gql<any>(q, {
            projectId,
            collectionId
        }).then(result => {
            return result.project.collections[0].bounds.coordinates.flat().map(i => parseFloat(i));
        });

    }
}
ProjectService.$inject = ['$http', '$q', 'CoreoAPI'];