<p-overlayPanel #op styleClass="p-0">
    @if(currentOrganisation(); as org){
    <div class="bg-primary w-full text-white p-4">
        <h3 class="font-semibold">{{org.name}}</h3>
        <h3 class="uppercase text-xs">Professional Plan</h3>
    </div>
    <ul class="px-2 py-4 bg-white max-h-100 overflow-y-auto no-scrollbar">

        <li class="h-12 flex items-center cursor-pointer hover:bg-[#F3F7FC] rounded px-4" (click)="handleOrgProjects()">
            Organisation Projects</li>
        <!-- Org Admin Options -->
        @if(org.role === 'admin' || org.role === 'owner'){
        <li class="h-12 flex items-center cursor-pointer hover:bg-[#F3F7FC] rounded px-4" (click)="handleOrgMembers()">
            Organisation Members</li>
        <li class="h-12 flex items-center cursor-pointer hover:bg-[#F3F7FC] rounded px-4" (click)="handleOrgSettings()">
            Organisation Settings</li>
        @if(count() > 1){
        <hr class="my-4 mx-4" />
        }
        }

        @if(count() > 1){
        <!-- Switch Organisations -->
        <h4 class="font-semibold pl-4 h-12 flex items-center">Switch Organisation</h4>
        @for(o of organisations; track o){
        <li class="h-12 flex items-center cursor-pointer hover:bg-[#F3F7FC] rounded px-4"
            (click)="switchOrganisation(o.id)">{{o.name}}</li>
        }
        }
    </ul>
    }
</p-overlayPanel>

<p-button (click)="toggleOverlay($event)" [outlined]="true">
    {{currentOrganisation()?.name}}
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" class="ml-1" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.67286 3.1637H10.3271C10.9258 3.1637 11.2252 3.88685 10.8014 4.31065L6.47593 8.63947C6.21358 8.90182 5.78642 8.90182 5.52407 8.63947L1.19861 4.31065C0.774807 3.88685 1.07416 3.1637 1.67286 3.1637Z"
            fill="#0069DF" />
    </svg>
</p-button>