<ul class="overflow-y-auto py-4 h-full divide-y divide-[#D3DBE3]">
    @if(!searchedFolders.search && !searchedFolders.folders.length){
    @for(f of current().node.children; track f){
    <li (click)="selectFolder(f)" class="h-[50px] cursor-pointer"
        [ngClass]="{selected: selection() === f.id, disabled: excludedFolderIds.includes(f.id)}">
        <div class="rounded-lg flex items-center gap-4 h-full px-2 group">
            <i class="fas fa-folder text-[#BCD5FF] w-8 h-8 text-3xl"></i>
            <span class="text-base text-primary font-semibold">{{f.name}}</span>
            <div class="py-1 ml-auto">
                <button (click)="openFolder(f)"
                    class="ml-auto transition-all hover:bg-white rounded flex items-center text-xs flex-grow-0 px-2 hover:text-primary text-grey-50">
                    <span class="opacity-0 group-hover:opacity-100 ">Open
                        Folder</span>
                    <i class="pi pi-angle-right text-grey-50 text-xl"></i>
                </button>
            </div>
        </div>
    </li>
    }
    @for(p of current().node.projects; track p){
    <li class="h-[50px] cursor-pointer disabled">
        <div class="rounded-lg flex items-center gap-4 h-full px-2">
            <div class="w-8 h-8 rounded-full relative overflow-hidden bg-[#DFEBFF]">
                @if(p.imageUrl){
                <img [ngSrc]="p.imageUrl" width="32" height="32" class="object-cover" alt="Project Image" />
                }
                @else{
                <svg-icon src="/assets/icons/placeholder.svg" class="w-full h-full object-cover"></svg-icon>
                }
            </div>
            <span class="text-base text-primary font-semibold">{{p.name}}</span>
        </div>
    </li>
    }
    }
    @else{
    @for(f of searchedFolders.folders; track f){
    <li (click)="selectFolder(f)" class="h-[50px] cursor-pointer"
        [ngClass]="{selected: selection() === f.id, disabled: excludedFolderIds.includes(f.id)}">
        <div class="rounded-lg flex items-center gap-4 h-full px-2">
            <i class="fas fa-folder text-[#BCD5FF] w-8 h-8 text-3xl"></i>
            <span class="text-base text-primary font-semibold">{{f.name}}</span>
        </div>
    </li>
    }
    }
</ul>