import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';

@Component({
    selector: 'app-new-collection-item-modal',
    templateUrl: 'new-collection-item-modal.component.html',
    imports: [
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
        TooltipModule
    ],
    standalone: true
})

export class NewCollectionItemModalComponent implements OnInit {

    private collectionId: number;
    public loading: boolean = false;
    
    public newItemForm = new FormGroup({
        name: new FormControl('', Validators.required),
        key: new FormControl('', [
            Validators.required,
            Validators.pattern('^[^ ]*$')
        ]),
    });

    constructor(
        private dialogRef: DynamicDialogRef,
        private dialogConfig: DynamicDialogConfig,
        private apiService: ApiService
    ) { }

    ngOnInit() { 
        this.collectionId = this.dialogConfig.data['collectionId'];
    }

    public setKey() {
        if (this.newItemForm.controls.key.dirty) {
            return;
        }
        const value = this.newItemForm.controls.name.value.toLowerCase().trim().replace(/\s+/g, '_');
        this.newItemForm.controls.key.setValue(value);
    }

    get name() {
        return this.newItemForm.get('name');
    }

    get key() {
        return this.newItemForm.get('key');
    }

    public onSubmit() {
        this.loading = true;

        console.log(this.newItemForm.value, this.name.value, this.key.value);
        const query = `mutation addNewCollectionItem($input: ItemCreateInput!){
            createCollectionItem(input: $input){
                id
            }
        }`;

        const input = {
            collectionId: this.collectionId,
            key: this.key.value,
            value: this.name.value
        };

        this.apiService.graphql<{ createCollectionItem: { item: { id: number } } }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            console.log(res);

            if (!!res) {
                // success show toast?
                // close modal and reload table
                this.dialogRef.close(true);
            }
            this.loading = false;
        });
    }
}