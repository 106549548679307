<div class="h-full w-full flex flex-col justify-normal">
    <div class="grow flex gap-5 p-5 bg-background overflow-hidden">
        <div class="h-full w-1/2 min-w-80">
            <p-tabView styleClass="h-full p-0" (onChange)="onTabChange($event)">
                <p-tabPanel header="Map">
                    <div class="relative h-full w-full">
                        <div #lookupMap class="absolute inset-0"></div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="List">
                    <div class="h-[calc(100%-64px)]">
                        <p-table 
                            [value]="records()"
                            [tableStyle]="{'min-width': '310px'}"
                            [lazy]="true"
                            (onLazyLoad)="loadRecords($event)"
                            [paginator]="true" 
                            [showPageLinks]="false"
                            paginatorDropdownAppendTo="body"
                            [rows]="rows()"
                            [rowsPerPageOptions]="[10,50,100]"
                            [totalRecords]="itemCount()"
                            [loading]="loading()"
                            [scrollable]="true"
                            scrollHeight="flex"
                            styleClass="bg-white p-0"
                        >
                            <ng-template pTemplate="body" let-item>
                                <tr (click)="activeRecord.set(item)" class="table-item table-item--thin" [ngClass]="{'selected': isSelected(item)}">
                                    <div class="table-item__inner justify-between">
                                        <p class="table-item__primary-text max-w-40 truncate">{{ item.title }}</p>
                                        <i class="fa-solid fa-chevron-right text-text-secondary"></i>
                                    </div>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="emptymessage">
                                <div class="absolute h-full w-full flex flex-col items-center justify-center">
                                    <i class="fa-solid fa-layer-group text-4xl text-primary-100 mb-5"></i>
                                    <p class="text-base font-semibold">No items</p>
                                </div>
                            </ng-template>
                        </p-table>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
        <div class="h-full w-1/2 min-w-80">
            <p-card styleClass="h-full overflow-y-auto">
                @if (!activeRecord()) {
                    <div class="h-full w-full flex items-center justify-center">
                        <div class="flex flex-col items-center gap-4">
                            @if (activeTab() === 'map') {
                                <i class="fa-solid fa-location-dot text-primary-100 text-5xl"></i>
                            }
                            @else if (activeTab() === 'list') {   
                                <i class="fa-solid fa-list text-primary-100 text-5xl"></i>
                            }

                            <p>Select a record to see its details</p>
                        </div>
                    </div>
                }
                @else {
                    <app-record-summary [recordId]="activeRecord().id" class="grow"></app-record-summary> 
                }
            </p-card>
        </div>
    </div>
    <div class="flex-none flex items-center justify-end p-5 bg-white border-t border-grey-border">
        <p-button size="large" label="Select record" (click)="selectRecord()" [disabled]="!activeRecord()"></p-button>
    </div>
</div>