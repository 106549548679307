import { Injectable, computed, inject, signal } from "@angular/core";
import { OrganisationFolder, OrganisationFolderTreeNode, OrganisationTreeFindResult, HomeProject, ProjectMembership, FavouriteEntity, OrganisationRole, ROOT_FOLDER } from "./home.models";
import { MenuItem } from "primeng/api";
import { ApiService } from "../../core/services/api.service";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { of, throwError } from "rxjs";
import { CoreoJob, JobsService, jobFieldsFragment } from "src/app/core/services/jobs.service";

@Injectable()
export class HomeService {

    private apiService = inject(ApiService);
    private jobsService = inject(JobsService);

    selectedProjects = signal<HomeProject[]>([]);
    selectedFolders = signal<OrganisationFolder[]>([]);
    selectionCount = computed(() => this.selectedProjects().length + this.selectedFolders().length);

    search = signal<string>('');

    projects = signal<HomeProject[]>([]);
    folders = signal<OrganisationFolder[]>([]);
    isFavouritesOnly = signal<boolean>(false);
    root = signal<OrganisationFolderTreeNode>(this.rootNode());

    folderId = signal<string | null>(null);
    currentOrganisationId = signal<number | null>(null);
    currentOrganisationRole = signal<OrganisationRole>(null);

    sortField = signal<'name' | 'isFavourite'>('name');
    sortReverse = signal<boolean>(false);

    current = computed<OrganisationTreeFindResult>(() => {
        const root = this.root();
        return this.findFolder(root, this.folderId()) ?? { node: root, path: [] };
    });

    currentFolderId = computed(() => {
        const current = this.current();
        if (current.node.id === ROOT_FOLDER) {
            return null;
        }
        return current.node.id;
    });


    currentProjects = computed<HomeProject[]>(() => {
        const current = this.current();
        const search = this.search();
        const sortReverse = this.sortReverse();
        const favourites = this.isFavouritesOnly();

        const canidateProjects = (search || favourites) ? this.getAllChildProjectsFromNode(this.root()) : [...current.node.projects];

        const projects = canidateProjects
            .filter(p => !search || p.name.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => {
                // Sort so all favourites are the top of the list, but sorted by name
                if (a.isFavourite !== b.isFavourite) {
                    return a.isFavourite ? -1 : 1;
                }
                return sortReverse ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
            })
            .filter(p => !favourites || p.isFavourite);
        return projects;
    });

    currentFolders = computed<OrganisationFolderTreeNode[]>(() => {
        const current = this.current();
        const search = this.search();
        const sort = this.sortField();
        const sortReverse = this.sortReverse();
        const favourites = this.isFavouritesOnly();

        const candidateFolders = (search || favourites) ? this.getAllChildFoldersFromNode(this.root()) : [...current.node.children];

        const folders = candidateFolders
            .filter(p => !search || p.name.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => sort === 'isFavourite' ? (a.isFavourite ? -1 : 1) : a[sort].localeCompare(b[sort]))
            .filter(p => !favourites || p.isFavourite);

        if (sortReverse) {
            folders.reverse();
        }
        return folders;
    });

    empty = computed(() => {
        return !this.currentFolders().length && !this.currentProjects().length;
    });

    isOrgAdmin = computed(() => {
        const role = this.currentOrganisationRole();
        return role === 'admin' || role === 'owner';
    });

    breadcrumbs = computed<MenuItem[]>(() => {
        const { path, node } = this.current();
        const breadcrumbsPath = [...path, node].map(({ id, name }) => ({ id, label: name }));
        return breadcrumbsPath.length >= 4
            ? [breadcrumbsPath[0], { id: null, label: '...' }, breadcrumbsPath.at(-2), breadcrumbsPath.at(-1)]
            : breadcrumbsPath;
    });

    loading = signal<boolean>(false);

    sort(sortField: 'name') {
        if (this.sortField() === sortField) {
            this.sortReverse.set(!this.sortReverse());
        } else {
            this.sortField.set(sortField);
        }
    }

    selectProject(project: HomeProject, isMulti: boolean) {
        if (!isMulti) {
            this.selectedFolders.set([]);
            return this.selectedProjects.set([project]);
        }
        const selection = this.selectedProjects();
        if (selection.findIndex(p => p.id === project.id) > -1) {
            this.selectedProjects.set(selection.filter(p => p !== project));
        } else {
            this.selectedProjects.set([...selection, project]);
        }
    }

    selectFolder(folder: OrganisationFolder, isMulti: boolean) {
        if (!isMulti) {
            this.selectedProjects.set([]);
            return this.selectedFolders.set([folder]);
        }

        const selection = this.selectedFolders();
        if (selection.findIndex(f => f.id === folder.id) > -1) {
            this.selectedFolders.set(selection.filter(f => f !== folder));
        } else {
            this.selectedFolders.set([...selection, folder]);
        }
    }

    updateSearch(search: string) {
        this.search.set(search);
    }

    openFolder(folder: OrganisationFolder) {
        this.clearSelection();
        this.folderId.set(folder.id);
    }

    isProjectSelected(project: HomeProject) {
        return this.selectedProjects().findIndex(p => p.id === project.id) > -1;
    }

    isFolderSelected(folder: OrganisationFolder) {
        return this.selectedFolders().findIndex(f => f.id === folder.id) > -1;
    }

    clearSelection() {
        this.selectedProjects.set([]);
        this.selectedFolders.set([]);
    }

    loadOrganisation(organisationId: number) {
        this.currentOrganisationId.set(organisationId);
        this.loading.set(true);
        this.projects.set([]);
        this.folders.set([]);
        this.root.set(this.rootNode());

        const query = `query {
            favouriteProjects(offset: 0) { id }
            favouriteOrganisationFolders(offset: 0) { id }
            organisation(id: ${this.currentOrganisationId()}){
                slug
                role: viewerRole
                folders{
                    id
                    parentId
                    name
                    updatedAt
                }
                projects{
                    id
                    name
                    imageUrl
                    slug
                    description
                    organisationFolderId
                    updatedAt
                    role: viewerRole
                }
            }
        }`;
        this.apiService.graphql<{ favouriteProjects: { id: number }[], favouriteOrganisationFolders: { id: string }[], organisation: { folders: OrganisationFolder[]; projects: HomeProject[]; role: OrganisationRole } }>(query).subscribe(t => {
            const favouriteProjectIds = t.favouriteProjects.map(project => project.id);
            const favouriteFolderIds = t.favouriteOrganisationFolders.map(folder => folder.id);

            const projects = t.organisation.projects.map(project => ({ ...project, isFavourite: favouriteProjectIds.includes(project.id), type: 'PROJECT' as 'PROJECT' }));
            const folders = t.organisation.folders.map(folder => ({ ...folder, isFavourite: favouriteFolderIds.includes(folder.id), type: 'ORGANISATION_FOLDER' as 'ORGANISATION_FOLDER' }));

            this.currentOrganisationRole.set(t.organisation.role);
            this.projects.set(projects);
            this.folders.set(folders);
            this.rebuildTree();
            this.loading.set(false);
        });
    }

    loadProject(projectId: number) {
        const query = `query CoreoAAGetProject($id: Int!){
            project(id: $id){
                    id
                    name
                    imageUrl
                    slug
                    description
                    organisationFolderId
                    updatedAt
                    role: viewerRole
            }
        }`;
        return this.apiService.graphql<{ project: HomeProject }>(query, { id: projectId }).pipe(map(res => res.project));
    }

    moveItems(targetFolderId: string, projects: HomeProject[], folders: OrganisationFolder[]) {
        const mutation = `mutation AAMoveProjectOrgFolder($input: MoveToOrganistionFolderInput!){
            moveToOrganisationFolder(input: $input)
        }`;

        return this.apiService.graphql<{ moveToOrganisationFolder: number }>(mutation, {
            input: {
                organisationId: this.currentOrganisationId(),
                targetOrganisationFolderId: targetFolderId === ROOT_FOLDER ? null : targetFolderId,
                projects: projects.map(p => p.id),
                folders: folders.map(f => f.id)
            }
        }).pipe(
            tap(() => {
                const updatedProjects = this.projects();
                const updatedFolders = this.folders();

                for (const project of projects) {
                    const index = updatedProjects.findIndex(p => p.id === project.id);
                    updatedProjects[index] = { ...updatedProjects[index], organisationFolderId: targetFolderId };
                }

                for (const folder of folders) {
                    const index = updatedFolders.findIndex(f => f.id === folder.id);
                    updatedFolders[index] = { ...updatedFolders[index], parentId: targetFolderId };
                }
                this.projects.set(updatedProjects);
                this.folders.set(updatedFolders);
                this.selectedFolders.set([]);
                this.selectedProjects.set([]);
                this.rebuildTree();
            })
        )
    }

    createProject(name: string, description: string, memberships: ProjectMembership[] = []) {
        const mutation = `mutation AACreateProject($input: ProjectInput!){
            createProject(input: $input){
                id
                slug
                name
                description
                imageUrl
                updatedAt
                role: viewerRole
                organisationFolderId
            }
        }`;

        return this.apiService.graphql<{ createProject: HomeProject }>(mutation, {
            input: {
                name,
                description,
                organisationId: this.currentOrganisationId(),
                organisationFolderId: this.currentFolderId(),
                memberships: memberships.map(m => ({
                    userId: m.userId,
                    role: m.role
                }))
            }
        }).pipe(tap((result) => {
            this.projects.set([...this.projects(), { ...result.createProject, type: 'PROJECT', isFavourite: false }]);
            this.rebuildTree();
        }));
    }

    cloneProjectTemplate(projectId: number, name: string, description: string, memberships: ProjectMembership[] = []) {
        const mutation = `mutation AACloneProjectTemplate($input: CloneProjectTemplateInput!){
            cloneProjectTemplate(input: $input){
                ...jobFields
            }
        }
        ${jobFieldsFragment}`;

        return this.apiService.graphql<{ cloneProjectTemplate: CoreoJob }>(mutation, {
            input: {
                projectId,
                name,
                description,
                organisationId: this.currentOrganisationId(),
                organisationFolderId: this.currentFolderId(),
                memberships: memberships.map(m => ({
                    userId: m.userId,
                    role: m.role
                }))
            }
        }).pipe(switchMap(result => {
            return this.jobsService.addJob(result.cloneProjectTemplate).pipe(
                filter(job => job.status === 'complete'),
                switchMap(job => {
                    return this.loadProject(parseInt(job.url)).pipe(
                        tap(project => {
                            console.log('LOADED UP PROJECT', project);
                            this.projects.set([...this.projects(), { ...project, type: 'PROJECT', isFavourite: false }]);
                            this.rebuildTree();
                        })
                    )
                })
            );
        }));
    }

    cloneProject(projectId: number, name: string, description: string, memberships: ProjectMembership[] = []) {
        return this.apiService.graphql<{ userCloneProject: CoreoJob }>(`mutation AACloneProject($input: UserCloneProjectInput!) {
            userCloneProject(input: $input){
                ...jobFields 
            }
        }
        ${jobFieldsFragment}`, {
            input: {
                projectId,
                name,
                description,
                memberships: memberships.map(m => ({
                    userId: m.userId,
                    role: m.role
                }))
            }
        }).pipe(switchMap(result => {
            return this.jobsService.addJob(result.userCloneProject).pipe(
                filter(job => job.status === 'complete'),
                switchMap(job => {
                    return this.loadProject(parseInt(job.url)).pipe(
                        tap(project => {
                            console.log('LOADED UP PROJECT', project);
                            this.projects.set([...this.projects(), { ...project, type: 'PROJECT', isFavourite: false }]);
                            this.rebuildTree();
                        })
                    )
                })
            )
        }));
    }

    createFolder(name: string, parentId: string | null = null) {
        const organisationId = this.currentOrganisationId();

        const mutation = `mutation AACreateOrgFolder($input: CreateOrganisationFolderInput!){
            createOrganisationFolder(input: $input){
                id
                name
                parentId
                updatedAt
            }
        }`;
        return this.apiService.graphql<{ createOrganisationFolder: OrganisationFolder }>(mutation, {
            input: {
                name,
                parentId,
                organisationId,
            }
        }).pipe(tap((r) => {
            this.folders.set([...this.folders(), { ...r.createOrganisationFolder, type: 'ORGANISATION_FOLDER' }]);
            this.rebuildTree();
        }));
    }

    renameFolder(id: string, name: string) {
        const mutation = `mutation AARenameOrgFolder($input: UpdateOrganisationFolderInput!){
            updateOrganisationFolder(input: $input){
                id
            }
        }`;
        const organisationId = this.currentOrganisationId()!;
        return this.apiService.graphql<{ renameOrganisationFolder: OrganisationFolder }>(mutation, {
            input: {
                id,
                name,
                organisationId
            }
        }).pipe(tap((r) => {
            this.loadOrganisation(organisationId);
        }));
    }

    deleteFolder(id: string) {
        const mutation = `mutation AADeleteOrgFolder($input: DeleteOrganisationFolderInput!){
            deleteOrganisationFolder(input: $input)
        }`;
        const organisationId = this.currentOrganisationId()!;
        return this.apiService.graphql<{ deleteOrganisationFolder: boolean }>(mutation, {
            input: {
                id,
                organisationId
            }
        }).pipe(tap((r) => {
            this.selectedFolders.update(s => s.filter(f => f.id !== id));
            this.loadOrganisation(organisationId);
        }));
    }

    deleteProject(id: number) {
        const mutation = `mutation AADeleteProject($input: projectDeleteInput!){
            deleteProject(input: $input)
        }`;
        return this.apiService.graphql<{ deleteProject: boolean }>(mutation, {
            input: {
                id
            }
        }).pipe(tap(() => {
            this.selectedProjects.update(s => s.filter(p => p.id !== id));
            this.loadOrganisation(this.currentOrganisationId());
        }));
    }

    createFavourite(entities: FavouriteEntity[]) {
        const mutation = `mutation AACreateFavourites($input: createFavouritesInput!){
            createFavourites(input: $input)
        }`;
        return this.apiService.graphql<{ createFavourites: boolean }>(mutation, {
            input: { entities }
        }).pipe(tap(() => {
            this.updateSuccessfulFavourite(entities, true);
        }));
    }

    deleteFavourite(entities: FavouriteEntity[]) {
        const mutation = `mutation AADeleteFavourites($input: deleteFavouritesInput!){
            deleteFavourites(input: $input)
        }`;
        return this.apiService.graphql<{ createFavourites: boolean }>(mutation, {
            input: { entities }
        }).pipe(tap(() => {
            this.updateSuccessfulFavourite(entities, false);
        }));
    }

    updateSuccessfulFavourite(updatedEntities: FavouriteEntity[], isFavourite: boolean) {
        const update = (items: any) => items.map(item =>
            updatedEntities.find(updated => updated.id === item.id) ? { ...item, isFavourite } : item
        );

        this.folders.set(update(this.folders()));
        this.projects.set(update(this.projects()));
        this.rebuildTree();
    }

    moveSelectedItems(targetFolderId: string) {
        return this.moveItems(targetFolderId, this.selectedProjects(), this.selectedFolders());
    }

    setRoot(root: OrganisationFolderTreeNode) {
        this.root.set(root);
    }

    rebuildTree() {
        this.root.set(this.buildTree(this.projects(), this.folders()));
    }

    rootNode(): OrganisationFolderTreeNode {
        return {
            id: ROOT_FOLDER,
            parentId: null,
            updatedAt: '',
            name: '',
            projects: [],
            children: [],
            isFavourite: false,
            type: 'ORGANISATION_FOLDER'
        };
    }

    buildTree(projects: HomeProject[], folders: OrganisationFolder[], folderId: string | null = null): OrganisationFolderTreeNode {

        const childProjects = projects.filter(p => p.organisationFolderId === folderId);
        const childFolders = folders.filter(f => f.parentId === folderId).map(f => this.buildTree(projects, folders, f.id));
        const folder = folders.find(f => f.id === folderId) ?? { isFavourite: false };
        return {
            id: ROOT_FOLDER,
            parentId: null,
            updatedAt: '',
            name: 'Projects',
            ...folder,
            projects: childProjects,
            children: childFolders,
            type: 'ORGANISATION_FOLDER'
        }
    };

    findFolder(node: OrganisationFolderTreeNode, id: string | null, path: OrganisationFolderTreeNode[] = []): OrganisationTreeFindResult | null {

        if (!node) {
            return {
                node: this.rootNode(),
                path: []
            };
        }

        if (node.id === id) {
            return { node, path };
        }

        for (const child of node.children) {
            const found = this.findFolder(child, id, [...path, node]);
            if (found) {
                return found;
            }
        }
        return null;
    }


    getAllChildProjectsFromNode(node: OrganisationFolderTreeNode): HomeProject[] {
        return node.projects.concat(node.children.flatMap(child => this.getAllChildProjectsFromNode(child)));
    }

    getAllChildFoldersFromNode(node: OrganisationFolderTreeNode): OrganisationFolderTreeNode[] {
        return node.children.concat(node.children.flatMap(child => this.getAllChildFoldersFromNode(child)));
    }


}