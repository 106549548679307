<p-table #adminRequestsTable [value]="requests" [tableStyle]="{ 'min-width': '50rem', 'table-layout': 'fixed' }"
    [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,50,100]" [rowHover]="true" [lazy]="true"
    (onLazyLoad)="loadRequests($event)" [loading]="loading" sortField="id" [sortOrder]="-1" [scrollable]="true"
    scrollHeight="flex" (onRowSelect)="rowSelect($event)" class="text-sm" [first]="first" [rows]="rows"
    [filters]="filters" [sortField]="sortField" [sortOrder]="sortOrder" [totalRecords]="1000">

    <ng-template pTemplate="caption">
        <div class="flex items-center justify-between w-full">
            <!-- <p-button label="Clear" icon="pi pi-filter-slash" [outlined]="false" size="large"
                    (click)="clear(adminUsersTable)"></p-button> -->
            <h3 class="text-2xl mr-4">Requests</h3>
            <!-- <span class="p-input-icon-left w-96">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="globalSearch($event)" class="w-full" autocomplete="off"
                    placeholder="Search by name, username or email" data-lpignore="true" [value]="search" />
            </span> -->
        </div>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="id" style="width: 10%;">ID<p-sortIcon field="id"></p-sortIcon></th>
            <th pSortableColumn="path" style="width: 10%;">Path<p-sortIcon field="path"></p-sortIcon></th>
            <th style="width: 10%;">Method</th>
            <th style="width: 10%;">Time Taken(ms)</th>
            <th style="width: 10%;">Response</th>
            <th style="width: 10%;">User</th>
            <th style="width: 10%;">Project</th>
            <th pSortableColumn="requestedAt" style="width: 10%;">Timestamp<p-sortIcon field="requestedAt"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th><p-columnFilter type="text" field="id" [showButtons]="false" [showOperator]="false"
                    [showMenu]="false"></p-columnFilter></th>
            <th><p-columnFilter field="path" type="text" matchMode="equals" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-inputGroup>
                            <p-inputGroupAddon>/</p-inputGroupAddon>
                            <input #path pInputText type="text" [ngModel]="value" (change)="filter(path.value)" />
                        </p-inputGroup>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th><p-columnFilter field="method" matchMode="equals" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-dropdown [ngModel]="value" [options]="methods" (onChange)="filter($event.value)"
                            placeholder="Any" [showClear]="true">
                            <ng-template let-option pTemplate="item">
                                {{option}}
                            </ng-template>
                        </p-dropdown>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th></th>
            <th></th>
            <th>
                <p-columnFilter field="user" type="text" matchMode="equals" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-autoComplete [ngModel]="value" [suggestions]="users" field="displayName"
                            (completeMethod)="searchUsers($event)" (onSelect)="filter($event.value)"></p-autoComplete>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th>
                <p-columnFilter field="project" type="text" matchMode="equals" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-autoComplete [ngModel]="value" [suggestions]="projects" field="name"
                            (completeMethod)="searchProjects($event)"
                            (onSelect)="filter($event.value)"></p-autoComplete>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th></th>
            <!-- <th><p-columnFilter field="displayName" type="text" matchMode="equals" [showMenu]="false"></p-columnFilter>
            </th>
            <th><p-columnFilter field="username" type="text" matchMode="equals" [showMenu]="false"></p-columnFilter>
            </th>
            <th><p-columnFilter field="email" type="text" matchMode="equals" [showMenu]="false"></p-columnFilter></th>
            <th><p-columnFilter field="role" matchMode="equals" [showMenu]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-dropdown [ngModel]="value" [options]="roles" (onChange)="filter($event.value)"
                            placeholder="Any" [showClear]="true">
                            <ng-template let-option pTemplate="item">
                                {{option.label}}
                            </ng-template>
                        </p-dropdown>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th></th>
            <th></th> -->
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-request>
        <tr (click)="rowSelect(request)" class="cursor-pointer">
            <td class="break-words"><a class="hover:underline" [href]="'/admin/requests/'+request.id">{{ request.id
                    }}</a></td>
            <td class="break-words">{{ request.path }}</td>
            <td class="break-words">{{ request.method }}</td>
            <td class="break-words">{{ request.timeTaken }}</td>
            <td class="break-words">{{ request.responseStatus }}</td>
            <td class="break-words">{{ request.user?.displayName }}</td>
            <td class="break-words">{{ request.project?.name }}</td>
            <td class="break-words">{{ request.requestedAt | date:'long' }}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8">No requests found.</td>
        </tr>
    </ng-template>
</p-table>