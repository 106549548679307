import angular from 'angular';
import { ROOT_FOLDER } from 'src/app/features/home/home.models';
import { getAuthCurrentOrganisation, getAuthOrganisationId, getAuthUserId } from '../store/selectors';

export default angular
    .module('app.home', ['ui.router'])
    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('home', {
                parent: 'app',
                url: '/?id',
                template: '<app-home [id]="ctrl.id" [organisation-id]="ctrl.organisationId" [user-id]="ctrl.userId" (folder)="ctrl.folder($event)" (project)="ctrl.project($event)"></app-home>',
                controllerAs: 'ctrl',
                params: {
                    id: {
                        dynamic: true,
                    }
                },
                controller: class HomeController {

                    public id: string;
                    public organisationId: number;
                    public currentOrganisation: any;
                    public userId: number;

                    static $inject = ['$scope', '$state', '$stateParams', '$transitions', '$ngRedux'];
                    constructor(private $scope, private $state, private $stateParams, private $transitions, private $ngRedux) {
                    }

                    $onInit() {
                        this.id = this.$stateParams.id;
                        this.$scope.$on('$destroy', this.$transitions.onSuccess({ to: 'home' }, (trans) => {
                            const params = trans.paramsChanged();
                            this.id = params.id;
                        }));

                        this.$scope.$on('$destroy', this.$ngRedux.connect((state) => {
                            return {
                                userId: getAuthUserId(state),
                                organisationId: getAuthOrganisationId(state),
                                currentOrganisation: getAuthCurrentOrganisation(state)
                            };
                        }, null)(this));
                    }

                    folder(id: string) {
                        this.$state.go('.', { id: id === ROOT_FOLDER ? null : id });
                    }

                    project(event: { project: any, state: string }) {
                        this.$state.go(event.state, {
                            orgSlug: this.currentOrganisation.slug,
                            id: event.project.slug
                        });
                    }
                }
            })
    }]);