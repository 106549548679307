export const ProjectFormsBlockQuestionComponent = {
    bindings: {
        attribute: '<',
        labeltext: '<',
        readonly: '<'
    },
    template: require('!raw-loader!./project-forms-block-question.component.html').default,
    controllerAs: 'ctrl',
    controller: class ProjectFormsBlockQuestionComponent {
        static $inject = ['$scope', '$element', '$timeout', 'ProjectService'];
        constructor($scope, $element, $timeout, ProjectService) {

        }
    }
};
