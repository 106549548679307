import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastModule } from "primeng/toast";
import { PanelModule } from 'primeng/panel';
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { TransferOrganisationComponent } from './transfer-organisation.component';
import { ApiService } from "src/app/core";
import { OrganisationInfoSchema } from '../../../core/models/organisation.model';

@Component({
    selector: 'app-admin-organisation',
    templateUrl: './admin-organisation.component.html',
    imports: [
        ReactiveFormsModule,
        ToastModule,
        PanelModule,
        ButtonModule,
        CalendarModule,
        InputTextModule,
        DropdownModule,
        TransferOrganisationComponent
    ],
    standalone: true,
    styles: [
        `:host{ @apply block h-full w-full overflow-scroll p-8;}`
    ],
    providers: [MessageService]
})
export class AdminOrganisationComponent implements OnInit {

    @Input() organisationId: number;

    @Output() dashboard: EventEmitter<number> = new EventEmitter();

    organisationForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        slug: new FormControl('', [Validators.required]),
        seats: new FormControl<number>(0, [Validators.required]),
        contributorSeats: new FormControl<number>(0, [Validators.required]),
        organisationTierId: new FormControl<number | null>(null, [Validators.required]),
        freeTrialEnd: new FormControl<Date | null>(null)
    });

    organisation: any = null;
    organisationTiers: any = null;

    isVisibleDialog: boolean = false;

    private readonly getOrganisationQuery: string = `query getAdminOrganisation($id: Int!) {
    organisationTiers { id name }
    organisation(id: $id) { id, name, slug, seats, contributorSeats, freeTrialEnd, organisationTierId,
      owner { id, displayName }
    }
  }`;

    private readonly updateOrganisationQuery: string = `mutation updateOrganisation($input: OrganisationUpdateInput!) {
      result: updateOrganisation(input: $input) { id }
  }`;

    constructor(
        private apiService: ApiService,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        this.initOrganisationData();
    }


    initOrganisationData() {
        this.apiService.graphql(this.getOrganisationQuery, { id: this.organisationId })
            .subscribe(response => {
                const parsedResponse = OrganisationInfoSchema.parse(response);
                const { name, slug, seats, contributorSeats, organisationTierId, freeTrialEnd } = this.organisation = parsedResponse.organisation;
                this.organisationTiers = parsedResponse.organisationTiers;
                const tier = this.organisationTiers.find(tier => this.organisation.organisationTierId === tier.id);
                this.organisationForm.setValue({ name, slug, seats, contributorSeats, organisationTierId: tier.id, freeTrialEnd: freeTrialEnd ? new Date(freeTrialEnd) : null });
            });
    }

    getDirtyValues() {
        const dirtyValues = {};
        Object.keys(this.organisationForm.controls).forEach(key => {
            const currentControl = this.organisationForm.controls[key];
            if (currentControl.dirty) {
                dirtyValues[key] = currentControl.value;
            }
        });
        return dirtyValues;
    }

    onSubmit() {
        const input = this.getDirtyValues();
        this.apiService.graphql<any>(this.updateOrganisationQuery, {
            input: {
                id: this.organisationId,
                ...input
            }
        })
            .subscribe(() => {
                this.initOrganisationData();
                this.organisationForm.markAsPristine();
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Organisation updated' });
            },
                (error) => {
                    console.error(error);
                    this.organisationForm.markAsPristine();
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Organisation failed to update' });
                });
    }

    onVisibleChange(event: { isVisibleDialog: boolean; isTransfered: boolean }) {
        this.isVisibleDialog = event.isVisibleDialog;
        if (!event.isTransfered) return;
        this.initOrganisationData();
        this.organisationForm.reset();
    }

    goToDashboard() {
        this.dashboard.emit(this.organisationId);
    }
}