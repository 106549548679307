export default {
    // background: [{
    //     label: 'Color',
    //     key: 'background-color',
    //     type: 'color',
    //     group: 'paint',
    //     default: '#000000'
    // }],
    circle: [{
        label: 'Radius',
        key: 'circle-radius',
        type: 'number',
        group: 'paint',
        default: 5
    }, {
        label: 'Color',
        key: 'circle-color',
        type: 'color',
        group: 'paint',
        default: '#000000'
    }, {
        label: 'Blur',
        key: 'circle-blur',
        type: 'number',
        group: 'paint',
        default: 0
    }, {
        label: 'Opacity',
        key: 'circle-opacity',
        type: 'number',
        group: 'paint',
        default: 1,
        min: 0,
        max: 1,
        step: 0.1
    }],
    line: [{
        label: 'Color',
        key: 'line-color',
        type: 'color',
        group: 'paint',
        default: '#000000'
    }, {
        label: 'Opacity',
        key: 'line-opacity',
        type: 'number',
        min: 0,
        max: 1,
        step: 0.1,
        group: 'paint',
        default: 1
    }, {
        label: 'Width',
        key: 'line-width',
        type: 'number',
        min: 0,
        step: 1,
        unit: 'px',
        group: 'paint',
        default: 1
    }, {
        label: 'Cap',
        key: 'line-cap',
        type: 'enum',
        options: ['butt', 'round', 'square'],
        group: 'layout',
        default: 'butt'

    }, {
        label: 'Join',
        key: 'line-join',
        type: 'enum',
        options: ['bevel', 'round', 'miter'],
        group: 'layout',
        default: 'miter'
    }],
    fill: [{
        label: 'Fill Opacity',
        key: 'fill-opacity',
        type: 'number',
        min: 0,
        max: 1,
        step: 0.1,
        group: 'paint',
        default: 1
    }, {
        label: 'Fill Color',
        key: 'fill-color',
        type: 'color',
        group: 'paint',
        default: '#000000'
    }, {
        label: 'Fill Outline Color',
        key: 'fill-outline-color',
        type: 'color',
        group: 'paint',
        default: '#000000'
    }],
    symbol: [{
        label: 'Text Field',
        key: 'text-field',
        type: 'string',
        default: '',
        group: 'layout'
    }, {
        label: 'Text Size',
        key: 'text-size',
        type: 'number',
        min: 0,
        step: 1,
        default: 16,
        group: 'layout',
        unit: 'px'
    }, {
        label: 'Text Color',
        key: 'text-color',
        type: 'color',
        default: '#000000',
        group: 'paint'
    }, {
        label: 'Text Allow Overlap',
        key: 'text-allow-overlap',
        default: false,
        group: 'layout',
        type: 'boolean',
    }, {
        label: 'Text Ignore Placement',
        key: 'text-ignore-placement',
        default: false,
        group: 'layout',
        type: 'boolean',
    }, {
        label: 'Text Optional',
        key: 'text-optional',
        default: false,
        group: 'layout',
        type: 'boolean',
    }, {
        label: 'Icon Size',
        key: 'icon-size',
        type: 'number',
        default: 1,
        min: 1,
        group: 'layout'
    }, {
        label: 'Icon Image',
        key: 'icon-image',
        type: 'string',
        default: '',
        group: 'layout',
        image: true
    }, {
        label: 'Icon Opacity',
        key: 'icon-opacity',
        type: 'number',
        default: 1,
        group: 'paint',
        min: 0,
        max: 1,
        step: 0.1
    }, {
        label: 'Icon Ignore Placement',
        key: 'icon-ignore-placement',
        default: false,
        group: 'layout',
        type: 'boolean',
    }, {
        label: 'Icon Allow Overlap',
        key: 'icon-allow-overlap',
        default: false,
        group: 'layout',
        type: 'boolean',
    }, {
        label: 'Icon Anchor',
        key: 'icon-anchor',
        default: 'center',
        group: 'layout',
        type: 'enum',
        options: ['center', 'left', 'right', 'top', 'bottom', 'top-left', 'top-right', 'bottom-left', 'bottom-right']
    }, {
        label: 'Placement',
        key: 'symbol-placement',
        type: 'enum',
        options: ['point', 'line', 'line-center'],
        default: 'point',
        group: 'layout'
    }],
    heatmap: [{
        label: 'Heatmap Radius',
        key: 'heatmap-radius',
        type: 'number',
        default: 30,
        group: 'paint'
    }, {
        label: 'Heatmap Weight',
        key: 'heatmap-weight',
        default: 1,
        group: 'paint',
        type: 'number',
        min: 0,
        max: 1,
        step: 0.1
    }, {
        label: 'Heatmap Intensity',
        key: 'heatmap-intensity',
        default: 1,
        group: 'paint',
        type: 'number',
        min: 0,
        max: 1,
        step: 0.1
    }, {
        label: 'Heatmap Color',
        key: 'heatmap-color',
        default: ["interpolate", ["linear"], ["heatmap-density"],
            0,
            "rgba(255,0,0,0)",
            0.1, "#4169e1",
            0.3, "#00FFFF",
            0.5, "#bfff00",
            0.7, "#ffff00",
            1, "#ff0000"],
        group: 'paint',
        type: 'heatmap-color',
    }, {
        label: 'Heatmap Opacity',
        key: 'heatmap-opacity',
        default: 1,
        group: 'paint',
        type: 'number',
        min: 0,
        max: 1,
        step: 0.1
    }]
};